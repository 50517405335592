<div class="legend-container flex-row">
    <div class="legend-item">
        <app-icon icon="time" class="icon type-red"></app-icon>
        Overdue
    </div>
    <div class="legend-item">
        <app-icon icon="time" class="icon type-orange"></app-icon>
        Due within 48 hours
    </div>
    <div class="legend-item">
        <app-icon icon="time" class="icon type-blue"></app-icon>
        Due after 48 hours
    </div>
    <div class="legend-item">
        <app-icon icon="time" class="icon type-grey"></app-icon>
        No due date
    </div>
</div>