import { Component, Input, OnInit } from '@angular/core';

import { Note } from './notes.models';
import { NotesService } from './notes.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-notes',
    templateUrl: 'notes.html',
    styleUrl: 'notes.scss'
})
export class NotesComponent implements OnInit {

    @Input()
    public notes: Note[] = [];

    @Input()
    public completionNote: string;

    @Input()
    public taskComplete: boolean;

    public showAdd: boolean = false;

    public expanded: boolean = false;

    public get IsEditMode(): boolean {

        return this.showAdd || this.notes.filter(x => x?.editing === true).length > 0;

    }

    private destroy$ = new Subject<void>();

    constructor(private noteService: NotesService) {
    }

    public ngOnInit(): void {

        this.noteService.addToggled$
            .pipe(takeUntil(this.destroy$))
            .subscribe((display: boolean) => {

                this.showAdd = display;

                if (display === true) {

                    this.toggleExpanded(true);

                }

            });

    }

    public toggleExpanded(expanded: boolean) {

        this.expanded = expanded;

    }

    public delete(noteId: string) {

        this.noteService.delete(noteId);

    }

    public toggleEditing(note: Note, editing: boolean) {

        note.editing = editing;

    }

    public cancelAdd() {

        this.showAdd = false;

        if (this.notes.length === 0) {

            this.toggleExpanded(false);

        }

    }

    public update(text: string, note: Note) {

        if (text !== note.text) {

            const updatedNote = { ...note, text };

            this.noteService.update(updatedNote);

        }

    }

    public add(text: string) {

        this.noteService.add(text);

    }

}