import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';

@Injectable({ providedIn: 'root' })
export class ToolPanelService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getBadgeValue(endpoint:string):Promise<any>{

        const baseUrl = this.getUserBaseUrl(true);

        return await this.http.get<any>(`${baseUrl}${endpoint}`).toPromise();

    }

}