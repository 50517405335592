import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';

import { MatMenuTrigger } from '@angular/material/menu';

export interface SelectMenuItem {
    title: string;
    value: any;
    description?: string;
    icon?: string;
}

@Component({
    selector: 'app-select-menu',
    templateUrl: 'select-menu.html',
    styleUrls: ['./select-menu.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectMenuComponent implements OnInit {

    @ViewChild(MatMenuTrigger, { static: true, read: ElementRef })
    private selectMenu: ElementRef<HTMLElement>;

    @Input()
    public items: SelectMenuItem[] = [];

    @Input()
    public value: any;

    @Output()
    public valueChange = new EventEmitter<any>();

    public selectedItem?: SelectMenuItem;

    ngOnInit(): void {

        if (!this.value) {
            return;
        }

        var selectedItem = this.items.find(x => x.value === this.value);
        if (selectedItem) {
            this.selectedItem = selectedItem;
        }

    }

    get menuData() {

        return {
            menuWidth: this.selectMenu.nativeElement.clientWidth
        };

    }

    public onSelectionChange(selectedItem: SelectMenuItem): void {

        this.selectedItem = selectedItem;
        this.value = this.selectedItem.value;
        this.valueChange.emit(selectedItem.value);

    }

}