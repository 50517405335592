import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuButtonsComponent } from './menu-buttons.component';
import { InfoPointModule } from '../info-point/info-point.module';

@NgModule({
    imports: [
        CommonModule,
        InfoPointModule

    ],
    exports: [
        MenuButtonsComponent
    ],
    declarations: [
        MenuButtonsComponent
    ]
})
export class MenuButtonsModule { }

export * from './menu-buttons.component'

