import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatTooltipModule } from '@angular/material/tooltip';
import { OverlayModule } from '@angular/cdk/overlay'
import { CorePipesModule, SmzDatePipe } from '@summize/shared/components-v2';

import { TaskCardComponent } from './task-card.component';
import { IconModule } from '../icon/icon.module';
import { BlockButtonModule } from '../block-button/block-button.module';
import { EllipsesMenuModule } from '../ellipses-menu/ellipses-menu.module';
import { TaskNotesModule } from '../task-notes/task-notes.module';
import { DueDateModule } from '../due-date/due-date.module';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        BlockButtonModule,
        EllipsesMenuModule,
        MatTooltipModule,
        RouterModule,
        MatTooltipModule,
        CorePipesModule,
        OverlayModule,
        TaskNotesModule,
        DueDateModule
    ],
    exports: [
        TaskCardComponent
    ],
    declarations: [
        TaskCardComponent,
    ],
    providers: [
        SmzDatePipe,
    ]
})
export class TaskCardModule { }

export * from './task-card.component'