export const SPECIAL_KEYWORD_TYPES = {
    NUMBERS: {label: 'All Numbers', tooltip: 'Search for any numerical value (i.e. 20)'},
    PARTIES: {label: 'All Parties', tooltip: 'Search for any detected party'},
    DURATION: {label: 'Duration', tooltip: 'Search for any numerical value together with a time-frame (i.e. 30 days, three months)'},
    CURRENCY: {label: 'All Currencies', tooltip: 'Search for any numerical value together with a currency symbol (i.e. £1,000,000)'},
    DATES: {label: 'All Dates', tooltip: 'Search for any date (i.e. 12/09/2021 or 12th September 2021)'},
    RATE: {label: 'Rate', tooltip: 'Search for any rates or percentages (i.e. 20% or £10 per hour)'},
    LOCATION: {label: 'Locations', tooltip: 'Search for any geographical location (i.e. London)'},
    CURRENCYSYMBOL: {label: 'Currency Symbol', tooltip: 'Search for any numerical value together with a currency symbol (i.e. £1,000,000)'},
    PERCENTAGE: {label: 'Percentage', tooltip: 'Search for a percentage (for example 20%, 1.5 Percent'}
};
