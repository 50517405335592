import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IconModule } from '../icon/icon.module';

import { AttachmentDetailsComponent } from './attachment-details.component';
import { AttachmentDetailsService } from './attachment-details.service';
import { EllipsesMenuModule } from '../ellipses-menu/ellipses-menu.module';
import { CorePipesModule, SmzDatePipe } from '../pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IconModule,
        EllipsesMenuModule,
        CorePipesModule,
    ],
    exports: [
        AttachmentDetailsComponent
    ],
    declarations: [
        AttachmentDetailsComponent
    ],
    providers: [
        AttachmentDetailsService,
        SmzDatePipe
    ]
})
export class AttachmentDetailsModule { }

export * from './attachment-details.component'