import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-info-tool',

    templateUrl: './tooltip.html',
    styleUrls: ['./tooltip.scss']
})
export class TooltipComponent {

    @Input()
    public text: string;

    @Input()
    public display: string;
}