import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './styled-button.html',
    styleUrls: ['./styled-button.scss'],
})
export class StyledButtonComponent implements OnInit {

    @Input()
    public type: 'primary' | 'secondary' | 'tertiary' | 'primaryPurple' | 'secondaryPurple' | 'tertiaryPurple' | 'ghost' | 'ghost-outline' | 'none' = 'none';

    @Input()
    public size: 'small' | 'medium' | 'large' | 'block' | 'none' = 'none';

    @Input('disabled')
    public isDisabled: boolean;

    @Input('expand')
    public expand: boolean;

    @Input('textAlign')
    public textAlign: 'left' | 'center' | 'right' = 'center';

    @HostBinding('style.width')
    public width = 'unset';

    public ngOnInit(): void {

        this.isDisabled
            = this.isDisabled !== undefined && this.isDisabled !== false;

        this.expand
            = this.expand !== undefined && this.expand !== false;

        if (this.expand) {
            this.width = '100%';
        }

    }
}
