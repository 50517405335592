<div class="flex">
    <mat-radio-group (change)="onSelectionChange($event.value)" [value]="value">
        <mat-radio-button *ngFor="let item of items" [value]="item.value">
            <span class="label-text">{{ item.name }}</span>
            <ng-container *ngIf="item.description">
                <span *ngIf="type === RadioGroupType.default" class="description"> - {{ item.description }}</span>
                <span *ngIf="type === RadioGroupType.alt" class="description alt-description"> {{ item.description }}</span>
            </ng-container>
        </mat-radio-button>
    </mat-radio-group>
</div>