<div class="container">

    <div class="body">

        <app-chat-welcome *ngIf="chatThreadId === undefined || chatThreadId === EMPTY_GUID" class="ml-16 mr-16"></app-chat-welcome>

        <app-chat-messages *ngIf="chatThreadId !== undefined && chatThread !== undefined && chatThread?.assistantChatMessages.length > 0" class="ml-16 mr-16"
            [chatMessages]="chatThread.assistantChatMessages" [settings]="meta?.settings"> </app-chat-messages>

        <div class="busy" *ngIf="isBusy === true">
            <mat-spinner [diameter]="16" class="progress-spinner"></mat-spinner>
        </div>

        <div id="chatThreadBottom"></div>
    </div>

    <div class="footer">
        <div *ngIf="inputMeta" id="chatInput" class="input-container" [class.ishidden]="isBusy === true">
            <div class="input-entry">
                <div *ngIf="isInputBusy === true" class="input-busy">
                    <div class="input-busy-icon">
                        <mat-spinner [diameter]="16" class="progress-spinner"></mat-spinner>
                    </div>
                    <div class="input-busy-text">Generating response...</div>
                </div>
                <div *ngIf="selectedText?.length > 0 && isInputBusy !== true" class="mb-8">
                    <div class="input-selected-text" (click)="insertSelectedText()">Insert selected text</div>
                </div>
                <app-chat-input [class.displaynone]="isInputBusy === true" (onContentSend)="messageSend($event)"
                    [meta]="inputMeta" display="inline"></app-chat-input>
            </div>
            <div class="input-footer">
                <div class="input-message">Use <span class="input-hash-character" (click)="chatInput.openMentionMenu('#')">#</span> to insert a suggested prompt</div>
                <mat-menu #appMenu="matMenu">
                    <button *ngIf="chatThread !== undefined" mat-menu-item (click)="newThread()">New thread</button>
                    <button *ngIf="chatThread !== undefined" mat-menu-item (click)="clearThread()">Clear thread</button>
                </mat-menu>
                <div class="input-info clickable" [matMenuTriggerFor]="appMenu">
                    <app-icon class="tokenlimit-icon" icon="alert-circle-outline"></app-icon>
                </div>
            </div>
        </div>
    </div>

</div>
