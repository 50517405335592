import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from './base-tenant.service';

export interface UserDetails {
    tenantId: string;
    departmentId: string;
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface Users {
    users: UserDetails[];
    totalUsers: number;
    filteredUsers: number;
}

@Injectable({
    providedIn: 'root'
})
export class UserDetailsService extends BaseTenantService {
    constructor(private http: HttpClient) {
        super();
    }

    public async getLegalUsers(query: string): Promise<Users> {

        const baseUrl = await this.getUserBaseUrl(true);

        return await this.asPromise(this.http.get<Users>(`${baseUrl}users?name=${query}&userTypes=1,2`, { responseType: 'json' }));

    }

    public async getUserById(id: string): Promise<UserDetails> {

        const baseUrl = await this.getUserBaseUrl(true);

        return await this.asPromise(this.http.get<UserDetails>(`${baseUrl}users/${id}`, { responseType: 'json' }));

    }
}
