import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTooltipModule } from '@angular/material/tooltip';

import { IconComponent } from './icon.component';

@NgModule({
    imports: [
        CommonModule,
        MatTooltipModule
    ],
    exports: [
        IconComponent
    ],
    declarations: [
        IconComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class IconModule { }

export * from './icon.component'