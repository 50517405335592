import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';

import { BaseTenantService } from '@summize/shared/core';

@Directive({
    selector: '[appClaimsHide]'
})
export class ClaimsHideDirective {

    @Input()
    public set appClaimsHide(claims: string[]) {

        this.claims = claims;

        this.updateView();

    }

    public claims: string[] = [];

    public claimsAction: string;

    private __base: BaseTenantService;

    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {

        this.__base = new BaseTenantService();

    }

    public updateView(): void {

        if (this.claims) {

            if (!this.__base.hasClaims(this.claims)) {

                this.viewContainer.createEmbeddedView(this.templateRef);

            } else {

                this.viewContainer.clear();

            }
        }
    }

}