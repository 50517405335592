import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ConfirmationModalComponent } from "@summize/shared/components-v2";
import { AppComponent } from "@summize/shared/core";
import { EventService, RequestAttachment, ShowToastMessage } from "@summize/shared/framework";

import { DownloadHelper } from "../download.helper";
import { ElipsisCommand } from "../ellipses-menu/ellipses-menu.component";
import { AttachmentDetailsService } from "./attachment-details.service";

@Component({
    selector: 'app-attachment-details',
    templateUrl: 'attachment-details.html',
    styleUrls: ['./attachment-details.scss']
})
export class AttachmentDetailsComponent extends AppComponent implements OnInit {

    @Input()
    public attachment: RequestAttachment;

    @Output()
    public onDeleted: EventEmitter<string>;

    public commands: ElipsisCommand[];

    constructor(private service: AttachmentDetailsService,
        private dialog: MatDialog,
        private events: EventService,
        private downloadHelper: DownloadHelper) {

        super();

        this.onDeleted = new EventEmitter<string>();
    }

    public ngOnInit() {

        this.commands = [
            {
                text: 'Download',
                icon: 'download-outline',
                onClick: async () => await this.download()
            }
        ];

        if (this.hasClaim(this.Claims.Attachments.CanAttachmentsDelete)) {
            this.commands.push({
                text: 'Delete',
                icon: 'trash-outline',
                onClick: async () => await this.delete()
            });
        }

    }

    public isImage() {
        return this.attachment?.fileType?.indexOf('image') > -1;
    }

    public getSize(input: number) {

        return (input / 1024) / 1024 < 1 ? `${(input / 1024).toFixed(2)} KB` : `${((input / 1024) / 1024).toFixed(2)} MB`;
    }


    public async download(): Promise<void> {

        if (window !== null && window !== undefined) {

            const url = await this.service.getAttachmentUrl(this.attachment);

            const blob = await this.service.downloadAttachmentBlob(this.attachment);

            this.downloadHelper.downloadBlob(url, this.attachment.fileName, blob);

            this.events.despatch(ShowToastMessage, { text: `Attachment downloaded.` });

        }

    }


    public async delete() {


        const confirmDelete = this.dialog.open(ConfirmationModalComponent, {
            data: {
                header: 'Delete attachment',
                text: `This will permanently delete the attachment '${this.attachment.fileName}' and cannot be undone. Are you sure you want to delete the attachment?`,
                confirmButton: 'Delete'
            }
        });

        this.subscribe(confirmDelete.afterClosed(), async confirm => {

            if (confirm === true) {

                try {

                    await this.service.deleteAttachment(this.attachment.attachmentId);

                    this.onDeleted.next(this.attachment.attachmentId);

                    this.events.actionComplete(`Attachment deleted.`);

                } catch (error) {

                    this.events.actionFailed(`Failed to delete attachment.`);

                }

            }

        });

    }

}