import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';

import { PipesModule } from './../../../components/src/pipes/pipes.module';
import { IconModule } from './../icon/icon.module';
import { BlockButtonModule } from './../block-button/block-button.module';
import { LoadingSpinnerModule } from './../loading-spinner/loading-spinner.module';

import { ClientMatterMenuService } from './client-matter-menu.service';
import { ClientMatterMenuComponent } from './client-matter-menu.component';
import { ClientMatterMenuSearchPipe } from './client-matter-menu-search-pipe/client-matter-menu-search.pipe';
import { AvatarModule } from '../avatar/avatar.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AvatarModule,
        ReactiveFormsModule,
        MatInputModule,
        MatMenuModule,
        MatButtonModule,
        MatTooltipModule,
        LoadingSpinnerModule,
        BlockButtonModule,
        MatListModule,
        IconModule,
        PipesModule
    ],
    exports: [
        ClientMatterMenuComponent
    ],
    declarations: [
        ClientMatterMenuComponent,
        ClientMatterMenuSearchPipe
      
    ],
    providers:[
        ClientMatterMenuService
    ]
})
export class ClientMatterMenuModule { }

export * from './client-matter-menu.component'