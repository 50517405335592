import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { GlobalSearchComponent } from './global-search.component';
import { GlobalSearchService } from './global-search.service';
import { IconModule } from '../icon/icon.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        LoadingSpinnerModule,
        FormsModule,
        RouterModule
    ],
    exports: [
        GlobalSearchComponent
    ],
    declarations: [
        GlobalSearchComponent
    ],
    providers: [
        GlobalSearchService
    ]
})
export class GlobalSearchModule { }

export * from './global-search.component';
