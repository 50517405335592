import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavigationListComponent } from './navigation-list.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        RouterModule
    ],
    exports: [
        NavigationListComponent
    ],
    declarations: [
        NavigationListComponent
    ]
})
export class NavigationListModule { }

export * from './navigation-list.component'