import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

import { ElipsisCommand } from '../ellipses-menu/ellipses-menu.component';

@Component({
    selector: 'app-expandable-card',
    templateUrl: 'expandable-card.html',
    styleUrls: ['./expandable-card.scss']
})
export class ExpandableCardComponent {

    @Output()
    public onDelete: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public title: string;

    @Input()
    public subtitle: string;

    @Input()
    public subtitlePosition: 'below' | 'right' = 'right';

    @Input()
    public subtitleHover: string;

    @Input()
    public iconStyle: string;

    @Input()
    public icon: string;

    @Input()
    public expanded: boolean = true;

    @Input()
    public canDelete: boolean = false;

    @Input()
    public commands: Array<ElipsisCommand>

    @Input()
    public template: TemplateRef<any>;

    @Input()
    public border: boolean = true;

    @Output()
    public onExpandChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public expandToggle(val: boolean) {

        this.expanded = val;

        this.onExpandChange.emit(this.expanded);
    }

}