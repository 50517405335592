<div>
    <h2 mat-dialog-title>Confirm</h2>
    <mat-dialog-content class="mat-typography">
        <div *ngIf="data.message" class="bg-red message">{{data.message}}</div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>Cancel</button>
        <button mat-button [mat-dialog-close]="shieldGuardForm.value" class="bg-red">Ok</button>
    </mat-dialog-actions>
</div>