<div [class.rounded]="rounded === true" class="progress-bar">
    <div [style.z-index]="complete < delta ? 9999:0" [style.width.%]="complete" [style.background-color]="color"
        class="progress">
        &nbsp;
    </div>
    <ng-container *ngIf="delta !== undefined">
        <div [style.width.%]="delta" class="progress delta">
            &nbsp;
        </div>
    </ng-container>
</div>