import { Component, Inject, Input, OnInit } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { BaseTenantService, EMPTY_GUID } from '@summize/shared/core';
import { EventService } from '@summize/shared/framework';
import { SelectBoxModel } from '@summize/shared/components-v2';

import { ChangeContractTypeService } from './change-contracttype-dialog.service';

export enum ChangeContractTypeState {
    Loading, SelectingMeta, Submitting
}

export interface ChangeContractTypeResult {
    contractTypeId: string;
}

@Component({
    selector: 'app-change-contracttype-dialog',
    templateUrl: 'change-contracttype-dialog.html',
    styleUrls: ['./change-contracttype-dialog.scss']
})
export class ChangeContractTypeDialogComponent extends BaseTenantService implements OnInit {

    @Input()
    public state: ChangeContractTypeState = ChangeContractTypeState.Loading;

    @Input()
    public contractTypes: Array<SelectBoxModel>;

    @Input()
    public selectedContractTypeId: string;

    public State = ChangeContractTypeState;

    public get isSaveDisabled() {

        return this.selectedContractTypeId === undefined || 
            this.selectedContractTypeId === EMPTY_GUID;

    }

    constructor(
        private dialogRef: MatDialogRef<ChangeContractTypeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private service: ChangeContractTypeService,
        private events: EventService) {

        super();

        this.selectedContractTypeId = data.selectedContractTypeId;

    }

    public async ngOnInit(): Promise<void> {

        const model
            = await this.service.getViewModel();

        this.contractTypes
            = model.contractTypes.map(t => ({ key: t.documentTypeId, value: t.name }));

        this.state
            = ChangeContractTypeState.SelectingMeta;

    }

    public setSelectedContractType(selectedContractTypeId: string): void {

        this.selectedContractTypeId = selectedContractTypeId;

    }

    public onClose() {

        this.dialogRef.close(undefined);

    }

    public async onSubmit(): Promise<void> {

        try {

            this.dialogRef.close({
                contractTypeId: this.selectedContractTypeId
            } as ChangeContractTypeResult);

        }
        catch(err) {

            console.log(err);

            this.events.actionFailed(`Change Contract Type error.`);

        }

    }

}
