<div *ngIf="requestStageMode === RequestStageMode.Linear" [matTooltip]="tooltip" [class.readonly]="readonly === true"
    class="stage-info" matTooltipClass="smz-tooltip-light" matTooltipPosition="below">
    <ng-container *ngIf="stage !== RequestStage.NotSet">
        <div *ngFor="let k of totalRequestStages">
            <div class="indicator"
                [style.background-color]="currentStage?.index === k ? currentStage?.color : inactiveColor">
                &nbsp;
            </div>
        </div>
        <div *ngIf="showTitle === true" class="display">
            {{getName(stage)}}
        </div>
    </ng-container>

    <app-icon *ngIf="showPadlock" icon="lock-closed-outline" class="locked"></app-icon>

    <ng-container *ngIf="stage === RequestStage.NotSet">
        <div class="display text-center">
            R/M
        </div>
    </ng-container>
</div>

<!-- 
    Ste:
    Template if its flexible mode -- This has to be seperate so the menu trigger can sit at the top, we could make it 
    work with a single template but the code would be complicated.
-->
<div *ngIf="requestStageMode === RequestStageMode.Flexible" [matMenuTriggerFor]="infoMenu"
    [class.readonly]="readonly === true" class="stage-info clickable">
    <div class="indicators">
        <ng-container>
            <div *ngFor="let k of totalRequestStages">
                <div class="indicator"
                    [style.background-color]="currentStage?.index === k ? currentStage?.color : inactiveColor">
                    &nbsp;
                </div>
            </div>

        </ng-container>
    </div>
    <div class="display">
        {{getName(stage)}}
    </div>

    <ng-container *ngIf="readonly === false">
        <div class="icon">
            <app-icon icon="chevron-down-outline"></app-icon>
        </div>
    </ng-container>

</div>

<mat-menu #infoMenu="matMenu" [hasBackdrop]="true" class="stage-select-popup-container">
    <ng-template matMenuContent let-row="row">
        <div class="stage-select-popup">
            <ng-container *ngFor="let item of indicators">
                <ng-container *ngIf="isRequestStatusAvailable(item.stage)">
                    <div (click)="item.disabled ? $event.stopPropagation() : OnStageChange.emit(item.stage)" class="item" 
                    [ngClass]="{'hover-enabled': !item.disabled, 'pointer-cursor': !item.disabled, 'not-allowed-cursor': item.disabled}"
                    [matTooltip]="item.disabled === true ? disabledTooltip : ''" matTooltipClass="smz-tooltip-light">
                        <div class="indicators">
                            <ng-container *ngFor="let i of totalRequestStages">
                                <div class="indicator" 
                                [style.background-color]="i === item.index ? item.color : inactiveColor">
                                    &nbsp;
                                </div>
                            </ng-container>
                        </div>
                        <div class="text" [ngClass]="{'disabled-text': item.disabled, 'enabled-text': !item.disabled}">
                            {{getName(item.stage)}}
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-template>
</mat-menu>