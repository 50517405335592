import { Component, ChangeDetectionStrategy, Input, TemplateRef } from '@angular/core';

import { FadeInAnimation } from '../animations/fade-in.animation';

@Component({
    selector: 'app-expandable-area',
    templateUrl: 'expandable-area.html',
    styleUrls: ['./expandable-area.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [FadeInAnimation]
})

export class ExpandableAreaComponent {

    @Input()
    public expanded: boolean;

    @Input()
    public title: string;

    @Input()
    public subtitle: string;

    @Input()
    public tooltip: string;

    @Input()
    public headerTemplate: TemplateRef<any>;

}