import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { QuillModule } from 'ngx-quill'

import { ConversationInputComponent } from './conversation-input.component';
import { ConversationInputService } from './conversation-input.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        QuillModule.forRoot()
    ],
    exports: [
        ConversationInputComponent
    ],
    declarations: [
        ConversationInputComponent
    ],
    providers: [
        ConversationInputService
    ]
})
export class ConversationInputModule { }

export * from './conversation-input.component'