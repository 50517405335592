import { Component, Input, OnInit } from '@angular/core';

export const defaultColors = [
    '#1abc9c',
    '#3498db',
    '#f1c40f',
    '#8e44ad',
    '#e74c3c',
    '#d35400',
    '#2c3e50',
    '#7f8c8d'
];

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.html',
    styleUrls: ['./avatar.scss']
})
export class AvatarComponent implements OnInit {

    @Input()
    public name: string;

    @Input()
    public size: string;

    @Input()
    public bgColor: string;

    public avatarColors: string[] = defaultColors;

    public avatarText: string;

    ngOnInit() {

        this.bgColor = this.bgColor || this.getRandomColor(this.name);

        this.avatarText = this.getAvatarText(this.name);

    }

    private getRandomColor(avatarText: string): string {

        if (!avatarText) {

            return 'transparent';

        }

        const asciiCodeSum = this.calculateAsciiCode(avatarText);

        return this.avatarColors[asciiCodeSum % this.avatarColors.length];

    }

    private calculateAsciiCode(value: string): number {

        return value
            .split('')
            .map(letter => letter.charCodeAt(0))
            .reduce((previous, current) => previous + current);

    }

    private getAvatarText(name: string): string {

        if (!name) {

            return '';
        }

        const nameSplit = this.name.trim().split(' ').filter(x => x.length > 0);

        const firstNameInitial = nameSplit[0]?.[0]?.toUpperCase() || '';

        const lastNameInitial = nameSplit[1]?.[0]?.toUpperCase() || '';

        return `${firstNameInitial}${lastNameInitial}`;

    }
}