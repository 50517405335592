import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConversationComponent } from './conversation.component';
import { ConversationInputModule } from '../conversation-input/conversation-input.module';
import { IconModule } from '../icon/icon.module';
import { ExpandableCardModule } from '../expandable-card/expandable-card.module';
import { ConversationService } from './conversation.service';
import { CorePipesModule, SmzDatePipe } from '../pipes/pipes.module';
import { AvatarModule } from '../avatar/avatar.module';

@NgModule({
    imports: [
        CommonModule,
        AvatarModule,
        IconModule,
        ConversationInputModule,
        ExpandableCardModule,
        CorePipesModule,
    ],
    exports: [
        ConversationComponent
    ],
    declarations: [
        ConversationComponent
    ],
    providers: [
        ConversationService,
        SmzDatePipe,
    ]
})
export class ConversationModule { }

export * from './conversation.component';
export * from './conversation.service';
