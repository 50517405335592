import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from 'rxjs/internal/Subject';
import { debounceTime } from 'rxjs/operators';

import { METRICS } from '../../analytics.contants';
import { Metric } from '../../analytics.types';
import { MetricBarService } from './metric-bar.service';

@Component({
    selector: 'app-metric-bar',
    templateUrl: 'metric-bar.html',
    styleUrls: ['./metric-bar.scss']
})

export class MetricBarComponent implements OnInit {

    @Input()
    public type: 'full' | 'compact' = 'full';

    @Input()
    public area!: 'Review' | 'Create' | 'Manage';

    @Input()
    public localMetrics: Array<Metric>;

    @Input()
    public set client(value: string | undefined) {

        this._client = value;

        this.$paramsChanged.next(undefined);

    }

    @Input()
    public set matter(value: string | undefined) {

        this._matter = value;

        this.$paramsChanged.next(undefined);

    }

    @Input()
    public set range(value: string) {

        this._range = value;

        this.$paramsChanged.next(undefined);

    }

    public get matter(): string | undefined {

        return this._matter;
    }

    public get client(): string | undefined {

        return this._client;

    }

    public get range(): string {

        return this._range || '12m';

    }

    public metrics: Array<Metric> | undefined;

    private _range!: string;

    private _client: string | undefined;

    private _matter: string | undefined;

    private $paramsChanged!: Subject<any>;

    constructor(private service: MetricBarService, private router: Router) {

        this.$paramsChanged = new Subject<any>();

        this.$paramsChanged.pipe(debounceTime(500)).subscribe(async term => {

            this.load();

        });

    }

    public ngOnInit() {

        if (this.localMetrics !== undefined) {

            this.metrics = this.localMetrics;

        }

    }

    public async load(): Promise<void> {

        if (this.localMetrics !== undefined) {

            this.metrics = this.localMetrics;

            return;
        }

        this.metrics = await this.getMetrics();

    }

    public goToAnalytics(): void {

        this.router.navigate(['/analytics/dashboard'], {
            queryParams: {
                fromModule: true
            }
        });

    }

    private getMetrics(): Promise<Metric[]> {

        if (this.area === 'Manage') {

            return this.service.getMetrics([{
                id: METRICS.ManageTotalContractsMetric,
                range: this.range,
                inverse: false,
                clientId: this.client,
                matterId: this.matter,
            }, {
                id: METRICS.ManageTotalRedFlagsMetric,
                range: this.range,
                inverse: true,
                clientId: this.client,
                matterId: this.matter,
            }, {
                id: METRICS.ManageTotalCalendarDates,
                range: this.range,
                inverse: false,
                clientId: this.client,
                matterId: this.matter
            }]);

        } else {

            return this.service.getMetrics([{
                id: METRICS.TotalContractsMetric,
                range: this.range,
                inverse: false,
                clientId: this.client,
                matterId: this.matter
            }, {
                id: METRICS.TotalPendingContractsMetric,
                range: this.range,
                inverse: false,
                clientId: this.client,
                matterId: this.matter,
            }, {
                id: METRICS.TotalCompleteContractsMetric,
                range: this.range,
                inverse: false,
                clientId: this.client,
                matterId: this.matter,
            }, {
                id: METRICS.AverageReviewTimeMetric,
                range: this.range,
                inverse: true,
                clientId: this.client,
                matterId: this.matter,
            }]);

        }

    }

}
