import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlockButtonComponent } from './block-button.component';
import { IconModule } from '../icon/icon.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

@NgModule({
    imports: [
        CommonModule,
        LoadingSpinnerModule,
        IconModule
    ],
    exports: [
        BlockButtonComponent
    ],
    declarations: [
        BlockButtonComponent
    ]
})
export class BlockButtonModule { }

export * from './block-button.component'