import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { ESignatureRecipient, ESignatureRecipientType, ESignatureRecipientOrigin, ESignatureRecipientLocationType } from '@summize/shared/framework';
import { EmailPattern } from '@summize/shared/core';

@Component({
    selector: 'app-esignature-email-recipients',
    templateUrl: 'esignature-email-recipients.html',
    styleUrls: ['./esignature-email-recipients.scss'],
})
export class EsignatureEmailRecipientsComponent {

    @Input()
    public recipients: ESignatureRecipient[] = [];

    @Input()
    public recipientType: ESignatureRecipientLocationType;

    @Input()
    public allowMultiple = false;

    @Input()
    public showCheckmark = true;

    @Output()
    public recipientsChanged: EventEmitter<ESignatureRecipient[]>;

    public recipientTypeEnum = ESignatureRecipientLocationType;

    private emailInputSubject = new Subject<{ recipient: any }>();

    constructor() {

        this.recipientsChanged = new EventEmitter<ESignatureRecipient[]>();
    }

    public ngOnInit() {

        if (this.recipients === undefined || this.recipients.length === 0) {

            this.recipients = [];

            this.addRecipient();
        }

        this.emailInputSubject
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
                switchMap(async ({ recipient }) => {

                    this.validateEmail(recipient);

                    this.recipientsChanged.emit(this.recipients);
                })
            )
            .subscribe();

    }

    public addRecipient() {

        this.recipients.push({
            name: '',
            email: '',
            tokenId: '',
            signOrder: this.recipientType === ESignatureRecipientLocationType.Internal ? 2 : 1,
            recipientType: ESignatureRecipientType.To,
            recipientOrigin: this.recipientType === ESignatureRecipientLocationType.Internal ?  ESignatureRecipientOrigin.Internal : ESignatureRecipientOrigin.Counterparty,
            visible: true
        });

        this.recipientsChanged.emit(this.recipients);

    }

    public removeRecipient(index: number) {

        this.recipients.splice(index, 1);

        this.recipientsChanged.emit(this.recipients);

    }

    public onKeyUp(recipient: any): void {

        this.emailInputSubject.next({ recipient });
    }

    private validateEmail(recipient: any) {

        recipient.emailInvalid = !EmailPattern.test(recipient.email);

        const exists = this.recipients.filter(x => x.email.toLocaleLowerCase() === recipient.email.toLocaleLowerCase());

        if (exists?.length > 1) {

            recipient.emailExists = true;

        } else {

            recipient.emailExists = false;
        }

    }

}