<div class="smz-flex-area" [@fadeInAnimation]>
    <div class="context-bar fade-block {{type}}" [class.has-template]="template !== undefined">

        <div class="context fade-block">
            <div *ngIf="hasBackButton === true" class="back-container clickable" (click)="onBack.emit()">
                <app-icon [icon]="backIcon"></app-icon>
            </div>
            <div class="info">

                <div *ngIf="primaryTemplate === undefined" class="primary">
                    {{primaryText}} <div class="icon-container" *ngIf="hasIntro === true" (click)="onIntroClick.emit()">
                        <app-icon class="clickable" icon="help-circle-outline" tooltip="View introduction"></app-icon>
                    </div>
                </div>
                <div *ngIf="primaryTemplate !== undefined" class="primary">
                    <ng-container *ngTemplateOutlet="primaryTemplate"></ng-container>
                    <div class="icon-container" *ngIf="hasIntro === true" (click)="onIntroClick.emit()">
                        <app-icon class="clickable" icon="help-circle-outline" tooltip="View introduction"></app-icon>
                    </div>
                </div>

                <div *ngIf="secondaryTemplate === undefined" class="secondary">
                    {{secondaryText}}
                </div>
                <div *ngIf="secondaryTemplate !== undefined" class="secondary">
                    <ng-container *ngTemplateOutlet="secondaryTemplate"></ng-container>
                </div>
            </div>
        </div>

        <ng-container *ngIf="centerTemplate !== undefined">
            <div [@slideUpAnimation] class="center">
                <div class="template block center">
                    <ng-container *ngTemplateOutlet="centerTemplate"></ng-container>
                </div>
            </div>
        </ng-container>

        <div [@slideUpAnimation] class="right">
            <div *ngIf="template !== undefined" class="template block">
                <ng-container *ngTemplateOutlet="template"></ng-container>
            </div>
        </div>

    </div>
</div>