import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { AuthOidcService } from './auth-oidc.service';
import { AuthServiceBase, LoginValues } from './auth-service.base';

@Injectable({ providedIn: 'root' })
export class AuthService implements AuthServiceBase {
  private readonly _isAuthenticated = new BehaviorSubject<boolean>(false);

  public isAuthenticated$ = this._isAuthenticated.asObservable();

  private authService: AuthServiceBase;
  private sub: Subscription = new Subscription();

  constructor(
    private authOidcService: AuthOidcService,
  ) {

    this.authService = this.authOidcService;

    this.sub.add(
      this.authService.isAuthenticated$.subscribe(isAuthenticated => {
        this._isAuthenticated.next(isAuthenticated);
      })
    );

  }

  public async init() {
    this.authService.init();
  }

  public async isAuthenticated() {
    return await this.authService.isAuthenticated();
  }

  public async login(values?: LoginValues) {
    await this.authService.login(values);
  }

  public async logout() {
    await this.authService.logout();
  }
}
