import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseTenantService } from '@summize/shared/core';

@Injectable({ providedIn: 'root' })
export class ConversationInputService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getEntities(documentId: string) {

        const baseUrl = this.getUserBaseUrl(true);

        const results: any = await this.http.get(`${baseUrl}conversation/${documentId}/entities`).toPromise();

        const clauses = (results.clauses || []).map(r => ({
            key: r.clauseId.toString(),
            value: `Clause: ${r.ruleName}`,
            menuItem: r.ruleName,
            type: 'clause',
            id: `clause:${r.clauseId.toString()}`,
            ...r
        }));

        var tasks = results.tasks.map(r => ({
            key: r.task.taskDescription,
            value: `Task: ${r.task.taskTitle}`,
            menuItem: r.task.taskTitle,
            type: 'task',
            id: `task:${r.task.taskInstanceId}`,
            ...r.task
        }));

        var attachments = results.attachments.map(a => ({
            key: a.attachmentId,
            value: `Attachment: ${a.fileName}`,
            menuItem: a.fileName,
            type: 'attachment',
            id: `attachment:${a.attachmentId}`,
            ...a
        }));

        return {
            clauses, tasks, attachments
        }
    }

    public async getTasks(documentId: string) {

        const baseUrl = this.getUserBaseUrl(true);

        var results = await this.http.get<any>(`${baseUrl}tasks/document/${documentId}`).toPromise();

        return results.map(r => ({
            key: r.task.taskDescription,
            value: `Task: ${r.task.taskTitle}`,
            menuItem: r.task.taskTitle,
            type: 'task',
            id: `task:${r.task.taskInstanceId}`,
            ...r.task
        }));

    }

    public async getClauses(documentId: string): Promise<any> {

        const url = `${this.getUserBaseUrl(true)}review/clauses/summary/${documentId}`

        const results = await this.http.get(url).toPromise() as any;

        return results.map(r => ({
            key: r.clauseId.toString(),
            value: `Clause: ${r.ruleName}`,
            menuItem: r.ruleName,
            type: 'clause',
            id: `clause:${r.clauseId.toString()}`,
            ...r.task
        }));

    }
}