<app-dialog-shell title="Save view" saveText="Save view" [showHeader]="true" [showFooter]="true"
    [saveDisabled]="model.name === undefined || model.name === ''" (onClose)="close()" (onSave)="save()">
    <div class="section-area">

        <div *ngIf="isSaveOrEdit === true" class="section-content">
            <mat-radio-group [(ngModel)]="saveAsNew" class="radio-control flex-column">
                <mat-radio-button class="radio-text" [value]="false">
                    Save and update '{{model.name}}'
                </mat-radio-button>
                <mat-radio-button class="radio-text mt-3" [value]="true">
                    Save as new view
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="section-content">
            <div class="title">
                Name
            </div>
            <input class="smz-standard-input" [(ngModel)]="model.name" />
        </div>
        <div class="section-content">
            <div class="field">
                <input type="checkbox" [(ngModel)]="model.default" /> Make this my default view
            </div>
        </div>
    </div>
</app-dialog-shell>