<div class="editabletext" [class.editabletext-iseditable]="isEditable" [class.focus-border]="isEditing">
  <!--
    This might seem weird, but making it contenteditable means we can capture the click
    and show the textarea. We originally just used contenteditable to edit, but you can't
    easily (across all browsers) only support plain-text - it posts HTML formatting too
    [this is true as of 2021.04.14, and https://caniuse.com/?search=plaintext-only].
  -->
  <span [contentEditable]="isEditable" spellcheck="false" [class.ishidden]="isEditing" class="editabletext-display"
    (focus)="onFocus()" [innerHTML]="text"></span>
  <textarea #textElement type="text" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
    class="editabletext-editable" [class.isediting]="isEditing" [value]="text" (blur)="onBlur()"
    (input)="updateText()" rows="1" wrap="soft"></textarea>
</div>
