<ng-container *ngIf="status !== undefined">
    <div class="status-info">
        <ng-container>
            <div class="indicators">
                <ng-container>
                    <div class="indicator" [style.background-color]="status.background">
                        &nbsp;
                    </div>
                </ng-container>
            </div>
            <div class="display">
                {{status.display}}
            </div>
        </ng-container>
    </div>
</ng-container>