<div class="smz-flex-area metric-container {{type}} fixed-height" [class.loading]="metrics === undefined">

    <ng-container *ngIf="metrics !== undefined && type === 'full'">
        <div *ngFor="let metric of metrics" class="metric-host">
            <app-metric [metric]="metric" [type]="type"></app-metric>
        </div>
    </ng-container>

    <ng-container *ngIf="metrics !== undefined && type === 'compact'">
        <div *ngFor="let metric of metrics" class="metric-host">
            <app-metric [metric]="metric" [type]="type"></app-metric>
        </div>
        <div>
            <app-block-button (click)="goToAnalytics()" icon="analytics-outline" display="See All Analytics" type="v2"
                width="150">
            </app-block-button>
        </div>
    </ng-container>

    <ng-container *ngIf="metrics === undefined">
        <div>
            <app-loading-spinner type="pale" visible="true"></app-loading-spinner>
        </div>
    </ng-container>

</div>