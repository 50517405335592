import { Component, Input, ElementRef, EventEmitter, Output, ViewChild, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-editable-text',
  templateUrl: 'editable-text.html',
  styleUrls: ['./editable-text.scss']
})

export class EditableTextComponent implements AfterViewInit {

  @ViewChild('textElement')
  public textElement: ElementRef;

  @Input('IsEditable')
  public isEditable: boolean;

  @Input('IsFinishOnEnter')
  public isFinishOnEnter: boolean;

  @Input()
  public text = '';

  @Output()
  public onTextChanged: EventEmitter<string> = new EventEmitter<string>();

  public isEditing: boolean = false;

  public ngAfterViewInit() {

    setTimeout(() => this.onKeyPress(), 0);

  }

  public updateText() {

    this.text = this.textElement.nativeElement.value;

  }

  public onFocus() {
    if (this.isEditable) {
      this.isEditing = true;
      setTimeout(() =>
        this.textElement.nativeElement.focus()
        , 100);

    }
  }

  public onBlur() {

    this.onTextChanged.emit(this.text);
    this.isEditing = false;

  }

  private onKeyPress() {

    this.textElement.nativeElement.addEventListener('keydown', (evt: any) => {

      if (evt.keyCode === 13 && this.isFinishOnEnter) {

        evt.preventDefault();
        this.textElement.nativeElement.blur();

      }

    });

  }

}
