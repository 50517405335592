import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export enum PillType {
  Standard = 0,
  Error = 1,
  Flagged = 2,
  Status = 3,
  Primary = 4,
  Filtered = 5
}

@Component({
  selector: 'app-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss']
})
export class PillComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() active: boolean;
  @Input() label: string;
  @Input() icon: string;
  @Input() style = '';
  @Input('type') pillType: PillType = PillType.Error;
  @Input() badgeNumber: number = 0;
  @Input() tooltip: string;

  @Output() public click: EventEmitter<string> = new EventEmitter();

  pillTypes = PillType;

  ngOnInit() {
    this.disabled = this.disabled !== undefined && this.disabled !== false;
    this.active = this.active !== undefined && this.active !== false;
  }

  onIconClick(event: MouseEvent) {
    event.stopPropagation();
    if (this.disabled || !this.active) {
      return;
    }
    this.click.emit(this.label);
  }

}
