import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { get } from 'lodash-es';

import { BaseTenantService } from './base-tenant.service';

@Injectable({ providedIn: 'root' })
export class GlobalContextService {

    public $change: Subject<any> = new Subject();

    protected state: any;

    public set(state: any, silent: boolean = false) {

        this.debug(`Setting Context: ${JSON.stringify(state)}, silent: ${silent}`);

        this.state = {
            ...this.state,
            ...state
        };

        if (silent === false) {

            this.$change.next(this.state);

        }

    }

    public get<T>(key: string): T {

        this.debug(`Getting Context by key: ${key}`);

        return get(this.state, key);

    }

    public clear(key: string): void {

        this.debug(`Clearing Context by key: ${key}`);

        if (this.state !== undefined) {

            this.state[key] = undefined;

        }

    }

    public getClientAndMatter(): { clientId: string, matterId: string } {

        return {
            clientId: this.get('clientId'),
            matterId: this.get('matterId'),
        }

    }

    private debug(str: string): void {

        if (BaseTenantService.environment.production === false) {

            console.log(`Summize -- ${str}`);

        }

    }

}