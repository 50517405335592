import { Injector } from "@angular/core";

import { TableProvider } from "./table-provider";

export class RequestedByGroupTable extends TableProvider {

    constructor(injector: Injector) {

        super(injector);

    }

    public getPrimary(): string {

        return `Review analytics`;

    }

    public getSecondary(range: string): string {

        return `SLA performance by groups over the last ${range}`;

    }

    public buildDataSource(): any {

        return {
            headers: [
                { key: 'groupName', display: 'Group Name' },
                { key: 'totalContracts', display: 'Total Contracts' },
                { key: 'pendingContracts', display: 'Pending Contracts' },
                { key: 'completeContracts', display: 'Complete Contracts' },
                { key: 'averageSLA', display: 'Average SLA (Days)' },
            ],
            default: {
                sort: {
                    field: 'averageSLA',
                    dir: 'desc'
                }
            }
        }
    }

}