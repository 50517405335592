import { Component } from '@angular/core';

import { AppComponent } from '@summize/shared/core';
import { RequestSubtype, TaskDefinitionModel } from '@summize/shared/framework';

import { DocumentTaskService } from './document-tasks.service';

export enum TaskStatus {
    Pending,
    Complete
}

@Component({
    selector: 'app-document-tasks',
    templateUrl: 'document-tasks.html',
    styleUrls: ['./document-tasks.scss']
})
export class DocumentTasksComponent extends AppComponent {

    public TaskStatus = TaskStatus;

    public documentId: string;

    public documentName: string;

    public isLoading: boolean = true;

    public type: RequestSubtype;

    public tasks: Array<TaskDefinitionModel>;

    public buckets: {
        0: Array<TaskDefinitionModel>,
        1: Array<TaskDefinitionModel>
    } = { 0: [], 1: [] }

    public selectedStatus:
        TaskStatus = TaskStatus.Pending;

    constructor(private service: DocumentTaskService) {

        super();

    }

    public async init(): Promise<void> {

        this.isLoading = true;

        this.tasks = await this.service.getTaskInstances(this.documentId);

        this.buckets[TaskStatus.Pending]
            = this.tasks.filter(t => t.task.isComplete === false);

        this.buckets[TaskStatus.Complete]
            = this.tasks.filter(t => t.task.isComplete === true);

        this.isLoading = false;
    }

    public getContractLink(): string {

        const type = RequestSubtype[this.type];

        return `/quick-summary/contract/${type.toLocaleLowerCase()}/${this.documentId}`;

    }

}