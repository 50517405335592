<div>

    <app-context-bar (onBack)="onBack()" type="v2" [hasBackButton]="true" primaryText="Browse available charts">
    </app-context-bar>

    <div class="tab-container">

        <div class="tabs-header">
            <div class="tabs">
                <div (click)="setSelectedTab(SelectedWidgetCategoryTab.Performance)" class="tab clickable"
                    [class.selected]="selectedTab === SelectedWidgetCategoryTab.Performance">
                    Performance
                </div>
                <div (click)="setSelectedTab(SelectedWidgetCategoryTab.Volume)" class="tab clickable"
                    [class.selected]="selectedTab === SelectedWidgetCategoryTab.Volume">
                    Volume
                </div>
                <div (click)="setSelectedTab(SelectedWidgetCategoryTab.Pending)" class="tab clickable"
                    [class.selected]="selectedTab === SelectedWidgetCategoryTab.Pending">
                    Pending
                </div>
            </div>
        </div>
        <div class="tab-content">

            <ng-container *ngIf="isLoading === true">
                <app-loading-spinner [visible]="true" [container]="true"></app-loading-spinner>
            </ng-container>

            <ng-container *ngIf="isLoading === false">

                <div class="info">
                    <app-icon [icon]="'bulb-outline'"></app-icon>
                    <span>All chart previews in this library are based on sample data only. Chart previews do not
                        reflect your actual data. To learn more about Request Analytics please visit the
                        <a class="chub" href="https://customers.summize.com/hub/request-analytics"
                            target="_blank">Customer Hub</a>.</span>
                </div>

                <ng-container *ngIf="widgets !== undefined && widgets.length > 0">
                    <div *ngFor="let widget of widgets" class="widget-picker">
                        <div class="flex-column description">
                            <div>
                                <h2>{{widget.title}}</h2>
                                <div class="widget-description" [innerHTML]="widget.description"></div>
                            </div>
                            <div>
                                <app-block-button (click)="addToDashboard(widget.id)" display="Add to dashboard"
                                    type="v2-primary" width="180" class="mr-2" icon="add-circle-outline">
                                </app-block-button>
                            </div>

                        </div>
                        <div class="chart">

                            <app-widget-host [widget]="widget" [showFilters]="false" [showCommands]="false"
                                [showSampleData]="true" (onDeleteWidget)="onDeleteWidget(i)"></app-widget-host>

                        </div>
                    </div>
                </ng-container>

                <div *ngIf="widgets?.length === 0" class="flex-column flex-align-center no-widgets">

                    <h2>No widgets exist for this category</h2>

                    <span>We don't currently have any widgets for this category.</span>

                    <span>Please choose another category using the tabs above</span>

                </div>
            </ng-container>

        </div>

    </div>
</div>