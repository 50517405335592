export const METRICS = {
    'TotalContractsMetric': 'TotalContractsMetric',
    'TotalCompleteContractsMetric': 'TotalCompleteContractsMetric',
    'TotalPendingContractsMetric': 'TotalPendingContractsMetric',
    'AverageReviewTimeMetric': 'AverageReviewTimeMetric',

    'ManageTotalContractsMetric': 'ManageTotalContractsMetric',
    'ManageTotalRedFlagsMetric': 'ManageTotalRedFlagsMetric',
    'ManageTotalCalendarDates': 'ManageTotalCalendarDates'
}

export const TABLES = {
    'AssignedToTable': 'AssignedToTable',
    'AssignedToGroupTable': 'AssignedToGroupTable',
    'RequestedByGroupTable': 'RequestedByGroupTable',
    'ContractStatusTable': 'ContractStatusTable'
}
