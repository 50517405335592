<div [@fadeInAnimation] class="user-bar">
    <div *ngIf="avatar === true" class="avatar fade-block">
        <app-avatar class="fade-block" size="36" [name]="model.userName"></app-avatar>
    </div>
    <div class="bar-content fade-block">
        <div class="top-content">
            <div class="name">
                {{model.userName}}
            </div>
            <div class="total">
                {{model.total}} {{model.totalLabel}}
            </div>
        </div>
        <div class="progress">
            <app-progress-bar [complete]="model.completePercentage"></app-progress-bar>
        </div>
        <div class="bottom-content">
            <div>{{model.subtitle}}</div>
            <div>{{model.remaining}} {{model.remainingLabel}}</div>
        </div>
    </div>
</div>