import { Pipe, PipeTransform } from '@angular/core';
import { TextUtil } from '@summize/shared/core';

@Pipe({
  name: 'takewords'
})
export class TakeWordsPipe implements PipeTransform {

  transform(value: string, take: number): string {

    return TextUtil.takeWords(value, take);

  }

}
