import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'app-kpi',
    templateUrl: 'kpi.html',
    styleUrl: './kpi.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KpiComponent {

    @Input()
    public tileColor = '#6f42c1';

    @Input()
    public topLabel = 'KPI';

    @Input()
    public bottomLabel = 'N/A';
}