import { Component, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { SummizeStorage } from '@summize/shared/framework';

@Component({
    selector: 'app-side-bar',
    templateUrl: 'side-bar.html',
    styleUrls: ['./side-bar.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideBarComponent {

    @Input()
    public expanded: boolean = false;

    @Output()
    public expandedChanged: EventEmitter<boolean>;

    public showInsightQuestions: boolean = false;

    public showRequests: boolean = false;

    private KEY = 'summize-admin:menu-expanded';

    constructor() {

        this.expandedChanged = new EventEmitter<boolean>();

    }

    public async ngOnInit(): Promise<void> {

        this.expanded = (JSON.parse(SummizeStorage.getLocalItem(this.KEY))) || false;

        this.expandedChanged.emit(this.expanded);

    }

    public toggle(): void {

        this.expanded = !this.expanded

        SummizeStorage.setLocalItem(this.KEY, JSON.stringify(this.expanded));

        this.expandedChanged.emit(this.expanded);

    }

}