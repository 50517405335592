import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-search-bar',
    templateUrl: 'search-bar.html',
    styleUrls: ['./search-bar.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SearchBarComponent {

    @Input()
    public query: string;

    @Input()
    public placeholder: string = 'Search ...';

    // If this is used in something like a Mat-Select, they use cursor+space to select the item. 
    // Which conflicts with typing text. So allow stopPropagation so that the select box doesn't 
    // see it if the input has focus.
    @Input()
    public stopPropagation: boolean = false;

    @Output()
    public onSearch: EventEmitter<string>;

    @Output()
    public onClearSearch: EventEmitter<void>;

    private debouncer: Subject<string> = new Subject<string>();

    constructor() {

        this.onSearch = new EventEmitter<string>();

        this.onClearSearch = new EventEmitter<void>();

        this.debouncer
            .pipe(debounceTime(100))
            .subscribe((val) => this.onSearch.emit(val));
    }

    public search(event: { target: { value: string } }): void {

        this.query = event.target.value;

        if (this.query && this.query.length > 0) {

            this.debouncer.next(this.query);
            
        } else {

            this.clearSearch();

        }

    }

    public clearSearch(): void {

        this.query = undefined;

        this.onClearSearch.emit();
        
    }

    public keydown(event) {

        if (this.stopPropagation === true) {

            event.stopPropagation();

        }

    }

}