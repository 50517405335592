import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { DirectivesModule } from '@summize/shared/components-v2';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ToolPanelComponent } from './tool-panel.component';
import { IconModule } from '../icon/icon.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { ToolPanelService } from './tool-panel.service';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IconModule,
        LoadingSpinnerModule,
        DirectivesModule,
        MatTooltipModule
    ],
    exports: [
        ToolPanelComponent
    ],
    declarations: [
        ToolPanelComponent
    ],
    providers: [
        ToolPanelService
    ]
})
export class ToolPanelModule { }

export * from './tool-panel.component';
export * from './base-tool-panel';