import { Injectable } from '@angular/core';

import { SlidePanelService, SlidePanelSettings, UserTasksComponent } from '@summize/shared/components-v2';
import { RequestSubtype, TaskDefinitionModel } from '@summize/shared/framework';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserTasksHelper {

    constructor(private slidePanel: SlidePanelService) { }

    public show(requestSubType: RequestSubtype = RequestSubtype.Review): Subject<TaskDefinitionModel> {

        const subject = new Subject<TaskDefinitionModel>();

        const settings: SlidePanelSettings = {
            component: UserTasksComponent,
            arguments: {
                requestSubType
            },
            title: undefined,
            closeable: true,
            size: 'xl',
            backdrop: true,
            managed: true,
        };

        this.slidePanel.show(settings);

        return subject;
    }

}