<div *ngIf="(oidcAuthService.isAuthenticated$ | async) === false" class="container">
    <div class="auth">
        <a *ngIf="idps?.length > 0" class="auth-clear body__regular" (click)="idps = []">&lt; Back</a>
        <div class="auth-header">
            <img class="logo-img" src="/assets/logos/logo-s.svg" />
            <h3 class="headline__small">Sign In</h3>
        </div>

        <div class="smz-form-control required login-email">
            <p>Email</p>
            <input required placeholder="Email" [disabled]="idps?.length > 0" [(ngModel)]="email" minlength="1"
                class="insert body__small" type="email" name="email" (keydown.enter)="onKeyDown($event)" />
        </div>

        <smz-block-button *ngIf="idps?.length === 0" class="login-button" [disabled]="!email" [width]="0"
            display="Continue" (click)="login()">
        </smz-block-button>

        <smz-block-button *ngFor="let idp of idps" class="login-button" [width]="0" [display]="idp.displayName"
            (click)="loginIdP(idp)">
        </smz-block-button>

        <div style="color: red">{{errorMessage}}</div>

        <div class="body__xxsmall login-help"><a href="mailto:support@summize.com?subject=Need Help Signing In">Need
                help
                signing
                in?</a></div>
    </div>
</div>