export class ChangeDetectionHelper {

    public static doNextCycle(func: Function, timeout: number = 0): void {

        setTimeout(() => {

            func();

        }, timeout);

    }


    public static whenElementByIdExists(id: string): Promise<HTMLElement> {

        const element = document.getElementById(id);

        return element === null ?
            ChangeDetectionHelper.onNextAnimationFrame().then(() => this.whenElementByIdExists(id)) :
            Promise.resolve(element);

    }

    public static onNextAnimationFrame(): Promise<any> {

        return new Promise(resolve => {
            requestAnimationFrame(resolve);
        });

    }
}