<div *ngIf="loading === true" class="loading-container">
    <app-loading-spinner visible="true"></app-loading-spinner>
</div>

<div *ngIf="loading === false && hint === true" class="hint">
    <div class="icon-container">
        <i class="icon icon-info"></i>
    </div>
    Something not right? See the <span class="clickable" (click)="onClose.emit()">plain text</span> instead.
</div>

<div class="pdf-host" [class.relative]="useRelativeHeight === true">
    <div #viewer class="viewer"></div>
</div>