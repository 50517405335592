import { Component, ChangeDetectionStrategy, Input, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-text-field',
    templateUrl: 'text-field.html',
    styleUrls: ['./text-field.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TextFieldComponent {

    @ViewChild('textFieldInput')
    public inputElement: ElementRef;

    @Input('required')
    public required: boolean;

    @Input()
    public label = '';

    @Input()
    public type = 'text';

    @Input()
    public minRows = 1;

    @Input()
    public autofocus = false;

    @Input()
    public readonly = false;

    @Input()
    public value = '';

    @Input()
    public suffix: string;

    @Output()
    public valueChange: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    public onEnter: EventEmitter<string> = new EventEmitter<string>();

    public textFieldControl: FormControl = new FormControl('');

    public errorMessage = 'Error';

    public ngOnInit(): void {

        this.autofocus = (this.autofocus !== undefined && this.autofocus !== false)

        this.required
            = this.required !== undefined;

        const validators = [];

        if (this.required) {

            validators.push(Validators.required);

        }

        if (this.type === 'email') {

            validators.push(Validators.email);
        }

        if (this.type === 'password') {

            validators.push(Validators.minLength(8));
        }

        this.textFieldControl.setValidators(validators);

        this.textFieldControl.markAsDirty();

    }

    public ngAfterViewInit() {

        if (this.autofocus) {

            setTimeout(() => this.inputElement.nativeElement.focus(), 0);

        }
    }

    public onKeydown(event: any): void {

        if (event.key === 'Enter') {

            this.onEnter.emit(this.value);

        }

    }

    public onMouseDown(event: any): void {

        event.stopPropagation();

    }
}