import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IconModule } from '../icon/icon.module';
import { NoteInputComponent } from './note-input.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IconModule
    ],
    exports: [
        NoteInputComponent
    ],
    declarations: [
        NoteInputComponent
    ]
})
export class NoteInputModule { }