export interface CustomFieldDefinition {
    id?: string;
    name: string;
    fieldType: CustomFieldType;
    targetContractTypes: any;
    values?: any;
    description?: string;
    targetContractTypesDisplay?: string;
    fieldTypeDisplay?: string;
    valueDisplay?: any;
};

export enum CustomFieldType {
    Dropdown,
    MultiSelect,
    FreeText,
    Numeric,
    Datepicker
};

export const FieldTypeNameMap = {
    0: 'Dropdown',
    1: 'Dropdown multi-select',
    2: 'Free text',
    3: 'Numeric',
    4: 'Date picker'
};

export interface CustomFieldValue {
    customFieldValueId: string;
    customFieldDefinitionId: string;
    documentId: string;
    tenantId: string;
    departmentId: string;
    value: string;
    createdBy: string;
}