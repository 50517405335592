import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-progress-modal',
    templateUrl: 'progress-modal.html',
    styleUrl: './progress-modal.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressModalComponent {

    public header: string;

    public text: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ProgressModalComponent>) {

        const _this: any = this;

        Object.keys(data).forEach((k: any) => _this[k] = data[k]);

    }

    public close() {

        this.dialogRef.close(undefined);

    }
    
}