import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadingSpinnerModule } from '@summize/shared/components';
import { DialogShellModule } from '../dialog-shell/dialog-shell.module';

import { ProgressModalComponent } from './progress-modal.component';

@NgModule({
    imports: [
        CommonModule,
        DialogShellModule,
        LoadingSpinnerModule
    ],
    exports: [
        ProgressModalComponent
    ],
    declarations: [
        ProgressModalComponent
    ]
})
export class ProgressModalModule { }

export * from './progress-modal.component'