import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-dashboard-intro-modal',
    templateUrl: 'dashboard-intro-modal.html',
    styleUrls: ['./dashboard-intro-modal.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DashboardIntroModalComponent {

    public pageNum: number = 1;

    public maxPageNum: number = 4;

    public showAgain: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DashboardIntroModalComponent>) {

    }

    public close() {

        this.dialogRef.close(undefined);

    }

    public finish() {

        this.dialogRef.close({ showAgain: this.showAgain });

    }

    public back() {

        this.pageNum -= 1;

        this.pageNum = Math.max(1, Math.min(this.pageNum, this.maxPageNum));

    }

    public next() {

        this.pageNum += 1;

        this.pageNum = Math.max(1, Math.min(this.pageNum, this.maxPageNum));

    }

    public nextLabel() {

        return `Next (${this.pageNum}/${this.maxPageNum})`;

    }

}