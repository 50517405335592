import { Component, Input } from '@angular/core';

export enum ListItemType {
    AVATAR,
    PLAIN_TEXT
}

export interface ListItem {
    type: ListItemType;
    name: string;
    link?: string;
}

@Component({
    selector: 'app-list-item',
    templateUrl: './list-item.html',
    styleUrls: ['./list-item.scss']
})
export class ListItemComponent {

    @Input()
    public listItem: ListItem;

    public listItemTypes = ListItemType;
}