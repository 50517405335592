<div class="upload-contract-dialog">

  <div class="dialog-shell-container">
      <div *ngIf="showHeader === true" class="header">
          <div *ngIf="headerTemplate !== undefined">
              <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
          </div>

          <div *ngIf="title !== undefined" class="title">
              {{title}}
          </div>
          <app-icon *ngIf="showClose === true" (click)="onClose.next()" class="clickable" icon="close-outline"></app-icon>
      </div>
      <div class="body">
          <ng-content></ng-content>
      </div>
      <div *ngIf="showFooter === true" class="footer">
          <div *ngIf="showBack === true" class="back-button">
              <app-block-button (click)="onBack.next()" [display]="backText" type="outline" width="100" data-cy="modal-back" [disabled]="backDisabled">
              </app-block-button>
          </div>

          <app-block-button (click)="onClose.next()" [display]="cancelText" type="outline" width="100" data-cy="modal-cancel">
          </app-block-button>

          <app-block-button (click)="onSave.next()" [width]="saveButtonWidth" [disabled]="saveDisabled" [display]="saveText"
              [colorClass]="saveColorClass" data-cy="modal-confirm">
          </app-block-button>
        </div>

      </div>

</div>
