

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';

import { FadeInAnimation, SlideUpAnimation } from '@summize/shared/framework';
import { CreatedUsersResponse } from '@summize/feature/user-mgmt/detail-views/create-user/created-users-response';

interface User {
    firstName: string;
    lastName: string;
    email: string;
}

@Component({
    selector: 'app-user-add-bulk-panel',
    templateUrl: 'user-add-bulk-panel.html',
    styleUrls: ['./user-add-bulk-panel.scss'],
    animations: [
        FadeInAnimation, SlideUpAnimation
    ]
})
export class UserAddBulkPanelComponent implements OnInit {

    @Output()
    public usersChanged: EventEmitter<Array<User>>;

    public usersForm: FormGroup;

    public separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON];

    public bulkEmailsCtrl = new FormControl();

    public bulkEmails: string[] = [];

    public bulkPlaceholderText = `Enter or paste list here, separated by commas or semi-colons e.g. user.one@email.com, user.two@email.com`;

    public isBulkView = false;

    public users: Array<User> = [];

    private outlookFormatRegex = /(\w+)\s+(.*)[<]([^>]+)[>]/;

    constructor(private formBuilder: FormBuilder) {

        this.usersChanged = new EventEmitter<Array<User>>();

    }

    public ngOnInit() {

        this.usersForm = this.formBuilder.group({
            users: this.formBuilder.array([this.addUserFormGroup()])
        });

    }

    public addToChips(event: any): void {
        if (event.keyCode === SEMICOLON || event.keyCode === ENTER || event.keyCode === COMMA) {

            const emails = (event.target.value || '').split(/[,;\s]/);

            emails.forEach(email => {

                email = email.trim();

                if (email.length > 1) {

                    this.bulkEmails.push(email);
                }

            });

            // Clear the input value
            this.bulkEmailsCtrl.reset();

        }

    }

    public removeFromChips(email: string): void {
        const index = this.bulkEmails.indexOf(email);

        if (index >= 0) {
            this.bulkEmails.splice(index, 1);
        }
    }

    public addUserFormGroup(firstName?: string, lastName?: string, email?: string): FormGroup {

        if (this.users.find(x => x.email === email) === undefined) {

            this.users.push({ firstName, lastName, email });

            this.usersChanged.next((<FormArray>this.usersForm?.get("users"))?.value);

        }


        return this.formBuilder.group({
            firstName: [firstName, Validators.required],
            lastName: [lastName, Validators.required],
            email: [email, [Validators.required, Validators.email]],
        });
    }

    public addUserRow(): void {
        (<FormArray>this.usersForm.get("users")).push(
            this.addUserFormGroup()
        );
    }

    public removeUserRow(i: number) {

        if ((<FormArray>this.usersForm.get("users")).length > 1) {

            (<FormArray>this.usersForm.get("users")).removeAt(i);

            this.usersChanged.next((<FormArray>this.usersForm.get("users")).value);

        }

    }

    public removeBlankRows(): void {

        if ((<FormArray>this.usersForm.get("users")).length > 0) {

            for (let index = 0; index <= (<FormArray>this.usersForm.get("users")).length; index++) {

                const userRow = (<FormArray>this.usersForm.get("users")).controls[index]?.value;

                if (userRow !== undefined &&
                    (userRow.firstName === null && userRow.lastName === null || userRow.email === null)) {

                    (<FormArray>this.usersForm.get("users")).removeAt(index);

                }
            }

        }

    }

    public addBulkUsers() {

        //check textarea
        const entry = (this.bulkEmailsCtrl.value || '').trim().replace(';', '').replace(',', '');

        if (entry.length > 0) {
            this.bulkEmails.push(entry);
        }

        const entries = this.bulkEmails;

        for (let index = 0; index < entries.length; index++) {
            const entry = entries[index];

            // Not an email entry
            if (entry.indexOf('@') === -1) {
                continue;
            }

            // Dedupe based on email
            if (this.usersForm.get("users").value.findIndex(x => x.email?.toLowerCase() === entry?.toLowerCase()) != -1) {
                continue;
            }

            if (this.isOutlookFormat(entry) === true) {

                const result = this.splitOutlookEntry(entry);

                this.addBulkUser(result);

            } else {
                const genericEmailDetails = this.splitEmailEntry(entry);

                if (genericEmailDetails !== undefined) {

                    this.addBulkUser(genericEmailDetails);

                } else {

                    this.addBulkUser({
                        firstName: '',
                        lastName: '',
                        email: entry
                    });
                }

            }

        }

        this.removeBlankRows();

        this.addInitialRow();

        this.resetBulkAdd();

        this.bulkEmails = [];

        this.userDetailsChange();

        this.usersForm.markAllAsTouched();

    }

    public userDetailsChange() {

        this.usersChanged.next((<FormArray>this.usersForm.get("users")).value);

    }

    public showServerValidation(users: CreatedUsersResponse) {

        this.removeSuccessfullySavedUsers(users.success);

        this.showServerErrors(users.failures);

    }

    private showServerErrors(errors: any) {

        const usersControl: FormArray = this.usersForm.get('users') as FormArray;

        errors.forEach(error => {

            const idx = usersControl.controls.findIndex(x => x.value.email?.toLowerCase() === error.email?.toLowerCase());

            usersControl.controls[idx].get('email').setErrors({
                serverError: error.reason
            });

        });

    }

    private removeSuccessfullySavedUsers(successes: any) {

        const usersControl: FormArray = this.usersForm.get('users') as FormArray;

        successes.forEach(success => {

            const idx = usersControl.controls.findIndex(x => x.value.email?.toLowerCase() === success.email?.toLowerCase());

            usersControl.removeAt(idx);

        });

    }

    private addInitialRow() {

        if ((<FormArray>this.usersForm.get('users')).length === 0) {

            this.addUserRow();
        }

    }

    private addBulkUser(user: User) {

        (<FormArray>this.usersForm.get('users')).push(
            this.addUserFormGroup(user.firstName, user.lastName, user.email)
        );

        this.userDetailsChange();
    }

    private isOutlookFormat(email: string): boolean {

        return this.outlookFormatRegex.test(email);

    }

    private splitOutlookEntry(entry: string): User {

        const match = this.outlookFormatRegex.exec(entry);

        // [0] is the grouping
        return {
            firstName: this.formatName(match[1]),
            lastName: this.formatName(match[2]),
            email: match[3]
        };

    }

    private splitEmailEntry(entry: string) {

        const atIndex = entry.indexOf('@');

        const decimalIndex = entry.indexOf('.');

        const hyphenIndex = entry.indexOf('-');

        const firstPart = entry.split('@')[0];

        if (decimalIndex > -1 && decimalIndex < atIndex) {

            const name = firstPart.split('.');

            return {
                firstName: this.formatName(name[0]),
                lastName: this.formatName(name[1]),
                email: entry
            }
        }

        if (hyphenIndex > -1 && hyphenIndex < atIndex) {

            const name = firstPart.split('-');

            return {
                firstName: this.formatName(name[0]),
                lastName: this.formatName(name[1]),
                email: entry
            }
        }
    }



    // Navigation //

    public addMultpleSlide() {

        this.isBulkView = true;

    }

    public resetBulkAdd() {

        this.bulkEmailsCtrl.reset();
        this.bulkEmails = [];
        this.isBulkView = false;

    }

    private formatName(name: string) {

        return name && name[0].toUpperCase() + name.slice(1);
    }
}
