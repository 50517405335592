import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { BaseTenantService } from "@summize/shared/core";

import { SelectedWidgetCategoryTab } from './widget-library.component';

@Injectable({ providedIn: 'root' })
export class WidgetLibraryService extends BaseTenantService {

    constructor(private http: HttpClient) { super() }

    public async getWidgets(tab: SelectedWidgetCategoryTab): Promise<any> {

        return await this.http
            .get(`${this.getUserBaseUrl(true)}requestanalytics/widgets?category=${tab}`)
            .toPromise();
    }
}