import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DialogShellModule } from '@summize/shared/components-v2';
import { SelectBoxModule } from '@summize/shared/components';

import { ConfigureMetricComponent } from './configure-metric.component';

@NgModule({
    imports: [
        CommonModule,
        DialogShellModule,
        FormsModule,
        SelectBoxModule,
    ],
    exports: [
        ConfigureMetricComponent
    ],
    declarations: [
        ConfigureMetricComponent
    ]
})
export class ConfigureMetricModule { }

export * from './configure-metric.component'