import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseTenantService } from '@summize/shared/core';

@Injectable({ providedIn: 'root' })
export class ManagePanelService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getClients(): Promise<any> {

        const url = `${this.getUserBaseUrl(true)}clients`;

        const result: any = await this.http.get(url).toPromise();

        return result.clients;

    }

    public async getMatters(clientId: string): Promise<any> {

        const url = `${this.getUserBaseUrl(true)}clients/${clientId}/matters`;

        const result: any = await this.http.get(url).toPromise();

        return result.matters;
    }

}