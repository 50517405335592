<div [class.icon--disabled]="disabled" [matTooltip]="tooltip">
    <div class="icon row" (click)="onClick($event)">
        <span *ngIf="label && iconSide === IconSide.Right" class="icon__text icon__text-left">{{ label }}</span>

        <div class="icon__container" [class.icon__container--small]="small" [class.icon__container--big]="big"
            [class.icon__container--circle]="circle" [class.icon__container--blue]="blue"
            [class.icon__container--primary]="primary" [class.icon__container--square]="square">

            <div class="{{ icon }} icon__standard">
            </div>
        </div>
        <span *ngIf="label && iconSide === IconSide.Left" class="icon__text icon__text-right">{{ label }}</span>
    </div>
</div>
