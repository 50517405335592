import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface ToastItem {
    id?: string;
    text: string;
    icon: string;
    duration?: number;
    type?: string;
}

@Injectable({ providedIn: 'root' })
export class ToastService {

    public $change: Subject<Array<ToastItem>>;

    private items: Array<ToastItem>;

    constructor() {

        this.$change = new Subject<Array<ToastItem>>();

        this.items = new Array<ToastItem>();

    }

    public error(text: string): void {

        this.show({ text, type: 'error', icon: 'icon-error1' });

    }

    public showResponseError(error: any): void {

        let text = error.error.detail || error.error.message;

        text = text === undefined || text.includes('stack trace') ? 'Something has gone wrong. Please contact Summize or try again later.' : text;

        this.show({ text, type: 'error', icon: 'icon-error1' });

    }

    public show(item: ToastItem): void {

        const initial = {
            id: new Date().getSeconds().toString(),
            duration: 10 * 1000,
            type: ''
        };

        item = Object.assign(initial, item);

        this.items.push(item);

        const despatch = () => this.$change.next(this.items);

        setTimeout(() => {

            this.items = this.items.filter(i => i.id !== item.id);

            despatch();

        }, item.duration);

        despatch();

    }

    public remove(item: string) {

        this.items = this.items.filter(i => i.text !== item);

        this.$change.next(this.items);

    }

}