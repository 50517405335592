import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {

    public transform(value: Array<any>, query: string) {

        if (query === undefined) {

            return value;

        }

        return value.filter(v => v.name.toLocaleLowerCase().includes(query.toLowerCase()));

    }
}