import { Component, HostListener } from "@angular/core";

@Component({ template: '' })
export abstract class DropHost {

    @HostListener('dragenter', ['$event'])
    public onDragEnter(evt) {

        evt.stopPropagation();

        evt.preventDefault();

        this.isDragging = true;

    }

    @HostListener('dragleave', ['$event'])
    public onDragLeave(evt) {

        evt.stopPropagation();

        evt.preventDefault();

        this.isDragging = false;

    }

    @HostListener('body:dragover', ['$event'])
    public onBodyDragOver(event: DragEvent) {

        event.preventDefault();

        event.stopPropagation();

    }

    @HostListener('body:drop', ['$event'])
    public onBodyDrop(event: DragEvent) {

        event.preventDefault();

    }

    @HostListener('drop', ['$event'])
    public onDrop(evt: DragEvent) {

        evt.stopPropagation();

        evt.preventDefault();

        const { dataTransfer } = evt;

        this.onDropComplete(dataTransfer);

    }

    public isDragging: boolean = false;

    protected abstract onDropComplete(transfer: any): void;

}

export class DragAndDropHelper {

    public static getFilesFromData(dataTransfer): any {

        let files = [];

        if (dataTransfer.items) {

            for (let i = 0; i < dataTransfer.items.length; i++) {

                if (dataTransfer.items[i].kind === 'file') {

                    const file = dataTransfer.items[i].getAsFile();

                    const fileType = file?.type?.toLowerCase();

                    if (fileType === 'application/pdf' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {

                        files.push(file);

                    }
                }
            }

            dataTransfer.items.clear();

        } else {

            files = Array.from(dataTransfer.files);

            dataTransfer.clearData();

        }

        return files;

    }
}