import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { DocumentTasksComponent } from './document-tasks.component';
import { DocumentTasksHelper } from './document-tasks.helper';
import { TaskCardModule } from '../task-card/task-card.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { DocumentTaskService } from './document-tasks.service';
import { TaskLegendModule } from '../task-legend/task-legend.module';

@NgModule({
    imports: [
        CommonModule,
        TaskCardModule,
        LoadingSpinnerModule,
        TaskLegendModule,
        RouterModule
    ],
    exports: [
        DocumentTasksComponent,
    ],
    declarations: [
        DocumentTasksComponent
    ],
    providers: [
        DocumentTasksHelper,
        DocumentTaskService
    ]
})
export class DocumentTasksModule { }

export * from './document-tasks.component'
export * from './document-tasks.helper';