import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';

@Injectable({
    providedIn: 'root'
})
export class CustomerHubPanelService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getCustomerHubLinks(): Promise<any> {

        const results: any = await this.http.get(`${this.getUserBaseUrl(true)}hubspot`).toPromise();

        return results;
    }

    public async saveTicketDetails(ticketDetails): Promise<any> {

        const results: any = await this.http.post(`${this.getUserBaseUrl(true)}hubspot/saveticket`, ticketDetails.request).toPromise();

        return results;
    }

}
