import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHeaders, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, exhaustMap, take } from 'rxjs/operators';

import { environment } from './../../environments/environment';
import { TokenService } from './token.service';
import { AuthService } from './auth.service';

export const InterceptorSkipAuthBearer = 'X-Skip-Auth-Bearer';

@Injectable({ providedIn: 'root' })
export class AuthInterceptorService implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        private tokenService: TokenService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

        return this.authService.isAuthenticated$.pipe(
            take(1),
            exhaustMap(user => {
                let headers = req.headers;

                let skipAuthBearer = false;
                const token = this.tokenService.getToken();
                const requestUrl = new URL(req.url);
                const envHost = new URL(environment.apiUrl).host.toLowerCase();

                if (req.headers.has(InterceptorSkipAuthBearer)) {

                    headers = headers.delete(InterceptorSkipAuthBearer);

                    skipAuthBearer = true;

                }

                if (user &&
                    token &&
                    !skipAuthBearer &&
                    (
                        requestUrl.host.toLowerCase().endsWith('summize.com') ||
                        requestUrl.host.toLowerCase() === envHost
                    )) {                    

                    const modifiedReq = req.clone({
                        headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)
                    });

                    return next.handle(modifiedReq);
                }

                return next.handle(req);

            }), catchError(
                (
                    httpErrorResponse: HttpErrorResponse,
                    _: Observable<HttpEvent<any>>
                ) => {
                    if (httpErrorResponse.status === 401) {

                        this.authService.logout();
                    }
                    return throwError(httpErrorResponse);
                }
            )
        );
    }
}
