import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ClaimsDisableDirective } from './claim-disable.directive';
import { ClaimsShowDirective } from './claim-show.directive';
import { ClaimsHideDirective } from './claim-hide.directive';
import { ContentEditableDirective } from './contenteditable.directive';
import { FeatureFlagDirective } from './feature-flag.directive';
import { VisibleDirective } from './Visible.directive';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ClaimsDisableDirective,
        ClaimsShowDirective,
        ClaimsHideDirective,
        ContentEditableDirective,
        FeatureFlagDirective,
        VisibleDirective
    ],
    exports: [
        ClaimsDisableDirective,
        ClaimsShowDirective,
        ClaimsHideDirective,
        ContentEditableDirective,
        FeatureFlagDirective,
        VisibleDirective
    ]
})

export class DirectivesModule { }
