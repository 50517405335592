import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseTenantService } from '@summize/shared/core';

@Injectable({ providedIn: 'root' })
export class DocumentTaskService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getTaskInstances(documentId: string): Promise<any> {

        const baseUrl = this.getUserBaseUrl(true);

        return await this.http.get<any>(`${baseUrl}tasks/document/${documentId}`).toPromise();
    }

}