import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-hover-content',
    templateUrl: 'hover-content.html',
    styleUrls: []
})
export class HoverContentComponent {

    @Input()
    public menuXPosition : 'before' | 'after' = 'before';

    private timedOutCloser;

    public mouseEnter(trigger) {
        if (this.timedOutCloser) {
            clearTimeout(this.timedOutCloser);
        }
        trigger.openMenu();
    }

    public mouseLeave(trigger) {
        this.timedOutCloser = setTimeout(() => {
            trigger.closeMenu();
        }, 50);
    }

}