import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { ClauseRuleResult } from '../clause-summary-panel/clause-summary-panel.module';

export interface RelatedParagraph {
    boundingBox: Array<number>;
    documentName: string;
    documentId: string;
    isFlagged: boolean;
    isLinkedPara: boolean;
    keyDate: Date;
    pageNumber: number;
    primaryTerms: any;
    resultLine: string;
    resultLineNumber: number;
    resultText: string;
    resultWeighting: number;
    secondaryTerms: any;
    paragraphNumber: string;
    paragraphId: string;
}


@Component({
    selector: 'app-related-paragraphs',
    templateUrl: 'related-paragraphs.html',
    styleUrls: ['./related-paragraphs.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatedParagraphsComponent implements OnInit, OnChanges {

    @Input()
    public clauseName: string;

    @Input()
    public paragraphs: RelatedParagraph[];

    @Input()
    public initialParagraph: ClauseRuleResult;

    @Output()
    public onParagraphChange: EventEmitter<RelatedParagraph>;

    public currentParagraphIndex = 0;

    constructor() {

        this.onParagraphChange = new EventEmitter<RelatedParagraph>();

    }

    public ngOnInit(): void {

        // Try and find the correct para set first
        this.findAndSelectParagraphIndex();

    }

    public ngOnChanges(changes: SimpleChanges): void {

        if (changes['paragraphs'] !== undefined) {

            this.findAndSelectParagraphIndex();
        }
    }

    private findAndSelectParagraphIndex() {

        const foundParaIndex = this.paragraphs.findIndex(
            x => x.pageNumber === this.initialParagraph.pageNumber &&
                x.paragraphId === this.initialParagraph.paragraphNumber);

        this.currentParagraphIndex = foundParaIndex > -1 ? foundParaIndex : 0;
    }

    public prev() {

        this.currentParagraphIndex--;

        if (this.currentParagraphIndex < 0) {

            this.currentParagraphIndex = this.paragraphs.length - 1;

        }

        const item: any = this.paragraphs[this.currentParagraphIndex];

        item.__relatedParaIndex = this.currentParagraphIndex;

        this.onParagraphChange.next(item);

    }

    public next() {

        this.currentParagraphIndex++;

        if (this.currentParagraphIndex >= this.paragraphs.length) {

            this.currentParagraphIndex = 0;

        }

        const item: any = this.paragraphs[this.currentParagraphIndex];

        item.__relatedParaIndex = this.currentParagraphIndex;

        this.onParagraphChange.next(item);

    }

}