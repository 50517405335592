
import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
export interface ShieldGuardEmit {
  data: any;
  callback: any;
}
@Component({
  selector: 'app-shieldguard',
  templateUrl: './shieldguard.component.html',
  styleUrls: ['./shieldguard.component.scss']
})
export class ShieldGuardComponent {

  public shieldGuardForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ShieldGuardComponent>,
    private formBuilder: FormBuilder
  ) {
    this.shieldGuardForm = this.formBuilder.group({
      shieldGuard: '',
      data: '',
      callback: undefined
    }, {
      validators: this.checkShieldGuard
    });

    this.shieldGuardForm.patchValue(data);
  }

  checkShieldGuard(group: FormGroup) {

    return true;

  }

  public onSubmit() {
    if (this.shieldGuardForm.valid) {

      this.dialogRef.close(this.shieldGuardForm.value as ShieldGuardEmit);

    }
  }
}
