import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { RangeSelectorComponent } from './range-selector.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonToggleModule
    ],
    exports: [
        RangeSelectorComponent
    ],
    declarations: [
        RangeSelectorComponent
    ]
})
export class RangeSelectorModule { }

export * from './range-selector.component'