import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppComponent } from '@summize/shared/core';
import { EventService } from '@summize/shared/framework';

import { TimeRangeChange } from '../analytics.events';
@Component({
    templateUrl: 'analytics-dashboard.html',
    styleUrls: ['./analytics-dashboard.scss']
})
export class AnalyticsDashboardComponent extends AppComponent implements OnInit {

    constructor(private route: ActivatedRoute,
        private router: Router,
        private events: EventService) {

        super();

    }

    public ngOnInit(): void {

        this.subscribe(this.events.when(TimeRangeChange), (event: any) => {

            this.router.navigate(
                [],
                {
                    relativeTo: this.route,
                    queryParams: {
                        range: event.context
                    },
                    queryParamsHandling: 'merge'
                });

        });

    }

}