import { Component, OnInit } from '@angular/core';
import { AuthOidcService } from '../../services/auth-oidc.service';

@Component({
    selector: 'app-logout-oidc',
    template: ''
})
export class LogoutOidcComponent implements OnInit {

    constructor(
        public oidcAuthService: AuthOidcService,
    ) {
    }

    public ngOnInit() {

        this.oidcAuthService.logoutCallback();

    }
}