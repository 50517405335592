import { ClauseMetricDefinition, CurrencyMask, DurationMask, SpecialTypes } from "../../analytics.types";

export const MaskValue = (definition: ClauseMetricDefinition, value: any) => {

    switch (definition.type) {
        case SpecialTypes.DURATION:
            return MaskDuration(definition, value);
        case SpecialTypes.CURRENCY:
            return MaskCurrency(definition, value);
        default:
            return Math.round(value).toString();
    }

}

export const MaskDuration = (definition: ClauseMetricDefinition, value: any) => {

    const mask = definition.settings.mask;

    switch (mask) {
        case DurationMask.Months.toString():
            return `${Math.round(value / 30.30)} Months`;
        case DurationMask.Weeks.toString():
            return `${Math.round(value / 7)} Weeks`;
        default:
            return `${Math.round(value)} Days`;

    }

}

export const MaskCurrency = (definition: ClauseMetricDefinition, value: any) => {

    try {

        const mask = definition.settings.mask;

        if (mask === undefined || mask === null) {

            return value;

        }

        if (mask === CurrencyMask.Exact.toString()) {

            return `${Math.round(value).toLocaleString()}`;
        }

        let newValue: any = parseInt(value, 10);

        const parsedValue = newValue;

        if (isNaN(parsedValue) === true) {

            return value;
        }

        if (parsedValue >= 1000) {

            let suffixes = ["", "k", "Million", "Billion", "Trillion"];

            let suffixNum = Math.floor(("" + parsedValue).length / 3);

            let shortValue: any = '';

            for (let precision = 2; precision >= 1; precision--) {

                shortValue = parseFloat((suffixNum != 0 ?
                    (parsedValue / Math.pow(1000, suffixNum)) : parsedValue).toPrecision(precision));

                let dotLessShortValue =
                    (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');

                if (dotLessShortValue.length <= 2) {

                    break;

                }

            }

            if (shortValue % 1 != 0) {

                shortValue = shortValue.toFixed(1);

            }

            newValue = `${shortValue} ${suffixes[suffixNum]}`;
        }

        return newValue;

    } catch (error) {

        // Rather than throw or Seen NaN
        // just return the initial value

        return value;

    }

}