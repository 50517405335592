import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatCheckboxModule } from '@angular/material/checkbox';

import { TemplatePermissionsComponent } from './template-permissions.component';

@NgModule({
    imports: [
        CommonModule,
        MatCheckboxModule
    ],
    exports: [
        TemplatePermissionsComponent
    ],
    declarations: [
        TemplatePermissionsComponent
    ]
})
export class TemplatePermissionsModule { }

export * from './template-permissions.component'