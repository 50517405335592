import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StatusPillComponent } from './status-pill.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        StatusPillComponent
    ],
    declarations: [
        StatusPillComponent
    ]
})
export class StatusPillModule { }

export * from './status-pill.component';