import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FadeInAnimation, SlideUpAnimation } from '@summize/shared/framework';

@Component({
    selector: 'app-section-panel',
    templateUrl: 'section-panel.html',
    styleUrls: ['./section-panel.scss'],
    animations: [FadeInAnimation, SlideUpAnimation]
})
export class SectionPanelComponent {

    @Output()
    public onShowMore: EventEmitter<any>;

    @Input()
    public title: string | undefined;

    @Input()
    public subtitle: string | undefined;

    @Input()
    public more: boolean = false;

    @Input()
    public loading: boolean = true;

    @Input()
    public hasData: boolean = true;

    constructor() {

        this.onShowMore = new EventEmitter<any>();

    }

}