import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExpandableAreaComponent } from './expandable-area.component';
import { InfoPointModule } from '../info-point/info-point.module';

@NgModule({
    imports: [
        CommonModule,
        InfoPointModule
    ],
    exports: [
        ExpandableAreaComponent
    ],
    declarations: [
        ExpandableAreaComponent
    ]
})
export class ExpandableAreaModule { }

export * from './expandable-area.component'