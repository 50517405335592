<app-context-bar [primaryText]="definition?.settings?.name || clauseName" [secondaryText]="documentTypeName"
    [hasBackButton]="true" (onBack)="goBack()">
</app-context-bar>

<div class="search-container">
    <div class="search-area">
        <input [(ngModel)]="inputQuery" (keyup)="search($event)" name="input" type="text" autocomplete="off"
            placeholder="Search ..." />
    </div>
</div>

<div class="table-container scroll">
    <app-table *ngIf="datasource" [datasource]="datasource"></app-table>
</div>

<ng-template #documentName let-row>
    <div (click)="goToContract(row)" class="doc-name clickable-text">{{row.documentName}}</div>
    <div class="client-matter">
        {{row.clientName}} > {{ row.matterName}}
    </div>
</ng-template>

<ng-template #clauseText let-row>
    <div>{{row.clauseText}}</div>
</ng-template>

<ng-template #trend let-row>

    <div class="trend {{getTrendDirection(row.trendDirection)}}">
        <div class="trend-value" *ngIf="row.trendDirection === TrendDirection.Up">
            <app-icon [icon]="'arrow-up-outline'"></app-icon>
            <div>{{row.delta}}</div>
        </div>

        <div class="trend-value" *ngIf="row.trendDirection === TrendDirection.Down">
            <app-icon [icon]="'arrow-down-outline'"></app-icon>
            <div>{{row.delta}}</div>
        </div>
    </div>

</ng-template>
