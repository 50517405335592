import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import 'quill-mention';
import { QuillEditorComponent } from 'ngx-quill';

import { AppComponent, ChangeDetectionHelper } from '@summize/shared/core';
import {
    DeleteSelectedTextEvent,
    EventService,
    GetSelectedTextEvent,
    LocalStorageService,
    SelectedTextEvent,
    SelectTextEvent,
    ShowPanelEvent
} from '@summize/shared/framework';

import { ConversationInputService } from './conversation-input.service';

export interface ConversationMessageContent {
    html: string;
    mentions: string;
    text?: string;
}

export interface LiveConversationContent extends ConversationMessageContent {
    conversationId: string;
}

const TextMention = 'text:';

@Component({
    selector: 'app-conversation-input',
    templateUrl: 'conversation-input.html',
    styleUrls: ['./conversation-input.scss']
})
export class ConversationInputComponent extends AppComponent implements OnInit, OnDestroy {

    @ViewChild(QuillEditorComponent, { static: true })
    public editor: QuillEditorComponent;

    @Output()
    public contentChanged: EventEmitter<any>;

    @Input()
    public set content(value: string) {

        this._content = value;

        if (this.initialRenderComplete === true) {

            this.renderInitialContent(value);

        }

    }

    public get content(): string {

        return this._content;

    }

    @Input()
    public readonly: boolean = false;

    @Input()
    public placeholder: string = "";

    @Input()
    public display: 'full' | 'text-area' | 'inline' = 'inline';

    @Input()
    public users: any;

    @Input()
    public fixMentions: boolean = true;

    @Input()
    public positionMode: string = 'absolute';

    @Input()
    public toolbar = true;

    @Input()
    public showEntities = false;

    @Input()
    public contractId: string;

    @Input()
    public conversationId: string;

    public configuration: any;

    public renderedContents: string;

    private messageCleared: boolean = false;

    private initialRenderComplete: boolean = false;

    private _content: string;

    private __renderedContent;

    private _entityCache: any;

    private _clickHandle: any;

    private mentionModule = undefined;

    private textLinks: Set<string> = new Set<string>();

    private isTextLinksEnabled = false;

    constructor(
        private events: EventService,
        private service: ConversationInputService,
        private storageService: LocalStorageService) {

        super();

        this.contentChanged = new EventEmitter<any>();

        this.isTextLinksEnabled = this.hasFeatureFlag('ConversationTextLinks');

    }

    public ngOnDestroy(): void {

        if (this.display === 'full' && this.conversationId !== undefined) {

            const message = this.getMessage();

            if (message?.text) {

                const liveContent = {} as LiveConversationContent;

                liveContent.html = message?.html;
                liveContent.mentions = message?.mentions;
                liveContent.text = message.text;
                liveContent.conversationId = this.conversationId;

                if (this.messageCleared === false) {

                    this.storageService.setItem(`smz-conversation-${this.conversationId}`, JSON.stringify(liveContent));

                }

            } else {

                this.clearLiveConversationText();

            }

        }

        window.removeEventListener('mention-clicked', this._clickHandle, false);

    }

    public ngOnInit(): void {

        if (this.showEntities === true) {

            this._clickHandle = this.handleMentionClick.bind(this);

            window.addEventListener('mention-clicked', this._clickHandle, false);

        }

        this.configuration = {
            style: this.getStyleForDisplay(),
            modules: {
                toolbar: (this.toolbar === true && this.readonly === false) ? this.getToolbar() : false,
                mention: {
                    allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                    mentionDenotationChars: this.showEntities === true ? ["@", "#"] : ["@"],
                    mentionListClass: 'variable-list',
                    fixMentionsToQuill: this.fixMentions,
                    positioningStrategy: this.positionMode,
                    showDenotationChar: true,
                    dataAttributes: [
                        ['id', 'value', 'denotationChar', 'key', 'type']
                    ],
                    onSelect: (item, insertItem) => this.onMenuItemSelected(item, insertItem),
                    renderItem: (item: any,) => this.renderMenuItem(item),
                    source: async (searchTerm, renderList, mentionChar) => {

                        if (mentionChar === '@') {

                            this.buildMenu(searchTerm, renderList, this.users)

                        } else if (mentionChar === '#') {

                            if (this._entityCache === undefined) {

                                const { clauses, tasks, attachments }
                                    = await this.service.getEntities(this.contractId);

                                this._entityCache = [];

                                if (tasks.length > 0) {

                                    this._entityCache.push({ key: 'Tasks', disabled: true, type: 'group' },
                                        ...tasks);

                                }

                                if (attachments.length > 0) {

                                    this._entityCache.push({ key: 'Attachments', disabled: true, type: 'group' },
                                        ...attachments);

                                }

                                if (clauses.length > 0) {

                                    this._entityCache.push({ key: 'Clauses', disabled: true, type: 'group' },
                                        ...clauses);

                                }

                            }

                            this.buildMenu(searchTerm, renderList, this._entityCache);

                        };

                    }
                }
            }
        };

    }

    public ngAfterContentInit() {

        if (this.display === 'full' && this.conversationId !== undefined) {

            const key = `smz-conversation-${this.conversationId}`;

            const message = this.storageService.getItem(key);

            if (message) {

                const messageContent = JSON.parse(message) as LiveConversationContent;

                this.content = messageContent.html;

            }

        }

        this.subscribe(this.events.when(SelectedTextEvent), async (values) => {

            if (this.readonly === true || values?.context?.selectedText === undefined || values.context.selectedText.length === 0) {

                console.log(`ReadOnly => ${this.readonly}, SelectedText => ${values?.context?.selectedText}`);

                return;

            }

            ChangeDetectionHelper.doNextCycle(() => {

                const text = values.context.selectedText.replace(/(\r\n|\n|\r)/gm, ' ');

                console.log(`Inserting SelectedText => ${text}`);

                const id = `${values.context.bookmarkId}||${values.context.paragraphId}`;

                this.addTextMention(text, id);

            }, 200);

        });

        this.renderInitialContent(this.content);

    }

    public handleMentionClick(event: any): void {

        // Ignore any clicks when in edit mode - as can cause issues when saving the changes.
        if (this.readonly !== true) {

            return;

        }

        const val = event.value;

        if (val.id?.startsWith('clause')) {

            this.events.despatch(ShowPanelEvent, {
                panelId: 'clauses',
                panelFunction: 'showFromConversation',
                arguments: {
                    clauseId: val.id.replace('clause:', '')
                }
            });

        } else if (val.id?.startsWith('task')) {

            this.events.despatch(ShowPanelEvent, {
                panelId: 'tasks',
                panelFunction: 'showTasksFromConversation',
                arguments: {}
            });

        } else if (val.id?.startsWith(TextMention)) {

            const id = val.id.substring(TextMention.length);

            const ids = id.split('||');

            const context = {
                selectedText: val.value,
                bookmarkId: ids.length >= 1 ? ids[0] : undefined,
                paragraphId: ids.length >= 2 ? ids[1] : undefined,
            };

            this.events.despatch(SelectTextEvent, {
                arguments: context
            });

        } else {

            this.events.despatch(ShowPanelEvent, {
                panelId: 'attachments',
                panelFunction: 'showFromConversation',
                arguments: {}
            });

        }

    }

    public getContent($event): void {

        if ($event.html !== undefined) {

            this.__renderedContent = $event.html;

            this.contentChanged.next({
                html: $event.html
            });

        }

    }

    public addTextMention(text: string, id: string) {

        if (this.mentionModule === undefined || text === undefined || text.length === 0 || id === undefined || id.length === 0) {

            return;

        }

        const textMentionId = TextMention + id;

        this.textLinks.add(textMentionId);

        this.mentionModule.insertItem(
            {
                id: textMentionId,
                value: text,
                denotationChar: ''
            },
            true,
            {
                showDenotationChar: false,
                spaceAfterInsert: true
            });

    }

    public setContent(html: string, contentType: 'blockquote' | 'text' = 'text', id: string = 'test'): void {

        const editor = this.editor.quillEditor;

        if (editor !== undefined) {

            const position = 0;

            if (contentType === 'blockquote') {

                editor.insertEmbed(position, 'blockquote', html);

            }

            editor.insertText(position, html, {
                magicid: id
            });

        }

    }

    public addContent(html: string, contentType: 'blockquote' | 'text' = 'text', id: string = 'test'): void {

        const editor = this.editor.quillEditor;

        if (editor !== undefined) {

            const position = editor.getLength() - 1;

            if (contentType === 'blockquote') {

                editor.insertEmbed(position, 'blockquote', html);

            }

            editor.insertText(position, html, {
                magicid: id
            });

        }

    }

    public clear(): void {

        this.editor.quillEditor.setContents([], 'silent');

        this.clearLiveConversationText();

    }

    public clearLiveConversationText(): void {

        this.messageCleared = true;

        const key = `smz-conversation-${this.conversationId}`;

        this.storageService.removeItem(key);

    }

    public getMessage(): ConversationMessageContent {

        const parser = new DOMParser()

        const doc = parser.parseFromString(this.__renderedContent, "text/html");

        const mentionsElements = doc.querySelectorAll('.mention');

        const mentions = [];

        mentionsElements.forEach(el => {

            const val = el.getAttribute('data-key');

            mentions.push(val);

        });

        const innerContent = doc.querySelectorAll('body p');

        const parseHtml = (content): string => {

            let c = '';

            for (const block of content) {

                c = `${c} ${block.innerText}`;

            }

            return c;

        }

        const result: ConversationMessageContent = {
            html: this.__renderedContent,
            mentions: mentions.join(',')
        };

        if (innerContent !== undefined && innerContent !== null) {

            result.text
                = parseHtml(innerContent).trim();

        } else {

            result.text = '';

        }

        return result;

    }

    private getToolbar(): any {

        return [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ]
    }

    private onMenuItemSelected(item: any, insertFunction: Function): void {

        const match =
            this.users?.find(def => def.value === item.value) || this._entityCache?.find(def => def.id === item.id);

        item.key = match.key;

        const editor = this.editor.quillEditor;

        insertFunction(item);

        editor.insertText(editor.getLength() - 1, '', 'user');

    }

    private getStyleForDisplay(): any {

        if (this.display === 'text-area' || this.display === 'full') {

            return {
                height: '100px'
            };

        }

    }

    private renderMenuItem(item: any): any {

        const createAsElement = (content) => {

            const div = document.createElement('div');

            div.innerHTML = content.trim();

            return div.firstChild;

        };

        if (item.type === 'group') {

            const element = `<div class="entity-header">${item.key}</div>`;

            return createAsElement(element);
        }

        if (item.type === 'task' || item.type === 'clause' || item.type === 'attachment') {

            const element = `<div class="entity-element">${item.menuItem}</div>`;

            return createAsElement(element);
        }
        else {

            const initials = item.value
                .split(' ')
                .map(word => word[0])
                .join('');

            const element = `
                <div class="user-element">
                    <div class="avatar">
                    <div class="initials">
                        ${initials}
                    </div>
                    </div>
                    <div class="user">
                        <div class="name">
                            ${item.value}
                        </div>
                        <div class="email">
                            ${item.key}
                        </div>
                    </div>
            `;

            return createAsElement(element);
        }
    }

    private buildMenu(search: string, renderListFunction: Function, values): void {

        if (search.length === 0) {

            renderListFunction(values, search);

        } else {

            const matches = [];

            values.forEach((entry) => {

                if (entry.value?.toLowerCase().indexOf(search.toLowerCase()) !== -1) {

                    matches.push(entry);
                }

            })

            renderListFunction(matches, search);

        }

    }

    private renderInitialContent(initialContent): void {

        setTimeout(() => {

            this.initialRenderComplete = true;

            const editor = this.editor.quillEditor;

            if (editor !== undefined) {

                const contents = editor.clipboard.convert(initialContent);

                editor.setContents(contents, 'silent');

                this.mentionModule = editor.getModule('mention');

                editor.on("text-change", (delta: any, oldContents: any, source: any) => {

                    this.cleanTextMentions(delta?.ops, oldContents?.ops, editor.getContents().ops);

                });

                // Only request selected text if we are in edit-mode, and the input is empty.
                console.log('this.editor.quillEditor?.getLength() => ' + this.editor.quillEditor?.getLength());

                if (this.isTextLinksEnabled === true && this.readonly !== true) {

                    console.log('Despatching GetSelectedText');

                    this.events.despatch(GetSelectedTextEvent);

                }

            }
        }, 100);

    }

    private cleanTextMentions(deltaOps: any, oldContentsOps: any, newContentsOps: any) {

        console.log('Delta => ' + JSON.stringify(deltaOps));
        console.log('OldContents => ' + JSON.stringify(oldContentsOps));
        console.log('NewContents => ' + JSON.stringify(newContentsOps));

        let removedTextMentions = [];

        for (const deltaOp of deltaOps) {

            if (deltaOp.retain) {

                oldContentsOps = oldContentsOps.slice(deltaOp.retain);

            } else if (deltaOp.delete) {

                const deleteContentsOps = oldContentsOps
                    .slice(0, deltaOp.delete)
                    .map(x => x.insert?.mention?.id)
                    .filter(x => x !== undefined && x.startsWith(TextMention));

                if (deleteContentsOps.length > 0) {

                    removedTextMentions.push(...deleteContentsOps);

                }

                oldContentsOps = oldContentsOps.slice(deltaOp.delete);

            }

        }

        console.log('RemovedTextMentions => ' + JSON.stringify(removedTextMentions));

        removedTextMentions.forEach(x => {

            const ids = x.substring(TextMention.length).split('||');

            const bookmarkId = ids.length >= 1 ? ids[0] : undefined;

            if (bookmarkId !== undefined) {

                this.events.despatch(DeleteSelectedTextEvent, { bookmarkId });

                this.textLinks.delete(x);

            }

        });

    }

    private getMentions(): any[] {

        const parser = new DOMParser()

        const doc = parser.parseFromString(this.__renderedContent, "text/html");

        const mentionsElements = doc.querySelectorAll('.mention');

        const mentions = [];

        mentionsElements.forEach(el => {

            const val = el.getAttribute('data-key');

            mentions.push(val);

        });

        return mentions;

    }

}