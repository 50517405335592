<div *ngIf="isConfirmingUpdate === true" class="field-editor">
    <p>
        <strong>Important:</strong> Changes you make to custom fields can have a significant impact if they have already
        been rolled out and
        could delete any currently stored values. Read the <a href="https://customers.summize.com/hub"
            target="_blank">Editing Custom Fields</a> help article to learn more about the
        impacts if you are not sure.
    </p>

    <p> Are you sure you want to save and apply these changes?</p>
</div>

<div *ngIf="isConfirmingUpdate === false" class="field-editor">
    <p *ngIf="isEdit === false" class="intro-description">
        Enter a name, select which contract types the custom field should be assigned to, then select the field type.
    </p>
    <p *ngIf="isEdit === true" class="intro-description">
        Edit the settings below then click 'Save changes' to apply
    </p>
    <div class="field">
        <div class="field-title required">
            Field name
        </div>
        <div class="field-value">
            <input [(ngModel)]="model.name" name="name" type="text" class="smz-standard-input v2" autocomplete="off">
        </div>
        <div *ngIf="invalidName === true" class="error">
            That name is already being used by another Custom Field. Please choose another.
        </div>
    </div>
    <div class="field">
        <div class="field-title">
            Field description
        </div>
        <div class="field-value">
            <input [(ngModel)]="model.description" name="description" type="text" class="smz-standard-input v2"
                autocomplete="off">
        </div>
    </div>
    <div class="field">
        <div class="field-title required">
            Assign to contract types
        </div>
        <div class="field-value">
            <app-select-box (changed)="selectedContractTypeTargeting = $event" [value]="selectedContractTypeTargeting"
                [options]="contractTypeTargeting" [multipleSelect]="false" [canSearch]="false"></app-select-box>
        </div>
        <div *ngIf="selectedContractTypeTargeting === '1'" class="field-value">
            <app-select-box (changed)="model.targetContractTypes = $event" [value]="model.targetContractTypes"
                [options]="contractTypes" [multipleSelect]="true"></app-select-box>
        </div>
    </div>
    <div class="field">
        <div class="field-title required">
            Field type
        </div>
        <div class="field-value">
            <app-select-box (changed)="setFieldType($event)" [value]="model.fieldType" [options]="fieldTypes"
                [disabled]="isEdit === true" [canSearch]="false"></app-select-box>
        </div>
    </div>

    <div *ngIf="model.fieldType === CustomFieldType.Dropdown || model.fieldType === CustomFieldType.MultiSelect"
        class="field values">
        <div class="field-description required">
            Configure the available options for this field.
        </div>
        <div class="field-value">
            <div cdkDropList cdkDropListAutoScrollStep="20" (cdkDropListDropped)="onDrop($event)">
                <ng-container *ngIf="model.values !== undefined">
                    <div cdkDrag cdkDragLockAxis="y" class="option" *ngFor="let fieldValue of values; let i = index">
                        <div class="title required">
                            Field option {{i+1}}
                        </div>
                        <div class="option-value flex-row">
                            <img cdkDragHandle class="drag drag-icon" src="assets/icons/drag.svg">
                            <input [(ngModel)]="fieldValue.display" name="value-{{i}}" type="text"
                                class="smz-standard-input v2 flex-grow" autocomplete="off">
                            <div class="icon">
                                <app-icon *ngIf="hasClaim('CanCustomFieldDelete') && values.length > 1"
                                    (click)="removeValue(i)" icon="trash-outline"></app-icon>
                            </div>
                        </div>
                        <div *cdkDragPreview>
                            <div class="custom-field-drag-preview">
                                <img class="drag-icon" src="assets/icons/drag.svg">
                                Field option {{i+1}}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="add">
            <div class="button-text party-add flex-row" (click)="addValue()">
                <app-icon icon="add-circle-outline" class="button-text add-button"></app-icon>
                Add another option
            </div>
        </div>
    </div>