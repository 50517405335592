import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatTooltipModule } from '@angular/material/tooltip';
import { TextFieldModule } from '@angular/cdk/text-field';

import { ExpandableCardModule } from '../expandable-card/expandable-card.module';
import { IconModule } from '../icon/icon.module';
import { CorePipesModule } from '../pipes/pipes.module';
import { ClauseSummaryPanelComponent } from './clause-summary-panel.component';
import { ClauseSummaryPanelService } from './clause-summary-panel.service';
import { DirectivesModule } from '../directives/directives.module';
import { AlertBannerModule } from '../alert-banner/alert-banner.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TextFieldModule,
        IconModule,
        ExpandableCardModule,
        MatTooltipModule,
        CorePipesModule,
        DirectivesModule,
        AlertBannerModule
    ],
    exports: [
        ClauseSummaryPanelComponent
    ],
    declarations: [
        ClauseSummaryPanelComponent
    ],
    providers: [
        ClauseSummaryPanelService
    ]
})
export class ClauseSummaryPanelModule { }

export * from './clause-summary-panel.component'