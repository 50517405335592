import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService, EMPTY_GUID } from '@summize/shared/core';
import { RequestSubtype } from '@summize/shared/framework';

@Injectable({ providedIn: 'root' })
export class UserTaskService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getTaskInstancesForUser(type: RequestSubtype, documentId: string = undefined): Promise<any> {

        const baseUrl = this.getUserBaseUrl(true);

        return await this.http.get<any>(`${baseUrl}tasks/user?requestSubType=${type}&documentId=${documentId || EMPTY_GUID}`).toPromise();

    }

    public async getContract(contractId: string): Promise<any> {

        const request: any = await this.http
            .get(`${this.getUserBaseUrl(true)}review/information/${contractId}`)
            .toPromise();

        return request;

    }

    public async getTaskInstancesForDocument(documentId: string): Promise<any> {

        const baseUrl = this.getUserBaseUrl(true);

        return await this.http.get<any>(`${baseUrl}tasks/document/${documentId}`).toPromise();

    }

    public async completeTaskInstance(task: any): Promise<void> {

        const baseUrl = this.getUserBaseUrl(true);

        return await this.http.post<any>(`${baseUrl}tasks/document/${task.documentId}/${task.task.taskInstanceId}/complete`, {}).toPromise();

    }

    public async sendForSignature(contractId: string): Promise<any> {

        const baseUrl = this.getUserBaseUrl(true);

        return await this.http.post<any>(`${baseUrl}esignatures/autosend`, {

            MasterDocumentId: contractId

        }).toPromise();

    }

}