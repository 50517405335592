<div class="dialog v2 no-scroll">
    <div class="dialog__content no-scroll">
        <div class="dialogHeader">
            <h1 class="dialogHeader__title body__large">
                {{title}}
            </h1>
            <div class="dialog__line"></div>
        </div>

        <app-loading-spinner *ngIf="loading === true" class="spinner" visible="true"></app-loading-spinner>

        <ng-container *ngIf="loading === false">
            <div [@fadeInAnimation] class="dialog__body no-scroll">
                <p class="dialog__text fade-block">{{subtitle}}</p>
                <div class="clause-list scroll">
                    <div class="clause-group fade-block" *ngFor="let group of clauses | keyvalue">
                        <div class="clause-name">
                            {{group.key}}
                        </div>
                        <div class="checkbox-list">
                            <div class="checkbox-container" *ngFor="let docType of group.value">
                                <mat-checkbox class="checkbox" [value]="docType.id" [checked]="docType.checked" (change)="docType.checked = $event.checked">
                                    <div class="checkbox-text ellipsis" [matTooltip]="docType.displayName" [matTooltipShowDelay]="1000">{{
                                        docType.displayName}}</div>
                                </mat-checkbox>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="dialog__footer fade-block">
                <app-button class="back__button" type="tertiary" size="medium" mat-dialog-close>Cancel</app-button>
                <app-button [disabled]="hasSelectedItems === false" type="primary" size="medium" [mat-dialog-close]="selected()">Add selected</app-button>
            </div>
        </ng-container>

    </div>
</div>