<div>
  <mat-form-field class="chip-list" [class.error]="errorState" subscriptSizing="dynamic">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <mat-chip-grid #chipList aria-label="Item selection">
      <mat-chip-row *ngFor="let selectedItem of selectedItems" (removed)="removeItem(selectedItem)">
        <span class="mat-chip-text">{{selectedItem.value}}</span>
        <button matChipRemove>
          <app-icon icon="close" iconColor="#212128"></app-icon>
        </button>
      </mat-chip-row>
      <input [placeholder]="placeholder" #itemInput [formControl]="autocompleteFormControl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addItem($event)">
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let filteredItem of filteredItems | async" [value]="filteredItem.key">
        {{filteredItem.value}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>