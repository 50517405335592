<mat-form-field class="select-box" [class.error]="errorState" appearance="fill">
    <mat-select [multiple]="multiple" [placeholder]="placeholder" [value]="value" [required]="isRequired"
        panelClass="select-panel" [disabled]="disabled" disableOptionCentering (openedChange)="openedChange($event)"
        (selectionChange)="valueChanged($event.value)" [class.disabled]="disabled" [multiple]="multipleSelect"
        panelWidth="auto">
        <div *ngIf="canSearch === true" class="search-box">
            <app-search-bar placeholder="Search..." (onSearch)="onKey($event)" (onClearSearch)="clearSearch()"
                [stopPropagation]="true" />
        </div>
        <ng-container *ngIf="grouped === false" class="option">
            <mat-option *ngFor="let opt of options" [value]="opt.key" [matTooltip]="tooltips ? opt.value : undefined"
                [class.has-actions]="opt.commands !== undefined" [disabled]="opt.disabled === true">

                <span>{{opt.value}}</span>

                <div *ngIf="opt.secondaryValue" class="secondary-value">
                    {{opt.secondaryValue}}
                </div>

                <div *ngIf="opt.commands">
                    <app-icon [icon]="'ellipsis-horizontal-outline'" [matMenuTriggerFor]="menu"
                        (click)="$event.stopPropagation();$event.preventDefault();" />
                    <mat-menu #menu="matMenu">
                        <button *ngFor="let command of opt.commands" mat-menu-item
                            (click)="command.action()">{{command.value}}</button>
                    </mat-menu>
                </div>

            </mat-option>
        </ng-container>
        <ng-container *ngIf="grouped === true">
            <mat-optgroup *ngFor="let group of options | keyvalue; let index = index" [label]="group.key">
                <mat-option *ngFor="let opt of group.value" [value]="opt.key"
                    [matTooltip]="tooltips ? opt.value : undefined">
                    {{opt.value}}
                </mat-option>
            </mat-optgroup>
        </ng-container>
        <ng-container *ngIf="actions.length > 0">
            <hr class="separator">
            <mat-option *ngFor="let opt of actions" [value]="opt.key" [matTooltip]="tooltips ? opt.value : undefined"
                (click)="$event.preventDefault(); $event.stopPropagation(); opt.action()">
                <div class="action">
                    <app-icon *ngIf="opt.icon !== undefined" [icon]="opt.icon" />
                    <span [ngClass]="{'text': opt.icon !== undefined}">{{opt.value}}</span>
                </div>
            </mat-option>
        </ng-container>
    </mat-select>
</mat-form-field>