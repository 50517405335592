import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class ContractUrlGuard implements CanActivate {

    constructor(
        private router: Router) { }

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        const originalUrl = state.url.substring(1, state.url.length);

        const urlSegments = originalUrl.split('/');

        return this.router.navigateByUrl(`contract/${urlSegments[urlSegments.length - 1]}`)

    }

}
