import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AsyncErrorCountService {

    constructor(private http: HttpClient) { }

    public async get(endpoint: string, tenantId: string, tenantIdentifier: string): Promise<any> {

        const query = `exceptions | where customDimensions['RequestPath'] contains '${tenantIdentifier}' or customDimensions['RequestPath'] contains '${tenantId}' | count`;

        const data: any = await this.http.get(`${endpoint}/admin/api/1.0/app-insights/query?timespan=P7D&execute=${query}`).toPromise();

        return data.results.tables[0].rows[0];
    }

}