import { trigger, transition, query, stagger, style, animate } from "@angular/animations";

export const showFlyInFlyOut = trigger('showFlyInFlyOut', [
    transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms', style({ transform: 'translateX(0)' }))
    ]),
    transition(':leave', [
        animate('100ms', style({ transform: 'translateX(100%)' }))
    ])
]);