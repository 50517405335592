import { Component } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

import { ContractStatusService } from '../contractstatus-select/contractstatus-select.service';

@Component({
    selector: 'app-change-status-dialog',
    templateUrl: 'change-status-dialog.html',
    styleUrls: ['./change-status-dialog.scss']
})
export class ChangeStatusDialogComponent {

    public statuses: Array<any>;

    public selected: any;

    constructor(private service: ContractStatusService, public dialogRef: MatDialogRef<ChangeStatusDialogComponent>) { }

    public async ngOnInit(): Promise<any> {

        const results = await this.service.getDocumentStatuses(undefined, 'Manage');

        this.statuses = [...results.map(r => ({ key: r.id, value: r.statusName }))]

    }

    public save() {

        this.dialogRef.close({
            selected: this.selected
        });

    }

    public close() {

        this.dialogRef.close(undefined);

    }

}