import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';

export interface Clause {
    documentTypeName: string;
    documentTypeId: string;
    displayName: string;
    clauseId: string;
    standardPositionLink: string;
}

@Injectable({ providedIn: 'root' })
export class ClauseSelectorService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getClauses(mode: string): Promise<Array<Clause>> {

        return this.http.get<Array<Clause>>(`${this.getUserBaseUrl(true)}documentTypes/Clauses?resultType=${mode}&status=ContractReviewDocumentTypes`).toPromise();

    }

}