import { Component, Input, OnInit } from '@angular/core';
import { EMPTY_GUID } from '@summize/shared/core';
import { FadeInAnimation } from '../animations/fade-in.animation';

export interface ClientMatterModel {
    id: string;
    name: string;
    allFutureMatters: boolean;
    expanded?: boolean,
    matters: Array<{
        id: string,
        name: string;
        selected: boolean
    }>
}

@Component({
    selector: 'app-client-matter-selector',
    templateUrl: 'client-matter-selector.html',
    styleUrls: ['./client-matter-selector.scss'],
    animations: [FadeInAnimation]
})
export class ClientMatterSelectorComponent implements OnInit {

    @Input()
    public user: any;

    @Input()
    public clients: any;

    public model: Array<ClientMatterModel>;

    public ngOnInit() {

        this.model = this.createModel(this.clients);

    }

    public expand(client: ClientMatterModel): void {

        this.model.filter(m => m.id !== client.id).forEach(m => m.expanded = false);

        client.expanded = !client.expanded;

    }

    public selectAll(client: ClientMatterModel): void {

        client.matters.forEach(m => m.selected = true);

    }

    public getSelectedState(client: ClientMatterModel): 'some' | 'all' {

        return this.getSelectedCount(client) === client.matters.length ? 'all' : 'some';

    }

    public getSelectedCount(client: ClientMatterModel): number {

        return client.matters.filter(m => m.selected).length

    }

    public setAllMattersToValue(client: ClientMatterModel, value: boolean) {

        client.matters.forEach(m => m.selected = value);

        client.allFutureMatters = value;

    }

    public getModel(): Array<ClientMatterModel> {

        return this.model;

    }

    private createModel(result: any): Array<ClientMatterModel> {

        const matters: Array<{ clientId: string, matterId: string }>
            = this.user.visibility.clientMatters;

        const isAdmin
            = matters.find(m => m.clientId === EMPTY_GUID && m.matterId == EMPTY_GUID) !== undefined;

        const hasMatter = (matter: any, client: any) =>
            matters.find(m => m.clientId === client && m.matterId === matter) !== undefined;

        return result.clients.map((client: any) => {

            return {
                id: client.clientId,
                name: client.clientName,
                allFutureMatters: isAdmin || matters.find(m => m.clientId === client.clientId && m.matterId == EMPTY_GUID) !== undefined,
                expanded: false,
                matters: client.matters.map((matter: any) => {
                    return {
                        id: matter.matterId,
                        name: matter.matterName,
                        selected: isAdmin || hasMatter(matter.matterId, client.clientId) || matters.find(m => m.clientId === client.clientId && m.matterId == EMPTY_GUID) !== undefined
                    }
                })
            };

        });

    }

}