import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseTenantService, EMPTY_GUID } from '@summize/shared/core';
import { UserService } from '@summize/shared/framework';

@Injectable({ providedIn: 'root' })
export class UserPanelService extends BaseTenantService {

    constructor(private http: HttpClient, private userService: UserService) {

        super();

    }

    public async getAssignedContracts(): Promise<any> {

        const response: any = await this.http.get(`${this.getUserBaseUrl(true)}assignedto/me`).toPromise();

        const user = await this.userService.getUser();

        const isClientMatterEnabled = user.isClientMatterEnabled;

        return response
            .map(contract => {

                const path = isClientMatterEnabled !== true
                  ? 'Contracts'
                  : contract.clientId === EMPTY_GUID
                    ? `${contract.tenantName} > ${contract.clientName}`
                    : `${contract.tenantName} > ${contract.clientName} > ${contract.matterName}`;

                return {
                    title: contract.documentName,
                    documentLink: this.getDocumentPage(contract.clientId, contract.matterId, contract.documentId),
                    path: path,
                    pathLink: this.getFolderPage(contract.clientId, contract.matterId),
                    status: {
                        id: contract.documentStatusId,
                        text: contract.documentStatusName
                    }
                }
            });

    }

}
