import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'app-icon',
    templateUrl: 'icon.html',
    styleUrls: ['./icon.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class IconComponent {

    @Input()
    public icon: string = '';

    @Input()
    public size: string = '';

    @Input()
    public tooltip: string = undefined;

    @Input()
    public label: string = undefined;

    @Input()
    public disabled: boolean = false;

    @Input()
    public customSVG: string;

    @HostBinding('style.pointer-events')
    get pEvents(): string {
        if (this.disabled) {
            return 'none';
        }
        return 'auto';
    }
}