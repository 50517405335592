import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';

@Injectable({ providedIn: 'root' })
export class FeedbackService extends BaseTenantService {

    constructor(private httpClient: HttpClient) { super() }

    public save(type: string | undefined, details: string | undefined): Promise<void> {

        let requestUrl = `${this.getUserBaseUrl()}1.0/feedback`

        return this.httpClient.post(requestUrl, { type, details }).toPromise() as any;

    }

}