<app-block-button [matMenuTriggerFor]="menu" (menuOpened)="initSearch()" [display]="selectedString" type="v2"
    width="185" class="mr-2" [disabled]="isDisabled" [icon]="'chevron-down-outline'" [type]="'filter'">
</app-block-button>

<mat-menu #menu="matMenu" style="max-height: 100px" [xPosition]="menuPositionX" [yPosition]="menuPositionY">
    <div class="search">
        <div class="icon icon-search"></div>
        <input class="search-input" type="text" matInput [formControl]="searchValueControl"
            placeholder="Search statuses..." (click)="$event.stopPropagation()">
    </div>

    <ng-container *ngIf="!loading">
        <div mat-menu-item *ngFor="let contractStatus of contractStatuses"
            (click)="setDocumentsStatus(contractStatus.id, docIds)" class="status">
            <div class="status-pill" [style]="contractStatus.statusStyle">{{ contractStatus.statusName }}</div>
        </div>
    </ng-container>
</mat-menu>