<button [attr.type]="btnType ? btnType : null" [disabled]="disabled" [style.width]="getWidth()" [class]="getCssClass()"
    [class.has-icon]="icon !== undefined" [class.icon-only]="display === undefined || display === ''"
    [attr.aria-label]="label || display">

    <ng-container *ngIf="type === 'filter' || type === 'filter-selected'">

        <div *ngIf="display" class="button-text">
            {{display}}
        </div>

        <app-icon *ngIf="icon !== undefined && icon !== 'spinner'" (click)="onIconClicked($event)"
            [style.color]="iconColor ? iconColor : ''" [icon]="icon" [disabled]="disabled"
            aria-hidden="true"></app-icon>

        <app-loading-spinner *ngIf="icon !== undefined && icon === 'spinner'" [visible]="true">
        </app-loading-spinner>

    </ng-container>

    <ng-container *ngIf="type !== 'filter' && type !== 'filter-selected'">

        <app-icon *ngIf="icon !== undefined && icon !== 'spinner' && iconPosition ==='left'"
            (click)="onIconClicked($event)" [style.color]="iconColor ? iconColor : ''" [icon]="icon"
            [disabled]="disabled" aria-hidden="true"></app-icon>

        <app-loading-spinner *ngIf="icon !== undefined && icon === 'spinner'" visible="true" type="upload">
        </app-loading-spinner>

        <div *ngIf="display" class="button-text">
            @if("type === 'dropdown-ghost'"){
                <span class="button-prefix">{{prefix}}</span>
            }
            {{display}}
        </div>

        <app-icon *ngIf="icon !== undefined && icon !=='spinner' && iconPosition ==='right'"
            (click)="onIconClicked($event)" [style.color]="iconColor ? iconColor : ''" [icon]="icon"
            [disabled]="disabled" class="right" aria-hidden="true"></app-icon>
    </ng-container>
</button>