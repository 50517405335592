import { NgModule } from '@angular/core';

import { ClientMatterLocalizerPipe } from './clientmatterlocalizer.pipe';
import { MapPropertyPipe } from './mapproperty.pipe';
import { SafeHtmlPipe } from './safehtml.pipe';
import { TakeWordsPipe } from './takewords.pipe';

@NgModule({
  imports: [],
  declarations: [
    ClientMatterLocalizerPipe,
    MapPropertyPipe,
    SafeHtmlPipe,
    TakeWordsPipe,
  ],
  exports: [
    ClientMatterLocalizerPipe,
    MapPropertyPipe,
    SafeHtmlPipe,
    TakeWordsPipe,
  ]
})
export class PipesModule { }
