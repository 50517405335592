import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TableComponent, TableDatasource } from '@summize/shared/components';
import { TenantSettingService, TenantSettingTypes } from '@summize/shared/core';
import { debounceTime } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ClauseMetricDefinition, TrendDirection } from '../analytics.types';
import { MaskValue } from '../components/clause-metric/clause-metric.helper';

@Component({
    templateUrl: 'clause-result-table.html',
    styleUrls: ['./clause-result-table.scss']
})

export class ClauseResultTableComponent implements OnInit {

    public TrendDirection = TrendDirection;

    @ViewChild(TableComponent)
    public table: TableComponent;

    @ViewChild('clauseText', { static: true })
    public clauseTextTemplate!: TemplateRef<any>;

    @ViewChild('trend', { static: true })
    public trendTemplate!: TemplateRef<any>;

    @ViewChild('documentName', { static: true })
    public documentNameTemplate!: TemplateRef<any>;

    public datasource!: TableDatasource;

    public definition!: ClauseMetricDefinition;

    public graphId!: string;

    public clauseId!: string;

    public range!: string;

    public type!: string;

    public documentType!: string;

    public documentTypeName!: string;

    public clauseName!: string;

    public inputQuery!: string;

    private clientId!: string;

    private matterId!: string;

    private $queryChanged!: Subject<string>;

    constructor(private route: ActivatedRoute,
        private router: Router,
        private tenantSettingService: TenantSettingService) {

        this.$queryChanged = new Subject<string>();

    }

    public ngOnInit(): void {

        this.$queryChanged.pipe(debounceTime(250), distinctUntilChanged()).subscribe(async query => {

            this.inputQuery = query;

            this.table.reload();

        });

        this.route.params.subscribe(async (params: any) => {

            this.graphId = params.graphId;

            this.clauseId = params.clauseId;

            this.type = params.type;

            this.documentType = params.documentType;

            this.range = params.range;

            this.clientId = this.route.snapshot.queryParams['clientId'];

            this.matterId = this.route.snapshot.queryParams['matterId'];

            await this.GetMetricDefinition();

            this.datasource = {
                headers: [
                    { key: 'documentName', display: 'Contract Name', template: this.documentNameTemplate },
                    { key: 'delta', display: 'Against Avg', template: this.trendTemplate, },
                    { key: 'value', display: 'Value' },
                    { key: 'clauseResult', display: 'Clause Text', template: this.clauseTextTemplate },
                ],
                default: {
                    sort: {
                        field: 'DocumentName',
                        dir: 'desc'
                    }
                },
                queryProvider: () => {

                    if (this.inputQuery !== undefined && this.inputQuery !== null && this.inputQuery.length > 0) {

                        return `&query=${this.inputQuery}`;

                    }

                    return '';

                },
                onFetch: (data: any) => {

                    this.documentTypeName = data.documentTypeName;

                    this.clauseName = data.clauseName;

                },
                source: {
                    url: `1.0/analytics/clause/table/${this.clauseId}/${this.documentType}/${this.type}/${this.range}?clientId=${this.clientId}&matterId=${this.matterId}&`,
                    resultsField: 'records',
                    countField: 'totalResultsCount'
                },
                transformer: (result: any) => {
                    return {
                        ...result,
                        value: MaskValue(this.definition, result.valueText || result.value),
                        clauseText: this.getClauseText(result)
                    };
                },
                allowSelection: false
            };

        });

    }

    public goToContract(row: any) {

        this.router.navigate(['my-contracts/clients',
            row.clientId,
            'matters',
            row.matterId,
            'review',
            row.documentId
        ]);

    }

    public goBack() {

        window.history.back();

    }

    public getTrendDirection(trend: TrendDirection): string {

        return trend === TrendDirection.Up ? 'up' : 'down';

    }

    public search(event: { target: { value: string } }): void {

        this.$queryChanged.next(event.target.value);

    }

    private getClauseText(row: any) {

        if (row.clauseResult === undefined || row.clauseResult === '') {

            return 'No text..';

        } else {

            try {

                const parsed = JSON.parse(row.clauseResult);

                return parsed[0];

            } catch (error) {

                return 'No text..';

            }

        }

    }

    private async GetMetricDefinition(): Promise<void> {

        const config = await this.tenantSettingService.GetSetting(TenantSettingTypes.ManageAnalyticsConfiguration);

        if (config !== undefined) {

            try {

                const definitions: Array<ClauseMetricDefinition> = JSON.parse(config.settingValue);

                if (definitions !== undefined && definitions !== null) {

                    this.definition = definitions.find(d => d.id === this.graphId);

                }


            } catch (error) {

                console.log(`Failed to parse existing analytic configuration: ${config.settingValue}`);

            }

        }

    }
}