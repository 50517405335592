import { Component, Input } from '@angular/core';

import { SlidePanelComponent, SlidePanelContent } from '@summize/shared/components-v2';

import { FadeInAnimation } from '../animations/fade-in.animation';
import { SlideUpAnimation } from '../animations/slide-up.animation';

import { SelectBoxModel } from '../select-box/select-box.module';
import { FeedbackService } from './feedback.service';

interface FeedbackModel {
    type?: string;
    content?: string;
    complete?: boolean;
}

@Component({
    selector: 'app-feedback-panel',
    templateUrl: 'feedback-panel.html',
    styleUrls: ['./feedback-panel.scss'],
    animations: [FadeInAnimation, SlideUpAnimation]
})
export class FeedbackPanelComponent implements SlidePanelContent {

    public ready: boolean = true;

    public options: Array<SelectBoxModel> = [
        { key: 'General', value: 'General' },
        { key: 'Suggestion', value: 'Suggestion' },
        { key: 'Problem', value: 'Problem' },
        { key: 'Manage', value: 'Manage' },
    ];

    public model: FeedbackModel;

    private $parent: SlidePanelComponent;

    constructor(private service: FeedbackService) {

        this.model = {
            type: 'General',
            complete: false,
            content: ''
        };

    }

    public init(parent: SlidePanelComponent): void {

        this.$parent = parent;

    }

    public close(): void {

        this.$parent.destroy();

    }

    public async save(): Promise<void> {

        await this.service.save(this.model.type, `Source: Web App: ${this.model.content}`);

        this.model.complete = true;

    }

}