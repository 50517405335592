import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'app-info-panel',
    templateUrl: 'info-panel.html',
    styleUrls: ['./info-panel.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class InfoPanelComponent {

    @Input()
    public title: string;

    @Input()
    public text: string;


}