import { Component, Input } from '@angular/core';

import { AssistantChatMessage, AssistantChatMessageSource, AssistantSettings } from '../../chat.service';

@Component({
  selector: 'app-chat-messages',
  templateUrl: './chat-messages.component.html',
  styleUrls: ['./chat-messages.component.scss'],
})
export class ChatMessagesComponent {
  
  @Input()
  public chatMessages: AssistantChatMessage[] = [];

  @Input()
  public settings: AssistantSettings = undefined;

  public AssistantChatMessageSource = AssistantChatMessageSource;
  
}
