import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

function fetchEnvironmentConfig() {
  return new Promise((resolve, reject) => {
    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status === 200) {
          return resolve(JSON.parse(xhttp.responseText));
        } else {
          return reject(`EnvironmentAPI Error: ${this.status}`);
        }
      }
    }

    let hostName = window.location.hostname;

    // Searching for the subdomain

    const hostNameSplit: string[] = hostName.split('.');

    // First part should the subdomain

    if (hostNameSplit[0].includes('-')) {
        const subdomainSplit = hostNameSplit[0].substring(hostNameSplit[0].indexOf('-') + 1);
        environment.apiUrl = `https:\\\\api-${subdomainSplit}.summize.com`;
    }

    xhttp.open('GET', `${environment.apiUrl}/api/environment/admin`, false);
    xhttp.send();
  });
}

(async () => {
  const remoteEnvironment: any = await fetchEnvironmentConfig()
    .catch((err) => { console.log(err); return {}; });

  // Allow local values to override remote values
  if (environment.name === 'dev') {
    Object.keys(environment).forEach((key) => {
      if (environment[key] !== null) {
        remoteEnvironment[key] = null;
      }
    });
  }

  Object.keys(remoteEnvironment).forEach((key) => {
    if (remoteEnvironment[key] !== null) {
      // Prevent overwriting valid entries with empty string
      if (typeof remoteEnvironment[key] === "string" && environment[key] !== null) {
        if (remoteEnvironment[key].trim().length > 0) {
          environment[key] = remoteEnvironment[key];
        }
      } else {
        environment[key] = remoteEnvironment[key];
      }
    }
  });

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
})();