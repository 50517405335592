import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StyledButtonComponent } from './styled-button.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        StyledButtonComponent
    ],
    declarations: [
        StyledButtonComponent
    ]
})
export class StyledButtonModule { }

export * from './styled-button.component'