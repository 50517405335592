<div class="container">
    <div>
        {{clauseName}}
    </div>
    <div class="commands">
        <app-icon *ngIf="paragraphs.length >= 1" [icon]="'chevron-back-outline'" (click)="prev()"></app-icon>

        <span>{{currentParagraphIndex + 1}} of {{paragraphs.length}} Related Paragraphs</span>

        <app-icon *ngIf="paragraphs.length >= 1" [icon]="'chevron-forward-outline'" (click)="next()"></app-icon>

    </div>
</div>