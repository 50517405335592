<app-block-button [disabled]="disabled" [display]="display"  [width]="width" type="v2" iconColor="#A3A3AD" [matMenuTriggerFor]="menu"
    (click)="init()" icon="folder-open">
</app-block-button>

<mat-menu #menu="matMenu">

    <mat-list *ngIf="visibleMenu === clientMatterVisibleMenu.Clients">
        <div *ngIf="loading">
            <app-loading-spinner visible="true"></app-loading-spinner>
        </div>
        <div *ngIf="!loading">
            <div class="search">
                <div class="icon icon-search"></div>
                <input class="search-input" type="text" matInput [(ngModel)]="clientQuery"
                    [placeholder]="'Search clients...' | clientmatterlocalizer" (click)="$event.stopPropagation()">
            </div>
            <mat-list-item *ngFor="let client of clients | search: clientQuery"
                (click)="selectedClient($event, client.clientId)">
                {{client.name}} </mat-list-item>
        </div>
    </mat-list>

    <mat-list *ngIf="visibleMenu === clientMatterVisibleMenu.Matters">
        <div *ngIf="loading">
            <app-loading-spinner visible="true"></app-loading-spinner>
        </div>
        <div *ngIf="!loading">
            <div class="menu-header">
                <app-icon (click)="showClientsList($event)" class="left" icon="chevron-back-outline"></app-icon>
                <span>{{currentClient.name}}</span>
            </div>
            <div *ngIf="matters.length > 0">
                <div class="search">
                    <div class="icon icon-search"></div>
                    <input class="search-input" type="text" matInput [(ngModel)]="matterQuery"
                        [placeholder]="'Search matters...' | clientmatterlocalizer" (click)="$event.stopPropagation()">
                </div>
                <mat-list-item *ngFor="let matter of matters | search: matterQuery"
                    (click)="selectedMatter($event, matter.matterId)">
                    {{matter.name}} </mat-list-item>
            </div>
            <div *ngIf="matters.length === 0" class="no-data">
                No {{'matters' | clientmatterlocalizer}} found.
            </div>

        </div>

    </mat-list>

    <mat-list *ngIf="visibleMenu === clientMatterVisibleMenu.ContractTypes">
        <div *ngIf="loading">
            <app-loading-spinner visible="true"></app-loading-spinner>
        </div>
        <div *ngIf="!loading">
            <div class="menu-header">
                <app-icon (click)="showMattersList($event)" class="left" icon="chevron-back-outline"></app-icon>
                <span>{{currentMatter.name}}</span>
            </div>
            <div *ngIf="contractTypes.length > 0">
                <div class="search">
                    <div class="icon icon-search"></div>
                    <input class="search-input" type="text" matInput [(ngModel)]="contractTypeQuery"
                        placeholder="Search contract types..." (click)="$event.stopPropagation()">
                </div>
                <mat-list-item *ngFor="let contractType of contractTypes | search: contractTypeQuery"
                    (click)="selectedContractType(contractType)">
                    {{contractType.name}} </mat-list-item>
            </div>
            <div *ngIf="contractTypes.length === 0" class="no-data">
                No contract types found.
            </div>
        </div>

    </mat-list>

</mat-menu>