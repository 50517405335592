import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AppComponent } from '@summize/shared/core';
import { EventService, ShowPanelEvent } from '@summize/shared/framework';

import { AlertBannerThemes } from '../alert-banner/alert-banner.component';

enum DocumentClauseResultState {
    Complete = 0,
    AiPolicyViolationFallback = 101
}

export interface Clause {
    clauseId: number;
    ruleName: string;
    document: any;
    ruleResult: ClauseRuleResult[];
    clauseComments: ClauseComment[];
    documentTypes: any;
    isFlagged: boolean;
    state: DocumentClauseResultState;
}

export interface ClauseRuleResult {
    boundingBox: number[];
    pageNumber: number;
    paragraphNumber: string;
    resultText: string;
    isEditing?: boolean;
    documentId: string;
}

export interface ClauseComment {
    clauseId: number;
    comment: string;
    commentId?: string;
    createdAt?: Date;
    createdBy?: string;
    createdByName?: string;
    documentId?: string;
    updatedAt?: Date;
    updatedBy?: string;
    updatedByName?: string;
    isEditing?: boolean;
}

export interface ClauseBulletAddRequest {
    clauseId: number;
    clauseIndex: number;
    clauseName: string;
    clauseText: string;
    clientId?: string;
    matterId?: string;
    pageNumber?: number;
    points?: any;
    linkedDocumentId?: string;
    newPara?: string;
}

export interface ClauseBulletUpdateRequest {
    clauseId: number;
    clauseIndex: number;
    clauseName: string;
    clauseText: string;
    clientId?: string;
    matterId?: string;
    newPara: string;
}

@Component({
    selector: 'app-clause-summary-panel',
    templateUrl: 'clause-summary-panel.html',
    styleUrls: ['./clause-summary-panel.scss']
})
export class ClauseSummaryPanelComponent extends AppComponent implements OnInit {

    @Input()
    public clause: Clause;

    @Input()
    public documentId: string;

    @Input()
    public linkedDocumentId: string;

    @Input()
    public isLatest = false;

    @Input()
    public highlightedSelectionContext = undefined;

    @Input()
    public isChildView = false;

    @Input()
    public selectedBullet: string;

    @Input()
    public bulletFromCopyEnabled: boolean;

    @Input()
    public readOnly: boolean = false;

    @Output()
    public onAddComment: EventEmitter<{ clauseId: number, commentText: string; }>;

    @Output()
    public onUpdateComment: EventEmitter<{ clauseId: number, commentId: string, commentText: string; }>;

    @Output()
    public onDeleteComment: EventEmitter<{ clauseId: number, commentId: string; }>;

    @Output()
    public onUpdateRedflag: EventEmitter<{ clauseId: number, isFlagged: boolean; }>;

    @Output()
    public onUpdateClauseBullet: EventEmitter<{ clauseBulletUpdateRequest: ClauseBulletUpdateRequest; }>;

    @Output()
    public onDeleteClauseBullet: EventEmitter<{ clauseIds: number[], clauseName: string, clauseIndex: number; }>;

    @Output()
    public onShowPlaybookForClause: EventEmitter<{ clause: Clause; }>;

    @Output()
    public onShowOpenAiForClause: EventEmitter<{ clause: Clause; }>;

    @Output()
    public onClauseBulletClick: EventEmitter<{ clause: Clause, ruleResult: ClauseRuleResult; }>;

    @Output()
    public addBulletFromCopyContext: EventEmitter<{ clause: Clause; }>;

    public AlertBannerThemes = AlertBannerThemes;

    public DocumentClauseResultState = DocumentClauseResultState;

    public clauseSubtitle: string;

    public clauseSubtitleHover: string;

    public isOpenAiEnabled = false;

    public isExpanded = true;

    public showMe = false;

    public canCreateConversations = false;

    private canDocumentsUpdate = false;

    private isAddingComment = false;

    public get clauseSummaryReadOnly() {

        if (this.readOnly) {
            return true;
        }

        return !this.canDocumentsUpdate;

    }

    constructor(private events: EventService) {

        super();

        this.onAddComment = new EventEmitter<{ clauseId: number, commentText: string; }>();

        this.onUpdateComment = new EventEmitter<{ clauseId: number, commentId: string, commentText: string; }>();

        this.onDeleteComment = new EventEmitter<{ clauseId: number, commentId: string; }>();

        this.onUpdateRedflag = new EventEmitter<{ clauseId: number, isFlagged: boolean; }>();

        this.onUpdateClauseBullet = new EventEmitter<{ clauseBulletUpdateRequest: ClauseBulletUpdateRequest; }>();

        this.onDeleteClauseBullet = new EventEmitter<{ clauseIds: number[], clauseName: string, clauseIndex: number; }>();

        this.onShowPlaybookForClause = new EventEmitter<{ clause: Clause; }>();

        this.onShowOpenAiForClause = new EventEmitter<{ clause: Clause; }>();

        this.onClauseBulletClick = new EventEmitter<{ clause: Clause, ruleResult: ClauseRuleResult; }>();

        this.addBulletFromCopyContext = new EventEmitter<{ clause: Clause; }>();
    }

    public async ngOnInit() {

        this.isOpenAiEnabled = this.hasFeatureFlag('OpenAIEnabled') && this.user.isAIEnabled;

        // Add an edit bool to switch from view to edit
        this.clause?.ruleResult?.map(x => {
            x.isEditing = false;
            return x;
        });

        // Add an edit bool to switch from view to edit
        this.clause?.clauseComments?.map(x => {
            x.isEditing = false;
            return x;
        });

        const otherContractTypes = this.clause.documentTypes.length > 2 ? 'contract types' : 'contract type';

        if (this.clause.documentTypes.length > 1) {

            this.clauseSubtitle =
                `${this.clause.documentTypes[0].documentTypeName} + ${this.clause.documentTypes.length - 1} other ${otherContractTypes}`;
        }


        this.clauseSubtitleHover = this.clause.documentTypes.map(x => x.documentTypeName).join(', ');

        this.canCreateConversations = this.hasClaim(this.Claims.Conversations.CanConversationCreate);

        this.canDocumentsUpdate = this.hasClaim(this.Claims.Documents.CanDocumentsUpdate);
    }

    public expandCard(expanded: boolean) {

        this.isExpanded = expanded;
    }

    public onBulletFromCopyContext(clause): void {

        this.addBulletFromCopyContext.emit(clause);

    }

    public addComment(clause: Clause) {

        // Allow one clause comment

        if (this.isAddingComment === true) {

            return;
        }

        if (clause.clauseComments.length > 0) {

            this.editComment(clause.clauseComments[0]);

            return;
        }

        this.isAddingComment = true;

        const newComment: ClauseComment = {
            clauseId: clause.clauseId,
            documentId: this.documentId,
            isEditing: true,
            comment: '',

        };

        this.clause.clauseComments.push(newComment);

    }

    public editComment(comment: any) {

        if (this.isAddingComment === true) {

            return;
        }

        comment.isEditing = true;

        this.isAddingComment = true;
    }

    public async saveComment(comment: ClauseComment) {

        if (comment.comment === '') {
            return;
        }

        this.isAddingComment = false;

        comment.isEditing = false;

        if (comment.commentId !== undefined) {

            this.onUpdateComment.next({ clauseId: this.clause.clauseId, commentId: comment.commentId, commentText: comment.comment });

        } else {

            this.onAddComment.next({ clauseId: this.clause.clauseId, commentText: comment.comment });

            // Remove the dummy entry
            const foundComment = this.clause.clauseComments.find(x => x.comment === comment.comment);

            if (foundComment !== undefined) {

                this.clause.clauseComments.splice(this.clause.clauseComments.indexOf(foundComment), 1);
            }

        }
    }

    public deleteComment(comment: any) {

        comment.isEditing = false;

        this.onDeleteComment.next({ clauseId: this.clause.clauseId, commentId: comment.commentId });
    }

    public closeComment(comment: ClauseComment) {

        comment.isEditing = false;

        this.isAddingComment = false;

        // Fresh comment not saved
        if (comment.createdAt === null || comment.createdAt === undefined) {
            const idx = this.clause.clauseComments.indexOf(comment);
            this.clause.clauseComments.splice(idx, 1);
        }
    }

    public editBullet(bullet: any) {

        bullet.isEditing = true;
    }

    public saveBullet(bullet: ClauseRuleResult, idx: number) {

        const request: ClauseBulletUpdateRequest = {
            clauseId: this.clause.clauseId,
            clauseIndex: idx,
            clauseName: this.clause.ruleName,
            clauseText: bullet.resultText,
            newPara: bullet.paragraphNumber
        };

        this.onUpdateClauseBullet.next({ clauseBulletUpdateRequest: request });

        bullet.isEditing = false;
    }

    public deleteBullet(bullet: ClauseRuleResult, idx: number) {

        bullet.isEditing = false;

        const associatedClauses = this.clause.documentTypes.map(x => x.clauseId);

        this.onDeleteClauseBullet.next({ clauseIds: associatedClauses, clauseName: this.clause.ruleName, clauseIndex: idx });
    }

    public clauseBulletClicked(clause: Clause, ruleResult: ClauseRuleResult) {

        this.onClauseBulletClick.next({ clause: clause, ruleResult: ruleResult });
    }

    public toggleRedflag() {

        this.clause.isFlagged = !this.clause.isFlagged;

        this.onUpdateRedflag.next({ clauseId: this.clause.clauseId, isFlagged: this.clause.isFlagged });
    }

    public showPlaybook(clause: Clause) {

        this.isChildView = true;

        this.onShowPlaybookForClause.next({ clause: clause });
    }

    public showOpenAiClauseReplacer(clause: Clause) {

        this.isChildView = true;

        this.onShowOpenAiForClause.next({ clause: clause });
    }

    public getClauseBulletId(clause: Clause, ruleResult: ClauseRuleResult) {

        return `${clause.clauseId}:${ruleResult.paragraphNumber}`;

    }

    public beginConversation(clause: Clause) {

        this.events.despatch(ShowPanelEvent, {
            panelId: 'conversations',
            panelFunction: 'beginConversationFromText',
            arguments: {
                text: {
                    title: `Clause - ${clause.ruleName}`
                }
            }
        });

    }

}