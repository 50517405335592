<app-block-button [matMenuTriggerFor]="menu" (menuOpened)="initSearch()" [display]="selectedString" type="v2"
    width="185" class="mr-2" [disabled]="isDisabled" [icon]="'chevron-down-outline'" [type]="'filter'">
</app-block-button>

<mat-menu #menu="matMenu" style="max-height: 100px" [xPosition]="menuPositionX" [yPosition]="menuPositionY"
    [class]="'assign-menu'">
    <div class="search">
        <div class="icon icon-search"></div>
        <input class="search-input" type="text" matInput [formControl]="searchValueControl"
            placeholder="Search users..." (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
    </div>
    <ng-container *ngIf="!loading">
        <div mat-menu-item *ngFor="let user of users" (click)="assignDocuments(user.id, docIds)" class="user">
            <app-avatar bgColor="#0066FF" size="32" class="user-avatar" [name]="user.firstName + ' ' + user.lastName">
            </app-avatar>
            <div class="user-text">
                <div class="user-name text-ellipsis">{{ user.firstName }} {{ user.lastName }}</div>
                <div class="user-email text-ellipsis">{{ user.email }}</div>
            </div>
        </div>

        <div mat-menu-item class="unassign" (click)="unassignDocuments(docIds)">
            <div class="unassign-text">Unassign</div>
        </div>
    </ng-container>
</mat-menu>