<app-expandable-card *ngIf="conversation  !== undefined" [commands]="readOnly === true ? undefined : cardCommands"
    [title]="conversation?.subject" [border]="!borderless">
    <div *ngIf="conversation !== undefined" class="conversation">
        <div class="messages">
            <div *ngFor="let message of conversation.messages; let odd = odd" class="message">
                <div class="avatar fade-block">
                    <app-avatar [bgColor]="odd ? '#6F728B' : '#0066FF'" size="42" class="avatar"
                        [name]="message.createdByName">
                    </app-avatar>
                </div>
                <div class="message-content fade-block">
                    <div class="meta">
                        <div class="user-content">
                            <span class="user">{{message.createdByName}}</span><span class="subtext">
                                {{message.createdAt | smzdate:'medium'}}</span>
                        </div>
                        <ng-container *ngIf="readOnly === false">
                            <div *ngIf="isMessageOwner(message) === false" class="commands">
                                <app-icon (click)="quoteMessage(message)" class="clickable"
                                    icon="chatbubble-ellipses-outline"></app-icon>
                            </div>
                            <div *ngIf="isMessageOwner(message) === true" class="commands">
                                <app-icon (click)="editMessage(message)" class="clickable" icon="create-outline">
                                </app-icon>
                                <app-icon *ngIf="conversation.messages.length > 1" (click)="deleteMessage(message)"
                                    class="clickable" icon="trash-outline">
                                </app-icon>
                            </div>
                        </ng-container>
                    </div>
                    <div class="message-content fade-block">
                        <app-conversation-input [readonly]="true" [content]="message.html"
                            [showEntities]="contractId !== undefined" [contractId]="contractId" 
                            [conversationId]="conversation?.contractConversationId" [users]="users">
                        </app-conversation-input>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="readOnly === false">
            <div *ngIf="state === EditingState.Initial" (click)="state = EditingState.Creating"
                class="clickable reply-icon fade-block">
                <app-icon icon="arrow-undo-outline">
                </app-icon> Reply
            </div>
            <div *ngIf="state === EditingState.Creating" class="reply fade-block">
                <div class="convo-container">
                    <app-conversation-input #pending [readonly]="false" [showEntities]="contractId !== undefined" [contractId]="contractId"
                        [conversationId]="conversation?.contractConversationId" [display]="'full'" [users]="users"></app-conversation-input>
                </div>
                <div class="command-container">
                    <app-icon (click)="cancelMessage()" class="cancel clickable" icon="close-sharp">
                    </app-icon>
                    <app-icon (click)="addMessageComplete()" [disabled]="hasValidMessage(pending) === false"
                        class="clickable" icon="send-sharp"></app-icon>
                </div>
            </div>
            <div *ngIf="state === EditingState.Editing" class="reply fade-block">
                <div class="convo-container">
                    <app-conversation-input #editing [readonly]="false" [showEntities]="contractId !== undefined" [contractId]="contractId"
                    [conversationId]="conversation?.contractConversationId" [users]="users" [content]="editingMessage.html">
                    </app-conversation-input>
                </div>
                <div class="command-container">
                    <app-icon (click)="cancelMessage()" class="cancel clickable" icon="close-sharp">
                    </app-icon>
                    <app-icon (click)="editMessageComplete()" [disabled]="hasValidMessage(editing) === false"
                        class="clickable" icon="create-outline"></app-icon>
                </div>
            </div>
        </ng-container>
    </div>
</app-expandable-card>