import { Component, EventEmitter, Input, Output } from '@angular/core';

import { EMPTY_GUID } from '@summize/shared/core';
import { FadeInAnimation } from '@summize/shared/framework';

@Component({
    selector: 'app-contract-type-selector',
    templateUrl: './contract-type-selector.html',
    styleUrls: ['./contract-type-selector.scss'],
    animations: [
        FadeInAnimation
    ]
})

export class ContractTypeSelectorComponent {

    @Output()
    public selectedChanged: EventEmitter<Array<any>>

    @Input()
    public types: Array<{
        documentTypeId: string,
        documentTypeName: string,
        _selected: boolean
    }>;

    public collapsed: boolean = true;

    constructor() {

        this.selectedChanged = new EventEmitter<Array<any>>();

    }

    public get allTypes() {

        return this.types.filter(t => t.documentTypeId !== EMPTY_GUID);

    }

    public checkedChanged(): void {

        this.selectedChanged.emit(
            this.types.filter(r => r._selected === true)
        );

    }

}