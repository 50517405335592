<app-block-button (click)="isOpen = !isOpen" display="{{displayText}}" type="v2" width="200" class="mr-2"
    [iconPosition]="'right'" cdkOverlayOrigin #trigger="cdkOverlayOrigin" icon="chevron-down-outline">
</app-block-button>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen"
    (overlayOutsideClick)="discardChanges()" [cdkConnectedOverlayHasBackdrop]="true"
    cdkConnectedOverlayBackdropClass="transparent-backdrop">
    <div class="datetime-panel">

        <!-- Presets -->
        <div class="panel-content presets">
            <div *ngFor="let preset of presets" (click)="changePreset(preset.key)" class="preset"
                [ngClass]="{'preset-selected': preset.value === selectedPreset.value}">{{preset.value}}</div>
        </div>
        <div class="vert-line">

        </div>
        <!-- Datepickers -->
        <div class="panel-content">
            <div class="date-container">
                <div class="date">
                    <div class="title">
                        From:
                    </div>
                    <input type="date" (ngModelChange)="onDateFromChanged($event)" [ngModel]="dateFrom"
                        class="smz-standard-input v2" />
                </div>
                <div class="date">
                    <div class="title">
                        To:
                    </div>
                    <input type="date" (ngModelChange)="onDateToChanged($event)" [ngModel]="dateTo"
                        class="smz-standard-input v2" />
                </div>
            </div>

            <div class="commands">
                <app-block-button (click)="discardChanges()" display="Cancel" type="v2-blue" width="100" class="mr-2">
                </app-block-button>

                <app-block-button (click)="apply()" display="Apply" type="v2-primary" width="100" class="mr-2">
                </app-block-button>

            </div>
        </div>

    </div>
</ng-template>