<mat-form-field class="select-box" appearance="fill">
    <mat-select [multiple]="multiple" [placeholder]="placeholder" [value]="value" [required]="isRequired"
        panelClass="select-panel" [disabled]="disabled" disableOptionCentering
        (selectionChange)="valueChanged($event.value)" [class.disabled]="disabled"
        (openedChange)="openedChange($event)">
        <div *ngIf="canSearch === true" class="search-box">
            <app-search-bar placeholder="Search..." (onSearch)="onKey($event)" [stopPropagation]="true"></app-search-bar>
        </div>
        <ng-container *ngIf="grouped === false">
            <mat-option *ngFor="let opt of options" [value]="opt.key" [matTooltip]="tooltips ? opt.value : undefined">
                {{opt.value}}
            </mat-option>
        </ng-container>
        <ng-container *ngIf="grouped === true">
            <mat-optgroup *ngFor="let group of options | keyvalue; let index = index" [label]="group.key">
                <mat-option *ngFor="let opt of group.value" [value]="opt.key"
                    [matTooltip]="tooltips ? opt.value : undefined">
                    {{opt.value}}
                </mat-option>
            </mat-optgroup>
        </ng-container>
    </mat-select>
</mat-form-field>