import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OverlayModule } from '@angular/cdk/overlay';

import { TooltipDirective } from './tooltip.directive';
import { TooltipComponent } from './tooltip.component';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule
    ],
    exports: [
        TooltipDirective,
        TooltipComponent
    ],
    declarations: [
        TooltipDirective,
        TooltipComponent
    ]
})
export class TooltipModule { }

export * from './tooltip.directive';
export * from './tooltip.component'