import { ConnectorIntegrationTypes, ESignatureAutoSendModel, ESignatureRecipientOrigin, TaskDefinitionModel } from "@summize/shared/framework";

export interface CreateTemplate {
    createTemplateId: string;
    name: string;
    displayName?: string;
    documentTypeId?: string;
    documentTypeName?: string;
    description?: string;
    isLocked?: boolean;
    templateStatus?: number;
    processingStatus?: number;
    totalQuestions?: number;
    questions?: Array<TemplateQuestion>;
    accesses?: Array<TemplateAccess>;
}

export interface TemplateAccess {
    id: string;
    groupId: string;
    group?: { groupName: string };
}

export interface TemplateNotification {
    id: string;
    notificationEntityType: number;
    notificationEntityId: string;
    displayName?: string;
}

export interface TemplateDetail {
    name: any;
    description: string;
    details?: string;
    displayName: string;
    markup: string;
    createTemplateId: string;
    templateStatus: number;
    processingStatus: number;
    questions: Array<TemplateQuestion>;
    inputQuestions: Array<TemplateQuestion>;
    conditionalQuestions: Array<TemplateQuestion>;
    accesses: Array<TemplateAccess>;
    notifications: Array<TemplateNotification>;
    isRestrictedAccess?: boolean;
    isPdfFormat?: boolean;
    isCompanyNumberRequired: boolean;
    tasks?: Array<TaskDefinitionModel>;
    assignedTo: string;
    originalFile?: any;
    eSignatureAutoSendDetail?: ESignatureAutoSendModel;
    lookupSettings?: LookupSettings;
    templatePermissions?: Array<any>;
    templateQuestions?: Array<TemplateQuestionDetail>;
    isContractQuestionFieldMappingEnabled?: boolean;
    isTemplateQuestionFieldMappingEnabled?: boolean;
}

export interface TemplateReplaceDetails extends TemplateDetail
{
    changes: CreateTemplateReplaceChangesResponse;
}

export interface TemplateQuestion {
    standardPosition: string;
    options: any;
    tokenId: any;
    value: any;
    createQuestionId: string;
    createTemplateId: string;
    tenantId: string;
    departmentId: string;
    description: string;
    rawString: string;
    questionType: number;
    parentSections?: string[];
    visible?: boolean;
    _display: {
        description?: string;
        rawString?: string;
    },
    mappings?: Array<{ connector: ConnectorIntegrationTypes, value: string }>
    fieldMappings?: string;
    isConditionalQuestion?: boolean;
    allowManualSearch?: boolean;
    subText?: string;
    parentId?: string;
}

export interface CreateTemplateFileUploadStatus {
    documentId?: string;
    progress?: number;
    complete?: boolean;
}

export interface CreateTemplateUploadData {
    createTemplateId?: string;
    name: string;
    size: number;
    type: string;
}

export enum QuestionType {
    Text = 0,
    Date = 1,
    Number = 2,
    Company = 3,
    Playbook = 4,
    Option = 5,
    Guidance = 6,
    ConditionalSection = 7,
    ConditionalSelect = 8,
    Docusign = 9,
    Group = 10
}

export interface LookupSettings {
    lookupRequired: boolean;
    lookupId: string;
    lookupName: string;
    lookupDescription: string;
}

export interface TemplateQuestionDetail {
    createTemplateId?: string;
    departmentId?: string;
    description: string;
    fieldMappings?: string;
    mappings?: Array<{ connector: ConnectorIntegrationTypes, value: string }>
    index: number;
    questionId: string;
    questionType: number;
    quickSummaryQuestionSetId?: string;
    tenantId?: string;
    typeMeta: string;
}

export interface CreateTemplateReplaceChangesResponse {
    contractQuestions: Array<CreateTemplateContractQuestionChangeResponse>;
    eSignatureAutoSend: CreateTemplateESignatureAutoSendChangeResponse;
    tasks: Array<CreateTemplateTaskChangeResponse>;
}

interface CreateTemplateContractQuestionChangeResponse {
    tokenId: string;
    description: string;
    replaceChangeType: CreateTemplateReplaceChangeType;
}

interface CreateTemplateESignatureAutoSendChangeResponse {
    enabled: boolean;
    disabled: boolean;
    recipients: CreateTemplateESignatureAutoSendRecipientChangeResponse[];
}

interface CreateTemplateESignatureAutoSendRecipientChangeResponse {
    tokenId: string;
    recipientOrigin: ESignatureRecipientOrigin;
    replaceChangeType: CreateTemplateReplaceChangeType;
}

interface CreateTemplateTaskChangeResponse {
    id: string
    taskConditionTargetQuestions: Array<string>;
    replaceChangeType: CreateTemplateReplaceChangeType;
}

export enum CreateTemplateReplaceChangeType
{
    Unknown = 0,
    Added = 1,
    Updated = 2, 
    Deleted = 3
};