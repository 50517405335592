import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

import { GotoLatestDocumentComponent } from './goto-latest-document.component';
import { DialogShellModule } from '../dialog-shell/dialog-shell.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DialogShellModule,
        LoadingSpinnerModule
    ],
    exports: [
      GotoLatestDocumentComponent
    ],
    declarations: [
      GotoLatestDocumentComponent
    ]
})
export class GotoLatestDocumentModule { }

export * from './goto-latest-document.component'
