import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { GotoLatestDocumentService, LatestDocument } from './goto-latest-document.service';

export enum DisplayMode {
    Request = 'request',
    Manage = 'contract'
}

@Component({
    selector: 'app-goto-latest-document',
    templateUrl: 'goto-latest-document.html',
    styleUrls: ['./goto-latest-document.scss']
})
export class GotoLatestDocumentComponent {

    public isLoading: boolean = true;

    public documentId: any;

    public latestDocument: LatestDocument;

    public displayMode: DisplayMode = DisplayMode.Request;

    public selectedPanel: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<GotoLatestDocumentComponent>,
        public gotoLatestDocumentService: GotoLatestDocumentService,
        private router: Router
    ) {

        this.documentId = data.documentId;

        this.selectedPanel = data.selectedPanel;

        if (data.displayMode === 'manage') {

            this.displayMode = DisplayMode.Manage;
            
        }

    }

    public async ngOnInit() {

      this.latestDocument = await this.gotoLatestDocumentService.getLatestDocument(this.documentId);

      this.isLoading = false;

    }

    public close() {

        this.dialogRef.close(undefined);

    }

    public viewDocument() {

      const documentUrl = this.gotoLatestDocumentService.getDocumentPage(
        this.latestDocument.clientId,
        this.latestDocument.matterId,
        this.latestDocument.documentId);

        if (this.selectedPanel) {

            this.router.navigateByUrl(`${documentUrl}?panelId=${this.selectedPanel}`, { replaceUrl: true });
            
        } else {

            this.router.navigateByUrl(documentUrl);

        }

        this.dialogRef.close(undefined);

    }

    public isSaveDisabled() {

      return this.latestDocument === undefined;

    }
}
