<div class="widget-host">

    <div *ngIf="isLoading; else initChart">
        <app-loading-spinner visible="true"></app-loading-spinner>
    </div>

    <ng-template #initChart>

        <div class="title-bar">

            <div class="title">{{widget.title}}</div>

            <div class="commands">

                <div class="mr-2">
                    <app-datetime-filter *ngIf="showFilters == true && hasTimeFilter === true"
                        [dateFilter]="widget.dateFilter" class="mr-8px"
                        (onDateChangedEvent)="onDateChangedEvent($event)"></app-datetime-filter>
                </div>

                <div *ngIf="showFilters === true && widget?.supportsTimeFormat === true" class="mr-2 select-box">
                    <app-block-button (click)="isTimeFormatPickerOpen = !isTimeFormatPickerOpen"
                        display="{{selectedTimeFormat.value}}" type="v2" width="100" class="mr-2"
                        [iconPosition]="'right'" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
                        icon="chevron-down-outline">
                    </app-block-button>
                    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger"
                        [cdkConnectedOverlayOpen]="isTimeFormatPickerOpen" (overlayOutsideClick)="discardChanges()"
                        [cdkConnectedOverlayHasBackdrop]="true" cdkConnectedOverlayBackdropClass="transparent-backdrop">
                        <div class="timeformat-panel">

                            <!-- Presets -->
                            <div class="panel-content presets">
                                <div *ngFor="let opt of timeFormatOptions" (click)="onTimeFormatChanged(opt.key)"
                                    class="preset">
                                    {{opt.value}}</div>
                            </div>
                        </div>
                    </ng-template>
                </div>

                <div *ngIf="showFilters" class="mr-2">
                    <app-filter-builder (onApply)="applyFilters($event)" [filters]="availableFilters"
                        [selected]="selectedFilters" class="mr-8px"></app-filter-builder>
                </div>

                <app-block-button *ngIf="hasChart" (click)="viewChart()"
                    [type]="currentView === 1 ? 'v2-primary' : 'v2'" width="40" class="flat-right"
                    icon="stats-chart-outline">
                </app-block-button>

                <app-block-button *ngIf="hasTable" (click)="viewTable()"
                    [type]="currentView === 2 ? 'v2-primary' : 'v2'" width="40" class="mr-2 flat-left"
                    icon="list-outline">
                </app-block-button>

                <app-ellipses-menu *ngIf="showCommands === true" [commands]="widgetCommands"
                    [icon]="'ellipsis-vertical-outline'" class="user-menu">
                </app-ellipses-menu>
            </div>

        </div>

        <div *ngIf="selectedFilters !== undefined && selectedFilters.length > 0" class="selected-filters flex-row">
            <app-selected-filter *ngFor="let filter of selectedFilters" (onEdit)="editFilter(filter)"
                (onRemove)="removeFilterParam(filter)" [filter]="filter" [context]="availableFilters" class="mt-3 mr-3">
            </app-selected-filter>
            <app-block-button (click)="clearAllFilters()" [display]="'Clear all'" type="outline" [width]="145"
                class="clear-all" [attr.data-cy]="'Clear all'">
            </app-block-button>
        </div>

        <div>

            <div class="search">
                <app-search-bar *ngIf="currentView === 2" placeholder="Search..." (onSearch)="onKey($event)"
                    [query]="searchText" (onClearSearch)="onClearSearch()"></app-search-bar>
            </div>

            <div *ngIf="currentView === 1; else tableView" class="chart">
                <app-chart-widget id="chart" [chartMeta]="chartElement" [widgetFilters]="widget.appliedFilters"
                    [showSampleData]="showSampleData" [dateFrom]="widget.dateFilter?.dateFrom"
                    [timeFormat]="selectedTimeFormat.key" [dateFilterPreset]="widget.dateFilter?.type"
                    [height]="'600px'" [dateTo]="widget.dateFilter?.dateTo"
                    (onWidgetFiltersReceived)="onWidgetFiltersReceived($event)"></app-chart-widget>
            </div>
            <ng-template #tableView>
                <div class="table">
                    <app-table *ngIf="tableDatasource" [datasource]="tableDatasource" [pageSizes]="[10]"
                        [height]="600"></app-table>

                </div>
            </ng-template>
        </div>
    </ng-template>

</div>

<ng-template #requestedAt let-row>
    <div [matTooltip]="row.requestedAt | smzdate:'medium'" matTooltipClass="smz-tooltip">
        {{row.requestedAt | smzdate}}
    </div>
</ng-template>

<ng-template #completedAt let-row>
    <div [ngSwitch]="row.completedAt">
        <div *ngSwitchCase="'-'">
            -
        </div>
        <div *ngSwitchCase="defaultDateUtc">
            -
        </div>
        <div *ngSwitchDefault [matTooltip]="row.completedAt | smzdate:'medium'" matTooltipClass="smz-tooltip">
            {{row.completedAt | smzdate}}
        </div>
    </div>
</ng-template>

<ng-template #dueDate let-row>
    <div [ngSwitch]="row.dueDate">
        <div *ngSwitchCase="'-'">
            No due date
        </div>
        <div *ngSwitchCase="defaultDateUtc">
            No due date
        </div>
        <div *ngSwitchDefault [matTooltip]="row.dueDate | smzdate:'mediumDate'" matTooltipClass="smz-tooltip">
            {{row.dueDate | smzdate}}
        </div>
    </div>
</ng-template>

<ng-template #documentSource let-row>
    <div>
        {{getDocumentSource(row.documentSource)}}
    </div>
</ng-template>

<ng-template #requestStage let-row>
    <div>
        {{getRequestStage(row.requestStage)}}
    </div>
</ng-template>