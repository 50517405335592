import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlockButtonModule, LoadingSpinnerModule } from '@summize/shared/components-v2';

import { MetricBarComponent } from './metric-bar.component';
import { MetricModule } from '../metric/metric.module';
import { MetricBarService } from './metric-bar.service';

@NgModule({
    imports: [
        CommonModule,
        MetricModule,
        LoadingSpinnerModule,
        BlockButtonModule,
        
    ],
    exports: [
        MetricBarComponent
    ],
    declarations: [
        MetricBarComponent
    ],
    providers: [
        MetricBarService
    ]
})
export class MetricBarModule { }

export * from './metric-bar.component'