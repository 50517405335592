import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatProgressBarModule } from '@angular/material/progress-bar';

import { 
    BlockButtonModule, 
    DialogShellModule, 
    IconModule, 
    LoadingSpinnerModule 
} from '@summize/shared/components-v2';
import { SelectBoxModule } from '@summize/shared/components';

import { ChangeContractTypeDialogComponent } from './change-contracttype-dialog.component';
import { ChangeContractTypeService } from './change-contracttype-dialog.service';

@NgModule({
    imports: [
        CommonModule,
        DialogShellModule,
        SelectBoxModule,
        BlockButtonModule,
        MatProgressBarModule,
        IconModule,
        FormsModule,
        LoadingSpinnerModule,
        SelectBoxModule
    ],
    exports: [
        ChangeContractTypeDialogComponent
    ],
    declarations: [
        ChangeContractTypeDialogComponent
    ],
    providers: [
        ChangeContractTypeService
    ]
})
export class ChangeContractTypeDialogModule { }

export * from './change-contracttype-dialog.component';