import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from './../../../environments/environment';
import { LoginValues } from './../../services/auth-service.base';
import { AuthOidcService } from './../../services/auth-oidc.service';
import { LocalStorageService } from './../../services/local-storage.service';
import { InterceptorSkipAuthBearer } from './../../services/auth-interceptor.service';

@Component({
    selector: 'app-login-oidc',
    templateUrl: './login-oidc.component.html',
    styleUrls: ['./login-oidc.component.scss']
})
export class LoginOidcComponent implements OnInit {

    public email: string;
    public errorMessage: string;

    public idps: LoginValues[] = [];

    constructor(
        public oidcAuthService: AuthOidcService,
        private localStorageService: LocalStorageService,
        private httpClient: HttpClient
    ) {
    }

    public ngOnInit() {

        let hostName = window.location.hostname;

        // Searching for the subdomain

        const hostNameSplit: string[] = hostName.split('.');

        // First part should the subdomain

        if (hostNameSplit[0].includes('-')) {
            const subdomainSplit = hostNameSplit[0].substring(hostNameSplit[0].indexOf('-') + 1);
            environment.apiUrl = `https:\\\\api-${subdomainSplit}.summize.com`;
        }

        if (environment.auth?.service !== 'oidc') {
            window.location.href = '/';
            return;
        }

        if (this.oidcAuthService.isAuthenticated) {
            this.goToRoot();
            return;
        }


        this.email = this.localStorageService.getItem('smz-oidc-email');

        if (this.email?.length > 0) {
            this.login();
        }
    }

    private goToRoot() {
        // window.location.href = '/tenants';
    }

    public async login() {
        if (this.email?.length > 0) {
            this.localStorageService.setItem('smz-oidc-email', this.email);
            this.errorMessage = undefined;

            const headers = new HttpHeaders().set(InterceptorSkipAuthBearer, '');

            await this.httpClient.get<{ idPs: LoginValues[] }>(`${environment.apiUrl}/api/identity/idps?email=${encodeURIComponent(this.email)}`, { headers }).toPromise()
                .then(response => {
                    let idps = response?.idPs;

                    if (idps?.length === 1) {
                        this.loginIdP(idps[0]);
                    } else {
                        this.idps = idps ?? [];
                    }
                })
                .catch(err => {
                    this.errorMessage = err.statusText;
                    this.localStorageService.removeItem('smz-oidc-email');
                });
        }
    }

    public onKeyDown(event: any) {
        if (event.key === 'Enter' && this.email?.length > 0) {
            this.login();
        }
    }

    public async loginIdP(idp: LoginValues) {
        this.oidcAuthService.login(idp);
    }

}