<div class="overlay-area">
    <app-block-button (click)="isOpen = !isOpen" [disabled]="disabled" #trigger="cdkOverlayOrigin" cdkOverlayOrigin
        icon="chevron-down-outline" [display]="display" type="filter" [width]="width"></app-block-button>

    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen"
        (overlayOutsideClick)="isOpen = false" [cdkConnectedOverlayHasBackdrop]="true"
        cdkConnectedOverlayBackdropClass="transparent-backdrop">
        <div class="overlay-host">
            <ng-content></ng-content>
        </div>
    </ng-template>
</div>