import { Component, Input } from '@angular/core';

import { Chart } from 'chart.js';
import { resolve } from 'chart.js/helpers';

import { ContractStatusItem } from '../../analytics.types';

interface ChartModel {
    dataset: [{
        data: any,
        backgroundColor: Array<string>
    }]
    labels: Array<string>,
    options: any
}

@Component({
    selector: 'app-contract-status-panel',
    templateUrl: 'contract-status-panel.html',
    styleUrls: ['./contract-status-panel.scss']
})
export class ContractStatusPanelComponent {

    @Input()
    public set statuses(value: Array<ContractStatusItem>) {

        this._statuses = value;

        if (this._statuses !== undefined && this._statuses !== null) {

            this.buildChart();

        }

    }

    public get statuses(): Array<ContractStatusItem> {

        return this._statuses;
    }

    public activeStatuses!: Array<ContractStatusItem>;

    public chart!: ChartModel;

    public backgroundColors = [
        "#5C39F5",
        "#F2C94C",
        "#F0224F",
        "#1BCDFF",
        "#2B158C",
        "#091567",
        "#3A0CA3",
        "#F79256",
        "#7DCFB6",
        "#EED2CC",
        "#00B2CA",
        "#1D4E89",
        "#FA8C91",
        "#FBD1A2",
        "#F5CC00",
        "#EB5757",
        "#3A0CA3",
        "#F79256",
        "#7DCFB6",
        "#EED2CC",
        "#00B2CA",
        "#1D4E89",
        "#FA8C91",
        "#FBD1A2",
        "#F5CC00",
        "#EB5757",
    ];

    private _statuses!: Array<ContractStatusItem>;

    public getPercentOfTotal(value: any): any {

        if (this.statuses === undefined || this.statuses === null) {

            return;

        }

        const total = this.statuses.reduce((partialSum, s) => partialSum + s.value, 0);

        return Math.floor((100 * value) / total);

    }

    private buildChart(): void {

        if (this.statuses === undefined || this.statuses === null) {

            return;

        }

        this.activeStatuses = this.statuses.filter(s => s.value > 0);

        this.chart = {
            dataset: [{
                data: this.statuses.filter(s => s.value > 0).map(s => s.value),
                backgroundColor: [...this.backgroundColors]
            }],
            labels: this.statuses.filter(s => s.value > 0).map(s => s.documentStatusName),
            options: {
                responsive: true,
                maintainAspectRatio: false,
                centerText: {
                    display: true,
                    text: '' + this.statuses.reduce((acc, s) => acc + s.value, 0)
                },
                legend: {
                    display: false,
                    position: 'right',
                    align: 'middle',
                    onHover: function (e: any) {
                        e.target.style.cursor = 'pointer'
                    },
                    onLeave: function (e: any) {
                        e.target.style.cursor = 'default'
                    },
                    labels: {
                        usePointStyle: true,
                        generateLabels: function (chart: any) {

                            const data = chart.data;

                            return data.labels.slice(0, 4).map((label: any, i: any) => {

                                const meta = chart.getDatasetMeta(0);

                                const ds = data.datasets[0];

                                const arc = meta.data[i];

                                const custom = arc && arc.custom || {};

                                const getValueAtIndexOrDefault = resolve

                                const arcOpts = chart.options.elements.arc;

                                const fill = custom.backgroundColor ?
                                    custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);

                                const stroke = custom.borderColor ?
                                    custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);

                                const bw = custom.borderWidth ?
                                    custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

                                const value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];

                                return {
                                    text: `${label} - ${value}`,
                                    fillStyle: fill,
                                    strokeStyle: stroke,
                                    lineWidth: bw,
                                    hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                                    index: i
                                };
                            });

                        }
                    }
                }
            }
        };

    }

}
