import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { FadeInAnimation, SummizeStorage } from '@summize/shared/framework';

import { ToastFeedService } from './toast-feed.service';

@Component({
    selector: 'app-toast',
    templateUrl: 'toast.html',
    styleUrls: ['./toast.scss'],
    animations: [FadeInAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent {

    @Input()
    public text: string;

    @Input()
    public icon: string;

    @Input()
    public type: string;

    public isWordAddIn: boolean = false;

    constructor(private toast: ToastFeedService) { }

    ngOnInit() {

        this.isWordAddIn = SummizeStorage.IsWordAddIn();

    }

    public close() {

        this.toast.remove(this.text);

    }

}