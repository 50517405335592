import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppComponent } from '@summize/shared/core';
import { EventService } from '@summize/shared/framework';

import { TimeRangeChange } from '../../analytics.events';

export const DEFAULT_RANGE = '12m';

@Component({
    selector: 'app-range-selector',
    templateUrl: 'range-selector.html'
})
export class RangeSelectorComponent extends AppComponent implements OnInit {

    public selected: string = DEFAULT_RANGE;

    constructor(private route: ActivatedRoute, private events: EventService) {

        super();

    }

    public ngOnInit(): void {

        this.subscribe(this.route.queryParams, (params: any) => {

            this.selected = params['range'] ? params['range'] : this.selected;

        });

    }

    public onChange(change: any): void {

        this.events.despatch(TimeRangeChange, change.value);

    }

}