import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService, EMPTY_GUID } from '@summize/shared/core';

@Injectable({ providedIn: 'root' })
export class HeaderBarService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getAvailableGraphCount(): Promise<any> {

        const url = `${this.getUserBaseUrl(true)}analytics/clause-metric/${EMPTY_GUID}`;

        return await this.http.get<any>(url).toPromise();
    }

}