<div class="attachment-details-container">

    <div class="attachment-details">
        <div class="thumbnail">
            <app-icon *ngIf="isImage()" [icon]="'image-outline'"></app-icon>
            <app-icon *ngIf="!isImage()" [icon]="'document-text-outline'"></app-icon>
        </div>
        <div>
            <div class="attachment-name" data-cy="attachment-name">{{attachment.fileName}}</div>
            <div class="attachment-meta">
                <div> <span class="file-size">{{getSize(attachment.fileSizeBytes)}}</span> -
                    {{attachment.createdByName}} on {{attachment.createdAt | smzdate: 'medium'}}</div>

            </div>
        </div>
    </div>
    <div class="menu">
        <app-ellipses-menu [commands]="commands"></app-ellipses-menu>
    </div>
</div>