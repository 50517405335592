import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    constructor(private localStorageService: LocalStorageService) { }

    getToken(): string {
        return this.localStorageService.getItem('token');
    }

    saveToken(token: string) {
        this.localStorageService.setItem('token', token);
    }

    destroyToken() {
        this.localStorageService.removeItem('token');
    }
}