import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SideMenuComponent } from './side-menu.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        RouterModule.forChild([])
    ],
    exports: [
        SideMenuComponent
    ],
    declarations: [
        SideMenuComponent
    ]
})
export class SideMenuModule { }

export * from './side-menu.component'