import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTooltipModule } from '@angular/material/tooltip';

import { DropdownButtonComponent } from './dropdown-button.component';
import { BlockButtonModule } from '../block-button/block-button.module';
import { IconModule } from '../icon/icon.module';

@NgModule({
    imports: [
        CommonModule,
        BlockButtonModule,
        MatTooltipModule,
        IconModule
    ],
    exports: [
        DropdownButtonComponent
    ],
    declarations: [
        DropdownButtonComponent
    ]
})
export class DropdownButtonModule { }

export * from './dropdown-button.component'