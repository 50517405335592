import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

export const AlertBannerThemes = {
    info: 'info',
    danger: 'danger',
    warn: 'warn',
    success: 'success',
    neutral: 'neutral'
} as const;

@Component({
    selector: 'app-alert-banner',
    templateUrl: 'alert-banner.html',
    styleUrls: ['./alert-banner.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertBannerComponent {

    @Input()
    public icon: string;

    @Input()
    public title: string;

    @Input()
    public message: string;

    @Input()
    public theme: string = AlertBannerThemes.info;

}