import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AnalyticsDashboardComponent } from './analytics-dashboard/analytics-dashboard.component';
import { AnalyticsDashboardModule } from './analytics-dashboard/analytics-dashboard.module';
import { AnalyicsComponent } from './analytics.component';
import { ClauseResultTableComponent } from './clause-result-table/clause-result-table.component';
import { ClauseResultTableModule } from './clause-result-table/clause-result-table.module';
import { TableViewComponent } from './table-view/table-view.component';
import { TableViewModule } from './table-view/table-view.module';

const routes: Routes = [
    {
        path: '',
        component: AnalyicsComponent,
        children: [
            { path: 'analytics', component: AnalyticsDashboardComponent },
            { path: 'table/:tableId/:range', component: TableViewComponent },
            { path: 'clause/table/:graphId/:clauseId/:documentType/:type/:range', component: ClauseResultTableComponent },
            { path: '**', redirectTo: 'analytics', }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        AnalyticsDashboardModule,
        TableViewModule,
        ClauseResultTableModule
    ],
    exports: [
        RouterModule
    ]
})
export class AnalyticsRoutingModule { }
