<div class="manage-panel no-scroll">
    <div class="range-area">
        <div class="panel-header">
            <div class="select-container">
                <div class="text">
                    {{'Client' | clientmatterlocalizer}}
                </div>
                <app-select-box (changed)="onClientChange($event)" [value]="selectedClientId" [options]="clients"
                    placeholder="All {{ 'Clients' | clientmatterlocalizer }}">
                </app-select-box>
                <ng-container *ngIf="hasClientIdSelected === true">
                    <div class="text">
                        {{'Matter' | clientmatterlocalizer}}
                    </div>
                    <app-select-box (changed)="onMatterChange($event)" [value]="selectedMatterId" [options]="matters"
                        placeholder="All {{ 'Matters' | clientmatterlocalizer }}">
                    </app-select-box>
                </ng-container>
            </div>
        </div>
        <app-range-selector></app-range-selector>
    </div>
    <div class="metric-area">
        <app-metric-bar [range]="range" [client]="selectedClientId" [matter]="selectedMatterId" area="Manage">
        </app-metric-bar>
    </div>
    <div class="smz-flex-area panel-area scroll">
        <div class="container-fluid">
            <div *ngIf="selectedMetrics?.length > 0" class="row">
                <div *ngFor="let metric of selectedMetrics" [ngClass]="metric.layout" cdkDragHandle>
                    <app-clause-metric [range]="range" [clientId]="selectedClientId" [matterId]="selectedMatterId"
                        [definition]="metric">
                    </app-clause-metric>

                    <div *cdkDragPreview>
                        <app-clause-metric [range]="range" [clientId]="selectedClientId" [matterId]="selectedMatterId"
                            [definition]="metric">
                        </app-clause-metric>
                    </div>
                </div>
            </div>

            <div *ngIf="isLoading === false && (selectedMetrics === undefined || selectedMetrics.length === 0)"
                class="row">
                <div class="col-12">
                    <div class="no-metrics">
                        <div class="title">
                            Let's get started!
                        </div>
                        <div class="sub-title">
                            Start discovering more about your contracts by adding a graph.
                        </div>
                        <app-block-button (click)="handleAddGraph()" display="Add Graph" type="v2" width="140"
                            icon="arrow-forward-circle-outline">
                        </app-block-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>