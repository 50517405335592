<app-dialog-shell title="Rename Dashboard" (onClose)="close()">
    <div class="content-area">
        <input [(ngModel)]="dashboardName" name="text" class="smz-standard-input v2" required
            autocomplete="off">
    </div>
    <div class="commands">
        <app-block-button mat-dialog-close [display]="'Cancel'" type="v2-blue" width="100" data-cy="modal-cancel">
        </app-block-button>
        <app-block-button [disabled]="dashboardName?.length === 0" [mat-dialog-close]="dashboardName" [display]="'Rename'" [width]="150" type="v2-primary"
            data-cy="modal-confirm">
        </app-block-button>
    </div>
</app-dialog-shell>