import { Pipe, PipeTransform } from '@angular/core';
import { ClientMatterLocalizerUtil } from './../../../core/src/lib/utils/clientmatterlocalizer.util';

@Pipe({
  name: 'clientmatterlocalizer'
})
export class ClientMatterLocalizerPipe implements PipeTransform {

  private clientMatterLocalizerUtil: ClientMatterLocalizerUtil;

  constructor() {

    this.clientMatterLocalizerUtil = new ClientMatterLocalizerUtil();

  }

  transform(value: string, specificGenericLabel?: string): string {

    return this.clientMatterLocalizerUtil.transform(value, specificGenericLabel)

  }

}
