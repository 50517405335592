<div class="breadcrumb-bar">

    <div class="content">

        <a *ngIf="hideNavigation === false" class="clickable" (click)="doBackEvent()">
            <div class="icon icon-back icon__standard">
            </div>
        </a>

        <div *ngIf="hideNavigation === false" class="title" [class.clickable]="onTitleClick !== undefined" (click)="doTitleClick()">
            {{title}}
        </div>

        <ng-container *ngIf="centerTemplate !== undefined">
            <div class="center">
                <ng-container *ngTemplateOutlet="centerTemplate"></ng-container>
            </div>
        </ng-container>

        <ng-container *ngIf="toolbarTemplate !== undefined">
            <div class="right">
                <ng-container *ngTemplateOutlet="toolbarTemplate"></ng-container>
            </div>
        </ng-container>

    </div>

</div>