import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlockButtonModule, SelectBoxModule, IconModule, ExpandableCardModule, LoadingSpinnerModule } from '@summize/shared/components-v2';

import { PlaybookDetailsPanelComponent } from './playbook-details-panel.component';
import { PlaybookDetailsPanelService } from './playbook-details-panel.service';


@NgModule({
    imports: [
        CommonModule,
        BlockButtonModule,
        IconModule,
        SelectBoxModule,
        ExpandableCardModule,
        LoadingSpinnerModule
    ],
    exports: [
        PlaybookDetailsPanelComponent
    ],
    declarations: [
        PlaybookDetailsPanelComponent
    ],
    providers: [
        PlaybookDetailsPanelService
    ]
})
export class PlaybookDetailsPanelModule { }

export * from './playbook-details-panel.component'