<div [@fadeInAnimation] class="bulk-users-container">
    <div #userListContainer>

        <form class="form-horizontal" [formGroup]="usersForm">
            <div formArrayName="users">

                <div *ngFor="let user of usersForm.get('users')['controls'];  let i = index">

                    <div [formGroupName]="i">

                        <div class="row user-row">


                            <div class="smz-form-control required">
                                <p *ngIf="i === 0">Email</p>
                                <input required placeholder="Email" formControlName="email" minlength="1"
                                    class="insert body__small" type="email" name="email"
                                    (ngModelChange)="userDetailsChange()"
                                    [ngClass]="{'row-error': user.controls.email.errors && user.controls?.email.touched}" />
                                <p *ngIf="user.controls.email.errors?.email && user.controls.email?.touched"
                                    class="validation-message">
                                    Invalid Email
                                </p>
                                <p *ngIf="user.controls.email.errors?.required && (user.controls.email?.touched ||user.controls.email?.dirty)"
                                    class="validation-message">
                                    Email required
                                </p>
                                <p *ngIf="user.controls.email.errors?.serverError" class="validation-message">
                                    {{user.controls.email.errors?.serverError}}
                                </p>

                            </div>


                            <div class="smz-form-control required">
                                <p *ngIf="i === 0">First name</p>
                                <input required placeholder="First name" formControlName="firstName" minlength="1"
                                    class="insert body__small" type="text" name="firstName"
                                    (ngModelChange)="userDetailsChange()"
                                    [ngClass]="{'row-error': user.controls.firstName?.errors && user.controls.firstName?.touched}" />

                                <p *ngIf="user.controls.firstName?.errors?.required && user.controls.firstName?.touched"
                                    class="validation-message">
                                    First name required
                                </p>
                            </div>


                            <div class="smz-form-control required">
                                <p *ngIf="i === 0">Last name</p>
                                <input required placeholder="Last name" formControlName="lastName" minlength="1"
                                    class="insert body__small" type="text" name="lastName"
                                    (ngModelChange)="userDetailsChange()"
                                    [ngClass]="{'row-error': user.controls.lastName?.errors && user.controls.lastName?.touched}" />
                                <p *ngIf="user.controls.lastName?.errors?.required && user.controls.lastName?.touched"
                                    class="validation-message">
                                    Last name required
                                </p>
                            </div>

                            <div class="smz-form-control">

                                <app-icon icon="close-outline" class="remove-button"
                                    [ngClass]="{'remove-button-first': i === 0}" (click)="removeUserRow(i)">
                                </app-icon>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </form>
    </div>



    <div *ngIf="isBulkView === true" class="bulk-invite-container">

        <mat-chip-list #chipList aria-label="Email list">
            <mat-chip *ngFor="let email of bulkEmails" (removed)="removeFromChips(email)">
                {{email}}
                <app-icon matChipRemove icon="close-outline"></app-icon>

            </mat-chip>
        </mat-chip-list>

        <textarea #bulkEmailInput [placeholder]="bulkPlaceholderText" [formControl]="bulkEmailsCtrl"
            [matChipInputFor]="chipList" (keyup)="addToChips($event)" rows="3">
        </textarea>

        <div class="multi-user-buttons">
            <app-block-button width="265" display="Cancel" type="ghost" (click)="resetBulkAdd()"></app-block-button>
            <app-block-button width="265" display="Add Users" type="ghost" (click)="addBulkUsers()"></app-block-button>
        </div>
    </div>

</div>

<div *ngIf="isBulkView === false" class="commands">

    <div (click)="addUserRow()">
        <app-icon icon="add-circle-outline"></app-icon>
        <a class=" user-mode-link">Add User</a>
    </div>
    <div (click)="addMultpleSlide()">
        or <a class=" user-mode-link">Add
            multiple users at once.</a>
    </div>
</div>