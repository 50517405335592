import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule } from '@angular/material/dialog';

import { ConfirmationModalComponent } from './confirmation-modal.component';
import { StyledButtonModule } from '../styled-button/styled-button.module';
import { DialogShellModule } from '../dialog-shell/dialog-shell.module';
import { BlockButtonModule } from '../block-button/block-button.module';

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        StyledButtonModule,
        DialogShellModule,
        BlockButtonModule
    ],
    exports: [
        ConfirmationModalComponent
    ],
    declarations: [
        ConfirmationModalComponent
    ]
})
export class ConfirmationModalModule { }

export * from './confirmation-modal.component'