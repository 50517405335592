import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";

import { ManageComponent } from "./manage/manage.component";
import { ManageModule } from './manage/manage.module';

const routes: Routes = [
    {
        path: 'manage',
        component: ManageComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        ManageModule
    ],
    exports: [
        RouterModule
    ]
})
export class CustomFieldsRoutingModule { }
