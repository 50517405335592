import { Injectable } from '@angular/core';

import { SummizeStorage } from './summize-storage';

@Injectable({
    providedIn: 'root'
})
export class CorrelationIdService {

    constructor() { }

    public getCorrelationId(): string {

        return SummizeStorage.getLocalItem('correlationId') as string;

    }

    public saveCorrelationId(token: string) {

        return SummizeStorage.setLocalItem('correlationId', token);

    }

    public destroyCorrelationId() {

        SummizeStorage.getLocalItem('correlationId');

    }

}
