<mat-form-field class="textfield" appearance="fill" [floatLabel]="false">

    <input #textFieldInput matInput [type]="type" [placeholder]="label" [(ngModel)]="value"
        (ngModelChange)="valueChange.emit(value)" [formControl]="textFieldControl" [autofocus]="autofocus"
        [readonly]="readonly" (mousedown)="onMouseDown($event)" (keydown)="onKeydown($event)">
    <mat-error *ngIf="textFieldControl.hasError('required')">
        {{this.label}} is required
    </mat-error>
    <mat-error *ngIf="textFieldControl.hasError('minlength')">
        Minimum length is 8
    </mat-error>
    <mat-error *ngIf="textFieldControl.hasError('email')">
        Valid Email address is required
    </mat-error>
    <div *ngIf="suffix !== undefined" matSuffix>{{suffix}}</div>
</mat-form-field>