import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { showFlyInFlyOut } from '../animations';
import { ToastService } from './toast.service';

@Component({
    selector: 'app-toast',
    templateUrl: 'toast.html',
    styleUrls: ['./toast.scss'],
    animations: [showFlyInFlyOut],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent {

    @Input()
    public text: string;

    @Input()
    public icon: string;

    @Input()
    public type: string;

    constructor(private toast: ToastService) { }

    public close() {

        this.toast.remove(this.text);

    }

}