import { Component, Input, ViewChild, AfterViewInit, TemplateRef, OnInit, OnDestroy, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';

import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { ChangeDetectionHelper } from '@summize/shared/core';
import { LocalStorageService } from '@summize/shared/framework';

import { merge, Subject, Subscription } from 'rxjs';
import { startWith, switchMap, map, debounceTime, filter } from 'rxjs/operators'
import { get } from 'lodash-es';

import { FadeInAnimation } from '../animations/fade-in.animation';
import { TableService } from './table.service';

export interface TableColumnDefinition {
    key: string;
    display?: string;
    canSort?: boolean;
    sortField?: string;
    template?: TemplateRef<any>;
    sticky?: boolean;
}

export interface PageChangeEvent {
    pageIndex: number;
    pageSize: number;
}

export interface TableDatasource {
    headers?: Array<TableColumnDefinition>,
    headerProvider?: () => Array<TableColumnDefinition>,
    default: {
        sort: {
            field?: string;
            dir?: 'asc' | 'desc'
        }
    },
    source: {
        url: string,
        resultsField: string;
        countField?: string;
        isPostRequest?: boolean;
    };
    queryProvider?: () => string,
    postStateProvider?: (state: any) => any;
    onFetch?: (data: any) => void,
    query?: string,
    queryChanged?: Subject<string>,
    preTransform?: Function,
    transformer?: Function
    rowEdit?: Function,
    allowSelection?: boolean;
    selectionChanged?: Function;
    floatingRows?: boolean;
    disableSort?: boolean;
    disableSelectAll?: boolean;
    saveSettings?: boolean;
}

@Component({
    selector: 'app-table',
    templateUrl: 'table.html',
    styleUrls: ['./table.scss'],
    animations: [FadeInAnimation]
})
export class TableComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {

    public get = get;

    @ViewChild(MatPaginator)
    private paginator: MatPaginator;

    @ViewChild(MatSort)
    private sort: MatSort;

    @Output()
    public selectionChanged: EventEmitter<any>;

    @Input()
    public datasource: TableDatasource;

    @Input()
    public pageSize: number = 10;

    @Input()
    public pageIndex: number = 0;

    @Output()
    public onPageChange: EventEmitter<PageChangeEvent> = new EventEmitter<PageChangeEvent>();

    @Input()
    public pageChangeExternal: boolean = false;

    @Input()
    public height: number = 0;

    @Input()
    public pageSizes = [10, 25, 50, 100];

    public columns: Array<string>;

    public data: Array<any>;

    public resultsLength: number;

    public initialLoad: boolean = true;

    public selectedIndex: number;

    public isReloading: boolean = false;

    public isLoading: boolean = false;

    public selection = new SelectionModel<any>(true, []);

    private subs: Array<Subscription>;

    private currentQuery: string;

    private selectAllOnLoad: boolean = false;

    constructor(
        private tableService: TableService,
        private localStorageService: LocalStorageService) {

        this.subs = new Array<Subscription>();

        this.selectionChanged = new EventEmitter<any>();

    }

    public ngOnInit(): void {

        this.data = undefined;

        if (this.datasource.headerProvider === undefined && this.datasource.headers === undefined) {

            throw new Error("Table must provide a source of headers");

        }

        if (this.datasource.headerProvider !== undefined) {

            this.datasource.headers = this.datasource.headerProvider();

        }

        if (this.datasource.allowSelection === true) {

            this.columns = ['select', ...this.datasource.headers.map(h => h.key)];

            if (this.datasource.selectionChanged !== undefined) {

                this.subs.push(this.selection.changed.subscribe(() => {

                    this.datasource.selectionChanged(this.selection.selected);

                }));

            }

        } else {

            this.columns = this.datasource.headers.map(h => h.key);

        }

    }

    public ngOnDestroy(): void {

        this.subs?.forEach(s => s.unsubscribe());

    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['pageIndex']) {

            if (changes['pageIndex'].firstChange || (changes['pageIndex'].currentValue !== changes['pageIndex'].previousValue)) {
                if (this.paginator !== undefined) {

                    this.paginator.pageIndex = changes['pageIndex'].currentValue;

                }
            }
        }
    }

    public reload(): void {

        // Fairly tricky process
        // Need to effectively re-render the grid without
        // re-rendering the component

        this.isReloading = true;

        ChangeDetectionHelper.doNextCycle(() => {

            this.ngOnDestroy();

            this.ngOnInit();

            this.isReloading = false;

            ChangeDetectionHelper.doNextCycle(() => {

                this.ngAfterViewInit();

            });

        }, 400);

    }

    public ngAfterViewInit(): void {

        if (this.datasource.queryChanged !== undefined) {

            this.subs.push(
                this.datasource.queryChanged.pipe(debounceTime(500)).subscribe(val => {

                    this.currentQuery = val;

                    this.datasource.query = this.currentQuery;

                    this.sort.sortChange.next(undefined);

                })
            );

        }

        const paginatorSettings = this.datasource.saveSettings === false ? undefined :
            this.localStorageService.getJson(`smz-paginator-${this.datasource.source.url}`);

        if (paginatorSettings !== undefined &&
            paginatorSettings !== null &&
            paginatorSettings.pageSize !== undefined &&
            this.paginator !== undefined &&
            this.paginator !== null) {

            this.pageSize = paginatorSettings.pageSize;
            this.paginator.pageSize = paginatorSettings.pageSize;

            if (this.paginator.pageIndex !== this.pageIndex) {
                this.paginator.pageIndex = this.pageIndex;
            }

        } else {

            this.paginator.pageSize = this.pageSize;
            this.paginator.pageIndex = this.pageIndex;

        }

        this.subs.push(
            this.sort.sortChange.subscribe(() => {

                this.paginator.pageIndex = 0;

                const paginatorSettings = this.datasource.saveSettings === false ? undefined
                    : this.localStorageService.getJson(`smz-paginator-${this.datasource.source.url}`);

                const { sortField, sortDirection } = this.getSortSettings(this.sort, this.datasource);

                const updatedPaginatorSettings = paginatorSettings || {};
                updatedPaginatorSettings.sortField = sortField;
                updatedPaginatorSettings.sortDirection = sortDirection;

                if (this.datasource.saveSettings !== false) {

                    this.localStorageService.setItem(`smz-paginator-${this.datasource.source.url}`, JSON.stringify(updatedPaginatorSettings));

                }
            })
        );

        this.subs.push(this.paginator.page
            .pipe()
            .subscribe(x => {

                const paginatorSettings = this.localStorageService.getJson(`smz-paginator-${this.datasource.source.url}`);

                const { sortField, sortDirection } = paginatorSettings != null &&
                    paginatorSettings.sortField != null &&
                    paginatorSettings.sortDirection != null ?
                    paginatorSettings : this.getSortSettings(this.sort, this.datasource);

                const changedPageSize = this.pageSize !== x.pageSize;
                const changedPageIndex = this.pageIndex !== x.pageIndex;

                this.pageSize = x.pageSize;
                this.pageIndex = x.pageIndex;

                if (changedPageSize === true || changedPageIndex === true) {
                    this.onPageChange.emit({ pageIndex: x.pageIndex, pageSize: x.pageSize });

                    const validPageSize = this.pageSizes.find(y => y === x.pageSize);

                    if (validPageSize !== undefined) {
                        const paginatorSettings = JSON.stringify({ ...x, sortField: sortField, sortDirection: sortDirection });
                        this.localStorageService.setItem(`smz-paginator-${this.datasource.source.url}`, paginatorSettings);
                    }

                    // Force a page refresh if the size changes (as this happens internally)
                    if (this.pageChangeExternal === true && changedPageSize === true) {
                        this.sort.sortChange.next(undefined);
                    }
                }

            })
        );

        let changeDetectionObservables = [this.sort.sortChange, this.paginator.page];

        if (this.pageChangeExternal === true) {

            changeDetectionObservables = [this.sort.sortChange];

        }

        this.subs.push(
            merge(...changeDetectionObservables)
                .pipe(
                    startWith({ initial: true }),
                    switchMap(() => {

                        this.selection.clear();

                        this.isLoading = true;

                        if (this.pageChangeExternal !== true) {

                            this.pageSize = this.paginator.pageSize;

                            this.pageIndex = this.paginator.pageIndex;
                        }

                        const paginatorSettings = this.datasource.saveSettings === false ?
                            undefined : this.localStorageService.getJson(`smz-paginator-${this.datasource.source.url}`);

                        const { sortField, sortDirection } = paginatorSettings != null &&
                            paginatorSettings.sortField != null &&
                            paginatorSettings.sortDirection != null ?
                            paginatorSettings : this.getSortSettings(this.sort, this.datasource);

                        return this.tableService.fetch(
                            this.datasource.source.url,
                            this.toPascalCase(sortField),
                            sortDirection,
                            this.pageIndex,
                            this.pageSize,
                            this.currentQuery || this.datasource.query,
                            this.datasource.queryProvider,
                            this.datasource.source.isPostRequest || false,
                            this.datasource.postStateProvider
                        );

                    }),
                    map(data => {

                        if (this.datasource.onFetch !== undefined) {

                            this.datasource.onFetch(data);

                        }

                        if (this.datasource.preTransform !== undefined) {

                            data = this.datasource.preTransform(data);
                        }

                        this.resultsLength = data[this.datasource.source.countField || 'totalCount'];

                        if (this.resultsLength !== undefined &&
                            this.resultsLength < (this.pageIndex * this.pageSize) &&
                            this.pageIndex > 0) {

                            this.paginator.firstPage();

                        }

                        const transformer = this.datasource.transformer;

                        const transformed = transformer !== undefined ?
                            data[this.datasource.source.resultsField]?.map(transformer) : data[this.datasource.source.resultsField];

                        this.initialLoad = false;

                        if (this.selectAllOnLoad === true) {

                            ChangeDetectionHelper.doNextCycle(() => {

                                this.selectAllOnLoad = false;

                                this.masterToggle();

                            }, 500);

                        }

                        this.isLoading = false;

                        return transformed;

                    })
                )
                .subscribe(data => this.data = data, (error) => {

                    this.isLoading = false;

                    console.log(error);

                    this.resultsLength = 0;

                    this.initialLoad = false;

                    this.data = [];

                })
        );

    }

    public getSortSettings(sort: any, datasource: any): { sortField: string, sortDirection: string } {

        const sortColumn = datasource.headers.find((h: any) => h.key === sort.active);
        const sortField = sortColumn?.sortField || sort.active;
        const sortDirection = sort.direction;

        return { sortField, sortDirection };
    }

    public isColumnSortingEnabled(column: string): boolean {

        return this.datasource.headers.find(h => h.key === column)?.canSort === false;

    }

    public getColumnHeader(column: string) {

        return this.datasource.headers.find(h => h.key === column)?.display;

    }

    public getColumnSticky(column: string) {

        return this.datasource.headers.find(h => h.key === column)?.sticky;

    }

    public isCustomTemplate(column: string): boolean {

        return column === 'edit';

    }

    public hasColumnTemplate(column: string): boolean {

        return this.datasource.headers.find(h => h.key === column)?.template !== undefined &&
            this.datasource.headers.find(h => h.key === column)?.template !== null;

    }

    public getColumnTemplate(column: string): TemplateRef<any> | undefined {

        return this.datasource.headers.find(h => h.key === column)?.template;

    }

    public onEditRow(row: any): void {

        const item = this.datasource;

        if (item !== undefined && item.rowEdit !== undefined) {

            item.rowEdit(row);

        }

    }

    public isAllSelected() {

        const numSelected = this.selection.selected.length;

        const numRows = this.data?.length;

        return numSelected === numRows;

    }

    public masterToggle() {

        if (this.isAllSelected()) {

            this.selection.clear();

            return;
        }

        this.selection.select(...this.data);

    }

    public clearSelection(): void {

        this.selection.clear();

    }

    public getPageSizeOptions(): Array<any> {

        let items = this.pageSizes.slice(0);

        return items;
    }

    public selectAllRecords() {

        const sizes = this.getPageSizeOptions();

        const allSize = sizes[sizes.length - 1];

        this.paginator.pageSize = allSize;

        this.selectAllOnLoad = true;

        // Bug or feature?? The paginator wont fire the change event if you set page size
        // in code, have to use the private method below;
        (<any>this.paginator)._emitPageEvent(this.paginator.pageIndex);

    }

    private toPascalCase(input: string): string {

        return input.replace(/\w+/g, (w) => { return w[0].toUpperCase() + w.slice(1); });

    }

}
