import { Component, ViewChild, ViewContainerRef, ComponentFactoryResolver, OnInit } from '@angular/core';

import { FadeInAnimation, SlideUpAnimation, User } from '@summize/shared/framework';

import { SlidePanelComponent } from '../slide-panel/slide-panel.component';
import { UserPanelService } from './user-panel.service';

enum SelectedTab {
    Assigned,
    Calendar
}
@Component({
    selector: 'app-user-panel',
    templateUrl: 'user-panel.html',
    styleUrls: ['./user-panel.scss'],
    animations: [
        FadeInAnimation, SlideUpAnimation
    ]
})
export class UserPanelComponent implements OnInit {

    public SelectedTab = SelectedTab;

    @ViewChild('host', { static: false, read: ViewContainerRef })
    public host: ViewContainerRef;

    public user: User;

    public calendarComponent: any;

    public calendarInstance: any;

    public avatarSize: number = 32;

    public selectedTab: SelectedTab = SelectedTab.Calendar;

    public assignedContracts: any;

    public query: string;

    public loading: boolean = true;

    private $parent: SlidePanelComponent;

    public get name() {

        return `${this.user?.firstName} ${this.user?.lastName}`;

    }

    public get level() {

        return this.getLevelFromMap();

    }

    constructor(private service: UserPanelService,
        private resolver: ComponentFactoryResolver) { }

    public async ngOnInit(): Promise<void> {

        this.assignedContracts = await this.service.getAssignedContracts();

        this.loading = false;

    }

    public setTab(tab: SelectedTab): void {

        if (this.selectedTab !== SelectedTab.Calendar && tab === SelectedTab.Calendar) {

            this.setupCalendarHost();

        }

        this.selectedTab = tab;

    }

    public init(parent: SlidePanelComponent) {

        this.$parent = parent;

        this.setupCalendarHost();

    }

    public close() {

        this.$parent.destroy();

    }

    private setupCalendarHost(): void {

        setTimeout(() => {

            if (this.calendarComponent !== undefined && this.selectedTab === SelectedTab.Calendar) {

                const factory =
                    this.resolver.resolveComponentFactory(this.calendarComponent);

                this.calendarInstance = this.host.createComponent(factory).instance;

            }

        }, 100);

    }


    private getLevelFromMap(): string {

        const map = {
            0: 'Empty',
            1: 'All',
            2: 'Full User',
            3: 'Restricted',
            4: 'Read Only',
            5: 'External',
            6: 'Share',
            7: 'Mobile Only',
            99: 'Dynamic'
        }

        return map[this.user.userType];

    }


}