<div class="container pl-0 pr-0" [@slideUpAnimation]="ready === true" [@fadeInAnimation]="ready === true"
    [class.open]="ready === true">
    <div class="content">
        <app-icon class="close clickable" icon="close-outline" (click)="close()"></app-icon>
        <ng-container *ngIf="model.complete === false">
            <div class="header-container">
                <div class="header">Summize User Feedback</div>
                <div class="subtitle">Leave your feedback here.</div>
            </div>
            <div class="form-container scroll">
                <div class="smz-form-control required first">
                    <p>Feedback Type</p>
                    <app-select-box [value]="model.type" (changed)="model.type = $event" [options]="options">
                    </app-select-box>
                </div>
                <div class="smz-form-control required">
                    <p>Description</p>
                    <textarea placeholder="Please provide your feedback" [value]="model.content"
                        (input)="model.content = $event.target.value"></textarea>
                </div>
                <app-button [disabled]="model.type === undefined  || model.content === ''" size="large" type="primary"
                    (click)="save()">
                    Leave feedback
                </app-button>
            </div>

        </ng-container>
        <ng-container *ngIf="model.complete === true">
            <div [@slideUpAnimation] class="header-container complete">
                <div class="header">Thank you for your feedback</div>
                <div class="subtitle">We want to make sure that we provide the best experience for our users. Your
                    feedback helps us to improve our platform and develop the most relevant new features.</div>
                <img class="hero block" src="./assets/images/woman-brolley-2.png" />
            </div>
        </ng-container>
    </div>
</div>