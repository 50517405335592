<app-dialog-shell (onClose)="close()" (onSave)="save()" [saveDisabled]="isSaveDisabled()" [showFooter]="true"
    title="New Conversation" saveText="Start Conversation" saveButtonWidth="175">
    <div [ngClass]="{'word-addin-content': isWordAddIn}" class="conversation">
        <div class="section">

            <p *ngIf="requestSubType === RequestSubType.Manage">
                To start a new conversation, enter a subject name then type your comment below. In the comments section you
                can use the &#64; symbol to &#64;mention specific users and notify them via email. You can also use the '#' symbol
                to include a reference link to an existing task or clause.
            </p>

            <p *ngIf="requestSubType !== RequestSubType.Manage">
                To start a new conversation, enter a subject name then type your comment below. In the comments section you
                can use the &#64; symbol to &#64;mention specific users to notify them via email and provide collaborator access.
                You can also use the '#' symbol to include a reference link to an existing task. 
            </p>

            <div class="text">
                Subject
            </div>
            <div class="value">
                <input [(ngModel)]="subject" type="text" placeholder="Subject ..." data-cy="conversation-subject"/>
            </div>
        </div>
        <div class="section">
            <div class="text">
                Comment
            </div>
            <div class="value">
                <app-conversation-input [users]="users" [toolbar]="contractId === undefined" [positionMode]="'fixed'"
                    [fixMentions]="false" [contractId]="contractId" [showEntities]="contractId !== undefined"
                    display="{{contractId === undefined ? 'inline':'full'}}" data-cy="conversation-comment">
                </app-conversation-input>
            </div>
        </div>
    </div>
</app-dialog-shell>