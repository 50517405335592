import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';

export class ContractResponse {
    isShared: boolean;
    documentId: string;
    documentName: string;
    assignedTo: string;
    assignedToName: string;
    assignedBy: string;
    assignedByName: string;
    assignedAt: string;
}

export class DocumentStatus {
    id: number;
    statusName: string;
    statusStyle: string;
    statusOrder: number;
}

@Injectable({
    providedIn: 'root'
})
export class ClientMatterMenuService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getClients(): Promise<any> {

        const results: any = await this.http.get(`${this.getUserBaseUrl(true)}clients?includeStats=false`).toPromise();

        return results.clients.map(x => {

            return {
                ...x,
                name: x.clientName,
            }

        })
        .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

    }

    public async getMattersForClient(clientId: string): Promise<any> {

        const results: any = await this.http.get(`${this.getUserBaseUrl(true)}clients/${clientId}/matters`).toPromise();

        return results.matters
            .map(x => {
                return {
                    ...x,
                    name: x.matterName
                }
            })
            .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));


    }

    public async getContractTypesForMatter(clientId: string, matterId: string): Promise<any> {

        const results: any = await this.http.get(`${this.getUserBaseUrl(true)}clients/${clientId}/matters/${matterId}/DocumentTypes?status=ContractReviewDocumentTypes`).toPromise();

        return results
            .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

    }

    public async getContractTypes(): Promise<any> {

      const results: any = await this.http.get(`${this.getUserBaseUrl(true)}DocumentTypes?type=ContractReview`).toPromise();

      return results
          .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

  }

}
