<div class="template-permissions">

    <div class="desc">
        Use the checkboxes to control what actions business users and requestor users can perform.
    </div>

    <div class="selection-container">
        <mat-checkbox (change)="setPermission(TemplatePermissionType.ManuallyAddNewVersion, $event.checked)"
            [checked]="hasPermission(TemplatePermissionType.ManuallyAddNewVersion)">
            <span class="heavy">Manually add new versions</span> (Users with the Business or Requestor licence can
            manually add new versions)
        </mat-checkbox>
    </div>

    <div class="selection-container">
        <mat-checkbox (change)="setPermission(TemplatePermissionType.WordForWeb, $event.checked)"
            [checked]="hasPermission(TemplatePermissionType.WordForWeb)">
            <span class="heavy"> Word for the web</span> (Users with the Business licence can use Word for the web)
        </mat-checkbox>
    </div>

    <div class="selection-container">
        <mat-checkbox (change)="setPermission(TemplatePermissionType.SendForSig, $event.checked)"
            [checked]="hasPermission(TemplatePermissionType.SendForSig)">
            <span class="heavy"> Send for signature</span> (Users with the Business licence can use the ‘Sign’ button to
            send for signature)
        </mat-checkbox>
    </div>

</div>