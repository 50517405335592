import { Component, Input } from '@angular/core';

import { FadeInAnimation } from '@summize/shared/framework';

export interface NavigationItem {
    path: string;
    title: string;
    description: string;
}
@Component({
    selector: 'app-navigation-list',
    templateUrl: './navigation-list.html',
    styleUrls: ['./navigation-list.scss'],
    animations: [
        FadeInAnimation
    ]
})
export class NavigationListComponent {

    @Input()
    public navigationItems: NavigationItem[] = [];

}