import { Component, Input, OnInit } from '@angular/core';

import { ScheduleFrequency } from '@summize/shared/framework';

import { SmzDatePipe } from '../pipes/date.pipe';

export enum DueDateStyle {
    None = 'none',
    Pending = 'pending',
    Upcoming = 'upcoming',
    Overdue = 'overdue'
};

const FrequencyIntervalMapping = {
    [ScheduleFrequency.Weekly]: 'weeks',
    [ScheduleFrequency.Monthly]: 'months',
    [ScheduleFrequency.Yearly]: 'years'
};

export interface RecurrenceSchedule {
    interval: number;
    frequency: ScheduleFrequency;
    endDate: Date | null;
}

@Component({
    selector: 'app-due-date',
    templateUrl: './due-date.html',
    styleUrl: './due-date.scss'
})
export class DueDateComponent implements OnInit {

    @Input()
    public dueDate?: Date;

    @Input()
    public dueInDays?: number;

    @Input()
    public style: DueDateStyle = DueDateStyle.Pending;

    @Input()
    public schedule?: RecurrenceSchedule | null;

    public dueDateText: string | undefined = 'No due date';

    public scheduleSummary: string | undefined;

    public tooltip: string | undefined;

    constructor(private datePipe: SmzDatePipe) { }

    public ngOnInit(): void {

        this.buildDueDateText();

        this.buildScheduleSummary();

    }

    private buildDueDateText() {

        if (this.dueInDays !== undefined && this.dueInDays > 0) {

            this.dueDateText = `${this.dueInDays} days`;

            return;

        }

        if (this.dueDate === undefined || this.dueDate.getUTCFullYear() === 1) {

            return;

        }

        this.tooltip = `Task due by ${this.datePipe.transform(this.dueDate, 'medium')} in your timezone.`;

        this.dueDateText = `Due ${this.datePipe.transform(this.dueDate, 'mediumDate')}`;

    }

    private buildScheduleSummary() {

        if (this.schedule === null || this.schedule === undefined) {

            return;

        }

        const summaryStr = [];

        if (this.schedule.interval > 1) {

            summaryStr.push(`Every ${this.schedule.interval} ${FrequencyIntervalMapping[this.schedule.frequency]}`);

        }
        else {

            summaryStr.push(ScheduleFrequency[this.schedule.frequency]);

        }

        if (this.schedule.endDate !== null) {

            summaryStr.push(`until ${this.datePipe.transform(this.schedule.endDate, 'mediumDate')}`);

        }

        this.scheduleSummary = summaryStr.join(' ');

    }

}