import { HttpClient } from '@angular/common/http';
import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppComponent } from '@summize/shared/core';

import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';

export interface TemplateQuestion {
    createTemplateId: string;
    allowManualSearch?: boolean;
}

@Component({
    selector: 'app-company-select',
    templateUrl: 'company-select.html',
    styleUrls: ['./company-select.scss']
})

export class CompanySelectComponent extends AppComponent {

    @Output()
    public onOptionSelected: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    public v2: boolean = false;

    @Input()
    public question!: TemplateQuestion;

    @Input()
    public isCompanyNumberRequired: boolean = true;

    public searchCtrl = new FormControl();

    public companies: any;

    public isLoading = false;

    public errorMsg: string;

    public manual: boolean = false;

    public company: any = {}

    constructor(private http: HttpClient) { super(); }

    public async ngOnInit() {

        const baseUrl = this.getUserBaseUrl(true);

        this.searchCtrl.valueChanges
            .pipe(
                debounceTime(500),
                tap(() => {
                    this.errorMsg = "";
                    this.companies = [];
                    this.isLoading = true;
                }),
                switchMap(value => this.http.post(`${baseUrl}templates/${this.question.createTemplateId}/companies/search`, { search: value })
                    .pipe(
                        finalize(async () => {
                            this.isLoading = false;
                        }),
                    )
                )
            )
            .subscribe((data: any) => {
                this.companies = data.companies.map(x => {

                    // Provide defaults if they are not available.
                    x.companyId = x.companyId ?? '';
                    x.companyAddress = x.companyAddress ?? '';

                    return x;
                });
            });
    }

    public onSelect(company): void {

        this.company = company;

        this.onOptionSelected.emit(this.company);

        this.setManual(true);

    }

    public onChanged(value: string, prop: string): void {

        this.company[prop] = value;

        this.onOptionSelected.emit(this.company);

    }

    public setManual(state: boolean) {

        this.manual = state;
    }
}