<div [class.borderless]="border === false" class="expandable-card">
    <div [class.collapsed]="expanded === false" class="header">
        <div class="controls">
            <app-icon *ngIf="expanded === true" (click)="expandToggle(false)" icon="chevron-down-outline"
                class="clickable"></app-icon>
            <app-icon *ngIf="expanded === false" (click)="expandToggle(true)" icon="chevron-forward-outline"
                class="clickable">
            </app-icon>
        </div>
        <div *ngIf="icon !== undefined" class="icon-container {{iconStyle}}">
            <app-icon [icon]="icon"></app-icon>
        </div>
        <div class="title">
            <div class="main" data-cy="conversation-title">
                {{title}}
                <div *ngIf="subtitlePosition === 'below'" class="subtitle" [title]="subtitleHover">{{subtitle}}</div>
            </div>
            <div *ngIf="subtitlePosition === 'right'" class="subtitle" [title]="subtitleHover">{{subtitle}}</div>
            <div *ngIf="commands !== undefined && commands.length > 0" class="commands">
                <div class="elip-container">
                    <app-ellipses-menu [commands]="commands"></app-ellipses-menu>
                </div>
            </div>
            <div *ngIf="template !== undefined" class="commands">
                <div class="template-container">
                    <ng-container *ngTemplateOutlet="template"></ng-container>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="expanded === true" class="expandable-content">
        <ng-content></ng-content>
    </div>
</div>