import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

import { IconModule } from '../icon/icon.module';
import { FileHelperModule } from '../file-helper/file-helper.module';
import { BlockButtonModule } from '../block-button/block-button.module';
import { SelectBoxModule } from '../select-box/select-box.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

import { CustomerHubPanelComponent } from './customer-hub-panel.component';
import { CustomerHubPanelService } from './customer-hub-panel.service';

@NgModule({
    imports: [
        FormsModule,
        MatInputModule,
        IconModule,
        FileHelperModule,
        BlockButtonModule,
        SelectBoxModule,
        LoadingSpinnerModule,
        CommonModule
    ],
    exports: [
        CustomerHubPanelComponent
    ],
    declarations: [
        CustomerHubPanelComponent
    ],
    providers:[
        CustomerHubPanelService
    ]
})
export class CustomerHubPanelModule { }

export * from './customer-hub-panel.component'