import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { BlockButtonModule, IconModule } from '@summize/shared/components-v2';

import { SelectBoxModule } from '../select-box/select-box.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

import { QuestionSetSelectionComponent } from './question-set-selection.component';
import { QuestionSetSelectionService } from './question-set-selection.service'

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        BlockButtonModule,
        SelectBoxModule,
        LoadingSpinnerModule,
        IconModule
    ],
    exports: [
        QuestionSetSelectionComponent
    ],
    declarations: [
        QuestionSetSelectionComponent]
    ,
    providers: [
        QuestionSetSelectionService
    ],
})
export class QuestionSetSelectionModule { }

export * from './question-set-selection.component'