import { NgModule } from '@angular/core';

import { AnalyicsComponent } from './analytics.component';
import { AnalyticsRoutingModule } from './analytics.routes';

@NgModule({
    imports: [AnalyticsRoutingModule],
    exports: [],
    declarations: [AnalyicsComponent],
    providers: [],
})
export class AnalyticsModule { }

export { MetricBarModule } from './components/metric-bar/metric-bar.module';
export { MetricModule } from './components/metric/metric.module';
export { ClauseMetricModule } from './components/clause-metric/clause-metric.module';
export { ProgressBarModule } from './components/progress-bar/progress-bar.module';
export { KpiModule } from './components/kpi/kpi.module';

export * from './analytics.types';