import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { OverlayModule } from '@angular/cdk/overlay';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { BlockButtonModule } from '../block-button/block-button.module';
import { DatetimeFilterComponent } from './datetime-filter.component';
import { SmzDatePipe } from '../pipes/date.pipe';
import { CorePipesModule } from '../pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        OverlayModule,
        BlockButtonModule,
        MatDatepickerModule,
        CorePipesModule
    ],
    exports: [
        DatetimeFilterComponent
    ],
    declarations: [
        DatetimeFilterComponent
    ],
    providers: [
        SmzDatePipe
    ]
})
export class DatetimeFilterModule { }

export * from './datetime-filter.component'