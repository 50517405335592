import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlockButtonModule, ContextBarModule, IconModule, LoadingSpinnerModule, SelectBoxModule } from '@summize/shared/components-v2';

import { ManageComponent } from './manage.component';
import { CustomFieldEditorModule } from '../components';
import { ManageService } from './manage.service';

@NgModule({
    imports: [
        CommonModule,
        ContextBarModule,
        BlockButtonModule,
        LoadingSpinnerModule,
        CustomFieldEditorModule,
        IconModule,
        SelectBoxModule
    ],
    declarations: [
        ManageComponent
    ],
    providers: [
        ManageService
    ]
})
export class ManageModule { }
