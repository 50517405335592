import { Component } from '@angular/core';

import { AppComponent } from '@summize/shared/core';
import { EventService, UserService } from '@summize/shared/framework';

@Component({
    selector: 'app-user-profile',
    templateUrl: 'user-profile.html',
    styleUrls: ['./user-profile.scss']
})
export class UserProfileComponent extends AppComponent {

    public fullName: string;
    public fullNameTooltip: string;

    constructor(
        private userService: UserService,
        private events: EventService
    ) { super() }

    public async ngOnInit() {

      this.fullName = `${this.user?.firstName} ${this.user?.lastName}`;
      this.fullNameTooltip = `${this?.fullName} (${this.user?.email})`;

    }

    public async showCalendarSync() {

        this.events.despatch(EventService.GlobalEvents.LaunchCalendarSync);

    }

    public async refreshSession() {

        await this.userService.refreshSession();

        window.location.href = window.location.href

    }

    public logout() {

        window.location.href = '/logout';

    }

}
