<div>
  <mat-form-field class="chip-list" subscriptSizing="dynamic">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <mat-chip-grid #chipList aria-label="Item selection">
      <mat-chip-row *ngFor="let selectedItem of selectedItems" (removed)="removeItem(selectedItem)">
        {{selectedItem.value}}
        <button matChipRemove>
          <app-icon icon="close" iconColor="#212128"></app-icon>
        </button>
      </mat-chip-row>
      <input [placeholder]="placeholder" #itemInput [formControl]="myControl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEn)="addItem($event)">
    </mat-chip-grid>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-optgroup *ngFor="let group of groups" [label]="group.value">
        <ng-container *ngFor="let filteredItem of filteredItems | async">
          <mat-option *ngIf="filteredItem.groupKey == group.key" [value]="filteredItem.key">
            {{filteredItem.value}}
          </mat-option>
        </ng-container>
      </mat-optgroup>
    </mat-autocomplete>
  </mat-form-field>
</div>