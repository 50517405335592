import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseTenantService } from '@summize/shared/core';

@Injectable({ providedIn: 'root' })
export class GlobalSearchService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public query(query: string, client: string, matter: string, isQuickSummary: boolean): Promise<any> {

        let baseUrl = `${this.getUserBaseUrl(true)}Search/Facets?searchText=${encodeURIComponent(query)}`;

        if (client !== undefined && client !== null && client !== '') {

            baseUrl = `${baseUrl}&clientId=${client}`;

        }

        if (matter !== undefined && matter !== null && matter !== '') {

            baseUrl = `${baseUrl}&matterId=${matter}`;

        }

        if (isQuickSummary === true) {

            baseUrl = `${baseUrl}&isQuickSummarySearch=true`;
        }

        return this.http.get(baseUrl).toPromise();

    }

}