import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { EventService } from '@summize/shared/framework';

import { ChangeCustomFieldDialogService, CustomFieldDefinition, CustomFieldType } from './change-customfield-dialog.service';

@Component({
    selector: 'app-change-customfield-dialog',
    templateUrl: 'change-customfield-dialog.html',
    styleUrls: ['./change-customfield-dialog.scss']
})
export class ChangeCustomFieldDialogComponent {

    public CustomFieldType = CustomFieldType;
    
    public choosenCustomFieldDefinition: CustomFieldDefinition;

    public customFields: Array<any>;

    public customFieldDefinitionsByDocumentIds: Array<any>;

    public hasCustomFieldDefinitionSelected: boolean;

    public dropdownValues: Array<any>;

    public selectedCustomField: any;

    public selectedCustomFieldType: any;

    public isLoading: boolean = false;

    constructor(
        private server: ChangeCustomFieldDialogService,
        public dialogRef: MatDialogRef<ChangeCustomFieldDialogComponent>,
        private events: EventService,
        @Inject(MAT_DIALOG_DATA) public data) {
         }

    public async ngOnInit(): Promise<any> {

        this.isLoading = true;

        try {

            this.customFieldDefinitionsByDocumentIds = await this.server.getCustomFieldDefinitionsByDocumentIds(this.data.selectedDocumentIds);

            this.addCustomFieldDefinitionsToDropDown();

        } catch (error) {

            this.events.actionFailed('Something went wrong trying to load the custom fields, please try again.');
            
        }

        this.isLoading = false;
    }

    public save() {
        
        this.dialogRef.close({
            customFieldId: this.choosenCustomFieldDefinition.id,
            customFieldValue: this.selectedCustomField
        });

    }

    public close() {

        this.dialogRef.close();

    }
   
    public selectCustomFieldDefinition(selectedCustomFieldDefinitionId){
        
        this.choosenCustomFieldDefinition = this.customFieldDefinitionsByDocumentIds.find(x => x.id === selectedCustomFieldDefinitionId);
        if (!this.choosenCustomFieldDefinition) {
            return;
        }

        this.selectedCustomField = null;

        switch(this.choosenCustomFieldDefinition.fieldType){
            case CustomFieldType.Dropdown:
            case CustomFieldType.MultiSelect:
                this.dropdownValues = this.getDropdownValues(this.choosenCustomFieldDefinition.values)
                break;
            case CustomFieldType.FreeText:
                this.selectedCustomFieldType = "text";
                break;
            case CustomFieldType.Numeric:
                this.selectedCustomFieldType = "number";
                break;
            case CustomFieldType.Datepicker:
                this.selectedCustomFieldType = "date";
        }

        this.hasCustomFieldDefinitionSelected = true;
    }

    public setCustomFieldDropDownValue(dropDownValueId){

        if(this.choosenCustomFieldDefinition.fieldType === CustomFieldType.Dropdown 
            || this.choosenCustomFieldDefinition.fieldType === CustomFieldType.MultiSelect){

            this.selectedCustomField = Array.isArray(dropDownValueId) 
            ? dropDownValueId.join(',')
            : dropDownValueId;
        }
    }

    private async addCustomFieldDefinitionsToDropDown() {
        if (this.customFieldDefinitionsByDocumentIds.length === 0) {
          this.customFields = [{ value: 'None of the contracts selected have custom fields' }];
        } else {
          this.customFields = this.customFieldDefinitionsByDocumentIds.map(x => ({ key: x.id, value: x.name }));
        }
    }

    private getDropdownValues(values: string){
        if(values === ""){
            return;
        }
        const parsedValues = JSON.parse(values);
        return [...parsedValues.map(x => ({key: x.id, value: x.display}))]
    }
}