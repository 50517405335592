import { ChangeDetectionHelper } from "./cd-helper";
import { HEADER_HEIGHT, IsLegalFrontDoor } from "./constants";

import { fromEvent, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export const SMALL_DISPLAY_WIDTH = 1300;

export class LayoutHelper {

    public static BindComponent(selector: string, heightProducer: (number) => number, handleResize = false): void {

        const onChange = () => {

            ChangeDetectionHelper.doNextCycle(() => {

                const headerHeight = IsLegalFrontDoor() === true ? 0 : HEADER_HEIGHT;

                const height = LayoutHelper.getWindowHeight() - headerHeight;

                const doc = document
                    .querySelector(selector);

                doc?.setAttribute('style', `height:${heightProducer(height)}px;`);

            });

        }
        if (handleResize === true) {

            this.getResizeObservable().subscribe(() => onChange());

        }

        onChange();

    }

    public static getWindowHeight(): number {

        if (window.visualViewport !== undefined) {

            return window.visualViewport.height;

        }

        return window.innerHeight;

    }

    public static getAvailableVerticalSpaceAfterElements(elements: Array<string>, buffer: number = 200): number {

        const headerHeight = IsLegalFrontDoor() === true ? 0 : HEADER_HEIGHT;

        let height = LayoutHelper.getWindowHeight() - headerHeight;

        for (const e of elements) {

            const found = document
                .querySelectorAll(e);

            if (found !== null && found.length > 0) {

                const first = found[0];

                height = height - first.scrollHeight;

            }
        }

        return height - buffer;

    }

    public static getWindowWidth(): number {

        if (window.visualViewport !== undefined) {

            return window.visualViewport.width;

        }

        return window.innerWidth;

    }

    public static isSmallDisplay(): boolean {

        // SteO: Not ideal
        const isELFD = (<any>window).summize?.isIsolated === true
            || (<any>window).smzHost === 'outlook';

        if (isELFD) {

            return false;

        }

        return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) < SMALL_DISPLAY_WIDTH;

    }

    public static getResizeObservable(time = 300): Observable<Event> {

        return fromEvent(window, 'resize').pipe(
            debounceTime(time)
        );

    }
}