export class FixedArray {

    private _items: Array<any>;

    constructor(private length) {

        this._items = [];

    }

    public push(item: any): void {

        this._items = this._items.slice(Math.max(this._items.length - this.length, 0))

        this._items.push(item);

    }

    public get() {

        return this._items;

    }

    public last() {

        return this._items[this._items.length - 1];

    }
    
}