import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { UserPanelComponent } from './user-panel.component';
import { IconModule } from '../icon/icon.module';
import { UserPanelService } from './user-panel.service';
import { SearchBarModule } from '../search-bar/search-bar.module';
import { UserPanelPipe } from './user-panel.pipe';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { AvatarModule } from '../avatar/avatar.module';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        AvatarModule,
        SearchBarModule,
        LoadingSpinnerModule,
        RouterModule.forChild([])
    ],
    exports: [
        UserPanelComponent
    ],
    declarations: [
        UserPanelComponent,
        UserPanelPipe
    ],
    providers: [
        UserPanelService
    ]
})
export class UserPanelModule { }

export * from './user-panel.component'