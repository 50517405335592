<div *ngIf="metric !== undefined" class="clause-metric {{mode}}">
    <div class="header-area fade-block">
        <div class="title-content">
            <div class="title fade-block">
                {{definition?.settings?.name || metric.title}}
            </div>
        </div>
        <div *ngIf="isCompare === false" class="show-all">
            <app-block-button (click)="goToTable(metric)" display="Show All" type="v2" width="85">
            </app-block-button>
        </div>
    </div>
    <div class="section-content fade-block">

        <div class="value-content">
            <span class="current-value {{mode}}" [matTooltip]="getValueTooltip(metric)">
                {{getValueDisplay(metric)}}
            </span>

            <ng-container *ngIf="metric.currentValue !== metric.previousValue">
                <div class="trend {{getTrendDirection(metric.trendDirection)}}">
                    <div class="trend-value" *ngIf="metric.trendDirection === TrendDirection.Up">
                        <app-icon [icon]="'arrow-up-outline'"></app-icon>
                        <div>{{metric.percentage}}</div>
                    </div>

                    <div class="trend-value" *ngIf="metric.trendDirection === TrendDirection.Down">
                        <app-icon [icon]="'arrow-down-outline'"></app-icon>
                        <div>{{metric.percentage}}</div>
                    </div>
                </div>
            </ng-container>

        </div>

        <div class="range-content">
            <div class="low">
                {{maskValue(metric.lowDisplay)}}
            </div>
            <div class="progress">
                <app-progress-bar [complete]="progressValue" [rounded]="true" [delta]="deltaValue" color="#D1D1D1">
                </app-progress-bar>
            </div>
            <div class="low">
                {{maskValue(metric.highDisplay)}}
            </div>
        </div>

        <div class="footer-content">
            <div> {{metric.templateName}}</div>
            <div class="pill-container">
                {{metric.type}}
            </div>
            <div *ngIf="isCompare === false" class="menu-container">
                <app-ellipses-menu [commands]="commands"></app-ellipses-menu>
            </div>
        </div>

    </div>
</div>

<div *ngIf="metric === undefined" class="clause-metric">
    <div class="header-area fade-block">
        <div class="title-content">
            <div class="title fade-block">
                &nbsp;
            </div>
        </div>

    </div>
    <div class="section-content fade-block">
        <app-loading-spinner visible="true" type="pale"></app-loading-spinner>
        <div *ngIf="isCompare === false" class="footer-content">
            <div class="menu-container">
                <app-ellipses-menu [commands]="commands"></app-ellipses-menu>
            </div>
        </div>
    </div>
</div>