import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DialogShellComponent } from './dialog-shell.component';
import { IconModule } from '../icon/icon.module';
import { BlockButtonModule } from '../block-button/block-button.module';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        BlockButtonModule
    ],
    exports: [
        DialogShellComponent
    ],
    declarations: [
        DialogShellComponent
    ]
})
export class DialogShellModule { }

export * from './dialog-shell.component'