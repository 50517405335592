import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { UserService } from './services/user.service';
import { IsNotEmptyString } from '@summize/shared/core';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagGuard implements CanActivate {

    constructor(private userService: UserService, private router: Router) { }

    public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {

        await this.userService.loadUser();

        const requiredFeatureFlag = route.data.requiredFeatureFlag as string;

        const redirectToFF = route.data.redirectToFF as string;

        const redirectToPermission = route.data.redirectToPermission as string;

        const requiresTenantAdmin = route.data.requireIsTenantAdmin as boolean;

        const requiredClaims = route.data.requiredClaims as Array<string>;

        if (requiresTenantAdmin === true && this.userService.getIsAdmin() !== true) {

            return IsNotEmptyString(redirectToPermission) ? this.router.navigateByUrl(redirectToPermission) : false;

        }

        if (requiredClaims !== undefined && requiredClaims.length > 0 &&
            this.userService.hasClaims(requiredClaims) !== true) {

            return IsNotEmptyString(redirectToPermission) ? this.router.navigateByUrl(redirectToPermission) : false;

        }

        if (requiredFeatureFlag !== undefined && this.userService.hasFeatureFlag(requiredFeatureFlag) !== true) {

            return IsNotEmptyString(redirectToFF) ? this.router.navigateByUrl(redirectToFF) : false;

        }

        return true;

    }

}
