import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InfoPanelComponent } from './info-panel.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        InfoPanelComponent
    ],
    declarations: [
        InfoPanelComponent
    ]
})
export class InfoPanelModule { }

export * from './info-panel.component'