import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlockButtonComponent } from './block-button.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        BlockButtonComponent
    ],
    declarations: [
        BlockButtonComponent
    ]
})
export class BlockButtonModule { }

export * from './block-button.component'