<div class="dashboard-host">
    <div *ngIf="isLoading; else dashboardPanel">
        <app-loading-spinner visible="true"></app-loading-spinner>
    </div>

    <ng-template #dashboardPanel class="dashboard">

        <app-context-bar type="v2" [hasBackButton]="false" primaryText="Dashboard"
            [primaryTemplate]="primaryTemplate" [template]="template">

            <ng-template #primaryTemplate>

                <div *ngIf="selectedDashboard !== undefined"
                    (click)="isDashboardDropdownOpen = !isDashboardDropdownOpen" #trigger="cdkOverlayOrigin"
                    cdkOverlayOrigin class="flex-row clickable filter-selector-header center">
                    <div class="title">
                        {{selectedDashboard?.name ?? 'Dashboard 1'}}
                    </div>
                    <div *ngIf="dashboardsResponse?.dashboards?.length > 0" class="icon">
                        <app-icon icon="chevron-down-outline"></app-icon>
                    </div>
                    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger"
                        [cdkConnectedOverlayOpen]="isDashboardDropdownOpen"
                        (overlayOutsideClick)="isDashboardDropdownOpen = false" [cdkConnectedOverlayHasBackdrop]="true"
                        cdkConnectedOverlayBackdropClass="transparent-backdrop">
                        <div class="filter-selector">
                            <div class="filter-list">
                                <div *ngFor="let dashboard of dashboardsResponse.dashboards"
                                    class="filter-list-item clickable flex-row flex-space-between">
                                    <a (click)="goToDashboard(dashboard.id)">
                                        <p>{{dashboard.name}} </p>
                                        <span *ngIf="dashboard.isDefault === true"> (My dashboard)</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>

            </ng-template>

            <ng-template #template>
                <div class="context-bar-commands">
                    <app-block-button (click)="saveDashboard()" display="Save Dashboard" type="v2-primary" width="170"
                        class="mr-2" [disabled]="pendingChanges === false" icon="save-outline">
                    </app-block-button>

                    <app-block-button (click)="addChart()" display="Add Chart" type="v2-blue" width="140" class="mr-2"
                        icon="add-circle-outline">
                    </app-block-button>

                    <app-block-button (click)="createDashboard()" display="Create Dashboard" type="v2-blue" width="180"
                        class="mr-2">
                    </app-block-button>

                    <app-ellipses-menu [commands]="dashboardCommands" [icon]="'ellipsis-vertical-outline'"
                        class="user-menu">
                    </app-ellipses-menu>

                </div>
            </ng-template>

        </app-context-bar>


        <div *ngIf="selectedDashboard !== undefined && selectedDashboard?.widgets?.length > 0">

            <div *ngFor="let widget of selectedDashboard.widgets; index as i" class="widget-host-container">

                <app-widget-host [widget]="widget" [showFilters]="true" (onDeleteWidget)="onDeleteWidget(i)"
                    (onFiltersChange)="onFiltersChange(i, $event)" (onTimeFormatChange)="onTimeFormatChange(i, $event)"
                    (onTimeframeChange)="onTimeframeChange(i, $event)"></app-widget-host>

            </div>
        </div>

        <div *ngIf="selectedDashboard?.widgets === undefined || selectedDashboard?.widgets?.length === 0"
            class="empty-dashboard">

            <app-icon customSVG="/assets/icons/chart.svg"></app-icon>

            <h2>Add a chart to begin exploring your data</h2>

            <span>Get started by clicking the 'Add chart' button to browse Summize's library of available charts.</span>

            <span>To learn more about Request Analytics visit our Customer Hub.</span>

            <app-block-button (click)="addChart()" display="Add Chart" type="v2-primary" width="140" class="mr-2"
                icon="add-circle-outline">
            </app-block-button>
        </div>

    </ng-template>
</div>