<div>

    <app-block-button (click)="isOpen = !isOpen" #trigger="cdkOverlayOrigin" cdkOverlayOrigin [display]="buttonText"
        type="v2" [width]="buttonWidth" [icon]="buttonIcon"></app-block-button>

    <ng-template #overlayTemplate cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" (detach)="detach()"
        [cdkConnectedOverlayOpen]="isOpen" (overlayOutsideClick)="isOpen = false"
        [cdkConnectedOverlayPositions]="overlayPositions" [cdkConnectedOverlayHasBackdrop]="true"
        cdkConnectedOverlayBackdropClass="transparent-backdrop">
        <div class="expanded-host flex-column scroll {{hostClass}}">

            <ng-container *ngIf="selectedFilter === undefined">
                <div [@fadeInAnimation] class="filter-list scroll">
                    <div *ngFor="let filter of filters" (click)="setSelectedFilter(filter)"
                        class="filter-list-item clickable fade-block">
                        {{filter.name}}
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="selectedFilter !== undefined">

                <div [@fadeInAnimation] class="filter-detail flex-grow no-scroll">

                    <div class="header flex-row fade-block">
                        <app-icon *ngIf="isEdit === false" icon="arrow-back-outline" (click)="cancel()"
                            class="clickable"> </app-icon>
                        <span *ngIf="selectedSubType === undefined" class="filter-title">{{selectedFilter.name}}</span>
                        <span *ngIf="selectedSubType !== undefined" class="filter-title">{{selectedSubType.name}}</span>
                    </div>

                    <div>
                        <div *ngIf="selectedFilter.type !== FilterType.DateRange && selectedSubType === undefined"
                            class="search fade-block">
                            <app-search-bar placeholder="Search ..." (onClearSearch)="query = undefined"
                                (onSearch)="query = $event">
                            </app-search-bar>
                        </div>

                        <div class="scroll-container fade-block">

                            <ng-container *ngIf="selectedFilter.type === FilterType.SubSelect">
                                <div *ngIf="selectedSubType === undefined" class="select-container">
                                    <div class="items">
                                        <ng-container *ngIf="(selectedFilterItems | search: query) as result">
                                            <div *ngIf="result === undefined || result.length ===0">
                                                No results
                                            </div>
                                            <div *ngFor="let item of result" class="flex-row {{item.class}}">
                                                <div (click)="setSelectedSubType(item)"
                                                    class="filter-item-name clickable">
                                                    <div class="toggle">
                                                        {{item.name}}
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div *ngIf="selectedSubType !== undefined" class="select-container">
                                    <div *ngIf="selectedSubType.type === SubFiltertype.Freetext"
                                        class="sub-filter-container items">
                                        <div class="op-container">
                                            <div class="sub-title">
                                                Value
                                            </div>
                                            <app-select-box (changed)="selectedOperator = $event"
                                                [value]="selectedOperator" [options]="typeOperators"
                                                [canSearch]="false"></app-select-box>
                                        </div>
                                        <div class="input">
                                            <div class="sub-title">
                                                Text
                                            </div>
                                            <input [(ngModel)]="selectedSubfilterItem" name="text" type="text"
                                                class="smz-standard-input v2" autocomplete="off">
                                        </div>
                                    </div>
                                    <div *ngIf="selectedSubType.type === SubFiltertype.Numeric"
                                        class="sub-filter-container items">
                                        <div class="op-container">
                                            <div class="sub-title">
                                                Number
                                            </div>
                                            <app-select-box (changed)="selectedOperator = $event"
                                                [value]="selectedOperator" [options]="typeOperators"
                                                [canSearch]="false"></app-select-box>
                                        </div>
                                        <div class="input">
                                            <div class="sub-title">
                                                Text
                                            </div>
                                            <input [(ngModel)]="selectedSubfilterItem" name="text" type="number"
                                                class="smz-standard-input v2" autocomplete="off">
                                        </div>
                                    </div>
                                    <div *ngIf="selectedSubType.type === SubFiltertype.Date">
                                        <div class="date-container">
                                            <div class="date">
                                                <div class="title">
                                                    From:
                                                </div>
                                                <input class="smz-standard-input" type="date"
                                                    [(ngModel)]="selectedFrom" />
                                            </div>
                                            <div class="date">
                                                <div class="title">
                                                    To:
                                                </div>
                                                <input class="smz-standard-input" type="date"
                                                    [(ngModel)]="selectedTo" />
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="selectedSubType.type === SubFiltertype.List"
                                        class="sub-filter-container items">
                                        <div class="op-container">
                                            <div class="sub-title">
                                                Value
                                            </div>
                                            <app-select-box (changed)="selectedOperator = $event"
                                                [value]="selectedOperator" [options]="typeOperators"
                                                [canSearch]="false"></app-select-box>
                                            <div class="search">
                                                <app-search-bar placeholder="Search ..."
                                                    (onClearSearch)="query = undefined" (onSearch)="query = $event">
                                                </app-search-bar>
                                            </div>
                                        </div>
                                        <div class="input">
                                            <div *ngFor="let item of selectedFilterItems| search: query"
                                                class="flex-row">
                                                <div class="toggle">
                                                    <mat-checkbox [checked]="item.enabled === true"
                                                        (change)="item.enabled = $event.checked">
                                                        {{item.name}}
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="selectedFilter.type === FilterType.List">
                                <div class="select-container">
                                    <div class="items">
                                        <ng-container *ngIf="(selectedFilterItems | search: query) as result">
                                            <div *ngIf="result === undefined || result.length ===0">
                                                No results
                                            </div>
                                            <div *ngFor="let item of result" class="flex-row {{item.class}} clickable">
                                                <div class="toggle">
                                                    <mat-checkbox [checked]="item.enabled === true"
                                                        (change)="item.enabled = $event.checked">
                                                        {{item.name}}
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="selectedFilter.type === FilterType.RadioSelect">
                                <div class="radio-control">
                                    <mat-radio-group [(ngModel)]="selectedRadioItem"
                                        class="radio-control flex-column clickable">
                                        <ng-container *ngIf="(selectedFilterItems | search: query) as result">
                                            <div *ngIf="result === undefined || result.length ===0">
                                                No results
                                            </div>
                                            <mat-radio-button *ngFor="let item of result" [value]="item.id"
                                                class="radio-text clickable">
                                                {{item.name}}
                                            </mat-radio-button>
                                        </ng-container>
                                    </mat-radio-group>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="selectedFilter.type === FilterType.ParentChildList">
                                <div class="select-container">
                                    <div class="items">
                                        <ng-container *ngIf="(selectedFilterItems | search: query:true) as result">
                                            <div *ngIf="result === undefined || result.length ===0">
                                                No results
                                            </div>
                                            <div *ngFor="let item of result" class="parent">
                                                <div class="flex-row">
                                                    <div class="toggle">
                                                        <mat-checkbox [checked]="item.enabled === true"
                                                            (change)="parentElementChecked(item, $event)">
                                                            {{item.name}}
                                                        </mat-checkbox>
                                                    </div>
                                                </div>
                                                <div *ngFor="let child of item.children">
                                                    <div *ngIf="isSubfolderMatch(child)" class="children flex-row">
                                                        <div class="toggle">
                                                            <mat-checkbox [checked]="child.enabled === true"
                                                                (change)="childElementChecked(child, item, $event)">
                                                                {{child.name}}
                                                            </mat-checkbox>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="selectedFilter.type === FilterType.ListWithGrouping">
                                <div class="select-container">
                                    <div class="items">
                                        <div class="group-header">
                                            By group
                                        </div>
                                        <ng-container *ngIf="(selectedFilterItems.groups | search: query) as result">
                                            <div *ngIf="result === undefined || result.length ===0">
                                                No results
                                            </div>
                                            <div *ngFor="let item of result" class="flex-row {{item.class}}">
                                                <div class="toggle">
                                                    <mat-checkbox [checked]="item.enabled === true"
                                                        (change)="item.enabled = $event.checked">
                                                        {{item.name}}
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="items">
                                        <div class="group-header">
                                            By {{selectedFilter.entity || 'record'}}
                                        </div>
                                        <ng-container *ngIf="(selectedFilterItems.items | search: query) as result">
                                            <div *ngIf="result === undefined || result.length ===0">
                                                No results
                                            </div>
                                            <div *ngFor="let item of result" class="flex-row {{item.class}}">
                                                <div class="toggle">
                                                    <mat-checkbox [checked]="item.enabled === true"
                                                        (change)="item.enabled = $event.checked">
                                                        {{item.name}}
                                                    </mat-checkbox>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="selectedFilter.type === FilterType.EntitiesWithGrouping">
                                <div class="select-container">
                                    <div *ngFor="let groupName of selectedFilterItems.entities | keyvalue"
                                        class="items">
                                        <div class="group-header">
                                            By {{ groupName.key || 'record'}}
                                        </div>
                                        <ng-container *ngIf="(groupName.value | search: query) as result">
                                            <div *ngIf="result === undefined || result.length ===0">
                                                No results
                                            </div>
                                            <div *ngFor="let item of result" class="flex-row {{item.class}}">
                                                <div class="toggle">
                                                    <mat-checkbox [checked]="item.enabled === true"
                                                        (change)="item.enabled = $event.checked">
                                                    </mat-checkbox>
                                                </div>
                                                <div class="filter-item-name">
                                                    <div class="toggle">
                                                        {{item.name}}
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="selectedFilter.type === FilterType.DateRange">
                                <div class="date-container">
                                    <div class="date">
                                        <div class="title">
                                            From:
                                        </div>
                                        <input class="smz-standard-input" type="date" [(ngModel)]="selectedFrom" />
                                    </div>
                                    <div class="date">
                                        <div class="title">
                                            To:
                                        </div>
                                        <input class="smz-standard-input" type="date" [(ngModel)]="selectedTo" />
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                    </div>
                </div>

                <div class="commands flex-row fade-block">
                    <app-block-button (click)="cancel(true)" class="mr-3" display="Cancel" width="100" type="v2-blue">
                    </app-block-button>
                    <app-block-button (click)="apply()" [disabled]="hasValue() === false" display="Apply" width="90"
                        type="v2-primary">
                    </app-block-button>
                </div>

            </ng-container>

        </div>
    </ng-template>

</div>