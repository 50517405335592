import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { DialogShellModule, LoadingSpinnerModule, SelectBoxModule } from '@summize/shared/components-v2';

import { DownloadDocumentsComponent } from './download-documents.component';

@NgModule({
    imports: [
        CommonModule,
        MatCheckboxModule,
        DialogShellModule,
        LoadingSpinnerModule,
        SelectBoxModule
    ],
    exports: [
        DownloadDocumentsComponent
    ],
    declarations: [
        DownloadDocumentsComponent
    ]
})
export class DownloadDocumentsModule { }

export * from './download-documents.component';
