import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'boolToString' })
export class BoolToStringPipe implements PipeTransform {
    transform(value: boolean): string {

        if (typeof value === 'boolean') {
            return value === true ? 'Yes' : 'No';
        } else {
            return value;
        }
    }
}