import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { FadeInAnimation, SlideUpAnimation, User } from '@summize/shared/framework';

import { SlidePanelService, SlidePanelSettings } from '../slide-panel/slide-panel.service';
import { UserPanelComponent } from '../user-panel/user-panel.component';

@Component({
    selector: 'app-header',
    templateUrl: 'app-header.html',
    styleUrls: ['./app-header.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [FadeInAnimation, SlideUpAnimation]
})

export class AppHeaderComponent {

    @Input()
    public user: User;

    @Input()
    public logo: string;

    public avatarSize: number = 32;

    public get name() {

        return `${this.user?.firstName} ${this.user?.lastName}`;

    }

    constructor(private panelService: SlidePanelService) { }

    public showUserPanel(): void {

        const settings: SlidePanelSettings = {
            component: UserPanelComponent,
            arguments: {
                user: this.user
            },
            title: this.name,
            closeable: true,
            size: 'lg',
            backdrop: true
        };

        this.panelService.show(settings);

    }

}