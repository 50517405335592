import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { BlockButtonModule, EllipsesMenuModule } from '@summize/shared/components-v2';

// RichS: Importing from the same module as you are exporting from often seems to have
// circular dependency (or 'import/export ordering') issues, so use relative folder import.
import { CorePipesModule } from '../pipes/pipes.module';
import { SmzDatePipe } from '../pipes/date.pipe';

import { CalendarCardComponent } from './calendar-card.component'

@NgModule({
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatTooltipModule,
        EllipsesMenuModule,
        BlockButtonModule,
        CorePipesModule,
        MatCheckboxModule
    ],
    exports: [
        CalendarCardComponent
    ],
    declarations: [
        CalendarCardComponent,
    ],
    providers: [
        SmzDatePipe
    ]
})
export class CalendarCardModule { }

export * from './calendar-card.component'
