<app-dialog-shell (onClose)="close()" (onSave)="save()" [saveDisabled]="selectedValue === undefined" [showFooter]="true"
    title="Add to Clause" saveText="Add to Clause" saveButtonWidth="150">
    <div class="conversation">
        <div class="section">
            <p>
                Select a clause to assign the selected text to.
            </p>
        </div>
        <div class="section">
            <app-select-box (changed)="onClauseSelected($event)" [value]="selectedValue"
            [options]="clauses" placeholder="Select Clause">
        </app-select-box>
        </div>
    </div>
</app-dialog-shell>