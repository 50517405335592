<div [class.component-display]="displayAsComponent === true" class="user-tasks flex-column">
    <ng-container *ngIf="displayAsComponent === false">
        <div class="header">
            {{ title }}
            <span *ngIf="isFullUser === false">
                ({{buckets[AssingmentStatus.User].length}})
            </span>
        </div>

        <div *ngIf="isFullUser === false" class="sub-header">
            View all tasks assigned to you or your group.
        </div>

        <div *ngIf="isFullUser === true" class="sub-header">
            View all tasks assigned to you or your group, or view all {{ title }}.
        </div>
    </ng-container>

    <div class="mt-3 mb-1">
        <app-task-legend></app-task-legend>
    </div>

    <ng-container *ngIf="isLoading === true">
        <app-loading-spinner visible="true" type="pale" [container]="true"></app-loading-spinner>
    </ng-container>

    <div *ngIf="isLoading === false" class="panel-content scroll">
        <div *ngIf="isFullUser === true" class="tabs-header fade-block">
            <div (click)="selectedStatus = AssingmentStatus.User"
                [class.selected]="selectedStatus ===  AssingmentStatus.User" class="tab selected clickable">
                My Tasks <span>({{buckets[ AssingmentStatus.User].length}})</span>
            </div>
            <div (click)="selectedStatus =  AssingmentStatus.All"
                [class.selected]="selectedStatus === AssingmentStatus.All" class="tab clickable">
                All Tasks <span>({{buckets[AssingmentStatus.All].length}})</span>
            </div>
        </div>
        <div class="card-list">
            <div *ngFor="let task of buckets[selectedStatus]" class="mb-3">
                <app-task-card *ngIf="displayAsComponent === false" [model]="task" mode="Instance"
                    [showDocumentName]="true" [stage]="task.requestStage" [displayCommands]="false" />
                <app-task-card *ngIf="displayAsComponent === true" (onDocumentClick)="onDocumentClick.emit($event)"
                    (onComplete)="complete($event)" [model]="task" mode="Instance" [showDocumentName]="true"
                    [stage]="task.requestStage" [compact]="true" [canCompleteTask]="true" [displayCommands]="false" />
            </div>
        </div>
    </div>

</div>