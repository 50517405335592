<div *ngIf="files === undefined || files.length === 0" class="no-files-container">
    <div class="add-file-container" [ngClass]="{'disabled': !enableComponent}">
        <app-block-button *ngIf="attachmentContext === undefined" (click)="file.click()" type="text-primary" width="200"
            display="Add file" icon="add-circle-outline" [iconPosition]="'right'" iconColor="#0066EF"
            [disabled]="!enableComponent">
        </app-block-button>

        <app-block-button *ngIf="attachmentContext !== undefined" [matMenuTriggerFor]="menu" type="text-primary"
            width="400" display="Add files" icon="chevron-down-outline" [iconPosition]="'right'" iconColor="#0066EF"
            [disabled]="!enableComponent">
        </app-block-button>

    </div>

</div>

<input class="hidden" type="file" [accept]="getFileTypeFilter()" id="file-upload"
    [multiple]="fileType !== documentTypeEnum.Master" (change)="onFileChange()" #file>

<div *ngIf="files?.length > 0">
    <div *ngFor="let file of files;" class="document-container">
        <ng-container>
            <div class="file-data">
                <div class="name">
                    <app-icon *ngIf="file.error === undefined && file.complete === true" class="complete"
                        icon="checkmark-circle" />
                    <app-icon *ngIf="file.error && file.complete === true" class="failed" icon="alert-circle-outline" />
                    <app-loading-spinner *ngIf="file.complete === false" visible="true" [type]="'pale'"
                        class="spinner" />
                    {{file.name}}
                    <div *ngIf="showMoveButtons" class="order-icons">
                        <app-icon (click)="moveDown(file)" [disabled]="last" icon="chevron-down-circle-outline" />
                        <app-icon (click)="moveUp(file)" [disabled]="first" icon="chevron-forward-circle-outline" />
                    </div>
                </div>

                <div class="commands">
                    <div (click)="onRemoveFile(file)" class="remove">
                        <app-icon icon="trash-outline"></app-icon>
                    </div>
                </div>
            </div>

            <div *ngIf="fileType === documentTypeEnum.Linked" class="select-type document-type-picker">
                <app-select-box (changed)="documentTypeChanged($event, file)" [options]="linkedDocumentTypes"
                    [value]="file.documentType" placeholder="Select Type">
                </app-select-box>
            </div>

        </ng-container>
        <div *ngIf="file.error" class="error">
            {{file.error}}
        </div>
    </div>

    <div *ngIf="fileType !== documentTypeEnum.Master && attachmentContext === undefined"
        class="add-another-file-container" (click)="file.click()">
        <app-icon icon="add-circle-outline"></app-icon>
        <div class="text">
            Add another
        </div>
    </div>

    <div *ngIf="fileType !== documentTypeEnum.Master && attachmentContext !== undefined"
        class="add-another-file-container" [matMenuTriggerFor]="menu">
        <app-icon icon="add-circle-outline"></app-icon>
        <div class="text">
            Add another
        </div>
    </div>

</div>

<div class="hint-container">
    <div class="text">{{hintText}}</div>
</div>


<mat-menu #menu="matMenu" [class]="'file-helper-menu'">
    <ng-container *ngIf="isAttachmentsPicker === true && attachmentContext !== undefined && attachmentContext.subject">
        <div class="email-files clickable">Email as attachment</div>
        <button (click)="addEmailAsAttachment()" mat-menu-item>
            <span>{{attachmentContext.subject}}.eml</span>
        </button>
    </ng-container>

    <ng-container>
        <div class="email-files clickable">{{platform.fileText}}</div>
        @if(attachments && attachments.length > 0){
        <ng-container *ngFor="let attachment of attachments">
            <button (click)="addAttachment(attachment)" mat-menu-item>
                <span>{{attachment.name}}</span>
            </button>
        </ng-container>
        }@else {
        <button mat-menu-item>
            <span>No attachments</span>
        </button>
        }

    </ng-container>

    <div (click)="file.click()" class="upload-files clickable flex-row flex-align-vcenter ">
        <app-icon icon="cloud-upload-outline" />
        Upload file
    </div>
</mat-menu>