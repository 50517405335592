<div class="input-container">
    <input type="text" [placeholder]="placeholder" matInput [formControl]="myControl" [matAutocomplete]="auto">
    <div class="clear-button" (click)="clear()">
        <div class="clear-button-icon"></div>
    </div>
</div>
<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="valueChanged($event)">
    <mat-option *ngFor="let option of options | async" [value]="option">
        {{option.optionValue ?? option.value}}
    </mat-option>
</mat-autocomplete>