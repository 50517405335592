import { Component, EventEmitter, Input, Output } from '@angular/core';
import { formatDate } from '@angular/common';

import { DateFilter, DateTimeFilterPresetEnum } from '@summize/shared/framework';
import { CloneObject } from '@summize/shared/core';

export interface DateTimeFilterPreset {
    key: DateTimeFilterPresetEnum;
    value: string;
}

@Component({
    selector: 'app-datetime-filter',
    templateUrl: 'datetime-filter.html',
    styleUrl: './datetime-filter.scss'
})
export class DatetimeFilterComponent {

    @Input()
    public dateFilter: DateFilter;

    @Output()
    public onDateChangedEvent: EventEmitter<DateFilter>;

    public isOpen = false;

    public selectedPreset: DateTimeFilterPreset;

    public displayText: string;

    public DateTimeFilterPresetEnum = DateTimeFilterPresetEnum;

    public dateFrom: any;

    public dateTo: any;

    private originalDateFilter: DateFilter;

    public presets: DateTimeFilterPreset[] = [
        {
            key: DateTimeFilterPresetEnum.Custom,
            value: 'Custom'
        }, {
            key: DateTimeFilterPresetEnum.Last14Days,
            value: 'Last 14 days'
        },
        {
            key: DateTimeFilterPresetEnum.Last28Days,
            value: 'Last 28 days'
        },
        {
            key: DateTimeFilterPresetEnum.Last3Months,
            value: 'Last 3 months'
        },
        {
            key: DateTimeFilterPresetEnum.Last6Months,
            value: 'Last 6 months'
        },
        {
            key: DateTimeFilterPresetEnum.Last12Months,
            value: 'Last 12 months'
        }];

    constructor() {

        this.onDateChangedEvent = new EventEmitter<DateFilter>();

    }

    public ngOnInit() {

        if (this.dateFilter === undefined) {

            this.selectedPreset = this.presets[2];

            const fromDate: Date = new Date();

            const numberOfDaysToTakeOff: number = 28;

            fromDate.setDate(fromDate.getDate() - numberOfDaysToTakeOff);

            this.dateFilter = {
                dateFrom: fromDate.toString(),
                dateTo: new Date().toString(),
                type: DateTimeFilterPresetEnum.Last28Days
            };

        } else {

            this.selectedPreset = this.presets[this.dateFilter.type];

        }

        this.dateFrom = this.getDateString(this.dateFilter.dateFrom);

        this.dateTo = this.getDateString(this.dateFilter.dateTo);

        if (this.dateFilter.type === this.DateTimeFilterPresetEnum.Custom) {

            this.displayText = `${this.dateFrom} - ${this.dateTo}`;

        } else {

            this.displayText = this.selectedPreset.value;

        }

        this.originalDateFilter = CloneObject(this.dateFilter);

    }

    public discardChanges() {

        this.dateFilter = this.originalDateFilter;

        if (this.dateFilter.type === this.DateTimeFilterPresetEnum.Custom) {

            this.displayText = `${this.dateFrom} - ${this.dateTo}`;

        } else {

            this.changePreset(this.dateFilter.type);

            this.displayText = this.selectedPreset.value;

        }

        this.isOpen = false;
    }

    public changePreset(key: DateTimeFilterPresetEnum) {

        this.selectedPreset = this.presets.find(x => x.key === key);

        const fromDate: Date = new Date();

        let numberOfDaysToTakeOff: number = 0;

        let numberOfMonthsToTakeOff: number = 0;

        // Actual figures are 1 less as we include the current day/month as an entry
        switch (this.selectedPreset.key) {
            case DateTimeFilterPresetEnum.Last14Days:

                numberOfDaysToTakeOff = 13;
                break;
            case DateTimeFilterPresetEnum.Last28Days:

                numberOfDaysToTakeOff = 27;
                break;
            case DateTimeFilterPresetEnum.Last3Months:

                numberOfMonthsToTakeOff = 2;
                break;
            case DateTimeFilterPresetEnum.Last6Months:

                numberOfMonthsToTakeOff = 5;
                break;
            case DateTimeFilterPresetEnum.Last12Months:

                numberOfMonthsToTakeOff = 11;
                break;
            case DateTimeFilterPresetEnum.Custom:

                this.displayText = `${this.dateFrom} - ${this.dateTo}`;

                return;

            default:

                return;
        }

        this.dateTo = this.getDateString(new Date());

        if (numberOfDaysToTakeOff !== 0) {

            fromDate.setDate(fromDate.getDate() - numberOfDaysToTakeOff);

        } else if (numberOfMonthsToTakeOff !== 0) {

            fromDate.setMonth(fromDate.getMonth() - numberOfMonthsToTakeOff);

            fromDate.setDate(1);
        }

        this.dateFrom = this.getDateString(fromDate);

        this.displayText = this.selectedPreset.value;
    }

    public onDateFromChanged(event: any) {

        this.dateFrom = event;

        this.selectedPreset = this.presets.find(x => x.key === DateTimeFilterPresetEnum.Custom);

        this.changePreset(DateTimeFilterPresetEnum.Custom);

    }

    public onDateToChanged(event: any) {

        this.dateTo = event;

        this.selectedPreset = this.presets.find(x => x.key === DateTimeFilterPresetEnum.Custom);

        this.changePreset(DateTimeFilterPresetEnum.Custom);

    }

    public apply() {

        this.isOpen = false;

        this.emitDates();
    }

    private emitDates() {

        const formattedFrom = this.getStartOfDayTimestamp(new Date(this.dateFrom));

        const formattedTo = this.getEndOfDayTimestamp(new Date(this.dateTo));

        this.dateFilter = {
            dateFrom: formattedFrom,
            dateTo: formattedTo,
            type: this.selectedPreset.key
        };

        this.originalDateFilter = CloneObject(this.dateFilter);

        this.onDateChangedEvent.next(this.dateFilter);
    }

    private getStartOfDayTimestamp(date: Date): string {

        date.setHours(0, 0, 0, 0);

        return this.dateToIsoIgnoreTimezone(new Date(date.getTime()));

    }

    private getEndOfDayTimestamp(date: Date): string {

        date.setHours(23, 59, 59, 999);

        return this.dateToIsoIgnoreTimezone(new Date(date.getTime()));

    }

    private dateToIsoIgnoreTimezone(date: Date) {

        return date.getFullYear() + '-' +
            ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
            ('0' + date.getDate()).slice(-2) + 'T' +
            ('0' + date.getHours()).slice(-2) + ':' +
            ('0' + date.getMinutes()).slice(-2) + ':' +
            ('0' + date.getSeconds()).slice(-2) + '.' +
            ('00' + date.getMilliseconds()).slice(-3);
    }

    private getDateString(inputDate: any) {

        return formatDate(new Date(inputDate), 'yyyy-MM-dd', 'en-GB');
    }
}