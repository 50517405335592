import { Component, EventEmitter, Input, Output } from '@angular/core';

interface NameIdPair {
    id?: string;
    name: string;
}


@Component({
    selector: 'app-document-filter',
    templateUrl: 'document-filter.html',
    styleUrls: ['./document-filter.scss']
})
export class DocumentFilterComponent {

    @Output()
    public onItemSelect = new EventEmitter<any>();

    @Input()
    public selected!: string;

    @Input()
    public selectedRange!: { to: string, from: string };

    @Input()
    public placeholder!: string;

    @Input()
    public selectedPrefix!: string;

    @Input()
    public type!: 'select' | 'select-group' | 'date';

    @Input()
    public items: any;

    @Input()
    public disabled: boolean = false;

    public isOpen: boolean = false;

    public query!: string;

    public selectedTo: any;

    public selectedFrom: any;

    public get hasSelection(): boolean {

        return this.selected !== undefined ||
            (this.selectedRange !== undefined && (this.selectedRange.to !== undefined || this.selectedRange.from !== undefined));
    }

    public get display() {

        if (this.hasSelection === false) {

            return this.placeholder;

        }

        if (this.type === 'select-group') {

            for (const item of this.items) {

                const _items: Array<NameIdPair> = item.items;

                // SteO -- ToString to cope with ints, guids etcs
                const display = _items?.find(i => i.id?.toString() === this.selected.toString())?.name;

                if (display !== undefined) {

                    return this.selectedPrefix !== undefined ? `${this.selectedPrefix}: ${display}` : display;

                }

            }

        }

        if (this.type === 'select') {

            const _items: Array<NameIdPair> = this.items;

            // SteO -- ToString to cope with ints, guids etcs
            const display = _items?.find(i => i.id?.toString() === this.selected.toString())?.name;

            return this.selectedPrefix !== undefined ? `${this.selectedPrefix}: ${display}` : display;

        }

        if (this.type === 'date') {

            if (this.selectedRange.from !== undefined && this.selectedRange.to === undefined) {

                return `From: ${this.selectedRange.from}`;

            }

            if (this.selectedRange.to !== undefined && this.selectedRange.from === undefined) {

                return `Before: ${this.selectedRange.to}`;

            }

            return `${this.selectedRange.from} - ${this.selectedRange.to}`;

        }

        return 'selected';

    }

    constructor() {

        this.onItemSelect = new EventEmitter<any>();

    }

    public init() {

        this.selectedTo = this.selectedRange?.to;

        this.selectedFrom = this.selectedRange?.from;

    }

    public rangeSelected(): void {

        this.onItemSelect.next({
            from: this.selectedFrom === '' ? undefined : this.selectedFrom,
            to: this.selectedTo === '' ? undefined : this.selectedTo,
        });

        this.isOpen = false;

    }

    public itemSelected(item: any): void {

        this.onItemSelect.next(item);

        this.isOpen = false;

    }

    public onIconClicked() {

        if (this.hasSelection === true) {

            this.itemSelected(<any>{});

        } else {

            this.isOpen = true;

        }

    }

}
