import { AppComponent } from "@summize/shared/core";
import { WebsocketEventTypes } from "@summize/shared/framework";

import { ToolPanel, ToolPanelComponent, ToolPanelContent } from "./tool-panel.component";

export class BaseToolPanel extends AppComponent implements ToolPanel {

    public contractId: string;

    public linkedContractId: string;

    public parent: ToolPanelComponent;

    public panel: ToolPanelContent;

    public type: string;

    public isChild: boolean;

    public isSideBar: boolean;

    public async init(parent: ToolPanelComponent, panel: ToolPanelContent): Promise<void> {

        this.parent = parent;

        this.panel = panel;

    }

    public async handleEvent(type: WebsocketEventTypes, payload: any): Promise<void> { }

    public joinSocketGroup(name: string): void {

        this.parent.joinSocketGroup(name);

    }

    public async goBack() {

        const previous = this.parent.panels.find(p => p.id === this.parent.previousPanel);

        await this.parent.setSelectedPanel(previous);

    }

    public destroy() {

        this.panel = null;

        this.parent = null;

        this.ngOnDestroy();

    }

}