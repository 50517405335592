import {
    Directive,
    Input,
    HostListener,
    OnInit,
    ElementRef,
    ComponentRef
} from "@angular/core";

import {
    OverlayRef,
    Overlay,
    OverlayPositionBuilder
} from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";

import { TooltipComponent } from './tooltip.component';

@Directive({ selector: "[appTooltip]" })
export class TooltipDirective implements OnInit {

    @Input("appTooltip")
    public content: string;

    @Input('display')
    public display: string;

    private overlayRef: OverlayRef;

    constructor(private overlayPositionBuilder: OverlayPositionBuilder, private elementRef: ElementRef, private overlay: Overlay) { }

    public ngOnInit() {

        const positionStrategy = this.overlayPositionBuilder
            .flexibleConnectedTo(this.elementRef)
            .withPositions([
                {
                    originX: 'center',
                    originY: 'bottom',
                    overlayX: 'center',
                    overlayY: 'top'
                }
            ]);

        this.overlayRef = this.overlay.create({ positionStrategy });

    }

    @HostListener("mouseenter")
    public show() {

        const tooltipPortal = new ComponentPortal(TooltipComponent);

        const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(
            tooltipPortal
        );

        tooltipRef.instance.text = this.content;

        tooltipRef.instance.display = this.display;

    }

    @HostListener("mouseout")
    public hide() {

        this.overlayRef.detach();

    }
}
