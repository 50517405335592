import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTooltipModule } from '@angular/material/tooltip';

import { SmzDatePipe } from '../pipes/date.pipe';
import { CorePipesModule } from '../pipes/pipes.module';
import { IconModule } from '../icon/icon.module';
import { DueDateComponent } from './due-date.component';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        CorePipesModule,
        MatTooltipModule
    ],
    exports: [
        DueDateComponent
    ],
    declarations: [
        DueDateComponent
    ],
    providers: [
        SmzDatePipe,
    ]
})
export class DueDateModule { }