import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CustomFieldValueInlineEditorComponent } from './custom-field-value-inline-editor.component';
import { SelectBoxModule } from '../select-box/select-box.module';
import { IconModule } from '../icon/icon.module';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        FormsModule,
        SelectBoxModule
    ],
    exports: [
        CustomFieldValueInlineEditorComponent
    ],
    declarations: [
        CustomFieldValueInlineEditorComponent
    ]
})
export class CustomFieldValueInlineEditorModule { }

export * from './custom-field-value-inline-editor.component'