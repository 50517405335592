<div class="user-tasks flex-column">
    <div class="header">
        Request Tasks
    </div>

    <div class="sub-header">
        Outstanding tasks for <span class="clickable" [routerLink]="getContractLink()">{{documentName}}</span>
    </div>

    <div class="mt-3 mb-1">
        <app-task-legend></app-task-legend>
    </div>

    <ng-container *ngIf="isLoading === true">
        <app-loading-spinner visible="true" type="pale" [container]="true"></app-loading-spinner>
    </ng-container>

    <div *ngIf="isLoading === false" class="panel-content scroll">
        <div class="tabs-header fade-block">
            <div (click)="selectedStatus = TaskStatus.Pending" [class.selected]="selectedStatus ===  TaskStatus.Pending"
                class="tab selected clickable">
                Pending <span>({{buckets[ TaskStatus.Pending].length}})</span>
            </div>
            <div (click)="selectedStatus =  TaskStatus.Complete"
                [class.selected]="selectedStatus === TaskStatus.Complete" class="tab clickable">
                Complete <span>({{buckets[TaskStatus.Complete].length}})</span>
            </div>
        </div>
        <div class="card-list">
            <div *ngFor="let task of buckets[selectedStatus]" class="mb-3">
                <app-task-card [model]="task" mode="Instance" [showDocumentName]="true"
                    [displayCommands]="false"></app-task-card>
            </div>
        </div>
    </div>


</div>