import { Component, ChangeDetectionStrategy, OnInit, Output, EventEmitter } from '@angular/core';

import { Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { AdobeSignSignatureContext, ESignatureRecipientType, ESignatureRecipientOrigin } from '@summize/shared/framework';
import { AdobeSignRecipientStatus, EmailPattern } from '@summize/shared/core';

@Component({
    selector: 'app-esignature-adobesign-recipients',
    templateUrl: 'esignature-adobesign-recipients.html',
    styleUrl: './esignature-adobesign-recipients.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EsignatureAdobesignRecipientsComponent implements OnInit {

    @Output()
    public modelChanged: EventEmitter<AdobeSignSignatureContext>;

    public model: AdobeSignSignatureContext;

    private emailInputSubject = new Subject<{ recipient: any, isCCRecipient: boolean }>();

    constructor() {

        this.modelChanged = new EventEmitter<AdobeSignSignatureContext>();

    }

    public ngOnInit() {

        this.model = {
            hasSignOrder: false,
            hasCcRecipients: false,
            recipients: [],
            ccRecipients: [],
            isValid: false
        }

        this.model.recipients.push({
            name: '',
            email: '',
            tokenId: '',
            signOrder: 1,
            signatureType: 'email',
            externalStatus: AdobeSignRecipientStatus.NOT_YET_VISIBLE,
            recipientType: ESignatureRecipientType.To,
            recipientOrigin: ESignatureRecipientOrigin.Counterparty
        });

        this.emailInputSubject
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
                switchMap(async ({ recipient, isCCRecipient }) => {

                    this.validateEmail(recipient, isCCRecipient);

                    this.isValid();

                })
            )
            .subscribe();
    }

    public addRecipient() {

        this.model.recipients.push({
            name: '',
            email: '',
            tokenId: '',
            signOrder: 1,
            signatureType: 'email',
            externalStatus: AdobeSignRecipientStatus.NOT_YET_VISIBLE,
            recipientType: ESignatureRecipientType.To,
            recipientOrigin: ESignatureRecipientOrigin.Counterparty
        });

        this.isValid();
    }

    public addCCRecipient() {

        this.model.ccRecipients.push({
            name: '',
            email: '',
            tokenId: '',
            signOrder: 2,
            signatureType: 'email',
            externalStatus: AdobeSignRecipientStatus.NOT_YET_VISIBLE,
            recipientType: ESignatureRecipientType.Cc,
            recipientOrigin: ESignatureRecipientOrigin.Counterparty
        });

        this.isValid();
    }

    public onDrop(event) {

        moveItemInArray(this.model.recipients, event.previousIndex, event.currentIndex);

        this.isValid();

    }

    public onDropCcRecipients(event) {

        moveItemInArray(this.model.ccRecipients, event.previousIndex, event.currentIndex);

        this.isValid();

    }

    public removeRecipient(index: number) {

        this.model.recipients.splice(index, 1);

        this.isValid();
    }

    public removeCCRecipient(index: number) {

        this.model.ccRecipients.splice(index, 1);

        this.isValid();
    }

    public toggleCcRecipients(checked: boolean) {

        this.model.hasCcRecipients = checked;

        if (this.model.ccRecipients.length === 0) {
          
            this.addCCRecipient();

        }

        this.isValid();
    }

    public onKeyUp(recipient: any, isCCRecipient: boolean): void {

        this.emailInputSubject.next({ recipient, isCCRecipient });
    }

    private isValid() {

        this.model.recipients.forEach(element => {

            element.signOrder = this.model.recipients.indexOf(element) + 1;

        });

        this.model.ccRecipients.forEach(element => {

            element.signOrder = this.model.ccRecipients.indexOf(element) + 1;

        });

        const invalidRecipients = this.model.recipients.filter(x => x.emailExists === true || x.emailInvalid === true || x.email.length === 0);

        const invalidccRecipients = this.model.ccRecipients.filter(x => x.emailExists === true || x.emailInvalid === true || x.email.length === 0);

        this.model.isValid = (
            this.model.recipients.length > 0 &&
            invalidRecipients.length === 0 &&
            (
                (this.model.hasCcRecipients === true && invalidccRecipients.length === 0) ||
                (this.model.hasCcRecipients === false)
            ));

        this.modelChanged.emit(this.model);

    }

    private validateEmail(recipient: any, isCCRecipient: boolean) {

        recipient.emailInvalid = !EmailPattern.test(recipient.email);

        const recipientExists = this.model.recipients.filter(x => x.email.toLocaleLowerCase() === recipient.email.toLocaleLowerCase());

        const ccRecipientExists = this.model.ccRecipients.filter(x => x.email.toLocaleLowerCase() === recipient.email.toLocaleLowerCase());

        if ((isCCRecipient === false && recipientExists?.length > 1) ||
            (isCCRecipient === true && ccRecipientExists?.length > 1)) {

            recipient.emailExists = true;

        } else {

            recipient.emailExists = false;
        }

    }

}