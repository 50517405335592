<div class="esignature-adobesign-recipients">

    <div class="header">
        Add recipients
    </div>

    <mat-checkbox [value]="model.hasSignOrder" (change)="model.hasSignOrder = $event.checked"
        [checked]="model.hasSignOrder">
        Recipients must sign in order
    </mat-checkbox>

    <div cdkDropList (cdkDropListDropped)="onDrop($event)" class="recipients-container">

        @for (recipient of model.recipients; track $index) {

        <div class="section">

            <div class="label" [ngClass]="{'sign-order-label': model.hasSignOrder}">
                <div class="field required">

                    @if(model.hasSignOrder) {
                    {{$index + 1}}:
                    }
                    Recipient email
                </div>
            </div>

            <div cdkDrag cdkDragLockAxis="y" class="control">

                @if(model.hasSignOrder === true) {
                <img cdkDragHandle class="drag drag-icon" src="assets/icons/drag.svg">
                }

                <input [(ngModel)]="recipient.email" name="value-{{$index}}" type="text"
                    (keyup)="onKeyUp(recipient, false)" (blur)="onKeyUp(recipient, false)"
                    class="smz-standard-input v2 flex-grow" autocomplete="off">
                <div class="remove-button">
                    <app-icon *ngIf="model.recipients.length > 1 && $index !== 0" (click)="removeRecipient($index)"
                        icon="trash-outline"></app-icon>
                </div>
            </div>
            <div *ngIf="recipient?.emailInvalid === true" class="hint-container">
                <p class="text error">Enter a valid email.</p>
            </div>
            <div *ngIf="recipient?.emailExists === true" class="hint-container">
                <p class="text error">Email already entered.</p>
            </div>

        </div>

        }
    </div>

    <div (click)="addRecipient()" class="inline-button clickable">
        <app-icon icon="add-circle-outline" class="button-text add-button"></app-icon>
        <span>Add another recipient</span>
    </div>

    <!-- CC Recipients -->
    <div class="cc-container">

        <mat-checkbox [value]="model.hasCcRecipients" (change)="toggleCcRecipients($event.checked)"
            [checked]="model.hasCcRecipients">
            Add CC recipients to receive a copy of the agreement
        </mat-checkbox>

        @if(model.hasCcRecipients === true){

        <div cdkDropList (cdkDropListDropped)="onDropCcRecipients($event)">

            @for (recipient of model.ccRecipients; track $index) {

            <div class="section">

                <div class="label">
                    <div class="field required">
                        CC email
                    </div>
                </div>

                <div class="control">

                    <input [(ngModel)]="recipient.email" name="value-{{$index}}" type="text"
                        (keyup)="onKeyUp(recipient, true)" (blur)="onKeyUp(recipient,true)"
                        class="smz-standard-input v2 flex-grow" autocomplete="off">
                    <div class="remove-button">
                        <app-icon *ngIf="model.ccRecipients.length > 1 && $index !== 0" (click)="removeCCRecipient($index)"
                            icon="trash-outline"></app-icon>
                    </div>
                </div>
                <div *ngIf="recipient?.emailInvalid === true" class="hint-container">
                    <p class="text error">Enter a valid email.</p>
                </div>
                <div *ngIf="recipient?.emailExists === true" class="hint-container">
                    <p class="text error">Email already entered.</p>
                </div>

            </div>

            }

            <div (click)="addCCRecipient()" class="flex-row flex-align-vcenter inline-button clickable">
                <app-icon icon="add-circle-outline" class="button-text add-button"></app-icon>
                <span>Add another CC</span>
            </div>

        </div>
        }
    </div>

</div>