<div class="dialog no-scroll smz-flex-area">
    <div class="dialog__content no-scroll">

        <div class="dialogHeader">
            <h1 class="dialog-title">
                {{title}}
            </h1>
            <p class="dialog-text mt-0 fade-block">{{subtitle}}</p>
            <div class="dialog-line"></div>
        </div>

        <app-loading-spinner *ngIf="loading === true" class="spinner" visible="true"></app-loading-spinner>

        <ng-container *ngIf="loading === false">
            <div [@fadeInAnimation]>
                <div class="clause-list mx-auto my-auto scroll">
                    <div class="clause-group fade-block" *ngFor="let group of clauses | keyvalue">
                        <div class="clause-name">
                            {{group.key}}
                        </div>
                        <div class="checkbox-list">
                            <div *ngFor="let docType of group.value" class="checkbox-container">
                                <mat-checkbox class="checkbox" [value]="docType.id" [checked]="docType.checked"
                                    (change)="docType.checked = $event.checked">
                                    <span class="checkbox-text" [title]="docType.displayName">
                                        {{docType.displayName}}
                                    </span>
                                </mat-checkbox>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div class="dialog__footer fade-block">
                <app-button class="back__button" type="tertiary" size="medium" mat-dialog-close>Cancel</app-button>
                <app-button [disabled]="hasSelectedItems === false" type="primary" size="medium"
                    [mat-dialog-close]="selected()">Add selected</app-button>
            </div>
        </ng-container>

    </div>
</div>