import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AdobeSignAccountResponse, AdobeSignEnvironment } from '@summize/shared/framework';
import { BaseTenantService } from './base-tenant.service';

@Injectable({ providedIn: 'root' })
export class AdobeSignService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getAdobeSignEnvironment() {

        const baseUrl = this.getUserBaseUrl(true);

        return await this.http.get<AdobeSignEnvironment>(`${baseUrl}adobesign/environment`).toPromise();

    }

    public async addAdobeSignPersonalAccount(authCode: string) {

        const baseUrl = this.getUserBaseUrl(true);

        const payload = {
            authCode,
            displayName: this.getUser().email
        };

        return await this.http.post<AdobeSignAccountResponse>(`${baseUrl}adobesign/accounts/new`, { ...payload }).toPromise();

    }

    public async deletePersonalAdobeSignIntegration(integrationId: string) {

        const baseUrl = this.getUserBaseUrl(true);

        const results = await this.http.delete<any>(`${baseUrl}adobesign/accounts/${integrationId}`).toPromise();

    }

}