import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';

import { ClauseComment } from '../clause-summary-panel/clause-summary-panel.component';

export interface Playbook {
    id: string;
    name: string;
    isPremium: boolean;
    clauses: PlaybookClause[];
}

export interface PlaybookClause {
    id: string | number;
    name: string;
    standardPositions: PlaybookPosition[] | PremiumPlaybookPosition[];
    positions: PlaybookPosition[] | PremiumPlaybookPosition[]
}

export interface PlaybookPosition {
    insertComment: boolean;
    insertText: boolean;
    clipboard: boolean;
    text: string;
    type: PlaybookPositionType;
}

export interface PremiumPlaybookPosition {
    id: string;
    columns: PremiumPlaybookColumn[];
}

export interface PremiumPlaybookColumn {
    id: string;
    text: string;
    name: string;
    insertComment: boolean;
    insertText: boolean;
    clipboard: boolean;
}

export enum PlaybookPositionType {
    Standard,
    Precedent
}
export interface PlaybookV1 {
    autoAddClauseDate: boolean;
    bulletResults: number;
    category: string;
    clauseId: number;
    clauseName: string;
    dateAdded: Date;
    dateUpdated: Date;
    displayName: string;
    documentTypeId: string;
    documentTypeName: string;
    order: number;
    playbookPositions: PlaybookPositionV1[];
    standard: boolean;
    standardPositionLink: string;
}

export interface PlaybookPositionV1 {
    precedentText: string;
    standardPosition: string;
    updatedAt: Date;
    updatedBy: string;
    updatedByUserId: string;
}

export interface PlaybookV2 {
    clauses: PlaybookV2Clause[];
    departmentId: string;
    description: string;
    id: string;
    importParentId: string;
    importType: number;
    name: string;
    schema: PlaybookV2Schema[];
    tenantId: string;
}

export interface PlaybookV2Clause {
    id: string;
    name: string;
    rows: ClauseV2Row[];
    standard: string;
}

export interface ClauseV2Row {
    id: string;
    [key: string]: string;
}
export interface PlaybookV2Schema {
    clipboard: boolean;
    insertComment: boolean;
    insertText: boolean;
    key: string;
    name: string;
}


@Injectable({ providedIn: 'root' })
export class PlaybookDetailsPanelService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }


    public async getPlaybooksForClause(request: any): Promise<Playbook[]> {

        const url = `${this.getUserBaseUrl(true)}playbook/v1`;

        return await this.http.post(url, request).toPromise() as any;

    }

    public async getPlaybooks(): Promise<Playbook[]> {

        const url = `${this.getUserBaseUrl(true)}playbook?playbookType=2`;

        return await this.http.get(url).toPromise() as any;

    }

    public async addTextToClauseBullet(documentId: string, clauseId: number, clauseIndex: number, clauseName: string, clauseText: string, newParagraph: string): Promise<void> {

        const url = `${this.getUserBaseUrl(true)}documents/${documentId}/clauses`;

        const payload = {
            clauseId: clauseId,
            clauseIndex: clauseIndex,
            clauseName: clauseName,
            clauseText: clauseText,
            newPara: newParagraph
        }

        return await this.http.post(url, payload).toPromise() as any;

    }

    public async addTextToClauseComment(documentId: string, clauseId: number, clauseText: string,): Promise<ClauseComment> {

        const url = `${this.getUserBaseUrl(true)}documents/${documentId}/clauses/${clauseId}/comments`;

        const payload = {
            comment: clauseText
        }

        return await this.http.post<ClauseComment>(url, payload).toPromise();

    }

    public async updateClauseComment(documentId: string, clauseId: number, commentId: string, commentText: string) {

        const url = `${this.getUserBaseUrl(true)}documents/${documentId}/clauses/${clauseId}/comments/${commentId}`;

        return this.http.put(url, { comment: commentText }).toPromise();
    }

    public async getPlaybookById(id: string, isPremium: boolean): Promise<Playbook> {

        if (isPremium === true) {

            const result = await this.getV2Playbook(id);

            return this.mapV2Playbook(result);

        } else {

            const results: PlaybookV1[] = await this.getV1Playbook(id);

            return this.mapV1Playbook(results);
        }

    }

    public async getV1Playbook(id: string): Promise<PlaybookV1[]> {

        const url = `${this.getUserBaseUrl(true)}DocumentTypes/${id}/Playbook`

        return await this.http.get(url).toPromise() as any;
    }

    public async getV2Playbook(id: string): Promise<PlaybookV2> {

        const url = `${this.getUserBaseUrl(true)}Playbook/${id}`

        return await this.http.get(url).toPromise() as any;
    }

    private mapV1Playbook(v1Playbooks: PlaybookV1[]) {

        if (v1Playbooks.length === 0) {
            return {
                id: undefined,
                isPremium: false,
                clauses: [],
                name: undefined
            };
        }

        const playbook: Playbook = {
            id: v1Playbooks[0].documentTypeId,
            isPremium: false,
            name: v1Playbooks[0].documentTypeName,
            clauses: []
        };

        v1Playbooks.forEach(v1Playbook => {

            const positions: PlaybookPosition[] = [];

            const clause: PlaybookClause = {
                id: v1Playbook.clauseId,
                name: v1Playbook.displayName,
                standardPositions: [],
                positions: []
            };

            // Add Standard position first
            const standardPosition: PlaybookPosition[] = [{
                clipboard: true,
                insertComment: true,
                insertText: true,
                text: v1Playbook.playbookPositions[0]?.standardPosition || 'No Standard Position found',
                type: PlaybookPositionType.Standard
            }];

            clause.standardPositions = standardPosition;

            v1Playbook.playbookPositions.forEach(position => {
                positions.push({
                    clipboard: true,
                    insertComment: true,
                    insertText: true,
                    text: position.precedentText || 'No text found',
                    type: PlaybookPositionType.Precedent
                });
            });

            clause.positions = positions;

            playbook.clauses.push(clause);

        });

        return playbook;

    }

    private mapV2Playbook(v2: PlaybookV2) {

        const playbookClauses: PlaybookClause[] = [];

        v2.clauses.forEach(clause => {

            const playbookClause = {
                id: clause.id,
                name: clause.name,
                standardPositions: [],
                positions: []
            };

            // Add standard position
            playbookClause.standardPositions.push({
                clipboard: true,
                insertComment: true,
                insertText: true,
                type: PlaybookPositionType.Standard,
                text: clause.standard || 'No Standard Position found'
            });

            // A Row in premium playbook is a position
            clause.rows?.forEach(row => {

                const pos: PremiumPlaybookPosition = {
                    id: row.id,
                    columns: []
                }

                const keys = Object.keys(row);

                keys.forEach(key => {
                    const schema = v2.schema.find(x => x.key === key);

                    if (schema !== undefined) {

                        pos.columns.push({
                            clipboard: schema.clipboard,
                            insertComment: schema.insertComment,
                            insertText: schema.insertText,
                            text: row[key] || 'No text found',
                            id: key,
                            name: schema.name
                        });
                    }

                });

                playbookClause.positions.push(pos);
            });

            playbookClauses.push(playbookClause);

        })

        const playbook: Playbook = {
            id: v2.id,
            isPremium: true,
            name: v2.name,
            clauses: playbookClauses

        };

        return playbook;

    }
}
