<div class="container">

    <div *ngFor="let chatMessage of chatMessages" class="chat-message"
        [class.usermessage]="chatMessage.source === AssistantChatMessageSource.User"
        [class.assistantmessage]="chatMessage.source === AssistantChatMessageSource.Assistant"
        [class.chatbreak]="chatMessage.source === AssistantChatMessageSource.ChatBreak"
        [class.tokenlimit]="chatMessage.source === AssistantChatMessageSource.TokenLimit">

        <ng-container *ngIf="chatMessage.source === AssistantChatMessageSource.User || chatMessage.source === AssistantChatMessageSource.Assistant">
            <quill-view *ngIf="settings.displayAsHtml !== true" class="viewer" [content]="chatMessage.typingMessage !== undefined ? chatMessage.typingMessage : chatMessage.message" theme="snow"></quill-view>
            <quill-view-html *ngIf="settings.displayAsHtml === true" class="viewer" [content]="chatMessage.typingMessage !== undefined ? chatMessage.typingMessage : chatMessage.message" theme="snow"></quill-view-html>
        </ng-container>

        <app-chat-tokenlimit *ngIf="chatMessage.source === AssistantChatMessageSource.TokenLimit"></app-chat-tokenlimit>

        <app-chat-separator *ngIf="chatMessage.source === AssistantChatMessageSource.ChatBreak" text="New Thread"></app-chat-separator>

    </div>

</div>
