import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';


import { ListItemComponent } from './list-item/list-item.component';
import { ListComponent } from './list.component';
import { AvatarModule } from '../avatar/avatar.module';

@NgModule({
    imports: [
        AvatarModule,
        CommonModule,
        RouterModule
    ],
    exports: [
        ListComponent,
        ListItemComponent
    ],
    declarations: [
        ListComponent,
        ListItemComponent
    ]
})
export class ListModule { }

export * from './list.component';
export * from './list-item/list-item.component';

