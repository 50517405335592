import { FormControl, FormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { distinctUntilChanged, switchMap, debounceTime, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

export interface AutocompleteBoxCallbackModel {
    key: string;
    value: string;
    optionValue?: string;
}

@Component({
    selector: 'app-autocomplete-box-callback',
    templateUrl: 'autocomplete-box-callback.html',
    styleUrls: ['./autocomplete-box-callback.scss']
})
export class AutocompleteBoxCallbackComponent {

    @Input()
    public parentForm: FormGroup;

    @Input()
    public fcn: string;

    @Input()
    public optionsCb: (value: string) => Observable<AutocompleteBoxCallbackModel[]>;

    @Input()
    public value: AutocompleteBoxCallbackModel;

    @Input()
    public placeholder = 'Search...';

    @Input()
    public grouped = false;

    @Input()
    public isRequired = false;

    @Input()
    public debounceTime: number = 250;

    @Output()
    public changed: EventEmitter<AutocompleteBoxCallbackModel>;

    public myControl = new FormControl();

    public options: Observable<AutocompleteBoxCallbackModel[]>;

    constructor() {

        this.changed = new EventEmitter();

    }

    public ngOnInit() {
        this.myControl.setValue(this.value);

        this.options = this.myControl.valueChanges
            .pipe(
                startWith(''),
                distinctUntilChanged(),
                debounceTime(this.debounceTime),
                filter(value => !(value?.key || value === this.value)),
                switchMap(value => this.optionsCb(value))
            );
    }

    public valueChanged(value: any): void {
        this.value = value.option.value;
        this.changed.emit(this.value);
    }

    public displayFn(option: AutocompleteBoxCallbackModel): string {
        this.value = option;
        return this.value?.optionValue || this.value?.value || '';
    }

    public clear() {
        this.value = undefined;
        this.myControl.reset();
        this.changed.emit(undefined);
    }

}
