import { Injectable } from '@angular/core';

import { OutlookService } from './platform/outlook.service';

@Injectable({ providedIn: 'root' })
export class DownloadHelper {

    constructor(private outlook: OutlookService) { }

    public downloadBlob(location, name, blob: any): void {

        if (this.outlook.isOfficeDesktopClient() === true) {

            return this.outlook.downloadBlob(location);

        }

        const url = window.URL.createObjectURL(blob);

        const a: any = document.createElement('a');

        a.style.display = 'none';

        a.href = url;

        a.download = name;

        document.body.appendChild(a);

        a.click();

        window.URL.revokeObjectURL(url);

    }

}