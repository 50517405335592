import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { IconModule } from '../icon/icon.module';
import { EsignatureAdobesignRecipientsComponent } from './esignature-adobesign-recipients.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DragDropModule,
        MatCheckboxModule,
        IconModule
    ],
    exports: [
        EsignatureAdobesignRecipientsComponent
    ],
    declarations: [
        EsignatureAdobesignRecipientsComponent
    ]
})
export class EsignatureAdobesignRecipientsModule { }

export * from './esignature-adobesign-recipients.component'