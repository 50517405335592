import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';

export class AssignableUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;

    public get fullName(): string {
        return this.firstName + ' ' + this.lastName;
    }
}

export class ContractResponse {
    isShared: boolean;
    documentId: string;
    documentName: string;
    assignedTo: string;
    assignedToName: string;
    assignedBy: string;
    assignedByName: string;
    assignedAt: string;
}

@Injectable({
    providedIn: 'root'
})
export class AssignedToService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public assignDocuments(userId: string, documentIds: string[]): Promise<ContractResponse[]> {

        const url = `${this.getUserBaseUrl(true)}assignedto/users/${userId}`;
        const payload = { DocumentIds: documentIds };
        return this.http.put<ContractResponse[]>(url, payload).toPromise();

    }

    public unassignDocument(documentId: string): Promise<ContractResponse> {

        const url = `${this.getUserBaseUrl(true)}assignedto/documents/${documentId}`;
        return this.http.delete<ContractResponse>(url).toPromise();

    }

    public unassignDocuments(documentIds: Array<string>): Promise<ContractResponse> {

        const url = `${this.getUserBaseUrl(true)}assignedto/documents/unassign`;
        return this.http.post<ContractResponse>(url, { documentIds }).toPromise();

    }

    public getAssignableUsers(clientId: string, matterId: string, searchValue?: string): Promise<AssignableUser[]> {

        // Admin = 1, Full = 2, Restricted = 3
        const assignableUserTypes = [1, 2, 3].join(',');

        let url = `${this.getUserBaseUrl(true)}clients/${clientId}/matters/${matterId}/users?userTypes=${assignableUserTypes}`;

        if (searchValue) {
            url += `&name=${searchValue}`;
        }

        return this.http
            .get<AssignableUser[]>(url)
            .toPromise()
            .then(response => {
                return response.map(u => {
                    // Convert the interface (POJO) to a instance
                    let assignableUser = new AssignableUser();
                    Object.keys(u).forEach(k => assignableUser[k] = u[k]);
                    return assignableUser;
                })
            });

    }
}
