import { Injectable } from '@angular/core';

import { SummizeStorage } from './summize-storage';

export const PreviewFeature = {
    Contracts: 'Contracts'
};

@Injectable({ providedIn: 'root' })
export class PreviewFeatureService {

    constructor() { }

    public hasPreviewFeatureEnabled(feature): boolean {

        const val = SummizeStorage.getLocalItem(`SMZPRV:${feature}`);

        if (val === undefined || val === null || val === '') {

            return false;

        }

        return val.toString() === 'true';

    }

    public setFeatureStatus(feature: string, active: boolean): void {

        SummizeStorage.setLocalItem(`SMZPRV:${feature}`, active.toString());

    }

}