import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { BaseTenantService } from "@summize/shared/core";
import { EventService } from "@summize/shared/framework";

const CalendarEventChanged = 'CalendarEventChanged';

@Injectable({ providedIn: 'root' })
export class CalendarEventsEvergreenService extends BaseTenantService {

    constructor(private http: HttpClient, private events: EventService) { super(); }

    public async update(eventId: string, isEvergreen: boolean): Promise<any> {

        const payload = {
            isEvergreen: isEvergreen
        };

        const baseUrl = this.getUserBaseUrl(true);

        try {

            await this.asPromise(this.http.put(`${baseUrl}calendar-events/${eventId}/evergreen`, payload));

            this.events.despatch(CalendarEventChanged, payload);

            this.events.actionComplete('Calendar event updated.');

        } catch (error) {

            this.events.actionFailed('Error updating calendar event.');

        }

    }
}