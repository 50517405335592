import { Component, OnInit } from '@angular/core';

import { ToastItem, ToastFeedService } from './toast-feed.service';

@Component({
    selector: 'app-toast-container',
    templateUrl: './toast-container.html',
    styleUrls: ['./toast-container.scss']
})
export class ToastContainerComponent implements OnInit {

    public toasts: Array<ToastItem>;

    constructor(private service: ToastFeedService) { }

    ngOnInit() {

        this.service.$change.subscribe(changes => {
            this.toasts = changes
        });

    }

}