import { AfterViewInit, Component, Injector, OnInit, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TableDatasource } from '@summize/shared/components';
import { AppComponent } from '@summize/shared/core';
import { TableProvider } from './tables/table-provider';

import { TABLES } from './tables/tables';

@Component({
    templateUrl: 'table-view.html',
    styleUrls: ['./table-view.scss']
})
export class TableViewComponent extends AppComponent implements OnInit, AfterViewInit {

    @ViewChildren(TemplateRef)
    public templates!: QueryList<TemplateRef<any>>;

    public datasource!: TableDatasource;

    public tableId!: string;

    public range!: string;

    private tableProvider!: TableProvider;

    constructor(private injector: Injector, private route: ActivatedRoute) {

        super();

    }

    public ngOnInit(): void {

        this.route.params.subscribe((params: any) => {

            this.tableId = params.tableId;

            this.range = params.range;

        });

    }

    public goBack() {

        window.history.back();

    }

    public getPrimaryText(): string {

        return this.tableProvider === undefined ? '' : this.tableProvider.getPrimary();

    }

    public getSecondaryText(): string {

        return this.tableProvider === undefined ? '' : this.tableProvider.getSecondary(this.range);

    }


    public ngAfterViewInit(): void {

        const imp = (TABLES as any)[this.tableId] as TableProvider;

        if (imp === undefined) {

            return;
        }

        this.tableProvider = new (imp as any)(this.injector);

        const base = this.tableProvider.buildDataSource(this.templates);

        const merge: any = <any>Object.assign(<any>base, {
            queryProvider: () => '&full=true',
            source: {
                url: `1.0/analytics/table/${this.tableId}/${this.range}`,
                resultsField: 'results',
                countField: 'totalResultsCount'
            },
            allowSelection: false,
        });

        this.datasource = merge;

    };

}