import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomFieldsRoutingModule } from './custom-fields-routing.module';

@NgModule({
    imports: [
        CommonModule,
        CustomFieldsRoutingModule
    ],
    exports: [],
    declarations: [],
    providers: [],
})
export class CustomFieldsModule { }

export * from './types';