import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContextBarComponent } from './context-bar.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
    imports: [
        CommonModule,
        IconModule
    ],
    exports: [
        ContextBarComponent
    ],
    declarations: [
        ContextBarComponent
    ]
})
export class ContextBarModule { }

export * from './context-bar.component'