import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DialogShellModule, LoadingSpinnerModule } from '@summize/shared/components-v2';
import { SelectBoxModule } from '@summize/shared/components';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { AddGraphComponent } from './add-graph.component';
import { AddGraphService } from './add-graph.service';

@NgModule({
    imports: [
        CommonModule,
        DialogShellModule,
        LoadingSpinnerModule,
        SelectBoxModule,
        MatCheckboxModule,
        FormsModule
    ],
    exports: [
        AddGraphComponent
    ],
    declarations: [
        AddGraphComponent
    ],
    providers: [
        AddGraphService
    ]
})
export class AddGraphModule { }

export * from './add-graph.component'