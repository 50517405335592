import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService, DOCX_EXTENSION, IsValidString, PDF_EXTENSION } from '@summize/shared/core';
import { PlatformAttachmentType } from '@summize/shared/framework';

import { PlatformAttachments, PlatformSuggestion } from './platform.types';

@Injectable({ providedIn: 'root' })
export class HubspotService extends BaseTenantService {

    public get fileText() {

        return 'Files from Hubspot';

    }

    constructor(private http: HttpClient) {

        super();

    }

    public async getAttachmentState(): Promise<PlatformAttachments> {

        const params = new URLSearchParams(window.location.search);

        const connectorRef = params.get('connectorRef');

        const connectorObjectType = params.get('objectType');

        if (IsValidString(connectorRef) === true && IsValidString(connectorObjectType) === true) {

            const url = `${this.getUserBaseUrl(true)}platform/hubspot/${connectorObjectType}/${connectorRef}/attachments`;

            const results = await this.asPromise(this.http.get(url))

            if (results !== undefined && results !== null) {

                const items = results.files.map(d => {

                    // SteL - Somethings use name or filename
                    const name = `${d.name.toLowerCase()}.${d.extension.toLowerCase()}`;

                    return {
                        ...d,
                        objectId: connectorRef,
                        objectType: connectorObjectType,
                        name: name,
                        fileName: name,
                        smzType: PlatformAttachmentType.Hubspot
                    };

                });

                return {
                    emailAttachments: items.filter(x => x.name.endsWith(PDF_EXTENSION) || x.name.endsWith(DOCX_EXTENSION)),
                    allAttachments: items,
                    context: {}
                };

            }

        } else {

            return Promise.resolve({
                emailAttachments: undefined,
                allAttachments: undefined,
                context: undefined
            });

        }

    }

    public handleEvent(): void { }
    public showRequestModal(): void { }
    public isComposeMode(): boolean { return false }
    public addFileAttachment() { }
    public openInBrowser() { }
    public getPlatformSuggestions(): Promise<Array<PlatformSuggestion>> { return Promise.resolve(undefined) }
    public getEmailId(): string { return undefined; }
    public contextChanged(): void { }
    public async getEmailAsAttachment(): Promise<any> { return undefined; }
    public async ready() { return Promise.resolve() };

}