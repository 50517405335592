import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { BaseTenantService } from "@summize/shared/core";
import { DateTimeFilterPresetEnum, WidgetTimeFormat } from '@summize/shared/framework';

@Injectable({ providedIn: 'root' })
export class ChartWidgetService extends BaseTenantService {

    constructor(private http: HttpClient) { super() }

    public async getData(datasource: string, dateFrom: Date, dateTo: Date, dateFilterPreset: DateTimeFilterPresetEnum,
        filters?: any, timeFormat?: WidgetTimeFormat): Promise<any> {

        const payload = {
            filters: filters,
            dateFrom: dateFrom !== null ? dateFrom : undefined,
            dateTo: dateTo !== null ? dateTo : undefined,
            dateFilterPreset: dateFilterPreset !== null ? dateFilterPreset : undefined,
            timeFormat: timeFormat
        };

        const request: any = await this.http
            .post(`${this.getUserBaseUrl(true)}${datasource}`, payload)
            .toPromise();

        return request;
    }
}