import { UserTypeEnum } from './constants';

export class UserTypeHelper {
    public static getDisplayName = (userType: number) => {
        let type = 'Empty';

        switch (userType) {
            case UserTypeEnum.Empty:
                type = 'Empty';
                break;
            case UserTypeEnum.All:
                type = 'Admin';
                break;
            case UserTypeEnum.Full:
                type = 'Full';
                break;
            case UserTypeEnum.Restricted:
                type = 'Business';
                break;
            case UserTypeEnum.ReadOnly:
                type = 'Read Only';
                break;
            case UserTypeEnum.External:
                type = 'External';
                break;
            case UserTypeEnum.Share:
                type = 'Shared';
                break;
            case UserTypeEnum.Requestor:
                type = 'Requestor';
                break;
            case 99:
                type = 'Dynamic';
                break;
            default:
                break;
        }

        return type;
    };

    public static getOptions(isReadOnlyAllowed: boolean, isRequestorEnabled: boolean): Array<{ value: UserTypeEnum, label: string; }> {
        const options = [
            {
                value: UserTypeEnum.All, label: UserTypeHelper.getDisplayName(UserTypeEnum.All)
            },
            {
                value: UserTypeEnum.Full, label: UserTypeHelper.getDisplayName(UserTypeEnum.Full)
            },
            {
                value: UserTypeEnum.Restricted, label: UserTypeHelper.getDisplayName(UserTypeEnum.Restricted)
            }
        ];

        if (isReadOnlyAllowed) {
            options.push({
                value: UserTypeEnum.ReadOnly, label: UserTypeHelper.getDisplayName(UserTypeEnum.ReadOnly)
            });
        }

        if (isRequestorEnabled) {
            options.push({
                value: UserTypeEnum.Requestor, label: UserTypeHelper.getDisplayName(UserTypeEnum.Requestor)
            });
        }

        return options;
    }
}

