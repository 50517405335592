export interface ExportSettings {
    ExportTypeValues: ExportTypeValues;
    ExportAllData: boolean;
    IncludeKeyDates: boolean;
    IncludeFlagged: boolean;
    IsSingleRowPerDocument: boolean;
}

export enum ExportFormat {
    Word = 1,
    Excel = 3,
}

export enum ExportType {
    Unknown = 1,
    Table = 2,
    ContractReview = 3,
}

export enum ExportTypeValues {
    Table = 0,
    Task = 1,
};