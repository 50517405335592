<div class="document-filter">

    <app-block-button (click)="isOpen = !isOpen" (onIconClick)="onIconClicked()" #trigger="cdkOverlayOrigin"
        cdkOverlayOrigin [hasIconClickEvent]="true" [disabled]="disabled"
        [icon]="hasSelection === false ?'chevron-down-outline':'close-outline'" [display]="display"
        [type]="hasSelection === false ? 'filter':'filter-selected'" width="140"></app-block-button>

    <ng-template cdkConnectedOverlay (overlayOutsideClick)="isOpen = false" (attach)="init()"
        [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen" [cdkConnectedOverlayHasBackdrop]="true"
        cdkConnectedOverlayBackdropClass="transparent-backdrop">
        <div class="document-filter-host">

            <ng-container *ngIf="type === 'select-group'">
                <div class="select-container scroll">
                    <div class="search">
                        <app-search-bar placeholder="Search ..." (onSearch)="query = $event"></app-search-bar>
                    </div>

                    <div *ngFor="let group of items" class="items">
                        <ng-container *ngIf="group.items?.length > 0">

                            <div class="item-title mb-3">{{group.title}}</div>
                            <div (click)="itemSelected(item)" *ngFor="let item of group.items | search: query;"
                                [class.selected]="item.id === selected" class="item clickable {{item.class}}"
                                [matTooltip]="item.tooltip">
                                <div *ngIf="item.indicators !== undefined" class="indicators">

                                    <ng-container *ngFor="let i of item.indicators">
                                        <div class="indicator" [style.background-color]="i">
                                            &nbsp;
                                        </div>
                                    </ng-container>
                                </div>

                                <app-icon *ngIf="item.icon !== undefined" [icon]="item.icon"></app-icon>
                                {{item.name}}
                                <div *ngIf="item.description" [class.selected]="item.id === selected"
                                    class="item-description">{{item.description}}</div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="type === 'select'">
                <div class="select-container">
                    <div class="search">
                        <app-search-bar placeholder="Search ..." (onSearch)="query = $event"></app-search-bar>
                    </div>

                    <div class="items">
                        <div (click)="itemSelected(item)" *ngFor="let item of items | search: query;"
                            [class.selected]="item.id === selected" class="item clickable {{item.class}}"
                            [matTooltip]="item.tooltip">
                            <app-icon *ngIf="item.icon !== undefined" [icon]="item.icon"></app-icon> {{item.name}}
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="type === 'date'">
                <div class="date-container">

                    <div class="date">
                        <div class="title">
                            From:
                        </div>
                        <input class="smz-standard-input" type="date" [(ngModel)]="selectedFrom" />
                    </div>

                    <div class="date">
                        <div class="title">
                            To:
                        </div>
                        <input class="smz-standard-input" type="date" [(ngModel)]="selectedTo" />
                    </div>

                </div>

                <div class="commands">
                    <app-block-button (click)="rangeSelected()" class="ml-3" display="Apply" width="100">
                    </app-block-button>
                </div>
            </ng-container>

        </div>
    </ng-template>

</div>