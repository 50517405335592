<div [class.no-records]="cleanFields === undefined || cleanFields.length === 0" class="custom-fields">

    <ng-container *ngIf="isLoading === true">
        <app-loading-spinner visible="true" [container]="true"></app-loading-spinner>
    </ng-container>

    <ng-container *ngIf="isLoading === false">
        <app-context-bar [template]="template" [hasBackButton]="false" type="v2" primaryText="Custom Fields">
            <ng-template #template>
                <div class=" template-area">
                    <app-block-button *ngIf="canAddCustomFields === true" (click)="addCustomField()"
                        display="Custom Field" icon="add-circle-outline" width="175" type="v2-primary">
                    </app-block-button>
                </div>
            </ng-template>
        </app-context-bar>

        <div class="view-host no-scroll flex-row">
            <ng-container *ngIf="cleanFields !== undefined && cleanFields.length > 0">
                <div class="panel fields scroll">
                    <div class="header flex-row flex-space-between">
                        <div class="title">
                            Name
                        </div>
                        <div class="select-area flex-row">
                            <app-select-box placeholder="Assigned to" (changed)="selectedTypeChanged($event)"
                                [options]="contractTypes" [disabled]="isEdit === true" [value]="selectedType"
                                [canSearch]="false"></app-select-box>
                            <div *ngIf="selectedType !== undefined" (click)="selectedTypeChanged(undefined)"
                                class="clear clickable">
                                <app-icon icon="close-circle-outline"></app-icon>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="fields !== undefined && fields.length > 0">
                        <div *ngFor="let field of fields" [class.selected]="selected?.id === field.id"
                            class="custom-field clickable flex-row flex-space-between">
                            <div (click)="setSelected(field)" class="item">
                                {{field.name}}
                                <span class="field-type">({{getTypeDisplay(field)}})</span>
                            </div>
                            <div *ngIf="canDeleteCustomFields === true" (click)="removeSelected(field)"
                                class="insight-actions edit clickable">
                                <div class="controls">
                                    <app-icon icon="trash-outline"></app-icon>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="fields === undefined || fields.length === 0">
                        <div [@fadeInAnimation] class="landing">
                            <img class="fade-block" src="/assets/images/clause-manager.svg">
                            <div class="fade-block">No results</div>
                        </div>
                    </ng-container>
                </div>
                <ng-container *ngIf="selected === undefined">
                    <div class="panel">
                        <div [@fadeInAnimation] class="landing">
                            <img class="fade-block" src="/assets/images/clause-manager.svg">
                            <div class="fade-block">Select a Custom Field</div>
                        </div>
                    </div>
                </ng-container>
                <div *ngIf="selected !== undefined" class="panel definition">
                    <div class="header flex-row flex-space-between">
                        <div class="title">
                            {{selected.name}}
                        </div>
                        <div class="commands flex-row">
                            <app-block-button *ngIf="canEditCustomFields === true" (click)="editSelected()"
                                display="Edit settings" icon="create-outline" width="155" type="v2-blue">
                            </app-block-button>
                        </div>
                    </div>
                    <div class="field-value">
                        <div class="name">
                            Field name:
                        </div>
                        <div class="value">
                            {{selected.name}}
                        </div>
                    </div>
                    <div class="field-value">
                        <div class="name">
                            Field description:
                        </div>
                        <div class="value">
                            {{selected.description}}
                        </div>
                    </div>
                    <div class="field-value">
                        <div class="name">
                            Assigned to contract types:
                        </div>
                        <div class="value">
                            {{selected.targetContractTypesDisplay}}
                        </div>
                    </div>
                    <div class="field-value">
                        <div class="name">
                            Field type:
                        </div>
                        <div class="value">
                            {{selected.fieldTypeDisplay}}
                        </div>
                    </div>
                    <div *ngIf="selected.fieldType === CustomFieldType.Dropdown || selected.fieldType === CustomFieldType.MultiSelect"
                        class="field-value">
                        <div class="name">
                            Field options:
                        </div>
                        <div class="value">
                            {{selected.valueDisplay || selected.values}}
                        </div>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="cleanFields === undefined || cleanFields.length === 0">
                <div [@fadeInAnimation] class="landing no-results">
                    <img class="fade-block" src="/assets/images/clause-manager.svg">
                    <div class="fade-block title">You have no Custom Fields</div>
                    <div class="fade-block">Click below to add a Custom Field or visit our <a
                            href="https://customers.summize.com/hub" target="_blank">Customer Hub</a> to learn more
                        about how Custom Fields work.</div>
                    <app-block-button *ngIf="canAddCustomFields === true" (click)="addCustomField()"
                        display="Custom Field" icon="add-circle-outline" width="175" type="v2-primary">
                    </app-block-button>
                </div>

            </ng-container>
        </div>
    </ng-container>

</div>