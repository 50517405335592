import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AsyncActivityCountService {

    constructor(private http: HttpClient) { }

    public async get(endpoint: string, tenantId: string, user: string): Promise<any> {

        const query =
            tenantId === undefined ?
                `customEvents | where customDimensions['UserId'] == '${user}' or customDimensions["userId"] == '${user}' | count` :
                `customEvents | where customDimensions['TenantId'] == '${tenantId}' or customDimensions["tenantId"] == '${tenantId}' | count`;

        const data: any = await this.http.get(`${endpoint}/admin/api/1.0/app-insights/query?timespan=P7D&execute=${query}`).toPromise();

        return data.results.tables[0].rows[0];
    }

}