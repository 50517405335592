export function getLocale() {

  const getBrowserLanguage = () => {
    if (window?.navigator?.languages !== undefined) {
      return navigator.languages[0];
    }
    return window?.navigator?.language;
  }

  const supportedLocales = ['en-GB', 'en-US'];
  const defaultLocale = 'en-GB';

  const browserLocale = getBrowserLanguage();
  if (browserLocale === null || browserLocale === undefined || browserLocale.length === 0) {
    return defaultLocale;
  }

  if (!supportedLocales.includes(browserLocale)) {
    return defaultLocale;
  }

  return browserLocale;

}

export function getTimezoneOffset() {

  const date = new Date();
  const offset = date.getTimezoneOffset();

  return '' + offset ?? '0';

}

export function getTimezoneName() {

  const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  if (timezone !== undefined && timezone !== null) {
    return timezone;
  }

  return '';

}
