import { EventEmitter, Inject, Injectable } from "@angular/core";

import { BaseTenantService } from "@summize/shared/core";
import { Environment, EventService, PlatformAttachmentType, SummizeStorage } from "@summize/shared/framework";

import { ExternalContextChangedEvent, PlatformAttachments, PlatformContextChanged, PlatformSuggestion, PlatformType } from "./platform.types";
import { PlatformService } from "./platform.service";

@Injectable()
export class GmailService extends BaseTenantService {

    public static Events = {
        AppReady: 'AppReady',
        PackageAuth: 'package_auth',
        BeginAuth: 'BeginAuth',
        AuthComplete: 'AuthComplete',
        Logout: 'Logout',
        AttachmentsChanged: 'AttachmentsChanged',
        ViewDocument: 'ViewDocument',
        Event: 'Event',
        PlatformContextChanged: 'PlatformContextChanged'
    };

    public onContextChange: EventEmitter<ExternalContextChangedEvent>;

    public static Commands = {
        GetAttachments: 'GetAttachments'
    };


    public get fileText() {

        return 'Files from email';

    }

    private initComplete: boolean = false;

    private activeDialog;

    private bridgeAttachments: PlatformAttachments;

    constructor(private events: EventService, @Inject(Environment) private environment) {

        super();

        this.onContextChange = new EventEmitter<ExternalContextChangedEvent>();

    }

    public async ready() {

        await this.setLocalStorageItem(
            'session',
            JSON.parse(SummizeStorage.getLocalItem('user'))
        );

    }

    public init() {

        if (this.initComplete === false) {

            this.whenEvent(GmailService.Events.Event, (message) => {

                this.events.despatch(message.data.type);

            });

            this.whenEvent(GmailService.Events.ViewDocument, (message) => {

                this.showRequestModal(message.data.documentId);

            });

            this.events.when(PlatformContextChanged).subscribe(() => {

                this.reloadRequestModal();

            });

            this.initComplete = true;

        }

    }

    public async showRequestModal(requestId: string) {

        const chrome = this.getChrome();

        if (chrome === undefined) {

            return;

        }

        if (this.activeDialog !== undefined) {

            chrome.windows.remove(this.activeDialog.id);

            this.activeDialog = undefined;

        }

        const attachments = await this.getAttachmentState();

        const params = [
            'fullscreen=true',
            'intent=view-request',
            'connector=5',
            `connectorUserId=${encodeURIComponent(this.getUser().email)}`,
            `contractId=${requestId}`,
            `token=${SummizeStorage.getLocalItem('token')}`,
            `host=gmail`,
        ];

        const withAttachments = [
            ...params,
            `attachments=${btoa(JSON.stringify(attachments))}`
        ];

        const url = `${this.environment.appUrl}/remote?${withAttachments.join('&')}`;

        const args = {
            url: url,
            type: 'popup',
            width: 900,
            height: 800,
            setSelfAsOpener: true,
            focused: true
        };

        chrome.windows.create(args).then(created => {

            this.activeDialog = created;

            this.activeDialog.params = params;

        });

    }

    public async showLogoutModal(url: string) {

        const chrome = this.getChrome();

        if (chrome === undefined) {

            return;

        }

        return new Promise(resolve => {

            if (this.activeDialog !== undefined) {

                chrome.windows.remove(this.activeDialog.id);

                this.activeDialog = undefined;

            }

            const args = {
                url: url,
                type: 'popup',
                width: 900,
                height: 800,
                setSelfAsOpener: true,
                focused: true
            };

            chrome.windows.create(args).then((dialog) => {

                setTimeout(() => {

                    chrome.windows.remove(dialog.id);

                    resolve(true);

                }, 2500);


            });

        });

    }

    public async getAttachmentState(): Promise<PlatformAttachments> {

        if (this.bridgeAttachments !== undefined) {

            const attachments = this.bridgeAttachments;

            if (PlatformService.Platform === PlatformType.GmailBridge) {

                attachments.context.subject = undefined;

            }

            return attachments;

        }

        const result = await this.executeWithinGmail('Attachments');

        if (result === undefined || result.data === undefined) {

            return {
                emailAttachments: undefined,
                allAttachments: undefined,
                context: {}
            };

        }

        const items = result.data
            .map(d => ({ ...d, fileName: d.name.toLowerCase(), smzType: PlatformAttachmentType.Gmail }));

        const context = await this.getCurrentItem();

        return {
            emailAttachments: items.filter(x => x.fileName.endsWith('.pdf') || x.fileName.endsWith('.docx')),
            allAttachments: items,
            context: {
                subject: context?.subject
            }
        };

    }

    public async getEmailAsAttachment(): Promise<any> {

        const result = await this.executeWithinGmail('EML');

        return result?.data;

    }

    public handleEvent() {

        //Gmail has no events to handle

    }

    public isComposeMode(): boolean {

        //Gmail doesnt publish anything to say if in compose or not
        return true;

    }

    public async addFileAttachment(url: string, filename: string) {

        await this.executeWithinGmail('AddAttachments', { url, filename });

    }

    public openInBrowser(url: string) {

        window.open(url);

    }

    public async getPlatformSuggestions(): Promise<Array<PlatformSuggestion>> {

        const current = await this.getCurrentItem();

        if (current !== undefined) {

            return [
                { name: 'summary', key: 'summary', value: current.subject },
                { name: 'id', key: 'id', value: current.id },
            ];

        }

        return [];

    }

    public async getEmailId(): Promise<string> {

        const current = await this.getCurrentItem();

        return current?.id;

    }

    public async contextChanged(): Promise<void> {

        const current = await this.getCurrentItem();

        this.onContextChange.next({ name: current?.subject });

    }

    public async getAttachmentItems() {

        const result = await this.getLocalStorageItem('attachments');

        return result;

    }

    public async executeWithinGmail(command: string, payload: any = undefined): Promise<{ data: any }> {

        return new Promise((resolve, reject) => {

            const chrome = this.getChrome();

            if (chrome === undefined) {

                return reject('Chrome API not available');

            } else {

                chrome.runtime.sendMessage({ type: `Content++${command}`, data: payload }, result => {

                    return resolve(result)

                });
            }

        });

    }

    public async sendEvent(event: string, payload: any = undefined) {

        return new Promise((resolve, reject) => {

            const chrome = this.getChrome();

            if (chrome === undefined) {

                reject('Chrome API not available');

            } else {

                chrome.runtime.sendMessage({ type: event, data: payload }, result => resolve(result));;

            }

        });

    }

    public async whenEvent(event: string, onComplete) {

        const chrome = this.getChrome();

        if (chrome === undefined) {

            return;

        } else {

            chrome.runtime.onMessage.addListener(async (message, sender, sendResponse) => {

                if (message.type === event) {

                    const result = await onComplete(message, sender)

                    if (result !== undefined) {

                        sendResponse({ success: true, data: result });

                    }
                    else {

                        sendResponse({ success: false });

                    }

                }

            })

        }

    }

    private async getCurrentItem() {

        return await this.getLocalStorageItem('currentItem');

    }

    public async getLocalStorageItem(key: string): Promise<any> {

        const result = await this.getChrome().storage.local.get([key]);

        return result === undefined ? undefined : result[key];

    }

    public async setLocalStorageItem(key: string, val: any): Promise<any> {

        const o = {};

        o[key] = val;

        await this.getChrome().storage.local.set(o);

    }

    public storeAttachmentFromBridge(attachments: PlatformAttachments) {

        this.bridgeAttachments = attachments;

    }

    private async reloadRequestModal() {

        const chrome = this.getChrome();

        if (chrome === undefined) {

            return;

        }

        if (this.activeDialog !== undefined) {

            chrome.windows.get(this.activeDialog.id, { populate: false }, async (info) => {

                // Only reload the window if the previous window is still active;

                if (info === undefined) {

                    // Window has been closed manually by the user
                    this.activeDialog = undefined;

                }

                if (info !== undefined) {

                    chrome.windows.remove(this.activeDialog.id);

                    const attachments = await this.getAttachmentState();

                    const params = this.activeDialog.params;

                    const withAttachments = [
                        ...params,
                        `attachments=${btoa(JSON.stringify(attachments))}`
                    ];

                    const url = `${this.environment.appUrl}/remote?${withAttachments.join('&')}`;

                    const args = {
                        url: url,
                        type: 'popup',
                        width: 900,
                        height: 800,
                        setSelfAsOpener: true,
                        focused: true
                    };

                    chrome.windows.create(args).then(created => {

                        this.activeDialog = created;

                        this.activeDialog.params = params;

                    });

                }

            })



        }

    }

    private getChrome() {

        const w = (<any>window);

        return w.chrome;

    }

}