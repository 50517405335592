<app-dialog-shell title="Configure Graph" (onClose)="close()" (onSave)="save()" [showFooter]="true">
    <div class=" edit-graph">
        <div class="section-area">
            <div class="text">
                Graph name:
            </div>
            <div class="section-content">
                <input class="smz-standard-input" [(ngModel)]="definition.settings.name" />
            </div>
        </div>

        <div class="section-area">
            <div class="text">
                Graph size:
            </div>
            <div class="section-content">
                <app-select-box (changed)="definition.settings.size = $event" [value]="definition.settings.size"
                    [options]="sizes">
                </app-select-box>
            </div>
        </div>

        <ng-container *ngIf="definition.type === SpecialTypes.DURATION">
            <div class="section-area">
                <div class="text">
                    Display duration in:
                </div>
                <div class="section-content">
                    <app-select-box (changed)="definition.settings.mask = $event" [value]="definition.settings.mask"
                        [options]="durationMask">
                    </app-select-box>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="definition.type === SpecialTypes.CURRENCY">
            <div class="section-area">
                <div class="text">
                    Display Currency in:
                </div>
                <div class="section-content">
                    <app-select-box (changed)="definition.settings.mask = $event" [value]="definition.settings.mask"
                        [options]="currencyMask">
                    </app-select-box>
                </div>
            </div>
        </ng-container>

    </div>
</app-dialog-shell>