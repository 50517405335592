<ng-container *ngIf="isLoading === true">
    <app-loading-spinner visible="true" type="pale"></app-loading-spinner>
</ng-container>

<div *ngIf="isLoading === false" [class.legacy-style]="useLegacyStyle === true"
    [class.settings-host]="useLegacyStyle === false">

    <h2 class="settings-header weight-light">DocuSign Auto-Send</h2>

    <p class="settings-description">
        DocuSign Auto-Send is enabled for this template because DocuSign Fields were detected in the Word file.
        Configure the settings below to control envelope, recipient and storage settings.
    </p>

    <section class="settings-section">

        <h3 class="settings-header weight-light">Envelope Settings</h3>
        <p class="settings-description mb-3">
            Control which users can access this template for submitting new requests.
        </p>

        <div class="mb-3">
            <label class="input-label required">Select which DocuSign shared account to use for authentication</label>
            <div class="control">
                <app-select-box [options]="integrationOptions" [canSearch]="false" [isRequired]="true"
                    placeholder="Select" (changed)="onIntegrationChanged($event)" [value]="model.integrationId"
                    [errorState]="displayErrors && validationState.integrationId === false" />
            </div>
            <small class="helper-text">Envelopes will be created under and sent on behalf of this account.</small>
        </div>

        <div class="mb-3">
            <label class="input-label required">
                Select which signature type to use
            </label>
            <div class="control">
                <app-select-box [options]="signatureTypeOptions" [canSearch]="false" [isRequired]="true"
                    placeholder="Select" (changed)="onSignatureTypeChanged($event)" [value]="model.signatureType"
                    [errorState]="displayErrors && validationState.signatureType === false" />
            </div>
            <small class="helper-text">All recipients will be sent envelopes using the same signature type.</small>
        </div>

        <div class="mb-3">
            <label class="input-label required">
                Select the recipient signing order
            </label>
            <div class="control">
                <app-select-box [options]="signingOrderOptions" [canSearch]="false" [isRequired]="true"
                    placeholder="Select" (changed)="onSigningOrderChanged($event)" [value]="model.signingOrder.toString()"
                    [errorState]="displayErrors && validationState.signingOrder === false" />
            </div>
        </div>

    </section>

    <section class="settings-section">
        <h3 class="settings-header weight-light">
            Internal Recipients ({{ model.internalRecipients.length }})
        </h3>

        <p class="settings-description mb-3">
            For signers whose name and email are known and always used.
        </p>

        <app-esignature-email-recipients-v2 [recipients]="model.internalRecipients"
            (recipientsChanged)="onRecipientsChanged($event)" [recipientOrigin]="recipientOriginEnum.Internal"
            [useLegacyStyle]="useLegacyStyle" />
    </section>

    <section class="settings-section">
        <h3 class="settings-header weight-light">
            Counterparty Recipients ({{ model.counterpartyRecipients.length }})
        </h3>

        <div *ngFor="let recipient of model.counterpartyRecipients; let i = index" class="mt-3">
            <span class="input-label required">
                {{ recipient.tokenId }}
            </span>
            <span class="counter-party">
                The email address and full name will be asked for when the request is submitted.
            </span>
        </div>

        <div *ngIf="model.counterpartyRecipients.length === 0" class="recipients-empty">
            There were no counterparty recipients detected.
        </div>
    </section>

    <section class="settings-section">
        <h3 class="settings-header weight-light">
            Repository Storage Settings
        </h3>

        <p class="settings-description mb-3">
            Control where the signed request contract will be stored in the repository.
        </p>

        <div class="mb-3">
            <label class="input-label required">
                Folder
            </label>
            <div class="control">
                <app-group-select-box (changed)="onFolderChanged($event)" [groups]="folders" [value]="model.matterId"
                    [errorState]="displayErrors && validationState.matterId === false" placeholder="Select" />
            </div>
        </div>

        <div class="mb-3">
            <label class="input-label required">
                Contract Type
            </label>
            <div class="control">
                <app-select-box [options]="contractTypeOptions" [canSearch]="true" [isRequired]="true"
                    placeholder="Select" (changed)="onContractTypeChanged($event)" [value]="model.contractTypeId"
                    [errorState]="displayErrors && validationState.contractTypeId === false" />
            </div>
        </div>

        <div class="mb-3">
            <label class="input-label required">
                Status
            </label>
            <div class="control">
                <app-select-box [options]="repositoryDocumentStatusIdOptions" [canSearch]="false" [isRequired]="true"
                    [value]="model.repositoryDocumentStatusId" placeholder="Select"
                    [errorState]="displayErrors && validationState.repositoryDocumentStatusId === false"
                    (changed)="onRepositoryStatusChanged($event)" />
            </div>
        </div>

        <div class="mb-3">
            <label class="input-label required">
                Owner
            </label>
            <div class="control">
                <app-select-box [options]="ownerOptions" [canSearch]="false" [isRequired]="true" placeholder="Select"
                    (changed)="onOwnerChanged($event)" [value]="model.assignTo"
                    [errorState]="displayErrors && validationState.assignTo === false" />
            </div>
        </div>

        <div *ngIf="isNotificationActionsEnabled === true" class="mb-3">
            <label class="input-label">
                When the request contract is signed and stored, send notification to
            </label>
            <div class="control">
                <app-autocomplete-chips-groups [groups]="notificationTargetGroups" [items]="notificationTargets"
                    [selectedItems]="selectedNotificationTargets" (changed)="updatedNotificationTargets($event)"
                    [refresh]="refreshSelectedNotificationTargets" />
            </div>
        </div>

    </section>

</div>