import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chat-tokenlimit',
  templateUrl: 'chat-tokenlimit.component.html',
  styleUrls: ['./chat-tokenlimit.component.scss']
})
export class ChatTokenLimitComponent {

  @Input()
  public showMore: boolean = false;

}