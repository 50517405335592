import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseTenantService } from '@summize/shared/core';

export interface OutboundIntegration {
    tenantId: string;
    departmentId: string;
    id: string;
    displayName: string;
    outboundIntegrationType: number;
}

export interface User {
    tenantId: string;
    departmentId: string;
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface Users {
    users: User[];
    totalUsers: number;
    filteredUsers: number;
}

export interface Group {
    groupId: string;
    tenantId: string;
    departmentId: string;
    groupName: string;
}

export interface Groups {
    groups: Group[];
    totalCount: number;
}

@Injectable({ providedIn: 'root' })
export class CalendarEventNotificationTargetsService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getNotificationTargets(): Promise<OutboundIntegration[]> {

        const baseUrl = this.getUserBaseUrl(true);

        const results = await this.asPromise(this.http.get<any>(`${baseUrl}outboundintegrations`, { responseType: 'json' }));

        return results.map(x => ({
            tenantId: x.tenantId || x.TenantId,
            departmentId: x.departmentId || x.DepartmentId,
            id: x.Id || x.id,
            displayName: x.displayName || x.DisplayName,
            outboundIntegrationType: x.outboundIntegrationType || x.OutboundIntegrationType
        }));

    }

    public async getLegalAndBusinessUsers(query: string): Promise<Users> {

        const baseUrl = this.getUserBaseUrl(true);

        const results = await this.asPromise(this.http.get<any>(`${baseUrl}users?name=${query}&userTypes=1,2,3`, { responseType: 'json' }));

        return results;

    }

    public async getGroups(query: string): Promise<Groups> {

        const baseUrl = this.getUserBaseUrl(true);

        const results = await this.asPromise(this.http.get<any>(`${baseUrl}groups?name=${query}`, { responseType: 'json' }));

        return results;

    }

}