export class TextUtil {

  public static takeWords(text: string, take: number) {

    if (!text || take <= 0) {
      return '';
    }

    text = text.trim();

    try {
      const regex = new RegExp(`^((\\S+\\s+){${take - 1}}(\\S+)).`);

      let xWords = text.match(regex);

      if (xWords && xWords.length > 1 && xWords[1].length < text.length) {
        return xWords[1] + ' ...';
      }
    } catch (ex) {
      console.log(ex);
    }

    return text;

  }

  public static splice(originalText: string, textToInsert: string, insertAt: number) {

    return originalText.substr(0, insertAt) + textToInsert + originalText.substr(insertAt);

  }

  public static trim(text: string) {

    // Remove whitespace and Unicode control characters.
    const trimmed = text?.replace(/[\u0000-\u001f\u007f-\u009f]+/g, '').trim();
    return trimmed;

  }

}
