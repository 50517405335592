import { Component, Input, OnInit } from '@angular/core';
import { AsyncErrorCountService } from './async-error-count.service';

@Component({
    selector: 'app-async-error-count',
    templateUrl: 'async-error-count.html',
    styleUrls: ['./async-error-count.scss']
})
export class AsyncErrorCountComponent implements OnInit {

    @Input()
    public tenantId!: string;

    @Input()
    public tenantIdentifier!: string;

    @Input()
    public endpoint!: string;

    public errors!: string;

    constructor(private service: AsyncErrorCountService) { }

    public async ngOnInit(): Promise<void> {

        this.errors = await this.service.get(this.endpoint, this.tenantId, this.tenantIdentifier);

    }

}