import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { MatSelect } from '@angular/material/select';

export interface SelectBoxModel {
    key: string, value: string
}

@Component({
    selector: 'app-form-select-box',
    templateUrl: 'form-select-box.html',
    styleUrls: ['./form-select-box.scss']
})

export class FormSelectBoxComponent {

    @ViewChild(MatSelect)
    public select: MatSelect;

    @Input()
    public parentForm: FormGroup;

    @Input()
    public fcn: string;

    @Input()
    public isRequired: boolean = false;

    @Input()
    public options: Array<SelectBoxModel>;

    @Input()
    public value: string;

    @Input()
    public placeholder: string = 'Please Select';

    @Input()
    public grouped: boolean = false;
    
    @Output()
    public changed: EventEmitter<string>;

    constructor() {

        this.changed = new EventEmitter();

    }

    public valueChanged(value: any): void {

        this.changed.emit(value);

    }

}