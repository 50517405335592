import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';
import { UserService } from '@summize/shared/framework';

export interface ReviewDocumentVersion {
  version: number;
  createdAt: string;
  createdBy: string;
  createdByName: string;
  document: ReviewMasterDocument;
}

export interface ReviewMasterDocument {
  documentId: string;
  documentName: string;
  documentType: string;
  tenantId: string;
  createdAt: string;
  createdBy: string;
  createdByName: string;
  requestedAt: string;
  requestedBy: string;
  requestedByName: string;
  source: string;
  answers: Array<{ questionText: string, value: string; }>;
  questionSet: { displayName: string; };
  preloadedDocument: any;
  linkedDocuments: Array<ReviewLinkedDocument>;
  accessToken: any;
}

export interface ReviewLinkedDocument {
  documentId: string;
  documentName: string;
  documentType: string;
  tenantId: string;
  createdAt: string;
  createdBy: string;
  createdByName: string;
  accessToken: any;
}

@Injectable({ providedIn: 'root' })
export class DownloadDocumentService extends BaseTenantService {

  constructor(private http: HttpClient, private userService: UserService) { super(); }

  public async getDocumentVersions(documentId: string): Promise<Array<ReviewDocumentVersion>> {

    const url = `${this.getUserBaseUrl(true)}documents/${documentId}/review/versions`;

    return await this.http.get(url).toPromise() as any;

  }

  public async getDocumentFileUrl(documentId: string, documentIndex: string): Promise<string> {

    const url = `${this.getUserBaseUrl(true)}quicksummaries/documents/${documentId}/${documentIndex}`;

    return await this.http.get(url).toPromise() as any;

  }


  public async getDownloadLinkForCreated(id: string): Promise<string> {

    const baseUrl = await this.getUserBaseUrl(true);

    const user = this.getUser();

    if (user.departmentId === undefined || user.departmentId === null) {

      await this.userService.refreshSession();

      return await this.getDownloadLinkForCreated(id);

    }

    const url = `${baseUrl}templates/created/download/${id}`;

    return await this.http.get(url).toPromise() as string;

  }

}
