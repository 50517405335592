export class AccessHelper {

    public static IsFullUser(claims: Array<string>): boolean {

        return claims.find(c => c === 'CanTenantSettingsIntegrationRead') !== undefined;

    }

    public static CanAccessApplication(featureFlags: Array<string>, claims: Array<string>): boolean {

        const hasFullClaim = this.IsFullUser(claims);

        if (hasFullClaim === true) {

            return true;

        }

        const hasRestrictedFeatureFlag = featureFlags.find(f => f == 'RestrictBusinessUser') !== undefined;

        if (hasFullClaim === false && hasRestrictedFeatureFlag === true) {

            return false;
        }

        return true;

    }

}
