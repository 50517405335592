import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SelectBoxModule } from '@summize/shared/components';
import { MatMenuModule } from '@angular/material/menu';

import { BlockButtonModule } from '../block-button/block-button.module';

import { FileHelperComponent } from './file-helper.component';
import { FileHelperService } from './file-helper.service';
import { IconModule } from '../icon/icon.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { DropdownButtonModule } from '../dropdown-button/dropdown-button.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SelectBoxModule,
        IconModule,
        BlockButtonModule,
        LoadingSpinnerModule,
        DropdownButtonModule,
        MatMenuModule
    ],
    exports: [
        FileHelperComponent
    ],
    declarations: [
        FileHelperComponent
    ],
    providers: [
        FileHelperService
    ]
})
export class FileHelperModule { }

export * from './file-helper.component';
export * from './file-helper.service';