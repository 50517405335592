<app-dialog-shell title="Custom field" saveText="Apply" [saveDisabled]="!selectedCustomField"
    [saveButtonWidth]="80" [showHeader]="true" [showFooter]="true" (onClose)="close()" (onSave)="save()">

    <ng-container *ngIf="isLoading === true">
        <app-loading-spinner visible="true"></app-loading-spinner>
    </ng-container>

    <ng-container *ngIf="isLoading === false">
        <div class="section-area">
            <div class="description">
                To apply a custom field value select a custom field, enter or select a value, 
                then click 'Apply' to complete the action.
            </div>
            <div class="warning">
                <strong>Please note:</strong> The new value will override any existing values and
                will only apply to contracts where the custom field is applicable.
            </div>
    
            <div class="select">
                <div class="title">
                    Select a custom field
                </div>
                <app-select-box (changed)="selectCustomFieldDefinition($event)" [options]="customFields" placeholder="Select">
                </app-select-box>
            </div>
    
            <div *ngIf="hasCustomFieldDefinitionSelected" class="select">
                <div class="title">
                    {{choosenCustomFieldDefinition.name}}
                </div>
                <div *ngIf="choosenCustomFieldDefinition.fieldType === CustomFieldType.Dropdown">
                    <app-select-box (changed)="setCustomFieldDropDownValue($event)" [options]="dropdownValues"></app-select-box>
                </div>
                <div *ngIf="choosenCustomFieldDefinition.fieldType === CustomFieldType.MultiSelect">
                    <app-select-box (changed)="setCustomFieldDropDownValue($event)"  [options]="dropdownValues" [multipleSelect]="true"></app-select-box>
                </div>
                <div *ngIf="(choosenCustomFieldDefinition.fieldType === CustomFieldType.FreeText 
                    || choosenCustomFieldDefinition.fieldType === CustomFieldType.Numeric
                    || choosenCustomFieldDefinition.fieldType === CustomFieldType.Datepicker)">
                    <input [(ngModel)]="selectedCustomField" name="text" [type]="selectedCustomFieldType" class="smz-standard-input v2">
                </div>
                <p class="text">This is the field description here controlled by the central settings.</p>
            </div>
        </div>
    </ng-container>

</app-dialog-shell>