import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';
import { SelectBoxModel } from '@summize/shared/components';


export interface DocumentClause {
    name: string
}

@Injectable({ providedIn: 'root' })
export class AddGraphService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public getDocumentTypes(): Promise<Array<SelectBoxModel>> {

        return this.http.get<any>(`${this.getUserBaseUrl(true)}documentTypes?status=ContractReviewDocumentTypes`).toPromise().then(results => {
            return results.filter((r: any) => r.isPlaybookOnlyType === false).map((element: any) => {
                return {
                    key: element.documentTypeId,
                    value: element.name
                };
            })
        });

    }

    public async getDocumentTypeClauses(documentTypeId: string): Promise<any> {

        const url = `${this.getUserBaseUrl(true)}analytics/clause-metric/${documentTypeId}`;

        return await this.http.get<any>(url).toPromise();
    }

}
