import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { UserService } from './services/user.service';

@Injectable({
    providedIn: 'root'
})
export class ExcludeRequestorUserGuard implements CanActivate {

    constructor(private userService: UserService, private router: Router) { }

    public canActivate(): boolean {

        if (this.userService.isRequestorUser()) {

            this.router.navigateByUrl('/problem?display=access');

        }

        return true;

    }

}
