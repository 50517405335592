import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SearchBarComponent } from './search-bar.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
    imports: [
        CommonModule,
        IconModule
    ],
    exports: [
        SearchBarComponent
    ],
    declarations: [
        SearchBarComponent
    ]
})
export class SearchBarModule { }

export * from './search-bar.component'