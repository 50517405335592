import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { EsignatureEmailRecipientsComponentV2 } from './esignature-email-recipients-v2.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IconModule
    ],
    exports: [
        EsignatureEmailRecipientsComponentV2
    ],
    declarations: [
        EsignatureEmailRecipientsComponentV2
    ]
})
export class EsignatureEmailRecipientsModuleV2 { }

export * from './esignature-email-recipients-v2.component'