import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { SelectBoxModel } from '@summize/shared/components-v2';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent, ClientMatterLocalizerUtil, EMPTY_GUID, TenantSetting, TenantSettingService, TenantSettingTypes } from '@summize/shared/core';
import { EventService, Event } from '@summize/shared/framework';
import { moveItemInArray } from '@angular/cdk/drag-drop';

import { HeaderCommandPressed, RemoveClauseMetric, SetSelectedManageClauseMetrics, UpdateClauseMetric } from '../analytics.events';
import { ClauseMetricDefinition } from '../analytics.types';
import { AddGraphComponent, DEFAULT_RANGE } from '../components';
import { ManagePanelService } from './manage-panel.service';

@Component({
    selector: 'app-manage-panel',
    templateUrl: 'manage-panel.html',
    styleUrls: ['./manage-panel.scss']
})

export class ManagePanelComponent extends AppComponent implements OnInit, OnDestroy {

    public range: string = DEFAULT_RANGE;

    public clients!: Array<SelectBoxModel>;

    public matters!: Array<SelectBoxModel>;

    public selectedClientId: string = EMPTY_GUID;

    public selectedMatterId: string = EMPTY_GUID;

    public selectedMetrics!: Array<ClauseMetricDefinition>;

    public isLoading: boolean = true;

    public get hasClientIdSelected() {

        return this.selectedClientId != undefined && this.selectedClientId !== null && this.selectedClientId !== EMPTY_GUID;

    }

    private _clients!: any;

    private _config!: TenantSetting;

    constructor(private service: ManagePanelService,
        private tenantSettingsService: TenantSettingService,
        private route: ActivatedRoute,
        private router: Router,
        private events: EventService,
        private dialog: MatDialog) {

        super();

    }

    public async ngOnInit(): Promise<void> {

        this.subscribe(this.events.when(HeaderCommandPressed), (event: Event) => {

            const { context } = event;

            if (context.id === 'ADD_GRAPH') {

                this.handleAddGraph();

            }

        });

        this.subscribe(this.events.when(SetSelectedManageClauseMetrics), async (event: Event) => {

            const { context } = event;

            await this.setSelectedClauseMetrics(context);

        });

        this.subscribe(this.events.when(RemoveClauseMetric), async (event: Event) => {

            const { context } = event;

            await this.removeClauseMetric(context);

        });

        this.subscribe(this.events.when(UpdateClauseMetric), async (event: Event) => {

            const { context } = event;

            await this.updateClauseMetric(context);

        });

        this.subscribe(this.route.queryParams, async (params: any) => {

            this.matters = undefined;

            this.range = params['range'] ? params['range'] : this.range;

            this.selectedClientId
                = params['clientId'] ? params['clientId'] : this.selectedClientId;

            this.selectedMatterId =
                params['matterId'] ? params['matterId'] : this.selectedMatterId;

            await this.load();

        });

    }

    public ngOnDestroy(): void {

        super.ngOnDestroy();

    }

    public onClientChange(client: string) {

        this.setQueryParams({
            clientId: client,
            matterId: EMPTY_GUID
        });

    }

    public onMatterChange(matter: string) {

        this.setQueryParams({
            matterId: matter
        });

    }

    public handleAddGraph(): void {

        this.dialog.open(AddGraphComponent);

    }

    public async onMove(event: any) {

        moveItemInArray(this.selectedMetrics, event.previousIndex, event.currentIndex);

        await this.saveMetricTenantSetting();
    }

    private async load(): Promise<void> {

        this.isLoading = true;

        this._config =
            await this.tenantSettingsService.GetSetting(TenantSettingTypes.ManageAnalyticsConfiguration);

        this.selectedMetrics = this.setSelectedMetrics();

        this._clients = await this.service.getClients()

        const localiser = new ClientMatterLocalizerUtil();

        var clientMerge = [
            { clientId: EMPTY_GUID, clientDisplayId: `All ${localiser.transform('Clients')}` },
            ... this._clients
        ];

        this.clients = clientMerge.map((c: any) => {
            return { key: c.clientId, value: c.clientName || c.clientDisplayId };
        });

        if (this.selectedClientId !== EMPTY_GUID) {

            const matters = await this.service.getMatters(this.selectedClientId);

            const matterMerge = [
                { matterId: EMPTY_GUID, matterName: `All ${localiser.transform('Matters')}` },
                ...matters
            ];

            this.matters = matterMerge.map((m: any) => {
                return { key: m.matterId, value: m.matterName }
            });

        }

        this.isLoading = false;

    }

    private setQueryParams(queryParams: any) {

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge'
            });
    }

    private setSelectedMetrics(): Array<ClauseMetricDefinition> {

        if (this._config === undefined || this._config.settingValue === undefined || this._config.settingValue === '') {

            return [];

        }

        const metrics = JSON.parse(this._config.settingValue);

        for (const metric of metrics) {

            this.setMetricLayout(metric);

        }

        return metrics;

    }


    private async removeClauseMetric(metric: ClauseMetricDefinition): Promise<void> {

        this.selectedMetrics = this.selectedMetrics.filter(m => m !== metric);

        await this.saveMetricTenantSetting();

    }


    private async updateClauseMetric(metric: ClauseMetricDefinition): Promise<void> {

        const match = this.selectedMetrics.find(m => m.id == metric.id);

        if (match !== undefined) {

            this.setMetricLayout(match);

            match.settings = metric.settings;

            await this.saveMetricTenantSetting();

        }

    }

    private async setSelectedClauseMetrics(metrics: Array<ClauseMetricDefinition>): Promise<void> {

        for (const metric of metrics) {

            this.setMetricLayout(metric);

        }

        this.selectedMetrics.push(...metrics);

        await this.saveMetricTenantSetting();

    }

    private async saveMetricTenantSetting(): Promise<void> {

        const raw = JSON.stringify(this.selectedMetrics);

        this._config.settingValue = raw;

        await this
            .tenantSettingsService
            .saveUserSetting(TenantSettingTypes.ManageAnalyticsConfiguration, this._config);

    }

    private setMetricLayout(metric: any): void {

        metric.layout = {
            'col-lg-4': metric.settings.size === '0',
            'col-xl-3': metric.settings.size === '0',
            'col-lg-8': metric.settings.size === '1',
            'col-xl-6': metric.settings.size === '1'
        };

    }
}