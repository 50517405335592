import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExpandableCardComponent } from './expandable-card.component';
import { IconModule } from '../icon/icon.module';
import { EllipsesMenuModule } from '../ellipses-menu/ellipses-menu.module';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        EllipsesMenuModule
    ],
    exports: [
        ExpandableCardComponent
    ],
    declarations: [
        ExpandableCardComponent
    ]
})
export class ExpandableCardModule { }

export * from './expandable-card.component'