import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { SlidePanelComponent, DocumentTypeEnum, SelectBoxModel, AttachmentTypeEnum } from '@summize/shared/components-v2';
import { TicketRequestModel } from '@summize/shared/framework';

import { CustomerHubPanelService } from './customer-hub-panel.service';

export enum SelectedPanelVisbilityEnum {
    LinksPanel = 'LinksPanel',
    SuggestionPanel = 'SuggestionPanel',
    BugPanel = 'BugPanel',
    SuggestionComplete = 'SuggestionComplete',
    BugComplete = 'BugComplete'
}

export enum TicketType {
    Bug = '1',
    Suggestion = '2'
}

@Component({
    selector: 'app-customer-hub-panel',
    templateUrl: 'customer-hub-panel.html',
    styleUrls: ['./customer-hub-panel.scss']
})
export class CustomerHubPanelComponent {

    private $parent: SlidePanelComponent;

    public selectedPanel: SelectedPanelVisbilityEnum = SelectedPanelVisbilityEnum.LinksPanel;

    public fileTypeEnum = DocumentTypeEnum;

    public attachmentTypeEnum = AttachmentTypeEnum;

    public displayedLinks = [];

    public ticketModel: TicketRequestModel;

    public options: Array<SelectBoxModel> = [{key: '1', value: 'Just me'}, {key: '2', value: 'Me and others'}, {key: '3', value: 'Everyone'}, {key: '4', value: 'Not sure'}];

    public attachmentsHintText = 'All file types supported. Max 25 MB per file.';

    public loading = true;

    constructor(
        private customerHubPanelService: CustomerHubPanelService, 
        private router: Router) {}

    public async ngOnInit() {

        const providedLinks = await this.customerHubPanelService.getCustomerHubLinks();

        if (providedLinks?.customerHubLinks?.length > 0) {

            this.displayedLinks = providedLinks.customerHubLinks;

        }

        this.ticketModel = {
            request: {
                descriptionText: '',
                detailText: '',
                pageUrl: '',
                currentUrl: '',
                affectedUsers: '0',
                ticketType: '0',
                attachments: []
            }
        };

        this.loading = false;
        
    }

    public init(parent: SlidePanelComponent): void {

        this.$parent = parent;

    }

    public setTicketType(selection: string) {

        if (selection === SelectedPanelVisbilityEnum.BugPanel) {

            this.selectedPanel = SelectedPanelVisbilityEnum.BugPanel;
            this.ticketModel.request.ticketType = TicketType.Bug;
            
        } else if (selection === SelectedPanelVisbilityEnum.SuggestionPanel) {

            this.selectedPanel = SelectedPanelVisbilityEnum.SuggestionPanel;
            this.ticketModel.request.ticketType = TicketType.Suggestion;

        }

    }

    public onFilesAdded(files: any) {

        files.forEach((element: any) => {

            this.ticketModel.request.attachments.push({
                attachmentId: element.attachmentId,
                fileName: element.name,
                fileSizeBytes: element.size,
                fileType: element.type
            });

        });

    }

    public onFileRemoved(id: any) {

        const idx = this.ticketModel.request.attachments.findIndex(x => x.attachmentId === id);

        if (idx > -1) {

            this.ticketModel.request.attachments.splice(idx, 1);

        }

    }

    public openLink(link: any) {

        window.open(link.url, '_blank');

    }

    public setOptions(selection): void {

        this.ticketModel.request.affectedUsers = selection;

    }

    public close(): void {

        this.$parent.destroy();

    }

    public async saveTicket(): Promise<void> {

        this.loading = true;

        this.ticketModel.request.currentUrl = this.router.url;

        await this.customerHubPanelService.saveTicketDetails(this.ticketModel);

        this.loading = false;

        this.ticketModel.request.ticketType === TicketType.Bug ? this.selectedPanel = SelectedPanelVisbilityEnum.BugComplete : this.selectedPanel = SelectedPanelVisbilityEnum.SuggestionComplete;

    }

    public goBack(): void {

        this.ticketModel = {

            request: {

                descriptionText: '',
                detailText: '',
                pageUrl: '',
                currentUrl: '',
                affectedUsers: '0',
                ticketType: '0',
                attachments: []

            }
        };

        this.selectedPanel = SelectedPanelVisbilityEnum.LinksPanel;

    }

    public isTicketValid(): boolean {

        return this.selectedPanel === SelectedPanelVisbilityEnum.BugPanel ?
            this.ticketModel.request.descriptionText === '' :
            this.ticketModel.request.descriptionText === '' || this.ticketModel.request.detailText === '';

    }

}