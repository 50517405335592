import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseTenantService } from '@summize/shared/core';

import { RequestStageMode, RequestSubtype } from '@summize/shared/framework';

export enum RequestStage {
    NoValue = 1,
    //Create + Review
    Draft = 10,
    Negotiation = 11,
    Completed = 12,
    Negotiation3rdParty = 13,
    ReadyForSignature = 14,
    SentForSignature = 15,
    //Manage
    Active = 20,
    Expired = 21,
    Archived = 22,
    //Ask Legal
    Open = 40,
    Closed = 41,
    //Available to all
    Abandoned = 90,
    NotSet = 99
}

export enum RequestStageColors {
    Inactive = '#CED0E1',
    Draft = '#FFD702',
    Negotiation = '#BB6BD9',
    Negotiation3rdParty = '#F68BFF',
    ReadyForSignature = '#1BCDFF',
    SentForSignature = '#0066EF',
    Completed = '#0ABF68',
    Active = '#FFD702',
    Expired = '#D92248',
    Archived = '#BB6BD9',
    Open = '#FF9791',
    Closed = '#0ABF68',
    Abandoned = '#6F728B'
}

export interface Indicator {
    index: number;
    stage: number;
    disabled?: boolean;
    color: string;
}

@Component({
    selector: 'app-stage-info',
    templateUrl: 'stage-info.html',
    styleUrls: ['./stage-info.scss']
})
export class StageInfoComponent extends BaseTenantService {

    public RequestStage = RequestStage;

    public RequestStageMode = RequestStageMode;

    public RequestSubtype = RequestSubtype;

    public totalRequestStages = [];

    public currentStage: Indicator;

    public disabledTooltip: string = "Cannot change status to abandoned when an edit session is active";

    @Output()
    public onUnlock: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public OnStageChange: EventEmitter<RequestStage> = new EventEmitter<RequestStage>();

    @Input()
    public type: number;

    @Input()
    public readonly: boolean = true;

    @Input()
    public showPadlock = true;

    @Input()
    public set editSessionActive (value: boolean) {

        const changed = this._editSessionActive !== value;

        this._editSessionActive = value;

        this.updateIndicators(value, changed);

    }

    @Input()
    public showTitle: boolean = true;

    @Input()
    public requestStageMode: RequestStageMode
        = RequestStageMode.Linear;

    @Input()
    public hasMandatoryTasks: boolean = false;

    @Input()
    public set stage(value: RequestStage) {

        const changed = this._stage !== value;

        this._stage = value;

        this.updateIndicators(value, changed);

        if (this.indicators !== undefined) {

            this.currentStage = this.indicators.filter(x => x.stage === this.stage)[0];

        }

        if (this.hasFeatureFlag('SendForSignature') === false || this.currentStage?.stage !== RequestStage.SentForSignature) {

            this.indicators = this.indicators.filter(i => i.stage !== RequestStage.SentForSignature);

        }

    }

    public get stage(): RequestStage {

        return this._stage;

    }

    public indicators: Array<Indicator>

    public inactiveColor = RequestStageColors.Inactive;

    private _stage: RequestStage;

    private _editSessionActive: boolean = false;

    public get tooltip(): string {

        switch (this.stage) {
            case RequestStage.Draft:
                return 'All required tasks must be completed before the status can be changed';
            case RequestStage.SentForSignature:
                return 'Document has already been sent for signature';
            default:
                return '';
        }

    }

    public ngOnInit(): void {

        if (this.indicators === undefined) {
            this.indicators = this.createIndicators();
        }

    }

    public getName(stage: RequestStage): string {

        const map = {};

        map[RequestStage.Negotiation] = 'Negotiation (internal)';
        map[RequestStage.Negotiation3rdParty] = 'Negotiation (with 3rd party)';
        map[RequestStage.ReadyForSignature] = 'Ready for Signature';
        map[RequestStage.SentForSignature] = 'Sent for Signature';

        const found = map[stage];

        if (found !== undefined) {

            return found;
        }

        return RequestStage[stage];

    }

    public isRequestStatusAvailable(itemStage: RequestStage): boolean {

        if (itemStage === this.stage) {

            return false;

        }

        if (this.stage === RequestStage.Draft && itemStage !== RequestStage.Abandoned) {

            return false;
            
        }

        if (this.stage !== RequestStage.Draft && this.stage !== RequestStage.Abandoned && itemStage === RequestStage.Draft) {

            return false;
            
        }

        if (this.hasMandatoryTasks === true && this.stage === RequestStage.Abandoned && itemStage !== RequestStage.Draft) {

            return false;
    
        }

        if (this.hasMandatoryTasks === false && this.stage === RequestStage.Abandoned && itemStage === RequestStage.Draft) {

            return false;
    
        }
    
        return true;

    }

    private createIndicators(): Array<Indicator> {

        let items = [];
        this.totalRequestStages = [];
    
        if (this.type === RequestSubtype.Manage) {

            items = [
                { stage: RequestStage.Active, color: RequestStageColors.Active, index: 0 },
                { stage: RequestStage.Archived, color: RequestStageColors.Archived, index: 1 }
            ];
    
            if (this.stage === RequestStage.Expired) {

                items[items.length - 1] = {
                    stage: RequestStage.Expired,
                    color: RequestStageColors.Expired,
                    index: 1
                };
            }
    
            this.getTotalRequestStages(items);

        }
    
        if (this.type === RequestSubtype.Review) {

            items = [
                { stage: RequestStage.Draft, color: RequestStageColors.Draft, index: 0 },
                { stage: RequestStage.Negotiation, color: RequestStageColors.Negotiation, index: 1 },
                { stage: RequestStage.Negotiation3rdParty, color: RequestStageColors.Negotiation3rdParty, index: 1 },
                { stage: RequestStage.ReadyForSignature, color: RequestStageColors.ReadyForSignature, index: 2 },
                { stage: RequestStage.SentForSignature, color: RequestStageColors.SentForSignature, index: 2 },
                { stage: RequestStage.Completed, color: RequestStageColors.Completed, index: 3 },
                { stage: RequestStage.Abandoned, color: RequestStageColors.Abandoned, disabled: this._editSessionActive, index: 3 }
            ];
    
            if (this.hasFeatureFlag('SendForSignature') === false) {

                items = items.filter(i => i.stage !== RequestStage.SentForSignature);

            }
    
            this.getTotalRequestStages(items);

            items = items.filter(i => i.stage !== RequestStage.Draft);

        }
    
        if (this.type === RequestSubtype.Create) {

            items = [
                { stage: RequestStage.Draft, color: RequestStageColors.Draft, index: 0 },
                { stage: RequestStage.Negotiation, color: RequestStageColors.Negotiation, index: 1 },
                { stage: RequestStage.Negotiation3rdParty, color: RequestStageColors.Negotiation3rdParty, index: 1 },
                { stage: RequestStage.ReadyForSignature, color: RequestStageColors.ReadyForSignature, index: 2 },
                { stage: RequestStage.SentForSignature, color: RequestStageColors.SentForSignature, index: 2 },
                { stage: RequestStage.Completed, color: RequestStageColors.Completed, index: 3 },
                { stage: RequestStage.Abandoned, color: RequestStageColors.Abandoned, disabled: this._editSessionActive, index: 3 }
            ];
    
            if (this.hasFeatureFlag('SendForSignature') === false) {

                items = items.filter(i => i.stage !== RequestStage.SentForSignature);

            }
    
            this.getTotalRequestStages(items);

        }
    
        if (this.type === RequestSubtype.AskLegal) {

            items = [
                { stage: RequestStage.Open, color: RequestStageColors.Open, index: 0 },
                { stage: RequestStage.Closed, color: RequestStageColors.Closed, index: 1 },
            ];
    
            this.getTotalRequestStages(items);
        }
    
        return items;
    }
    

    private getTotalRequestStages(items: Array<Indicator>) {

        const maxStages = Math.max(...items.map(x => x.index));
        const minStages = Math.min(...items.map(x => x.index));

        for (let count = minStages; count <= maxStages; count++) {

            this.totalRequestStages.push(count);
        }
    }

    private updateIndicators(value: boolean | RequestStage, changed: boolean) {

        if (value !== undefined && value !== null) {

            if (changed === true || this.indicators?.length === 0) {

                this.indicators = this.createIndicators();

            }

        }
    }

}