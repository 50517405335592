import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SummizeStorage } from '@summize/shared/framework';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: 'confirmation-modal.html',
    styleUrls: ['./confirmation-modal.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ConfirmationModalComponent {

    public header: string;

    public text: string;

    public subtext: string;

    public entity: string = '';

    public cancelButton: string;

    public confirmButton: string;

    public confirmButtonSize: number;

    public hideCancel: boolean = false;

    public colorClass: 'default' | 'danger' | 'success' = 'default';

    public isWordAddIn: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ConfirmationModalComponent>) {

        const _this: any = this;

        Object.keys(data).forEach((k: any) => _this[k] = data[k]);

        this.confirmButton = (this.confirmButton ?? `Delete ${this.entity}`).trim();

        this.cancelButton = this.cancelButton ?? `Cancel`;

        this.confirmButtonSize = this.confirmButtonSize ?? 180;

        this.colorClass = this.colorClass ?? 'default';

        this.isWordAddIn = SummizeStorage.IsWordAddIn();

    }

    public close() {

        this.dialogRef.close(undefined);

    }

}