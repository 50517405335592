<div class="review-panel no-scroll">
    <div class="range-area">
        <div class="panel-header">
            Status Analytics
        </div>
        <app-range-selector></app-range-selector>
    </div>
    <div class="metric-area">
        <app-metric-bar [range]="range"></app-metric-bar>
    </div>
    <div class="smz-flex-area panel-area scroll">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-4 col-sm-12 mt-3">
                    <app-section-panel [loading]="assignedUsers === undefined"
                        [hasData]="assignedUsers?.results?.length > 0" title="Assigned to (users)"
                        subtitle="{{assignedUsers?.totalResultsCount}} users" [more]="true"
                        (onShowMore)="showAssignedTable()">
                        <div *ngFor="let user of assignedUsers?.results" class="progress-item">
                            <app-progress-result-bar [model]="user">
                            </app-progress-result-bar>
                        </div>
                    </app-section-panel>
                </div>
                <div class="col-md-4 col-sm-12 mt-3">
                    <app-section-panel [loading]="assignedGroups === undefined"
                        [hasData]="assignedGroups?.results?.length > 0" title="Assigned to (groups)"
                        subtitle="{{assignedGroups?.totalResultsCount}} groups" [more]="true"
                        (onShowMore)="showAssignedGroupTable()">
                        <div *ngFor="let group of assignedGroups?.results" class="progress-item">
                            <app-progress-result-bar [model]="group" [avatar]="false">
                            </app-progress-result-bar>
                        </div>
                    </app-section-panel>
                </div>
                <div class="col-md-4 col-sm-12 mt-3">
                    <app-section-panel [loading]="contractStatuses === undefined"
                        [hasData]="contractStatuses?.results?.length > 0" title="Contract status">
                        <app-contract-status-panel [statuses]="contractStatuses?.results"></app-contract-status-panel>
                    </app-section-panel>
                </div>
                <div class="col-md-4 col-sm-12 mt-3">
                    <app-section-panel [loading]="requestedGroups === undefined"
                        [hasData]="requestedGroups?.results?.length > 0" title="Requested by (groups)"
                        subtitle="{{requestedGroups?.totalResultsCount}} groups" [more]="true"
                        (onShowMore)="showRequestedGroupTable()">
                        <div *ngFor="let group of requestedGroups?.results" class="progress-item">
                            <app-progress-result-bar [model]="group" [avatar]="false">
                            </app-progress-result-bar>
                        </div>
                    </app-section-panel>
                </div>
            </div>
        </div>
    </div>
</div>
