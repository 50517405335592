import { Component, ChangeDetectionStrategy, OnInit, Input, ViewChild, Output, EventEmitter, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Subject } from 'rxjs';
import { DateFilter, DateTimeFilterPresetEnum, DocumentSource, WidgetFilterRequest, WidgetResponse, WidgetTableElement, WidgetTimeFormat, WidgetType } from '@summize/shared/framework';
import { TableComponent, TableDatasource } from '@summize/shared/components';
import { CloneObject, DEFAULT_DATE, DEFAULT_DATE_UTC } from '@summize/shared/core';

import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { AvailableFilter, FilterBuilderComponent, SelectedFilter } from '../filter-builder/filter-builder.component';
import { WidgetFilterHelper } from './widget-filter-helper';
import { ChartWidgetComponent } from '../chart-widget/chart-widget.component';
import { RequestStage } from '../stage-info/stage-info.component';

export enum WidgetViews {
    Chart = 1,
    Table = 2,
}

@Component({
    selector: 'app-widget-host',
    templateUrl: 'widget-host.html',
    styleUrl: './widget-host.scss',
    changeDetection: ChangeDetectionStrategy.Default
})
export class WidgetHostComponent implements OnInit {

    @ViewChild('requestedAt', { static: true })
    public requestedAtTemplate!: TemplateRef<any>;

    @ViewChild('completedAt', { static: true })
    public completedAtTemplate!: TemplateRef<any>;

    @ViewChild('dueDate', { static: true })
    public dueDateTemplate!: TemplateRef<any>;

    @ViewChild('documentSource', { static: true })
    public documentSourceTemplate!: TemplateRef<any>;

    @ViewChild('requestStage', { static: true })
    public requestStageTemplate!: TemplateRef<any>;

    @ViewChild(FilterBuilderComponent)
    public filterBuilder: FilterBuilderComponent;

    @ViewChild(TableComponent)
    public table!: TableComponent;

    @ViewChild(ChartWidgetComponent)
    public chart!: ChartWidgetComponent;

    @Input()
    public widget: WidgetResponse;

    @Input()
    public showFilters = true;

    @Input()
    public showCommands = true;

    @Input()
    public showSampleData = false;

    @Output()
    public onDeleteWidget: EventEmitter<any>;

    @Output()
    public onFiltersChange: EventEmitter<any>;

    @Output()
    public onTimeframeChange: EventEmitter<any>;

    @Output()
    public onTimeFormatChange: EventEmitter<any>;

    public hasTimeFilter = false;

    public hasChart = false;

    public hasTable = false;

    public isLoading: boolean = true;

    public currentView: WidgetViews;

    public availableFilters: AvailableFilter[] = [];

    public selectedFilters: Array<SelectedFilter> = [];

    public chartElement: any;

    public tableElement: any;

    public tableDatasource: TableDatasource;

    public defaultDate = DEFAULT_DATE;

    public defaultDateUtc = DEFAULT_DATE_UTC;

    public filterActions = [{
        value: 'Apply',
        key: 'apply'
    }, {
        value: 'Cancel',
        key: 'cancel'
    }];

    public timeFormatOptions: Array<any> = [
        {
            value: 'Minutes',
            key: WidgetTimeFormat.Minutes
        },
        {
            value: 'Hours',
            key: WidgetTimeFormat.Hours
        },
        {
            value: 'Days',
            key: WidgetTimeFormat.Days
        },
        {
            value: 'Weeks',
            key: WidgetTimeFormat.Weeks
        }
    ];

    public widgetCommands = [{
        text: 'Remove chart',
        icon: 'trash-outline',
        onClick: () => this.deleteWidget()
    }];

    public isTimeFormatPickerOpen = false;

    public selectedTimeFormat: any;

    private searchText = '';

    constructor(public dialog: MatDialog) {

        this.onDeleteWidget = new EventEmitter<any>();

        this.onFiltersChange = new EventEmitter<any>();

        this.onTimeframeChange = new EventEmitter<any>();

        this.onTimeFormatChange = new EventEmitter<any>();
    }

    public getDocumentSource(docSource: DocumentSource) {

        const source = DocumentSource[docSource];

        return source?.replace(/([a-z])([A-Z])/g, '$1 $2') ?? '-';
    }

    public getRequestStage(reqStage: RequestStage) {

        const stage = RequestStage[reqStage];

        return stage?.replace(/([a-z])([A-Z])/g, '$1 $2') ?? '-';
    }

    public async ngOnInit() {

        this.isLoading = true;

        if (this.widget?.dateFilter === undefined || this.widget.dateFilter === null && this.widget.supportsTimeFilter === true) {

            this.widget.dateFilter = {
                dateTo: this.dateToIsoIgnoreTimezone(new Date(new Date().setHours(23, 59, 59, 999))),
                dateFrom: this.dateToIsoIgnoreTimezone(new Date(new Date().setHours(0, 0, 0, 0))),
                type: DateTimeFilterPresetEnum.Last6Months
            };

            // This month is included so take 5
            const d = new Date(this.widget.dateFilter.dateFrom)
                .setMonth(new Date(this.widget.dateFilter.dateTo).getMonth() - 5);

            this.widget.dateFilter.dateFrom = this.dateToIsoIgnoreTimezone(new Date(d));

        }

        if (this.widget?.elements === undefined) {

            return;
        }

        this.hasTimeFilter = this.widget.supportsTimeFilter;

        this.selectedTimeFormat = this.timeFormatOptions.find(x => x.key === this.widget?.timeFormat) ?? this.timeFormatOptions[1]; // Default Hours

        this.hasChart = this.widget.elements.find(x => x.widgetType === WidgetType.Chart) !== undefined;

        this.hasTable = this.widget.elements.find(x => x.widgetType === WidgetType.Table) !== undefined;

        this.currentView = this.hasChart ? WidgetViews.Chart : WidgetViews.Table;

        if (this.showFilters === true) {

            this.availableFilters = WidgetFilterHelper.buildAvilableFiltersFromWidgetFilters(this.widget.availableFilters);

        }

        if (this.widget.appliedFilters !== undefined && this.widget.appliedFilters !== null) {

            this.selectedFilters = WidgetFilterHelper.buildSelectedFiltersFromAppliedFilters(this.widget.appliedFilters, this.widget.availableFilters);

            this.availableFilters = WidgetFilterHelper.setSelectedFiltersOnAvailableWidgetFilterContext(this.selectedFilters, this.availableFilters);

        }

        if (this.hasTable) {

            this.tableDatasource = this.widget.elements.find(x => x.widgetType === WidgetType.Table) as WidgetTableElement;

            if (this.tableDatasource) {

                this.tableDatasource.queryChanged = new Subject<string>();

                this.refreshTableQuery();
            }

        }

        if (this.hasChart) {

            this.chartElement = this.widget.elements.find(x => x.widgetType === WidgetType.Chart);
        }

        this.isLoading = false;

    }

    public async onTimeFormatChanged(timeFormat: WidgetTimeFormat) {

        this.selectedTimeFormat = this.timeFormatOptions.find(x => x.key === timeFormat);

        this.onTimeFormatChange.next(timeFormat);

        this.isTimeFormatPickerOpen = false;

        await this.refreshData();
    }

    public discardChanges() {

        this.isTimeFormatPickerOpen = false;
    }

    public viewChart() {

        if (this.currentView === WidgetViews.Chart) {

            return;
        }

        this.currentView = WidgetViews.Chart;
    }

    public viewTable() {

        if (this.currentView === WidgetViews.Table) {

            return;
        }

        this.currentView = WidgetViews.Table;

        this.refreshTableQuery();

    }

    public async applyFilters(filters: Array<AvailableFilter>) {

        this.widget.appliedFilters = WidgetFilterHelper.buildWidgetFiltersFromFilterBuilder(filters);

        this.selectedFilters = WidgetFilterHelper.buildSelectedFiltersFromState(filters, this.widget.availableFilters);

        this.onFiltersChange.emit(this.widget.appliedFilters);

        await this.refreshData();

    }

    public onWidgetFiltersReceived(appliedFilters: any) {

        if (appliedFilters) {

            this.selectedFilters = WidgetFilterHelper.buildSelectedFiltersFromAppliedFilters(appliedFilters, this.widget.availableFilters);

            this.availableFilters = WidgetFilterHelper.setSelectedFiltersOnAvailableWidgetFilterContext(this.selectedFilters, this.availableFilters);

            this.widget.appliedFilters = WidgetFilterHelper.buildWidgetFiltersFromFilterBuilder(this.availableFilters);

        }

    }

    public async clearAllFilters() {

        this.selectedFilters = [];

        this.widget.appliedFilters = [];

        this.availableFilters = WidgetFilterHelper.resetAvailableFilters(this.availableFilters);

        this.onFiltersChange.emit(this.widget.appliedFilters);

        await this.refreshData();

    }

    public async onDateChangedEvent(dateTime: DateFilter) {

        this.onTimeframeChange.next(dateTime);

        await this.refreshData();

    }

    public async onKey(event: any) {

        this.searchText = event;

        await this.refreshData();

    }

    public async onClearSearch() {

        this.searchText = '';

        await this.refreshData();

    }

    public editFilter(filter: SelectedFilter) {

        if (this.filterBuilder !== undefined) {

            this.filterBuilder.editSelectedFilter(filter);

        }

    }

    public async removeFilterParam(filter: SelectedFilter): Promise<void> {

        const idx = this.selectedFilters.findIndex(x => x.id === filter.id);

        const wfidx = this.widget.appliedFilters.findIndex(x => x.field === filter.field);

        const availableIdx = this.availableFilters.findIndex(x => x.fieldName === filter.fieldName);

        if (availableIdx > -1) {

            this.availableFilters[availableIdx].selected = [];

        }

        if (idx > -1 && wfidx > -1) {

            this.selectedFilters.splice(idx, 1);

            this.widget.appliedFilters.splice(wfidx, 1);

            this.widget.appliedFilters = CloneObject(this.widget.appliedFilters);


            if (this.filterBuilder !== undefined) {

                this.filterBuilder.clearSelectedById(filter.id);

            }

            this.onFiltersChange.emit(this.widget.appliedFilters);

            await this.refreshData();

        }

    }

    private async refreshData() {

        if (this.currentView === WidgetViews.Chart) {

            await this.refrehChartData();

        } else {

            this.refreshTableQuery();
        }
    }

    private async refrehChartData() {

        await this.chart?.reload(this.widget.appliedFilters, this.widget.dateFilter, this.selectedTimeFormat.key);

    }

    private refreshTableQuery() {

        let tableQuery = `query=${this.searchText}`;

        if (this.widget.appliedFilters?.length > 0) {

            const filters = JSON.stringify(this.widget.appliedFilters);

            tableQuery += `&filters=${filters}`
        }

        tableQuery = this.widget.dateFilter?.dateFrom ? tableQuery += `&dateFrom=${this.widget.dateFilter.dateFrom}` : tableQuery;

        tableQuery = this.widget.dateFilter?.dateTo ? tableQuery += `&dateTo=${this.widget.dateFilter.dateTo}` : tableQuery;

        tableQuery = this.widget.dateFilter?.type ? tableQuery += `&dateFilterPreset=${this.widget.dateFilter.type}` : tableQuery;

        tableQuery = this.selectedTimeFormat ? tableQuery += `&timeFormat=${this.selectedTimeFormat.key}` : tableQuery;

        this.tableDatasource.queryProvider = () => {

            return tableQuery;
        };

        this.tableDatasource.headerProvider = () => {

            const transformedHeaders = this.tableDatasource.headers.map(header => {

                switch (header.template as any) {
                    case 'requestedAt':
                        header.template = this.requestedAtTemplate;
                        break;
                    case 'completedAt':
                        header.template = this.completedAtTemplate;
                        break;
                    case 'dueDate':
                        header.template = this.dueDateTemplate;
                        break;
                    case 'documentSource':
                        header.template = this.documentSourceTemplate;
                        break;
                    case 'requestStage':
                        header.template = this.requestStageTemplate;
                        break;
                }

                return header;

            });

            return transformedHeaders;
        }

        this.tableDatasource.queryChanged.next(tableQuery);
    }

    private deleteWidget() {

        this.dialog.open(ConfirmationModalComponent, {
            data: {
                header: 'Remove Chart',
                text: `Are you sure you want to remove this chart from your dashboard?`,
                entity: 'Chart',
                confirmButton: 'Remove Chart'
            }
        }).afterClosed().subscribe((x) => {

            if (x === true) {

                this.onDeleteWidget.emit(this.widget.id);
            }
        });
    }

    private dateToIsoIgnoreTimezone(date: Date) {

        return date.getFullYear() + '-' +
            ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
            ('0' + date.getDate()).slice(-2) + 'T' +
            ('0' + date.getHours()).slice(-2) + ':' +
            ('0' + date.getMinutes()).slice(-2) + ':' +
            ('0' + date.getSeconds()).slice(-2) + '.' +
            ('00' + date.getMilliseconds()).slice(-3);
    }

}