import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Groups } from "@summize/shared/framework";

import { BaseTenantService } from "./base-tenant.service";

@Injectable({ providedIn: 'root' })
export class GroupsService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getUserGroups(): Promise<Groups> {

        const baseUrl = this.getUserBaseUrl(true);

        return await this.asPromise(this.http.get<any>(`${baseUrl}groups`));

    }

    public async getUserGroupsByQuery(query: string): Promise<Groups> {

        const baseUrl = this.getUserBaseUrl(true);

        return await this.asPromise(this.http.get<Groups>(`${baseUrl}groups?name=${query}`));

    }
}