import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AsyncAtivityCountComponent } from './async-activity-count.component';
import { AsyncActivityCountService } from './async-activity-count.service';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        AsyncAtivityCountComponent
    ],
    declarations: [
        AsyncAtivityCountComponent
    ],
    providers: [
        AsyncActivityCountService
    ]
})
export class AsyncActivityCountModule { }

export * from './async-activity-count.component'