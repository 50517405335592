import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseTenantService } from './base-tenant.service';

export const TenantSettingTypes = {
    TeamsNotification: 'TeamsNotification',
    CreateIntroductionComplete: 'CreateIntroductionComplete',
    ManageIntroductionComplete: 'ManageIntroductionComplete',
    InboxIntroductionComplete: 'InboxIntroductionComplete',
    ManageAnalyticsConfiguration: 'ManageAnalyticsConfiguration',
    WebhookSecret: 'WebhookSecret',
    DocusignSignature: 'DocusignSignature',
    HasApiManagement: 'HasApiManagement',
    UserCalendarSyncSettings: 'UserCalendarSyncSettings',
    ManageSaveFilters: 'ManageSaveFilters',
    DocusignAllowPersonalAccounts: 'DocusignAllowPersonalAccounts',
    GlobalCompanyLookupSource: 'GlobalCompanyLookupSource',
    WordEditorIntroComplete: 'WordEditorIntroComplete',
    DocumentViewerConvertDocxToPdf: 'DocumentViewerConvertDocxToPdf',
    IsSendToAdobeSignEnabled: 'IsSendToAdobeSignEnabled',
    RequestorLicense: 'RequestorLicense',
    NotificationRequestAnalyticsMovedConfirmed: 'NotificationRequestAnalyticsMovedConfirmed'
};

export interface TenantSetting {
    tenantSettingId?: string;
    tenantSettingType?: string;
    settingValue: string;
}

@Injectable({ providedIn: 'root' })
export class TenantSettingService extends BaseTenantService {

    constructor(private httpClient: HttpClient) { super(); }

    public async GetSetting(settingTypeName: string): Promise<TenantSetting> {

        return await this.httpClient.get(`${this.getUserBaseUrl(true)}tenantsetting/${settingTypeName}`).toPromise() as any;
    }

    public async isEnabled(settingTypeName: string): Promise<boolean> {

        const setting = await this.GetSetting(settingTypeName);

        return setting && setting.settingValue === "true";

    }

    public async saveTenantSetting(settingTypeId: string, setting: TenantSetting): Promise<any> {

        return await this.saveSetting(settingTypeId, setting, 0);

    }

    public async saveDepartmentSetting(settingTypeId: string, setting: TenantSetting): Promise<any> {

        return await this.saveSetting(settingTypeId, setting, 1);

    }

    public async saveUserSetting(settingTypeId: string, setting: TenantSetting): Promise<any> {

        return await this.saveSetting(settingTypeId, setting, 2);

    }

    private async saveSetting(settingTypeId: string, setting: TenantSetting, level: number): Promise<any> {

        return await this.httpClient.post(`${this.getUserBaseUrl(true)}tenantsetting/${settingTypeId}`, {
            tenantSettingId: setting.tenantSettingId,
            settingValue: setting.settingValue,
            targetingLevel: level
        }).toPromise() as any;

    }

}