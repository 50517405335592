import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { PipesModule, SelectBoxModule } from '@summize/shared/components';
import { BlockButtonModule } from '@summize/shared/components-v2';

import {
    ContractStatusPanelModule,
    MetricBarModule,
    RangeSelectorModule,
    SectionPanelModule,
    ProgressResultBarModule,
    ClauseMetricModule,
    AddGraphModule
} from '../components';

import { ManagePanelComponent } from './manage-panel.component';
import { ManagePanelService } from './manage-panel.service';


@NgModule({
    imports: [
        CommonModule,
        MetricBarModule,
        RangeSelectorModule,
        SectionPanelModule,
        ProgressResultBarModule,
        ContractStatusPanelModule,
        SelectBoxModule,
        ClauseMetricModule,
        PipesModule,
        AddGraphModule,
        BlockButtonModule,
        DragDropModule
    ],
    declarations: [
        ManagePanelComponent
    ],
    exports: [
        ManagePanelComponent
    ],
    providers: [
        ManagePanelService
    ]
})
export class ManagePanelModule { }
