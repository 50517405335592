import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { NotificationsService } from '@summize/shared/core';

import { handlePendingNotifications } from './notification-util';

@Injectable({
    providedIn: 'root'
})
export class RequestAnalyticsMovedService {

    constructor(private notificationsService: NotificationsService,
        private dialog: MatDialog,
        private router: Router) {
    }

    public async checkAndHandleNotifications(menuItemClick?: boolean, onComplete?: () => void) {

        const notificationHandler = handlePendingNotifications(
            this.notificationsService,
            this.dialog,
            this.router,
            menuItemClick,
            onComplete
        );

        await notificationHandler();
    }
}