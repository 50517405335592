<div class="smz-flex-area">
    <div class="app-header container-fluid">
        <div class="row align-items-center mx-0 my-0">
            <div [@fadeInAnimation] class="col-2 px-0">
                <div class="fade-block logo align-self-center">
                    <img *ngIf="logo === undefined || logo === null || logo === ''"
                        src="assets/logos/summize-logo.svg" />
                    <img *ngIf="logo !== undefined && logo !== ''" src="{{logo}}" class="custom-logo" />
                </div>
            </div>
            <ng-container *ngIf="user !== undefined">
                <div class="col">
                    <div class="align-self-center">
                        <ng-content select="[left]"></ng-content>
                    </div>
                </div>
                <div [@slideUpAnimation] class="col block align-self-end align-self-center header-right-area">
                    <div class="block actions my-auto float-right">
                        <ng-content select="[right]"></ng-content>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>