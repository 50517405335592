import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToastComponent } from './toast.component';
import { ToastContainerComponent } from './toast-container.component';
import { ToastService } from './toast.service';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        ToastComponent,
        ToastContainerComponent
    ],
    declarations: [
        ToastComponent,
        ToastContainerComponent
    ],
    providers: [
        ToastService
    ]
})
export class ToastModule { }

export * from './toast.component'
export * from './toast-container.component';
export * from './toast.service';