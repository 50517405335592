import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TaskNotesComponent } from './task-notes.component';
import { NotesModule } from '../notes/notes.module';

@NgModule({
    imports: [
        CommonModule,
        NotesModule
    ],
    exports: [
        TaskNotesComponent
    ],
    declarations: [
        TaskNotesComponent
    ]
})
export class TaskNotesModule { }