import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FadeInAnimation } from '@summize/shared/components';
import { SelectBoxModel } from '@summize/shared/components-v2';
import { CloneObject, ListOperators, NumericOperators, StringOperators } from '@summize/shared/core';

export enum FilterType {
    List,
    ListWithGrouping,
    DateRange,
    ParentChildList,
    RadioSelect,
    SubSelect,
    EntitiesWithGrouping
}

export enum CustomFieldFilterType {
    Freetext,
    Numeric,
    Date,
    List
}

export interface AvailableFilter {
    id: string;
    name: string;
    field?: number;
    fieldName?: string;
    type: FilterType;
    selected?: Array<string>;
    entity?: string;
    urlEncode?: boolean;
    source: () => Promise<any>;
}

export interface SelectedFilter {
    id: string,
    name: string;
    field?: number;
    fieldName?: string;
    width?: number;
    value: string;
    valueCount: number;
    subType?: any;
}

@Component({
    selector: 'app-filter-builder',
    templateUrl: 'filter-builder.html',
    styleUrls: ['./filter-builder.scss'],
    animations: [FadeInAnimation]
})
export class FilterBuilderComponent {

    public FilterType = FilterType;

    public SubFiltertype = CustomFieldFilterType;

    @Output()
    public onApply: EventEmitter<Array<AvailableFilter>>;

    @Input()
    public filters: Array<AvailableFilter>;

    @Input()
    public selected: Array<SelectedFilter>;

    @Input()
    public buttonWidth: number = 110;

    @Input()
    public buttonIcon: string = 'add-outline';

    @Input()
    public buttonText: string = 'Add filter';

    @Input()
    public hostClass: string;

    public isOpen: boolean = false;

    public isEdit: boolean = false;

    public selectedFilter: AvailableFilter;

    public selectedFilterItems: any;

    public selectedRadioItem: any;

    public selectedSubfilterItem: any;

    public query: string = undefined;

    public selectedFrom: string;

    public selectedTo: string;

    public selectedSubType: any;

    public selectedOperator: any;

    public typeOperators: Array<SelectBoxModel> = StringOperators;

    public overlayPositions = [
        {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
        },
        {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
        }
    ];

    constructor() {

        this.onApply = new EventEmitter<Array<AvailableFilter>>();

    }

    public detach() {

        this.query = undefined;

        this.selectedFilter = undefined;

        this.selectedSubType = undefined;

        this.selectedOperator = undefined;

        this.selectedSubfilterItem = undefined;

        this.selectedTo = undefined;

        this.selectedFrom = undefined;

        this.isEdit = false;

    }

    public async setSelectedSubType(value: any) {

        this.selectedSubType = value;

        this.setOperatorsForSubType();

        if (this.selectedSubType.availableValues !== undefined && Array.isArray(this.selectedSubType.availableValues)) {

            this.selectedFilterItems = this.selectedSubType.availableValues
                .map(v => ({ id: v.id, name: v.display, enabled: false }));

        }

    }

    public async setSubSelectFilter(filter: AvailableFilter, selected: SelectedFilter) {

        this.selectedFilter = filter;

        const items = await this.selectedFilter.source();

        const match = items.find(i => i.customFieldId === (<any>selected).customFieldId);

        this.selectedSubType = match;

        this.setOperatorsForSubType();

        const parts = selected.value.split('_');

        if (selected.subType === CustomFieldFilterType.Freetext || selected.subType === CustomFieldFilterType.Numeric) {

            this.selectedOperator = parts[0];

            this.selectedSubfilterItem = parts[1];

        } else if (selected.subType === CustomFieldFilterType.List) {

            this.selectedOperator = parts[0];

            this.selectedFilterItems = this.selectedSubType.availableValues
                .map(v => ({ id: v.id, name: v.display, enabled: false }));

            for (const selected of parts[1].split(',')) {

                const match = this.selectedFilterItems.find(s => s.name === selected);

                if (match !== undefined) {

                    match.enabled = true;

                }

            }

        } else if (selected.subType === CustomFieldFilterType.Date) {

            const selected = parts[1].split(',');

            this.selectedFrom = selected[0];

            this.selectedTo = selected[1];

        }

    }

    public async setSelectedFilter(filter: AvailableFilter, selectedValues: string = undefined): Promise<void> {

        this.selectedFilter = filter;

        let parsed = [];

        if (selectedValues === undefined) {

            selectedValues = this.selected?.find(s => decodeURIComponent(s.id) === filter.id)?.value;

            if (selectedValues !== undefined) {

                parsed = decodeURIComponent(selectedValues).split(',');

            }
            else {

                parsed = [];
                
            }

        } else if (selectedValues !== undefined && selectedValues !== '') {

            parsed = filter.urlEncode === true ?
                selectedValues.split(',').map(x => decodeURIComponent(x)) :
                selectedValues.split(',');

        }

        if (filter.type === FilterType.DateRange) {

            this.selectedFrom = parsed[0];

            this.selectedTo = parsed[1];

        }

        if (filter.type !== FilterType.DateRange) {

            const cleanArray = CloneObject(await this.selectedFilter.source());

            this.selectedFilterItems = cleanArray;

            if (selectedValues !== undefined) {

                if (parsed.length > 0) {

                    for (const selected of parsed) {

                        let match = undefined;

                        if (filter.type === FilterType.List) {

                            match = this.selectedFilterItems.find(s => s.id === selected);

                        }
                        else if (filter.type === FilterType.RadioSelect) {

                            this.selectedRadioItem = selected;

                        } else if (filter.type === FilterType.ListWithGrouping) {

                            match = this.selectedFilterItems.items.find(s => s.id === selected)
                                || this.selectedFilterItems.groups.find(s => s.id === selected);

                        } else if (filter.type === FilterType.EntitiesWithGrouping) {

                            for (const key in this.selectedFilterItems.entities) {

                                const items = this.selectedFilterItems.entities[key];
                                match = items?.find(x => x.id === selected);

                                if (match !== undefined) {

                                    match.enabled = true;

                                    break;

                                }
                            }

                        } else if (filter.type === FilterType.ParentChildList) {

                            for (const parent of this.selectedFilterItems) {

                                if (parent.id.toLowerCase() === selected.toLowerCase()) {

                                    parent.enabled = true;

                                    for (const child of parent.children) {

                                        child.enabled = parsed.find(p => p.toLowerCase() === child.id.toLowerCase()) !== undefined;
                                    }

                                    if (parent.children.some(c => c.enabled === true) === false) {

                                        parent.children.forEach(c => c.enabled = true);

                                    }

                                } else {

                                    match = parent.children.find(s => s.id.toLowerCase() === selected.toLowerCase());

                                    if (match !== undefined) {

                                        match.enabled = true;

                                    }

                                }

                            }

                        }

                        if (match !== undefined) {

                            match.enabled = true;

                        }

                    }

                }

            }

        }

    }

    public apply() {

        if (this.selectedSubType !== undefined) {

            this.applySelectedSubType();

            return;
        }

        if (this.selectedFilter.type === FilterType.List) {

            this.selectedFilter.selected
                = this.selectedFilterItems?.filter(s => s.enabled === true).map(s => s.id);

        }
        else if (this.selectedFilter.type === FilterType.RadioSelect) {

            const match = this.selectedFilterItems.find(s => s.id === this.selectedRadioItem);

            if (match !== undefined) {

                this.selectedFilter.selected = [match.id];

            }

        }
        else if (this.selectedFilter.type === FilterType.ListWithGrouping) {

            this.selectedFilter.selected = [
                ...this.selectedFilterItems.groups.filter(s => s.enabled === true).map(s => s.id),
                ...this.selectedFilterItems.items.filter(s => s.enabled === true).map(s => s.id)
            ];

        }
        else if (this.selectedFilter.type === FilterType.EntitiesWithGrouping) {

            let results = [];

            for (const key in this.selectedFilterItems.entities) {

                const items = this.selectedFilterItems.entities[key];
                const filtered = items?.filter(x => x.enabled === true).map(i => i.id);

                if (filtered?.length > 0) {

                    results.push(...filtered);
                }
            }

            this.selectedFilter.selected = [...results];

        }
        else if (this.selectedFilter.type === FilterType.ParentChildList) {

            const working = [];

            for (const parent of this.selectedFilterItems) {

                if (parent.enabled === true) {

                    working.push(parent.id);

                    continue;

                }

                working.push(...parent.children.filter(c => c.enabled === true).map(s => s.id));

            }

            this.selectedFilter.selected = working;

        } else if (this.selectedFilter.type === FilterType.DateRange) {

            this.selectedFilter.selected = [
                this.selectedFrom || new Date('0001-01-01T00:00:00').toISOString(),
                this.selectedTo || new Date('0001-01-01T00:00:00').toISOString()
            ];

        }

        this.onApply.emit(this.filters);

        this.isOpen = false;

    }

    public applySelectedSubType() {

        const parent: any = this.filters.find(f => f.id === this.selectedSubType.parent);

        if (parent !== undefined) {

            if (parent.selected === undefined) {

                parent.selected = [];

            }

            let value = this.selectedSubfilterItem;

            if (this.selectedSubType.type === CustomFieldFilterType.List) {

                value = this.selectedFilterItems
                    .filter(f => f.enabled === true)
                    .map(f => f.id)
                    .join(',');

            }

            if (this.selectedSubType.type === CustomFieldFilterType.Date) {

                const parts = [
                    this.selectedFrom || new Date('0001-01-01T00:00:00').toISOString(),
                    this.selectedTo || new Date('0001-01-01T00:00:00').toISOString()
                ];

                value = `${parts[0]},${parts[1]}`;

            }

            parent.selected.push({
                field: this.selectedSubType.value,
                value: value,
                operator: this.selectedSubType.type === CustomFieldFilterType.Date ? 'range' : this.selectedOperator
            });

        }

        this.onApply.emit(this.filters);

        this.isOpen = false;

    }

    public hasValue() {

        if (this.selectedFilter.type === FilterType.List) {

            return this.selectedFilterItems?.filter(s => s.enabled === true).map(s => s.id).length > 0;

        }
        else if (this.selectedFilter.type === FilterType.RadioSelect) {

            const match = this.selectedFilterItems.find(s => s.id === this.selectedRadioItem);

            return match !== undefined;

        }
        else if (this.selectedFilter.type === FilterType.ListWithGrouping) {

            return this.selectedFilterItems.groups.filter(s => s.enabled === true).map(s => s.id).length > 0 ||
                this.selectedFilterItems.items.filter(s => s.enabled === true).map(s => s.id).length > 0;

        }
        else if (this.selectedFilter.type === FilterType.ParentChildList) {

            const working = [];

            for (const parent of this.selectedFilterItems) {

                if (parent.enabled === true) {

                    working.push(parent.id);

                    continue;

                }

                working.push(...parent.children.filter(c => c.enabled === true).map(s => s.id));

            }

            return working.length > 0;

        } else if (this.selectedFilter.type === FilterType.DateRange) {

            return this.selectedFrom && this.selectedTo ? true : false;

        } else if (this.selectedFilter.type === FilterType.SubSelect) {

            if (this.selectedSubType !== undefined) {

                if (this.selectedSubType.type === CustomFieldFilterType.Date) {

                    return true;

                } else if (this.selectedSubType.type === CustomFieldFilterType.Freetext || this.selectedSubType.type === CustomFieldFilterType.Numeric) {

                    return this.selectedOperator !== undefined
                        && this.selectedSubfilterItem !== undefined
                        && this.selectedSubfilterItem !== '';

                }
                else if (this.selectedSubType.type === CustomFieldFilterType.List) {

                    return this.selectedOperator !== undefined
                        && this.selectedFilterItems !== undefined
                        && this.selectedFilterItems.some(f => f.enabled === true);

                }

            }

            return false;

        }

    }

    public editSelectedFilter(filter: SelectedFilter): void {

        const match = this.filters.find(f => f.id === filter.id);

        this.isOpen = true;

        this.isEdit = true;

        match.type === FilterType.SubSelect ?
            this.setSubSelectFilter(match, filter) :
            this.setSelectedFilter(match, filter.value);

    }

    public clearSelectedById(key: string): void {

        const match = this.filters.find(x => x.id == key);

        if (match !== undefined) {

            match.selected = undefined;

        }

    }

    public async cancel(close: boolean = false) {

        if (this.selectedSubType !== undefined) {

            this.selectedSubType = undefined;

            if (this.isEdit === true) {

                this.isOpen = false;

                this.isEdit = false;

            } else {

                const cleanArray = CloneObject(await this.selectedFilter?.source());

                this.selectedFilterItems = cleanArray;

            }

            return;

        }

        this.selectedFilter = undefined;

        if (this.isEdit === true) {

            this.isOpen = false;

            this.isEdit = false;

        }

        if (close === true) {

            this.isOpen = false;

        }

    }

    public queryHasNoResults(results): boolean {

        return results.lengh === 0;

    }

    public parentElementChecked(element, event) {

        element.enabled = event.checked;

        element.children.forEach(c => c.enabled = element.enabled);

    }

    public childElementChecked(element, parent, event) {

        element.enabled = event.checked;

        parent.enabled = false;

    }

    public isSubfolderMatch(child) {

        if (this.query !== undefined) {

            const query = this.query.toLowerCase();

            const childName = child.name.toLowerCase();

            return childName.includes(query);
        }

        return true;
    }

    private setOperatorsForSubType() {

        if (this.selectedSubType.type === CustomFieldFilterType.Freetext) {

            this.typeOperators = StringOperators;

        } else if (this.selectedSubType.type === CustomFieldFilterType.Numeric) {

            this.typeOperators = NumericOperators;

        } else if (this.selectedSubType.type === CustomFieldFilterType.List) {

            this.typeOperators = ListOperators;

        }

        this.selectedOperator = this.typeOperators[0].key;

    }

}