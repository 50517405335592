<div class="dialog v2">
    <div class="dialog__content upload-document-dialog__content">
        <div class="dialogHeader">
            <h1 class="dialogHeader__title body__large">
                {{header}}
            </h1>
            <div class="dialog__line"></div>
        </div>
        <p class="dialog__text">{{text}}</p>
        <div class="dialog__footer">

            <app-button size="medium" type="tertiary" class="mr-3" mat-dialog-close>
                Cancel
            </app-button>

            <app-button size="medium" type="primary" [mat-dialog-close]="true">
                Delete {{entity}}
            </app-button>

        </div>
    </div>
</div>