import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

export enum TaskStatusType {
    UpToDate,
    Overdue,
    DueSoon,
    NotDue
}

export enum TaskDisplayStatusColour {
    Red,
    Orange,
    Blue,
    Grey
}

@Component({
    selector: 'app-task-info',
    templateUrl: 'task-info.html',
    styleUrls: ['./task-info.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskInfoComponent {

    @Input()
    public tasks: number = 0;

    @Input()
    public status: TaskDisplayStatusColour = TaskDisplayStatusColour.Grey;

    public static getStatusColourFromTaskNumbers(base: any): TaskDisplayStatusColour {

        if (base.tasksOverdue > 0) {

            return TaskDisplayStatusColour.Red;

        } else if (base.tasksDueNext48 > 0) {

            return TaskDisplayStatusColour.Orange;

        }
        else if (base.tasksNotYetDue > 0) {

            return TaskDisplayStatusColour.Blue;

        } else {

            return TaskDisplayStatusColour.Grey;
        }

    }

    public static getStatusColourFromTaskStatus(taskStatus: TaskStatusType): TaskDisplayStatusColour {

        switch (taskStatus)
        {
            case TaskStatusType.Overdue:
                return TaskDisplayStatusColour.Red;
            case TaskStatusType.DueSoon:
                return TaskDisplayStatusColour.Orange;
            case TaskStatusType.NotDue:
                return TaskDisplayStatusColour.Blue;
            default:
                return TaskDisplayStatusColour.Grey;
        }

    }

    public get isActive() {

        return this.tasks > 0;

    }

}