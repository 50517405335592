import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { BaseTenantService } from '@summize/shared/core';

@Directive({ selector: '[appFeatureFlag]' })
export class FeatureFlagDirective extends BaseTenantService implements OnInit {

    @Input()
    public set appFeatureFlag(feature: string) {
        this.feature = feature;
    }

    @Input()
    public set appFeatureFlagShow(show: boolean) {
        this.show = show;
    }

    private feature: string;

    private show: boolean = true;

    constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {

        super();

    }

    public ngOnInit(): void {

        if (this.feature) {

            if (this.hasFeatureFlag(this.feature) === this.show) {

                this.viewContainer.createEmbeddedView(this.templateRef);

            } else {

                this.viewContainer.clear();

            }
        }

    }

}