import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'app-usage-metric',
    templateUrl: 'usage-metric.html',
    styleUrls: ['./usage-metric.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class UsageMetricComponent {

    @Input()
    public current: number;

    @Input()
    public limit: number;

    @Input()
    public description: string;

    @Input()
    public tooltip: string;

}