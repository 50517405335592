import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-info-point',
    templateUrl: 'info-point.html',
    styleUrls: ['./info-point.scss']
})

export class InfoPointComponent {

    @Input()
    public text: string;

    @Input()
    public display: 'inline' | 'metric' = 'inline';

}