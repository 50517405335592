import { Injectable } from '@angular/core';

import { SummizeStorage } from '@summize/shared/framework';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
    private window = (typeof window !== 'undefined');

    public getItems() {
        if (this.window) {
            return Object.entries(localStorage).map(e => { return { key: e[0], value: e[1] } });
        }
        return null;
    }

    getItem(key: string): any {
        if (this.window) {
            return SummizeStorage.getLocalItem(key);
        }
        return null;
    }

    setItem(key: string, val: string) {
        if (this.window) {
            SummizeStorage.setLocalItem(key, val);
        }
    }

    removeItem(key: string) {
        if (this.window) {
            SummizeStorage.removeLocalItem(key);
        }
    }

    removeItemByRegex(regex: RegExp) {
        if (this.window) {
            var items = this.getItems();
            items.forEach(i => {
                if (regex.test(i.key)) {
                    this.removeItem(i.key);
                }
            })
        }
    }

    public getJson(key: string): any {
        try {
            return JSON.parse(this.getItem(key));
        } catch {
            return undefined;
        }
    }

    public setJson(key: string, val: any) {
        this.setItem(key, JSON.stringify(val));
    }

    clear() {
        if (this.window) {
            SummizeStorage.clearLocal();
        }
    }
}