import { Component, OnInit } from '@angular/core';

import { ConfirmationModalComponent, SelectBoxModel, SlidePanelService, SlidePanelSettings } from '@summize/shared/components-v2';
import { EventService, FadeInAnimation, SummizeClaims } from '@summize/shared/framework';
import { MatDialog } from '@angular/material/dialog';
import { AllContractTypesId, AppComponent, ChangeDetectionHelper, CloneObject, HEADER_HEIGHT, LayoutHelper } from '@summize/shared/core';

import { CustomFieldDefinition, CustomFieldEditorComponent, CustomFieldType, FieldTypeNameMap } from '../components';
import { ManageService } from './manage.service';

@Component({
    templateUrl: 'manage.html',
    styleUrls: ['./manage.scss'],
    animations: [FadeInAnimation]
})
export class ManageComponent extends AppComponent implements OnInit {

    public CustomFieldType = CustomFieldType;

    public fields: Array<CustomFieldDefinition>;

    public selected: CustomFieldDefinition;

    public contractTypes: Array<SelectBoxModel>;

    public isLoading = true;

    public cleanFields: Array<CustomFieldDefinition>;

    public selectedType: string;

    public canAddCustomFields: boolean = false;

    public canDeleteCustomFields: boolean = false;

    public canEditCustomFields: boolean = false;

    constructor(private slidePanelService: SlidePanelService,
        private service: ManageService,
        private events: EventService,
        private dialog: MatDialog) { super(); }

    public async ngOnInit(maintainSelected = false) {

        this.isLoading = true;

        this.canAddCustomFields = this.hasClaim(SummizeClaims.CustomFields.CanCustomFieldCreate) && this.isPocUser === false;

        this.canDeleteCustomFields = this.hasClaim(SummizeClaims.CustomFields.CanCustomFieldDelete) && this.isPocUser === false;
        
        this.canEditCustomFields = this.hasClaim(SummizeClaims.CustomFields.CanCustomFieldEdit) && this.isPocUser === false;

        this.cleanFields = await this.service.getDefinitions();

        this.selectedTypeChanged(this.selectedType);

        this.contractTypes = await this.service.getTypes();

        if (this.fields !== undefined && this.fields.length > 0) {

            if (maintainSelected === true) {

                this.setSelected(this.fields.find(f => f.id === this.selected?.id));

            } else {

                this.selected = undefined;

            }

        }

        ChangeDetectionHelper.doNextCycle(() => {

            const height = LayoutHelper.getWindowHeight() - HEADER_HEIGHT;

            document
                .querySelector('.custom-fields')
                .setAttribute('style', `height:${height - 3}px`);

        }, 1000);

        this.isLoading = false;
    }

    public selectedTypeChanged(type: string): void {

        this.selectedType = type;

        this.selected = undefined;

        if (type !== undefined) {

            this.fields = CloneObject(this.cleanFields.filter(f => f.targetContractTypes.includes(type)));

        } else {

            this.fields = CloneObject(this.cleanFields);

        }

    }

    public addCustomField() {

        const saveCommand = {
            text: 'Add custom field',
            isSaveCommand: true,
            closeAfterClick: false,
            type: 'block',
            width: 140,
            onClick: async (panel: CustomFieldEditorComponent, command: any, slidePanel: any) => {

                try {

                    await this.create(panel.getDefinition());

                    slidePanel.destroy();

                } catch (error) {

                    if (error.status === 409) {

                        panel.invalidName = true;

                    } else {

                        this.events.actionFailed('Saving Custom Field failed. Please try again.');

                    }

                }

            }
        };

        const settings: SlidePanelSettings = {
            component: CustomFieldEditorComponent,
            arguments: {
                contractTypes: this.contractTypes
            },
            commands: [
                saveCommand, { text: 'Cancel', type: 'outline', closeAfterClick: true }
            ],
            title: 'Add a custom field',
            closeable: true,
            size: 'xl',
            backdrop: true,
            onBackdrop: () => { },
        };

        this.slidePanelService.show(settings);

    }

    public editSelected() {

        const item = CloneObject(this.selected);

        item.targetContractTypes = item.targetContractTypes?.split(',');

        const saveCommand = {
            text: 'Save changes',
            isSaveCommand: true,
            closeAfterClick: false,
            type: 'block',
            width: 130,
            onClick: async (panel: CustomFieldEditorComponent, command: any, slidePanel: any) => {

                try {

                    if (panel.isConfirmingUpdate === false) {

                        panel.isConfirmingUpdate = true;

                        settings.title = 'Confirm changes';

                        settings.commands[0].text = 'Yes, save changes';

                        settings.commands[0].width = 160;

                    } else {

                        await this.service.saveDefinition(panel.getDefinition());

                        await this.ngOnInit(true);

                        slidePanel.destroy();

                    }

                } catch (error) {

                    if (error.status === 409) {

                        panel.invalidName = true;

                        panel.isConfirmingUpdate = false;

                        settings.commands[0].text = 'Save changes';

                        settings.commands[0].width = 130;

                    } else {

                        this.events.actionFailed('Saving Custom Field failed. Please try again.');

                    }

                }

            }
        };

        const settings: SlidePanelSettings = {
            component: CustomFieldEditorComponent,
            arguments: {
                contractTypes: this.contractTypes,
                model: item
            },
            commands: [
                saveCommand, { text: 'Cancel', type: 'outline', closeAfterClick: true }
            ],
            title: 'Edit custom field',
            closeable: true,
            size: 'xl',
            backdrop: true,
            onBackdrop: () => { },
        };

        this.slidePanelService.show(settings);

    }

    public removeSelected(field) {

        const data: any = {
            header: 'Remove custom field',
            text: `Deleting a custom field will remove it from the system completely, it will no longer be visible as a column in the Repository, all values currently stored will be removed and this cannot be undone.
            <br/><br/>
            Are you sure you want to delete the custom field called '${field.name}'?`,
            confirmButton: 'Delete',
            confirmButtonSize: 120
        };

        const confirmDelete = this.dialog.open(ConfirmationModalComponent, { data });

        this.subscribe(confirmDelete.afterClosed(), async confirm => {

            if (confirm === true) {

                await this.service.removeDefinition(field.id);

                this.events.actionComplete('Custom field removed');

                this.ngOnInit(this.selected !== undefined && this.selected?.id !== field.id);

            }

        });

    }

    public setSelected(item: any) {

        this.selected = item;

        if (this.selected === undefined) {

            return;

        }

        if (this.selected.targetContractTypes !== undefined) {

            const parts = this.selected.targetContractTypes.split(',');

            const available = [{ key: AllContractTypesId, value: 'All contract types' }, ...this.contractTypes];

            this.selected.targetContractTypesDisplay = parts
                .map(p => available.find(c => c.key === p).value)
                .join(', ');

        }

        this.selected.fieldTypeDisplay = FieldTypeNameMap[this.selected.fieldType];

        if (this.selected.fieldType === CustomFieldType.Dropdown || this.selected.fieldType === CustomFieldType.MultiSelect) {

            this.selected.valueDisplay = JSON.parse(this.selected.values).map(v => v.display).join(',');

        }

    }

    public getTypeDisplay(item): string {

        return FieldTypeNameMap[item.fieldType];

    }

    private async create(def: CustomFieldDefinition): Promise<any> {

        await this.service.createDefinition(def);

        this.events.actionComplete('Custom field added');

        this.ngOnInit();

    }
}