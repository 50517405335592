import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppComponent } from '@summize/shared/core';
import { TABLES } from '../analytics.contants';

import { AnalyticTableResult, ContractStatusItem } from '../analytics.types';
import { DEFAULT_RANGE } from '../components';
import { ReviewPanelService } from './review-panel.service';

@Component({
    selector: 'app-review-panel',
    templateUrl: 'review-panel.html',
    styleUrls: ['./review-panel.scss']
})

export class ReviewPanelComponent extends AppComponent implements OnInit {

    public assignedUsers: AnalyticTableResult | undefined;

    public assignedGroups: AnalyticTableResult | undefined;

    public requestedGroups: AnalyticTableResult | undefined;

    public contractStatuses: AnalyticTableResult | undefined;

    public range: string = DEFAULT_RANGE;

    constructor(
        private service: ReviewPanelService,
        private route: ActivatedRoute,
        private router: Router) {

        super();

    }

    public async ngOnInit(): Promise<void> {

        this.subscribe(this.route.queryParams, async (params: any) => {

            this.range = params['range'] ? params['range'] : this.range;

            await this.load();

        });

    }

    public showAssignedTable(): void {

        this.router.navigate([`analytics/table/`, TABLES.AssignedToTable, this.range]);

    }

    public showAssignedGroupTable(): void {

      this.router.navigate([`analytics/table/`, TABLES.AssignedToGroupTable, this.range]);

    }

    public showRequestedGroupTable(): void {

        this.router.navigate([`analytics/table/`, TABLES.RequestedByGroupTable, this.range]);

    }

    private async load(): Promise<void> {

        await Promise.all([
            this.loadAssignedToUsers(),
            this.loadAssignedToGroups(),
            this.loadContractStatuses(),
            this.loadRequestedByGroups()]);

    }

    private async loadAssignedToUsers(): Promise<void> {

        this.assignedUsers = undefined;

        this.assignedUsers = await this.service.getAssignedToUsers(this.range);

    }

    private async loadRequestedByGroups(): Promise<void> {

        this.requestedGroups = undefined;

        this.requestedGroups = await this.service.getRequestedByGroups(this.range);

    }

    private async loadAssignedToGroups(): Promise<void> {

      this.assignedGroups = undefined;

      this.assignedGroups = await this.service.getAssignedToGroups(this.range);

    }

    private async loadContractStatuses(): Promise<void> {

        this.contractStatuses = undefined;

        this.contractStatuses = await this.service.getContractStatuses(this.range);

    }

}
