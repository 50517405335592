import { QuestionDefinitionType } from "@summize/shared/core";

interface RequestQuestion {
    description: string;
    fieldMappings: string;
    questionType?: number;
    meta?: { values: Array<{ key: string, value: string }> }
}

export class QuestionSuggestionHelper {

    private static ScriptStartIndicator = '!';

    public static getQuestionValue(suggestions, connector, question: RequestQuestion): any {

        if (suggestions === undefined || suggestions.length === 0) {

            return '';

        }

        if (question === undefined) {

            return '';
        }

        const canUseFieldMappings = question.fieldMappings !== undefined
            && question.fieldMappings !== null
            && question.fieldMappings !== ''
            && question.fieldMappings !== 'null'
            && connector !== undefined
            && connector !== '';

        try {

            if (canUseFieldMappings === true) {

                const mappings: Array<{ connector: any, value: string }> = JSON.parse(question.fieldMappings);

                if (mappings !== undefined && mappings !== null && mappings.length > 0) {

                    const mappingsForConnector = mappings.filter(x => parseInt(x.connector) === parseInt(connector));

                    if (mappingsForConnector !== undefined) {

                        for (const map of mappingsForConnector) {

                            if (map.value.startsWith(QuestionSuggestionHelper.ScriptStartIndicator) === true) {

                                const val = QuestionSuggestionHelper.ExecuteAsScript(map, suggestions);

                                if (val === undefined) {

                                    return val;

                                }

                                if (question.meta !== undefined && question.meta.values !== undefined && question.meta.values.length > 0) {

                                    if (question.questionType === QuestionDefinitionType.Dropdown) {

                                        const match = question.meta?.values?.find(x => x.value === val)?.key;

                                        return match;
                                    }

                                }

                                return val;

                            }

                            const matchOnMapping = suggestions.find(s => s.name === map.value);

                            if (matchOnMapping !== undefined) {

                                if (question.meta !== undefined && question.meta.values !== undefined && question.meta.values.length > 0) {

                                    const matchOnOptionDisplay = question.meta.values.find(x => x.value === matchOnMapping.value);

                                    if (matchOnOptionDisplay !== undefined) {

                                        if (question.questionType === QuestionDefinitionType.MultiSelect) {

                                            return [matchOnOptionDisplay.key];

                                        }

                                        return matchOnOptionDisplay.key;

                                    }

                                }

                                return matchOnMapping.value;

                            }

                        }

                    }

                }

            } else {

                const directMatch = suggestions.find(s => s.name.toLocaleLowerCase() === question.description.toLowerCase());

                if (directMatch !== undefined) {

                    return directMatch.value;

                }

            }

        } catch (error) {

            console.log(`Summize: Failed to map field: ${JSON.stringify(question)}`)

            return '';

        }

        return '';

    }

    public static ExecuteAsScript(map: { value: string; }, suggestions: Array<any>): string {

        const evaluator = (code, context) => {

            const keys = Object.keys(context);

            const values = Object.values(context);

            const restrictedFunc = new Function(...keys, 'return `' + code.slice(1) + '`');

            try {

                const val = restrictedFunc(...values);

                return val;

            } catch (error) {

                return '';

            }

        };

        const context = QuestionSuggestionHelper.buildObjectFromSuggestions(suggestions);

        const result = evaluator(map.value, context);

        return result;

    }

    private static buildObjectFromSuggestions(array: Array<{ key: string; value: any; displayName: string }>): any {

        const result = {};

        array.forEach(item => {

            const keys = item.key.split('.');

            let current = result;

            keys.forEach((key, index) => {

                const isLastKey = index === keys.length - 1;

                if (!current[key]) {

                    current[key] = isLastKey ? item.value : {};

                } else if (isLastKey) {

                    current[key] = item.value;

                }

                current = current[key];

            });
        });

        const transformToArrayIfNecessary = (obj: any) => {

            if (obj && typeof obj === 'object' && obj[0] !== undefined) {

                const items = new Array(Object.keys(obj).length);

                for (const subKey in obj) {

                    if (Object.prototype.hasOwnProperty.call(obj, subKey)) {

                        items[subKey] = obj[subKey];

                    }
                }

                return items;
            }

            return obj;

        }

        const transformComplexItems = (obj: any) => {

            for (const key in obj) {

                if (Object.prototype.hasOwnProperty.call(obj, key)) {

                    obj[key] = transformToArrayIfNecessary(obj[key]);

                    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {

                        transformComplexItems(obj[key]);

                    }

                }
            }

        }

        transformComplexItems(result);

        return result;

    }

}