import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ESignatureAutoSendModel, ESignatureRecipientType, ESignatureRecipientOrigin, ESignatureRecipientLocationType, EventService } from '@summize/shared/framework';

import { ReviewDocumentVersion } from '../download-documents/download-documents.service';
import { SelectModel } from '../select-box/select-box.component';

import { SendForSignatureAutoPanelService } from './send-for-signature-auto-panel.service';
import { SlidePanelContent, SlidePanelComponent } from '../slide-panel/slide-panel.component';

@Component({
    selector: 'app-send-for-signature-auto-panel',
    templateUrl: 'send-for-signature-auto-panel.html',
    styleUrls: ['./send-for-signature-auto-panel.scss']
})
export class SendForSignatureAutoPanelComponent implements OnInit, SlidePanelContent {

    @Input()
    public documentId: string;

    @Input()
    public eSignatureAutoSendDetail: ESignatureAutoSendModel;

    public model: any;

    public isLoading = false;

    public selectedDocumentId: string;

    public versions: Array<ReviewDocumentVersion>;

    public versionOptions: Array<SelectModel> = [];

    public selectedVersion: ReviewDocumentVersion;

    public recipientTypeEnum = ESignatureRecipientLocationType;

    public linkedContractType = [
        { key: '1', value: 'Variation' },
        { key: '2', value: 'Addendum' },
        { key: '3', value: 'Novation' },
        { key: '4', value: 'SOW' },
        { key: '5', value: 'OrderForm' }
    ];

    private $parent: SlidePanelComponent;

    constructor(private service: SendForSignatureAutoPanelService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private events: EventService) {

        this.model = {
            internalRecipients: [],
            counterparties: []
        }

    }

    public save(instance: SendForSignatureAutoPanelComponent) {

        instance.onSave.call(instance);

    }

    public async ngOnInit() {
        this.isLoading = true;

        this.versions = await this.service.getDocumentVersions(this.documentId);

        this.versionOptions = this.versions.map(x => ({
            key: x.document.documentId,
            value: `Version ${x.version}${x.version === this.versions.length ? ' (current)' : ''}`
        }));

        if (this.versionOptions.length > 0) {

            this.selectedDocumentId = this.documentId;

            this.selectVersion(this.versionOptions.find(x => x.key === this.documentId)?.key);

        }

        if (this.eSignatureAutoSendDetail.eSignaturePendingRequestContext?.internalRecipients?.length > 0) {

            this.eSignatureAutoSendDetail.internalRecipients = this.eSignatureAutoSendDetail.eSignaturePendingRequestContext.internalRecipients;
        }

        this.eSignatureAutoSendDetail.counterpartyRecipients = this.eSignatureAutoSendDetail.eSignaturePendingRequestContext?.counterparties ?? [{
            name: '',
            email: '',
            tokenId: '',
            signOrder: 1,
            recipientType: ESignatureRecipientType.To,
            recipientOrigin: ESignatureRecipientOrigin.Counterparty,
            signatureType: this.eSignatureAutoSendDetail.signatureType,
            visible: true
        }];
        
        this.isLoading = false;
    }

    public init(parent: SlidePanelComponent) {

        this.$parent = parent;

        if (this.$parent?.instance?.model !== undefined) {

            this.model = this.$parent?.instance?.model;
        }

    }

    // Selecting a new version will 'check' every document and add it to the request
    public selectVersion(masterDocumentId: string) {

        // Reset model documents
        this.model.masterDocumentId = undefined;
        this.model.documents = [];

        if (masterDocumentId === undefined) {
            return;
        }

        this.selectedVersion = this.versions.find(x => x.document.documentId === masterDocumentId);

        if (this.selectedVersion === undefined) {
            return;
        }

        this.model.masterDocumentId = masterDocumentId;

        this.model.documents.push({
            documentId: masterDocumentId,
            documentName: this.selectedVersion.document.documentName,
            documentType: 0,
            order: 1
        });

        if (this.selectedVersion.document.linkedDocuments?.length > 0) {

            for (const linked of this.selectedVersion.document.linkedDocuments as any) {

                linked.checked = true;
                linked.linkedDocumentType = this.linkedContractType.find(x => x.key === `${linked.documentType}`)?.value || '';

            }
        }
    }

    public changeVersion(selectedVersion) {

        this.selectVersion(selectedVersion);

    }

    public onCounterpartiesChanged(event: any) {

        this.eSignatureAutoSendDetail.counterpartyRecipients = event;
    }

    public onInternalRecipientsChanged(event: any) {

        this.eSignatureAutoSendDetail.internalRecipients = event;
    }

    private async onSave(): Promise<void> {

        this.isLoading = true;

        try {

            let order = 2
            this.selectedVersion.document.linkedDocuments.forEach((linked: any) => {

                const foundDoc = this.model.documents.find(x => x.documentId === linked.documentId);

                if (linked.checked && foundDoc === undefined) {

                    this.model.documents.push({
                        documentId: linked.documentId,
                        documentName: linked.documentName,
                        documentType: linked.documentType,
                        order: order
                    });

                    order++;

                }

            });

            const payload = {
                masterDocumentId: this.documentId,
                internalRecipients: this.eSignatureAutoSendDetail.internalRecipients,
                counterparties: this.eSignatureAutoSendDetail.counterpartyRecipients,
                documents: this.model.documents
            };

            await this.service.sendForSignature(payload);

            this.$parent?.destroy();

            this.events.actionComplete('Sent for signature');

            this.router.navigate(
                [],
                {
                    relativeTo: this.activatedRoute,
                    queryParams: { panelId: 'signatures' },
                    queryParamsHandling: 'merge'
                });

        }
        catch (err) {

            this.events.actionFailed(err.error !== undefined ? err.error : 'An error occurred sending for signature');

        }

        this.isLoading = false;

    }

}