import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseTenantService, EMPTY_GUID } from '@summize/shared/core';
import { OutboundIntegration, User } from "@summize/shared/framework";

@Injectable({ providedIn: 'root' })
export class AutoSendForSignatureServiceV2 extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getSignatureIntegrations(): Promise<any> {

        const baseUrl = this.getUserBaseUrl(true);

        const results = await this.http.get<any>(`${baseUrl}esignatures/integrations`, { responseType: 'json' }).toPromise();

        return results;

    }

    public async getSignatureIntegrationSignatureTypes(integrationId: string) {

        const baseUrl = this.getUserBaseUrl(true);

        const results = await this.http.get<any>(`${baseUrl}docusign/accounts/${integrationId}/signatureTypes`, { responseType: 'json' }).toPromise();

        return results;

    }

    public async getAvailableAssignees(clientId: string = EMPTY_GUID, matterId: string = EMPTY_GUID): Promise<any> {

        const baseUrl = this.getUserBaseUrl(true);

        let users
            = this.http.get<any>(`${baseUrl}users`, { responseType: 'json' }).toPromise();

        if (clientId !== EMPTY_GUID && matterId !== EMPTY_GUID) {

            const allowedUserTypes = [1, 2, 3].join(',');

            let url = `${this.getUserBaseUrl(false)}1.0/clients/${clientId}/matters/${matterId}/users?userTypes=${allowedUserTypes}`;

            users = new Promise(async (resolve) => {

                const u: any = await this.http.get(url).toPromise();

                resolve({
                    users: u.map(x => ({ userId: x.id, firstName: x.firstName, lastName: x.lastName, email: x.email }))
                });

            });

        }

        const groups
            = this.http.get<any>(`${baseUrl}groups`, { responseType: 'json' }).toPromise();

        return Promise.all([users, groups]);

    }

    public async getNotificationTargets(): Promise<OutboundIntegration[]> {

        const baseUrl = this.getUserBaseUrl(true);

        const results = await this.http.get<any>(`${baseUrl}outboundintegrations`, { responseType: 'json' }).toPromise();

        return results.map(x => ({
            tenantId: x.tenantId || x.TenantId,
            departmentId: x.departmentId || x.DepartmentId,
            id: x.Id || x.id,
            displayName: x.displayName || x.DisplayName,
            outboundIntegrationType: x.outboundIntegrationType || x.OutboundIntegrationType
        }));

    }

    public async getSignatureMeta(): Promise<any> {

        const baseUrl = this.getUserBaseUrl(true);

        const results = await this.http.get<any>(`${baseUrl}esignatures/meta`, { responseType: 'json' }).toPromise();

        return results;

    }

    public async getUsersForClientMatter(clientId: string, matterId: string): Promise<User[]> {

        const baseUrl = this.getUserBaseUrl(true);

        const results = await this.http.get<any>(`${baseUrl}clients/${clientId}/matters/${matterId}/users?userTypes=1,2,3`, { responseType: 'json' }).toPromise();

        return results;

    }

}