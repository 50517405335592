
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { BehaviorSubject, Subject } from 'rxjs';

import { BaseTenantService, EMPTY_GUID } from '@summize/shared/core';
import { SummizeStorage } from './summize-storage';

export enum WebsocketEventTypes {
    ConversationCreated,
    ConversationMessageAdded,
    ConversationDeleted,
    ConversationMessageDeleted,
    ConversationMessageUpdated,
    ClauseCommentAdded,
    ClauseCommentUpdated,
    ClauseCommentDeleted,
    RedflagUpdated,
    ConversationStatusChanged,
    ClauseBulletUpdated,
    ClauseBulletDeleted,
    ClauseBulletAdded,
    NewVersionAdded,
    UserRefreshSession,
    UserLogoutSession,
    DocumentCheckoutStarted,
    DocumentCheckoutCancelled,
    DocumentCheckoutCompleted,
    DocumentCheckoutTakeover,
    ESignatureStatusChanged,
    ESignatureRecipientsStatusChanged
}

export interface WebsocketPayload<T> {
    type: WebsocketEventTypes,
    payload: T
}

export class WebsocketService {

    private connectionEstablished = new BehaviorSubject<boolean>(false);

    public isConnected = this.connectionEstablished.asObservable();

    public isStartingConnection = false;

    public isConnectionEstablished = false;

    private hubConnection: HubConnection;

    constructor() { }

    public async initConnection<T>(startConnection = false): Promise<Subject<any>> {

        const chatApi = `${BaseTenantService.environment.apiUrl}/api/chathub`;

        const $onMessage = new Subject<any>();

        this.hubConnection = new HubConnectionBuilder()
            .withUrl(chatApi, { accessTokenFactory: () => SummizeStorage.getLocalItem('token') })
            .withAutomaticReconnect()
            .build();

        this.hubConnection.keepAliveIntervalInMilliseconds
            = 1000 * 60 * 3;

        this.hubConnection.serverTimeoutInMilliseconds
            = 1000 * 60 * 6;

        this.hubConnection.onclose(async () => {

            setTimeout(() => this.startConnection(), + BaseTenantService.environment.signalRTimeout);

        });

        this.hubConnection.on('onEvent', (message) => {

            $onMessage.next(message);

        });

        this.hubConnection.keepAliveIntervalInMilliseconds
            = +BaseTenantService.environment.signalRKeepAlive;

        if (startConnection === true) {

            await this.hubConnection
                .start()
                .then(() => {

                    this.isConnectionEstablished = true;

                    this.isStartingConnection = false;

                    this.connectionEstablished.next(true);

                })
                .catch(err => {

                    this.isConnectionEstablished = false;

                    this.isStartingConnection = false;

                    this.connectionEstablished.next(false);

                    setTimeout(this.startConnection, +BaseTenantService.environment.signalRTimeout);

                });

        }

        return $onMessage;

    }


    public joinGroup(group: string, clientId: string, matterId: string, attempt: number = 0, onJoin = undefined): Promise<void> {

        if (this.isConnectionEstablished === false) {

            this.startConnection();

            return;
        }

        return this.hubConnection
            .invoke('JoinGroup', group, clientId, matterId)
            .catch(async err => {

                if (attempt <= 3) {

                    await this.startConnection();

                    attempt++;

                    this.joinGroup(group, clientId, matterId, attempt, onJoin);

                } else {

                    console.error(err);

                    return;

                }

            }).then(() => {

                if (onJoin !== undefined) {

                    onJoin();
                }

            });

    }

    public leaveGroup(group: string, clientId: string = EMPTY_GUID, matterId: string = EMPTY_GUID, attempt: number = 0): Promise<void> {

        if (this.isConnectionEstablished || this.isStartingConnection || this.hubConnection === undefined) {

            return;

        }

        return this.hubConnection.invoke('LeaveGroup', group, clientId, matterId);

    }

    public stopConnection() {

        this.isStartingConnection = true;

        this.isConnectionEstablished = false;

        this.connectionEstablished.next(this.isConnectionEstablished);

        if (this.hubConnection) {

            this.hubConnection.stop();

        }

        this.hubConnection = null;

        this.isStartingConnection = false;

    }

    private startConnection() {

        if (this.isConnectionEstablished || this.isStartingConnection) {

            return;

        }

        this.isStartingConnection = true;

        if (!this.hubConnection) {

            this.initConnection();

        }

        return this.hubConnection
            .start()
            .then(() => {
                this.isConnectionEstablished = true;
                this.isStartingConnection = false;
                this.connectionEstablished.next(true);
            })
            .catch(err => {
                this.isConnectionEstablished = false;
                this.isStartingConnection = false;
                this.connectionEstablished.next(false);
                setTimeout(this.startConnection, +BaseTenantService.environment.signalRTimeout);
            });

    }

}