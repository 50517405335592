import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoadingSpinnerComponent } from './loading-spinner.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        LoadingSpinnerComponent
    ],
    declarations: [
        LoadingSpinnerComponent
    ]
})
export class LoadingSpinnerModule { }

export * from './loading-spinner.component'