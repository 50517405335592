import { Injectable } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

import { FixedArray } from "../fixed-array";

@Injectable({ providedIn: "root" })
export class NavigationService {

  private history: FixedArray = new FixedArray(50);

  constructor(private router: Router) {

    this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {

        this.history.push(event.urlAfterRedirects);

      }

    });

  }

  public getHistory(): Array<string> {

    return this.history.get();

  }

}