import { Injectable } from '@angular/core';

import { SummizeStorage } from './summize-storage';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {

    private window = (typeof window !== 'undefined');

    public getItems() {

        if (this.window) {
            return Object.entries(SummizeStorage.getLocalItems()).map(e => { return { key: e[0], value: e[1] } });
        }

        return null;
    }

    getItem(key: string): any {
        if (this.window) {
            return SummizeStorage.getLocalItem(key);
        }
        return null;
    }

    getJson(key: string): any {
        const value = this.getItem(key);

        if (value === undefined || value === null) {
            return value;
        }

        try {
            return JSON.parse(value);
        } catch {
            return null;
        }

    }

    public getNumberFromStorage(location: 'localStorage' | 'sessionStorage', key: string, fallback: number): number {

        const value = window[location].getItem(key);

        if (value !== null && value !== undefined) {

            return parseInt(value, 10);

        }

        return fallback;

    }


    setItem(key: string, val: string) {
        if (this.window) {
            SummizeStorage.setLocalItem(key, val);
        }
    }

    removeItem(key: string) {
        if (this.window) {
            SummizeStorage.removeLocalItem(key);
        }
    }

    removeItemByRegex(regex: RegExp) {
        if (this.window) {
            var items = this.getItems();
            items.forEach(i => {
                if (regex.test(i.key)) {
                    this.removeItem(i.key);
                }
            })
        }
    }

    clear() {
        if (this.window) {
            SummizeStorage.clearLocal();
        }
    }

}
