import { Injectable } from '@angular/core';

import { BaseTenantService } from '@summize/shared/core';

export interface UserDetails{
    firstName: string;
    lastName: string;
    email: string;
}

@Injectable({ providedIn: 'root' })
export class UserProfileService extends BaseTenantService {

    public getUserDetails(): UserDetails {

        const user = this.getUser();

        return {
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email
        }

    }

}