import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { AppComponent, GlobalContextService, RequestStatusesWithoutSendForSignature, RequestStatusesWithSendForSignature, FeatureFlags } from '@summize/shared/core';
import { UserService } from '@summize/shared/framework';
import { RequestAnalyticsMovedService } from '@summize/shared/components-v2';

@Component({
    selector: 'app-side-menu',
    templateUrl: 'side-menu.html',
    styleUrls: ['./side-menu.scss']
})
export class SideMenuComponent extends AppComponent implements OnInit {

    @Output()
    public expandedChanged: EventEmitter<boolean>;

    public showRepository: boolean = false;

    public showUserManagement: boolean = false;

    public showClauseManager: boolean = false;

    public showInbox: boolean = false;

    public showManagement: boolean = false;

    public showPlaybookManager: boolean = false;

    public showPlaybooks: boolean = false;

    public showSettings: boolean = false;

    public showQuickSummary: boolean = false;

    public showAnalytics: boolean = false;

    public showCreate: boolean = false;

    public showRequests: boolean = false;

    public showCustomFields: boolean = false;

    public requestStatuses = '';

    constructor(private userService: UserService,
        private router: Router,
        private context: GlobalContextService,
        private cdr: ChangeDetectorRef,
        private requestAnalyticsMovedService: RequestAnalyticsMovedService) {

        super();

        this.expandedChanged = new EventEmitter<boolean>();

    }

    public async ngOnInit(): Promise<void> {

        this.setItemVisability();

        this.requestStatuses = this.hasFeatureFlag('SendForSignature') === true ? RequestStatusesWithSendForSignature : RequestStatusesWithoutSendForSignature;

        this.context.$change.subscribe(() => {

            this.cdr.detectChanges();

        });

    }

    public IsOnRequests(): boolean {

        const routes = [
            'quick-summary/contract/review',
            'quick-summary/contract/create',
            'quick-summary/contract/asklegal',
            'quick-summary/overview',
            'quick-summary/templates',
            'quick-summary/question',
            'quick-summary/repository',
            'create/templates',
            'contracts/quick-summary',
        ];

        const isInRoutes = routes.some(r => this.router.url.includes(r));

        return isInRoutes === true;
    }

    public isOnManagePage(): boolean {

        const routes = [
            'quick-summary/contract/manage',
            'contracts/overview',
            'contracts/repository'
        ];

        const isInRoutes = routes.some(r => this.router.url.includes(r));

        return isInRoutes === true;

    }

    public isOnQuickSummary(): boolean {

        const routes = [
            'quick-summary/dashboard',
            'quick-summary/contract/review'
        ];

        const isInRoutes = routes.some(r => this.router.url.includes(r));

        return isInRoutes === true &&
            this.router.url.includes('settings') === false;

    }

    public async goToManage(): Promise<void> {

        const root = await this.userService.getManagePage();

        if (root.route === undefined) {

            this.router.navigate([root]);

        } else {

            this.router.navigate([root.route], { queryParams: root.params });

        }

    }

    public async goToAnalytics() {

        await this.requestAnalyticsMovedService.checkAndHandleNotifications(true, () => {

            this.router.navigateByUrl('/quick-summary/dashboards');

        });

    }

    private setItemVisability(): void {

        this.showRepository = this.userService.hasClaim(this.Claims.Documents.CanDocumentsRead);

        this.showRequests = this.userService.hasClaim(this.Claims.Requests.CanQuickSummarySelfRead);

        this.showCreate =
            this.showRequests === false &&
            this.shouldBeVisibleForRestrictedBusinessUsers('RestrictCreateForBizUsers');

        this.showUserManagement =
            this.userService.hasClaims(['CanUsersUpdate']) &&
            this.userService.getIsAdmin();

        this.showClauseManager =
            this.userService.hasClaims(['CanClausesCreate']) ||
            this.userService.hasClaims(['CanClausesUpdate']);

        this.showInbox = !this.userService.isPocUser() && this.userService.hasFeatureFlag('Inbox') &&
            this.userService.hasClaims(['CanReceivedEnvelopesRead']) &&
            this.shouldBeVisibleForRestrictedBusinessUsers('RestrictInboxForBizUsers');

        this.showPlaybooks =
            (this.userService.hasClaims(['CanRulebooksRead']) ||
                this.userService.hasFeatureFlag(FeatureFlags.HasPlaybookDesignerAugust2024)) &&
            (this.userService.hasClaims(['CanPlaybookCreate']) ||
                this.userService.hasClaims(['CanPlaybookUpdate']));

        this.showPlaybookManager = this.showPlaybooks !== true &&
            (this.userService.hasClaims(['CanPlaybookCreate']) ||
                this.userService.hasClaims(['CanPlaybookUpdate']));

        this.showQuickSummary =
            this.showRequests === false &&
            this.userService.hasClaims(['CanQuickSummarySelfRead']) &&
            this.shouldBeVisibleForRestrictedBusinessUsers('RestrictReviewForBizUsers');

        this.showCustomFields = this.hasFeatureFlag('CustomFields');

        this.showManagement =
            this.showUserManagement === true ||
            this.showClauseManager === true ||
            this.showPlaybookManager === true ||
            this.showPlaybooks === true;

        this.showAnalytics = this.isFullUser;

        this.showSettings = this.userService.getIsAdmin();

    }

    private shouldBeVisibleForRestrictedBusinessUsers(flag: string): boolean {

        const hasFullClaim = this.hasClaims(['CanTenantSettingsIntegrationRead']);

        if (hasFullClaim === true) {

            return true;

        }

        if (hasFullClaim == false) {

            const hasRestrictedFlag = this.hasFeatureFlag(flag);

            if (hasRestrictedFlag !== undefined && hasRestrictedFlag === true) {

                return false;
            }

        }

        return true;

    }

}
