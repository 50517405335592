import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlockButtonModule, IconModule, LoadingSpinnerModule } from '@summize/shared/components-v2';

import { OpenAiTldrPanelComponent } from './openai-tldr-panel.component';
import { OpenAiTldrPanelService } from './openai-tldr-panel.service';

@NgModule({
    imports: [
        CommonModule,
        BlockButtonModule,
        IconModule,
        LoadingSpinnerModule
    ],
    exports: [
        OpenAiTldrPanelComponent
    ],
    declarations: [
        OpenAiTldrPanelComponent
    ],
    providers: [
        OpenAiTldrPanelService
    ]
})
export class OpenAiTldrPanelModule { }

export * from './openai-tldr-panel.component';