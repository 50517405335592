import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { EMPTY_GUID } from '@summize/shared/core';
import { OutboundIntegrationTypes } from '@summize/shared/framework';

import { CalendarEventNotificationTargetsService } from './calendar-event-notification-targets.service';
import { AutocompleteChipGroupModel, AutocompleteGroupModel } from '../autocomplete-chips-groups/autocomplete-chips-groups.module';
import { NotificationItem } from './calendar-event-notification-targets.models';

const supportedOutboundIntegrationTypes = [
    OutboundIntegrationTypes.Webhook,
    OutboundIntegrationTypes.Teams,
    OutboundIntegrationTypes.Slack,
    OutboundIntegrationTypes.Email
];

@Component({
    selector: 'app-calendar-event-notification-targets',
    templateUrl: './calendar-event-notification-targets.html',
    styleUrl: './calendar-event-notification-targets.scss'
})
export class CalendarEventNotificationTargetsComponent implements OnInit {

    @Input()
    public value: NotificationItem[] = [];

    @Output()
    public valueChange = new EventEmitter<NotificationItem[]>();

    public loading = true;

    public notificationTargetGroups: AutocompleteGroupModel[] = [];

    public notificationTargets: AutocompleteChipGroupModel[] = [];

    public selectedNotificationTargets: AutocompleteChipGroupModel[] = [];

    public refreshSelectedNotificationTargets: Subject<AutocompleteChipGroupModel[]> = new Subject();

    constructor(public service: CalendarEventNotificationTargetsService) { }

    public async ngOnInit() {

        this.loading = true;

        await this.loadNotificationTargets();

        this.loading = false;

    }

    public async loadNotificationTargets() {

        this.notificationTargetGroups = [
            { "key": "user", "value": "User" },
            { "key": "group", "value": "Group" },
            { "key": "1", "value": "Webhook" },
            { "key": "2", "value": "Teams" },
            { "key": "3", "value": "Slack" },
            { "key": "4", "value": "Email" },
        ];

        const [notificationTargets, users, groups] = await Promise.all([
            this.service.getNotificationTargets(),
            this.service.getLegalAndBusinessUsers(''),
            this.service.getGroups('')
        ]);

        this.notificationTargets = notificationTargets
            .filter(x => supportedOutboundIntegrationTypes.includes(x.outboundIntegrationType))
            .map(x => ({
                key: x.id,
                value: x.displayName,
                groupKey: '2'
            } as AutocompleteChipGroupModel));

        if (users?.users?.length > 0) {
            this.notificationTargets.push(...users.users.map(x => ({
                key: x.userId,
                value: `${x.firstName} ${x.lastName} (${x.email})`,
                groupKey: 'user'
            })));
        }

        if (groups?.groups?.length > 0) {
            this.notificationTargets.push(...groups.groups.map(x => ({
                key: x.groupId,
                value: x.groupName,
                groupKey: 'group'
            })));
        }

        // Only show notification targets groups for those notification areas that have at least one notification target.
        this.notificationTargetGroups = this.notificationTargetGroups
            .filter(x => this.notificationTargets.map(y => y.groupKey).includes(x.key));

        const selectedNotificationTargets = this.notificationTargets
            .filter(x => this.value.map(y => y.notificationEntityId).includes(x.key));

        this.setSelectedNotificationTargets(selectedNotificationTargets);
        this.updatedNotificationTargets(this.selectedNotificationTargets);
    }

    public setSelectedNotificationTargets(selectedNotificationTargets: AutocompleteChipGroupModel[]) {

        this.selectedNotificationTargets = selectedNotificationTargets;
        this.refreshSelectedNotificationTargets.next(this.selectedNotificationTargets);

    }

    public updatedNotificationTargets(selectedNotificationTargets: AutocompleteChipGroupModel[]) {

        const existingNotifications = this.value || [];

        const selectedIds = selectedNotificationTargets.map(x => x.key);
        const existingIds = existingNotifications.map(x => x.id);

        // Remove ids that are not selected
        const notifications = existingNotifications.filter(x => selectedIds.includes(x.id));

        // Add ids that are selected
        const newNotificationTargets = selectedNotificationTargets.filter(x => !existingIds.includes(x.key));
        notifications.push(...newNotificationTargets.map(x => ({
            id: EMPTY_GUID,
            notificationEntityType: this.notificationTargetTypeToNotificationEntityType(x.groupKey),
            notificationEntityId: x.key,
        })));

        this.value = notifications;

        this.valueChange.emit(this.value);

    }

    private notificationTargetTypeToNotificationEntityType(key: string): number {

        if (key === 'user') return 1;
        if (key === 'group') return 2;

        return 0;

    }

}