import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { ClientMatterSelectorComponent } from './client-matter-selector.component';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    PipesModule,
  ],
  exports: [
    ClientMatterSelectorComponent
  ],
  declarations: [
    ClientMatterSelectorComponent
  ]
})
export class ClientMatterSelectorModule { }

export * from './client-matter-selector.component'
