import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { MatRadioModule } from '@angular/material/radio';

import { RadioButtonGroupComponent } from './radio-button-group.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatRadioModule
    ],
    exports: [
        RadioButtonGroupComponent
    ],
    declarations: [
        RadioButtonGroupComponent
    ]
})
export class RadioButtonGroupModule { }

export * from './radio-button-group.component';