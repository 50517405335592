import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';

import { ReviewDocumentVersion } from '../download-documents/download-documents.service';

@Injectable({ providedIn: 'root' })
export class SendForSignatureAutoPanelService extends BaseTenantService {

    constructor(private http: HttpClient) {
        super();
    }

    public async getDocumentVersions(documentId: string): Promise<Array<ReviewDocumentVersion>> {

        const url = `${this.getUserBaseUrl(true)}documents/${documentId}/review/versions`;

        return await this.http.get(url).toPromise() as any;

    }
 
    public async sendForSignature(request: any): Promise<any> {

        const url = `${this.getUserBaseUrl(true)}esignatures/autosend`;

        return await this.http.post<any>(url, request).toPromise();
    }
}