<div *ngIf="type === 'full'" class="metric">
    <div class="title">
        {{metric.title}}
        <ng-container *ngIf="hasDrilldown === true">
            <app-icon icon="arrow-forward-outline"></app-icon>
        </ng-container>
    </div>
    <div class="value">
        {{metric.displayValue}}
    </div>

    <ng-container *ngIf="isAllDisplay === false">
        <div *ngIf="metric.inverse === false" class="trend {{getTrendDirection(metric.trendDirection)}}">

            <ng-container *ngIf="metric.value !== metric.previousValue">

                <div class="trend-value" *ngIf="metric.trendDirection === TrendDirection.Up">
                    <app-icon [icon]="'arrow-up-outline'"></app-icon>
                    <div>{{metric.trendValue}}</div>
                </div>

                <div class="trend-value" *ngIf="metric.trendDirection === TrendDirection.Down">
                    <app-icon [icon]="'arrow-down-outline'"></app-icon>
                    <div>{{metric.trendValue}}</div>
                </div>

            </ng-container>

            <ng-container *ngIf="metric.value === metric.previousValue">
                <ng-container *ngTemplateOutlet="levelTrend"></ng-container>
            </ng-container>

            <div [matTooltip]="getValueTooltip(metric)" class="range">
                {{metric.trendRange}}
            </div>

        </div>

        <div *ngIf="metric.inverse === true" class="trend inverse {{getTrendDirection(metric.trendDirection)}}">

            <ng-container *ngIf="metric.value !== metric.previousValue">

                <div class="trend-value" *ngIf="metric.trendDirection === TrendDirection.Up">
                    <app-icon [icon]="'arrow-down-outline'"></app-icon>
                    <div>{{metric.trendValue}}</div>
                </div>

                <div class="trend-value" *ngIf="metric.trendDirection === TrendDirection.Down">
                    <app-icon [icon]="'arrow-up-outline'"></app-icon>
                    <div>{{metric.trendValue}}</div>
                </div>

            </ng-container>

            <ng-container *ngIf="metric.value === metric.previousValue">
                <ng-container *ngTemplateOutlet="levelTrend"></ng-container>
            </ng-container>

            <div [matTooltip]="getValueTooltip(metric)" class="range">
                {{metric.trendRange}}
            </div>

        </div>
    </ng-container>

</div>

<div *ngIf="type === 'compact'" class="metric compact">
    <div class="compact-value">
        {{metric.value}} <span>{{metric.slug}}</span>
    </div>
</div>

<ng-template #levelTrend>
    <div class="trend-value neutral">
        <app-icon [icon]="'arrow-forward-outline'"></app-icon>
        <div>~</div>
    </div>
</ng-template>