<div>
  <span *ngIf="icon">
    <i class="{{icon}}"></i>
  </span>

  <p *ngIf="display">
    {{display}}
  </p>

  <div *ngIf="text">
    {{text}}
  </div>
</div>
