import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

const sleeper = ms => new Promise(res => setTimeout(res, ms));

export interface Authentication {
    user: any;
    token: string;
}

export interface AuthServiceBase {
    init();

    isAuthenticated$: Observable<boolean>;

    isAuthenticated();
    login(values?: LoginValues);
    logout();
}

export interface LoginValues {
    scheme?: string;
    redirectUrl?: string;
}