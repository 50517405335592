import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { AppComponent } from '@summize/shared/core';
import { EventService, RequestSubtype, TaskDefinitionModel } from '@summize/shared/framework';
import { MatDialog } from '@angular/material/dialog';

import { UserTaskService } from './user-task.service';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

export enum AssingmentStatus {
    User,
    All
}

@Component({
    selector: 'app-user-tasks',
    templateUrl: 'user-tasks.html',
    styleUrls: ['./user-tasks.scss']
})
export class UserTasksComponent extends AppComponent implements OnInit {

    public AssingmentStatus = AssingmentStatus;

    @Output()
    public onDocumentClick: EventEmitter<any>
        = new EventEmitter<any>();

    @Input()
    public requestSubType: RequestSubtype;

    @Input()
    public displayAsComponent = false;

    @Input()
    public documentId;

    public buckets: {
        0: Array<TaskDefinitionModel>,
        1: Array<TaskDefinitionModel>
    } = { 0: [], 1: [] }

    public selectedStatus:
        AssingmentStatus = AssingmentStatus.User;

    public isLoading: boolean = true;

    public title: string;

    constructor(private service: UserTaskService, private events: EventService, private dialog: MatDialog) {

        super();

    }

    public async ngOnInit(): Promise<void> {

        if (this.displayAsComponent === true) {

            await this.init();

        }

    }

    public async init(): Promise<void> {

        this.isLoading = true;

        this.title = this.requestSubType === RequestSubtype.Manage ? 'Repository tasks' : 'Request tasks';

        if (this.displayAsComponent === false) {

            const result = await this.service.getTaskInstancesForUser(this.requestSubType, this.documentId);

            this.buckets[AssingmentStatus.User] = result.userTasks;

            this.buckets[AssingmentStatus.All] = result.allTasks;

        }
        else {

            const [request, repo] = await Promise.all([
                this.service.getTaskInstancesForUser(RequestSubtype.Review, this.documentId),
                this.service.getTaskInstancesForUser(RequestSubtype.Manage, this.documentId)
            ]);

            this.buckets[AssingmentStatus.User] = [...request.userTasks, ...repo.userTasks];

            this.buckets[AssingmentStatus.All] = [...request.allTasks, ...repo.allTasks];

        }


        this.isLoading = false;
    }

    public async complete(task: TaskDefinitionModel): Promise<any> {

        const [contract, tasks] = await Promise.all([
            this.service.getContract(task.documentId),
            this.service.getTaskInstancesForDocument(task.documentId)
        ]);

        const isLastMandatoryTask = this.isLastMandatoryTask(task, tasks);

        const data = {
            header: 'Complete task',
            text: this.getCompleteModalMessage(task, contract, tasks),
            confirmButton: 'Complete task'
        };

        const confirmComplete = this.dialog.open(ConfirmationModalComponent, { data: data });

        this.subscribe(confirmComplete.afterClosed(), async confirm => {

            if (confirm === true) {

                this.isLoading = true;

                await this.service.completeTaskInstance(task);

                if (this.canAutoSendForSignature(isLastMandatoryTask, contract) === true) {

                    await this.service.sendForSignature(contract.documentId);

                }

                this.events.actionComplete('Task complete');

                await this.init();

            }

        });

    }

    private getCompleteModalMessage(task: any, contract, tasks) {

        const isLastMandatoryTask = this.isLastMandatoryTask(task, tasks);

        if (isLastMandatoryTask) {

            if (this.hasFeatureFlag('AutoSendForSignature') && contract.eSignatureAutoSendDetail?.isEnabled) {

                return 'This is the last remaining required task. Completing this task will change the request status to Sent for Signature, notify the requestor and send for signature';

            } else if (contract.eSignatureAutoSendDetail?.isEnabled === false) {

                return 'This is the last remaining required task. Completing this task will change the request status to Completed and notify the requestor';

            }
        }

        return 'Please confirm you want to complete this task';

    }

    private isLastMandatoryTask(task: any, allTasks: Array<any>): boolean {

        const remainingRequiredTasks = allTasks.filter(x => x.task.isMandatory === true && x.task.isComplete === false);

        if (remainingRequiredTasks.length === 1) {

            return task.task.taskInstanceId === remainingRequiredTasks[0].task.taskInstanceId;

        }

        return false;

    }

    private canAutoSendForSignature(isLastMandatoryTask: boolean = false, contract): boolean {

        return contract.requestSubType === RequestSubtype.Create &&
            isLastMandatoryTask === true &&
            this.hasFeatureFlag('AutoSendForSignature') &&
            contract.eSignatureAutoSendDetail?.isEnabled === true;
    }

}