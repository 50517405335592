<app-dialog-shell [showHeader]="true" [showClose]="false" [headerTemplate]="headerTemplate" (onClose)="closeAndCheckLater()">

    <ng-template #headerTemplate>
        <div class="header-container">
            <div class="header-body">
                <div class="page1">
                    <img class="img" src="./assets/images/request-analytics-has-moved.svg" />
                </div>
            </div>
        </div>
    </ng-template>

    <div class="content-area">
        <div class="page1">
            <span class="header mb-24">
                <h2>Request Analytics have moved!</h2>
            </span>

            <p class="mb-24">Out with the old, in with the new. We've said goodbye to our outdated widgets to make way
                for our new Request Analytics charts and for some more enhancements coming soon! You can now find your
                dashboards under the 'Analytics' feature in the main navigation.</p>

            <div>
                <mat-checkbox [checked]="showAgain !== true" (change)="showAgain = !$event.checked" class="mb-24">
                    Got it! I know where to find Request Analytics.
                </mat-checkbox>
            </div>
        </div>
    </div>
    <div class="commands">

        <app-block-button class="mr-a link" [disabled]="showAgain === true || disableClick === true" (click)="closeAndGoToAnalytics()"
            display="Go to Analytics" type="v2-blue" width="150" data-cy="modal-next">
        </app-block-button>

        <app-block-button *ngIf="hideCheckLater === false" class="link"
            [disabled]="showAgain === true || disableClick === true" (click)="closeAndCheckLater()" display="I'll check later"
            type="v2-primary" width="180" data-cy="modal-confirm">
        </app-block-button>
    </div>
</app-dialog-shell>