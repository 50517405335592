import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mapproperty'
})
export class MapPropertyPipe implements PipeTransform {

    transform(value: Array<any>, property: string): Array<any> {

        if (value && value.length > 0) {

            return value.map(x => x[property]);

        }

        return value;

    }

}
