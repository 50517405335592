import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-note-input',
    templateUrl: 'note-input.html',
    styleUrl: 'note-input.scss'
})
export class NoteInputComponent implements AfterViewInit, OnInit {

    @Input()
    public text: string | null;

    @Output()
    public onCancel = new EventEmitter();

    @Output()
    public onSave = new EventEmitter<string>();

    @ViewChild('noteTextarea')
    textarea!: ElementRef;

    public isValid = false;

    public saveDisabled: boolean = false;

    private initialText: string | null;

    public ngOnInit(): void {

        this.initialText = this.text;

        this.validate();

    }

    public ngAfterViewInit(): void {

        setTimeout(() => {

            this.adjustTextareaHeight();
            this.textarea.nativeElement.focus();

        }, 0);

    }

    public input = () => {

        this.validate();
        this.adjustTextareaHeight();

    }

    public cancel = () => {

        this.onCancel.emit();

    }

    public save = () => {

        this.validate();

        if (!this.isValid) {

            return;

        }

        this.saveDisabled = true;
        this.onSave.emit(this.text);

    }

    private validate = () => {

        this.isValid = this.text?.trim().length > 0 && this.text !== this.initialText;

    }

    private adjustTextareaHeight(): void {

        const textarea = this.textarea.nativeElement;
        textarea.style.height = '0';
        textarea.style.height = `${textarea.scrollHeight}px`;

    }

}