<div [ngClass]="isWordAddIn ? 'toast-container-word-add-in' : 'toast-container'" [@fadeInAnimation]>
    <div class="toast block {{type}}">
        <div class="toast-icon">
            <app-icon icon="{{icon || 'checkmark-outline'}}"></app-icon>
        </div>
        <h5 class="toast-text">{{text}}</h5>

        <div class="toast-close">
            <svg class="toast-close-progress" width="32" height="32">
                <circle pathLength="100" class="toast-close-progress-circle" fill="transparent" r="13" cx="50%"
                    cy="50%" />
            </svg>
            <app-icon (click)="close()" icon="close-outline"></app-icon>
        </div>
    </div>
</div>