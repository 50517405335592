import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AppComponent } from '@summize/shared/core';
import { WidgetType } from '@summize/shared/framework';

import { WidgetLibraryService } from './widget-library.service';

export enum SelectedWidgetCategoryTab {
    Performance = 1,
    Volume = 2,
    Pending = 3
}

@Component({
    selector: 'app-widget-library',
    templateUrl: 'widget-library.html',
    styleUrl: './widget-library.scss',
    encapsulation: ViewEncapsulation.None
})
export class WidgetLibraryComponent extends AppComponent implements OnInit {

    public SelectedWidgetCategoryTab = SelectedWidgetCategoryTab;

    public selectedTab: SelectedWidgetCategoryTab;

    public widgets: any;

    public dashboardId: string;

    public isLoading = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private service: WidgetLibraryService) {
        super();
    }

    public async ngOnInit() {

        this.subscribe(this.route.queryParams, async (args) => {

            if (args['dashboardId'] !== undefined) {

                this.dashboardId = args['dashboardId'];

            }
        });

        this.selectedTab = SelectedWidgetCategoryTab.Performance;

        await this.getWidgets();

    }

    public onBack() {

        this.router.navigate(['quick-summary', 'dashboards', this.dashboardId]);

    }

    public async setSelectedTab(tab: SelectedWidgetCategoryTab) {

        if (this.selectedTab !== tab) {

            this.selectedTab = tab;

            await this.getWidgets();
        }

    }

    public getChartElementForWidget(widget: any) {

        const chartElement = widget.elements?.find(x => x.widgetType === WidgetType.Chart);

        if (chartElement) {

            return chartElement;
        }

    }

    public addToDashboard(widgetId: string) {

        this.router.navigate(['quick-summary', 'dashboards', this.dashboardId], { queryParams: { widgetId: widgetId } });

    }

    private async getWidgets() {

        this.isLoading = true;

        this.widgets = await this.service.getWidgets(this.selectedTab);

        this.isLoading = false;

    }

}