import { AuthUserService } from './../../services/auth-user.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthOidcService } from './../../services/auth-oidc.service';

@Component({
    selector: 'app-oidc-authenticated',
    templateUrl: './oidc-authenticated.component.html',
    styleUrls: ['./oidc-authenticated.component.scss']
})
export class OidcAuthenticatedComponent implements OnInit {
    redirectButtonHidden = true;

    constructor(
        private router: Router,
        private authOidcService: AuthOidcService,
        private authUserService: AuthUserService,
    ) { }

    public async ngOnInit() {
        await this.authOidcService.init();

        if (!this.authOidcService.isAuthenticated()) {
            await this.authOidcService.completeAuthentication();
        }

        const isAuthenticated = this.authOidcService.isAuthenticated();
        setTimeout(() => this.redirectButtonHidden = false, 10000);

        if (isAuthenticated) {

            this.router.navigate(['/']);

        } else {

            await this.authOidcService.logout();
            this.router.navigate(['/']);

        }
    }

    public retryClicked() {

        this.authUserService.purgeUserDetails();
        window.location.href = '/';

    }
}