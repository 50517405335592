import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { DirectivesModule, IconModule } from '@summize/shared/components-v2';

import { SideBarComponent } from './side-bar.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IconModule,
        DirectivesModule
    ],
    exports: [
        SideBarComponent
    ],
    declarations: [
        SideBarComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class SideBarModule { }

export * from './side-bar.component'