import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectionalArrowButtonComponent } from './directional-arrow-button.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        DirectionalArrowButtonComponent
    ],
    declarations: [
        DirectionalArrowButtonComponent
    ]
})
export class DirectionalArrowButtonModule { }

export * from './directional-arrow-button.component'