<div class="container">

    @if (expanded && (notes.length > 0 || showAdd)) {
    <button (click)="toggleExpanded(false)" [disabled]="IsEditMode" class="toggle-btn">
        Hide notes
        <app-icon icon="chevron-up-outline"></app-icon>
    </button>
    }

    @if(!expanded && notes.length > 0)
    {
    <button (click)="toggleExpanded(true)" [disabled]="IsEditMode" class="toggle-btn">
        Show notes
        <app-icon icon="chevron-down-outline"></app-icon>
    </button>
    }

    @if(!expanded && notes.length === 0 && completionNote && taskComplete){
        <div class="completion-notes">
            <span>Completion notes</span>
            <p>{{ completionNote }}</p>
        </div>
    }

    @if (expanded) {
    <div class="notes-container">
        @for (note of notes; track note.id) {
        <div class="note">
            <div class="note-header">
                <span class="note-details">
                    {{note.author}} - {{note.date | smzdate: 'medium'}}
                </span>
                @if(note.editable){
                <div class="note-actions">
                    @if (note?.editing !== true){
                    <button class="action-btn" (click)="toggleEditing(note, true)">
                        <span class="sr-only">Edit</span>
                        <app-icon icon="create-outline"></app-icon>
                    </button>
                    }
                    <button class="action-btn" (click)="delete(note.id)">
                        <span class="sr-only">Delete</span>
                        <app-icon icon="trash-outline"></app-icon>
                    </button>
                </div>
                }
            </div>
            @if (note.editing){
            <app-note-input [text]="note.text" (onCancel)="toggleEditing(note, false)"
                (onSave)="update($event, note)"></app-note-input>
            }
            @else {
            <div class="note-text">{{note.text}}</div>
            }
        </div>
        }

        @if (showAdd){
        <app-note-input (onCancel)="cancelAdd()" (onSave)="add($event)"></app-note-input>
        }

        <div *ngIf="completionNote && taskComplete" class="completion-notes">
            <span>Completion notes</span>
            <p>{{ completionNote }}</p>
        </div>

    </div>
    }

</div>