import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthService } from './../../services/auth.service';
import { AuthUserService } from '../../services/auth-user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  user: string = 'Please wait...';
  isAuthenticated = false;
  private isAuthenticatedSub: Subscription;
  private userSub: Subscription;

  constructor(
    private authService: AuthService,
    private userService: AuthUserService
  ) {
    this.isAuthenticatedSub = this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
    });

    this.userSub = this.userService.user.subscribe((user) => {
      if (user) {
        this.user = user.email;
      } else {
        this.user = 'Please wait...';
      }
    })
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.isAuthenticatedSub.unsubscribe();
    this.userSub.unsubscribe();
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }
}
