import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsageMetricComponent } from './usage-metric.component';
import { InfoPointModule } from '../info-point/info-point.module';

@NgModule({
    imports: [
        CommonModule,
        InfoPointModule
    ],
    exports: [
        UsageMetricComponent
    ],
    declarations: [
        UsageMetricComponent
    ]
})
export class UsageMetricModule { }

export * from './usage-metric.component'