<div class="table-container table-responsive">
    <ng-container *ngIf="isReloading === false">
        <table (mouseleave)="selectedIndex = undefined" mat-table [class.can-edit]="datasource.rowEdit"
            [class.floating-rows]="datasource.floatingRows" [dataSource]="data" matSort
            [matSortActive]="datasource.default.sort.field" [matSortDisabled]="datasource.disableSort === true"
            matSortDisableClear [matSortDirection]="datasource.default.sort.dir" recycleRows>

            <ng-container *ngFor="let c of columns; let first = first">

                <ng-container *ngIf="isCustomTemplate(c) === false" [matColumnDef]="c"
                    [sticky]="c === 'select' ? true:getColumnSticky(c)">

                    <ng-container *ngIf="c !== 'select'">

                        <th class="fade-block" mat-header-cell [disabled]="isColumnSortingEnabled(c)" mat-sort-header
                            *matHeaderCellDef>
                            {{getColumnHeader(c)}}
                        </th>

                        <ng-container></ng-container>

                        <td class="fade-block" mat-cell *matCellDef="let row"
                            [class.selected]="selection.isSelected(row)">

                            <ng-container *ngIf="hasColumnTemplate(c) === true">
                                <ng-container
                                    *ngTemplateOutlet="getColumnTemplate(c); context: { $implicit: row, column:c, header:getColumnHeader(c) }">
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="hasColumnTemplate(c) === false">
                                {{get(row, c)}}
                            </ng-container>

                        </td>
                    </ng-container>

                    <ng-container *ngIf="c === 'select'">

                        <th class="select-cell" mat-header-cell *matHeaderCellDef>
                            <mat-checkbox
                                *ngIf="datasource.disableSelectAll === undefined || disableSelectAll === false"
                                (change)="$event ? masterToggle() : null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                data-cy="select-all-contracts">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row" [class.selected]="selection.isSelected(row)">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                            </mat-checkbox>
                        </td>

                    </ng-container>

                </ng-container>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>

            <tr mat-row class="remove-background {{row.__class}}" (click)="onEditRow(row)"
                (mouseover)="selectedIndex = i" [ngClass]="{'hover' : i === selectedIndex }"
                *matRowDef="let row let i = index; columns: columns;">
            </tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="99">
                    <div class="no-results body-regular">
                        <img src="assets/images/table__no-results.svg" />
                        <div class="body-regular" data-cy="no-results">
                            No Results
                        </div>
                    </div>
                </td>
            </tr>

        </table>

        <div *ngIf="initialLoad === true || isLoading === true"
            [style.min-height]="height > 0 ? (height - 150) + 'px' : 'auto'" class="loader table-loader">
            <app-loading-spinner visible="true"></app-loading-spinner>
        </div>

    </ng-container>


</div>
<mat-paginator class="remove-background" [length]="resultsLength"
    [pageSizeOptions]="getPageSizeOptions()"></mat-paginator>