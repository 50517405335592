<ng-container *ngIf="status !== undefined && selected !== undefined">
    <div [class.readonly]="readonly === true" [matMenuTriggerFor]="infoMenu" class="status-info clickable">
        <ng-container>
            <div class="indicators">
                <ng-container>
                    <div class="indicator" [style.background-color]="selected.background">
                        &nbsp;
                    </div>
                </ng-container>
            </div>
            <div class="display">
                {{selected.statusName}}
            </div>
            <ng-container *ngIf="readonly === false">
                <div class="icon">
                    <app-icon icon="chevron-down-outline"></app-icon>
                </div>
            </ng-container>
        </ng-container>
    </div>

    <mat-menu #infoMenu="matMenu" [hasBackdrop]="true" class="status-select-popup-container">
        <ng-template matMenuContent let-row="row">
            <div class="status-select-popup">
                <div (click)="onStatusChanged.emit(item)" *ngFor="let item of available" class="item clickable">
                    <div [style.background]="item.background" class="indicator">
                        &nbsp;
                    </div>
                    <div class="text">
                        {{item.statusName}}
                    </div>
                </div>
            </div>
        </ng-template>
    </mat-menu>
</ng-container>