import { Component } from '@angular/core';

@Component({
  selector: 'app-chat-welcome',
  templateUrl: './chat-welcome.component.html',
  styleUrls: ['./chat-welcome.component.scss'],
})
export class ChatWelcomeComponent {

  constructor() { }

}
