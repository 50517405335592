<div [class]="'container ' + style">
    <app-icon icon="time" class="icon time-icon"></app-icon>

    <span [matTooltip]="tooltip ?? tooltip" matTooltipClass="smz-tooltip-light" class="due-date">
        {{ dueDateText }}
    </span>

    @if (scheduleSummary !== undefined) {
    <app-icon icon="repeat-outline" class="icon repeat-icon"></app-icon>
    <span class="schedule-summary">
        {{ scheduleSummary }}
    </span>
    }
</div>