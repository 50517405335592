import { Component, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectBoxModel } from '@summize/shared/components-v2';

import { ClauseMetricDefinition, CurrencyMask, DurationMask, GraphSize, SpecialTypes } from '../../analytics.types';

@Component({
    selector: 'app-configure-metric',
    templateUrl: 'configure-metric.html',
    styleUrls: ['./configure-metric.scss']
})
export class ConfigureMetricComponent {

    public SpecialTypes = SpecialTypes;

    public definition!: ClauseMetricDefinition;

    public sizes: Array<SelectBoxModel> = [
        { key: GraphSize.Regular.toString(), value: 'Regular' },
        { key: GraphSize.Large.toString(), value: 'Large' }
    ];

    public durationMask: Array<SelectBoxModel> = [
        { key: DurationMask.Days.toString(), value: 'Days' },
        { key: DurationMask.Weeks.toString(), value: 'Weeks' },
        { key: DurationMask.Months.toString(), value: 'Months' }
    ];

    public currencyMask: Array<SelectBoxModel> = [
        { key: CurrencyMask.Exact.toString(), value: 'Exact' },
        { key: CurrencyMask.Rounded.toString(), value: 'Rounded' }
    ];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ConfigureMetricComponent>) {

        this.definition
            = JSON.parse(JSON.stringify(data.definition));

    }

    public close() {

        this.dialogRef.close(undefined);

    }

    public save() {

        this.dialogRef.close(this.definition);

    }

}