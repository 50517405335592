<div [class.legacy-style]="useLegacyStyle">

    @for (recipient of recipients; track recipient.tokenId; let i = $index) {

    @if (recipient.visible === false) {
    continue;
    }

    <div class="recipient">
        <div class="mb-3">
            <label [for]="'email' + i" class="input-label required">
                Enter the email for {{ recipient.tokenId }}
            </label>
            <input [(ngModel)]="recipient.email" [id]="'email' + i"
                [class.error]="recipient?.emailInvalid === true || recipient?.emailExists === true"
                placeholder="Email address" type="email" 
                [class]="useLegacyStyle ? 'smz-standard-input v2' : 'smz-standard-input v3'" maxlength="200"
                (keyup)="onKeyUp(recipient)" (blur)="onKeyUp(recipient)" />

            @if(recipient?.emailInvalid === true) {
            <small class="helper-text error">
                Enter a valid email.
            </small>
            }

            @if(recipient?.emailExists === true) {
            <small class="helper-text error">
                Email already entered.
            </small>
            }

        </div>
        <div class="mb-3">
            <label [for]="'name' + i" class="input-label required">
                Enter the full name for {{ recipient.tokenId }}
            </label>
            <input [(ngModel)]="recipient.name" [id]="'name' + i" [class.error]="recipient?.nameInvalid === true"
                placeholder="Full name" type="text" 
                [class]="useLegacyStyle ? 'smz-standard-input v2' : 'smz-standard-input v3'" maxlength="200"
                (keyup)="onKeyUp(recipient)" (blur)="onKeyUp(recipient)" />

            @if(recipient?.nameInvalid === true){
            <small class="helper-text error">
                Enter a valid name.
            </small>
            }

        </div>
    </div>

    }

</div>

@if(recipients.length === 0) {
<div class="recipients-empty">
    There were no {{ recipientOrigin === recipientOriginEnum.Internal ? 'internal' : 'counterparty' }} recipients
    detected.
</div>
}