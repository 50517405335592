import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { Environment, SummizeStorage } from '@summize/shared/framework';
import { SideBarModule } from '@summize/admin';
import { BaseTenantService } from '@summize/shared/core';
import { CorePipesModule } from '@summize/shared/components-v2';

import { LogoutOidcComponent } from './components/logout-oidc/logout-oidc.component';
import { LoginOidcComponent } from './components/login-oidc/login-oidc.component';
import { CommonComponentsModule } from './components/commoncomponents.module';
import { OidcAuthenticatedComponent } from './components/oidc-authenticated/oidc-authenticated.component';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './services/auth.service';
import { HeaderComponent } from './components/header/header.component';
import { BoolToStringModule } from './common/pipes/bool-to-string.module';

import { getLocale } from './common/utilities/locale.helper';
import { environment } from '../environments/environment';

export function initialiseAuth(authService: AuthService) {
    return (): Promise<any> => {
        return authService.init();
    }
}

const envFactory = () => {

    SummizeStorage.setSessionItem(BaseTenantService.ENV_KEY, JSON.stringify(environment));

    return environment;

}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        OidcAuthenticatedComponent,
        LoginOidcComponent,
        LogoutOidcComponent,
    ],
    bootstrap: [AppComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        MatToolbarModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        BoolToStringModule,
        MatSidenavModule,
        MatDialogModule,
        MatNativeDateModule,
        CommonComponentsModule,
        SideBarModule,
        CorePipesModule], providers: [
            {
                provide: HTTP_INTERCEPTORS,
                useClass: AuthInterceptorService,
                multi: true
            },
            { provide: MAT_DATE_LOCALE, useFactory: getLocale },
            { provide: LOCALE_ID, useFactory: getLocale },
            {
                provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {
                    hasBackdrop: true,
                    disableClose: true,
                    width: '600px'
                }
            },
            {
                provide: APP_INITIALIZER,
                useFactory: initialiseAuth,
                deps: [AuthService],
                multi: true
            },
            { provide: Environment, useFactory: envFactory },
            provideHttpClient(withInterceptorsFromDi())
        ]
})
export class AppModule { }
