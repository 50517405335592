import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { EventService } from '@summize/shared/framework';

import { OAiError, OpenAiTldrPanelService } from './openai-tldr-panel.service';

import { Clause } from './openai-tldr-panel.service';

@Component({
    selector: 'app-openai-tldr-panel',
    templateUrl: 'openai-tldr-panel.html',
    styleUrls: ['./openai-tldr-panel.scss']
})
export class OpenAiTldrPanelComponent implements OnInit {

    @Input()
    public documentId: string;

    @Input()
    public clause: Clause;

    @Input()
    public clientId: string;

    @Input()
    public matterId: string;

    @Output()
    public onClosePanel: EventEmitter<void>;

    public isQuickSummary = false;

    public isLoading = false;

    public error = false;

    public errorMessage: string;

    public answer: any;

    public ruleResults: string;

    constructor(
        private service: OpenAiTldrPanelService,
        private events: EventService) {

        this.onClosePanel = new EventEmitter();
    }

    public async ngOnInit(): Promise<void> {

        this.isLoading = true;

        await this.load();

        this.isLoading = false;

    }

    public async getSummary() {

        this.error = false;

        this.errorMessage = '';

        this.isLoading = true;

        const text = this.ruleResults
            .replace(/\n/g, ' ')
            .trim();

        const response = await this.service.getTldr(text);

        this.validateOAiResponse(response);

        this.answer = response?.answer || '';

        this.isLoading = false;

    }

    public async replaceSummary() {

        this.isLoading = true;

        const response = await this.service.replaceClauseTexts(
            this.clause,
            this.clientId,
            this.matterId,
            this.documentId,
            this.answer
        );

        if (response.clausesTextUpdateFailures.length === 0 && response.clausesTextUpdateFailures.length === 0) {

            this.events.actionComplete(`Replaced clause summary for ${this.clause.ruleName}`);

        } else {

            this.events.actionFailed(`Failed to replace one or more bullets for ${this.clause.ruleName}`);
        }

        this.isLoading = false;

        this.onClosePanel.next();

    }

    private async load(): Promise<void> {

        this.clause = Object.assign({}, this.clause);

        this.buildRuleResults();

        await this.getSummary();

    }

    private validateOAiResponse(response) {

        if (response.error != null) {

            this.error = true;

            if (response.error === OAiError.AboveCapacity) {

                this.errorMessage = `OpenAI was unable to generate a summary for ${this.clause.ruleName}. OpenAI is currently above capacity. Please try again later.`;

            } if (response.error === OAiError.MinLength) {

                this.errorMessage = `OpenAI was unable to generate a summary for ${this.clause.ruleName}. The clause text is below the minimum length of 128 characters.`;

            } if (response.error === OAiError.Generic || response.answer === '') {

                this.errorMessage = `OpenAI was unable to generate a summary for ${this.clause.ruleName}. Try using shorter clause text for better results.`;

            }

        }

    }

    private buildRuleResults() {

        if (this.clause.ruleResult.length === 0) {

            this.ruleResults = '';
        } else {

            this.ruleResults = this.clause.ruleResult
                .map(x => x.resultText)
                .join(`'\n\n'`);
        }

    }

}