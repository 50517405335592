import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlertBannerComponent } from './alert-banner.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
    imports: [
        CommonModule,
        IconModule
    ],
    exports: [
        AlertBannerComponent
    ],
    declarations: [
        AlertBannerComponent
    ]
})
export class AlertBannerModule { }

export * from './alert-banner.component';