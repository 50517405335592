import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { PDFViewerComponent } from './pdf-viewer.component';

@NgModule({
    imports: [
        CommonModule,
        LoadingSpinnerModule
    ],
    exports: [
        PDFViewerComponent
    ],
    declarations: [
        PDFViewerComponent
    ]
})
export class PDFViewerModule { }

export * from './pdf-viewer.component';
export * from './pdf-viewer.helper';