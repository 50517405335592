<div class="smz-flex-area">
    <div class="container-fluid mx-0 my-0">
        <div class="row">
            <div [@fadeInAnimation] class="col-12 px-0 py-0">
                <div class="title-bar fade-block">
                    <div class="avatar">
                        <app-avatar [title]="user?.email" [name]="name" [size]="avatarSize" bgColor="#0066FF"
                            class="avatar">
                        </app-avatar>
                    </div>
                    <div class="text">
                        {{name}} <span class="level"> {{level}}</span>
                    </div>
                    <div (click)="close()" class="close clickable">
                        <app-icon class="align-self-end align-self-center" icon="close-circle"></app-icon>
                    </div>
                </div>
                <div class="col-12 px-0 py-0 fade-block">
                    <div class="tab-container">
                        <div class="headers">
                            <div (click)="setTab(SelectedTab.Assigned)"
                                [class.selected]="selectedTab === SelectedTab.Assigned" class="header clickable">
                                <div class="icon">
                                    <app-icon icon="person-outline"></app-icon>
                                </div>
                                Assigned to me
                            </div>
                            <div (click)="setTab(SelectedTab.Calendar)"
                                [class.selected]="selectedTab === SelectedTab.Calendar" class="header clickable">
                                <div class="icon">
                                    <app-icon icon="calendar-outline"></app-icon>
                                </div>
                                Calendar
                            </div>
                        </div>
                        <div class="tab-content scroll">
                            <ng-container *ngIf="selectedTab === SelectedTab.Assigned">

                                <div class="assigned">

                                    <ng-container *ngIf="loading === true">
                                        <div class="spinner">
                                            <app-loading-spinner visible="true"></app-loading-spinner>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="loading === false">

                                        <div class="no-info" *ngIf="assignedContracts?.length === 0">
                                            <div>
                                                <img src="/assets/images/clipboard.svg" />
                                            </div>
                                            You don’t have any assigned contracts
                                        </div>

                                        <div [@fadeInAnimation]
                                            *ngIf="assignedContracts !== undefined && assignedContracts.length > 0"
                                            class="assigned-content">

                                            <div class="search fade-block">
                                                <app-search-bar placeholder="Search by contract name"
                                                    (onSearch)="query = $event"></app-search-bar>
                                            </div>

                                            <div class="assigned-header fade-block">
                                                {{assignedContracts.length}} Contract(s) assigned
                                            </div>

                                            <div *ngFor="let contract of assignedContracts | search: query; "
                                                class="assigned-contract fade-block">
                                                <div class="title clickable" [routerLink]='[contract.documentLink]'>
                                                    {{contract.title}}
                                                </div>
                                                <div class="status status-{{contract.status.id}}">
                                                    {{contract.status.text}}
                                                </div>
                                                <div class="path clickable" [routerLink]='[contract.pathLink]'>
                                                    {{contract.path}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                </div>
                            </ng-container>

                            <ng-container *ngIf="selectedTab === SelectedTab.Calendar">
                                <ng-template #host></ng-template>
                            </ng-container>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>