import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AvatarModule } from '@summize/shared/components-v2';

import { ProgressResultBarComponent } from './progress-result-bar.component';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';

@NgModule({
    imports: [
        CommonModule,
        ProgressBarModule,
        AvatarModule
    ],
    exports: [
        ProgressResultBarComponent
    ],
    declarations: [
        ProgressResultBarComponent
    ]
})
export class ProgressResultBarModule { }

export * from './progress-result-bar.component'