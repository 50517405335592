import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';

import { v4 as uuidv4 } from 'uuid';

import { EMPTY_GUID } from '@summize/shared/core';
import { EventService } from '@summize/shared/framework';

import { AssistantChatMessage, AssistantChatMessageSource, AssistantChatThread, AssistantMeta, ChatService } from '../../chat.service';
import { ChatInputComponent, InputMessage, InputMeta } from '../chat-input/chat-input.component';

@Component({
  selector: 'app-chat-thread',
  templateUrl: './chat-thread.component.html',
  styleUrls: ['./chat-thread.component.scss']
})
export class ChatThreadComponent implements OnInit {

  @ViewChild(ChatInputComponent)
  public chatInput: ChatInputComponent;

  @Input()
  public chatThreadId: string;

  @Input()
  public set documentId(value: string) {

    this._documentId = value;

    this.loadMeta();

  }

  public get documentId(): string {
    
    return this._documentId;

  }

  @Input()
  public set clientId(value: string) {

    this._clientId = value;

    this.loadMeta();

  }

  public get clientId(): string {
    
    return this._clientId;

  }  

  @Input()
  public set matterId(value: string) {

    this._matterId = value;

    this.loadMeta();    

  }

  public get matterId(): string {
    
    return this._matterId;

  }

  public isBusy: boolean = false;
  public isInputBusy: boolean = false;

  public meta: AssistantMeta = undefined;
  public inputMeta: InputMeta = undefined;

  public chatThread: AssistantChatThread = undefined;

  public selectedText: string = undefined;

  public EMPTY_GUID = EMPTY_GUID;

  private _documentId: string;

  private _clientId: string;

  private _matterId: string;

  private typingMessageId: string = '';

  constructor(
    public router: Router,
    private chatService: ChatService,
    private events: EventService    
  ) { }

  public async ngOnInit() {

    await this.loadMeta();

    this.chatThreadId = this.chatThreadId ?? EMPTY_GUID;

    this.loadChatThread(this.chatThreadId);

  }

  public async clearThread() {

    this.chatThreadId = EMPTY_GUID;

    this.chatThread = undefined;

    this.chatInput.clear();

  }

  public async newThread() {

    const chatThreadId = this.chatThread?.assistantChatThreadId ?? EMPTY_GUID;

    this.isInputBusy = true;

    try {

        this.chatThread = await this.chatService.sendChatMessageNewThread(chatThreadId);

        this.chatThreadId = this.chatThread.assistantChatThreadId;

        this.chatInput.clear();

        this.scrollToBottom();

    }
    catch(err) {
      
      console.error(err);

      this.events.actionFailed('Failed to perform action');

    }

    this.isInputBusy = false;

  }

  public insertSelectedText() {

    this.chatInput.setContent(this.selectedText);

    this.selectedText = undefined;

  }

  public async messageSend(message: InputMessage) {

    const chatThreadId = this.chatThread?.assistantChatThreadId ?? EMPTY_GUID;

    this.isInputBusy = true;

    try {

      if (message.elements?.length > 0) {

        const chatThread = await this.chatService.sendChatMessage(chatThreadId, message.html, message.elements);

        this.meta.settings.typingSpeedMs = 0;

        if (this.meta.settings.typingSpeedMs > 0 && chatThread.assistantChatMessages?.length > 0) {

          // Generate a id to track if we need to speed up the typing.
          this.typingMessageId = uuidv4();

          const chatMessage = chatThread.assistantChatMessages[chatThread.assistantChatMessages.length - 1];

          if (chatMessage.source === AssistantChatMessageSource.Assistant) {

            chatMessage.typingMessage = '';

            this.typeChatMessage(chatMessage);

          }

        }

        this.chatThread = chatThread;

        this.chatThreadId = this.chatThread.assistantChatThreadId;

        this.chatInput.clear();

        this.scrollToBottom();

      }

    }
    catch(err) {

      console.error(err);

      this.events.actionFailed('Failed to perform action');

    }

    this.isInputBusy = false;

  }

  private async loadMeta() {

    const documentId = this.documentId;

    const clientId = this.clientId;

    const matterId = this.matterId;

    if (this.documentId !== undefined && this.documentId !== EMPTY_GUID) {

      const meta = await this.chatService.getMetaForDocument(this.documentId);

      // Check the 'this' values are the same as before the API call - to avoid overwriting wrong info.
      if (this.documentId === documentId && this.clientId === clientId && this.matterId === matterId) {

        this.meta = meta;

      }

    } else {

      const meta = await this.chatService.getMeta(this.clientId ?? EMPTY_GUID, this.matterId ?? EMPTY_GUID);

      // Check the 'this' values are the same as before the API call - to avoid overwriting wrong info.
      if (this.documentId === documentId && this.clientId === clientId && this.matterId === matterId) {

        this.meta = meta;

      }

    }

    this.inputMeta = {

      atMentions: [],
      hashMentions: this.meta.prompts.map(x => ({ value: x.label, key: x.promptId, prompt: x }))

    };

  }  

  private typeChatMessage(chatMessage: AssistantChatMessage) {

    const messageElements = chatMessage.message.split(' ');

    if (messageElements.length <= 5) {

      chatMessage.typingMessage = undefined;

      this.scrollToBottom();

      return;

    }

    let chatMessageText = '';

    const typingMessageId = this.typingMessageId;

    const repeater = () => {

      if (messageElements.length <= 0 || this.typingMessageId !== typingMessageId) {

        chatMessage.typingMessage = undefined;

        this.scrollToBottom();

        return;

      }

      const messageElement = messageElements.shift();

      chatMessageText = chatMessageText.length > 0
        ? chatMessageText + ' ' + messageElement
        : messageElement;

      chatMessage.typingMessage = chatMessageText + (messageElements.length > 0 ? ' ...' : '');

      if (messageElements.length % 10 === 0) {

        this.scrollToBottom();

      }

      setTimeout(repeater, this.meta.settings.typingSpeedMs);

    }

    repeater();

  }

  private async loadChatThread(chatThreadId: string) {

    this.isBusy = true;

    try {

      this.chatThread = await this.chatService.getThreadById(chatThreadId);

      this.chatThreadId = this.chatThread.assistantChatThreadId;

      this.scrollToBottom();

    }
    catch(err) {

      console.error(err);

      this.events.actionFailed('Failed to perform action');

    }

    this.isBusy = false;

  }

  private scrollToBottom() {

    setTimeout(() => {

      const element = document.getElementById('chatThreadBottom');

      if (element !== undefined) {

        element.scrollIntoView({ behavior: "auto", block: "end" });

      }
    }, 100);

  }

}
