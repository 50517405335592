import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {

    public transform(value: Array<any>, query: string, isParentChild: boolean = false) {

        if (query === undefined) {

            return value;

        }

        if (isParentChild === false) {

            return value.filter(v => v.name.toLocaleLowerCase().includes(query.toLowerCase()));

        }
        else {

            return value.filter(v =>
                v.name.toLocaleLowerCase().includes(query.toLowerCase()) || 
                v.children.some(c => c.name.toLocaleLowerCase().includes(query.toLowerCase())) 
            );

        }

    }
}