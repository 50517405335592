import { Component, Inject } from '@angular/core';

import { Subscription } from 'rxjs';

import { ChangeDetectionHelper, LayoutHelper } from '@summize/shared/core';
import { Environment } from '@summize/shared/framework';

import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  public title = 'Summize Admin';

  public isAuthenticated = false;

  private isAuthenticatedSub: Subscription;

  public menuExpanded: boolean = false;

  constructor(private authService: AuthService, @Inject(Environment) private environment) {

    console.log('Admin startup complete', environment.apiUrl);

    if (!this.isAuthenticatedSub) {

      this.isAuthenticatedSub = this.authService.isAuthenticated$.subscribe((isAuthenticated) => {

        this.isAuthenticated = isAuthenticated;

        ChangeDetectionHelper.doNextCycle(() => {

          LayoutHelper.BindComponent('.router', (h) => h);

        });

      });

    }

  }

  public ngOnInit(): void {

    LayoutHelper.BindComponent('.router', (h) => h);

  }

}
