import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlockButtonModule, IconModule } from '@summize/shared/components-v2';

import { HeaderBarComponent } from './header-bar.component';
import { HeaderBarService } from './header-bar.service';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        BlockButtonModule
    ],
    exports: [
        HeaderBarComponent
    ],
    declarations: [
        HeaderBarComponent
    ],
    providers: [
        HeaderBarService
    ]
})
export class HeaderBarModule { }

export * from './header-bar.component'