import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AppComponent } from '@summize/shared/core';
import { EventService, ShowToastMessage, TaskNote } from '@summize/shared/framework';
import { ConfirmationModalComponent } from '@summize/shared/components-v2';

import { TaskNotesService } from './task-notes.service';
import { Note } from '../notes/notes.models';
import { NotesService } from '../notes/notes.service';

@Component({
    selector: 'app-task-notes',
    templateUrl: 'task-notes.html'
})
export class TaskNotesComponent extends AppComponent implements OnInit {

    @Input()
    public taskInstanceId: string;

    @Input()
    public taskComplete: boolean;

    @Input()
    public documentId: string;

    @Input()
    public taskNotes: TaskNote[];

    @Input()
    public completionNote: string;

    @Output()
    public notesUpdated: EventEmitter<TaskNote[]> = new EventEmitter<TaskNote[]>();

    public notes: Note[] = [];

    constructor(
        private taskNotesService: TaskNotesService,
        private notesService: NotesService,
        private events: EventService,
        public dialog: MatDialog) {
        super();
    }

    public ngOnInit(): void {

        if (!this.taskNotes) {

            return;

        }

        this.notes = this.taskNotes.map(note => this.mapNote(note));

        this.subscribe(this.notesService.add$, (text: string) => {

            this.addNote(text);

        });


        this.subscribe(this.notesService.update$, (note: Note) => {

            this.updateNote(note);

        });

        this.subscribe(this.notesService.delete$, (noteId: string) => {

            this.deleteNote(noteId);

        });
    }

    private async addNote(text: string) {

        try {

            const note = await this.taskNotesService.create(this.documentId, this.taskInstanceId, text);

            this.notes.push(this.mapNote(note));

            this.taskNotes.push(note);

            this.notesService.toggleAdd(false);

            this.notesUpdated.emit(this.taskNotes);

        }
        catch (err) {

            this.handleError(err, 'Failed to add note.');

        }

    }

    private async updateNote(note: Note) {

        try {

            const updatedNote = await this.taskNotesService.update(this.documentId, this.taskInstanceId, note.id, note.text);

            const mappedUpdatedNote = this.mapNote(updatedNote);

            // Update the notes list with the mapped updated note
            this.updateNoteInList(this.notes, mappedUpdatedNote);

            // Update the task notes list with the original updated note
            this.updateNoteInList(this.taskNotes, updatedNote);

            this.notesUpdated.emit(this.taskNotes);

        }
        catch (err) {

            this.handleError(err, 'Failed to edit note.');
        }
    }

    private async deleteNote(noteId: string) {

        this.dialog.open(ConfirmationModalComponent, {
            data: {
                header: 'Delete note',
                text: 'Please confirm you want to delete this task note.',
                confirmButton: 'Delete note',
                hideCancel: false
            }
        }).afterClosed().subscribe(async (confirm: boolean) => {

            if (confirm === true) {

                try {

                    await this.taskNotesService.delete(this.documentId, this.taskInstanceId, noteId);

                    this.notes = this.notes.filter(note => note.id !== noteId);

                    this.taskNotes = this.taskNotes.filter(x => x.id !== noteId);

                    this.notesUpdated.emit(this.taskNotes);


                }
                catch (err) {

                    this.handleError(err, 'Failed to delete note.');

                }

            }
        });

    }

    private mapNote(note: TaskNote): Note {

        return {
            id: note.id,
            author: note.createdByName,
            text: note.note,
            editable: this.taskComplete === false && note.createdBy === this.user.userId && this.hasClaim(this.Claims.Tasks.CanTasksUpdate),
            date: note.updatedAt
        };

    }

    private handleError(err: any, message: string) {

        console.log(err);

        this.events.despatch(ShowToastMessage, {
            text: message,
            type: 'error',
            icon: 'icon-error1'
        });

    }

    private updateNoteInList(list: Note[] | TaskNote[], updatedNote: Note | TaskNote): void {

        var index = list.findIndex(x => x.id === updatedNote.id);

        if (index !== -1) {

            list[index] = updatedNote;

        }

    }
}