import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  name: 'dev',
  apiUrl: 'https://api-kc-dev.summize.com',
  publicSiteUrl: 'https://www.summize.local',
  oktaAuthUrl: 'https://dev-938782.oktapreview.com',
  auth: {
    service: 'oidc',
    oidc: {
      authority: 'https://identity-kc-dev.summize.com',
      clientId: 'summizeui',
      clientSecret: "tddA25drSzw6B3yK",
      signinRedirectUrl: "https://admin-kc-dev.summize.com/signin-oidc",
      signoutRedirectUrl: "https://admin-kc-dev.summize.com/signout-oidc",
      scopes: 'openid profile email'
    }
  },
  applicationInsights: '',
  featureFlags: [
    'allowDeleteTenant',
    'adminUIEditSSO'
  ]
};

