import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface RadioButtonItem {
    name: string;
    value: any;
    description?: string;
}

export enum RadioGroupType {
    default,
    alt
}

@Component({
    selector: 'app-radio-button-group',
    templateUrl: './radio-button-group.html',
    styleUrls: ['./radio-button-group.scss']
})
export class RadioButtonGroupComponent {

    @Input()
    public type: RadioGroupType = RadioGroupType.default;

    @Input()
    public items: RadioButtonItem[] = [];

    @Input()
    public value: any;

    @Output()
    public valueChange = new EventEmitter<any>();

    public RadioGroupType = RadioGroupType;

    public onSelectionChange(selectedValue: any): void {
        this.value = selectedValue;
        this.valueChange.emit(selectedValue);
    }
}
