import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollingModule } from '@angular/cdk/scrolling';

import { SlidePanelComponent } from './slide-panel.component';
import { SlidePanelService } from './slide-panel.service';
import { IconModule } from '../icon/icon.module';
import { BlockButtonModule } from '../block-button/block-button.module';


@NgModule({
    imports: [
        CommonModule,
        IconModule,
        BlockButtonModule,
        ScrollingModule // Required for hosted-components with cdkDragLists
    ],
    exports: [
        SlidePanelComponent
    ],
    declarations: [
        SlidePanelComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    providers: [
        SlidePanelService
    ]
})
export class SlidePanelModule { }

export * from './slide-panel.service';

export * from './slide-panel.component';