import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { BlockButtonModule, IconModule, SelectBoxModule } from '@summize/shared/components-v2';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { CustomFieldEditorComponent } from './custom-field-editor.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IconModule,
        SelectBoxModule,
        DragDropModule,
        BlockButtonModule
    ],
    exports: [
        CustomFieldEditorComponent
    ],
    declarations: [
        CustomFieldEditorComponent
    ]
})
export class CustomFieldEditorModule { }

export * from './custom-field-editor.component'