import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import WebViewer, { Core, WebViewerInstance } from '@pdftron/webviewer';

import { SummizeStorage } from '@summize/shared/framework';
import { AppComponent, ChangeDetectionHelper, TenantSettingService, TenantSettingTypes } from '@summize/shared/core';


import { PDFViewerHelper } from './pdf-viewer.helper';

export interface PDFCommand {
    label?: string;
    onClick?: Function;
    type?: string;
}

export interface PDFParagraphPosition {
    pageNumber: number;
    boundingBox: Array<number>;
    paragraphNumber?: string;
    resultText?: string;
    fallBackText?: string;
}

export interface DefinedTerm {
    definitionTitle: string;
    html: string;
}

export interface PDFOnClickArguments {
    text: string;
    quads: any;
    page: number;
    context: any;
}

@Component({
    selector: 'app-pdf-viewer',
    templateUrl: 'pdf-viewer.html',
    styleUrls: ['./pdf-viewer.scss']
})
export class PDFViewerComponent extends AppComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild('viewer')
    public viewer: ElementRef;

    @Input()
    public visibleIndex: number = 0;

    @Input()
    public hint: boolean = true;

    @Input()
    public boundingBoxes: any;

    @Input()
    public definedTerms: Array<DefinedTerm>;

    private _source: Array<string> = [
        '/assets/Supply of Services Example.pdf'
    ];

    public get source(): Array<string> {
        return this._source;
    }

    @Input() set source(newSource: Array<string>) {
        this._source = newSource;
        this.loadDocument();
    }

    @Input()
    public selectedClause: any;

    @Input()
    public set selectedParagraph(val: PDFParagraphPosition) {

        if (val !== undefined && val !== null) {

            this._selectedParagraph = val;
            this.highlight(this._selectedParagraph, true);

        }
    }

    private _selectedParagraph: PDFParagraphPosition;

    @Input()
    public commands: Array<PDFCommand>;

    @Input()
    public fileName: string = 'summize.pdf';

    @Input()
    public useRelativeHeight: boolean = false;

    @Input()
    public fitMode: string = 'Zoom';

    @Input()
    public disableHeader: boolean = false;

    @Output()
    public onClose: EventEmitter<void>;

    @Output()
    public onSelectedTextChange: EventEmitter<string>;

    @Output()
    public onReady: EventEmitter<void>;

    public wvInstance: WebViewerInstance;

    public loading: boolean = true;

    public isDocxDocument: boolean = false;

    private boundingBoxConversionFactor: number = 72; //PDF JS default DPI Scale Setting

    private highlightedParagraphId = 'SMZ-CURRENT-PARA';

    private linkedDocSeparator = '--';

    private showDefinedTermsForPDF: boolean = false;

    private debugBoundingBoxes: string;

    private pdfKey: string;

    private webViewerReady: boolean = false;

    private convertDocxToPdf: boolean = false;

    constructor(private tenantSettingService: TenantSettingService) {

        super();

        this.onClose = new EventEmitter<void>();

        this.onReady = new EventEmitter<void>();

        this.onSelectedTextChange = new EventEmitter<string>();

        this.showDefinedTermsForPDF = this.hasFeatureFlag('showDefinedTermsForPDF');

        this.pdfKey = SummizeStorage.getLocalItem('pdfKey');

    }

    public async ngAfterViewInit(): Promise<void> {

        await PDFViewerHelper.resetHelper();

        PDFViewerHelper.docList = this.source;

        const convertDocxToPdfTenantSetting = await this.tenantSettingService.GetSetting(TenantSettingTypes.DocumentViewerConvertDocxToPdf);

        this.convertDocxToPdf = convertDocxToPdfTenantSetting?.settingValue === "true";

        const args = {
            path: './assets/pdf-viewer/lib',
            css: `//${window.location.host}/assets/pdf-viewer/custom.css`,
            fullAPI: this.convertDocxToPdf,
            licenseKey: this.pdfKey,
            disabledElements: [
                this.disableHeader === true ? 'header' : 'wont-remove',
                'leftPanel',
                'leftPanelButton',
                'toolsHeader',
                'menuButton',
                'ribbons',
                'contextMenuPopup',
                'textHighlightToolButton',
                'textUnderlineToolButton',
                'textSquigglyToolButton',
                'textStrikeoutToolButton',
                'linkButton',
                'toggleNotesButton',
                'copyTextButton',
                'annotationPopup'
            ]
        };

        WebViewer(args, this.viewer.nativeElement).then(async (instance: any) => {

            const feature = instance.Feature;

            instance.Core?.disableEmbeddedJavaScript();

            instance.disableFeatures([feature.Download, feature.Print]);

            this.wvInstance = instance;

            this.debugBoundingBoxes = SummizeStorage.getLocalItem('smz-debugBoundingBoxes');

            if (this.debugBoundingBoxes === 'true') {

                const { annotManager } = instance;

                annotManager.addEventListener('annotationSelected', (annotations, action) => {

                    if (action === 'selected' && annotations[0].Term.debug !== undefined) {

                        window.prompt('Press CTRL + C to copy to clipboard:', annotations[0].Term.debug);

                    }

                });
            }

            instance.setFitMode(this.fitMode);

            this.wvInstance.UI.setAnnotationContentOverlayHandler(this.vwAnnotationHabdler);

            if (this.commands !== undefined && this.commands.length > 0) {

                this.commands = this.commands.map(c => {

                    return {
                        type: c.type,
                        label: c.label,
                        onClick: async () => {

                            const textContext = await this.getTextSelectionContext();

                            c.onClick(textContext);

                        }
                    };

                });

                instance.textPopup.update(this.commands);

            }

            instance.docViewer.addEventListener('mouseLeftUp', this.vwMouseLeftUp.bind(this));

            instance.docViewer.addEventListener('documentLoaded', this.wvDocumentLoadedHandler.bind(this));

            instance.docViewer.addEventListener('annotationsLoaded', this.wvAnnotationsLoaded.bind(this));

            this.webViewerReady = true;

            await this.loadDocument();

        });

    }

    public ngOnDestroy(): void {

        const wrapped = <any>this.wvInstance;

        if (this.commands !== undefined && this.commands.length > 0) {

            this.commands.forEach(c => c.onClick = undefined);

            this.commands = undefined;

        }

        if (wrapped !== undefined) {

            wrapped.UI?.setAnnotationContentOverlayHandler(undefined);

            wrapped.docViewer?.removeEventListener('documentLoaded', this.wvDocumentLoadedHandler);

            wrapped.docViewer?.removeEventListener('mouseLeftUp', this.vwMouseLeftUp);

            wrapped.docViewer?.removeEventListener('annotationsLoaded', this.wvAnnotationsLoaded);

            wrapped.dispose();

        }

    }

    public ngOnInit() {

        this.wvDocumentLoadedHandler = this.wvDocumentLoadedHandler.bind(this);

    }

    public async loadDocument() {

        console.log(`Load Document ${!!this.wvInstance?.UI}`);

        if (!this.webViewerReady) {

            console.log(`Load Document ${!!this.wvInstance?.UI} End - WebViewer Not Ready`);

            return;

        }

        const documentToLoad = await PDFViewerHelper.fetchBlob(this.source[0]);

        const first = this.source[0];

        if (first !== undefined) {

            this.isDocxDocument = (<any>this.source[0]).documentName.toLowerCase().endsWith('.docx') ||
                (<any>this.source[0]).documentName.toLowerCase().endsWith('/docx');

        }

        if (this.wvInstance?.UI) {

            await this.loadDocumentInWebView(documentToLoad);

        }

        console.log(`Load Document ${!!this.wvInstance?.UI} End`);

    }

    public async scrollToDocument(index: number): Promise<void> {

        if (index === 0 || index === 1) {

            this.wvInstance.Core.documentViewer.displayPageLocation(1, 0, 0);

            return;

        }

        if (PDFViewerHelper.linkedDocPageIndexes !== undefined && PDFViewerHelper.linkedDocPageIndexes.length > 0) {


            const linkedDocPage = PDFViewerHelper.calculateDocumentStart(index - 1) + 1;

            this.wvInstance.Core.documentViewer.displayPageLocation(linkedDocPage, 0, 0);
        }

    }

    public search(searchTerm: any, searchUp: boolean) {

        const { Search } = this.wvInstance.Core;

        const searchMode = searchUp === true ?
            Search.Mode.PAGE_STOP | Search.Mode.HIGHLIGHT | Search.Mode.SEARCH_UP :
            Search.Mode.PAGE_STOP | Search.Mode.HIGHLIGHT;

        this.highlight(searchTerm, false, searchMode);

    }

    private async loadDocumentInWebView(documentToLoad: Blob) {

        if (this.convertDocxToPdf === false || this.isDocxDocument === false) {

            const fileName = this.isDocxDocument === false ?
                this.fileName : this.fileName.replace('.pdf', '.docx');

            this.wvInstance.UI.loadDocument(documentToLoad, { filename: fileName });

            return;

        }

        const core: any = this.wvInstance.Core;
        const buf = await core.officeToPDFBuffer(
            documentToLoad, { extension: 'docx' }
        );

        this.wvInstance.UI.loadDocument(buf, { filename: this.fileName.replace('.docx', '.pdf') });

    }

    private async getTextSelectionContext(): Promise<any> {

        const { documentViewer } = this.wvInstance.Core;

        const quads = documentViewer.getSelectedTextQuads();

        const page = documentViewer.getCurrentPage();

        const text = documentViewer.getSelectedText();

        const match = await this.findContextForTerm(text, quads, page);

        return {
            text,
            quads,
            page,
            context: match[0]
        };

    }

    public highlight(value: string | PDFParagraphPosition, fullSearch: boolean = true, searchMode?: any): void {

        if (this.wvInstance === undefined || value === undefined) {

            return;

        }

        const { documentViewer, Search } = this.wvInstance.Core;

        this.clearHighlightedParagraph();

        if ((<PDFParagraphPosition>value).fallBackText !== undefined) {

            value = (<PDFParagraphPosition>value).fallBackText;

        }

        if (typeof (value) === 'string') {

            const searchText = this.fixSearch(value);

            if (searchText === undefined) {

                return;

            }

            documentViewer.clearSearchResults();

            const mode = searchMode || Search.Mode.PAGE_STOP | Search.Mode.HIGHLIGHT;

            const searchOptions = {
                fullSearch: fullSearch,
                onResult: (result: any) => {

                    if (result.resultCode === Search.ResultCode.FOUND) {

                        documentViewer.displaySearchResult(result);
                    }
                }
            };

            documentViewer.textSearchInit(searchText, mode, searchOptions);

        } else if (Array.isArray(value.boundingBox) === false) {

            this.highlight(value.resultText);

        } else {

            this.highlightParagraph(value);

        }

    }

    private fixSearch(val: string): string | undefined {

        if (val === undefined || val === null) {

            return undefined;

        };


        return val.replace('<br>', '');

    }

    private clearHighlightedParagraph() {

        const annotManager = this.wvInstance.Core.annotationManager;

        const selectedAnnot = annotManager.getAnnotationById(this.highlightedParagraphId);

        if (selectedAnnot) {
            annotManager.deleteAnnotation(selectedAnnot);
        }
    }

    private highlightParagraph(paragraph: PDFParagraphPosition) {

        if (!Array.isArray(paragraph.boundingBox) || paragraph.boundingBox?.every((x) => x === 0)) {

            return;

        }

        try {

            let pdfPage = paragraph.pageNumber;

            const { Annotations, annotationManager } = this.wvInstance.Core;

            const currentHighlightedParagraph = annotationManager.getAnnotationById(this.highlightedParagraphId);

            if (currentHighlightedParagraph !== undefined) {

                annotationManager.deleteAnnotation(currentHighlightedParagraph, { force: true });

            }

            const quads = this.createQuadsFromBoundingBox(paragraph.boundingBox)[0];

            const highlight: any = this.createAnnotation(quads, pdfPage, new Annotations.RectangleAnnotation);

            highlight.Id = this.highlightedParagraphId;
            highlight.StrokeColor = new Annotations.Color(255, 255, 0);
            highlight.FillColor = new Annotations.Color(255, 255, 0);
            highlight.Opacity = 0.4;
            highlight.Term = { text: this.selectedClause?.ruleName || '' };

            annotationManager.addAnnotation(highlight);
            annotationManager.drawAnnotations(highlight.PageNumber);

            const selectedAnnots = annotationManager.getAnnotationById(this.highlightedParagraphId);

            if (selectedAnnots) {

                annotationManager.bringToBack(selectedAnnots);

                annotationManager.jumpToAnnotation(selectedAnnots);

            }

        } catch (error) {

            console.log("Unable to highlight paragraph.", error);

        }

    }

    private getLinkedDocPageNumber(paragraphNumber: string, pageNumber: number): number {

        const [linkedDocId] = paragraphNumber.split(this.linkedDocSeparator);

        const docIndex = PDFViewerHelper.docList.findIndex(x => x.documentId === linkedDocId);

        // Pagenumber is actual page number in document
        // Need to add one for a seperater page

        return PDFViewerHelper.linkedDocPageIndexes[docIndex - 1] + pageNumber + 1;
    }

    private async mergeDocuments() {

        const { documentViewer } = this.wvInstance.Core;

        const doc = documentViewer.getDocument();

        if (doc === undefined || doc === null) {

            // Can undefined or undefined if disposing
            return;

        }

        PDFViewerHelper.linkedDocPageIndexes.push(doc.getPageCount());

        PDFViewerHelper.totalPages = doc.getPageCount();

        for (let index = 1; index < this.source.length; index++) {

            let pageIndexToInsert = doc.getPageCount() + 1;

            // Create separator page
            const separatorPage = await PDFViewerHelper.createSeparatorPage(this.source[index]);

            const separatorPageToInsert = await this.wvInstance.Core.createDocument(separatorPage, { extension: 'pdf' });

            await doc.insertPages(separatorPageToInsert, undefined, pageIndexToInsert);

            const docBlob = await PDFViewerHelper.fetchBlob(this.source[index]);

            if (this.isDocxDocument === false) {

                const docToInsert = await this.wvInstance.Core.createDocument(docBlob, { extension: 'pdf' });

                pageIndexToInsert = doc.getPageCount() + 1;

                await doc.insertPages(docToInsert, undefined, pageIndexToInsert);

                PDFViewerHelper.linkedDocPageIndexes.push(doc.getPageCount());

            }

        }

    }

    private wvAnnotationsLoaded(): void {

        const { annotationManager, Annotations } = this.wvInstance?.Core;

        const annotations = annotationManager?.getAnnotationsList() || [];

        // Force remove link annotations as they can contain XSS.
        // https://github.com/PortSwigger/portable-data-exfiltration/blob/main/PDF-research-samples/pdf-lib/acrobat/steal-contents-of-pdf-with-js/test.js
        for (const annotation of annotations) {

            if (annotation instanceof Annotations.Link) {

                annotationManager.deleteAnnotation(annotation);

            }

        }

    }

    private wvDocumentLoadedHandler(): void {

        console.log('Executing DocumentLoaded');

        setTimeout(async () => {

            console.log('Timeout DocumentLoaded');

            await this.mergeDocuments();

            this.loading = false;


            this.wvInstance.Core.disableEmbeddedJavaScript();

            const { Search } = this.wvInstance.Core;

            const searchMode = Search.Mode.PAGE_STOP | Search.Mode.HIGHLIGHT;

            if (this.selectedParagraph !== undefined && this.selectedParagraph !== null) {

                this.highlight(this.selectedParagraph, true, searchMode);

            }

            if (this.definedTerms !== undefined && this.showDefinedTermsForPDF === true) {

                this.setDefinedTerms();

            }

            if (this.debugBoundingBoxes === 'true') {

                this.addBoundingBoxes(this.boundingBoxes);

            }

            this.onReady.emit();

            if (this._selectedParagraph) {

                this.highlight(this._selectedParagraph);

            }

            console.log('Completed DocumentLoaded');

        }, 1000);

    }

    private vwMouseLeftUp(): void {

        ChangeDetectionHelper.doNextCycle(async () => {

            this.onSelectedTextChange.emit(await this.getTextSelectionContext());

        }, 100);

    }

    private vwAnnotationHabdler(annotation: any): any {

        const div = document.createElement('div');

        div.classList.add('definedterm-tooltip');

        div.appendChild(document.createTextNode(annotation.Term?.text));

        return div;

    }

    public scrollToRelatedParagraph(relatedPara: any) {

        this.highlightParagraph({
            paragraphNumber: relatedPara.resultLine,
            pageNumber: relatedPara.pageNumber,
            boundingBox: relatedPara.boundingBox
        });

    }

    private setDefinedTerms() {

        const searchArray = this.definedTerms
            .filter(t => t.html !== undefined && t.html !== '')
            .map(t => t.definitionTitle);

        const term = searchArray.join('|');

        const { documentViewer, Search, annotationManager, Annotations } = this.wvInstance.Core;

        const searchMode = Search.Mode.PAGE_STOP | Search.Mode.HIGHLIGHT | Search.Mode.REGEX | Search.Mode.WHOLE_WORD;

        const searchOptions = {
            fullSearch: true,
            onResult: async (result) => {
                if (result.resultCode === Search.ResultCode.FOUND) {

                    if (!result.quads.length) {

                        return;
                    }


                    const textQuad = result.quads[0].getPoints();

                    const annot: any = new Annotations.TextUnderlineAnnotation();

                    annot.StrokeColor = new Annotations.Color(220, 220, 220, 1);

                    annot.Quads = [textQuad];

                    annot.PageNumber = result.pageNum;

                    annot.NoResize = true;

                    annot.Term = this.definedTerms.find(t => t.definitionTitle.toLowerCase() === result.resultStr.toLowerCase());

                    annotationManager.addAnnotation(annot);

                    annotationManager.drawAnnotations(annot.PageNumber);

                }
            }
        };

        documentViewer.textSearchInit(term, searchMode, searchOptions);

    }

    public getSelectedTextPosition(text: string) {

        const { documentViewer, Search } = this.wvInstance.Core;

        const searchMode = Search.Mode.PAGE_STOP | Search.Mode.HIGHLIGHT;

        const searchOptions = {
            fullSearch: true,
            onResult: async (result) => {
                if (result.resultCode === Search.ResultCode.FOUND) {

                    if (!result.quads.length) return;

                    const textQuad = result.quads[0].getPoints();

                    return textQuad;

                }
            }

        };

        documentViewer.textSearchInit(text, searchMode, searchOptions);

    }

    private async findContextForTerm(term: string, foundQuads: any, pageNumber: number): Promise<any> {

        const { documentViewer } = this.wvInstance.Core;

        const found = [];

        if (term === '') {

            return found;
            
        }

        const doc = documentViewer.getDocument();

        const searchTerm = term;

        const pageText = await doc.loadPageText(pageNumber);

        let startIndex = 0;

        let endIndex = 0;

        if (pageText != undefined) {

            while (startIndex > -1) {

                startIndex = pageText.indexOf(searchTerm, endIndex);

                if (startIndex !== -1) {

                    endIndex = startIndex + searchTerm.length;

                    let quads = [];

                    if (startIndex !== endIndex) {

                        quads = await doc.getTextPosition(pageNumber, startIndex, endIndex);

                    } else {

                        break; // Must be in a scanned doc where we cannot highlight the text so break out
                    }

                    if (this.isQuadMatch(quads[0], (foundQuads || [])[0])) {

                        const context = pageText.substring(startIndex - 10, (startIndex + searchTerm.length) + 10);

                        found.push({ quads, pageNumber, context });

                    }

                }
            }
        }

        return found;

    }

    private isQuadMatch(quadA: any, quadB: any): boolean {

        return quadA.x1 === quadB?.x1 && quadA.y1 === quadB?.y1;

    }

    private createQuadsFromBoundingBox(boundingBox: Array<number>) {

        const quads = {

            x1: boundingBox[0] * this.boundingBoxConversionFactor,
            y1: boundingBox[1] * this.boundingBoxConversionFactor,
            x2: boundingBox[2] * this.boundingBoxConversionFactor,
            y2: boundingBox[3] * this.boundingBoxConversionFactor,
            x3: boundingBox[4] * this.boundingBoxConversionFactor,
            y3: boundingBox[5] * this.boundingBoxConversionFactor,
            x4: boundingBox[6] * this.boundingBoxConversionFactor,
            y4: boundingBox[7] * this.boundingBoxConversionFactor,
            toRect: undefined
        };

        return [quads];

    }

    public addBoundingBoxes(boundingBoxes: any) {

        const { Annotations, annotationManager } = this.wvInstance.Core;

        for (let index = 0; index < boundingBoxes?.length; index++) {

            const box = boundingBoxes[index];

            if (box.documentId !== PDFViewerHelper.docList[0].documentId) {
                box.pageNumber = PDFViewerHelper.FindActualPageNumberForDocument(box.documentId, box.pageNumber);
            }

            const quads = this.createQuadsFromBoundingBox(box.boundingBox)[0];

            const rectangle: any = this.createAnnotation(quads, box.pageNumber, new Annotations.RectangleAnnotation);

            const debug = {
                ui: quads,
                api: box.boundingBox
            };

            rectangle.StrokeColor = new Annotations.Color(255, 0, 0);

            rectangle.Term = { text: JSON.stringify(quads), debug: JSON.stringify(debug) };

            annotationManager.addAnnotation(rectangle);
            annotationManager.drawAnnotations(rectangle.PageNumber);
        }

    }

    private createAnnotation(quads: any, pageNumber: number, annotation: any): Core.Annotations.Annotation {

        const { documentViewer } = this.wvInstance.Core;

        const completeRotations = documentViewer.getCompleteRotation(pageNumber);

        const width = documentViewer.getPageWidth(pageNumber);
        const height = documentViewer.getPageHeight(pageNumber);

        switch (completeRotations) {
            case 0: // Page is not rotated or rotated 360 degrees
                annotation.X = quads.x1;
                annotation.Y = quads.y1;
                annotation.Width = quads.x3 - quads.x1;
                annotation.Height = quads.y3 - quads.y1;

                break;

            case 1: // Page is rotated 90 degress
                annotation.X = quads.y1;
                annotation.Y = height - quads.x3;
                annotation.Width = quads.y3 - quads.y1;
                annotation.Height = quads.x3 - quads.x1;
                break;

            case 2: // Page is rotated 180 degrees
                annotation.X = width - quads.x2;
                annotation.Y = height - quads.y4;
                annotation.Width = quads.x3 - quads.x1;
                annotation.Height = quads.y3 - quads.y1;

                break;

            case 3: // Page is rotated 270 degrees
                annotation.X = width - quads.y3;
                annotation.Y = quads.x1;
                annotation.Width = quads.y3 - quads.y1;
                annotation.Height = quads.x3 - quads.x1;

                break;

            default:
                annotation.X = quads.x1;
                annotation.Y = quads.y1;
                annotation.Width = quads.x3 - quads.x1;
                annotation.Height = quads.y3 - quads.y1;

                break;
        }


        annotation.PageNumber = pageNumber;
        annotation.NoResize = true;
        annotation.NoMove = true;

        return annotation;

    }

}
