import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';

export interface OutboundIntegration {
    tenantId: string;
    departmentId: string;
    id: string;
    displayName: string;
    outboundIntegrationType: number;
}

export interface NotificationAction {
    id: string;
    outboundIntegrationId: string;
}

export interface Notification {
    id: string;
    notificationAreaType: number;
    notificationActions: NotificationAction[];
}

@Injectable({
    providedIn: 'root'
})
export class NotificationTargetService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getNotificationTargets(): Promise<OutboundIntegration[]> {
        
        const baseUrl = await this.getUserBaseUrl(true);

        const results = await this.http.get<any>(`${baseUrl}outboundintegrations`, { responseType: 'json' }).toPromise();

        return results.map(x => ({
            tenantId: x.tenantId || x.TenantId,
            departmentId: x.departmentId || x.DepartmentId,
            id: x.Id || x.id,
            displayName: x.displayName || x.DisplayName,
            outboundIntegrationType: x.outboundIntegrationType || x.OutboundIntegrationType
        }));

    }

    public async getNotifications(): Promise<Notification[]> {
        
        const baseUrl = await this.getUserBaseUrl(true);

        const results = await this.http.get<any>(`${baseUrl}notifications`, { responseType: 'json' }).toPromise();

        return results;

    }

    public async addNotification(notification: Notification): Promise<Notification> {

        const url = `${this.getUserBaseUrl(true)}notifications`;

        return this.http
            .post<Notification>(url, notification)
            .toPromise();

    }

    public async updateNotification(notification: Notification): Promise<Notification> {

        const url = `${this.getUserBaseUrl(true)}notifications/${notification.id}`;

        return this.http
            .put<Notification>(url, notification)
            .toPromise();

    }

}
