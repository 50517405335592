<div class="component">
  <div class="editabletext" [class.focus-border]="isEditing === true" >
    <input type="text" 
      [placeholder]="placeholder" 
      matInput
      [matAutocomplete]="auto" 
      [formControl]="myControl" 
      class="input-area"
      [value]="value"
      (focus)="onFocus()"
      (blur)="onBlur()">
    <app-icon icon="chevron-down-outline" class="select-button" (click)="onDownArrowClick($event)"></app-icon>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="valueChanged($event)" >
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value" (mousedown)=$event.preventDefault()>
          {{option.value}}
      </mat-option>
    </mat-autocomplete>
  </div>
</div>
