import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { ESignatureRecipient, ESignatureRecipientOrigin } from '@summize/shared/framework';
import { EmailPattern } from '@summize/shared/core';

@Component({
    selector: 'app-esignature-email-recipients-v2',
    templateUrl: 'esignature-email-recipients-v2.html',
    styleUrls: ['./esignature-email-recipients-v2.scss'],
})
export class EsignatureEmailRecipientsComponentV2 {

    @Input()
    public recipients: ESignatureRecipient[] = [];

    @Output()
    public recipientsChanged: EventEmitter<ESignatureRecipient[]>;

    @Input()
    public recipientOrigin: ESignatureRecipientOrigin = ESignatureRecipientOrigin.Internal;

    @Input()
    public useLegacyStyle = true;

    public recipientOriginEnum = ESignatureRecipientOrigin;

    private emailInputSubject = new Subject<{ recipient: ESignatureRecipient }>();

    constructor() {

        this.recipientsChanged = new EventEmitter<ESignatureRecipient[]>();
    }

    public ngOnInit() {

        if (this.recipients === undefined || this.recipients.length === 0) {

            this.recipients = [];
        }

        this.emailInputSubject
            .pipe(
                debounceTime(500),
                distinctUntilChanged(),
                switchMap(async ({ recipient }) => {

                    this.validate(recipient);

                    this.recipientsChanged.emit(this.recipients);
                })
            )
            .subscribe();

    }

    public onKeyUp(recipient: any): void {

        this.emailInputSubject.next({ recipient });
    }

    private validate(recipient: any) {

        const trimmedName = recipient.name?.trim();
        const trimmedEmail = recipient.email?.trim();

        recipient.nameInvalid = !trimmedName;
        recipient.emailInvalid = !EmailPattern.test(trimmedEmail);

        if (!trimmedEmail) {
            return;
        }

        const exists = this.recipients.filter(
            x => x.email && x.email.trim().toLowerCase() === trimmedEmail.toLowerCase()
        );

        recipient.emailExists = exists?.length > 1;

    }

}