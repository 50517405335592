<app-dialog-shell title="Change owner" saveText="Change owner" [saveDisabled]="selected === undefined"
    [saveButtonWidth]="160" [showHeader]="true" [showFooter]="true" (onClose)="close()" (onSave)="save()">
    <div class="section-area">
        <div class="description">
            Select the owner you want to assign the selected contract(s) to, then click 'Change owner' to complete the
            action.
        </div>
        <div class="warning">
            <strong>Please note:</strong> If the owner you select does not have the necessary folder visibility to view
            any of the
            contracts selected, then this action will not apply to those contracts.
        </div>
        <div class="select">
            <div class="title">
                Owner
            </div>
            <app-select-box (changed)="selected = $event" [options]="users" placeholder="Select">
            </app-select-box>
        </div>
    </div>
</app-dialog-shell>