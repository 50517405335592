import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OverlayModule } from '@angular/cdk/overlay'

import { SelectedFilterComponent } from './selected-filter.component';
import { BlockButtonModule } from '../block-button/block-button.module';
import { CorePipesModule } from '../pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        BlockButtonModule,
        OverlayModule,
        CorePipesModule
    ],
    exports: [
        SelectedFilterComponent
    ],
    declarations: [
        SelectedFilterComponent
    ]
})
export class SelectedFilterModule { }

export * from './selected-filter.component'