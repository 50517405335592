import { Component, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { merge, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { map } from 'rxjs/operators';

export interface AutocompleteChipGroupModel {
    key: string, value: string, groupKey: string
}

export interface AutocompleteGroupModel {
    key: string, value: string
}

@Component({
    selector: 'app-autocomplete-chips-groups',
    templateUrl: 'autocomplete-chips-groups.html',
    styleUrls: ['./autocomplete-chips-groups.scss']
})

export class AutocompleteChipsGroupsComponent {

    @Input()
    public label: string;

    @Input()
    public parentForm: FormGroup;

    @Input()
    public fcn: string;

    @Input()
    public isExclusiveSelections: boolean = true;

    @Input()
    public placeholder: string = 'Select...';

    @Input()
    public selectedItems: AutocompleteChipGroupModel[] = [];

    @Input()
    public separatorKeysCodes: number[] = [ENTER, COMMA];

    @Input()
    public items: AutocompleteChipGroupModel[] = [];

    @Input()
    public groups: AutocompleteGroupModel[] = [];

    @Input()
    public refresh: Subject<AutocompleteChipGroupModel[]>;

    @Output()
    public changed: EventEmitter<AutocompleteChipGroupModel[]>;

    public myControl = new FormControl();

    public filteredItems: Observable<AutocompleteChipGroupModel[]>;


    @ViewChild('itemInput')
    public itemInput: ElementRef<HTMLInputElement>;

    constructor() {

        this.changed = new EventEmitter();

    }

    public ngOnInit() {

        const inputFiltering = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => {
                return this.filter(value);
            })
        );

        const selectionFiltering = this.changed.pipe(
            map(value => this.filter(null)));

        const selectionRefresh = this.refresh?.pipe(
            tap(x => this.selectedItems = x),
            map(value => this.filter(null)));

        this.filteredItems = merge(inputFiltering, selectionFiltering, selectionRefresh);

    }

    public addItem(event: MatChipInputEvent): void {

        const key = event.value;

        if (key) {
            const item = this.items.find(x => x.key === key);

            if (item) {
                this.selectedItems.push(item);
            }
        }

        event.chipInput!.clear();
        this.myControl.setValue('');

        this.changed.emit(this.selectedItems);

    }

    public removeItem(item: AutocompleteChipGroupModel): void {

        const index = this.selectedItems.findIndex(x => x.key === item.key);

        if (index >= 0) {
            this.selectedItems.splice(index, 1);

            this.myControl.setValue('');

            this.changed.emit(this.selectedItems);
        }

    }

    public selected(event: MatAutocompleteSelectedEvent): void {

        const key = event.option.value;
        const item = this.items.find(x => x.key === key);

        if (item) {
            this.selectedItems.push(item);
        }

        this.itemInput.nativeElement.value = '';
        this.myControl.setValue('');

        this.changed.emit(this.selectedItems);

    }

    public displayFn(item: AutocompleteChipGroupModel): string {

        return item && item.value ? item.value : '';

    }

    private filter(value: string): AutocompleteChipGroupModel[] {

        const availableItems = this.isExclusiveSelections ?
            this.items.filter(x => !this.selectedItems.find(y => y.key === x.key)) :
            this.items;

        if (value === null || value === undefined || value.length === 0) {
            return availableItems.slice();
        }

        const filterValue = this.normalizeValue(value);

        return availableItems.filter(x => this.normalizeValue(x.value).indexOf(this.normalizeValue(filterValue)) > -1);

    }

    private normalizeValue(value: string | AutocompleteChipGroupModel): string {

        if (typeof value === 'string') {
            return value.toLowerCase().replace(/\s/g, '');
        } else if (value?.value !== undefined) {
            return value.value.toLowerCase().replace(/\s/g, '');
        }

        return 'Unknown';

    }

}