import { Component } from '@angular/core';

import { EMPTY_GUID } from '@summize/shared/core';
import { MatDialogRef } from '@angular/material/dialog';

import { AssignedToService } from '../assignto-select/assignto-select.service';

@Component({
    selector: 'app-change-owner-dialog',
    templateUrl: 'change-owner-dialog.html',
    styleUrls: ['./change-owner-dialog.scss']
})
export class ChangeOwnerDialogComponent {

    public users: Array<any>;

    public selected: any;

    constructor(private server: AssignedToService, public dialogRef: MatDialogRef<ChangeOwnerDialogComponent>) { }

    public async ngOnInit(): Promise<any> {

        const results = await this.server.getAssignableUsers(EMPTY_GUID, EMPTY_GUID);

        this.users = [{ key: EMPTY_GUID, value: 'No owner' }, ...results.map(r => ({ key: r.id, value: r.fullName }))]

    }

    public save() {

        this.dialogRef.close({
            selected: this.selected
        });

    }

    public close() {

        this.dialogRef.close(undefined);

    }

}