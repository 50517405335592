<div [@.disabled]="isFrameMode === true" [@backAnimation] [@state]="state"
    class="slide-panel-host smz-flex-area {{state}} {{settings?.size}}"
    [class.full-control]="settings?.managed === false">

    <ng-container *ngIf="settings?.managed === undefined || settings?.managed === true">
        <div class="row m-0 mt-3">

            <div *ngIf="settings?.title" class="col-sm-12">

                <h3 class="pt-3 pb-3 title-text">
                    <ion-icon [@backAnimation] *ngIf="isRoot === false" name="arrow-back-outline" class="mr-3 back-icon"
                        (click)="goBack()"></ion-icon>
                    {{settings?.title}}
                </h3>

                <app-icon *ngIf="settings?.closeable === true" (click)="destroy()" icon="close-outline"
                    class="clickable close-icon">
                </app-icon>

            </div>
        </div>

        <div class="content container-fluid mt-0 mb-1" cdkScrollable>
            <div class="row fade-block">
                <ng-template #host></ng-template>
            </div>
        </div>

        <div *ngIf="settings?.commands !== undefined && settings?.commands.length > 0"
            class="row commands seperator-top">
            <div class="command-row flex-row">
                <app-block-button [disabled]="!checkForValidator(command)" *ngFor="let command of settings?.commands"
                    (click)="despatchClickToComponent(command)" class="{{command.class}}" [icon]="command.icon"
                    [width]="command.width || 120" [display]="command.text" [type]="command.type">
                </app-block-button>
            </div>

        </div>
    </ng-container>

    <ng-container *ngIf="settings?.managed === false">
        <ng-template #host></ng-template>
    </ng-container>

</div>

<div class="overlay" *ngIf="settings?.backdrop === true" (click)="onBackdrop()" [@overlay]="state"></div>