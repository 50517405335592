import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { AccessHelper, AppComponent, EMPTY_GUID, RequestorPlaceholderGuid, isDateTimeDefault } from '@summize/shared/core';
import { RequestSubtype, TaskDefinitionModel, TaskNote, TaskType } from '@summize/shared/framework';
import { RequestStage } from '@summize/shared/components-v2';

import { ElipsisCommand } from '../ellipses-menu/ellipses-menu.component';
import { NotesService } from '../notes/notes.service';
import { DueDateStyle, RecurrenceSchedule } from '../due-date/due-date.component';

@Component({
    selector: 'app-task-card',
    templateUrl: 'task-card.html',
    styleUrls: ['./task-card.scss'],
    providers: [NotesService]
})
export class TaskCardComponent extends AppComponent implements OnInit {

    public TaskType = TaskType;

    public RequestSubType = RequestSubtype;

    public RequestStage = RequestStage;

    @Output()
    public onEdit: EventEmitter<TaskDefinitionModel>
        = new EventEmitter<TaskDefinitionModel>();

    @Output()
    public onDelete: EventEmitter<TaskDefinitionModel>
        = new EventEmitter<TaskDefinitionModel>();

    @Output()
    public onComplete: EventEmitter<TaskDefinitionModel>
        = new EventEmitter<TaskDefinitionModel>();

    @Output()
    public onConversation: EventEmitter<TaskDefinitionModel>
        = new EventEmitter<TaskDefinitionModel>();

    @Output()
    public onDocumentClick: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    public model: TaskDefinitionModel;

    @Input()
    public tasks: Array<TaskDefinitionModel>;

    @Input()
    public mode: 'Instance' | 'Definition' = 'Definition';

    @Input()
    public showDocumentName: boolean = false;

    @Input()
    public canCompleteTask: boolean = false;

    @Input()
    public isActiveEdit: boolean = false;

    @Input()
    public displayCommands: boolean = true;

    @Input()
    public compact: boolean = false;

    @Input()
    public stage: RequestStage;

    public instanceCommands!: Array<ElipsisCommand>;

    public canComplete: boolean = false;

    public canCompleteSystemTask: boolean = false;

    public isSystemCompleteEnabled: boolean = false;

    public dueInDays?: Number;

    public dueDate?: Date;

    public dueDateStyle: string = DueDateStyle.Pending;

    public schedule: RecurrenceSchedule = null;

    public timeZoneId: string;

    public cardStyle: string = '';

    public isNotesAvailable: boolean = false;

    public get isComplete(): boolean {

        const complete = this.model?.task?.isComplete;

        return complete === true;

    }

    public get isCompleteDisabled(): boolean {

        return this.isActiveEdit === true &&
            this.model.requestSubType === RequestSubtype.Create &&
            this.model.task.isMandatory === true;

    }

    private get canEditTask(): boolean {

        return this.isFullUser === true || this.model.task.createdBy === this.user.userId;

    }

    constructor(private router: Router, private notesService: NotesService) { super(); }

    public ngOnInit(): void {

        this.isNotesAvailable = this.model.requestSubType === RequestSubtype.Manage;

        this.canComplete
            = this.model.assignments.some(a => a.canComplete === true);

        if (this.model.assignments === undefined || this.model.assignments.length === 0) {

            this.canComplete = AccessHelper.IsFullUser(this.getClaims());

        }

        this.isSystemCompleteEnabled = this.tasks
            ?.filter(t => t.task.taskType !== TaskType.System && t.task.isMandatory === true)
            ?.every(t => t.task.isComplete === true);

        this.canComplete = this.canComplete || this.user.isAdmin;

        this.schedule = this.model.task.taskInstanceSchedule;

        this.setupMenu();
        this.setDueDates();
        this.calculateStyles();

    }

    public complete() {

        this.onComplete.next(this.model);

    }

    public getTypeString(): string {

        const type = parseInt(<any>this.model.task.taskType);

        switch (type) {
            case TaskType.System:
                return 'Default';
            case TaskType.Review:
                return 'Review';
            case TaskType.General:
                return 'General';
            case TaskType.Approval:
                return 'Approval';
            default:
                return '';
        }

    }

    public setDueDates() {

        if (this.mode === 'Definition') {

            this.dueInDays = parseInt(this.model.task.dueInDays);

        } else {

            if (isDateTimeDefault(<any>this.model.task.dueDateUtc) === true) {

                return;

            }

            this.dueDate = new Date(this.model.task.dueDateUtc);

        }

    }

    public getAssignedToString(tooltip: boolean = false): string {

        if (this.model.assignments === undefined || this.model.assignments.length === 0) {

            return 'No assignee';

        }

        const first = this.model.assignments[0];

        const requestorString = 'Requestor';

        let text = first.userId === RequestorPlaceholderGuid ? requestorString : first.groupName;

        const groupIsEmpty = (item) => item.groupName === null || item.groupId === undefined || item.groupId === null || item.groupId === EMPTY_GUID;

        if (groupIsEmpty(first) === true && text !== requestorString) {

            text = first.userName;

        }

        const assignmentTotal = this.model.assignments.length;

        if (tooltip === true) {

            return this.model.assignments.map(a => groupIsEmpty(a) === false ? a.groupName : a.userName).join(', ');
        }

        return `${text} ${assignmentTotal > 1 ? `+${assignmentTotal - 1}` : ``}`;

    }

    public goToDocument(): void {

        const type = RequestSubtype[this.model.requestSubType]
            .toString()
            .toLowerCase();

        if (this.onDocumentClick.observed === true) {

            this.onDocumentClick.emit({
                type,
                documentId: this.model.documentId
            });

        }
        else {

            this.router.navigateByUrl(`/quick-summary/contract/${type}/${this.model.documentId}`);

        }

    }

    public calculateStyles() {

        if (this.mode == 'Definition') {

            this.cardStyle = '';
            this.dueDateStyle = DueDateStyle.Pending;
            return;

        }

        if (this.model.task.isComplete) {

            this.cardStyle = 'complete';
            return;

        }

        if (isDateTimeDefault(this.model.task.dueDate) === true) {

            this.cardStyle = 'grey';
            this.dueDateStyle = DueDateStyle.None;
            return;

        }

        const dueDateUtc = new Date(this.model.task.dueDateUtc);

        const now = new Date();

        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);
        now.setMilliseconds(0);

        if (now > dueDateUtc) {

            this.cardStyle = 'red';
            this.dueDateStyle = DueDateStyle.Overdue;
            return;

        }

        const next48 = new Date();

        next48.setDate(next48.getDate() + 2);

        if (dueDateUtc >= now && dueDateUtc < next48) {

            this.cardStyle = 'orange';
            this.dueDateStyle = DueDateStyle.Upcoming;
            return;

        }

        const further48 = new Date();

        further48.setDate(further48.getDate() + 2);

        this.cardStyle = 'blue';
        this.dueDateStyle = DueDateStyle.Pending;

    }

    public formatTaskCondition(value: string | number): Array<string> {

        if (typeof value !== 'string') {

            return [value.toString()];

        }

        return value.split('|').map(x => x.charAt(0) === ' ' ? x : ` ${x}`);
    }

    public onNotesUpdated(updatedNotes: TaskNote[]): void {

        if (!updatedNotes){

            return;

        }

        this.model.notes = updatedNotes;

    }

    private setupMenu() {

        if (this.model.task.isComplete === true || this.stage === RequestStage.Abandoned || this.displayCommands === false) {

            return;

        }

        this.instanceCommands = [];

        if (this.canEditTask) {

            this.instanceCommands.push(
                {
                    text: 'Edit task',
                    icon: 'pencil-outline',
                    isDisabled: () => this.isCompleteDisabled == true,
                    onClick: () => this.onEdit.emit(this.model)
                }
            );

        }

        if (this.isNotesAvailable && this.hasClaim(this.Claims.Tasks.CanTasksCreate)) {

            this.instanceCommands.push({
                text: 'Add note',
                icon: 'reader-outline',
                onClick: () => {
                    this.notesService.toggleAdd(true);
                }
            });

        }

        if (this.mode === 'Instance' && this.hasClaim(this.Claims.Conversations.CanConversationCreate)) {

            this.instanceCommands.push({
                text: 'New conversation',
                icon: 'chatbox-ellipses-outline',
                onClick: () => { this.onConversation.emit(this.model); }
            });

        }

        if (this.model.task.taskType !== TaskType.System && this.canEditTask) {

            this.instanceCommands.push({
                text: 'Delete task',
                icon: 'trash-outline',
                isDisabled: () => this.isCompleteDisabled == true,
                onClick: () => this.onDelete.emit(this.model)
            });

        }

    }

}