import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { AppComponent } from '@summize/shared/core';

import { ClientMatterMenuService } from './client-matter-menu.service';

export enum ClientMatterVisibleMenu {
    Clients = 0,
    Matters,
    ContractTypes
}

export interface SelectedClientMatter {
    client: any;
    matter?: any;
    docType?: any;
}

@Component({
    selector: 'app-client-matter-menu',
    templateUrl: 'client-matter-menu.component.html',
    styleUrls: ['./client-matter-menu.scss']
})

export class ClientMatterMenuComponent extends AppComponent implements OnInit {

    @Input()
    public display: string = 'Manage';

    @Input()
    public width: string = '140';

    @Input()
    public menuPositionX: 'before' | 'after' = 'before';

    @Input()
    public menuPositionY: 'above' | 'below' = 'below';

    @Input()
    public selectMode: 'client' | 'matter' | 'doctype' = 'doctype';

    @Input()
    public disabled: boolean;

    @Output()
    public onUpdated: EventEmitter<SelectedClientMatter> = new EventEmitter();

    public clients: any = [];

    public clientQuery = '';

    public matterQuery = '';

    public contractTypeQuery = '';

    public matters: any = [];

    public contractTypes: any = [];

    public loading: boolean = false;

    public clientMatterVisibleMenu = ClientMatterVisibleMenu;

    public visibleMenu: ClientMatterVisibleMenu = ClientMatterVisibleMenu.Clients;

    public currentClient: any;

    public currentMatter: any;

    constructor(private clientMatterMenuService: ClientMatterMenuService) { super(); }

    public async ngOnInit() {

        this.visibleMenu = ClientMatterVisibleMenu.Clients;

        await this.getClientsForUser();
    }

    public init() {

        this.visibleMenu = ClientMatterVisibleMenu.Clients;

        this.clientQuery = '';
        this.matterQuery = '';
        this.contractTypeQuery = '';

    }

    public async initSearch() {

        this.loading = true;

    }

    public async getClientsForUser() {

        if (this.clients.length > 0) {
            return;
        }

        this.loading = true;

        this.clients = await this.clientMatterMenuService.getClients();

        this.loading = false;

    }

    public async selectedClient(event: any, clientId: string) {

        this.currentClient = this.clients.find(x => x.clientId === clientId);

        if (this.selectMode === 'client') {

            this.onUpdated.next({
                client: this.currentClient
            });

            return;

        }

        event.stopPropagation();

        this.loading = true;

        this.matters = [];

        if (this.currentClient?.matters === undefined) {

            this.currentClient.matters = await this.clientMatterMenuService.getMattersForClient(clientId);
        }

        this.matters = this.currentClient.matters;

        this.loading = false;

        this.visibleMenu = ClientMatterVisibleMenu.Matters;

    }

    public async selectedMatter(event: any, matterId: string) {

        this.currentMatter = this.matters.find(x => x.matterId === matterId);

        if (this.selectMode === 'matter') {

            this.onUpdated.next({
                client: this.currentClient,
                matter: this.currentMatter
            });

            return;

        }

        event.stopPropagation();

        this.loading = true;

        this.contractTypes = [];

        if (this.currentMatter?.contractTypes === undefined) {

            this.currentMatter.contractTypes = await this.clientMatterMenuService.getContractTypes();
            
        }

        this.contractTypes = this.currentMatter.contractTypes;

        this.loading = false;

        this.visibleMenu = ClientMatterVisibleMenu.ContractTypes;
    }

    public async selectedContractType(contractType: any) {

        if (this.selectMode === 'doctype') {

            this.onUpdated.next({
                client: this.currentClient,
                matter: this.currentMatter,
                docType: contractType
            });

        }

    }

    public showClientsList(event: any) {

        event.stopPropagation();

        this.visibleMenu = ClientMatterVisibleMenu.Clients;
    }

    public showMattersList(event: any) {

        event.stopPropagation();

        this.visibleMenu = ClientMatterVisibleMenu.Matters;
    }

}
