const invalidCharRegex = /[\/:*?"<>|\\]/g;
const consecutiveDotsRegex = /\.{2,}/g;
const leadingOrTrailingSpaceDotsRegex = /^[\s\.]+|[\s\.]+$/g;

export class FileNameUtil {

    public static sanitize(fileName: string, extension: string): string {

        let sanitized = fileName.replace(invalidCharRegex, '_');
        sanitized = sanitized.replace(consecutiveDotsRegex, '.');
        sanitized = sanitized.replace(leadingOrTrailingSpaceDotsRegex, '');

        if (sanitized.toLowerCase().endsWith(extension) === false) {
            sanitized += extension;
        }

        return sanitized;
    }

    public static removeExtension(fileName: string): string {

        return fileName.replace(/\.[^/.]+$/, '');

    }

}