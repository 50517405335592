import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { UserService } from './services/user.service';
import { AuthService } from './services/auth.service';
import { OidcAuthService } from './services/oidc-auth.service';
import { CorrelationIdService } from './services/correlation.service';
import { HttpTokenInterceptor } from './interceptors/http-token.interceptor';
import { EventService } from './services/event.service';
import { PreviewFeatureService } from './services/preview-feature.service';

@NgModule({
    imports: [CommonModule],
    providers: [
        AuthService,
        OidcAuthService,
        UserService,
        EventService,
        PreviewFeatureService,
        CorrelationIdService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpTokenInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class FrameworkModule { }

export * from './types';
export * from './events';
export * from './services/_index';
export * from './animations/_index';
export { InterceptorSkipAuthValidation, InterceptorSkipAuthBearer } from './interceptors/http-token.interceptor';
export * from './claims';
export * from './feature-flag-guard.service';
export * from './exclude-requestor-user.guard';
export * from './pending-changes.guard';