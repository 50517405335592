import { AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { SummizeStorage } from '@summize/shared/framework';

@Component({
    selector: 'app-dialog-shell',
    templateUrl: 'dialog-shell.html',
    styleUrls: ['./dialog-shell.scss']
})

export class DialogShellComponent implements AfterViewInit {

    @Input()
    public title: string;

    @Input()
    public showHeader: boolean = true;

    @Input()
    public showClose: boolean = true;

    @Input()
    public showFooter: boolean = false;

    @Input()
    public showBack: boolean = false;

    @Input()
    public cancelText: string = 'Cancel';

    @Input()
    public saveText: string = 'Save';

    @Input()
    public backText: string = 'Back';

    @Input()
    public backDisabled: boolean = false;

    @Input()
    public saveColorClass: string = 'default';

    @Input()
    public saveDisabled: boolean = false;

    @Input()
    public saveButtonWidth: number = 100;

    @Input()
    public headerTemplate: TemplateRef<any>;

    @Output()
    public onClose: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public onSave: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public onBack: EventEmitter<void> = new EventEmitter<void>();

    public isWordAddIn: boolean = false;

    public ngAfterViewInit(): void {

        this.isWordAddIn = SummizeStorage.IsWordAddIn();

        if (SummizeStorage.IsWordAddIn() === true) {

            const dialogShellContainer: any = document.querySelector('.dialog-shell-container');

            if (dialogShellContainer !== undefined) {

                dialogShellContainer.style.minWidth = 'unset';

            }

        }

        if (SummizeStorage.IsOutlook() === true) {

            const overlayPane: any = document.querySelector('.cdk-overlay-pane');

            if (overlayPane !== undefined) {

                overlayPane.style.width = '100vw';

                overlayPane.style.height = '100vh';

                overlayPane.style.maxWidth = '100vw';

            }

        }

    }

}
