import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chat-separator',
  templateUrl: 'chat-separator.component.html',
  styleUrls: ['./chat-separator.component.scss']
})
export class ChatSeparatorComponent {

  @Input()
  public text: string = undefined;

}