
<div class="toast__container">
    <div class="toast {{type}}" [@showFlyInFlyOut]>
        <div class="icon {{icon}} toast__icon"></div>
        <h5 class="toast__text">{{text}}</h5>

        <div class="toast-close">
            <svg class="toast-close__progress" width="32" height="32">
                <circle pathLength="100" class="toast-close__progress-circle" fill="transparent" r="13" cx="50%"
                    cy="50%" />
            </svg>
            <i class="icon icon-close toast-close__icon" (click)="close()"></i>
        </div>
    </div>
</div>