import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { QuillModule } from 'ngx-quill';

import { IconModule } from '@summize/shared/components-v2';

import { ChatComponent } from './components/chat/chat.component';
import { ChatWelcomeComponent } from './components/chat-welcome/chat-welcome.component';
import { ChatInputComponent } from './components/chat-input/chat-input.component';
import { ChatThreadComponent } from './components/chat-thread/chat-thread.component';
import { ChatMessagesComponent } from './components/chat-messages/chat-messages.component';
import { ChatSeparatorComponent } from './components/chat-separator/chat-separator.component';
import { ChatTokenLimitComponent } from './components/chat-tokenlimit/chat-tokenlimit.component';

@NgModule({
  declarations: [
    ChatComponent,
    ChatInputComponent,
    ChatMessagesComponent,
    ChatSeparatorComponent,
    ChatThreadComponent,
    ChatTokenLimitComponent,
    ChatWelcomeComponent
  ],
  exports: [
    ChatComponent,
    ChatInputComponent,
    ChatMessagesComponent,
    ChatSeparatorComponent,
    ChatThreadComponent,
    ChatTokenLimitComponent,
    ChatWelcomeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    IconModule,
    MatTooltipModule,
    MatMenuModule,
    MatProgressSpinnerModule
  ]
})
export class ChatModule { }
