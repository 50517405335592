import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KpiModule } from '@summize/feature/analytics';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

import { ChartWidgetComponent } from './chart-widget.component';
import { ChartWidgetService } from './chart-widget.service';

@NgModule({
    imports: [
        CommonModule,
        LoadingSpinnerModule,
        KpiModule
    ],
    exports: [
        ChartWidgetComponent
    ],
    declarations: [
        ChartWidgetComponent
    ],
    providers: [
        ChartWidgetService
    ]
})
export class ChartWidgetModule { }

export * from './chart-widget.component';