<div [@fadeInAnimation] class="client-matter-select flexible-layout">
  <div *ngFor="let client of model" class="client-container fade-block" [class.expanded]="client.expanded">
    <div class="row client-row">
      <div class="expand-icon clickable" (click)="expand(client)">
        <div class="icon icon-next"></div>
      </div>
      <div class="column">
        <div class="client-name">
          {{client.name}}
        </div>
        <div class="client-count" *ngIf="client.matters !== undefined && client.matters.length > 0">
          <span class="dot {{getSelectedState(client)}}"></span>
          {{getSelectedCount(client)}} of {{client.matters.length}} {{ 'Matters' | clientmatterlocalizer }} Selected
        </div>
        <div class="client-count" *ngIf="client.matters === undefined || client.matters.length === 0">
          {{ 'This client has no matters.' | clientmatterlocalizer }}
        </div>
      </div>
      <div class="column">
        <div class="select-all matters">
          All {{ 'Matters' | clientmatterlocalizer }}
          <div class="toggle">
            <mat-slide-toggle [checked]="client.allFutureMatters"
              (change)="setAllMattersToValue(client,$event.checked)">
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </div>
    <div class="matter-container" *ngIf="client.expanded === true">

      <ng-container *ngIf="client.matters !== undefined && client.matters.length > 0">
        <div class="select-all clickable" (click)="selectAll(client)">
          Select All
        </div>
        <div class="matter-checkbox" *ngFor="let matter of client.matters">
          <mat-checkbox [disabled]="client.allFutureMatters === true" [checked]="matter.selected"
            (change)="matter.selected = $event.checked" class="checkbox">
            {{matter.name}}
          </mat-checkbox>
        </div>
      </ng-container>

      <ng-container *ngIf="client.matters === undefined || client.matters.length === 0">
        <div class="select-all">
          {{ 'This client has no matters.' | clientmatterlocalizer }}
        </div>
      </ng-container>
    </div>
  </div>
</div>