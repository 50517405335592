<app-dialog-shell [showHeader]="true" [showClose]="false" [headerTemplate]="headerTemplate" (onClose)="close()">

    <ng-template #headerTemplate>
        <div class="header-container">
            <div class="header-body">
                <div *ngIf="pageNum === 1" class="page1">
                    <img class="img" src="./assets/images/request-analytics-dashboard-intro-1.svg" />
                </div>

                <div *ngIf="pageNum === 2" class="page2">
                    <img class="img" src="./assets/images/request-analytics-dashboard-intro-2.svg" />
                </div>

                <div *ngIf="pageNum === 3" class="page3">
                    <img class="img" src="./assets/images/request-analytics-dashboard-intro-3.svg" />
                </div>

                <div *ngIf="pageNum === 4" class="page4">
                    <img class="img" src="./assets/images/request-analytics-dashboard-intro-4.svg" />
                </div>
            </div>
        </div>
    </ng-template>

    <div class="content-area">
        <div *ngIf="pageNum === 1" class="page1">
            <span class="header mb-24"><app-icon icon="star" class="star"></app-icon>
                <h2> Welcome to Request Analytics!</h2>
            </span>

            <p class="mb-24">Our latest feature, Request Analytics, empowers you and your team to build custom
                dashboards that suit your business requirements and goals. Uncover essential insights into usage,
                engagement, and workload metrics!</p>
            <p class="mb-24">You can visit our <a href="https://customers.summize.com/hub/request-analytics" target="_blank"
                    class="link chub">Customer Hub</a> to learn about Request Analytics, but here are 3 essential tips to
                help get you get started...</p>
        </div>
        <div *ngIf="pageNum === 2">
            <h2 class="mb-24">1. Create dashboards with a purpose</h2>

            <p class="mb-24">Whether it's monitoring team workload, understanding engagement or analysing different
                contracts types, the Request Analytics feature helps you create custom dashboards for many different
                purposes! Mix and match charts and filters to create tailored insights and get in-depth data about your
                contract portfolio and users.</p>
        </div>
        <div *ngIf="pageNum === 3">
            <h2 class="mb-24">2. Browse, add, customise, and save</h2>

            <p class="mb-24">To build your dashboard, click 'Add chart' and browse our library of available charts. Then
                simply add the ones you want to see! Once a chart has been added, you can refine the data by applying
                filters and save your configurations to ensure their insights are always accessible.</p>
        </div>
        <div *ngIf="pageNum === 4">
            <h2 class="mb-24">3. See the underlying data</h2>

            <p class="mb-24">When you need to spot outliers or get even more in-depth, you can toggle to the table view
                to see the raw data used to build each chart.</p>

            <div>
                <mat-checkbox [checked]="showAgain !== true" (change)="showAgain = !$event.checked" class="mb-24">Don't
                    show this message again</mat-checkbox>
            </div>
        </div>
    </div>
    <div class="commands">
        <app-block-button *ngIf="pageNum > 1" icon="arrow-back" class="mr-a link" (click)="back()" display="Back"
            type="v2-blue" width="140" data-cy="modal-back">
        </app-block-button>

        <app-block-button *ngIf="pageNum < maxPageNum" icon="arrow-forward" class="link" iconPosition="right"
            (click)="next()" [display]="nextLabel()" type="v2-primary" width="140" data-cy="modal-next">
        </app-block-button>

        <app-block-button *ngIf="pageNum === maxPageNum" class="link" (click)="finish()" display="Let's get started!"
            type="v2-primary" width="180" data-cy="modal-confirm">
        </app-block-button>
    </div>
</app-dialog-shell>