import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseTenantService } from '@summize/shared/core';
import { PendingNotificationsResponse } from '@summize/shared/framework';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService extends BaseTenantService {

    constructor(public http: HttpClient) {

        super();
    }

    public async getUserNotifications(): Promise<PendingNotificationsResponse> {

        const baseUrl = this.getUserBaseUrl(true);

        const url = `${baseUrl}notifications/me`;

        return await this.asPromiseFrom<PendingNotificationsResponse>(this.http.get<PendingNotificationsResponse>(url));

    }

}


