<app-dialog-shell (onClose)="close()" (onSave)="viewDocument()" [saveDisabled]="isSaveDisabled()" [showFooter]="true"
  title="A new version has been added" saveText="Refresh" cancelText="Ignore" saveButtonWidth="175">
  <div class="conversation">
    <div class="section">
        <p>
            You are no longer working from the current version of this 
            {{ displayMode | lowercase }}
            and must refresh this page before you can make changes.
        </p>
      <p>
        Any unsaved changes such as adding, editing or deleting a task, conversation or clause bullet can no longer be
        applied but text changes can be copied before refreshing the page by clicking 'Ignore'.
      </p>
      <p>
        Click 'Refresh' to continue, or click 'Ignore' if you need to copy any unsaved text before refreshing.
      </p>
      <div *ngIf="isLoading === true">
        <div class="spinner">
          <app-loading-spinner visible="true"></app-loading-spinner>
        </div>
      </div>

    </div>
  </div>
</app-dialog-shell>