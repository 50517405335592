import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';

import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TableService extends BaseTenantService {

    constructor(private httpClient: HttpClient) { super() }

    public fetch(url: string, sortField: string, sortDir: string, page: number, pageSizenumber: number, query: string | undefined,
        queryProvider?: () => string, isPostRequest: boolean = false, postStateProvider?: (state: any) => any): Observable<any> {

        if (isPostRequest === false) {

            let requestUrl =
                `${this.getUserBaseUrl()}${url}?sortField=${sortField}&order=${sortDir}&top=${pageSizenumber}&take=${pageSizenumber}&skip=${page * pageSizenumber}`;

            if (query !== undefined && query !== '') {

                requestUrl = `${requestUrl}&query=${query}`;

            }

            if (queryProvider !== undefined) {

                requestUrl = `${requestUrl}${queryProvider()}`

            }

            return this.httpClient.get(requestUrl);

        } else {

            let state: any = {};

            if (queryProvider !== undefined) {

                query = queryProvider();

            }

            for (const para of query.split('&')) {

                if (para === '') {

                    continue;

                }

                const val = para.split('=');

                let data: object | string;

                try {
                    data = JSON.parse(val[1]);

                } catch (err) {

                    data = val[1];
                }

                state[val[0]] = data;

            }

            state.sortField = sortField;
            state.order = sortDir;
            state.top = pageSizenumber;
            state.take = pageSizenumber;
            state.skip = page * pageSizenumber;

            if (postStateProvider !== undefined) {

                state = postStateProvider(state);

            }

            let requestUrl = `${this.getUserBaseUrl()}${url}`;

            return this.httpClient.post(requestUrl, state);

        }

    }
}