import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CreateConversationComponent } from './create-conversation.component';
import { DialogShellModule } from '../dialog-shell/dialog-shell.module';
import { ConversationInputModule } from '../conversation-input/conversation-input.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DialogShellModule,
        ConversationInputModule
    ],
    exports: [
        CreateConversationComponent
    ],
    declarations: [
        CreateConversationComponent
    ]
})
export class CreateConversationModule { }

export * from './create-conversation.component'