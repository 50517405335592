@for (question of questions; track question.id; let i = $index) {
    <div class="question-container">
        <label [class.required]="isFullUser === false" for="question-{{question.id}}">{{question.text}}</label>

        @if(isQuestionBuilderType(question.type) === true) {

            @switch (question.type) {
                @case (QuestionDefinitionType.FreeText) {
                <input [(ngModel)]="question.value" id="question-{{question.id}}" type="text" class="smz-standard-input v2" />
                }
                @case (QuestionDefinitionType.Numeric) {
                <input [(ngModel)]="question.value" id="question-{{question.id}}" type="number" class="smz-standard-input v2" />
                }
                @case (QuestionDefinitionType.Datepicker) {
                <input [(ngModel)]="question.value" id="question-{{question.id}}" type="date" class="smz-standard-input v2" />
                }
                @case (QuestionDefinitionType.Dropdown) {
                <app-select-box (changed)="question.value = $event" [value]="question.value" [options]="question.meta.values" />
                }
                @case (QuestionDefinitionType.MultiSelect) {
                <app-select-box (changed)="question.value = $event" [value]="question.value" [options]="question.meta.values"
                    [multipleSelect]="true" />
                }
            }

            @if(question.meta?.longDescription) {
                <small class="helper-text">{{question.meta?.longDescription}}.</small>
            }

        } @else {
            <input [(ngModel)]="question.value" id="question-{{question.id}}" type="text" class="smz-standard-input v2" />
        }

    </div>
}