import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PillComponent } from './pill.component';

@NgModule({
    imports: [
        CommonModule,
        MatTooltipModule,
    ],
    exports: [PillComponent],
    declarations: [PillComponent],
})
export class PillModule { }

export * from './pill.component';
