export class RegexUtil {
  public static replaceAll(input: string, from: string, to: string): string {
    const regex = new RegExp(this.escape(from), 'g');

    return input.replace(regex, to);
  }

  public static escape(input: string): string {
    return input
      .replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
      .replace(/-/g, '\\x2d');
  }
}