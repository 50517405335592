import { Component, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject, combineLatest } from 'rxjs';

import { AppComponent, ChangeDetectionHelper, HEADER_HEIGHT, LayoutHelper } from '@summize/shared/core';
import {
    DashboardRequest,
    DashboardDetailResponse,
    DateFilter,
    EventService,
    LocalStorageService,
    SessionStorageService,
    WidgetFilterRequest,
    WidgetResponse,
    WidgetTimeFormat,
    DashboardsResponse,
    CanHandlePendingChanges
} from '@summize/shared/framework';

import { DashboardService } from './dashboard.service';
import { RenameDashboardModalComponent } from './rename-dashboard-modal/rename-dashboard-modal.component';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { DashboardIntroModalComponent } from './dashboard-intro-modal/dashboard-intro-modal.component';
import { NewDashboardModalComponent } from './new-dashboard-modal/new-dashboard-modal.component';

@Component({
    selector: 'app-dashboard',
    templateUrl: 'dashboard.html',
    styleUrl: './dashboard.scss',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class DashboardComponent extends AppComponent implements CanHandlePendingChanges {

    @HostListener('window:unload', ['$event'])
    public clearSessionStorage(event: any) {

        this.clearDashboardFromStorage();
    }

    public dashboardsResponse: DashboardsResponse = {

        dashboards: []
    };

    public selectedDashboard: DashboardDetailResponse;

    public isDashboardDropdownOpen = false;

    public isLoading = true;

    public newWidget: WidgetResponse;

    public dashboardCommands = [{
        text: 'Rename dashboard',
        icon: 'pencil-outline',
        onClick: () => this.renameDashboard()
    },
    {
        text: 'Make my default',
        icon: 'star-outline',
        isDisabled: () => this.canMakeDefault(),
        onClick: () => this.makeDefaultDashboard()
    },
    {
        text: 'Delete dashboard',
        icon: 'trash-outline',
        isDisabled: () => this.canDelete(),
        onClick: () => this.removeDashboard()
    }];

    private storageKey = 'request-dashboard-current';

    private showIntro = 'show-request-analytics-intro';

    private newWidgetId: string;

    public cancelRequests = new Subject<void>();

    constructor(private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private storageService: SessionStorageService,
        private localStorageService: LocalStorageService,
        private events: EventService,
        private dashboardService: DashboardService) {

        super();

    }

    public hasPendingChanges(nextState: RouterStateSnapshot): boolean {

        // Don't deactivate if we are going to the library to add more widgets
        if (nextState.url.includes('dashboards/library')) {

            return false;
        }

        return this.pendingChanges;
    }

    public clearPendingChanges() {

        this.clearDashboardFromStorage();
    }

    public canDelete() {

        return this.dashboardsResponse?.dashboards?.length === 1;

    }

    public canMakeDefault() {

        return this.selectedDashboard?.isDefault === true;
    }

    public async ngOnInit() {

        this.isLoading = true;

        if (this.dashboardsResponse?.dashboards?.length === 0) {

            this.dashboardsResponse = await this.dashboardService.getDashboards();
        }

        const params = combineLatest([this.route.params, this.route.queryParams]);

        this.subscribe(params, async (args) => {

            const [params, queryParams] = args;

            this.newWidgetId = queryParams.widgetId;

            if (params.id !== undefined) {

                // Go to specific dashboard
                await this.loadDashboard(params.id);

                this.showWelcomeScreen();

            } else if (this.dashboardsResponse.dashboards?.length > 0) {

                // Go to default or first in list
                const id = this.dashboardsResponse.dashboards.find(x => x.isDefault === true)?.id 
                ?? this.dashboardsResponse.dashboards[0].id;

                this.goToDashboard(id);

            }
        });

        ChangeDetectionHelper.doNextCycle(() => {

            const height = LayoutHelper.getWindowHeight() - HEADER_HEIGHT;

            document
                .querySelector('.dashboard-host')
                .setAttribute('style', `height:${height - 3}px`);

        });

    }

    public async saveDashboard() {

        const payload: DashboardRequest = {
            name: this.selectedDashboard.name,
            isDefault: this.selectedDashboard.isDefault,
            context: {
                widgets: this.selectedDashboard.widgets.map(x => {

                    return {
                        widgetId: x.id,
                        appliedFilters: x.appliedFilters,
                        dateFilter: x.dateFilter,
                        timeFormat: x.timeFormat
                    }

                })
            }
        }

        let dashboard;

        if (this.selectedDashboard.id === this.EMPTY_GUID) {

            dashboard = await this.dashboardService.saveDashboard(payload);

            this.events.actionComplete('Dashboard saved');

            this.pendingChanges = false;

        } else {

            dashboard = await this.dashboardService.updateDashboard(this.selectedDashboard.id, payload);

            this.events.actionComplete('Dashboard updated');

            this.pendingChanges = false;
        }

        this.clearDashboardFromStorage();

        this.goToDashboard(dashboard.id);
    }

    public goToDashboard(id: string) {

        this.router.navigate(['quick-summary', 'dashboards', id]);
    }

    public async loadDashboard(id: string) {

        this.isLoading = true;

        if (this.newWidgetId !== undefined) {

            this.newWidget = await this.getWidgetById(this.newWidgetId);

            this.router.navigate([], {
                queryParams: {
                    'widgetId': null
                },
                queryParamsHandling: 'merge'
            });

            this.events.actionComplete('Chart added');

            this.pendingChanges = true;

            return;
        }

        const existingDashboard = await this.dashboardService.getDashboardDetailById(id);

        const localDashboard = this.getDashboardFromStorage();

        if (existingDashboard?.id === localDashboard?.id) {

            if (existingDashboard.widgets !== localDashboard.widgets) {

                this.pendingChanges = true;

            }

            existingDashboard.widgets = localDashboard.widgets;

            this.selectedDashboard = existingDashboard; // Use local changes

        } else {

            this.selectedDashboard = existingDashboard;

        }

        if (this.newWidget !== undefined) {

            this.selectedDashboard.widgets.push(this.newWidget);

            this.newWidget = undefined;

            this.setDashboardToStorage();
        }

        this.isDashboardDropdownOpen = false;

        this.isLoading = false;

    }

    public addChart() {

        this.router.navigate(['/quick-summary', 'dashboards', 'library'], { queryParams: { dashboardId: this.selectedDashboard.id ?? this.EMPTY_GUID } });
    }

    public createDashboard() {

        if (this.selectedDashboard.id !== this.EMPTY_GUID) {

            this.dialog.open(NewDashboardModalComponent).afterClosed()
                .subscribe(async (x: any) => {

                    if (x?.name !== undefined && /\S/.test(x.name)) {

                        const payload = {
                            ...x,
                            context: {
                                widgets: []
                            }
                        };

                        try {

                            const dashboard = await this.dashboardService.saveDashboard(payload);

                            this.dashboardsResponse = await this.dashboardService.getDashboards();

                            this.events.actionComplete('Dashboard created');

                            this.router.navigate(['quick-summary', 'dashboards', dashboard?.id]);
                        }
                        catch (err) {

                            if (err.status === 409) {

                                this.events.actionFailed('Dashboard name already exists');
                            }

                        }
                    }
                });
        }

    }

    public onFiltersChange(idx: number, filters: WidgetFilterRequest[]) {

        this.pendingChanges = true;

        this.setDashboardToStorage();

    }

    public onTimeframeChange(idx: number, dateFilter: DateFilter) {

        this.selectedDashboard.widgets[idx].dateFilter = dateFilter;

        this.pendingChanges = true;

        this.setDashboardToStorage();

    }

    public onTimeFormatChange(idx: number, timeFormat: WidgetTimeFormat) {

        this.selectedDashboard.widgets[idx].timeFormat = timeFormat;

        this.pendingChanges = true;

        this.setDashboardToStorage();

    }

    public onDeleteWidget(idx: number) {

        this.selectedDashboard.widgets.splice(idx, 1);

        this.pendingChanges = true;

        this.setDashboardToStorage();

    }

    private renameDashboard() {

        this.dialog.open(RenameDashboardModalComponent, {
            data: {
                dashboardName: this.selectedDashboard.name
            }
        }).afterClosed()
            .subscribe(async x => {

                if (x !== undefined && x !== null && x.length > 0) {

                    try {

                        await this.dashboardService.updateDashboard(this.selectedDashboard.id, {
                            name: x,
                            isDefault: this.selectedDashboard.isDefault
                        });

                        this.selectedDashboard.name = x;

                        this.dashboardsResponse = await this.dashboardService.getDashboards();

                        this.setDashboardToStorage();

                        this.events.actionComplete('Dashboard updated');
                    }
                    catch (err) {

                        if (err.status === 409) {

                            this.events.actionFailed('Dashboard name already exists');
                        }

                    }

                }
            });
    }

    private removeDashboard() {

        this.dialog.open(ConfirmationModalComponent, {
            data: {
                header: 'Delete Dashboard',
                text: `This dashboard will be deleted for all users and cannot be undone. Are you sure you want to delete this dashboard?`,
                confirmButton: 'Delete'

            }
        }).afterClosed()
            .subscribe(async x => {

                if (x === true) {

                    await this.dashboardService.deleteDashboard(this.selectedDashboard.id);

                    this.events.actionComplete('Dashboard deleted');

                    this.router.navigate(['quick-summary', 'dashboards']);
                }
            });
    }

    public async makeDefaultDashboard() {

        try {

            await this.dashboardService.updateDashboardDefault(this.selectedDashboard.id);

            this.selectedDashboard.isDefault = true;

            this.dashboardsResponse = await this.dashboardService.getDashboards();

            this.setDashboardToStorage();

            this.events.actionComplete('Default dashboard set');
        }
        catch (err) {

            this.events.actionFailed('Unable to set dashboard as default');

        }
    }

    private async getWidgetById(widgetId: string) {

        const widget = await this.dashboardService.getWidgetById(widgetId);

        return widget;
    }

    private getDashboardFromStorage() {

        try {

            const wipDashboard = this.storageService.getItem(this.storageKey);

            return wipDashboard ? JSON.parse(wipDashboard) : undefined;

        } catch {

            return undefined;
        }
    }

    private setDashboardToStorage() {

        try {

            this.storageService.setItem(this.storageKey, JSON.stringify(this.selectedDashboard));
        }
        catch (err) {

            console.log('Unable to set dashboard locally - ' + err);
        }

    }

    private clearDashboardFromStorage() {

        this.storageService.removeItem(this.storageKey);

    }

    private showWelcomeScreen() {

        if (this.isELFD === true) {

            return;

        }

        const showIntro = this.localStorageService.getItem(this.showIntro);

        if (showIntro === null || showIntro === undefined || showIntro === 'true') {

            setTimeout(() => {

                this.dialog.open(DashboardIntroModalComponent, { disableClose: true }).afterClosed()
                    .subscribe(async (x: any) => {

                        if (x?.showAgain === false) {

                            this.localStorageService.setItem(this.showIntro, 'false');

                        }

                    });

            }, 1000);
        }
    }

}