export enum PlatformType {
    Outlook,
    Gmail,
    GmailBridge,
    Salesforce,
    Jira,
    Hubspot,
    WebApp = 999,
}

export interface PlatformAttachments {
    emailAttachments: Array<any>;
    allAttachments: Array<any>;
    context: any;
}

export interface ExternalContextChangedEvent {
    name: string;
}

export interface PlatformSuggestion {
    name?: string;
    key?: string;
    value?: any;
}

export const PlatformBridgeEvent = 'PlatformBridgeEvent';
export const PlatformContextChanged = 'PlatformContextChanged';
