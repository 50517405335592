import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatMenuModule } from '@angular/material/menu';
import { PortalModule } from '@angular/cdk/portal';

import { EllipsesMenuComponent } from './ellipses-menu.component';
import { IconModule } from '../icon/icon.module';
import { DirectivesModule } from '../directives/directives.module';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        MatMenuModule,
        PortalModule,
        DirectivesModule
    ],
    exports: [
        EllipsesMenuComponent
    ],
    declarations: [
        EllipsesMenuComponent
    ]
})
export class EllipsesMenuModule { }

export * from './ellipses-menu.component';
