import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TableModule } from '@summize/shared/components';
import { ContextBarModule, IconModule } from '@summize/shared/components-v2';

import { ClauseResultTableComponent } from './clause-result-table.component';

@NgModule({
    imports: [
        CommonModule,
        ContextBarModule,
        TableModule,
        IconModule,
        FormsModule
    ],
    exports: [],
    declarations: [ClauseResultTableComponent],
    providers: [],
})
export class ClauseResultTableModule { }
