import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToastComponent } from './toast.component';
import { ToastContainerComponent } from './toast-container.component';
import { ToastFeedService } from './toast-feed.service';
import { IconModule } from '../icon/icon.module';

@NgModule({
    imports: [
        CommonModule,
        IconModule
    ],
    exports: [
        ToastComponent,
        ToastContainerComponent
    ],
    declarations: [
        ToastComponent,
        ToastContainerComponent
    ]
})
export class ToastModule {

    static forRoot(): ModuleWithProviders<ToastModule> {
        return {
            ngModule: ToastModule,
            providers: [
                ToastFeedService
            ]
        };
    }
}

export * from './toast.component'
export * from './toast-container.component';
export * from './toast-feed.service';