import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';

export interface ChangeContractTypeModel {
    contractTypes: Array<ContractType>
}

interface ContractType {
    documentTypeId: string;
    name: string;
}

@Injectable({ providedIn: 'root' })
export class ChangeContractTypeService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getViewModel(): Promise<ChangeContractTypeModel> {

        const baseUrl = this.getUserBaseUrl(true);

        return this.http
            .get<Array<ContractType>>(`${baseUrl}documentTypes?type=ContractReview`)
            .toPromise()
            .then((contractTypes: Array<ContractType>) => {

                return {
                    contractTypes
                };

            });

    }

}
