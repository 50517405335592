import { Component, ChangeDetectionStrategy, Input, HostBinding, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-block-button',
    templateUrl: 'block-button.html',
    styleUrls: ['./block-button.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlockButtonComponent {

    @Output()
    public onIconClick: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    public hasIconClickEvent: boolean = false;

    @Input()
    public display: string;

    @Input()
    public prefix: string;

    @Input()
    public icon: string;

    @Input()
    public iconPosition: 'left' | 'right' = 'left';

    @Input()
    public iconColor: string;

    @Input()
    public type: 'block' | 'outline' | 'outline-grey' | 'ghost' | 'v2' | 'v2-blue' | 'v2-primary' | 'v2-ghost' | 'filter' | 'text-primary' | 'filter-selected' | 'dropdown-ghost' | 'positive-action' = 'block';

    @Input()
    public colorClass: 'default' | 'danger' | 'success' = 'default';

    @Input()
    public width: number = 235;

    @Input()
    public disabled: boolean = false;

    @Input()
    public btnType: string;

    @Input()
    public label: string;

    @Input()
    public fontSize?: 'small' | 'regular' = undefined;

    @Input()
    public customClass: string = '';

    @HostBinding('style.pointer-events')
    get pEvents(): string {
        if (this.disabled) {
            return 'none';
        }
        return 'auto';
    }

    public onIconClicked(event: any): void {

        if (this.hasIconClickEvent === true) {

            event.stopPropagation();

            event.preventDefault();

            this.onIconClick.next(undefined);

        }
    }

    public getWidth() {

        if (this.width === 0) {

            return '100%';

        }

        if (this.width === -1) {

            return 'auto';
        }

        return `${this.width}px`;

    }

    public getCssClass(): string {

        const cssClasses = ['block-button', this.type, `block-button-${this.colorClass}`];

        if (this.fontSize !== undefined) {

            cssClasses.push('block-button-font-size-' + this.fontSize);

        }

        if (this.customClass !== '') {

            cssClasses.push(this.customClass);

        }

        return cssClasses.join(' ');

    }
}