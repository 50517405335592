<mat-form-field [class.error]="errorState" class="select-box" appearance="fill">

  <mat-select [multiple]="multiple" [placeholder]="placeholder" [value]="value" [required]="isRequired"
    panelClass="select-panel" [disabled]="disabled" disableOptionCentering
    (selectionChange)="valueChanged($event.value)" [class.disabled]="disabled" (closed)="onClearSearch()">

    <mat-select-trigger>
      <div class="selected-value"><span *ngIf="selected.group" class="selected-group">{{selected.group}} -
        </span>{{selected.value}}</div>
    </mat-select-trigger>

    <div class="search-box">
      <app-search-bar placeholder="Search..." (onSearch)="onKey($event)"
        (onClearSearch)="onClearSearch()"></app-search-bar>
    </div>
    <ng-container *ngIf="groups !== undefined">
      <mat-optgroup *ngFor="let group of groups; let index = index">
        <div class="group-key">{{group.key}}<span *ngIf="group.description" class="group-description"> -
            {{group.description}}</span></div>
        <mat-option *ngFor="let opt of group.value" [value]="opt.key" [matTooltip]="tooltips ? opt.value : undefined">
          {{opt.value}}
        </mat-option>
      </mat-optgroup>
    </ng-container>
  </mat-select>
</mat-form-field>