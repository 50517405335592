<div class="flexible-layout">
    <div class="row info-panel">
        <div class="icon-container">
            <div class="icon icon-tick"></div>
        </div>
        <div class="column">
            <div class="title">{{title}}</div>
            <div class="text">{{text}}</div>
        </div>
    </div>
</div>