import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './rename-dashboard-modal.html',
    styleUrl: './rename-dashboard-modal.scss'
})
export class RenameDashboardModalComponent {

    public dashboardName: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<RenameDashboardModalComponent>) {

        this.dashboardName = this.data.dashboardName;

    }

    public close() {

        this.dialogRef.close(undefined);

    }
}