import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';

export interface Clause {
    documentTypeName: string;
    documentTypeId: string;
    displayName: string;
    clauseId: string;
    standardPositionLink: string;
}

@Injectable({ providedIn: 'root' })
export class ClauseSelectorService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getClauses(mode: string, languageCode: string): Promise<Array<Clause>> {

        let url = `${this.getUserBaseUrl(true)}documentTypes/Clauses?type=ContractReview`;

        if(mode !== undefined && mode !== ''){
            url += `&resultType=${mode}`;
        }

        if(languageCode !== undefined && languageCode !== ''){
            url += `&languageCode=${languageCode}`;
        }

        return this.http.get<Array<Clause>>(url).toPromise();

    }

}