<app-dialog-shell title="New Dashboard" (onClose)="close()">
    <div class="content-area">
        <span>Please enter a name for your dashboard to continue. All dashboards will be visible to Full and Admin users.</span>
        <input [(ngModel)]="dashboardName" name="text" class="smz-standard-input v2" required autocomplete="off">
        <mat-checkbox [checked]="isDefault" (change)="isDefault = !isDefault" class="default-checkbox">
            Make this my default dashboard
        </mat-checkbox>
    </div>
    <div class="commands">
        <app-block-button mat-dialog-close [display]="'Cancel'" type="v2-blue" width="100" data-cy="modal-cancel">
        </app-block-button>
        <app-block-button [disabled]="dashboardName?.length === 0" (click)="close()"
            [display]="'Create dashboard'" [width]="150" type="v2-primary" data-cy="modal-confirm">
        </app-block-button>
    </div>
</app-dialog-shell>