<app-dialog-shell title="Add Graph" (onClose)="close()" (onSave)="save()" [showFooter]="true">
    <div class="add-graph">
        <ng-container *ngIf="isLoading === true">
            <app-loading-spinner visible="true"></app-loading-spinner>
        </ng-container>
        <ng-container *ngIf="isLoading === false">
            <div class="section-area">
                <div class="text">
                    Select Contract Type
                </div>
                <div class="section-content">
                    <app-select-box (changed)="contractTypeChanged($event)" [value]="selectedDocumentType" [options]="documentTypes"></app-select-box>
                </div>
            </div>
            <div class="section-area">
                <div class="text">
                    Select clause
                </div>

                <div *ngIf="isClausesLoading === true">
                    <app-loading-spinner visible="true"></app-loading-spinner>
                </div>

                <div *ngIf="clauses !== undefined && isClausesLoading === false" class="section-content">
                    <div *ngFor="let clause of clauses" class="clause-bar">
                        <div class="check-box">
                            <mat-checkbox [(ngModel)]="clause.checked">
                                {{clause.clauseName}}
                            </mat-checkbox>
                        </div>
                        <div class="pill">
                            {{clause.type}}
                        </div>
                    </div>
                </div>
                <div *ngIf="clauses === undefined && isClausesLoading === false" class="section-content">
                    <div class="no-results">
                        <img src="/assets/images/analytics__nocontact.svg" />
                        <div class="title">
                            No clauses available.
                        </div>
                        <div class="sub-title ">
                            No graphs available for this contract type.
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</app-dialog-shell>