import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseTenantService } from '@summize/shared/core';

import { Metric } from '../../analytics.types';

export class MetricRequest {
    id: string;
    range: string;
    inverse: boolean;
    clientId: string;
    matterId: string;
}

@Injectable({ providedIn: 'root' })
export class MetricBarService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getMetrics(metricsRequest: MetricRequest[]): Promise<Metric[]> {

        for (var metricRequest of metricsRequest) {

            if (metricRequest.inverse === undefined) {

                metricRequest.inverse = false;

            }

        }

        const baseUrl = this.getUserBaseUrl(true);

        let url = `${baseUrl}analytics/metrics`;

        const metrics = await this.http.post(url, metricsRequest).toPromise() as any;

        if (metrics !== undefined) {

            for (var metric of metrics) {

                const metricRequest = metricsRequest.find(x => x.id == metric.id);

                if (metricRequest !== undefined) {

                    metric.inverse = metricRequest.inverse;

                }
            }
        }

        return metrics;

    }

    public async getMetric(id: string, range: string, inverseValue: boolean = false, client: string | undefined = undefined, matter: string | undefined = undefined): Promise<Metric> {

        const baseUrl = this.getUserBaseUrl(true);

        let url = `${baseUrl}analytics/metric/${id}/${range}`;

        if (client !== undefined || matter !== undefined) {

            url += '?';

            if (client !== undefined) {

                url += `clientId=${client}`;

            }

            if (matter !== undefined) {

                url += `&matterId=${matter}`;

            }

        }

        const val = await this.http.get(url).toPromise() as any;

        if (val !== undefined) {

            val.inverse = inverseValue;

        }

        return val;

    }

}
