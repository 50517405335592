import { Subject } from "rxjs";

class SubscriptionReference {

    private sub: Subject<any>;

    constructor(private observable, private handler) {

        this.sub = this.observable.subscribe((val: any) => {

            this.handler(val)

        });

    }

    public destroy(): void {

        this.sub.complete();

        this.sub.unsubscribe();

        this.handler = undefined;

    }

}

export class SubscriptionManager {

    private subs: Array<SubscriptionReference>

    constructor() {

        this.subs = [];

    }

    public destroy(): void {

        if (this.subs !== undefined) {

            for (const sub of this.subs) {

                sub.destroy();

            }

        }

    }

    public subscribe(observable: any, func: any): void {

        this.subs.push(new SubscriptionReference(observable, func));

    }
}