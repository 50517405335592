import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HeaderBarModule } from '../components';
import { ManagePanelModule } from '../manage-panel/manage-panel.module';
import { ReviewPanelModule } from '../review-panel/review-panel.module';

import { AnalyticsDashboardComponent } from './analytics-dashboard.component';

@NgModule({
    imports: [
        CommonModule,
        HeaderBarModule,
        ReviewPanelModule,
        ManagePanelModule
    ],
    declarations: [
        AnalyticsDashboardComponent
    ],
})
export class AnalyticsDashboardModule { }
