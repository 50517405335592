<div class="contract-status-panel">
    <div class="chart-area">
        <ng-container *ngIf="chart !== undefined">
            <canvas baseChart [datasets]="chart?.dataset" [labels]="chart?.labels" [options]="chart?.options"
                chartType="doughnut">
            </canvas>
        </ng-container>
    </div>

    <div class="progress-area">
        <div *ngFor="let status of activeStatuses; let index = index" class="progress-item">
            <div class="name">
                {{status.documentStatusName}}
            </div>
            <div class="total">
                {{status.value}}
            </div>
            <div class="percent">
                {{getPercentOfTotal(status.value)}}%
            </div>
            <div class="progress-bar">
                <app-progress-bar [complete]="getPercentOfTotal(status.value)" [color]="backgroundColors[index]"
                    [rounded]="true">
                </app-progress-bar>
            </div>
        </div>
    </div>

</div>