<ng-container *ngIf="isLoading === true">
    <app-loading-spinner visible="true" [type]="'pale'"></app-loading-spinner>
</ng-container>

<div *ngIf="isLoading === false" class="auto-send-for-signature">

    <div class="title">
        DocuSign Settings
    </div>
    <div *ngIf="requestSubtype === requestSubtypeEnum.Create" class="subtitle">
        For create templates you can enable and configure DocuSign settings to automatically send generated contracts
        for signature when a request is submtted.
    </div>
    <div *ngIf="requestSubtype === requestSubtypeEnum.Review" class="subtitle">
        For review templates you can enable and configure DocuSign settings to automatically capture signing information
        when a request is submitted.
    </div>

    <div class="enable-auto-send">

        <span [matTooltip]="enableTooltip" matTooltipClass="button-tooltip" class="btn-container">
            <span style="display: contents;">
                <div class="toggle">
                    <mat-slide-toggle [checked]="model?.isEnabled" [disabled]="noValidIntegrations"
                        (change)="updateEnabled($event.checked)">
                    </mat-slide-toggle>
                </div>
            </span>
        </span>

        <span>Enable DocuSign settings</span>
    </div>

    <div *ngIf="model.isEnabled === true">
        <div class="section">
            <div class="type">
                <div class="field required">
                    Select which DocuSign shared account to use for authentication
                </div>
                <div class="control">
                    <app-select-box [options]="integrationOptions" [canSearch]="false" placeholder="Select"
                        (changed)="onIntegrationChanged($event)" [value]="model.integrationId">
                    </app-select-box>
                </div>
                <div class="hint-container">
                    <p class="text">Envelopes will be created under and send on behalf of this account.</p>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="type">
                <div class="field required">
                    Select which signature type to use
                </div>
                <div class="control">
                    <app-select-box [options]="signatureTypeOptions" [canSearch]="false" placeholder="Select"
                        (changed)="onSignatureTypeChanged($event)" [value]="model.signatureType">
                    </app-select-box>
                </div>
                <div class="hint-container">
                    <p class="text">All recipients will be sent envelopes using the same signature type.</p>
                </div>
            </div>
        </div>

        <app-esignature-email-recipients [recipients]="model.internalRecipients" [recipientType]="recipientTypeEnum.Internal"
            [allowMultiple]="true" [showCheckmark]="false"
            (recipientsChanged)="onRecipientsChanged($event)"></app-esignature-email-recipients>

        <div class="section">
            <div class="type">
                <div class="field required">
                    Number of counterparty recipients/signers
                </div>
                <div class="control">

                    <app-select-box [options]="counterpartyOptions" [canSearch]="false" placeholder="Select"
                        (changed)="onCounterpartyChanged($event)" [value]="model.requiredCounterparties?.toString()">
                    </app-select-box>

                </div>

                <div class="hint-container">
                    <p class="text">This determines if the requestor can enter a single counterparty recipient or
                        multiple
                        counterparty recipients.</p>
                </div>
            </div>
        </div>

        <div class="subtitle break">
            Configure the settings below to control where and how the contract should be stored in the Repository once
            fully
            signed.
        </div>

        <div class="section">
            <div class="type">
                <div class="field required">
                    Folder
                </div>
                <div class="control">
                    <app-group-select-box (changed)="onFolderChanged($event)" [groups]="folders"
                        [value]="model.matterId" placeholder="Select">
                    </app-group-select-box>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="type">
                <div class="field required">
                    Contract Type
                </div>
                <div class="control">
                    <app-select-box [options]="contractTypeOptions" [canSearch]="true" placeholder="Select"
                        (changed)="onContractTypeChanged($event)" [value]="model.contractTypeId">
                    </app-select-box>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="type">
                <div class="field required">
                    Status
                </div>
                <div class="control">
                    <app-select-box [options]="repositoryDocumentStatusIdOptions" [canSearch]="false"
                        [value]="model.repositoryDocumentStatusId" placeholder="Select"
                        (changed)="onRepositoryStatusChanged($event)">
                    </app-select-box>
                </div>
            </div>
        </div>

        <div class="section">
            <div class="type">
                <div class="field required">
                    Owner
                </div>
                <div class="control">
                    <app-select-box [options]="ownerOptions" [canSearch]="false" placeholder="Select"
                        (changed)="onOwnerChanged($event)" [value]="model.assignTo">
                    </app-select-box>
                </div>
            </div>
        </div>

        <div *ngIf="isNotificationActionsEnabled === true" class="section">
            <div class="type">
                <div class="field">
                    When the request contract is signed and stored, send notification to
                </div>
                <div class="control">
                    <app-autocomplete-chips-groups [groups]="notificationTargetGroups" [items]="notificationTargets"
                        [selectedItems]="selectedNotificationTargets" (changed)="updatedNotificationTargets($event)"
                        [refresh]="refreshSelectedNotificationTargets"></app-autocomplete-chips-groups>
                </div>
            </div>
        </div>
    </div>

</div>