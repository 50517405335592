import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

import { IconButtonComponent } from './icon-button.component';

@NgModule({
    imports: [
        CommonModule,
        MatTooltipModule
    ],
    exports: [
        IconButtonComponent
    ],
    declarations: [
        IconButtonComponent
    ]
})
export class IconButtonModule { }

export * from './icon-button.component'
