import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AsyncErrorCountComponent } from './async-error-count.component';
import { AsyncErrorCountService } from './async-error-count.service';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        AsyncErrorCountComponent
    ],
    declarations: [
        AsyncErrorCountComponent
    ],
    providers: [
        AsyncErrorCountService
    ]
})
export class AsyncErrorCountModule { }

export * from './async-error-count.component'