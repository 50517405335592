export interface AnalyticTableResult {
    totalResultsCount: number;
    results: Array<ProgressListItem>
}

export interface ProgressListItem {
    userName: string;
    total: number;
    complete: number;
    completePercentage: number;
    remaining: number;
    totalLabel: string;
    completeLabel: string;
    remainingLabel: string;
    subtitle: string;
}

export interface ContractStatusItem {
    documentStatusName: string;
    documentStatusId: number;
    value: number;
}

export enum TrendDirection {
    Up = 0,
    Down = 1
}

export interface Metric {
    id: string;
    title: string;
    displayValue: string;
    trendDirection: TrendDirection;
    trendValue: string;
    trendRange: string;
    inverse?: boolean;
    value?: number;
    previousValue?: number;
    slug?: string;
    clause?: string;
    pillType?: string;
    high?: string;
    low?: string;
}

export interface ClauseMetric {
    clauseName: string;
    currentValue: number;
    high: number;
    low: number;
    percentage: string;
    previousValue: number;
    templateName: string;
    title: string;
    trendDirection: TrendDirection
    type: string;
    noData: boolean;
    documentComparisonResult?: any;
}

export interface ClauseMetricDefinition {
    id: string;
    contractTypeId: string;
    clauseId: string;
    type: string;
    settings: any;
}

export enum GraphSize {
    Regular, Large
}

export enum DurationMask {
    Days, Weeks, Months
}

export enum CurrencyMask {
    Exact, Rounded
}

export const SpecialTypes = {
    'DURATION': 'DURATION',
    'CURRENCY': 'CURRENCY'
}
