import { Component, Input, OnInit } from '@angular/core';

import { SelectBoxModel } from '@summize/shared/components';
import { EventService, ShowToastMessage } from '@summize/shared/framework';

import { Clause, ClauseRuleResult } from '../clause-summary-panel/clause-summary-panel.module';
import { Playbook, PlaybookDetailsPanelService } from './playbook-details-panel.service';

@Component({
    selector: 'app-playbook-details-panel',
    templateUrl: 'playbook-details-panel.html',
    styleUrls: ['./playbook-details-panel.scss']
})
export class PlaybookDetailsPanelComponent implements OnInit {

    @Input()
    public selectedClause: Clause;

    @Input()
    public documentId: string;

    @Input()
    public isLatest = false;

    public isLoading = true;

    public playbooks: Playbook[];

    public playbookOptions: Array<SelectBoxModel>;

    public selectedPlaybook: Playbook;

    constructor(private service: PlaybookDetailsPanelService, private events: EventService) { }

    public async ngOnInit() {


        if (this.selectedClause) {

            const request = this.selectedClause.documentTypes.map(x => {

                return {
                    documentTypeId: x.documentTypeId,
                    clauseId: x.clauseId
                }

            });

            this.playbooks = await this.service.getPlaybooksForClause(request);

            this.playbookOptions = this.playbooks?.map(x => { return { key: x.id, value: x.name } });


        } else {

            this.playbooks = await this.service.getPlaybooks();

            this.playbookOptions = this.playbooks?.map(x => { return { key: x.id, value: x.name } });

        }

        this.isLoading = false;
    }

    public async selectPlaybook(id: any) {

        this.isLoading = true;

        const selectedPlaybook = this.playbooks.find(x => x.id === id);

        if (!selectedPlaybook) {

            this.isLoading = false;

            return;
        }

        this.selectedPlaybook = await this.service.getPlaybookById(selectedPlaybook.id, selectedPlaybook.isPremium);

        if (this.selectedClause?.clauseId) {

            this.selectedPlaybook.clauses = this.selectedPlaybook.clauses.filter(x => x.name === this.selectedClause.ruleName);

        }

        this.isLoading = false;
    }

    public async copyToClauseBullet(text: string) {

        try {

            await this.service.addTextToClauseBullet(this.documentId, this.selectedClause.clauseId, 0, this.selectedClause.ruleName, text, this.selectedClause.ruleResult[0].paragraphNumber);

            this.events.despatch(ShowToastMessage, {
                text: `Position added to clause.`,
            });

        } catch (err) {

            this.events.despatch(ShowToastMessage, {
                text: `Error adding position to clause.`,
                type: 'error',
                icon: 'alert-circle'
            });
        }

    }

    public async copyToComments(text: string) {

        try {

            if (this.selectedClause.clauseComments?.length > 0) {

                await this.service.updateClauseComment(this.documentId, this.selectedClause.clauseId, this.selectedClause.clauseComments[0].commentId, text);

            } else {

                await this.service.addTextToClauseComment(this.documentId, this.selectedClause.clauseId, text);

            }

            this.events.despatch(ShowToastMessage, {
                text: `Position added to clause comments.`,
            });

        } catch (err) {

            this.events.despatch(ShowToastMessage, {
                text: `Error adding position to clause comments.`,
                type: 'error',
                icon: 'alert-circle'
            });
        }

    }

    public copyToClipboard(text: string) {

        try {

            navigator.clipboard.writeText(text);

            this.events.despatch(ShowToastMessage, {
                text: `Position copied to clipboard.`,
            });


        } catch (err) {

            this.events.despatch(ShowToastMessage, {
                text: `Error copying to clipboard.`,
                type: 'error',
                icon: 'alert-circle'
            });
        }

    }

    public isCommandEnabled() {

        return this.documentId === undefined || (this.documentId !== undefined && this.isLatest === true);
    }

}