<div class="global-search">
    <div [class.active]="currentState !== State.Initial" class="search-area search-area-right">
        <input name="input" type="text" [(ngModel)]="inputQuery" (keyup)="search($event)" autocomplete="off"
            [placeholder]="placeholder" />

        <app-icon *ngIf="currentState !== State.Initial" (click)="clear($event)" class="icon"
            icon="close-circle-outline">
        </app-icon>

        <div *ngIf="currentState !== State.Initial" class="expanded-area">
            <ng-container *ngIf="currentState === State.Pending">
                <div class="spinner">
                    <app-loading-spinner visible="true"></app-loading-spinner>
                </div>
            </ng-container>

            <ng-container *ngIf="currentState === State.Results && query !== WILDCARD">
                <div *ngFor="let key of model.keyOrder" class="segment">
                    <ng-container *ngIf="model[key] !== undefined && model[key].partial?.length > 0">
                        <div class="segment-header">{{model[key].header}}</div>
                        <div class="segment-results">
                            <div *ngFor="let result of model[key].partial"
                                (click)="model[key].onItemClick($event, result)" class="result">
                                {{result.display}}
                            </div>
                            <div *ngIf="model[key].full?.length > 5"
                                (click)="model[key].onMoreClick($event, model[key])" class="allresults">
                                View all results
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="currentState === State.NoResults">
                <div class="segment no-results">
                    <img src="assets/images/global-search__no-results.svg" />
                    <div class="text mt-2">Oops! No search results found</div>
                    <div *ngIf="hasFullTextSearch === true && query !== ''" class="text mt-2 free-text-search clickable"
                        (click)="goToTextSearch($event)">
                        Search contract text with term "{{query}}" instead?
                    </div>
                </div>
            </ng-container>

            <ng-container
                *ngIf="hasFullTextSearch === true && query !== '' && currentState !== State.Pending && currentState !== State.NoResults">
                <div class="free-text-search clickable" (click)="goToTextSearch($event)">
                    Or search contract text with term "{{query}}"
                </div>
            </ng-container>

            <div class="multitenancy-search-container" *ngIf="isMultitenancySearchEnabled">
                <a [routerLink]="['/contracts/multitenancy-search']" [queryParams]="{ query: inputQuery }"
                    class="multitenancy-search-link clickable">
                    <app-icon class="multitenancy-search-icon" icon="globe-outline"></app-icon>
                    Search across all regions
                </a>
            </div>
        </div>
    </div>
</div>