import { Component, Input, ViewChild, ElementRef, ChangeDetectionStrategy, AfterViewInit, OnDestroy } from '@angular/core';

import lottie from 'lottie-web/build/player/lottie_svg.min.js';

@Component({
    selector: 'app-loading-spinner',
    templateUrl: 'loading-spinner.html',
    styleUrls: ['./loading-spinner.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingSpinnerComponent implements AfterViewInit, OnDestroy {

    @Input('visible')
    public visible: boolean;

    @Input('type')
    public type: string;

    @ViewChild('loadingSpinner')
    public loadingSpinner: ElementRef;

    @Input()
    public container: boolean = false;

    private animation: any = undefined;

    public ngAfterViewInit() {

        if (this.loadingSpinner !== undefined && this.loadingSpinner.nativeElement !== undefined) {

            this.animation = lottie.loadAnimation({
                container: this.loadingSpinner.nativeElement,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: this.getSpinner()
            });

        }

    }

    public ngOnDestroy(): void {

        this.animation?.stop();

        this.animation?.destroy();

    }

    private getSpinner(): string {

        switch (this.type) {
            case 'blue':
                return 'assets/animations/loading-spinner-blue.json';
            case 'pale':
                return 'assets/animations/loading-spinner-pale.json';
            case 'upload':
                return 'assets/animations/uploading-indicator-spinner.json';
            default:
                return 'assets/animations/summize-spinner.json';
        }

    }

}
