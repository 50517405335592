import { RequestSubtype } from 'libs/shared/framework/src/types';

export class RequestSubTypeHelper {

    public static isRequestType = (requestType: RequestSubtype) => {
        return requestType === RequestSubtype.AskLegal ||
            requestType == RequestSubtype.Create ||
            requestType == RequestSubtype.Review;
    }

}
