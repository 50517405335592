import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

// RichS: This is needed as even though we use UtcNow in the backend, we don't store the 'timezone' portion
// in the DB. Therefore even though the browser handles timezone, it thinks the time is already in the
// same timezone as current.
// i.e. these produce different results when the browser is not in UTC timezone, but we intend them to be
// the same:
//   new Date('2006-01-02T15:04:05.1') => will show the same irrespective of user's timezone
//   new Date('2006-01-02T15:04:05.1Z') => will show the value correctly for the user's timezone
@Pipe({
  name: 'smzdate'
})
export class SmzDatePipe extends DatePipe implements PipeTransform {

  transform(value: Date|string|number, format?: string, timezone?: string, locale?: string): string
      |null;
  transform(value: null|undefined, format?: string, timezone?: string, locale?: string): null;
  transform(
      value: Date|string|number|null|undefined, format?: string, timezone?: string,
      locale?: string): string|null;
  transform(
      value: Date|string|number|null|undefined, format?: string, timezone?: string,
      locale?: string): string|null {

        if (typeof (value) === 'string' || value instanceof String) {
          if (value.length > 0 && !(value.includes('z') || value.includes('Z') || value.includes('+'))) {
            value = value + 'Z';
          }
        }

        return super.transform(value, format, timezone, locale);

  }

  // Use this if you don't want the UTC timezone conversion to run, 
  // and instead what the date to be treated as a local date (see comment at top of this file for more details)
  transformNoZ(value: Date|string|number|null|undefined, format?: string, timezone?: string, locale?: string): string|null {

      return super.transform(value, format, timezone, locale);

  }

}
