import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

export interface ButtonCommand {
    display: string;
    onClick?: Function;
    icon?: string;
    isDisabled?: boolean;
    disabledReason?: string;
}

@Component({
    selector: 'app-dropdown-button',
    templateUrl: 'dropdown-button.html',
    styleUrls: ['./dropdown-button.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownButtonComponent {

    @Input()
    public set commands(commands: Array<ButtonCommand>) {

        this.first = commands[0];

        this.items = commands.slice(1, commands.length);

        this.isSingular = this.items.length === 0;

        this.selectedDisplayText = commands[0].display;

    }

    @Input()
    public width: number = 235;

    @Input()
    public type: string = 'v2-primary';

    @Input()
    public disabled: boolean = false;

    @Input()
    public align: 'right' | 'left' = 'left';

    @Input()
    public prefix: string;

    public get iconClasses(): { [key: string]: boolean } {
        return {
            'v2': this.type === 'v2',
            'ghost': this.type === 'ghost',
            'v2-ghost': this.type === 'v2-ghost',
            'dropdown-ghost': this.type === 'dropdown-ghost',
            'text-primary': this.type === 'text-primary'
        };
    }

    public expanded: boolean = false;

    public first: ButtonCommand;

    public items: Array<ButtonCommand>;

    public isSingular: boolean;

    public selectedDisplayText: string;

    public fire(command: ButtonCommand): void {

        if (command.onClick !== undefined) {

            if (command.isDisabled === true) {

                return;

            }

            this.expanded = false;

            if (this.type === 'dropdown-ghost') {

                this.selectedDisplayText = command.display;

            }

            command.onClick();

        }

    }

}