import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TableModule } from '@summize/shared/components';
import { ContextBarModule } from '@summize/shared/components-v2';

import { HeaderBarModule } from '../components';
import { ReviewPanelModule } from '../review-panel/review-panel.module';

import { TableViewComponent } from './table-view.component';

@NgModule({
    imports: [
        CommonModule,
        HeaderBarModule,
        ReviewPanelModule,
        ContextBarModule,
        TableModule
    ],
    declarations: [
        TableViewComponent
    ],
})
export class TableViewModule { }
