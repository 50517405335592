<div class="custom-field-value">
    <div class="field">
        <div class="field-title">
            {{model.definition.name}}
        </div>
        <div *ngIf="model.definition.fieldType === CustomFieldType.MultiSelect || model.definition.fieldType === CustomFieldType.Dropdown"
            class="field-value">
            <app-select-box (changed)="setArrayValue($event)" [value]="boundValue" [options]="values"
                [disabled]="canEdit === false"
                [multipleSelect]="model.definition.fieldType === CustomFieldType.MultiSelect"></app-select-box>
        </div>
        <div *ngIf="model.definition.fieldType === CustomFieldType.FreeText" class="field-value">
            <input [ngModel]="boundValue" (blur)="setNormalValue(boundValue)" (ngModelChange)="boundValue = $event"
                [disabled]="canEdit === false" name="text" type="text" class="smz-standard-input v2" autocomplete="off">
        </div>
        <div *ngIf="model.definition.fieldType === CustomFieldType.Numeric" class="field-value">
            <input [ngModel]="boundValue" (blur)="setNormalValue(boundValue)" (ngModelChange)="boundValue = $event"
                [disabled]="canEdit === false" name="text" type="number" class="smz-standard-input v2"
                autocomplete="off">
        </div>
        <div *ngIf="model.definition.fieldType === CustomFieldType.Datepicker" class="field-value">
            <input [ngModel]="boundValue" (blur)="setNormalValue(boundValue)" (ngModelChange)="boundValue = $event"
                [disabled]="canEdit === false" name="text" type="date" class="smz-standard-input v2" autocomplete="off">
        </div>
        <div class="field-desc">
            {{model.definition.description}}
        </div>
    </div>
</div>