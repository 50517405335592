import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AllContractTypesId, BaseTenantService } from '@summize/shared/core';

import { CustomFieldDefinition } from '../components';

@Injectable({ providedIn: 'root' })
export class ManageService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getDefinitions(): Promise<Array<CustomFieldDefinition>> {

        const base = this.getUserBaseUrl(true);

        return await this.http.get<Array<CustomFieldDefinition>>(`${base}customfields/definition`).toPromise();

    }

    public async createDefinition(def: CustomFieldDefinition): Promise<CustomFieldDefinition> {

        const base = this.getUserBaseUrl(true);

        return await this.http.put<CustomFieldDefinition>(`${base}customfields/definition`, def).toPromise();

    }

    public async saveDefinition(def: CustomFieldDefinition): Promise<CustomFieldDefinition> {

        const base = this.getUserBaseUrl(true);

        return await this.http.post<CustomFieldDefinition>(`${base}customfields/definition`, def).toPromise();

    }

    public async removeDefinition(id: string): Promise<any> {

        const base = this.getUserBaseUrl(true);

        return await this.http.delete<CustomFieldDefinition>(`${base}customfields/definition/${id}`).toPromise();

    }

    public async getTypes(): Promise<any> {

        const url = `${this.getUserBaseUrl(true)}manage/contracts/upload/meta`

        const result = await this.http.get<any>(url).toPromise();

        const base = result.types.map(t => ({ key: t.id, value: t.name }));

        return [...base];

    }

}