import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseTenantService } from "./base-tenant.service";

export interface DynamicsCredentials {
    appAuthAzureTenantId: string,
    appAuthAzureClientId: string,
    appAuthAzureClientSecret: string,
    domainUrl: string,
    token: string
}

export interface DynamicsCompanyLookup {
    form: string,
    properties: Record<string,string>;
    isDefaultSearch: boolean
}

export interface DynamicsConnectorContext {
    credentials: DynamicsCredentials,
    companyLookup: DynamicsCompanyLookup,
    isConnected: boolean
}

export interface DynamicsConnector {
    id: string,
    tenantId: string,
    departmentId: string,
    dynamicsConnectorContext: DynamicsConnectorContext,
    createdAt: Date,
    createdBy: string
}

@Injectable({ providedIn: 'root' })
export class DynamicsService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getDynamicsConnectorDefaultSearch(): Promise<DynamicsConnector>{
        const baseUrl = this.getUserBaseUrl( true );
        return await this.http.get<DynamicsConnector>(`${baseUrl}admin/dynamics/defaultsearch`).toPromise();
    }
}