import { WidgetFilterRequest, WidgetFilterFieldNames, WidgetFilterType, WidgetFilterResponse } from '@summize/shared/framework';
import { CloneObject, EMPTY_GUID } from '@summize/shared/core';

import { AvailableFilter, FilterType, SelectedFilter } from '../filter-builder/filter-builder.component';

export interface AvailableWidgetFilter {
    name: string;
    field: WidgetFilterFieldNames;
    fieldName: string;
    isDriver: boolean;
    widgetFilterTypes: WidgetFilterType[];
    data: any;
}

export class WidgetFilterHelper {
    public static buildSelectedFiltersFromState(state: AvailableFilter[], availableFilters: WidgetFilterResponse[]): Array<SelectedFilter> {

        const filters: any = availableFilters.map(x => {
            return {
                id: x.name,
                name: x.name,
                field: x.field,
                fieldName: x.fieldName,
                width: null
            }
        });

        const selectedFilters = [];

        for (const param of state) {

            const matchingFilter = filters.find(f => f.field === param.field);

            if (matchingFilter !== undefined && param.selected?.length > 0) {

                const values = this.applyPrefixToValues(matchingFilter.widgetFilterType, param.selected);

                const existing = selectedFilters.find(y => y.field === param.field);

                if (existing !== undefined) {

                    existing.value += ',' + values.join(',');
                    existing.valueCount += values.length;

                } else {

                    selectedFilters.push(
                        {
                            ...matchingFilter,
                            value: values.join(','),
                            valueCount: values.length,
                            width: -1
                        }
                    );
                }

            }

        }

        return selectedFilters;

    }

    public static resetAvailableFilters(availableFilters: AvailableFilter[]): AvailableFilter[] {

        return availableFilters.map(x => {

            x.selected = [];

            return x;

        });
    }

    public static buildSelectedFiltersFromAppliedFilters(appliedFilters: WidgetFilterRequest[], availableFilters: WidgetFilterResponse[]): Array<SelectedFilter> {

        const filters: any = availableFilters.map(x => {
            return {
                id: x.name,
                name: x.name,
                field: x.field,
                fieldName: x.fieldName,
                width: null
            }
        });

        const selectedFilters = [];

        for (const param of appliedFilters) {

            const matchingFilter = filters.find(f => f.field === param.field);

            if (matchingFilter !== undefined && param.values?.length > 0) {

                const values = this.applyPrefixToValues(param.widgetFilterType, param.values);

                const existing = selectedFilters.find(y => y.field === param.field);

                if (existing !== undefined) {

                    existing.value += ',' + values.join(',');
                    existing.valueCount += values.length;

                } else {

                    selectedFilters.push(
                        {
                            ...matchingFilter,
                            value: values.join(','),
                            valueCount: values.length,
                            width: -1
                        }
                    );
                }

            }

        }

        return selectedFilters;

    }

    private static convertNotSets(id: string, name: string, fieldName: string) {

        if (id !== EMPTY_GUID) {

            return name;
        }

        switch (fieldName?.toLowerCase()) {
            case 'assignedto':
            case 'assigned to':
                return 'No Assignee';
            case 'owner':
                return 'No Owner';
            default:
                return 'Not Set';
        }
    }

    public static setSelectedFiltersOnAvailableWidgetFilterContext(selectedFilters: SelectedFilter[], availableFilters: AvailableFilter[]): AvailableFilter[] {

        if (selectedFilters?.length < 1) {

            return availableFilters;

        }

        const matches = availableFilters.map(x => {

            const match = selectedFilters.find(y => y.field === x.field);

            if (match) {

                x.selected = match.value.split(',');

            }

            return x;

        });

        return matches;

    }

    public static buildAvilableFiltersFromWidgetFilters(availableFilters: AvailableWidgetFilter[]) {

        const fieldsToRemoveEmptyGuid = ['requestedby'];

        const filters = availableFilters.map(x => {

            let source;
            let type;
            let entity = x.name;

            if (x.data.Users !== undefined && x.data.Groups !== undefined) {

                entity = 'user';

                if (fieldsToRemoveEmptyGuid.includes(x.fieldName?.toLowerCase())) {

                    x.data.Users.splice(0, 1);
                }

                source = Promise.resolve(({
                    groups: x.data.Groups.map(p => ({ id: 'G:' + p.id.toLowerCase(), name: p.name })).sort((a, b) => this.sortByName(a, b)),
                    items: x.data.Users.map(p => ({ id: 'U:' + p.id.toLowerCase(), name: this.convertNotSets(p.id.toLowerCase(), p.name, x.name) })).sort((a, b) => this.sortByName(a, b))
                }));

                type = FilterType.ListWithGrouping;

            } else if (x.data.Groups !== undefined) {
                source = Promise.resolve((
                    x.data.Groups.map(p => ({ id: 'G:' + p.id.toLowerCase(), name: p.name })).sort((a, b) => this.sortByName(a, b))
                ));

                type = FilterType.List;

            } else if (x.data.Users !== undefined) {

                if (fieldsToRemoveEmptyGuid.includes(x.fieldName?.toLowerCase())) {

                    x.data.Users.splice(0, 1);
                }

                source = Promise.resolve((
                    x.data.Users.map(p => ({ id: 'U:' + p.id.toLowerCase(), name: this.convertNotSets(p.id.toLowerCase(), p.name, x.name) })).sort((a, b) => this.sortByName(a, b))
                ));

                type = FilterType.List;

            } else if (x.data.Templates !== undefined ||
                x.data.AskLegalTemplates !== undefined ||
                x.data.CreateTemplates !== undefined ||
                x.data.ReviewTemplates !== undefined
            ) {

                source = Promise.resolve(({
                    entities: x.data.Templates ||
                        x.data.AskLegalTemplates ||
                        x.data.CreateTemplates ||
                        x.data.ReviewTemplates
                }));

                type = FilterType.EntitiesWithGrouping;

            } else {
                // Handle any other key in x.data
                const otherKey = Object.keys(x.data)[0];

                source = Promise.resolve(
                    x.data[otherKey].map(p => { return { id: p.id.toString().toLowerCase(), name: p.name } })
                );
                type = FilterType.List;
            }

            return {
                id: x.name,
                field: x.field,
                fieldName: x.fieldName,
                name: x.name,
                entity: entity,
                type: type,
                source: () => source
            }
        });

        return filters;

    }

    public static buildWidgetFiltersFromFilterBuilder(filters: AvailableFilter[]): WidgetFilterRequest[] {

        if (!filters || filters.length === 0) {

            return [];
        }

        const widgetFilters = filters.flatMap(filter => {

            const selected = filter.selected;

            if (!selected) return [];

            if (filter.fieldName === "RequestType") {
                return [{
                    field: filter.field,
                    fieldName: filter.fieldName,
                    values: selected,
                    widgetFilterType: WidgetFilterType.RequestType
                }];
            } else if (filter.fieldName === "RequestStage") {

                return [{
                    field: filter.field,
                    fieldName: filter.fieldName,
                    values: selected,
                    widgetFilterType: WidgetFilterType.RequestStage
                }];
            } else if (filter.fieldName === "Source") {

                return [{
                    field: filter.field,
                    fieldName: filter.fieldName,
                    values: selected,
                    widgetFilterType: WidgetFilterType.RequestSource
                }];
            }  else if (filter.fieldName === 'TemplateId') {

                return [{
                    field: filter.field,
                    fieldName: filter.fieldName,
                    values: selected,
                    widgetFilterType: WidgetFilterType.Templates
                }];
            }

            const isGroupSelected = selected.some(e => e.startsWith('G:'));
            const isUserSelected = selected.some(e => e.startsWith('U:'));

            if (isGroupSelected && isUserSelected) {

                const selectedUsers = selected.filter(y => y.startsWith('U:'));
                const selectedGroups = selected.filter(y => y.startsWith('G:'));

                return [
                    {
                        field: filter.field,
                        fieldName: filter.fieldName,
                        values: this.stripValuePrefixes(selectedUsers),
                        widgetFilterType: WidgetFilterType.Users
                    },
                    {
                        field: filter.field,
                        fieldName: filter.fieldName,
                        values: this.stripValuePrefixes(selectedGroups),
                        widgetFilterType: WidgetFilterType.Groups
                    }
                ];
            } else if (isGroupSelected) {

                return [{
                    field: filter.field,
                    fieldName: filter.fieldName,
                    values: this.stripValuePrefixes(selected),
                    widgetFilterType: WidgetFilterType.Groups
                }];
            } else if (isUserSelected) {

                return [{
                    field: filter.field,
                    fieldName: filter.fieldName,
                    values: this.stripValuePrefixes(selected),
                    widgetFilterType: WidgetFilterType.Users
                }];
            } 
        });

        let appliedFilters = [];

        widgetFilters.forEach(widgetFilter => {

            if (widgetFilter === undefined) {

                return;
            }

            let existing = appliedFilters.find(y => y.field === widgetFilter.field && y.widgetFilterType === widgetFilter.widgetFilterType);

            if (existing) {

                existing.values = widgetFilter.values;

            } else {

                appliedFilters.push(widgetFilter);

            }
        });

        return CloneObject(appliedFilters);

    }

    private static stripValuePrefixes(values: string[]): string[] {
        return values.map(value => value.substring(2));
    }

    private static sortByName(a, b) {

        // No group etc first
        if (a.id.includes(EMPTY_GUID)) {
            return -1;
        }
        if (b.id.includes(EMPTY_GUID)) {
            return 1;
        }

        const nameA = a.name.toLowerCase();

        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;

    }

    private static applyPrefixToValues(widgetFilterType: WidgetFilterType, values: string[]) {

        switch (widgetFilterType) {

            case WidgetFilterType.Groups: {

                return values.map(x => "G:" + x);
            }

            case WidgetFilterType.Users: {

                return values.map(x => "U:" + x);
            }

            case WidgetFilterType.RequestSource: {

                return values.map(x => parseInt(x));

            }

            default:

                return values;

        }
    }

}