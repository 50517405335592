import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatSelectModule } from '@angular/material/select';

import { FormSelectBoxComponent } from './form-select-box.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatSelectModule
    ],
    exports: [
        FormSelectBoxComponent
    ],
    declarations: [
        FormSelectBoxComponent
    ]
})
export class FormSelectBoxModule { }

export * from './form-select-box.component'