<div class="add-contracts">
    <app-dialog-shell (onClose)="onClose()" (onSave)="onSubmit()" title="Change Contract Type" [showFooter]="true"
        saveText="Change type" [showFooter]="state !== State.Loading" [saveDisabled]="isSaveDisabled === true" saveButtonWidth="150">

        <ng-container *ngIf="state === State.Loading || state === State.Submitting">
            <app-loading-spinner visible="true">
            </app-loading-spinner>
        </ng-container>

        <ng-container *ngIf="state === State.SelectingMeta">

            <div class="content-text">
                Changing the contract type will result in a new version being created to preserve the history of the contract including conversations and tasks, however the clause summary will be regenerated.
            </div>

            <div class="select-area">
                <div class="area-text">
                    Select the new contract type:
                </div>
                <app-select-box (changed)="setSelectedContractType($event)" placeholder="Select"
                    [value]="selectedContractType" [options]="contractTypes" data-cy="contract-type">
                </app-select-box>
            </div>
            
        </ng-container>

    </app-dialog-shell>
</div>
