<div [@fadeInAnimation] class="selector">
    <div class="header-bar fade-block">

        <div class="text">
            <div class="title">
                Recommended Clauses
            </div>

            <div class="sub-title">
                {{allTypes?.length}} contract types found
            </div>
        </div>

        <div (click)="collapsed = !collapsed" *ngIf="collapsed === true" class="collapse">
            <app-icon icon="caret-up-outline"></app-icon>
        </div>

        <div (click)="collapsed = !collapsed" *ngIf="collapsed === false" class="collapse">
            <app-icon icon="caret-down-outline"></app-icon>
        </div>

    </div>
    <div *ngIf="collapsed === false" class="options-content">
        <div *ngFor="let type of allTypes" class="option-box">
            <div class="type-container">
                <div class="box">
                    <mat-checkbox [(ngModel)]="type._selected" (change)="checkedChanged()"></mat-checkbox>
                </div>
                <div class="text">
                    {{type.documentTypeName}}
                </div>
            </div>
        </div>
    </div>
</div>