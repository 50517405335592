import { NgModule } from '@angular/core';

import { GmailService } from './gmail.service';
import { OutlookService } from './outlook.service';
import { PlatformService } from './platform.service';
import { SalesforceService } from './salesforce.service';
import { JiraService } from './jira.service';
import { HubspotService } from './hubspot.service';

@NgModule({
    providers: [
        GmailService,
        OutlookService,
        PlatformService,
        SalesforceService,
        JiraService,
        HubspotService
    ]
})
export class PlatformModule { }
