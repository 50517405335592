import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService, EMPTY_GUID } from '@summize/shared/core';
import { ClauseMetric } from '../../analytics.types';

@Injectable({ providedIn: 'root' })
export class ClauseMetricService extends BaseTenantService {

    constructor(private http: HttpClient) {

        super();

    }

    public async getClauseMetric(
        clientId: string,
        matterId: string,
        documentTypeId: string,
        clauseId: string,
        type: string,
        range: string,
        documentId: string): Promise<ClauseMetric> {

        let query = `?&range=${range}`;

        if (clientId !== undefined && clientId !== EMPTY_GUID) {

            query = `${query}&clientId=${clientId}`;

        }

        if (matterId !== undefined && matterId !== EMPTY_GUID) {

            query = `${query}&matterId=${matterId}`;

        }

        if (documentId !== undefined) {

            query = `${query}&documentId=${documentId}`;
        }

        const url = `${this.getUserBaseUrl(true)}analytics/clause-metric/${documentTypeId}/${clauseId}/${type}${query}`;

        return await this.http.get<any>(url).toPromise();
    }

}