<div class="centered-hv" [matTooltip]="tooltip" [class.disabled]="disabled">
    <ion-icon *ngIf="customSVG === undefined && icon !== undefined && icon.endsWith('.svg') === false" [name]="icon" [size]="size">
    </ion-icon>

    <div *ngIf="customSVG === undefined && icon !== undefined && icon.endsWith('.svg') === true" class="custom-icon">
        <img src="{{icon}}" />
    </div>

    <div *ngIf="customSVG !== undefined" class="custom-icon">
        <img src="{{customSVG}}" />
    </div>

    <div *ngIf="label !== undefined" class="label">{{ label }}</div>
</div>