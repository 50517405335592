import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { OverlayModule } from '@angular/cdk/overlay';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BlockButtonModule, IconModule, LoadingSpinnerModule, SearchBarModule, SelectBoxModule } from '@summize/shared/components-v2';

import { FilterBuilderComponent } from './filter-builder.component';
import { SearchPipe } from './search.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        BlockButtonModule,
        OverlayModule,
        IconModule,
        LoadingSpinnerModule,
        SearchBarModule,
        MatCheckboxModule,
        MatRadioModule,
        FormsModule,
        SelectBoxModule
    ],
    exports: [
        FilterBuilderComponent
    ],
    declarations: [
        FilterBuilderComponent,
        SearchPipe
    ]
})
export class FilterBuilderModule { }

export * from './filter-builder.component'