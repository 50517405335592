import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChangeStatusDialogComponent } from './change-status-dialog.component';
import { DialogShellModule } from '../dialog-shell/dialog-shell.module';
import { SelectBoxModule } from '../select-box/select-box.module';

@NgModule({
    imports: [
        CommonModule,
        DialogShellModule,
        SelectBoxModule
    ],
    exports: [
        ChangeStatusDialogComponent
    ],
    declarations: [
        ChangeStatusDialogComponent
    ]
})
export class ChangeStatusDialogModule { }

export * from './change-status-dialog.component'