<div class="section-panel">
    <ng-container *ngIf="loading === true">
        <div class="section-content loading">
            <app-loading-spinner type="pale" visible="true"></app-loading-spinner>
        </div>
    </ng-container>
    <ng-container *ngIf="loading === false && hasData === true">
        <div [@fadeInAnimation] class="header-area fade-block">
            <div class="title-content">
                <div class="title fade-block">
                    {{title}}
                </div>
                <div class="subtitle fade-block">
                    {{subtitle}}
                </div>
            </div>
            <div *ngIf="more === true" class="show-all">
                <app-block-button (click)="onShowMore.emit()" display="Show All" type="v2" width="85">
                </app-block-button>
            </div>
        </div>
        <div class="section-content fade-block">
            <ng-content></ng-content>
        </div>
    </ng-container>
    <ng-container *ngIf="loading === false && hasData === false">
        <div class="section-content block no-data">
            <div [@fadeInAnimation] class="no-results">
                <img class="block" src="/assets/images/analytics__nocontact.svg" />
                <div class="title block">
                    Nothing to show
                </div>
                <div class="sub-title block">
                    You have no data for this time range.
                </div>
            </div>
        </div>
    </ng-container>
</div>