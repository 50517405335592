import { FormControl, FormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { map } from 'rxjs/operators';

export interface AutocompleteBoxModel {
    key: string, value: string
}

@Component({
    selector: 'app-autocomplete-box',
    templateUrl: 'autocomplete-box.html',
    styleUrls: ['./autocomplete-box.scss']
})

export class AutocompleteBoxComponent {

    @Input()
    public parentForm: FormGroup;

    @Input()
    public fcn: string;

    @Input()
    public options: Array<AutocompleteBoxModel>;

    @Input()
    public value: string = '';

    @Input()
    public placeholder: string = 'Search...';

    @Input()
    public grouped: boolean = false;

    @Input()
    public isRequired: boolean = false;

    @Output()
    public changed: EventEmitter<string>;

    public myControl = new FormControl();

    public filteredOptions: Observable<AutocompleteBoxModel[]>;

    constructor() {

        this.changed = new EventEmitter();

    }

    public ngOnInit() {
        this.filteredOptions = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this.filter(value))
        );
    }

    public valueChanged(value: any): void {
        this.changed.emit(value.option.value.key);
    }

    public displayFn(tenant: AutocompleteBoxModel): string {
        return tenant && tenant.value ? tenant.value : '';
    }

    private filter(value: string): AutocompleteBoxModel[] {
        const filterValue = this.normalizeValue(value);
        return this.options.filter(x => this.normalizeValue(x.value).indexOf(this.normalizeValue(filterValue)) > -1);
    }

    private normalizeValue(value: string | AutocompleteBoxModel): string {

        if (typeof value === 'string') {

            return value.toLowerCase().replace(/\s/g, '');
        } else {
            return value.value.toLowerCase().replace(/\s/g, '');
        }

    }

}