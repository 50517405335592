import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { UserManager, UserManagerSettings, User, WebStorageStateStore } from 'oidc-client';
import { ReplaySubject } from 'rxjs';
import { BaseTenantService } from '@summize/shared/core';

import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';
import { TokenService } from './token.service';
import { AuthServiceBase } from './auth-service.base';
import { UserService } from './user.service';
import { LoginValues, LogoutValues } from '../types';

export function getClientSettings(environment: any): UserManagerSettings {
    return {
        authority: environment.auth.oidc.authority,
        client_id: environment.auth.oidc.clientId,
        client_secret: environment.auth.oidc.clientSecret,
        redirect_uri: environment.auth.oidc.signinRedirectUrl,
        post_logout_redirect_uri: environment.auth.oidc.signoutRedirectUrl,
        response_type: 'code',
        scope: environment.auth.oidc.scopes ?? 'openid profile email offline_access',
        filterProtocolClaims: true,
        loadUserInfo: true,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
        silent_redirect_uri: environment.tokenRefreshUrl,
        automaticSilentRenew: false
    };
}

@Injectable({
    providedIn: 'root'
})
export class OidcAuthService implements AuthServiceBase {

    private isAuthenticatedSubject = new ReplaySubject<boolean>(1);

    public isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

    public isAuthenticatedObservable = this.isAuthenticatedSubject.asObservable();

    private userManager: UserManager;

    private user: User = null;

    private initialised: boolean = false;

    constructor(
        private router: Router,
        private localStorageService: LocalStorageService,
        private sessionStorageService: SessionStorageService,
        private tokenService: TokenService,
        private userService: UserService
    ) {
    }

    public async initialise() {

        const env = BaseTenantService.environment;

        if (this.initialised === true) {

            return Promise.resolve(this.initialised);

        }

        if (this.userManager === undefined && env.auth !== undefined && env.auth.oidc !== undefined) {

            const clientSettings = getClientSettings(env);

            this.userManager = new UserManager(clientSettings);

            this.userManager.events.addSilentRenewError((err) => {
                console.log('Error renewing token');
                console.dir(err);
            });

            this.userManager.events.addAccessTokenExpiring(() => {

                console.log('Access token is expiring');

                this.userManager
                    .signinSilent()
                    .then((user) => {
                        this.user = user;
                        this.updateToken();
                        this.isAuthenticated();
                    })
                    .catch((err) => {

                        // RichS: Unfortunately the ../token API includes the failure reason (i.e.'refresh token is invalid')
                        // in error_description, but the oidc-client only returns error value.
                        if (err?.message === 'invalid_grant') {

                            this.logout({ disableAutoLogin: true });

                        } else {

                            throw err;

                        }

                    });
            });
        }

        if (this.userManager !== undefined) {

            const user = await this.userManager.getUser();

            this.user = user;

            this.updateToken();

            this.initialised = true;

            return this.initialised;

        }

        return Promise.resolve(false);

    }

    public isAuthenticated(): boolean {
        const isAuthenticated = this.user != null && !this.user.expired;

        // RichS: Always ensure the token is saved - other areas of the code are
        // reliant on this being there, and without a major refactor, this
        // returning true, but the token not being in the correct place
        // causes an endless loop. It should really be refactored to eliminate
        // this issue - but we can do that post Okta-removal.
        this.updateToken();

        this.isAuthenticatedSubject.next(isAuthenticated);
        return isAuthenticated;
    }

    private updateToken() {
        if (this.user?.access_token) {
            this.tokenService.saveToken(this.user.access_token);
        } else {
            this.tokenService.destroyToken();
        }
    }

    public login(values?: LoginValues): Promise<void> {

        if (values?.redirectUrl && values?.redirectUrl !== '/') {
            this.sessionStorageService.setItem('login-redirect-page', values.redirectUrl);
        }

        if (values && values.scheme) {
            return this.userManager.signinRedirect({ extraQueryParams: { idp: values.scheme, login_hint: encodeURIComponent(values.email) } });
        }

        this.router.navigate(['/login-oidc'], { queryParams: { autoLogin: !(values?.autoLogin === false) ? 'false' : undefined } });

    }

    public async logout(logoutValues: LogoutValues) {

        if (logoutValues?.removeEmail === true) {
            this.localStorageService.removeItem('smz-oidc-email');
        }

        if (!(logoutValues?.disableAutoLogin === true)) {
            this.sessionStorageService.setItem('smz-oidc-autologin', 'false');
        }

        this.tokenService.destroyToken();

        await this.userManager.createSignoutRequest({ id_token_hint: this.user?.id_token });

        // Note: Not all Idps support the revocation endpoint (see the openid-configuration response
        // for the 'revocation_endpoint' value). So silently ignore.
        try {

            await this.userManager.revokeAccessToken();

        } catch {

            console.log('Issue with revocation_endpoint');

        }

        await this.userManager.signoutRedirect();

    }

    public async logoutCallback() {

        await this.userManager.signoutRedirectCallback('');

        const isSummizeAuthentication = this.userService.getIsSummizeAuthentication();

        if (isSummizeAuthentication === true) {

            window.location.href = `${BaseTenantService.environment.oktaAuthUrl}/login/signout?fromURI=${BaseTenantService.environment.auth.oidc.signoutRedirectUrl}`;

        } else {

            window.location.href = `${BaseTenantService.environment.publicSiteUrl}/logout`;

        }

        this.localStorageService.removeItemByRegex(/^oidc\.user/i);

        this.isAuthenticatedSubject.next(false);

    }

    public completeAuthentication(): Promise<void> {
        return this.userManager
            .signinRedirectCallback()
            .then(user => {
                this.user = user;
                this.updateToken();
                this.isAuthenticated();
            });
    }
}
