import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ContractStatusPanelModule, MetricBarModule, RangeSelectorModule, SectionPanelModule, ProgressResultBarModule } from '../components';
import { ReviewPanelComponent } from './review-panel.component';
import { ReviewPanelService } from './review-panel.service';


@NgModule({
    imports: [
        CommonModule,
        MetricBarModule,
        RangeSelectorModule,
        SectionPanelModule,
        ProgressResultBarModule,
        ContractStatusPanelModule
    ],
    declarations: [
        ReviewPanelComponent
    ],
    exports: [
        ReviewPanelComponent
    ],
    providers: [
        ReviewPanelService
    ]
})
export class ReviewPanelModule { }
