import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router';

export interface CanHandlePendingChanges {
    hasPendingChanges(nextState?: RouterStateSnapshot): boolean;
    clearPendingChanges();
}

export const pendingChangesGuard: CanDeactivateFn<any> = (component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot) => {

    if (component.pendingChanges === false || component?.hasPendingChanges(nextState) === false) {

        // No changes so can deactivate route
        return true;
    }

    if (component.pendingChanges === true) {

        const result = confirm("You have unsaved changes. Are you sure you want to leave?");

        if (result === true) {

            component.clearPendingChanges();

            component.pendingChanges = false;

            return true;

        } else {

            return false;
        }

    }

    return false;
}