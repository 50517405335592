import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotesComponent } from './notes.component';
import { IconModule } from '../icon/icon.module';
import { CorePipesModule, SmzDatePipe } from '../pipes/pipes.module';
import { NoteInputModule } from '../note-input/note-input.module';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        CorePipesModule,
        NoteInputModule
    ],
    exports: [
        NotesComponent
    ],
    declarations: [
        NotesComponent
    ],
    providers: [
        SmzDatePipe
    ]
})
export class NotesModule { }