import { v4 as uuidv4 } from 'uuid';

import { IsValidString } from '@summize/shared/core';

export class SummizeStorage {

    public static OutlookHostname = 'outlook';

    public static WordAddInHostName = 'wordaddin-editor';

    public static GmailHostName = 'gmail';

    public static SalesforceHost = 'salesforce';

    public static JiraHost = 'jira';

    public static SharepointHost = 'sharepoint';

    public static HubspotHost = 'hubspot';

    public static LaunchParams = 'launchParams';

    public static isolate() {

        console.log(`Summize: Isolating storage`);

        const w: any = window;

        if (w.summize === undefined) {

            const key = uuidv4();

            w.summize = {
                sessionId: key,
                session: new PatchedStorage(key),
                local: new PatchedStorage(key)
            };

        }

        w.summize.isIsolated = true;

    }

    public static isIsolated() {

        return (<any>window).summize?.isIsolated === true;

    }

    public static IsHosted() {

        return IsValidString((<any>window).smzHost);

    }

    public static IsOutlook() {

        return (<any>window).smzHost === SummizeStorage.OutlookHostname;

    }

    public static IsWordAddIn() {

        return (<any>window).smzHost === SummizeStorage.WordAddInHostName;

    }

    public static IsGmail() {

        return (<any>window).smzHost === SummizeStorage.GmailHostName;

    }

    public static isHost(host: string): boolean {

        return (<any>window).smzHost === host;

    }

    public static setHost(host: string) {

        const current = (<any>window).smzHost;

        if (current !== host) {

            (<any>window).smzHost = host;

        }

    }

    public static get localStorage() {

        return SummizeStorage.getStorage(false)

    }

    public static get sessionStorage() {

        return SummizeStorage.getStorage(true)

    }

    public static getSessionItems() {

        return Object.entries(
            SummizeStorage.getStorage(true)
        ).map(e => ({ key: e[0], value: e[1] }));

    }

    public static getSessionItem(key: string): any {

        return SummizeStorage.getStorage(true).getItem(key);

    }

    public static removeSessionItem(key: string): void {

        SummizeStorage.getStorage(true).removeItem(key);

    }

    public static setSessionItem(key: string, value: string): void {

        SummizeStorage.getStorage(true).setItem(key, value);
    }

    public static clearSession(): void {

        SummizeStorage.getStorage(true).clear();
    }


    public static getLocalItems() {

        return Object.entries(
            SummizeStorage.getStorage(false)
        ).map(e => ({ key: e[0], value: e[1] }));

    }

    public static getLocalItem(key: string): any {

        return SummizeStorage.getStorage(false).getItem(key);

    }

    public static removeLocalItem(key: string): void {

        SummizeStorage.getStorage(false).removeItem(key);

    }

    public static setLocalItem(key: string, value: string): void {

        SummizeStorage.getStorage(false).setItem(key, value);
    }

    public static clearLocal(): void {

        SummizeStorage.getStorage(false).clear();
    }


    private static getStorage(isSession: boolean) {

        try {

            if (this.isIsolated() === true) {

                const w: any = window;

                return isSession === true ? w.summize.session : w.summize.local;

            }

            return isSession === true ? sessionStorage : localStorage;

        } catch (error: any) {

            if (error.message.includes('sandboxed')) {

                SummizeStorage.isolate();

                const w: any = window;

                return isSession === true ? w.summize.session : w.summize.local;

            }

        }

    }

}

class PatchedStorage {

    private state = {}

    constructor(private sessionKey = '') { }

    public removeItem(key: string) {

        delete this.state[`${this.sessionKey}${key}`];

    }

    public setItem(key: string, val: string) {

        this.state[`${this.sessionKey}${key}`] = val;

    }

    public getItem(key: string) {

        return this.state[`${this.sessionKey}${key}`];
    }

    public clear() {

        this.state = {};

    }

}