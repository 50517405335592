import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { SelectBoxModule } from "@summize/shared/components-v2";

import { QuestionDefinitionType } from "@summize/shared/core";
import { TemplateQuestionAnswer } from "@summize/shared/framework";

@Component({
    selector: 'app-template-questions',
    templateUrl: './template-questions.html',
    styleUrl: './template-questions.scss',
    standalone: true,
    imports: [CommonModule, FormsModule, SelectBoxModule],
})
export class TemplateQuestionsComponent {

    public QuestionDefinitionType = QuestionDefinitionType;

    @Input()
    public questions: Array<TemplateQuestionAnswer> = [];

    @Input()
    public isFullUser: boolean;

    public isQuestionBuilderType(type: number): boolean {

        return type > 20;

    }

}