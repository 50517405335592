import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AppComponent } from '@summize/shared/core';

import { ContractStatusService } from './contractstatus-select.service';

@Component({
    selector: 'app-contractstatus-select',
    templateUrl: 'contractstatus-select.html',
    styleUrls: ['./contractstatus-select.scss']
})

export class ContractStatusSelectComponent extends AppComponent implements OnInit {

    @Input()
    public selectedStatusId: string;

    @Input()
    public docIds: Array<string> = [];

    @Input()
    public menuPositionX: 'before' | 'after' = 'before';

    @Input()
    public menuPositionY: 'above' | 'below' = 'below';

    @Input()
    public statusArea: 'All' | 'Review' | 'Manage' = 'All';

    @Input()
    public isDisabled = true;

    @Output()
    public onUpdated: EventEmitter<any> = new EventEmitter();

    public searchValueControl = new FormControl();

    public contractStatuses: any = [];

    public searchValue: string = '';

    public loading: boolean = false;

    public get selectedString() {

        if (this.selectedStatusId === undefined) {

            return 'Change status';

        } else {

            if (this.contractStatuses !== undefined) {

                const match = this.contractStatuses.find(u => u.id === this.selectedStatusId);

                if (match !== undefined) {

                    return `${match.statusName}`;

                }

            }

            if (this.contractStatuses === 0) {

                return 'No status';
            }

            return 'No status';
        }
    }


    constructor(private contractStatusService: ContractStatusService) { super(); }

    public async ngOnInit() {

        if (this.selectedStatusId !== undefined) {

            await this.loadContractStatuses();
        }

        this.searchValueControl.valueChanges.pipe(
            debounceTime(250),
            distinctUntilChanged()
        ).subscribe(async (search) => {

            this.searchValue = search;

            this.loadContractStatuses();

        });

    }

    public async initSearch() {

        this.loading = true;

        if (this.searchValueControl.value === '') {

            await this.loadContractStatuses();

        }

        this.searchValueControl.setValue('');

    }

    public async loadContractStatuses() {

        this.loading = true;

        this.contractStatuses = await this.contractStatusService.getDocumentStatuses(this.searchValue, this.statusArea);

        this.loading = false;

    }

    public async setDocumentsStatus(statusId: number, docIds: Array<string>) {

        if (docIds.length > 0) {

            await this.contractStatusService.setDocumentsStatus(statusId, docIds);

            this.onUpdated.emit();

        }

    }

}
