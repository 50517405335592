import { AfterViewInit, Directive, TemplateRef, ViewContainerRef } from '@angular/core'

@Directive({
    selector: '[isVisible]',
})
export class VisibleDirective implements AfterViewInit {

    private hasLoaded: boolean = false;

    constructor(private vcRef: ViewContainerRef, private tplRef: TemplateRef<any>) { }

    public ngAfterViewInit() {

        const observedElement = this.vcRef.element.nativeElement.parentElement;

        const observer = new IntersectionObserver(([entry]) => {

            this.renderContents(entry.isIntersecting);

        });

        observer.observe(observedElement);
    }

    public renderContents(isIntersecting: boolean) {

        this.vcRef.clear();

        if (isIntersecting === true && this.hasLoaded === false) {

            this.vcRef.createEmbeddedView(this.tplRef);

            this.hasLoaded = true;

        }

    }
}