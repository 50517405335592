import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';

import { ContractStatusPanelComponent } from './contract-status-panel.component';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';

@NgModule({
    imports: [
        CommonModule,
        NgChartsModule,
        ProgressBarModule
    ],
    exports: [
        ContractStatusPanelComponent
    ],
    declarations: [
        ContractStatusPanelComponent
    ]
})
export class ContractStatusPanelModule { }

export * from './contract-status-panel.component'