import { trigger, stagger, animate, style, query, transition, state } from '@angular/animations';

export let FadeInOutAnimation = trigger('fadeInOutAnimation', [
    state('in', style({ opacity: 1 })),
    transition(':enter', [
        query('.fade-block', stagger(10, [
            animate('0.3s cubic-bezier(.75,-0.48,.26,1.52)', style({ opacity: 1 })),
        ])),
    ]),
    transition(':leave', [
        query('.fade-block', stagger(10, [
            animate('0.3s cubic-bezier(.75,-0.48,.26,1.52)', style({ opacity: 0 })),
        ])),
    ])
]);