import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlockButtonModule, LoadingSpinnerModule } from '@summize/shared/components-v2';

import { SectionPanelComponent } from './section-panel.component';

@NgModule({
    imports: [
        CommonModule,
        BlockButtonModule,
        LoadingSpinnerModule
    ],
    exports: [
        SectionPanelComponent
    ],
    declarations: [
        SectionPanelComponent
    ]
})
export class SectionPanelModule { }

export * from './section-panel.component'