import { EventEmitter, Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { createNestablePublicClientApplication, IPublicClientApplication } from "@azure/msal-browser";

import { BaseTenantService, IsValidString, TelemetryService, isSummizeDomain } from '@summize/shared/core';
import { Environment, SummizeStorage } from '@summize/shared/framework';

import { ExternalContextChangedEvent, PlatformAttachments, PlatformSuggestion } from './platform.types';

export const OutlookBridgeEvent = 'OutlookBridgeEvent';

export enum MessageType {
    Attachments
}

interface BridgedAttachments {
    files: any;
    token: any;
}

export const TelemetryEvents = {
    eLFDLaunched: 'eLFDLaunched'
}

declare const Office: any;

@Injectable({ providedIn: 'root' })
export class OutlookService extends BaseTenantService {

    public static get IsOfficeLoaded() {

        const w = (<any>window);

        return w.isOfficeClient === true || w.Office !== undefined;

    }

    public onContextChange: EventEmitter<ExternalContextChangedEvent>;

    public get fileText() {

        return 'Files from email';

    }

    private activeDialog;

    private bridgedAttachments: BridgedAttachments;

    private pca: IPublicClientApplication;

    private args: { outlookClientId: string, useInteractiveAuth: boolean };

    constructor(@Inject(Environment) private environment, private telemetry: TelemetryService, private http: HttpClient) {

        super();

        this.onContextChange = new EventEmitter<ExternalContextChangedEvent>();

    }

    public async ready(args?: { outlookClientId: string, useInteractiveAuth: boolean }) {

        this.args = args;

        if (args !== undefined && args.outlookClientId !== undefined && args.outlookClientId !== null) {

            this.pca = await createNestablePublicClientApplication({
                auth: {
                    clientId: args.outlookClientId,
                    authority: "https://login.microsoftonline.com/common"
                },
            });

        }

        return await Promise.resolve();

    }

    public showRequestModal(requestId: string) {

        const office = OutlookService.getOffice();

        if (office === undefined) {

            return;
        }

        this.clearExistingDialog();

        const options = {
            height: 50,
            width: 30,
            promptBeforeOpen: false,
            displayInIframe: office.context.platform !== 'OfficeOnline'
        };

        const parts = [
            'fullscreen=true',
            'intent=view-request',
            'connector=5',
            `connectorUserId=${encodeURIComponent(this.getUser().email)}`,
            `contractId=${requestId}`,
            `token=${SummizeStorage.getLocalItem('token')}`,
            `host=outlook`
        ];

        const url = `${this.environment.appUrl}/remote?${parts.join('&')}`;

        office.context.ui.displayDialogAsync(url, options, (asyncResult) => {

            this.telemetry.track(TelemetryEvents.eLFDLaunched, { documentId: requestId });

            this.activeDialog = asyncResult.value;

            this.activeDialog.addEventHandler(Office.EventType.DialogMessageReceived, async (arg) => {

                if (isSummizeDomain(arg.origin) === true) {

                    if (arg.message === 'ready') {

                        this.sendDialogMessage({
                            type: MessageType.Attachments,
                            payload: {
                                files: await this.getAttachmentItems(),
                                context: await this.getAttachmentContext(),
                                attachments: await this.getAttachmentItems(false)
                            }
                        });

                    }

                }

            });

        });

    }

    public showDialog(url, options) {

        const office = OutlookService.getOffice();

        if (office === undefined) {

            return;
        }

        this.clearExistingDialog();

        return new Promise(resolve => {

            office.context.ui.displayDialogAsync(url, options, (asyncResult) => {

                resolve(asyncResult);

            });
        });

    }

    public async getEmailAsAttachment(): Promise<any> {

        const context = await this.getAttachmentContext();

        if (context === undefined) {

            return undefined;

        }

        const params = { token: context.token, ewsUrl: context.ewsUrl, itemId: context.itemId };

        const url = `${this.getUserBaseUrl(true)}attachments/office/ews/email`;

        return await this.http.post(url, params, { responseType: 'text' }).toPromise();

    }

    public async getEmailItem(): Promise<any> {

        const office = OutlookService.getOffice();

        if (office === undefined) {

            return;
        }


        return office.context.mailbox.item;

    }

    public getEmailId(): string {

        const office = OutlookService.getOffice();

        if (office === undefined) {

            return;
        }

        return office.context.mailbox.item.itemId;

    }

    public getEmailBody(): Promise<{ value: string }> {

        const office = OutlookService.getOffice();

        if (office === undefined) {

            return;
        }

        return new Promise<{ value: string }>((resolve) => {
            office.context.mailbox.item.body.getAsync("html", (result: any) => resolve(result));
        });

    }

    public async getAttachmentState(): Promise<PlatformAttachments> {

        const [email, all, context] = await Promise.all([
            this.getAttachmentItems(),
            this.getAttachmentItems(false),
            this.getAttachmentContext()
        ]);

        return {
            emailAttachments: email,
            allAttachments: all,
            context: context
        };

    }

    private async getAttachmentItems(filterForDocuments: boolean = true): Promise<any> {

        const office = OutlookService.getOffice();

        if (office === undefined) {

            if (this.bridgedAttachments !== undefined) {

                return this.bridgedAttachments.files;
            }

            return;


        }

        const item = office.context.mailbox.item;

        if (IsValidString(item.conversationId) === false) {

            // Item is draft

            const wrapped = new Promise((resolve) =>
                item.getAttachmentsAsync(results => resolve(results))
            );


            const result: any = await wrapped;

            if (result.status !== 'succeeded') {

                return [];

            }

            const attachments = result.value;

            if (attachments === undefined || attachments === null) {

                return [];

            }

            return filterForDocuments === true ?
                this.filterAttachmentsBySupportedDocTypes(attachments) :
                attachments?.filter(x => x.isInline === false);
        }

        return filterForDocuments === true ?
            this.filterAttachmentsBySupportedDocTypes(item.attachments) :
            item.attachments?.filter(x => x.isInline === false);

    }

    private async getAttachmentContext(): Promise<any> {

        const office = OutlookService.getOffice();

        if (office === undefined) {

            if (this.bridgedAttachments !== undefined) {

                return this.bridgedAttachments.token;
            }

            return;
        }

        const getContextObject = (token: string) => ({
            token,
            attachments: office.context.mailbox.item.attachments,
            ewsUrl: office.context.mailbox.ewsUrl,
            itemId: office.context.mailbox.item.itemId,
            subject: office.context.mailbox.item.subject
        });

        return new Promise(async (resolve) => {

            const hasNAAAuth = office.context.requirements.isSetSupported('NestedAppAuth', '1.1');

            if (this.pca === undefined || hasNAAAuth === false) {

                office.context.mailbox.getCallbackTokenAsync((result) => {
                    return resolve(getContextObject(result.value));
                });

            } else {

                const tokenRequest = {
                    scopes: ['Mail.Read', 'User.Read', 'openid', 'profile'],
                };

                try {

                    const silentResult = await this.pca.acquireTokenSilent(tokenRequest);

                    return resolve(getContextObject(silentResult.accessToken));

                } catch (error) {

                    console.log(`Unable to acquire token silently: ${error}`);

                    if (this.args.useInteractiveAuth === true) {

                        try {

                            const interactiveResult = await this.pca.acquireTokenPopup(tokenRequest);

                            console.log(`Acquired token popup: ${interactiveResult.accessToken}`);

                            return resolve(getContextObject(interactiveResult.accessToken));

                        } catch (error) {

                            console.log(`Unable to acquire token popup: ${error}`);

                            return resolve({});

                        }

                    } else {

                        return resolve({});

                    }
                }
            }

        });

    }

    public isComposeMode() {

        const office = OutlookService.getOffice();

        if (office === undefined) {

            return false;
        }

        return office.context.mailbox.item.addFileAttachmentAsync !== undefined;

    }

    public addFileAttachment(url: string, filename: string, context = { asyncContext: null }) {

        const office = OutlookService.getOffice();

        if (office === undefined) {

            return;
        }

        return new Promise(resolve => {

            Office.context.mailbox.item.addFileAttachmentAsync(url, filename, context, (asyncResult) => {

                resolve(asyncResult);

            });

        });

    }

    public handleEvent(event: any): void {

        if (event.context !== undefined) {

            const context: { type: MessageType, payload: any } = event.context;

            if (context.type === MessageType.Attachments) {

                this.bridgedAttachments = {
                    files: context.payload.files,
                    token: context.payload.context
                };

                this.onContextChange.next(context?.payload?.subject);

            }

        }

    }

    public async contextChanged() {

        const email = await this.getEmailItem();

        this.onContextChange.next({ name: email?.subject });

        const message = {
            type: MessageType.Attachments,
            payload: {
                files: await this.getAttachmentItems(),
                context: await this.getAttachmentContext()
            }
        };

        this.sendDialogMessage(message);

    }

    public isOfficeDesktopClient() {

        const office = (<any>window).Office;

        if (office === undefined) {

            return false;

        }

        return (<any>window).isOfficeClient === true
            && office.context.platform !== 'OfficeOnline';

    }

    public downloadBlob(url: any) {

        const office = (<any>window).Office;

        if (office !== undefined) {

            // openBrowserWindow isnt supported for mac
            // and doesnt work well on windows
            // need to fallback to just a window.open

            window.open(url);

        }

    }

    public openInBrowser(url: string) {

        const office = (<any>window).Office;

        if (office === undefined) {

            return false;

        }

        if (office.context.platform === 'OfficeOnline') {

            window.open(url);

        } else {

            Office.context.ui.openBrowserWindow(url);

        }

    }

    public async getPlatformSuggestions(): Promise<Array<PlatformSuggestion>> {

        const body = await this.getEmailBody();

        const div = document.createElement('div');

        div.innerHTML = body.value.trim();

        const mail = await this.getEmailItem();

        return [
            { name: 'summary', key: 'summary', value: mail.subject },
            { name: 'subject', value: mail.subject },
            { name: 'from', value: mail.emailAddress },
            { name: 'body', value: div.querySelector('p')?.textContent },
        ];

    }

    private static getOffice(): any {

        const windizzle = (<any>window);

        // We use outlook in the eLFD for messaging
        // if isOfficeClient is true, always ignore any
        // office calls
        if (windizzle.isOfficeClient === true) {

            return undefined;

        }

        if (windizzle.Office !== undefined) {

            return windizzle.Office;

        }

        return undefined;

    }

    private filterAttachmentsBySupportedDocTypes(attachments) {

        if (attachments === undefined || attachments === null) {

            return [];

        }

        return attachments
            .filter(x => x.isInline === false)
            .filter((x: { name: string }) =>
                x.name.toLowerCase().endsWith('.pdf') ||
                x.name.toLowerCase().endsWith('.docx')
            );

    }

    private sendDialogMessage(message: any) {

        if (this.activeDialog !== undefined) {

            this.activeDialog.messageChild(JSON.stringify(message), { targetOrigin: "*" });

        }

    }

    private clearExistingDialog() {

        if (this.activeDialog !== undefined && this.activeDialog !== null) {

            try {

                this.activeDialog.close();

            } catch (error) {

                // Sometimes can throw if already manually closed

            }

            this.activeDialog = undefined;
        }

    }

}