import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-breadcrumb-bar',
    templateUrl: 'breadcrumb-bar.html',
    styleUrls: ['./breadcrumb-bar.scss']
})
export class BreadcrumbBarComponent {

    @Input()
    public title: string;

    @Input()
    public toolbarTemplate: TemplateRef<any>;

    @Input()
    public centerTemplate: TemplateRef<any>;

    @Input()
    public hideNavigation: boolean = false;

    @Input()
    public customBackEvent: Function;

    @Input()
    public onTitleClick: Function;

    @Output()
    public onBack: EventEmitter<void> = new EventEmitter<void>();

    public doBackEvent(): void {

        if (this.customBackEvent !== undefined) {

            this.customBackEvent();

        } else {

            this.onBack.emit();

        }


    }

    public doTitleClick() {

        if (this.onTitleClick !== undefined) {

            this.onTitleClick();

        }

    }
}