import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InfoPointComponent } from './info-point.component';
import { TooltipModule } from '../tooltip/tooltip.module';

@NgModule({
    imports: [
        CommonModule,
        TooltipModule
    ],
    exports: [
        InfoPointComponent
    ],
    declarations: [
        InfoPointComponent
    ]
})
export class InfoPointModule { }

export * from './info-point.component'