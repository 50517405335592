<div class="container">

  <quill-editor class="editor {{display}}" [readOnly]="readonly" [styles]="configuration?.style"
    [formats]="configuration?.formats" [modules]="configuration?.modules" [(ngModel)]="renderedContents"
    (onEditorChanged)="getContent($event)">
  </quill-editor>

  <button class="send" (click)="contentSend()" matTooltip="Send Message" [disabled]="isSendDisabled">
    <app-icon icon="send-outline"></app-icon>
  </button>

</div>
