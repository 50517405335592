import { Component, OnInit } from '@angular/core';

import { ToastItem, ToastService } from './toast.service';

@Component({
    selector: 'app-toast-container',
    templateUrl: './toast-container.html',
    styleUrls: ['./toast-container.scss']
})
export class ToastContainerComponent implements OnInit {

    public toasts: Array<ToastItem>;

    constructor(private service: ToastService) { }

    ngOnInit() {

        this.service.$change.subscribe(changes => this.toasts = changes);

    }

}