import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UserService } from '@summize/shared/framework';
import { EMPTY_GUID } from '@summize/shared/core';

import { InputMessageElement } from './components/chat-input/chat-input.component';

export enum AssistantChatMessageSource {
  User = 0,
  Assistant,
  ChatBreak,
  TokenLimit
};

export interface AssistantChatMessage {
  createdAt: string;
  createdBy: string;
  message: string;
  typingMessage?: string;
  source: AssistantChatMessageSource;
}

export interface AssistantChatThread {
  assistantChatThreadId: string;
  assistantChatMessages: AssistantChatMessage[];
}

export interface AssistantChatPrompt {
  promptId: string;
  label: string;
}

export interface AssistantSettings {
  typingSpeedMs: number;
  insertAsHtml: boolean;
  displayAsHtml: boolean;
}

export interface AssistantMeta {
  settings: AssistantSettings;
  prompts: AssistantChatPrompt[];
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(
    private httpClient: HttpClient,
    private userService: UserService,
  ) { }

  public async getMeta(clientId: string, matterId: string): Promise<AssistantMeta> {

    const user = await this.userService.getUser();

    let url = `${user.apiBaseUrl}1.0/assistant/meta`;

    if (clientId !== undefined && clientId !== EMPTY_GUID) {
      url += `?clientId=${clientId}&matterId=${matterId}`;
    }

    const response = await this.httpClient.get<AssistantMeta>(url).toPromise();

    return this.responseMap(response);

  }

  public async getMetaForDocument(documentId: string): Promise<AssistantMeta> {

    const user = await this.userService.getUser();
    const response = await this.httpClient.get<AssistantMeta>(`${user.apiBaseUrl}1.0/assistant/meta/documents/${documentId}`).toPromise();

    return this.responseMap(response);

  }

  public async getPrompts(path: string): Promise<any> {

    const user = await this.userService.getUser();
    const response = await this.httpClient.get<AssistantMeta>(`${user.apiBaseUrl}1.0/${path}`).toPromise();

    const prompts = (response?.prompts || []).map(x => ({
        key: x.promptId.toString(),
        value: `Prompt: ${x.label}`,
        menuItem: x.label,
        type: 'prompt',
        id: `prompt:${x.promptId.toString()}`,
        ...x
    }));

    return { prompts };

  }

  public async getThreadById(chatThreadId: string): Promise<AssistantChatThread> {

    const user = await this.userService.getUser();
    return this.httpClient.get<AssistantChatThread>(`${user.apiBaseUrl}1.0/assistant/chats/${chatThreadId}`).toPromise();

  }

  public async sendChatMessage(chatThreadId: string, message: string, messageElements: InputMessageElement[]): Promise<AssistantChatThread> {

    const user = await this.userService.getUser();
    return this.httpClient
      .post<AssistantChatThread>(
        `${user.apiBaseUrl}1.0/assistant/chats/${chatThreadId}`, {
          message: message,
          messageElements: messageElements
        })
      .toPromise();

  }

  public async sendChatMessageNewThread(chatThreadId: string): Promise<AssistantChatThread> {

    const user = await this.userService.getUser();
    return this.httpClient
      .post<AssistantChatThread>(
        `${user.apiBaseUrl}1.0/assistant/chats/${chatThreadId}`, {
          message: '',
          messageElements: [],
          source: AssistantChatMessageSource.ChatBreak
        })
      .toPromise();

  }

  private responseMap(response) {

      const prompts = (response?.prompts || []).map(x => ({
          key: x.promptId.toString(),
          value: `Prompt: ${x.label}`,
          menuItem: x.label,
          type: 'prompt',
          id: `prompt:${x.promptId.toString()}`,
          ...x
      }));

      return { settings: response?.settings, prompts };

    }

}
