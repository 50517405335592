import { Component, Input } from '@angular/core';

import { TemplatePermissionTargetAll, TemplatePermissionTargetNone } from '@summize/shared/core';
import { TemplatePermission, TemplatePermissionType } from '@summize/shared/framework';

@Component({
    selector: 'app-template-permissions',
    templateUrl: 'template-permissions.html',
    styleUrl: './template-permissions.scss'
})
export class TemplatePermissionsComponent {

    public TemplatePermissionType = TemplatePermissionType;

    @Input()
    public permissions: Array<TemplatePermission>;

    public hasPermission(type: TemplatePermissionType): boolean {

        const found = this.permissions?.find(x => x.type === type);

        return found === undefined ? true : found.target === TemplatePermissionTargetAll;

    }

    public setPermission(type: TemplatePermissionType, value: boolean): void {

        const match = this.permissions?.find(x => x.type === type);

        if (match === undefined) {

            if (this.permissions === undefined) {

                this.permissions = [];

            }

            this.permissions.push({
                type,
                target: value === true ? TemplatePermissionTargetAll : TemplatePermissionTargetNone
            });

        } else {

            match.target = value === true ? TemplatePermissionTargetAll : TemplatePermissionTargetNone;

        }

    }

}