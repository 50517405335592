import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ListOperators, NumericOperators, StringOperators } from '@summize/shared/core';

import { AvailableFilter, CustomFieldFilterType, FilterType, SelectedFilter } from '../filter-builder/filter-builder.component';
import { SmzDatePipe } from '../pipes/date.pipe';

@Component({
    selector: 'app-selected-filter',
    templateUrl: 'selected-filter.html',
    styleUrls: ['./selected-filter.scss']
})
export class SelectedFilterComponent {

    @Output()
    public onRemove = new EventEmitter<SelectedFilter>();

    @Output()
    public onEdit = new EventEmitter<SelectedFilter>();

    @Input()
    public filter: SelectedFilter;

    @Input()
    public context: Array<AvailableFilter>;

    public overlayOpen: boolean = false;

    public filterText;

    constructor(private datepipe: SmzDatePipe) { }

    public async ngOnInit(): Promise<void> {

        this.filter.subType !== undefined ?
            this.setFilterTextForSubtype() : await this.setFilterTextForPrimaryType();

    }

    private setFilterTextForSubtype() {

        const parts = this.filter.value.split('_');

        if (this.filter.subType === CustomFieldFilterType.Freetext) {

            this.filterText = `${StringOperators.find(k => k.key === parts[0]).value}: '${parts[1]}'`;

        } else if (this.filter.subType === CustomFieldFilterType.Numeric) {

            this.filterText = `${NumericOperators.find(k => k.key === parts[0]).value}: ${parts[1]}`;

        }
        else if (this.filter.subType === CustomFieldFilterType.List) {

            this.filterText = `${ListOperators.find(k => k.key === parts[0]).value}: ${parts[1].split(',').join(' / ')}`;

        } else if (this.filter.subType === CustomFieldFilterType.Date) {

            const selected = parts[1].split(',');

            const from = new Date(Date.parse(selected[0]));

            const to = new Date(Date.parse(selected[1]));

            const isDefault = (d: Date) => {

                return d.getFullYear() === 1;

            }

            if (isDefault(from) === false && isDefault(to) === true) {

                this.filterText = `From ${this.datepipe.transform(from, 'mediumDate')}`;

            }

            if (isDefault(from) === true && isDefault(to) === false) {

                this.filterText = `Until ${this.datepipe.transform(to, 'mediumDate')}`;

            }

            if (isDefault(from) === false && isDefault(to) === false) {

                this.filterText = `From ${this.datepipe.transform(from, 'mediumDate')} - Until ${this.datepipe.transform(to, 'mediumDate')}`;
            }

        }

    }

    private async setFilterTextForPrimaryType() {

        const match = this.context.find(c => c.id === this.filter.id);

        if (match !== undefined) {

            const items = await match.source();

            if (match.type === FilterType.List || match.type === FilterType.RadioSelect) {

                const selected = match.urlEncode === true ? 
                    this.filter.value.split(',').map(x => decodeURIComponent(x)) :
                    this.filter.value.split(',');

                this.filterText = items
                    .filter(i => selected.includes(i.id) || selected.includes(i.id.toString()))
                    .map(i => i.name)
                    .join(' / ');

            } else if (match.type === FilterType.DateRange) {

                const selected = this.filter.value.split(',');

                const from = new Date(Date.parse(selected[0]));

                const to = new Date(Date.parse(selected[1]));

                const isDefault = (d: Date) => {

                    return d.getFullYear() === 1;

                }

                if (isDefault(from) === false && isDefault(to) === true) {

                    this.filterText = `From ${this.datepipe.transform(from, 'mediumDate')}`;

                }

                if (isDefault(from) === true && isDefault(to) === false) {

                    this.filterText = `Until ${this.datepipe.transform(to, 'mediumDate')}`;

                }

                if (isDefault(from) === false && isDefault(to) === false) {

                    this.filterText = `From ${this.datepipe.transform(from, 'mediumDate')} - Until ${this.datepipe.transform(to, 'mediumDate')}`;
                }

            } else if (match.type === FilterType.ListWithGrouping) {

                const selected = this.filter.value.split(',');

                this.filterText = [
                    ...items.groups.filter(i => selected
                        .includes(i.id))
                        .map(i => i.name),
                    ...items.items.filter(i => selected
                        .includes(i.id))
                        .map(i => i.name)
                ].join(' / ');

            } else if (match.type === FilterType.EntitiesWithGrouping) {

                const selected = this.filter.value.split(',');

                this.filterText = [];

                selected.forEach((itemId: string) => {
                    Object.keys(items.entities).forEach((key: string) => {
                        items.entities[key].forEach((item: any) => {
                            if (item.id === itemId) {
                                this.filterText.push(item.name);
                            }
                        });
                    });
                });

                this.filterText = this.filterText.join(' / ');

            } else if (match.type === FilterType.ParentChildList) {

                const selected = this.filter.value.split(',');

                const matches = [];

                const safeSelected = selected.map(s => s.toLowerCase());

                for (const parent of items) {

                    const children = parent.children
                        .filter(i => safeSelected.includes(i.id.toLowerCase()))
                        .map(i => i.name)

                    if (children.length > 0) {

                        matches.push(`${parent.name} > ${children.join(' / ')}`);

                    }

                    if (safeSelected.includes(parent.id.toLowerCase()) === true) {

                        matches.push(`${parent.name}`);

                    }

                }

                this.filterText = matches.join(' / ');

            }

        }

    }
}