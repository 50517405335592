import { IconModule } from '../icon/icon.module';
import { MatChipsModule } from '@angular/material/chips';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AutocompleteChipsGroupsComponent } from './autocomplete-chips-groups.component';

@NgModule({
    imports: [
        CommonModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatChipsModule,
        IconModule,
    ],
    exports: [
        AutocompleteChipsGroupsComponent
    ],
    declarations: [
        AutocompleteChipsGroupsComponent
    ]
})
export class AutocompleteChipsGroupsModule { }

export * from './autocomplete-chips-groups.component'