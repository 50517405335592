import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { AppComponent, EMPTY_GUID } from '@summize/shared/core';
import { EventService, SetContext, ShowGlobalSearchResult } from '@summize/shared/framework';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { GlobalSearchService } from './global-search.service';

export enum ComponentState {
    Initial = 'Initial',
    Pending = 'Pending',
    NoResults = 'NoResults',
    Results = 'Results'
}

interface SegmentModel<T> {
    header: string;
    partial: Array<T>;
    full: Array<T>;
    onItemClick: (event: any, item: T) => void;
    onMoreClick: (event: any, item: T) => void;
}

interface ViewModel {
    keyOrder: Array<string>;
    files: SegmentModel<any>;
    parties: SegmentModel<any>;
}

@Component({
    selector: 'app-global-search',
    templateUrl: 'global-search.html',
    styleUrls: ['./global-search.scss']
})

export class GlobalSearchComponent extends AppComponent implements OnInit {

    public State = ComponentState;

    @Input()
    public placeholder: string = 'Search for contracts names, parties or contract text';

    @Input()
    public documentsOnly: boolean = false;

    @Input()
    public inputQuery: string = '';

    @Input()
    public set clientId(value: string) {

        this._clientId = value;

        if (this._clientId !== null && this._clientId !== undefined && this._clientId !== EMPTY_GUID) {

            this.event.despatch(SetContext, { clientId: this._clientId });

        } else {

            this.event.despatch(SetContext, { clientId: EMPTY_GUID });

        }

    }

    public get clientId(): string {

        return this._clientId;

    }

    @Input()
    public set matterId(value: string) {

        this._matterId = value;

        if (this._matterId !== null && this._matterId !== undefined && this._matterId !== EMPTY_GUID) {

            this.event.despatch(SetContext, { matterId: this._matterId });

        } else {

            this.event.despatch(SetContext, { matterId: EMPTY_GUID });

        }

    }

    public get matterId(): string {

        return this._matterId;

    }

    public active: boolean;

    public query: string;

    public model: ViewModel;

    public currentState: ComponentState;

    public WILDCARD: string = 'a';

    public hasFullTextSearch: boolean = true;

    public isMultitenancySearchEnabled: boolean = false;

    private $queryChanged: Subject<string>;

    private _matterId: string;

    private _clientId: string;

    constructor(
        private service: GlobalSearchService,
        private event: EventService,
        private router: Router) {

        super();

        this.$queryChanged = new Subject<string>();

    }

    public ngOnInit(): void {

        this.hasFullTextSearch =
            this.user.isFullTextSearchEnabled !== undefined &&
            this.user.isFullTextSearchEnabled !== null &&
            this.user.isFullTextSearchEnabled === true;

        this.isMultitenancySearchEnabled = this.user.isMultitenancySearchEnabled === true;

        this.currentState = ComponentState.Initial;

        this.$queryChanged.pipe(debounceTime(300), distinctUntilChanged()).subscribe(async query => {

            if (query.length > 1 || query === this.WILDCARD) {

                this.query = query;

                this.runQuery();

            }

        });

    }

    public search(event: { target: { value: string; }; }): void {

        if (event.target.value.length >= 2) {

            this.$queryChanged.next(event.target.value);

        }

    }

    public clear(event: any): void {

        event.preventDefault();

        event.stopPropagation();

        this.query = undefined;

        this.inputQuery = '';

        this.$queryChanged.next('');

        this.currentState = this.State.Initial;

    }

    public goToTextSearch(event): void {

        this.event.despatch(ShowGlobalSearchResult, {
            type: 'documents',
            query: this.inputQuery,
            facetName: 9,
            clientId: this.clientId,
            matterId: this.matterId
        });

        this.clear(event);

    }

    private async runQuery(): Promise<void> {

        if (this.query === undefined || this.query === '') {

            return;

        }

        this.currentState = ComponentState.Pending;

        try {

            this.model = this.buildModel(await this.service.query(this.query, this.clientId, this.matterId, this.documentsOnly));

            if (this.model.parties?.full?.length === 0 && this.model.files?.full?.length === 0) {

                this.currentState = ComponentState.NoResults;
            }
            else {

                this.currentState = ComponentState.Results;
            }

        } catch (error) {

            this.currentState = ComponentState.NoResults;

            console.error(error);

        }

    }

    private buildModel(result: any): ViewModel {

        const results = {
            keyOrder: ['files', 'parties'],
            parties: {
                header: 'Parties',
                full: result.parties,
                partial: [...result.parties].splice(0, 5),
                onItemClick: (event, item) => {

                    this.router.navigateByUrl(this.getRepositoryPage({ party: item.display }));

                    return;

                },
                onMoreClick: () => {

                    this.router.navigateByUrl(this.getRepositoryPage({ query: this.query }));

                    return;

                }
            },
            files: {
                header: 'Contract names',
                full: result.fileNames,
                partial: [...result.fileNames].splice(0, 5),
                onItemClick: (event, item) => {

                    this.router.navigateByUrl(this.getRepositoryPage({ query: item.display }));

                    return;

                },
                onMoreClick: () => {

                    this.router.navigateByUrl(this.getRepositoryPage({ query: this.query }));

                    return;

                }
            }
        };

        return this.documentsOnly === true
            ? {
                ...results,
            } as any
            : results;

    }

}
