import { Component, Input } from '@angular/core';
import { ListItem } from './list-item/list-item.component';


@Component({
    selector: 'app-list',
    templateUrl: './list.html',
    styleUrls: ['./list.scss']
})
export class ListComponent {

    @Input()
    public listItems: ListItem[];
}