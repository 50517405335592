import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TenantSettingService, TenantSettingTypes } from '@summize/shared/core';

@Component({
    selector: 'app-request-analytics-moved-modal',
    templateUrl: 'request-analytics-moved-modal.html',
    styleUrl: './request-analytics-moved-modal.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestAnalyticsMovedComponent {

    public notificationModalRequest: any;

    public showAgain: boolean = true;

    public disableClick: boolean = false;

    public hideCheckLater: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<RequestAnalyticsMovedComponent>,
        private tenantSettingService: TenantSettingService) {

        this.notificationModalRequest = data;

    }

    public ngOnInit() {

        if (this.data.menuItemClick === true) {

            this.hideCheckLater = true;

        }

    }

    public async closeAndCheckLater() {

        await this.updateTenantSetting();

        this.dialogRef.close({ goToAnalytics: false });

    }

    public async closeAndGoToAnalytics() {

        await this.updateTenantSetting();

        this.dialogRef.close({ goToAnalytics: this.hideCheckLater === false });

    }

    private async updateTenantSetting() {

        this.disableClick = true;

        await this.tenantSettingService.saveUserSetting(
            TenantSettingTypes.NotificationRequestAnalyticsMovedConfirmed,
            {
                settingValue: 'true',
            }
        );
        
    }

}