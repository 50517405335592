<ng-container>
    <button [disabled]="isDisabled" class="button" [class.button--primary]="type === 'primary'"
        [class.button--secondary]="type === 'secondary'" [class.button--tertiary]="type === 'tertiary'"
        [class.button--none]="type === 'none'" [class.button--small]="size=== 'small'"
        [class.button--medium]="size === 'medium'" [class.button--large]="size === 'large'"
        [class.button--disabled]="isDisabled" [class.button--primaryPurple]="type === 'primaryPurple'"
        [class.button--secondaryPurple]="type === 'secondaryPurple'"
        [class.button--tertiaryPurple]="type === 'tertiaryPurple'" [class.button--ghost]="type === 'ghost'"
        [class.button--ghost-outline]="type === 'ghost-outline'" [class.button--expand]="expand"
        [class.text-align-left]="textAlign === 'left'"
        [class.text-align-center]="textAlign === 'center'"
        [class.text-align-right]="textAlign === 'right'">
        <ng-content></ng-content>
    </button>
</ng-container>