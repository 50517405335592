import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SearchBarComponent } from './search-bar.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    exports: [
        SearchBarComponent
    ],
    declarations: [
        SearchBarComponent
    ]
})
export class SearchBarModule { }

export * from './search-bar.component'