import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { RequestAnalyticsMovedComponent } from './request-analytics-moved-modal.component';
import { BlockButtonModule } from '../block-button/block-button.module';
import { DialogShellModule } from '../dialog-shell/dialog-shell.module';

@NgModule({
    imports: [
        CommonModule,
        DialogShellModule,
        BlockButtonModule,
        MatCheckboxModule
    ],
    exports: [
        RequestAnalyticsMovedComponent
    ],
    declarations: [
        RequestAnalyticsMovedComponent
    ]
})
export class RequestAnalyticsMovedModule { }

export * from './request-analytics-moved-modal.component';