import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { BaseTenantService } from "@summize/shared/core";

export interface LatestDocument {
    documentId: string;
    documentName: string;
    clientId: string;
    matterId: string;
}

@Injectable({ providedIn: 'root' })
export class GotoLatestDocumentService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getLatestDocument(documentId: any): Promise<LatestDocument>  {

        const baseUrl = await this.getUserBaseUrl(true);

        return await this.http.get<LatestDocument>(`${baseUrl}documents/${documentId}/latest`).toPromise();

    }

}
