import { Component, Input, OnInit } from '@angular/core';

import { CommandDefinition, SelectBoxModel } from '@summize/shared/components-v2';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { AllContractTypesId, AppComponent, CloneObject } from '@summize/shared/core';
import { v4 as uuidv4 } from 'uuid';

import { CustomFieldDefinition, CustomFieldType } from '../../types';

@Component({
    selector: 'app-custom-field-editor',
    templateUrl: 'custom-field-editor.html',
    styleUrls: ['./custom-field-editor.scss']
})
export class CustomFieldEditorComponent extends AppComponent implements OnInit {

    public CustomFieldType = CustomFieldType;

    @Input()
    public contractTypes: Array<SelectBoxModel>;

    public model: CustomFieldDefinition;

    public invalidName: boolean = false;

    public isConfirmingUpdate: boolean = false;

    public fieldTypes: Array<SelectBoxModel>;

    public contractTypeTargeting: Array<SelectBoxModel>;

    public selectedContractTypeTargeting: any;

    public isEdit: boolean = false;

    public values: Array<{ id: string, display: string }>

    public async ngOnInit() {

        this.isEdit = this.model !== undefined;

        if (this.model === undefined) {

            this.model = <any>{
                fieldType: undefined,
                values: []
            };

        }

        if (this.isEdit) {

            this.selectedContractTypeTargeting
                = this.model.targetContractTypes.includes(AllContractTypesId) === true ? '0' : '1';

        }

        if (this.isEdit && this.model.values !== undefined && this.model.values !== '') {

            const parsed = JSON.parse(this.model.values);

            this.values = parsed.map(v => ({ id: v.id, display: v.display }));

        }

        this.fieldTypes = [
            { key: <any>CustomFieldType.Dropdown, value: 'Dropdown' },
            { key: <any>CustomFieldType.MultiSelect, value: 'Dropdown (multiple-select)' },
            { key: <any>CustomFieldType.FreeText, value: 'Free text' },
            { key: <any>CustomFieldType.Numeric, value: 'Numeric' },
            { key: <any>CustomFieldType.Datepicker, value: 'Date picker' }
        ];

        this.contractTypeTargeting = [
            { key: '0', value: 'All contract types' },
            { key: '1', value: 'Specific contract types' }
        ];
    }

    public getDefinition(): CustomFieldDefinition {

        const item: CustomFieldDefinition = CloneObject(this.model);

        if (this.selectedContractTypeTargeting === '0') {

            item.targetContractTypes = AllContractTypesId;

        } else {

            item.targetContractTypes = item.targetContractTypes.join(',');

        }

        if (item.fieldType === CustomFieldType.Dropdown || item.fieldType === CustomFieldType.MultiSelect) {

            item.values = JSON.stringify(this.values);

        } else {

            item.values = '';

        }

        return item;

    }

    public setFieldType(type: any): void {

        this.model.fieldType = type;

        if (this.model.fieldType === CustomFieldType.MultiSelect ||
            this.model.fieldType === CustomFieldType.Dropdown) {

            if (this.values === undefined || this.values.length === 0) {

                this.addValue();

            }

        }

    }

    public validate(command: CommandDefinition) {

        if (command.isSaveCommand === true) {

            const base = (this.model.name !== undefined && this.model.name !== '')
                && (this.selectedContractTypeTargeting === '0' || this.model.targetContractTypes?.length > 0)
                && this.model.fieldType !== undefined;

            if (this.model.fieldType === CustomFieldType.MultiSelect ||
                this.model.fieldType === CustomFieldType.Dropdown) {

                return base
                    && this.values.length > 0
                    && this.values.some(v => v.display === '') === false;

            }

            return base;

        }

        return true;

    }

    public onDrop(event) {

        moveItemInArray(this.values, event.previousIndex, event.currentIndex);

    }

    public hasClaim(claim): boolean {

        return this.hasClaims([claim]);

    }

    public addValue() {

        if (this.values === undefined) {

            this.values = [];

        }

        this.values.push({ id: uuidv4(), display: '' });

    }

    public removeValue(index: number) {

        this.values.splice(index, 1);

    }

}