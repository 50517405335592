<div class="select-menu-container">
    <button type="button" class="select-menu-btn" [matMenuTriggerFor]="menu" [matMenuTriggerData]="menuData" panelClass="testing-big-name">
        {{ selectedItem ? selectedItem.title : "Select" }}
    </button>
    <mat-menu #menu="matMenu" class="max-w-none">
        <ng-template matMenuContent let-menuWidth="menuWidth">
            <div [style.width.px]="menuWidth">
                <div *ngFor="let item of items" (click)="onSelectionChange(item)" class="h-min select-menu-item" [class.select-menu-item-selected]="item.value === value">

                    <app-icon *ngIf="item.icon" [icon]="item.icon" class="icon"></app-icon>

                    <div class="item-text">
                        <span class="title">{{ item.title }}</span>
                        <span class="description">{{ item.description }}</span>
                    </div>

                </div>
            </div>

        </ng-template>

    </mat-menu>
</div>