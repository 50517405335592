import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService, EMPTY_GUID } from '@summize/shared/core';

export interface Clause extends Record<string, any> { };

export interface TldrResponse {
    answer?: string;
    response?: OpenAiResponse;
    error?: OAiError;
}

export interface OpenAiResponse {
    id: string;
    object: string;
    created: string;
    model: string;
    choices: Choices[];
    usage: Usage[];
}

export interface Choices {
    text: string;
    index: string;
    logprobs: string;
    finish_reason: string;
}

export interface Usage {
    prompt_tokens: string;
    completion_tokens: string;
    total_tokens: string;
}

export enum OAiError {
    MinLength,
    AboveCapacity,
    Generic,
}

export interface ClauseTextsFailuresResponse {
    clausesTextDeleteFailures: DeleteClauseTextRequest[];
    clausesTextUpdateFailures: UpdateClauseTextRequest[];
}

export interface DeleteClauseTextRequest {
    clauseId: number;
    clauseIndex: number;
}

export interface UpdateClauseTextRequest {
    clientId: string,
    matterId: string,
    documentId: string,
    clauseIndex: number,
    clauseId: number,
    clauseName: string,
    newPara: string,
    clauseText: string
}

@Injectable({ providedIn: 'root' })
export class OpenAiTldrPanelService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getTldr(clauseText: string): Promise<TldrResponse> {

        const response: TldrResponse = {};

        if (clauseText.length < 128) {

            response.error = OAiError.MinLength;

            return response;
        }

        const payload = {
            input: clauseText
        }

        const baseUrl = this.getUserBaseUrl(true);

        try {

            const oAiResult = await this.http.post<TldrResponse>(`${baseUrl}openai/generatetldr`, payload).toPromise();

            return oAiResult;


        } catch (error) {

            if (error.status === 503 || error.status === 429) {

                response.error = OAiError.AboveCapacity;

            } else {

                response.error = OAiError.Generic;

                return response;

            }

        }

    }

    async replaceClauseTexts(clause: Clause, clientId: string, matterId: string, documentId: string, updatedText: string): Promise<ClauseTextsFailuresResponse> {

        const baseUrl = this.getUserBaseUrl(true);

        const url = `${baseUrl}documents/${documentId}/clauses/replace`;

        clientId = clientId ?? EMPTY_GUID;
        matterId = matterId ?? EMPTY_GUID;

        let clauseTextsToDelete: DeleteClauseTextRequest[] = [];

        const ruleResultToUpdate = clause.ruleResult[0];

        if (clause.ruleResult.length > 1) {

            clauseTextsToDelete = clause.ruleResult
                .slice(1)
                .map(x => {
                    return {
                        clauseIndex: clause.ruleResult.indexOf(x),
                        clauseId: clause.clauseId
                    }
                });
        }

        const clauseTextsToUpdate: UpdateClauseTextRequest[] = [{
            clientId: clientId,
            matterId: matterId,
            documentId: documentId,
            clauseIndex: 0,
            clauseId: clause.clauseId,
            clauseName: clause.ruleName,
            newPara: ruleResultToUpdate.paragraphNumber,
            clauseText: updatedText

        }];

        const body = {
            clausesTextsToDelete: clauseTextsToDelete,
            clausesTextsToUpdate: clauseTextsToUpdate
        };

        return await this.http.put<any>(url, body).toPromise();

    }

}