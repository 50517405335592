<div *ngIf="first !== undefined && isSingular === false" class="dropdown">
    <div class="primary" [style.width.px]="width" [disabled]="disabled">
        <app-block-button (click)="fire(first); expanded = true" [display]="selectedDisplayText" [type]="type" [disabled]="disabled" [prefix]="prefix" />
        <app-icon *ngIf="expanded === false" [ngClass]="iconClasses" (click)="expanded = true" class="icon clickable" icon="chevron-down-outline" />
        <app-icon *ngIf="expanded === true" [ngClass]="iconClasses" (click)="expanded = false" class="icon clickable" icon="chevron-up-outline" />
    </div>

    <div *ngIf="expanded === true" class="sub" [class.align-right]="align === 'right'">
        <div *ngFor="let command of items" (click)="fire(command)" class="command">
            <app-block-button [display]="command.display" type="ghost" [icon]="command.icon"
                [disabled]="command.isDisabled === true" [class.disabled]="command.isDisabled === true"
                [matTooltip]="command.disabledReason" matTooltipClass="toolbar">
            </app-block-button>
        </div>
    </div>
</div>

<app-block-button *ngIf="isSingular === true" (click)="fire(first)" [width]="width" [display]="first.display"
    [type]="type"></app-block-button>

<div *ngIf="expanded === true" (click)="expanded = false" class="backdrop">
    &nbsp;
</div>