export class HtmlHelper {

    public static PlaybookAsHtml(text: string): string {

        if (text === undefined) {
            return '';
        }

        let html = [];

        let paragraphs = text.replace('\t', ' ').split('\n');

        let currentBulletLevel = -1;

        paragraphs.forEach(p => {

            const bulletLevel = HtmlHelper.getBulletLevel(p);

            if (bulletLevel && bulletLevel.level >= 0) {

                if (bulletLevel.level > currentBulletLevel) {
                    let diff = bulletLevel.level - currentBulletLevel;
                    while (diff > 0) {
                        html.push(`<ul>`);
                        diff--;
                    }
                } else if (bulletLevel.level < currentBulletLevel) {
                    let diff = currentBulletLevel - bulletLevel.level;
                    while (diff > 0) {
                        html.push(`</ul>`);
                        diff--;
                    }
                }

                html.push(`<li>${bulletLevel.text.trim()}</li>`);

                currentBulletLevel = bulletLevel ? bulletLevel.level : -1;

            } else {

                while (currentBulletLevel >= 0) {
                    html.push(`</ul>`);
                    currentBulletLevel--;
                }

                html.push(`<p>${p.trim()}</p>`);

            }
        });

        while (currentBulletLevel >= 0) {
            html.push(`</ul>`);
            currentBulletLevel--;
        }

        return html.join('\n');

    }

    private static getBulletLevel(text: string): { level: number, text: string } | undefined {

        let bulletIndex = text.indexOf('●');
        if (bulletIndex > -1 && bulletIndex < 3) {
            return { level: 0, text: text.substr(bulletIndex + 1) };
        }

        bulletIndex = text.indexOf('○');
        if (bulletIndex > -1 && bulletIndex < 3) {
            return { level: 1, text: text.substr(bulletIndex + 1) };
        }

        bulletIndex = text.indexOf('■');
        if (bulletIndex > -1 && bulletIndex < 3) {
            return { level: 2, text: text.substr(bulletIndex + 1) };
        }

        bulletIndex = text.indexOf('*');
        if (bulletIndex > -1 && bulletIndex < 4) {
            return { level: bulletIndex, text: text.substr(bulletIndex + 1) };
        }

        bulletIndex = text.indexOf('-');
        if (bulletIndex > -1 && bulletIndex < 4) {
            return { level: bulletIndex, text: text.substr(bulletIndex + 1) };
        }

        return undefined;
    }
}