import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CloneObject } from '@summize/shared/core';

@Component({
    selector: 'app-filter-edit-dialog',
    templateUrl: 'filter-edit-dialog.html',
    styleUrls: ['./filter-edit-dialog.scss']
})
export class FilterEditDialogComponent {

    public model: any;

    public isSaveOrEdit: boolean;

    public saveAsNew: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<FilterEditDialogComponent>) {

        this.model = CloneObject(data?.filter?.model || {
            name: undefined,
            default: false
        });

        this.isSaveOrEdit = data?.isSaveOrEdit;

    }

    public save() {

        this.dialogRef.close({
            model: this.model,
            saveAsNew: this.saveAsNew
        });

    }

    public close() {

        this.dialogRef.close(undefined);

    }

}