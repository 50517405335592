import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

import { Subject } from 'rxjs';
import { BaseTenantService, EMPTY_GUID, RequestorPlaceholderGuid } from '@summize/shared/core';
import { 
    ESignatureAutoSendModel,
    ESignatureAutoSendVersion, 
    ESignatureRecipientLocationType,
    ESignatureRecipientType, 
    ESignatureRecipientOrigin, 
    ESignatureMeta, 
    EsignatureIntegrationType, 
    EsignatureSigningOrder,
    OutboundIntegration, 
    OutboundIntegrationTypes, 
    RequestSubtype 
} from '@summize/shared/framework';

import { SelectBoxModel } from '../form-select-box/form-select-box.module';
import { AutocompleteChipGroupModel, AutocompleteGroupModel } from '../autocomplete-chips-groups/autocomplete-chips-groups.component';
import { AutoSendForSignatureService } from './auto-send-for-signature.service';

@Component({
    selector: 'app-auto-send-for-signature',
    templateUrl: 'auto-send-for-signature.html',
    styleUrls: ['./auto-send-for-signature.scss']
})
export class AutoSendForSignatureComponent extends BaseTenantService implements OnInit {

    @Input()
    public model: ESignatureAutoSendModel;

    @Input()
    public requestSubtype: RequestSubtype;

    @Output()
    public modelChanged: EventEmitter<any>;

    public isLoading = false;

    public recipientTypeEnum = ESignatureRecipientLocationType;

    public requestSubtypeEnum = RequestSubtype;

    public counterpartyOptions: Array<SelectBoxModel> = []

    public clientId: string = EMPTY_GUID;

    public matterId: string = EMPTY_GUID;

    public isNotificationActionsEnabled = false;

    public notificationTargetGroups: AutocompleteGroupModel[] = [];

    public notificationTargets: AutocompleteChipGroupModel[] = [];

    public selectedNotificationTargets: AutocompleteChipGroupModel[] = [];

    public refreshSelectedNotificationTargets: Subject<AutocompleteChipGroupModel[]> = new Subject();

    public meta: ESignatureMeta;

    public folders: any = [];

    public integrations: any = [];

    public integrationOptions: Array<SelectBoxModel> = [];

    public contractTypeOptions: Array<SelectBoxModel> = [];

    public signatureTypeOptions: Array<SelectBoxModel> = [];

    public repositoryDocumentStatusIdOptions: Array<SelectBoxModel> = [];

    public ownerOptions: Array<SelectBoxModel> = [];

    public noValidIntegrations = false;

    public enableTooltip = '';

    constructor(private service: AutoSendForSignatureService) {

        super();

        this.modelChanged = new EventEmitter<any>();

    }

    public async ngOnInit() {

        this.isLoading = true;

        if (this.model === undefined || this.model === null) {

            this.model = {
                assignTo: EMPTY_GUID,
                clientId: undefined,
                clientName: undefined,
                matterId: undefined,
                matterName: undefined,
                contractTypeId: undefined,
                contractTypeName: undefined,
                repositoryDocumentStatusId: 4,
                internalRecipients: [],
                counterpartyRecipients: [],
                notifications: [],
                integrationId: undefined,
                integrationType: EsignatureIntegrationType.DocuSignShared,
                signingOrder: EsignatureSigningOrder.CounterpartiesFirst,
                signatureType: undefined,
                requiredCounterparties: undefined,
                isEnabled: false,
                isValid: false,
                autoSendVersion: ESignatureAutoSendVersion.V1
            }

            this.model.internalRecipients.push({
                name: '',
                email: '',
                tokenId: '',
                signOrder: 2,
                recipientType: ESignatureRecipientType.To,
                recipientOrigin: ESignatureRecipientOrigin.Internal
            });
        }

        await this.loadIntegrations();

        if (this.model.isEnabled === true) {

            await this.load();
        }

        this.isLoading = false;

    }

    public async load() {

        this.isLoading = true;

        this.isNotificationActionsEnabled = this.hasFeatureFlag('NotificationActions');

        this.meta = await this.service.getSignatureMeta();

        this.meta.folders.forEach(folder => {

            const group = {
                key: folder.name,
                value: folder.children.map(x => {
                    return {
                        key: x.id.toLowerCase(),
                        value: x.name
                    }
                })

            };

            this.folders.push(group);
        });

        this.contractTypeOptions = this.meta.contractTypes.map(x => {
            return {
                key: x.id,
                value: x.name
            }
        });

        this.repositoryDocumentStatusIdOptions = this.meta.availableStatuses.map(x => {
            return {
                key: x.id,
                value: x.statusName
            }
        });

        await this.getUsersForClientMatter();

        if (this.isNotificationActionsEnabled === true) {

            await this.loadNotifications();
        }

        this.counterpartyOptions = [{
            key: '1',
            value: 'Only one allowed'
        },
        {
            key: '999',
            value: 'Multiple are allowed'
        }];

        this.isLoading = false;

    }

    public async loadIntegrations() {

        this.integrations = await this.service.getSignatureIntegrations();

        if (this.integrations === undefined || this.integrations.filter(x => x.type === OutboundIntegrationTypes.DocusignShared).length === 0) {

            this.noValidIntegrations = true;

            this.enableTooltip = 'You must have at least x1 DocuSign account configured to enable this feature';

            return;
        }

        this.integrationOptions = this.integrations
            .filter(x => x.type === EsignatureIntegrationType.DocuSignShared)
            .map(x => {

                return {
                    key: x.id,
                    value: x.name
                }

            });

        if (this.integrationOptions.length === 1 || this.integrations.filter(x => x.id === this.model.integrationId).length === 0) {

            this.model.integrationId = this.integrationOptions[0].key;

        }

        if ((this.model.integrationId !== EMPTY_GUID || this.model.integrationId !== undefined)) {

            await this.getSignatureTypesForIntegration();

        }
    }

    public async updateEnabled(checked: boolean) {

        this.model.isEnabled = checked;

        if (this.model.isEnabled === true) {

            await this.load();
        }

        this.validate();

    }

    public async onIntegrationChanged(option: string) {

        this.model.integrationId = option;

        this.signatureTypeOptions = [];

        await this.getSignatureTypesForIntegration();

        this.validate();

    }

    public async onSignatureTypeChanged(option: string) {

        this.model.signatureType = option;

        this.validate();

    }

    public async onFolderChanged(option: string) {

        this.matterId = option;

        const parentClient = this.meta.folders.find(x => x.children.find(y => y.id === this.matterId.toUpperCase()) !== undefined);

        this.clientId = parentClient !== undefined ? parentClient.id : EMPTY_GUID;

        this.model.clientId = parentClient?.id ?? EMPTY_GUID;
        this.model.clientName = parentClient?.name ?? '';

        this.model.matterId = this.matterId;
        this.model.matterName = parentClient.children.find(x => x.id === this.matterId.toUpperCase()).name ?? '';

        await this.getUsersForClientMatter();

        this.validate();
    }

    public async onContractTypeChanged(option: string) {

        this.model.contractTypeId = option;

        this.model.contractTypeName = this.contractTypeOptions.find(x => x.key === option).value;

        this.validate();
    }

    public async onRepositoryStatusChanged(option: string) {

        this.model.repositoryDocumentStatusId = parseInt(option);

        this.validate();

    }

    public async onOwnerChanged(option: string) {

        this.model.assignTo = option;

        this.validate();

    }

    public async onCounterpartyChanged(option: string) {

        this.model.requiredCounterparties = parseInt(option);

        this.validate();

    }


    public onRecipientsChanged(event: any) {

        this.model.internalRecipients = event;

        this.validate();

    }

    private async getSignatureTypesForIntegration() {

        const signatureTypesResponse = await this.service.getSignatureIntegrationSignatureTypes(this.model.integrationId);

        this.signatureTypeOptions = signatureTypesResponse.signatureProviders
            .map(x => {

                return {
                    key: x.signatureProviderName,
                    value: x.signatureProviderDisplayName
                }

            });

        if (signatureTypesResponse.signatureProviders.length === 1) {

            this.model.signatureType = signatureTypesResponse.signatureProviders[0].signatureProviderName;

        }
    }

    private async loadNotifications() {

        const [users, groups] = await this.service.getAvailableAssignees(this.clientId, this.matterId);

        const notificationTargets: OutboundIntegration[] = await this.service.getNotificationTargets();

        this.notificationTargetGroups = [];
        this.notificationTargets = [];

        // Add requestor as option
        this.notificationTargetGroups = [{ "key": "default", "value": "Default" }, ...this.notificationTargetGroups]

        this.notificationTargets.push({
            key: RequestorPlaceholderGuid,
            value: `Requestor`,

            groupKey: 'default'
        });

        if (users?.users?.length > 0) {

            this.notificationTargetGroups.push({ "key": "user", "value": "User" });

            this.notificationTargets.push(...users.users.map(x => ({
                key: x.userId,
                value: `${x.firstName} ${x.lastName} (${x.email})`,
                groupKey: 'user'
            })));

        }

        if (groups?.groups?.length > 0) {

            this.notificationTargetGroups.push({ "key": "group", "value": "Group" });

            this.notificationTargets.push(...groups.groups.map(x => ({
                key: x.groupId,
                value: x.groupName,
                groupKey: 'group'
            })));

        }

        const webhooks = notificationTargets.filter(x => x.outboundIntegrationType === OutboundIntegrationTypes.Webhook);

        if (webhooks.length > 0) {

            this.notificationTargetGroups.push({ "key": "webhook", "value": "Webhook" });

            this.notificationTargets.push(...webhooks.map(x => ({
                key: x.id,
                value: x.displayName,
                groupKey: 'webhook'
            })));

        }

        const teams = notificationTargets.filter(x => x.outboundIntegrationType === OutboundIntegrationTypes.Teams);

        if (teams.length > 0) {

            this.notificationTargetGroups.push({ "key": "teams", "value": "Teams" });

            this.notificationTargets.push(...teams.map(x => ({
                key: x.id,
                value: x.displayName,
                groupKey: 'teams'
            })));

        }

        const slack = notificationTargets.filter(x => x.outboundIntegrationType === OutboundIntegrationTypes.Slack);

        if (slack.length > 0) {

            this.notificationTargetGroups.push({ "key": "slack", "value": "Slack" });

            this.notificationTargets.push(...slack.map(x => ({
                key: x.id,
                value: x.displayName,
                groupKey: 'slack'
            })));

        }

        const selectedNotificationTargets =
            this.notificationTargets.filter(x => this.model.notifications.map((y: any) => {

                if (y.notificationEntityId !== undefined && y.notificationEntityId !== EMPTY_GUID) {

                    return y.notificationEntityId;
                }

                return y.groupId;

            }).includes(x.key));

        this.setSelectedNotificationTargets(selectedNotificationTargets);

    }


    public setSelectedNotificationTargets(selectedNotificationTargets: AutocompleteChipGroupModel[]) {

        this.selectedNotificationTargets = selectedNotificationTargets;

        this.refreshSelectedNotificationTargets.next(this.selectedNotificationTargets);

    }

    public updatedNotificationTargets(selectedNotificationTargets: AutocompleteChipGroupModel[]) {

        const existingNotifications = this.model.notifications || [];

        const selectedIds = selectedNotificationTargets.map(x => x.key);

        const existingIds = existingNotifications
            .map(x => x.id);

        const notifications = existingNotifications
            .filter(x => selectedIds.includes(x.id));

        const newNotificationTargets
            = selectedNotificationTargets.filter(x => !existingIds.includes(x.key));

        notifications.push(...newNotificationTargets.map(x => {

            return {
                id: x.key,
                notificationEntityType: x.groupKey === 'user' || x.groupKey === 'default' ? 1 : x.groupKey === 'group' ? 2 : 0,
                notificationEntityId: x.key,
                groupKey: x.groupKey,
                displayName: x.value
            }

        }));

        this.model.notifications = notifications;

        this.validate();

    }

    private async getUsersForClientMatter() {

        const users = await this.service.getUsersForClientMatter(this.clientId, this.matterId);

        this.ownerOptions = users.map(x => {
            return {
                key: x.id,
                value: `${x.firstName} ${x.lastName} - ${x.email}`
            }
        });

        this.ownerOptions.unshift({ key: EMPTY_GUID, value: 'No Owner' });

        // Reset the user dropdown if the user doesn't exist in the client matter
        if (this.model.assignTo !== EMPTY_GUID &&
            users.find(x => x.id === this.model.assignTo) === undefined) {

            this.model.assignTo = undefined;
        }

        await this.loadNotifications();

    }

    private validate() {

        if (this.model.isEnabled === false) {

            this.model.isValid = true;

        } else {

            const modelProps = Object.keys(this.model);

            const validProps = modelProps.every(x => this.model[x] !== undefined);

            let validRecipients = false;

            if (this.model.autoSendVersion === ESignatureAutoSendVersion.V1) {

                validRecipients = this.model.counterpartyRecipients?.every(x => x.email !== '' && !x.emailInvalid && !x.emailExists) === true;
                    
            } else {

                validRecipients = this.model.counterpartyRecipients?.every(x => 
                    (x.name !== '' && !x.nameInvalid)
                    && (x.email !== '' && !x.emailInvalid && !x.emailExists)
                ) === true;

            }
    
            if (validRecipients === true && validProps === true) {
    
                this.model.isValid = true;
            }
            else {
    
                this.model.isValid = false;
    
            }
        }

        this.modelChanged.next(this.model);
    }

}