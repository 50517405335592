import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { WidgetHostModule } from '../widget-host/widget-host.module';
import { ContextBarModule } from '../context-bar/context-bar.module';
import { BlockButtonModule } from '../block-button/block-button.module';
import { EllipsesMenuModule } from '../ellipses-menu/ellipses-menu.module';
import { SelectBoxModule } from '../select-box/select-box.module';
import { IconModule } from '../icon/icon.module';

import { DashboardComponent } from './dashboard.component';
import { DashboardService } from './dashboard.service';
import { WidgetLibraryComponent } from './widget-library/widget-library.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { WidgetLibraryService } from './widget-library/widget-library.service';
import { ChartWidgetModule } from '../chart-widget/chart-widget.module';
import { DialogShellModule } from '../dialog-shell/dialog-shell.module';
import { RenameDashboardModalComponent } from './rename-dashboard-modal/rename-dashboard-modal.component';
import { NewDashboardModalComponent } from './new-dashboard-modal/new-dashboard-modal.component';
import { DashboardIntroModalComponent } from './dashboard-intro-modal/dashboard-intro-modal.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatDialogModule,
        DialogShellModule,
        WidgetHostModule,
        ContextBarModule,
        BlockButtonModule,
        EllipsesMenuModule,
        SelectBoxModule,
        OverlayModule,
        IconModule,
        LoadingSpinnerModule,
        ChartWidgetModule,
        MatCheckboxModule,
        DialogShellModule
    ],
    exports: [
        DashboardComponent,
        WidgetLibraryComponent,
        RenameDashboardModalComponent,
        NewDashboardModalComponent
    ],
    declarations: [
        DashboardComponent,
        WidgetLibraryComponent,
        RenameDashboardModalComponent,
        NewDashboardModalComponent,
        DashboardIntroModalComponent
    ],
    providers: [
        DashboardService,
        WidgetLibraryService
    ]
})
export class DashboardModule { }

export * from './dashboard.component';
export * from './widget-library/widget-library.component';
export * from './rename-dashboard-modal/rename-dashboard-modal.component';
export * from './new-dashboard-modal/new-dashboard-modal.component';