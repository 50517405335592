import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SelectBoxComponent } from './select-box.component';
import { SearchBarModule } from '../search-bar/search-bar.module';

@NgModule({
    imports: [
        CommonModule,
        MatSelectModule,
        MatTooltipModule,
        SearchBarModule,
    ],
    exports: [
        SelectBoxComponent
    ],
    declarations: [
        SelectBoxComponent
    ]
})
export class SelectBoxModule { }

export * from './select-box.component'