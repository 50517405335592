import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { BaseTenantService } from "@summize/shared/core";
import { DashboardDetailResponse, DashboardRequest, DashboardsResponse, WidgetResponse } from '@summize/shared/framework';

@Injectable({ providedIn: 'root' })
export class DashboardService extends BaseTenantService {

    constructor(private http: HttpClient) { super() }

    public async getDashboards(): Promise<DashboardsResponse> {

        const request: any = await this.http
            .get(`${this.getUserBaseUrl(true)}requestanalytics/dashboards`)
            .toPromise();

        return request;
    }

    public async getDashboardDetailById(dashboardId: string): Promise<DashboardDetailResponse> {

        const request: any = await this.http
            .get(`${this.getUserBaseUrl(true)}requestanalytics/dashboards/${dashboardId}`)
            .toPromise();

        return request;
    }

    public async getWidgetById(widgetId): Promise<WidgetResponse> {

        const request: any = await this.http
            .get(`${this.getUserBaseUrl(true)}requestanalytics/widgets/${widgetId}`)
            .toPromise();

        return request;
    }

    public async saveDashboard(dashboard: DashboardRequest): Promise<DashboardDetailResponse> {

        return await this.http
            .post<DashboardDetailResponse>(`${this.getUserBaseUrl(true)}requestanalytics/dashboards`, dashboard)
            .toPromise();

    }

    public async updateDashboard(id: string, dashboard: DashboardRequest): Promise<DashboardDetailResponse> {

        return await this.http
            .put<DashboardDetailResponse>(`${this.getUserBaseUrl(true)}requestanalytics/dashboards/${id}`, dashboard)
            .toPromise();

    }

    public async updateDashboardDefault(id: string): Promise<DashboardDetailResponse> {

        return await this.http
            .put<DashboardDetailResponse>(`${this.getUserBaseUrl(true)}requestanalytics/dashboards/${id}/default`, {})
            .toPromise();

    }

    public async deleteDashboard(dashboardId: string): Promise<any> {

        await this.http
            .delete(`${this.getUserBaseUrl(true)}requestanalytics/dashboards/${dashboardId}`)
            .toPromise();

    }
}

