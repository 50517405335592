import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseTenantService } from '@summize/shared/core';

import { TABLES } from '../analytics.contants';
import { AnalyticTableResult, ContractStatusItem } from '../analytics.types';

@Injectable({ providedIn: 'root' })
export class ReviewPanelService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getAssignedToUsers(range: string): Promise<AnalyticTableResult> {

        const baseUrl = this.getUserBaseUrl(true);

        const url = `${baseUrl}analytics/table/${TABLES.AssignedToTable}/${range}`;

        const data = await this.http.get(url).toPromise() as any;

        return {
            totalResultsCount: data.totalResultsCount,
            results: data.results.map((r: any) => {
                return {
                    userName: r.userName,
                    total: r.totalContracts,
                    complete: r.completeContracts,
                    completePercentage: (100 * r.completeContracts) / r.totalContracts,
                    remaining: r.pendingContracts,
                    totalLabel: 'Contracts',
                    completeLabel: 'Reviewed',
                    remainingLabel: 'Pending',
                    subtitle: `Average SLA ${Math.round(r.averageSLA) || '< 0'}d`
                }
            })
        };

    }

    public async getAssignedToGroups(range: string): Promise<AnalyticTableResult> {

      const baseUrl = this.getUserBaseUrl(true);

      const url = `${baseUrl}analytics/table/${TABLES.AssignedToGroupTable}/${range}`;

      const data = await this.http.get(url).toPromise() as any;

      return {
          totalResultsCount: data.totalResultsCount,
          results: data.results.map((r: any) => {
              return {
                  userName: r.groupName,
                  total: r.totalContracts,
                  complete: r.completeContracts,
                  completePercentage: (100 * r.completeContracts) / r.totalContracts,
                  remaining: r.pendingContracts,
                  totalLabel: 'Contracts',
                  completeLabel: 'Reviewed',
                  remainingLabel: 'Pending',
                  subtitle: `Average SLA ${Math.round(r.averageSLA) || '< 0'}d`
              }
          })
      };
    }

    public async getRequestedByGroups(range: string): Promise<AnalyticTableResult> {

        const baseUrl = this.getUserBaseUrl(true);

        const url = `${baseUrl}analytics/table/${TABLES.RequestedByGroupTable}/${range}`;

        const data = await this.http.get(url).toPromise() as any;

        return {
            totalResultsCount: data.totalResultsCount,
            results: data.results.map((r: any) => {
                return {
                    userName: r.groupName,
                    total: r.totalContracts,
                    complete: r.completeContracts,
                    completePercentage: (100 * r.completeContracts) / r.totalContracts,
                    remaining: r.pendingContracts,
                    totalLabel: 'Contracts',
                    completeLabel: 'Reviewed',
                    remainingLabel: 'Pending',
                    subtitle: `Average SLA ${Math.round(r.averageSLA) || '< 0'}d`
                }
            })
        };
    }

    public async getContractStatuses(range: string): Promise<AnalyticTableResult> {

        const baseUrl = this.getUserBaseUrl(true);

        const url = `${baseUrl}analytics/table/${TABLES.ContractStatusTable}/${range}`;

        const data = await this.http.get(url).toPromise() as any;

        return data;
    }

}
