import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { Note } from './notes.models';

@Injectable()
export class NotesService {

    public addToggled$ = new Subject<boolean>();
    public add$ = new Subject<string>();
    public update$ = new Subject<Note>();
    public delete$ = new Subject<string>();

    public toggleAdd = (display: boolean) => {

        this.addToggled$.next(display);

    };

    public add = (text: string) => {

        this.add$.next(text);

    };

    public update = (note: Note) => {

        this.update$.next(note);

    };

    public delete = (id: string) => {

        this.delete$.next(id);

    };

}