import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { TableComponent } from './table.component';
import { TableService } from './table.service';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

@NgModule({
    imports: [
        CommonModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatCheckboxModule,
        LoadingSpinnerModule
    ],
    exports: [
        TableComponent
    ],
    declarations: [
        TableComponent
    ],
    providers: [
        TableService
    ]
})
export class TableModule { }

export * from './table.component'