import { Component, Input, OnInit } from '@angular/core';
import { AsyncActivityCountService } from './async-activity-count.service';

@Component({
    selector: 'app-async-activity-count',
    templateUrl: 'async-activity-count.html',
    styleUrls: ['./async-activity-count.scss']
})
export class AsyncAtivityCountComponent implements OnInit {

    @Input()
    public tenantId!: string;

    @Input()
    public tenantIdentifier!: string;

    @Input()
    public user!: string;

    @Input()
    public endpoint!: string;

    public activity!: string;

    constructor(private service: AsyncActivityCountService) { }

    public async ngOnInit(): Promise<void> {

        this.activity = await this.service.get(this.endpoint, this.tenantId, this.user);

    }

}