import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';

import { environment } from '../../environments/environment';
import { AuthServiceBase, LoginValues } from './auth-service.base';
import { TokenService } from './token.service';
import { LocalStorageService } from './local-storage.service';

export function getClientSettings(): UserManagerSettings {

    return {
        authority: environment.auth.oidc.authority,
        client_id: environment.auth.oidc.clientId,
        client_secret: environment.auth.oidc.clientSecret,
        redirect_uri: environment.auth.oidc.signinRedirectUrl,
        post_logout_redirect_uri: environment.auth.oidc.signoutRedirectUrl,
        response_type: 'code',
        scope: environment.auth.oidc.scopes ?? 'openid profile email',
        filterProtocolClaims: true,
        loadUserInfo: true,
        userStore: new WebStorageStateStore({ store: window.localStorage })
    };

}

@Injectable({ providedIn: 'root' })
export class AuthOidcService implements AuthServiceBase {
    private userManager: UserManager;
    private user: User = null;

    private readonly _isAuthenticated = new BehaviorSubject<boolean>(false);

    public isAuthenticated$ = this._isAuthenticated.asObservable();

    constructor(
        private router: Router,
        private tokenService: TokenService,
        private localStorageService: LocalStorageService,
    ) {
    }

    public init() {

        const clientSettings = getClientSettings();

        this.userManager = new UserManager(clientSettings);

        return this.userManager
            .getUser()
            .then(user => {
                this.user = user;
                this.updateToken();
                this.isAuthenticated();
            });

    }

    public isAuthenticated(): boolean {
        const isAuthenticated = this.user != null && !this.user.expired;

        // RichS: Always ensure the token is saved - other areas of the code are
        // reliant on this being there, and without a major refactor, this
        // returning true, but the token not being in the correct place
        // causes an endless loop. It should really be refactored to eliminate
        // this issue - but we can do that post Okta-removal.
        this.updateToken();

        this._isAuthenticated.next(isAuthenticated);

        return isAuthenticated;
    }

    private updateToken() {

        if (this.user?.access_token) {

            this.tokenService.saveToken(this.user.access_token);

        } else {

            this.tokenService.destroyToken();

        }
    }

    public login(values?: LoginValues): Promise<void> {

        if (values && values.scheme) {

            // TODO: Think this needs to deal with the redirectUrl from LoginValues
            return this.userManager.signinRedirect({ extraQueryParams: { idp: `${values.scheme}` } });

        }

        this.router.navigateByUrl('/login-oidc');

        // RichS: Need to validate if we need this here, or not.
        // return this.userManager.signinRedirect(values?.redirectUrl);
        
    }

    public async logout() {

        this.localStorageService.removeItem('smz-oidc-email');

        this.tokenService.destroyToken();

        // TODO: RichS: 2023.06.09 - It has been noticed that 'req' is not used. It is unclear if this 
        // is correct or not. Needs investigating.
        const req = await this.userManager.createSignoutRequest({ id_token_hint: this.user.id_token });

        // Note: Not all Idps support the revocation endpoint (see the openid-configuration response
        // for the 'revocation_endpoint' value). So silently ignore
        try {

            await this.userManager.revokeAccessToken();

        } catch {

            console.log('Issue with revocation_endpoint');

        }

        await this.userManager.signoutRedirect();

    }

    public async logoutCallback() {

        await this.userManager.signoutRedirectCallback('');

        const isSummizeAuthentication = this.getIsSummizeAuthentication();

        if (isSummizeAuthentication === true) {

            window.location.href = `${environment.oktaAuthUrl}/login/signout?fromURI=${environment.auth.oidc.signoutRedirectUrl}`;

        } else {

            window.location.href = `${environment.publicSiteUrl}/logout`;

        }

        this._isAuthenticated.next(false);

    }

    public completeAuthentication(): Promise<void> {

        return this.userManager
            .signinRedirectCallback()
            .then(user => {
                this.user = user;
                this.updateToken();
                this.isAuthenticated();
            });

    }

    private getIsSummizeAuthentication() {

        return this.localStorageService.getItem('isSummizeAuthentication') === 'true';

    }
}
