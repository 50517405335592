import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ClipboardService {

    public copyToClipboard(textToCopy: string) {

        const dummy = document.createElement("textarea");

        document.body.appendChild(dummy);

        dummy.value = textToCopy;

        dummy.select();

        document.execCommand("copy");

        document.body.removeChild(dummy);

    }

}