<div class="container">
    <textarea #noteTextarea placeholder="Enter your note" required (input)="input()" [(ngModel)]="text"></textarea>
    <div class="action-buttons">
        <button class="action-btn" (click)="cancel()">
            <span>Cancel</span>
            <app-icon icon="close-sharp"></app-icon>
        </button>
        <button class="action-btn" (click)="save()" [disabled]="saveDisabled || !isValid">
            <span>Save</span>
            <app-icon icon="send-sharp"></app-icon>
        </button>
    </div>
</div>