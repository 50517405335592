import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SelectModel } from '@summize/shared/components-v2';

@Component({
    selector: 'app-clause-assign-bullet',
    templateUrl: 'clause-assign-bullet.html',
    styleUrls: ['./clause-assign-bullet.scss']
})
export class ClauseAssignBulletComponent {

    public clauses: Array<SelectModel> = [];

    public selectedValue: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ClauseAssignBulletComponent>
    ) {
        this.clauses = data.clauses;
    }

    public close() {

        this.dialogRef.close(undefined);

    }

    public save() {

        const clause = this.clauses.find(x => x.key === this.selectedValue);

        const result = {
            selectedClause: clause
        };

        this.dialogRef.close(result);

    }

    public onClauseSelected(event: any) {

        this.selectedValue = event;

    }
}