import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KpiComponent } from './kpi.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        KpiComponent
    ],
    declarations: [
        KpiComponent
    ]
})
export class KpiModule { }

export * from './kpi.component'