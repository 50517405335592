import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-directional-arrow-button',
    templateUrl: 'directional-arrow-button.html',
    styleUrls: ['./directional-arrow-button.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DirectionalArrowButtonComponent {

    @Input('up')
    public up: boolean;

    @Input('down')
    public down: boolean;

    @Input('left')
    public left: boolean;

    @Input('right')
    public right: boolean;

    @Input('dark')
    public dark: boolean;

    @Input('square')
    public square: boolean;

    @Input('disabled')
    public disabled: boolean;

    @Input('small')
    public small: boolean;

    @Input('borderless')
    public borderless: boolean;

    @Output()
    public click: EventEmitter<MouseEvent> = new EventEmitter();

    public ngOnInit(): void {

        this.up = this.up !== undefined && this.up !== false;

        this.down = this.down !== undefined && this.down !== false;

        this.left = this.left !== undefined && this.left !== false;

        this.right = this.right !== undefined && this.right !== false;

        this.square = this.square !== undefined && this.square !== false;

        this.dark = this.dark !== undefined && this.dark !== false;

        this.disabled = this.disabled !== undefined && this.disabled !== false;

        this.borderless = this.borderless !== undefined && this.borderless !== false;

        this.small = this.small !== undefined && this.small !== false;

        if (!this.up && !this.right && !this.left && !this.down) {

            throw new Error('Either up, down, left, or right must be selected in directional arrow button');

        }
    }

    public onIconClick(event: MouseEvent): void {

        event.stopPropagation();

        if (this.disabled) {

            return;
        }

        this.click.emit(event);

    }
}