import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { MatSelect } from '@angular/material/select';

import { CloneObject } from '@summize/shared/core';

import { SearchBarComponent } from '../search-bar/search-bar.component';

interface SelectCommandModel {
    action: Function,
    value: string
}

export interface SelectModel {
    key: string,
    value: string,
    secondaryValue?: string,
    commands?: SelectCommandModel[],
    forceRebuild?: boolean,
    disabled?: boolean
}

export interface SelectActionModel {
    key: string, value: string, icon?: string, action: Function
}

@Component({
    selector: 'app-select-box',
    templateUrl: 'select-box.html',
    styleUrls: ['./select-box.scss']
})
export class SelectBoxComponent {

    @ViewChild(MatSelect)
    public select: MatSelect;

    @ViewChild(SearchBarComponent)
    public searchBarComponent: SearchBarComponent;

    @Input()
    public set options(value: Array<SelectModel>) {

        this._options = value;

        if (this._options !== undefined && this._options.length > 0 && this._options[0].forceRebuild === true) {

            this.initialOptions = CloneObject(this.options || []);

            return;
        }

        if (this._options !== undefined && this.initialOptions === undefined) {

            this.initialOptions = CloneObject(this.options || []);

        }

    }

    public get options(): Array<SelectModel> {

        return this._options;

    }

    @Input()
    public value: string;

    @Input()
    public tooltips: boolean = false;

    @Input()
    public placeholder: string = 'Select';

    @Input()
    public grouped: boolean = false;

    @Input()
    public actions: Array<SelectModel> = [];

    @Input()
    public isRequired: boolean = false;

    @Input()
    public disabled: boolean = false;

    @Input()
    public errorState: boolean = false;

    @Input()
    public multiple: boolean = false;

    @Input()
    public canSearch: boolean = true;

    @Input()
    public multipleSelect: boolean = false;

    @Input()
    public asyncSearchHandler: any = undefined;

    @Input()
    public asyncSearchContext: any = undefined;

    @Output()
    public changed: EventEmitter<string>;

    public initialOptions: Array<SelectModel>;

    private _options: Array<SelectModel>;

    private searchDebounceTimeout: any;

    constructor() {

        this.changed = new EventEmitter();

    }

    public openedChange(event) {

        // Clear the search value, otherwise it messes up if you leave a non-found search term (the select dropdown won't re-open)
        this.searchBarComponent?.clearSearch();

    }

    public valueChanged(value: any): void {

        this.changed.emit(value);

    }

    public async onKey(value) {

        this.options = await new Promise(resolve => {

            if (this.searchDebounceTimeout !== undefined) {

                clearTimeout(this.searchDebounceTimeout);

                this.searchDebounceTimeout = undefined;

            }

            this.searchDebounceTimeout = setTimeout(async () => {

                resolve(await this.search(value));

            }, this.asyncSearchHandler !== undefined ? 300 : 0);

        });
    }

    public async search(value: string) {

        const filter = value.toLowerCase();

        if (filter === undefined || filter === '') {

            return this.initialOptions;

        }

        if (this.asyncSearchHandler !== undefined) {

            return await this.asyncSearchHandler.call(this.asyncSearchContext, filter);

        }

        if (this.grouped === false) {

            return this.initialOptions
                .filter(option => option.value.toLowerCase()
                    .includes(filter));

        } else {

            const results: any = {};

            for (const group of Object.keys(this.initialOptions)) {

                const keyMatch = group.toLowerCase().includes(filter);

                if (keyMatch === true) {

                    results[group] = this.initialOptions[group];

                } else {

                    const groupMatches = this.initialOptions[group]
                        .filter(option => option.value.toLowerCase()
                            .includes(filter));

                    if (groupMatches !== undefined && groupMatches.length > 0) {

                        results[group] = groupMatches;

                    }

                }

            }

            return results;

        }

    }

    public clearSearch() {

        this.options = this.initialOptions;

    }

}