import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconModule } from '@summize/shared/components-v2';

import { FeedbackPanelComponent } from './feedback-panel.component';
import { SelectBoxModule } from '../select-box/select-box.module';
import { StyledButtonModule } from '../styled-button/styled-button.module';
import { FeedbackService } from './feedback.service';

@NgModule({
    imports: [
        CommonModule,
        SelectBoxModule,
        StyledButtonModule,
        IconModule
    ],
    exports: [
        FeedbackPanelComponent
    ],
    declarations: [
        FeedbackPanelComponent
    ],
    providers: [
        FeedbackService
    ]
})
export class FeedbackPanelModule { }

export * from './feedback-panel.component'