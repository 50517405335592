import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BaseTenantService } from './base-tenant.service';
import * as FileSaver from 'file-saver';

@Injectable({ providedIn: 'root' })
export class FileDownloadService extends BaseTenantService {

    constructor(private http: HttpClient) { 
        super(); 
    }

    public async downloadFile(request: string) {

        const baseUrl = this.getUserBaseUrl(true);

        return await this.http.get(`${baseUrl}filedownload?request=${request}`, { responseType: 'arraybuffer', observe: 'response' }).toPromise()
            .then(response => {
                const fileName = this.getFileName(response.headers);
                this.download(response.body, fileName)
            })
            .catch(error => console.log('Something went wrong when trying to download the export to excel file.', error));
    }

    private download(data: ArrayBuffer, documentName: string) {
        const blob = new Blob([data], { type: '' });
        FileSaver.saveAs(blob, documentName);
    }

    private getFileName(headers: HttpHeaders): string {
        const disposition = headers.get('Content-Disposition');
        if (!disposition) {
            return 'test.doc';
        }
        const headerInfo = disposition.split(';');
        const keyValue = headerInfo.find(x => x.startsWith(' filename='));
        return keyValue.replace('filename=', '').replace(/"/g, '');
    }
}