<app-block-button (mouseenter)="overlayOpen = true" (mouseleave)="overlayOpen = false" (click)="onEdit.next(filter)"
    (onIconClick)="onRemove.next(filter)" icon="close-outline" display="{{filter.name}} ({{filter.valueCount}})"
    type="filter-selected small auto-width" [iconPosition]="'right'" [width]="filter.width" [hasIconClickEvent]="true"
    cdkOverlayOrigin #trigger="cdkOverlayOrigin">
</app-block-button>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="overlayOpen">
    <div class="selected-filter-items">
        {{filterText}}
    </div>
</ng-template>