import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-search-bar',
    templateUrl: 'search-bar.html',
    styleUrls: ['./search-bar.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBarComponent {

    @Input()
    public query: string;

    @Input()
    public placeholder: string = 'Search ...';

    // If this is used in something like a Mat-Select, they use cursor+space to select the item. 
    // Which conflicts with typing text. So allow stopPropagation so that the select box doesn't 
    // see it if the input has focus.
    @Input()
    public stopPropagation: boolean = false;

    @Output()
    public onSearch: EventEmitter<string>;

    constructor() {

        this.onSearch = new EventEmitter<string>();

    }

    public search(event: { target: { value: string } }): void {

        this.onSearch.emit(event.target.value);

    }

    public clearQuery() {

        this.query = undefined;

        this.onSearch.emit('');

    }

    public keydown(event) {

        if (this.stopPropagation === true) {

            event.stopPropagation();

        }

    }

}