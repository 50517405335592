import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ChangeCustomFieldDialogComponent } from './change-customfield-dialog.component';
import { DialogShellModule } from '../dialog-shell/dialog-shell.module';
import { SelectBoxModule } from '../select-box/select-box.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

@NgModule({
    imports: [
        CommonModule,
        DialogShellModule,
        SelectBoxModule,
        FormsModule,
        LoadingSpinnerModule
    ],
    exports: [
        ChangeCustomFieldDialogComponent
    ],
    declarations: [
        ChangeCustomFieldDialogComponent
    ]
})
export class ChangeCustomFieldDialogModule { }

export * from './change-customfield-dialog.component'