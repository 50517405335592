import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconModule } from '@summize/shared/components-v2';
import { MatTooltipModule } from '@angular/material/tooltip';

import { MetricComponent } from './metric.component';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        MatTooltipModule
    ],
    exports: [
        MetricComponent
    ],
    declarations: [
        MetricComponent
    ]
})
export class MetricModule { }

export * from './metric.component'