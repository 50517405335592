import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';

import { AutocompleteBoxCallbackComponent } from './autocomplete-box-callback.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
    imports: [
        CommonModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatSelectModule
    ],
    exports: [
        AutocompleteBoxCallbackComponent
    ],
    declarations: [
        AutocompleteBoxCallbackComponent
    ]
})
export class AutocompleteBoxCallbackModule { }

export * from './autocomplete-box-callback.component'