import { Directive, HostListener, OnDestroy } from '@angular/core';

import { ConnectorIntegrationTypes } from '@summize/shared/framework';
import { Router } from '@angular/router';

import { AccessHelper } from './access.helper';
import { EMPTY_GUID, FeatureFlags, TenantModeEnum, UserTypeEnum } from './constants';
import { BaseTenantService, User } from './services/base-tenant.service';
import { SubscriptionManager } from './utils/sub-manager';
import { SummizeClaims } from 'libs/shared/framework/src/claims';

@Directive()
export class AppComponent extends BaseTenantService implements OnDestroy {

    @HostListener('window:beforeunload', ['$event'])
    public unloadNotification($event: any): void {

        if (this.pendingChanges) {

            // Display alert if there are unsaved changes
            $event.returnValue = true;
        }
    }

    public pendingChanges = false;

    public isComponentLoading: boolean;

    public user: User;

    public isFullUser = false;

    public isRequestUser = false;

    public EMPTY_GUID = EMPTY_GUID;

    public FeatureFlags = FeatureFlags;

    public Claims = SummizeClaims;

    public get isELFD() {

        const isolated = (<any>window).summize?.isIsolated === true;

        return isolated !== undefined && isolated === true;

    }

    public get username() {

        return `${this.user?.firstName} ${this.user?.lastName}`;

    }

    public get hasConnectors() {

        return this.user.connectors !== undefined
            && this.user.connectors.length > 0;

    }

    public get hasSalesforceConnector() {

        return this.hasConnectors && this.user.connectors.some(x => x === ConnectorIntegrationTypes.Salesforce);

    }

    public get hasHubspotConnector() {

        return this.hasConnectors && this.user.connectors.some(x => x === ConnectorIntegrationTypes.HubSpot);

    }

    public get isAdminUser() {

        return this.user.userType === UserTypeEnum.All;

    }

    private subManager: SubscriptionManager = new SubscriptionManager();

    constructor() {

        super();

        this.user = this.getUser();

        if (this.user !== undefined && this.user !== null) {

            this.isFullUser = AccessHelper.IsFullUser(this.getClaims());

            this.isRequestUser = this.user?.userType === UserTypeEnum.Requestor;

        }

    }

    public subscribe(observable: any, func: any): void {

        this.subManager.subscribe(observable, func);

    }

    public ngOnDestroy(): void {

        this.subManager.destroy();

    }

    public redirectToNoAccess(router: Router): Promise<boolean> {

        return router.navigateByUrl('/problem?display=access');

    }

}
