import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { StageInfoComponent } from './stage-info.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        MatMenuModule,
        MatTooltipModule
    ],
    exports: [
        StageInfoComponent
    ],
    declarations: [
        StageInfoComponent
    ]
})
export class StageInfoModule { }

export * from './stage-info.component'