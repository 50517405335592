import { Pipe, PipeTransform } from '@angular/core';

import { UserTypeEnum } from 'libs/shared/core/src/lib/constants';

@Pipe({
    name: 'userTypeFriendlyName'
})
export class UserTypeFriendlyNamePipe implements PipeTransform {
    transform(userTypeName: string): string {

        if (!userTypeName) {
            return userTypeName;
        }

        if (userTypeName === UserTypeEnum[UserTypeEnum.All]) {
            return 'Admin';
        }

        if (userTypeName === UserTypeEnum[UserTypeEnum.Restricted]) {
            return 'Business';
        }

        return userTypeName;
    }
}