import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlockButtonModule, EllipsesMenuModule, IconModule, LoadingSpinnerModule } from '@summize/shared/components-v2';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ClauseMetricComponent } from './clause-metric.component';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { ClauseMetricService } from './clause-metric.service';
import { ConfigureMetricModule } from '../configure-metric/configure-metric.module';

@NgModule({
    imports: [
        CommonModule,
        BlockButtonModule,
        ProgressBarModule,
        LoadingSpinnerModule,
        EllipsesMenuModule,
        ConfigureMetricModule,
        IconModule,
        MatTooltipModule
    ],
    exports: [
        ClauseMetricComponent
    ],
    declarations: [
        ClauseMetricComponent
    ],
    providers: [
        ClauseMetricService
    ]
})
export class ClauseMetricModule { }

export * from './clause-metric.component'