import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Subject } from 'rxjs';

import { PlatformAttachmentType } from '@summize/shared/framework';
import { BaseTenantService } from '@summize/shared/core';

import { AttachmentTypeEnum } from './file-helper.component'

export interface AttachmentRequest {
    token: string;
    ewsUrl: string;
    attachmentId: string;
}

export interface EmailRequest {
    token: string;
    ewsUrl: string;
    itemId: string;
}

@Injectable({ providedIn: 'root' })
export class FileHelperService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public uploadAttachment(route: AttachmentTypeEnum, file: any): Subject<any> {

        const subject: Subject<any> = new Subject<any>();

        const filesize = ((file.size / 1024) / 1024); // MB

        if (filesize > 25) {

            file.error = 'File is too large to be uploaded. Files larger than 25 MB are not currently supported.';

            subject.error(file.error);

        }

        this.requestUploadTokenNew(file, `${route}/${file.attachmentId}/generateaccesstoken`).then(accessToken => {

            const uploadFileHttpRequest = this.getUploadHttpRequest(accessToken, file);

            uploadFileHttpRequest.subscribe(event => {

                if (event.type === HttpEventType.UploadProgress) {

                    subject.next({
                        progress: Math.round(100 * event.loaded / event.total),
                        complete: false
                    });

                }
                else if (event.type === HttpEventType.Response) {

                    subject.next({
                        progress: 100,
                        complete: true
                    });

                }

            }, err => {

                subject.error(err);

            });
        });

        return subject;

    }

    public deleteAttachment(attachmentId) {

        const url = `${this.getUserBaseUrl(true)}attachments/${attachmentId}`;

        return this.http.delete(url).toPromise();

    }

    public replace(masterId: string, request: any): Promise<any> {

        const url = `${this.getUserBaseUrl(true)}documents/${masterId}/replace`;

        return this.http.post(url, request).toPromise();

    }

    public async GetPlatformAttachment(request: AttachmentRequest, attachment: any): Promise<any> {

        const baseUrl = this.getUserBaseUrl(true);

        const config: any = { responseType: 'arraybuffer' };

        switch (attachment.smzType) {

            case PlatformAttachmentType.Gmail:
                request.attachmentId = attachment.id;
                return this.asPromise(this.http.post(`${baseUrl}attachments/gmail`, { EwsUrl: attachment.url }, config));

            case PlatformAttachmentType.Salesforce:
                return this.asPromise(this.http.get(`${baseUrl}platform/salesforce/${attachment.contentDocumentId}/content`, config));

            case PlatformAttachmentType.Jira:
                return this.asPromise(this.http.get(`${baseUrl}platform/jira/${attachment.issueId}/content/${attachment.id}`, config));

            case PlatformAttachmentType.Hubspot:
                return this.asPromise(this.http.get(`${baseUrl}platform/hubspot/${attachment.objectId}/attachments/${attachment.id}`, config));

            default:
                request.attachmentId = attachment.id;
                return this.asPromise(this.http.post(`${baseUrl}attachments/office/ews`, request, config));
                
        }
    }

    public async GetEmailAsEML(request: EmailRequest): Promise<any> {

        const url = `${this.getUserBaseUrl(true)}attachments/office/ews/email`;

        return await this.http.post(url, request, { responseType: 'text' }).toPromise();

    }

    private async requestUploadTokenNew(uploadFile: any, url: string) {

        const baseUrl = await this.getUserBaseUrl(true);

        const payload = {
            fileName: uploadFile.name,
            fileSizeBytes: uploadFile.size,
            fileType: uploadFile.type,
            requestSubtype: uploadFile.requestSubtype
        };

        return this.http.request('POST', `${baseUrl}${url}`, { body: payload }).toPromise();

    }

    private getUploadHttpRequest(token: any, file: any) {

        const url = `${token.cloudLocation}/${token.fileName}${token.accessToken}`;

        const uploadFileRequest = new HttpRequest('PUT', url, file, {
            headers: new HttpHeaders().set('x-ms-blob-type', 'BlockBlob'),
            reportProgress: true,
        });

        return this.http.request(uploadFileRequest);

    }

}
