import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';

export class ContractResponse {
    isShared: boolean;
    documentId: string;
    documentName: string;
    assignedTo: string;
    assignedToName: string;
    assignedBy: string;
    assignedByName: string;
    assignedAt: string;
}

export class DocumentStatus {
    id: number;
    statusName: string;
    statusStyle: string;
    statusOrder: number;
}

@Injectable({
    providedIn: 'root'
})
export class ContractStatusService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public setDocumentsStatus(documentStatusId: number, documentIds: string[]): Promise<ContractResponse[]> {

        const url = `${this.getUserBaseUrl(true)}documents/status`;
        const payload = { DocumentIds: documentIds, DocumentStatusId: documentStatusId };
        return this.http.put<ContractResponse[]>(url, payload).toPromise();

    }

    public setDocumentStatus(documentStatusId: number, documentId: string): Promise<ContractResponse[]> {

        const url = `${this.getUserBaseUrl(true)}documents/${documentId}`;
        const payload = { DocumentStatusId: documentStatusId };
        return this.http.put<ContractResponse[]>(url, payload).toPromise();

    }

    public getDocumentStatuses(searchValue?: string, statusArea?: string): Promise<DocumentStatus[]> {

        let url = `${this.getUserBaseUrl(true)}documentstatuses?`;

        if (searchValue && searchValue.length > 0) {
            url += `&statusname=${searchValue}`;
        }

        if (statusArea && statusArea.length > 0) {
            url += `&statusArea=${statusArea}`;
        }

        return this.http
            .get<DocumentStatus[]>(url)
            .toPromise()
            .then(response => {
                return response.map(u => {
                    // Convert the interface (POJO) to a instance
                    let documentStatus = new DocumentStatus();
                    Object.keys(u).forEach(k => documentStatus[k] = u[k]);
                    return documentStatus;
                })
            });

    }
}
