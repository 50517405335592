import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTooltipModule } from '@angular/material/tooltip';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { BlockButtonModule, IconModule, SearchBarModule } from '@summize/shared/components-v2';
import { FormsModule } from '@angular/forms';

import { DocumentFilterComponent } from './document-filter.component';
import { SearchPipe } from './search.pipe';

@NgModule({
    imports: [
        CommonModule,
        OverlayModule,
        MatSlideToggleModule,
        FormsModule,
        IconModule,
        BlockButtonModule,
        SearchBarModule,
        MatTooltipModule
    ],
    exports: [
        DocumentFilterComponent
    ],
    declarations: [
        DocumentFilterComponent,
        SearchPipe
    ]
})
export class DocumentFilterModule { }

export * from './document-filter.component'