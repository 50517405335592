import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { AppHeaderComponent } from './app-header.component';
import { AvatarModule } from '../avatar/avatar.module';

@NgModule({
    imports: [
        CommonModule,
        AvatarModule
    ],
    exports: [
        AppHeaderComponent
    ],
    declarations: [
        AppHeaderComponent
    ]
})
export class AppHeaderModule { }

export * from './app-header.component'