import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AvatarModule } from '../avatar/avatar.module';
import { IconModule } from '../icon/icon.module';

import { UserProfileComponent } from './user-profile.component';
import { UserProfileService } from './user-profile.service';

@NgModule({
    imports: [
        CommonModule,
        IconModule,
        AvatarModule,
        MatTooltipModule,
        MatMenuModule
    ],
    exports: [
        UserProfileComponent,
    ],
    declarations: [
        UserProfileComponent
    ],
    providers: [
        UserProfileService
    ]
})
export class UserProfileModule { }

export * from './user-profile.component';