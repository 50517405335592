import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { v4 as uuidv4 } from 'uuid';
import { EMPTY_GUID } from '@summize/shared/core';

import { DropHost } from '../drop-host';
import { FileHelperService } from './file-helper.service';
import { PlatformService } from '../platform/platform.service';

export enum DocumentTypeEnum {
    Master,
    Linked,
    Attachment
}

export enum RequestTypeEnum {
    Review,
    AskLegal,
    Manage,
    Create
}

export enum AttachmentTypeEnum {
    Attachments = 'attachments',
    Hubspot = 'hubspot'
}

@Component({
    selector: 'app-file-helper',
    templateUrl: 'file-helper.html',
    styleUrls: ['./file-helper.scss']
})
export class FileHelperComponent extends DropHost implements OnInit {

    @ViewChild('file')
    public fileSelectedControl: ElementRef;

    @Input()
    public fileType: DocumentTypeEnum;

    @Input()
    public requestType: RequestTypeEnum;

    @Input()
    public attachmentType: AttachmentTypeEnum = AttachmentTypeEnum.Attachments;

    @Input()
    public showMoveButtons = false;

    @Input()
    public document: any;

    @Input()
    public attachments: Array<any>;

    @Input()
    public attachmentContext: any;

    @Input()
    public isAttachmentsPicker: boolean = false;

    @Input()
    public hintText: string;

    @Input()
    public enableComponent = true;

    @Output()
    public onMoveUp: EventEmitter<any>;

    @Output()
    public onMoveDown: EventEmitter<any>;

    @Output()
    public onReplaceDocument: EventEmitter<any>;

    @Output()
    public onFileRemoved: EventEmitter<any>;

    @Output()
    public onDocumentTypeChanged: EventEmitter<any>;

    @Output()
    public onFilesAdded: EventEmitter<any>;

    public files: any = [];

    public documentTypeEnum = DocumentTypeEnum;

    public isLoading = true;

    public clientId: string = EMPTY_GUID;

    public matterId: string = EMPTY_GUID;

    public contractTypeId: string = EMPTY_GUID;

    public linkedDocumentTypes = [
        { key: 'Variation', value: 'Variation' },
        { key: 'Addendum', value: 'Addendum' },
        { key: 'Novation', value: 'Novation' },
        { key: 'SOW', value: 'SOW' },
        { key: 'OrderForm', value: 'OrderForm' }
    ];

    constructor(public platform: PlatformService, private service: FileHelperService) {

        super();

        this.onMoveUp = new EventEmitter<any>();
        this.onMoveDown = new EventEmitter<any>();
        this.onFileRemoved = new EventEmitter<string>();
        this.onReplaceDocument = new EventEmitter<any>();
        this.onDocumentTypeChanged = new EventEmitter<any>();
        this.onFilesAdded = new EventEmitter<any>();

    }

    public async ngOnInit(): Promise<void> {

        this.isLoading = false;

    }

    public async onFileChange(files = undefined) {

        const filesToAdd = files || Array.from(this.fileSelectedControl.nativeElement.files);

        filesToAdd.forEach(async (element: any) => {

            // Don't add the same file twice
            if (this.files.filter(x => x.name === element.name).length > 0) {
                if (this.fileType === this.documentTypeEnum.Linked) {
                    element.linkedFileNameAlreadyAdded = true;
                }
                return;
            }

            if (this.fileType === this.documentTypeEnum.Linked) {

                element.complete = false;
            }

            // Attachments can be uploaded without the parent as they don't relate to
            // anything at this point
            if (this.fileType === this.documentTypeEnum.Attachment) {

                element.attachmentId = uuidv4();

                if (this.requestType) {

                    element.requestSubtype = this.requestType;

                }

                element.complete = false;

                await this.service.uploadAttachment(this.attachmentType, element).subscribe(attachment => {

                    if (attachment.complete === true) {
                        // TODO - WIP on these to add spinners
                        element.complete = true;
                    }

                }, (error) => {

                    element.complete = true;

                });

            }

            this.files.push(element);

        });

        // Clear the file input element down so we can re-add if needed
        // If we don't and the user deletes and tries to re-add the same file
        // The onChange won't fire for the input control
        this.fileSelectedControl.nativeElement.value = null;

        this.onFilesAdded.next(filesToAdd);

    }

    public async addAttachment(attachment): Promise<void> {

        const attchmentContent
            = await this.service.GetPlatformAttachment(this.attachmentContext, attachment);

        const byteArray = new Uint8Array(attchmentContent); // Convert array buffer to byte array

        const blob = new Blob([byteArray], { type: attachment.contentType }); // Create a Blob

        const file = new File([blob], attachment.name, { type: attachment.contentType });

        this.onFileChange([file]);

    }

    public async addEmailAsAttachment(): Promise<void> {

        const content = await this.platform.getEmailAsAttachment();

        if (content !== undefined) {

            const blob = new Blob([content], { type: 'message/rfc822' });

            const file = new File([blob], `${this.attachmentContext.subject}.eml`, { type: 'message/rfc822' });

            this.onFileChange([file]);

        }

    }

    protected onDropComplete(dataTransfer) {

        const files = [];

        for (let i = 0; i < dataTransfer.items.length; i++) {

            if (dataTransfer.items[i].kind === 'file') {

                const file = dataTransfer.items[i].getAsFile();

                files.push(file);

            }
        }

        dataTransfer.items.clear();

        this.isDragging = false;

        this.files.concat(files);

    }

    public moveUp(doc: any): void {

        this.onMoveUp.next(doc);

    }

    public moveDown(doc: any): void {

        this.onMoveDown.next(doc);

    }

    public getFileTypeFilter() {

        if (this.fileType === DocumentTypeEnum.Attachment) {

            return '*';

        }

        if (this.service.hasFeatureFlag('AllowWopiTest')) {

            // Note: If this is changed, it needs to be updated in the Backend too.
            return '.DOCX,.PDF,.WOPITEST,.WOPITESTX,.XLSX,.PPTX';

        }

        return '.DOCX,.PDF';

    }

    public async onRemoveFile(file: any): Promise<void> {

        const idx = this.files.indexOf(file);

        if (idx > -1) {

            this.files.splice(idx, 1);
        }

        if (file.complete) {
            switch (this.fileType) {
                case this.documentTypeEnum.Master:
                    this.onFileRemoved.next(undefined);
                    break;
                case this.documentTypeEnum.Linked:
                    this.onFileRemoved.next(file.name);
                    break;
                case this.documentTypeEnum.Attachment:
                    this.onFileRemoved.next(file.attachmentId);
                    break;
                default:
                    break;
            }
        }

        if (!file.complete && this.fileType === this.documentTypeEnum.Linked) {
            this.onFileRemoved.next(file.name);
        }
    }

    public documentTypeChanged(value: string, document): void {

        document.documentType = value;

        const pendingUploads = this.files.filter(x => x.complete === false && x.name === document.name);

        if (pendingUploads.length > 0) {

            for (let x = 0; x < pendingUploads.length; x++) {

                const pendingFile = pendingUploads[x];

                pendingFile.documentType = value;

                this.onDocumentTypeChanged.next(pendingFile);

                pendingFile.complete = true;
            }

        } else {

            this.onDocumentTypeChanged.next(document);
        }

    }

    public replaceDocumentFile(linked): void {

        this.onReplaceDocument.next(linked);

    }

}
