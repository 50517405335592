<div *ngIf="isLoading === false" class="send-for-signature flex-column">

    <div class="sub-header">
        Configure the settings below to control who to send documents to and which documents to send.
    </div>

    <div class="section">
        <div class="type">
            <div class="field ">
                E-Signature method
            </div>
            <div class="control locked-container">
                <input class="smz-standard-input v2 disabled"
                    [(ngModel)]="eSignatureAutoSendDetail.integrationDisplayName" type="text" [disabled]="true">
                <app-icon [icon]="'lock-closed-outline'"></app-icon>
            </div>
        </div>
    </div>

    <!-- Internal Recipients -->
    <app-esignature-email-recipients [recipients]="eSignatureAutoSendDetail.internalRecipients"
        [recipientType]="recipientTypeEnum.Internal" [allowMultiple]="true" [showCheckmark]="false"
        (recipientsChanged)="onInternalRecipientsChanged($event)"></app-esignature-email-recipients>

    <!-- Counterparties -->
    <app-esignature-email-recipients [recipients]="eSignatureAutoSendDetail.counterpartyRecipients"
        [recipientType]="recipientTypeEnum.Counterparty" [showCheckmark]="false"
        [allowMultiple]="eSignatureAutoSendDetail.requiredCounterparties > eSignatureAutoSendDetail.counterpartyRecipients.length"
        (recipientsChanged)="onCounterpartiesChanged($event)"></app-esignature-email-recipients>

    <div class="section">
        <div class="type">
            <div class="field required">
                Select which version to send
            </div>
            <div class="control">
                <div *ngIf="versionOptions?.length > 0" class="version-select">
                    <app-select-box (changed)="selectVersion($event)" [options]="versionOptions" [canSearch]="false"
                        [value]="selectedDocumentId" placeholder="Select"></app-select-box>
                </div>

                <div *ngIf="this.model.masterDocumentId !== undefined" class="files">
                    <div class="file">
                        <div class="selector disabled">
                            <mat-checkbox [value]="true" [checked]="true" disabled>
                            </mat-checkbox>
                        </div>
                        <div class="info">
                            <div class="name">
                                {{selectedVersion.document.documentName}}
                            </div>
                            <div class="type">
                                Master
                            </div>
                        </div>
                    </div>
                    <div *ngFor="let linked of this.selectedVersion.document.linkedDocuments" class="file">
                        <div class="selector">
                            <mat-checkbox [value]="linked.checked" (change)="linked.checked = $event.checked"
                                [checked]="linked.checked">
                            </mat-checkbox>
                        </div>
                        <div class="info">
                            <div class="name">
                                {{linked.documentName}}
                            </div>
                            <div class="type">
                                {{linked.linkedDocumentType}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div *ngIf="isLoading" class="saving">
    <div class="spinner">
        <app-loading-spinner visible="true" [type]="'pale'"></app-loading-spinner>
    </div>
</div>