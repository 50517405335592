import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IntroJsComponent } from './intro-js.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        IntroJsComponent
    ],
    declarations: [
        IntroJsComponent
    ]
})
export class IntroJsModule { }

export * from './intro-js.component'