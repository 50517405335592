import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search'
})
export class UserPanelPipe implements PipeTransform {

    public transform(value: Array<any>, query: string) {

        if (query === undefined) {

            return value;

        }

        return value.filter(v => v.title.toLocaleLowerCase().includes(query));

    }
}