<ng-container *ngIf="loading === true">
    <div class="spinner">
        <app-loading-spinner visible="true"></app-loading-spinner>
    </div>
</ng-container>

<ng-container *ngIf="loading === false && selectedPanel === 'LinksPanel'">

    <div class="hub-header">
        <div class="header-text">
            Help center
        </div>
        <app-icon class="close clickable" icon="close-outline" (click)="close()"></app-icon>
    </div>

    <div class="links-content scroll">

        <div class="hub-link-card">

            <div class="card-content flex-row" onclick="window.open('https://customers.summize.com/hub', '_blank');">
    
                <app-icon icon="help-circle-outline" class="question-icon"></app-icon> 
                
                <div class="card-text">
                    Visit the Customer Hub to search for help articles and guides!
                </div>
    
                <app-icon icon="chevron-forward-outline" class="chevron-icon"></app-icon> 
    
            </div>
    
        </div>
    
        <div *ngIf="displayedLinks.length > 0" class="customer-hub-panel">
    
                <div class="pt-2">
                    <label class="body-small heavy">Browse popular articles</label>
                </div>            
    
                <div *ngFor="let link of displayedLinks" class="customer-hub-links flex-row" (click)="openLink(link)">
                    <app-icon icon="enter-outline" class="link-icon"></app-icon> 
                    <div class="link-text">{{ link.displayText }}</div>
                </div>
    
                <div class="pt-4">
                    <label class="body-small heavy">Contact support</label>
                </div>
    
        </div>
    
        <div class="ticket-card">
    
            <div class="ticket-content flex-row" (click)="setTicketType('BugPanel')">
            
                <app-icon icon="warning-outline" class="warning-icon"></app-icon> 
                
                <div class="card-text">
                    Report an issue or bug
                </div>
        
                <app-icon icon="chevron-forward-outline" class="ticket-chevron-icon"></app-icon> 
        
            </div>
        
            <div class="ticket-content flex-row mt-3" (click)="setTicketType('SuggestionPanel')">
        
                <app-icon icon="megaphone-outline" class="megaphone-icon"></app-icon> 
                
                <div class="card-text">
                    Suggest an improvement
                </div>
        
                <app-icon icon="chevron-forward-outline" class="ticket-chevron-icon"></app-icon> 
        
            </div>
    
        </div>

    </div>

</ng-container>

<ng-container *ngIf="loading === false && (selectedPanel === 'SuggestionPanel' || selectedPanel === 'BugPanel' || selectedPanel === 'BugComplete' || selectedPanel === 'SuggestionComplete')">

    <div class="hub-header row">
        <app-icon icon="arrow-back-outline" class="header-arrow-icon" (click)="goBack()"></app-icon> 
        <div *ngIf="selectedPanel === 'SuggestionPanel'" class="header-text">
            Suggest an improvement
        </div>
        <div *ngIf="selectedPanel === 'BugPanel'" class="header-text">
            Report an issue or bug
        </div>
        <div *ngIf="selectedPanel === 'BugComplete' || selectedPanel === 'SuggestionComplete'" class="header-text">
            Submitted, thank you!
        </div>
        <app-icon class="close clickable" icon="close-outline" (click)="close()"></app-icon>
    </div>

    <div class="customer-hub-panel">

        <div  *ngIf="selectedPanel === 'BugPanel' || selectedPanel === 'SuggestionPanel'" class="form-content scroll">

            <ng-container *ngIf="selectedPanel === 'BugPanel'">

                <div class="description-text">
                    We’re sorry you’re experiencing issues. Please tell us what’s wrong in as much detail as possible to help us resolve the issue.
                </div>
    
                <div class="pt-2 pb-1 pl-1">
                    <label class="body-small heavy required">Please describe the issue or bug is as much detail as possible including the steps you took before the issue occurred and when it first occurred</label>
                </div>
        
                <mat-form-field appearance="fill">
                    <textarea matInput [(ngModel)]="ticketModel.request.descriptionText" class="description-input"></textarea>
                </mat-form-field>
        
                <div class="pt-4 pb-1 pl-1">
                    <label class="body-small heavy">If applicable, paste the URL of the page where the issue is occurring</label>
                </div>
        
                <mat-form-field appearance="fill">
                    <input matInput [(ngModel)]="ticketModel.request.pageUrl" class="url-input">
                </mat-form-field>
      
                <div class="pt-4 pb-1 pl-1">
                    <label class="body-small heavy">Please indicate who is experiencing the issue</label>
                </div>
        
                <app-select-box (changed)="setOptions($event)" [options]="options" [canSearch]="false" placeholder="Select...">
                </app-select-box>
    
                <div class="pt-4 pb-1 pl-1">
                    <label class="body-small heavy">Attach any relevant files such as videos, screenshots or contracts that might help us diagnose your issue</label>
                </div>
    
            </ng-container>
    
            <ng-container *ngIf="selectedPanel === 'SuggestionPanel'">
    
                <div class="description-text">
                    We love hearing ideas and suggestions for how we can improve Summize. Submit your idea or suggestion to our product team to consider as part of our roadmap.
                </div>
    
                <div class="pt-2 pb-1 pl-1">
                    <label class="body-small heavy required">Tell us what you’d like to improve</label>
                </div>
        
                <mat-form-field appearance="fill">
                    <textarea matInput [(ngModel)]="ticketModel.request.descriptionText" class="description-input"></textarea>
                </mat-form-field>
        
                <div class="pt-4 pb-1 pl-1">
                    <label class="body-small heavy required">Tell us why this is important to you</label>
                </div>
        
                <mat-form-field appearance="fill">
                    <textarea matInput [(ngModel)]="ticketModel.request.detailText" class="description-input"></textarea>
                </mat-form-field>
        
                <div class="pt-4 pb-1 pl-1">
                    <label class="body-small heavy">Attach any relevant files such as screenshots or videos that might could give us more context</label>
                </div>
    
            </ng-container>

            <app-file-helper [fileType]="fileTypeEnum.Attachment" [attachmentType]="attachmentTypeEnum.Hubspot"
                [hintText]="attachmentsHintText" (onFilesAdded)="onFilesAdded($event)"
                (onFileRemoved)="onFileRemoved($event)">
            </app-file-helper>

            <div class="row commands">

                <app-block-button (click)="saveTicket()" display="Submit" width="100" type="block" [disabled]="isTicketValid()"></app-block-button>
                <app-block-button (click)="close()" display="Cancel" width="100" type="ghost"></app-block-button>
            
            </div>

        </div>

        <ng-container *ngIf="selectedPanel === 'BugComplete' || selectedPanel === 'SuggestionComplete'">

            <div class="success-container">

                <div class="circle">
                    <app-icon icon="checkmark-circle-outline" class="success-icon"></app-icon> 
                </div>

                <div class="success-text">

                    <ng-container *ngIf="selectedPanel === 'BugComplete'">

                        <div class="pt-4 pb-1 pl-1 ">
                            <label class="body-regular heavy">Your issue has been raised with Support</label>
                        </div>
        
                        <div class="description-text pb-4">
                            Thank you for bringing this to our attention. The information provided has been sent to our Support team for assessment.
                        </div>

                    </ng-container>

                    <ng-container *ngIf="selectedPanel === 'SuggestionComplete'">

                        <div class="pt-4 pb-1 pl-1 ">
                            <label class="body-regular heavy">Your suggestion has been submitted</label>
                        </div>
        
                        <div class="description-text pb-4">
                            Thank you for submitting your suggestion, the information you provided has been sent to our Product team. Although we can’t guarantee implementation of every suggestion, customer feedback is an important part of how we continue to improve Summize and we value all ideas.
                        </div>
    
                    </ng-container>

                    <app-block-button (click)="close()" display="Finish" width="100" type="block"></app-block-button>

                </div>

              </div>

        </ng-container>

    </div>

</ng-container>