<div class="question-sets-dialog">
    <div class="header">
        <div class="title">
            Move Contract to Review
        </div>
        <div class="sub-title">
            Question sets
        </div>

        <app-icon (click)="close()" class="clickable" icon="close-outline"></app-icon>
    </div>

    <div *ngIf="!questionSets" class="spinner">
        <app-loading-spinner visible="true"></app-loading-spinner>
    </div>

    <div *ngIf="questionSets" class="body">

        <ng-container *ngIf="questionSets.length === 0">

            <div class="sub-title no-question-sets">
                There are currently no question sets available. Question sets can be added under <strong>Review</strong>
                > <strong>Settings</strong> if required.
            </div>

        </ng-container>

        <ng-container *ngIf="questionSets.length > 0">

            <div class="title">
                Supporting Information
            </div>
            <div class="sub-title">
                Please provide the following information or skip and <strong>Move to review</strong> if not required.
            </div>

            <app-select-box *ngIf="questionSets.length > 1" (changed)="changeQuestionSet($event)" [options]="questionSetOptions" [value]="questionSet?.id"
                placeholder="Please select a template"></app-select-box>

            <div *ngIf="questionSet" class="linked-area">
                <div class="linked-list">
                    <div *ngFor="let q of questionSet.questions;" class="linked-item">
                        <ng-container *ngIf="isQuestionBuilderType(q.questionType) === false">
                            <div class="field" [ngClass]="{'required': isFullUser === false}">{{q.description}}</div>
                            <div class="control">
                                <input [(ngModel)]="q.answer" type="text" class="smz-standard-input v2" />
                            </div>
                        </ng-container>
                        <ng-container *ngIf="isQuestionBuilderType(q.questionType)">
                            <div class="field" [ngClass]="{'required': isFullUser === true}">{{q.description}}</div>
                            <div *ngIf="q.questionType === questionDefinitionType.FreeText" class="control">
                                <input [(ngModel)]="q.answer" type="text" class="smz-standard-input v2" />
                            </div>
                            <div *ngIf="q.questionType === questionDefinitionType.Numeric" class="control">
                                <input [(ngModel)]="q.answer" type="number" class="smz-standard-input v2" />
                            </div>
                            <div *ngIf="q.questionType === questionDefinitionType.Datepicker" class="control">
                                <input [(ngModel)]="q.answer" type="date" class="smz-standard-input v2" />
                            </div>
                            <div *ngIf="q.questionType === questionDefinitionType.Dropdown" class="control">
                                <app-select-box (changed)="q.answer = $event" [value]="q.answer" [options]="q.meta.values"></app-select-box>
                            </div>
                            <div *ngIf="q.questionType === questionDefinitionType.MultiSelect" class="control">
                                <app-select-box (changed)="q.answer = $event" [value]="q.answer" [options]="q.meta.values" [multipleSelect]="true">
                                </app-select-box>
                            </div>
                            <div *ngIf="q.meta?.longDescription" class="hint-container">
                                <p class="text">{{q.meta?.longDescription}}.</p>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

        </ng-container>

    </div>

    <div *ngIf="questionSets" class="commands">

        <app-block-button (click)="fire()" display="Move to review" width="200"></app-block-button>

    </div>

</div>