import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CalendarEventNotificationTargetsComponent } from './calendar-event-notification-targets.component';
import { CalendarEventNotificationTargetsService } from './calendar-event-notification-targets.service';
import { AutocompleteChipsGroupsModule } from '../autocomplete-chips-groups/autocomplete-chips-groups.module';

@NgModule({
    imports: [
        CommonModule,
        AutocompleteChipsGroupsModule
    ],
    exports: [
        CalendarEventNotificationTargetsComponent
    ],
    declarations: [
        CalendarEventNotificationTargetsComponent
    ],
    providers: [
        CalendarEventNotificationTargetsService
    ]
})
export class CalendarEventNotificationTargetsModule { }

export * from './calendar-event-notification-targets.models';