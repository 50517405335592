import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { BaseTenantService } from '@summize/shared/core';
import { TaskNote } from '@summize/shared/framework';

@Injectable({ providedIn: 'root' })
export class TaskNotesService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public create(documentId: string, taskInstanceId: string, note: string): Promise<TaskNote> {

        const payload = {
            note: note
        };

        return lastValueFrom(
            this.http.post<TaskNote>(`${this.getUserBaseUrl(true)}document/${documentId}/tasks/${taskInstanceId}/notes`, payload));

    }

    public update(documentId: string, taskInstanceId: string, noteId: string, note: string): Promise<TaskNote> {

        const payload = {
            note: note
        };

        return lastValueFrom(
            this.http.put<TaskNote>(`${this.getUserBaseUrl(true)}document/${documentId}/tasks/${taskInstanceId}/notes/${noteId}`, payload));

    }

    public delete(documentId: string, taskInstanceId: string, noteId: string): Promise<any> {

        return lastValueFrom(
            this.http.delete(`${this.getUserBaseUrl(true)}document/${documentId}/tasks/${taskInstanceId}/notes/${noteId}`));

    }

}