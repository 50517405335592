import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './services/auth-guard.service';
import { NoAuthGuard } from './services/no-auth-guard.service';
import { LogoutOidcComponent } from './components/logout-oidc/logout-oidc.component';
import { LoginOidcComponent } from './components/login-oidc/login-oidc.component';
import { OidcAuthenticatedComponent } from './components/oidc-authenticated/oidc-authenticated.component';

const routes: Routes = [
  {
    path: 'login-oidc',
    component: LoginOidcComponent
  },
  {
    path: 'signin-oidc',
    component: OidcAuthenticatedComponent,
  },
  {
    path: 'signout-oidc',
    component: LogoutOidcComponent,
  },
  {
    path: 'tenants',
    loadChildren: () => import('./modules/tenants/tenants.module').then(m => m.TenantsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tenantusers',
    loadChildren: () => import('./modules/tenantusers/tenantusers.module').then(m => m.TenantUsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'oktausers',
    loadChildren: () => import('./modules/oktausers/oktausers.module').then(m => m.OktaUsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'identityusers',
    loadChildren: () => import('./modules/identityusers/identityusers.module').then(m => m.IdentityUsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'auditevents',
    loadChildren: () => import('./modules/auditevents/auditevents.module').then(m => m.AuditEventsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'activityevents',
    loadChildren: () => import('./modules/activityevents/activityevents.module').then(m => m.ActivityEventsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'logs',
    loadChildren: () => import('./modules/logs/logs.module').then(m => m.LogsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard/clm',
    loadChildren: () => import('./modules/clm-dashboard/clm-dashboard.module').then(m => m.ClmDashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard/tenant',
    loadChildren: () => import('./modules/tenant-dashboard/tenant-dashboard.module').then(m => m.TenantDashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'exceptions',
    loadChildren: () => import('./modules/exceptions/exceptions.module').then(m => m.ExceptionsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'custom-events',
    loadChildren: () => import('./modules/tenant-activity/tenant-activity.module').then(m => m.TenantActivityModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tenantsvisibility',
    loadChildren: () => import('./modules/tenantsvisibility/tenantsvisibility.module').then(m => m.TenantsVisibilityModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'usage',
    loadChildren: () => import('./modules/usage/usage.module').then(m => m.UsageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'processing-queue',
    loadChildren: () => import('./modules/processing-queue/processing-queue.module').then(m => m.ProcessingQueueModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'documents',
    loadChildren: () => import('./modules/documents/documents.module').then(m => m.DocumentsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'developer',
    loadChildren: () => import('./modules/developer/developer.module').then(m => m.DeveloperModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [NoAuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
