<div class="pill--container">
    <div class="pill" [style]="style" [class.has-icon]="icon !== undefined" [class.disabled]="disabled"
        [class.pill--active]="active" [class.pill--error]="pillType === pillTypes.Error"
        [class.pill--flagged]="pillType === pillTypes.Flagged" [class.pill--status]="pillType === pillTypes.Status"
        [class.pill--primary]="pillType === pillTypes.Primary" [class.pill--filtered]="pillType === pillTypes.Filtered"
        [matTooltip]="tooltip" (click)="onIconClick($event)">
        <div (click)="onIconClick($event)" [class]="icon + ' icon pill__icon'" [class.pill__icon--active]="active">
        </div>
        <p> {{ label }}</p>
    </div>
    <span class="pill--badge" *ngIf="badgeNumber > 1">{{badgeNumber}}</span>
</div>