import { Injector, QueryList, TemplateRef } from "@angular/core";

export class TableProvider {

    constructor(public injector: Injector) { }

    public getPrimary(): string {

        throw new Error('getPrimary must be implemented');

    }

    public getSecondary(range: string): string {

        throw new Error('getSecondary must be implemented');

    }

    public buildDataSource(templates: QueryList<TemplateRef<any>>) {

        throw new Error('buildDataSource must be implemented');

    }

    public getTemplateById(templates: QueryList<TemplateRef<any>>, id: string): any {

        return templates.find(t => {

            return t.elementRef.nativeElement.parentNode.getAttribute('type') === id;

        });

    }
}