<ng-container *ngIf="backingField !== undefined">
    <div class="custom-field-value">
        <div *ngIf="isEditing === false" (click)="enableEditing()" [class.clickable]="isEditable === true"
            [class.not-found]="value ==='n/a'" class="value-holder">
            {{value}}
        </div>
        <div *ngIf="isEditing === true" (blur)="reset()" class="editor-holder flex-row flex-space-between">
            <div class="editor flex-grow">
                <div *ngIf="backingField.fieldType === CustomFieldType.FreeText" class="field-value">
                    <input [ngModel]="boundValue" (blur)="setNormalValue(boundValue)"
                        (ngModelChange)="boundValue = $event" name="text" type="text" class="smz-standard-input v2"
                        autocomplete="off">
                </div>
                <div *ngIf="backingField.fieldType === CustomFieldType.Numeric" class="field-value">
                    <input [ngModel]="boundValue" (blur)="setNormalValue(boundValue)"
                        (ngModelChange)="boundValue = $event" name="text" type="number" class="smz-standard-input v2"
                        autocomplete="off">
                </div>
                <div *ngIf="backingField.fieldType === CustomFieldType.Datepicker" class="field-value">
                    <input [ngModel]="boundValue" (blur)="setNormalValue(boundValue)"
                        (ngModelChange)="boundValue = $event" name="text" type="date" class="smz-standard-input v2"
                        autocomplete="off">
                </div>
                <div *ngIf="backingField.fieldType === CustomFieldType.MultiSelect || backingField.fieldType === CustomFieldType.Dropdown"
                    class="field-value">
                    <app-select-box placeholder="Select" (changed)="setArrayValue($event)" [value]="boundValue"
                        [options]="values"
                        [multipleSelect]="backingField.fieldType === CustomFieldType.MultiSelect"></app-select-box>
                </div>
            </div>
            <div class="commands flex-row flex-align-vcenter flex-align-center">
                <app-icon (click)="reset()" icon="close-outline"></app-icon>
                <app-icon (click)="apply()" [disabled]="hasValue === false" icon="checkmark-outline"></app-icon>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="backingField === undefined">
    <div class="custom-field-value">
        <div [class.not-found]="value ==='n/a'" class="value-holder">
            {{value}}
        </div>
    </div>
</ng-container>