import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';

import { ConversationMessageContent } from '../conversation-input/conversation-input.component';

export interface Conversation {
    createdAt: string | number | Date;
    createdById: string;
    tenantId: any;
    departmentId: any;
    contractConversationId: string;
    subject: string;
    messages: Array<ConversationMessage>
    status: ConversationStatus;
}

export interface ConversationMessage {
    createdById: string;
    text: any;
    contractConversationMessageId: any;
    createdByName: string,
    email: string,
    createdAt: Date,
    html: string
}

export enum ConversationStatus {
    Active = 0,
    Resolved = 1
}

@Injectable({ providedIn: 'root' })
export class ConversationService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getConversationsForContract(contractId: string): Promise<Array<Conversation>> {

        const url = this.getUserBaseUrl(true);

        const result = await this.http.get(`${url}conversation/${contractId}`).toPromise() as any;

        return result.conversations;
    }

    public async createConversation(contractId: string,
        subject: string,
        message: ConversationMessageContent): Promise<Conversation> {

        const payload = {
            subject,
            ...message
        };

        const url = this.getUserBaseUrl(true);

        return await this.http.post(`${url}conversation/${contractId}`, payload).toPromise() as any;

    }

    public async changeConversationStatus(contractId: string, conversation: Conversation, status: ConversationStatus): Promise<void> {

        const payload = {
            status: status
        };

        const url = this.getUserBaseUrl(true);

        return await this.http.post(`${url}conversation/${contractId}/${conversation.contractConversationId}/status`, payload).toPromise() as any;
    }

    public async deleteConversation(contractId: string, conv: Conversation): Promise<void> {

        const url = this.getUserBaseUrl(true);

        return await this.http.delete(`${url}conversation/${contractId}/${conv.contractConversationId}`).toPromise() as any;
    }

    public async deleteMessage(contractId: string, conv: Conversation, messageId: string): Promise<void> {

        const url = this.getUserBaseUrl(true);

        return await this.http.delete(`${url}conversation/${contractId}/${conv.contractConversationId}/${messageId}`).toPromise() as any;
    }

    public async createConversationMessage(contractId: string,
        conversation: Conversation,
        message: ConversationMessageContent): Promise<ConversationMessage> {

        const url = this.getUserBaseUrl(true);

        return await this.http
            .post(`${url}conversation/${contractId}/${conversation.contractConversationId}`, message)
            .toPromise() as any;

    }

    public async editConversationMessage(contractId: string,
        conversation: Conversation,
        messageId: string,
        message: ConversationMessageContent): Promise<ConversationMessage> {

        const url = this.getUserBaseUrl(true);

        return await this.http
            .put(`${url}conversation/${contractId}/${conversation.contractConversationId}/${messageId}`, message)
            .toPromise() as any;

    }


}