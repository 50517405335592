import { Injectable } from '@angular/core';

import { SummizeStorage } from '@summize/shared/framework';

@Injectable({ providedIn: 'root' })
export class TokenService {
  getToken(): string {
    return SummizeStorage.getLocalItem('token');
  }

  saveToken(token: string) {
    SummizeStorage.setLocalItem('token', token);
  }

  destroyToken() {
    SummizeStorage.removeLocalItem('token');
  }
}
