import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';

import { SelectBoxModule } from '../select-box/select-box.module';
import { IconModule } from '../icon/icon.module';
import { AutocompleteChipsGroupsModule } from '../autocomplete-chips-groups/autocomplete-chips-groups.module';

import { AutoSendForSignatureComponent } from './auto-send-for-signature.component';
import { AutoSendForSignatureService } from './auto-send-for-signature.service';
import { GroupSelectBoxModule } from '../group-select-box/group-select-box.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { EsignatureEmailRecipientsModule } from '../esignature-email-recipients/esignature-email-recipients.module';
import { EsignatureEmailRecipientsModuleV2 } from '../esignature-email-recipients-v2/esignature-email-recipients-v2.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatSlideToggleModule,
        IconModule,
        MatTooltipModule,
        SelectBoxModule,
        GroupSelectBoxModule,
        AutocompleteChipsGroupsModule,
        LoadingSpinnerModule,
        EsignatureEmailRecipientsModule,
        EsignatureEmailRecipientsModuleV2
    ],
    exports: [
        AutoSendForSignatureComponent
    ],
    declarations: [
        AutoSendForSignatureComponent
    ],
    providers: [
        AutoSendForSignatureService
    ]
})
export class AutoSendForSignatureModule { }

export * from './auto-send-for-signature.component'