import { trigger, transition, style, animate } from '@angular/animations';

export const showFadeInFadeOut = createShowFadeInFadeOut('300ms', '300ms');
export const showFlyInFlyOut = createShowFlyInFlyOut('300ms', '100ms');
export const showFlyInFadeOut = createShowFlyInFadeOut('300ms', '1000ms');
export const showSlideDownSlideUp = createSlideDownSlideUp('300ms', '300ms');

export function createShowFadeInFadeOut(duration: string, outDuration?: string) {
    duration = duration ?? '300ms';
    outDuration = outDuration ?? duration;

    return trigger('showFadeInFadeOut', [
        transition(':enter', [
            style({ opacity: 0 }),
            animate(duration, style({ opacity: 1 }))
        ]),
        transition(':leave', [
            style({ opacity: 1 }),
            animate(outDuration, style({ opacity: 0 }))
        ])
    ]);
}

export function createShowFlyInFlyOut(duration: string, outDuration?: string) {
    duration = duration ?? '300ms';
    outDuration = outDuration ?? duration;

    return trigger('showFlyInFlyOut', [
        transition(':enter', [
            style({ transform: 'translateX(100%)' }),
            animate(duration, style({ transform: 'translateX(0)' }))
        ]),
        transition(':leave', [
            animate(outDuration, style({ transform: 'translateX(100%)' }))
        ])
    ]);
}

export function createShowFlyInFadeOut(duration: string, outDuration?: string) {
    duration = duration ?? '300ms';
    outDuration = outDuration ?? duration;

    return trigger('showFlyInFadeOut', [
        transition(':enter', [
            style({ transform: 'translateX(100%)' }),
            animate(duration, style({ transform: 'translateX(0)' }))
        ]),
        transition(':leave', [
            style({ opacity: 1 }),
            animate(outDuration, style({ opacity: 0 }))
        ])
    ]);
}

export function createSlideDownSlideUp(duration: string, outDuration?: string) {
    duration = duration ?? '300ms';
    outDuration = outDuration ?? duration;

    return trigger('showSlideDownSlideUp', [
        transition(':enter', [
            style({ transform: 'translateY(-100%)' }),
            animate(duration, style({ transform: 'translateY(0)' }))
        ]),
        transition(':leave', [
            style({ transform: 'translateY(0)' }),
            animate(outDuration, style({ transform: 'translateY(-100%)' }))
        ])
    ]);
}