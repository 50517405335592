import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: 'progress-bar.html',
    styleUrls: ['./progress-bar.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {

    @Input()
    public complete: number | undefined;

    @Input()
    public color: string | undefined;

    @Input()
    public rounded: boolean = false;

    @Input()
    public delta: number;

}