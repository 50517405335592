<mat-menu #menu="matMenu" xPosition="before" yPosition="below">
    <div class="menu">
        <div class="menu-user">
            <app-avatar size="32" class="header__profile-image" [name]="fullName"></app-avatar>
            <div class="menu-user-details">
                <div class="menu-user-name">
                    {{fullName}}
                </div>
                <div class="menu-user-email">
                    {{user?.email}}
                </div>
            </div>
        </div>

        <div class="menu-actions">
            <div (click)="showCalendarSync()" class="menu-icon-action clickable">
                <app-icon icon="calendar-outline"></app-icon>
                Calendar Sync
            </div>
            <div (click)="refreshSession()" class="menu-icon-action clickable">
                <app-icon icon="refresh-circle-outline"></app-icon>
                Refresh session
            </div>
            <div (click)="logout()" class="menu-icon-action clickable">
                <app-icon icon="log-out-outline"></app-icon>
                Log out
            </div>
        </div>
    </div>
</mat-menu>

<a [matMenuTriggerFor]="menu" class="header-link" [matTooltip]="fullNameTooltip">
    <app-avatar size="32" class="header__profile-image" [name]="fullName"></app-avatar>
</a>
