import { WidgetChartConfigurationOptions } from '@summize/shared/framework';

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
export const DEFAULT_DATE = '0001-01-01T00:00:00';
export const DEFAULT_DATE_UTC = '0001-01-01T00:00:00Z';
export const HEADER_HEIGHT = 60;
export const IsDefaultGUID = (v) => v === EMPTY_GUID;
export const PDF_EXTENSION = '.pdf';
export const DOCX_EXTENSION = '.docx';
export const CloneObject = (v) => JSON.parse(JSON.stringify(v));

export const isValidGuid = (item: string) => item !== undefined && item !== '' && item !== EMPTY_GUID;

export const isDateTimeDefault = (item: string) => {
  return new Date(item + 'Z').getUTCFullYear() === 1;
};

export const IsValidString = (str) => {
  return str !== undefined && str !== null && str !== '';
};

export const IsNotUndefinedOrNull = (str) => {
  return str !== undefined && str !== null;
};

export const IsNotEmptyString = (str: string): boolean => {
  return str !== undefined && str !== null && str.trim() !== '';
};

export const IsNullOrEmptyArray = (value: any): boolean => {
  return value === undefined || value === null && !Array.isArray(value) || value.length < 1;
};

export const IsNotNullOrEmptyArray = (value: any): boolean => {
  return value !== undefined && value !== null && Array.isArray(value) && value.length > 0;
};

export const CreateContractReviewUrl = (client, matter, document) => `/my-contracts/clients/${client}/matters/${matter}/review/${document}`;

export const RequestorPlaceholderGuid = '9c5429fd-5105-49c6-80b3-67fdb07456cd';

export const AllContractTypesId = '2018b2db-b06c-46b5-87f2-9bb05fb0a2e7';

export const TemplatePermissionTargetAll = '92087564-936c-4549-a6ee-d018207aa907';

export const TemplatePermissionTargetNone = '3c70dd7d-f38d-4206-8993-c389eff0df47';

export const CustomFieldPrefx = 'cf:';

export const EmailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const LogoValidFiletypes =  ['png', 'jpg', 'jpeg'];

export const isSummizeDomain = (domain) => {

  return domain.endsWith('summize.com') || domain.endsWith('summize.local');

};

export const IsLegalFrontDoor = () => {

  const isolated = (<any>window).summize?.isIsolated === true;

  return isolated !== undefined && isolated === true;

};

export const SafeParseInt = (value: string) => {
  try {
    const parsedValue = parseInt(value);

    if (isNaN(parsedValue)) {
      return undefined;
    }

    return parsedValue;
  } catch {
    return undefined;
  }
};

export const GetFileExtension = (str: string): string => {
  if (!IsValidString(str)) {
    return '';
  }

  const parts = str.split('.');
  return parts.length > 1 ? parts[parts.length - 1] : '';
}

export enum UserTypeEnum {
  'Empty' = 0,
  'All' = 1,
  'Full' = 2,
  'Restricted' = 3,
  'ReadOnly' = 4,
  'External' = 5,
  'Share' = 6,
  'MobileOnly' = 7,
  'Requestor' = 8
}

export enum TenantModeEnum {
  Full = 1,
  Poc = 2
}

export enum ProcessingStatus {
  Queued = 1,
  Started = 2,
  LocatingParties = 3,
  PreparingDocument = 4,
  ImageEnhancement = 5,
  CompressingDocument = 6,
  ReadingDocument = 7,
  PartiesIdentified = 8,
  ExtractingClauseInformation = 9,
  Failed = 10,
  ConfirmParties = 11,
  Error = 12,
  Completed = 13,
  PartiesConfirmed = 14,
  New = 15
}

export const ProcessingStatusText = {
  1: 'Queued',
  2: 'Started',
  3: 'Locating parties',
  4: 'Preparing document',
  5: 'Image enhancement',
  6: 'Compressing document',
  7: 'Reading document',
  8: 'Parties identified',
  9: 'Extracting clause information',
  10: 'Failed',
  11: 'Party confirmation received',
  12: 'Error',
  13: 'Completed',
  14: 'Parties confirmed',
  15: 'New'
};

export const StringOperators: Array<any> = [
  { key: 'equals', value: 'Equals' },
  { key: 'ne-equals', value: 'Does not equal' },
  { key: 'starts-with', value: 'Starts with' },
  { key: 'ends-with', value: 'Ends with' },
  { key: 'contains', value: 'Contains' },
  { key: 'ne-contains', value: 'Does not contain' }
];

export const NumericOperators: Array<any> = [
  { key: 'equals', value: 'Equals' },
  { key: 'ne-equals', value: 'Does not equal' },
  { key: 'gt-than', value: 'Greater than' },
  { key: 'gte', value: 'Greater than or equal to' },
  { key: 'lt-than', value: 'Less than' },
  { key: 'lte', value: 'Less than or equal to' }
];

export const ListOperators: Array<any> = [
  { key: 'any', value: 'Is any of' },
  { key: 'ne-any', value: 'Is not any of' }
];

export const AdobeSignRecipientStatus = {
  NOT_YET_VISIBLE: 'NOT_YET_VISIBLE'
};

export const PocTenantNonSummizeUserTooltipMessage = 'This feature is currently unavailable for users during the trial period.';

export const RequestStatusesWithSendForSignature = '10,40,11,13,14,15,99';

export const RequestStatusesWithoutSendForSignature = '10,40,11,13,14,99';

export const FeatureFlags = {
  TemplateQuestionScripting: 'TemplateQuestionScripting',
  AutoSendForSignature: 'AutoSendForSignature',
  HasPlaybookDesignerAugust2024: 'HasPlaybookDesignerAugust2024',
  HasHubSpotIntegration: 'HasHubSpotIntegration',
  TemplateSettings: 'TemplateSettings',
  DocuSignMarkup: 'DocuSignMarkup',
  NotificationActions: 'NotificationActions',
  RulebooksPromptIncludeExamples: 'RulebooksPromptIncludeExamples',
  HasSharePointIntegration: 'HasSharePointIntegration',
  RelationshipsDisconnect: 'RelationshipsDisconnect',
  CreateTemplateReplace: 'CreateTemplateReplace',
  LookupBelmond: 'LookupBelmond',
  ContractQuestionGrouping: 'ContractQuestionGrouping',
  ClauseCalendarCustomReminders: 'ClauseCalendarCustomReminders'
};

export class WidgetChartConfigurationOptionsImpl implements WidgetChartConfigurationOptions {

  public displayTotals: boolean;

  private constructor(displayTotals: boolean) {

    this.displayTotals = displayTotals;
  }

  public static create(displayTotals: boolean): WidgetChartConfigurationOptions {

    return new WidgetChartConfigurationOptionsImpl(displayTotals);

  }
}