import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatMenuModule } from '@angular/material/menu';
import { HoverContentComponent } from './hover-content.component';

@NgModule({
    imports: [
        CommonModule,
        MatMenuModule,
    ],
    exports: [
        HoverContentComponent
    ],
    declarations: [
        HoverContentComponent
    ]
})
export class HoverContentModule { }

export * from './hover-content.component';
