import { Component, OnInit } from '@angular/core';

import { v4 as uuidv4 } from 'uuid';
import { MatDialogRef } from '@angular/material/dialog';
import { SelectBoxModel } from '@summize/shared/components';
import { EventService } from '@summize/shared/framework';

import { SetSelectedManageClauseMetrics } from '../../analytics.events';
import { CurrencyMask, DurationMask, GraphSize, SpecialTypes } from '../../analytics.types';

import { AddGraphService } from './add-graph.service';

@Component({
    selector: 'app-add-graph',
    templateUrl: 'add-graph.html',
    styleUrls: ['./add-graph.scss']
})
export class AddGraphComponent implements OnInit {

    public isLoading: boolean = true;

    public isClausesLoading: boolean = true;

    public documentTypes!: Array<SelectBoxModel>;

    public selectedDocumentType!: string;

    public clauses!: Array<any> | undefined;

    constructor(public dialogRef: MatDialogRef<AddGraphComponent>,
        private service: AddGraphService,
        private events: EventService
    ) { }

    public async ngOnInit(): Promise<void> {

        this.isLoading = true;

        this.documentTypes = await this.service.getDocumentTypes();

        this.selectedDocumentType = this.documentTypes[0].key;

        this.contractTypeChanged(this.selectedDocumentType);

        this.isLoading = false;

    }

    public close() {

        this.dialogRef.close(undefined);

    }

    public save() {

        const selected = this.clauses?.filter(c => c.checked === true).map(c => {

            let initial: any = {
                id: uuidv4(),
                contractTypeId: c.documentTypeId,
                clauseId: c.clauseId,
                type: c.type,
                settings: {
                    name: `Avg ${c.clauseName}`,
                    size: GraphSize.Regular.toString()
                }
            }

            switch (c.type) {
                case SpecialTypes.DURATION:
                    initial.settings.mask = DurationMask.Weeks.toString();
                    break;
                case SpecialTypes.CURRENCY:
                    initial.settings.mask = CurrencyMask.Exact.toString();
                    break;
                default:
                    break;
            }

            return initial;

        });

        this.events.despatch(SetSelectedManageClauseMetrics, selected);

        this.close();

    }

    public async contractTypeChanged(typeId: string): Promise<void> {

        this.selectedDocumentType = typeId;

        this.isClausesLoading = true;

        const clauses: any = await this.service.getDocumentTypeClauses(typeId);

        this.clauses = clauses.items.map((c: any) => ({ ...c, checked: false }));

        this.clauses = this.clauses?.length === 0 ? undefined : this.clauses;

        this.isClausesLoading = false;

    }

}