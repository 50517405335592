import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

import { MatChipsModule } from '@angular/material/chips';


import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { UserAddBulkPanelComponent } from './user-add-bulk-panel.component';
import { BlockButtonModule } from '../block-button/block-button.module';
import { IconModule } from '../icon/icon.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingSpinnerModule,
        MatButtonModule,
        MatChipsModule,
        MatIconModule,
        BlockButtonModule,
        IconModule
    ],
    exports: [
        UserAddBulkPanelComponent
    ],
    declarations: [
        UserAddBulkPanelComponent
    ],
    providers: [

    ]
})
export class UserAddBulkPanelModule { }

export * from './user-add-bulk-panel.component'