import { Inject, Injectable } from '@angular/core';

import { Subject, Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { IsValidString } from '@summize/shared/core';

import { Environment, Event } from '../types';

export interface DespatchConfig {
    sendInternal: boolean;
    sendPostMessage: boolean;
    postMessageOrigin?: string;
}

@Injectable({ providedIn: 'root' })
export class EventService {

    public static GlobalEvents = {
        SetUser: 'SetUser',
        LaunchCalendarSync: 'LaunchCalendarSync'
    };

    protected $stream: Subject<Event>;

    private debug: boolean;

    private eventsDespatchConfig: { [key: string]: DespatchConfig } = {};

    constructor(@Inject(Environment) environment) {

        this.$stream = new Subject<Event>();

        this.debug = environment.production === false;

    }

    public setEventDespatchConfig(event: string, config: DespatchConfig) {

        this.eventsDespatchConfig[event] = config;

    }

    public despatch(event: string, context?: any, scope?: string): void {

        if (this.debug === true) {

            console.log(`Summize Event In: ${event} - ${JSON.stringify(context || {})}`);

        }

        const eventDespatchConfig = this.eventsDespatchConfig[event] ?? { sendInternal: true, sendPostMessage: false};

        if (eventDespatchConfig.sendInternal === true) {

            this.$stream.next({ event, context, scope });

        }

        if (eventDespatchConfig.sendPostMessage === true && IsValidString(eventDespatchConfig.postMessageOrigin)) {

            window.parent.postMessage({ MessageId: event, values: { context, scope } }, eventDespatchConfig.postMessageOrigin);

        }

    }

    public whenScoped(event: string, scope: string): Observable<Event> {

        return this.$stream.pipe(
            filter(a => a.scope !== undefined && a.scope === scope && a.event === event),
            tap(a => this.logEvent(a))
        );

    }

    public when(event: string): Observable<Event> {

        return this.$stream.pipe(
            filter(a => a.event === event),
            tap(a => this.logEvent(a))
        );

    }

    public actionComplete(text: string): void {

        this.despatch("ShowToastMessage", { text: text });
    }

    public actionFailed(text: string): void {

        this.despatch("ShowToastMessage", {
            text: text,
            type: 'error',
            icon: 'alert-circle'
        });
    }

    private logEvent(val: Event): void {

        if (this.debug === true) {

            console.log(`Summize -- Event Out: ${val.event} - ${JSON.stringify(val.context || {})}`);

        }

    }

}
