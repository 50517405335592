import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconModule } from '../icon/icon.module';

import { RelatedParagraphsComponent } from './related-paragraphs.component';

@NgModule({
    imports: [
        CommonModule,
        IconModule
    ],
    exports: [
        RelatedParagraphsComponent
    ],
    declarations: [
        RelatedParagraphsComponent
    ]
})
export class RelatedParagraphsModule { }

export * from './related-paragraphs.component'