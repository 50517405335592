import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { MatSelect } from '@angular/material/select';
import { CloneObject } from '@summize/shared/core';

export interface GroupModel {
  key: string;
  description: string;
  value: any[];
}

@Component({
  selector: 'app-group-select-box',
  templateUrl: 'group-select-box.html',
  styleUrls: ['./group-select-box.scss']
})

export class GroupSelectBoxComponent implements OnInit {

  @ViewChild(MatSelect)
  public select: MatSelect;

  @Input()
  public set value(value: string) {

    this._value = value;

    if (value !== undefined && value !== null) {

      this.updateSelectedLabel(value);

    }

  }

  public get value(): string {

    return this._value;

  }

  @Input()
  public tooltips: boolean = false;

  @Input()
  public placeholder: string = 'Please Select';

  @Input()
  public groups: Array<GroupModel>;

  @Input()
  public isRequired: boolean = false;

  @Input()
  public disabled: boolean = false;

  @Input()
  public errorState: boolean = false;

  @Input()
  public multiple: boolean = false;

  @Output()
  public changed: EventEmitter<string>;

  public initialGroups: Array<GroupModel>;

  public selected: any = {};

  private _value: string;

  constructor() {

    this.changed = new EventEmitter();

  }

  public ngOnInit(): void {

    this.initialGroups = CloneObject(this.groups);

  }

  public update(newGroups: any, selectedId: string): void {

    this.groups = newGroups;

    this.value = selectedId;

  }

  public valueChanged(value: any): void {

    this.changed.emit(value);

    this.updateSelectedLabel(value);

  }

  public updateSelectedLabel(value: any): void {

    for (const group of this.groups) {

      const item = group.value.find(x => {

        return typeof x.key === 'string' ?
          x.key.toLowerCase() === value.toLowerCase() :
          x.key === value;

      });

      if (item !== undefined) {

        this.selected = { group: group.key, value: item.value };

        return;

      }

    }

  }

  public onKey(value) {

    this.groups = this.search(value);
  }

  public onClearSearch() {

    const groups = CloneObject(this.initialGroups);

    this.groups = groups;

  }

  public search(value: string) {

    let filter = value.toLowerCase();

    if (filter === undefined || filter === '') {

      return this.initialGroups;

    }

    let groups = CloneObject(this.initialGroups);

    const results: any = [];

    for (const group of groups) {

      var valueMatches = group.value.filter(value => value.value.toLowerCase().includes(filter));

      if (valueMatches !== undefined && valueMatches.length > 0) {

        group.value = valueMatches;

        results.push(group);

      }

    }

    return results;

  }

}
