<mat-form-field class="select-box" appearance="fill" [formGroup]="parentForm">

    <mat-select [placeholder]="placeholder" [value]="value" panelClass="select-panel" disableOptionCentering
        formControlName="{{fcn}}" [required]="isRequired" (selectionChange)="valueChanged($event.value)">
        <ng-container *ngIf="grouped === false">
            <mat-option *ngFor="let opt of options" [value]="opt.key">
                {{opt.value}}
            </mat-option>
        </ng-container>
        <ng-container *ngIf="grouped === true">
            <mat-optgroup *ngFor="let group of options | keyvalue; let index = index" [label]="group.key">
                <mat-option *ngFor="let opt of group.value" [value]="opt.key">
                    {{opt.value}}
                </mat-option>
            </mat-optgroup>
        </ng-container>
    </mat-select>
</mat-form-field>