import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router, ResolveEnd, ActivatedRouteSnapshot } from '@angular/router';

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Environment } from '@summize/shared/framework';

import { BaseTenantService } from './base-tenant.service';

export const AppLoginMetric: string = 'WebAppUserLogin';

@Injectable({
    providedIn: 'root'
})
export class ApplicationInsightsService implements OnDestroy {

    private routerSubscription: Subscription | null;

    private appInsights: ApplicationInsights;

    constructor(private router: Router, @Inject(Environment) private environment) { }

    public ngOnDestroy() {

        if (this.routerSubscription) {

            this.routerSubscription.unsubscribe();

            this.routerSubscription = null;

        }

    }

    public init(instrumentationKey: string, cloudRole: string = 'SummizeUI') {

        if (instrumentationKey === undefined || instrumentationKey === '') {

            return;

        }

        if (this.appInsights !== undefined) {

            return;

        }

        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                loggingLevelConsole: 1,
            }
        });

        this.routerSubscription = null;


        this.appInsights.loadAppInsights();

        const telemetryInitializer = (envelope) => {

            envelope.tags["ai.cloud.role"] = cloudRole;

        }

        this.appInsights.addTelemetryInitializer(telemetryInitializer);

        this.routerSubscription = (<any>this.router.events).pipe(filter(event => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {

            const activatedComponent = this.getActivatedComponent(event.state.root);

            if (activatedComponent) {

                this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);

            }

        });

    }

    setUserId(userId: string) {

        if (!BaseTenantService.environment.applicationInsights || !this.appInsights) { return; }

        this.appInsights.setAuthenticatedUserContext(userId);

    }

    clearUserId() {

        if (!BaseTenantService.environment.applicationInsights || !this.appInsights) { return; }

        this.appInsights.clearAuthenticatedUserContext();

    }

    logPageView(name?: string, uri?: string) {

        if (!BaseTenantService.environment.applicationInsights || !this.appInsights) { return; }

        this.appInsights.trackPageView({ name, uri });

    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {

        if (!BaseTenantService.environment.applicationInsights || !this.appInsights) { return; }

        this.appInsights.trackMetric({ name: name, average: average }, properties);

    }

    trackError(error: Error) {

        if (!BaseTenantService.environment.applicationInsights || !this.appInsights) { return; }

        if (error instanceof Error) {

            this.appInsights.trackException({
                exception: error
            });

        } else {

            this.appInsights.trackException({
                exception: new Error(error)
            });

        }
    }

    public trackEvent(name: string, properties?: any): void {

        if (!BaseTenantService.environment.applicationInsights || !this.appInsights) { return; }

        const payload = {
            name, properties
        };

        this.appInsights.trackEvent(payload);
        
    }

    private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
        if (snapshot.firstChild) {
            return this.getActivatedComponent(snapshot.firstChild);
        }

        return snapshot.component;
    }

    private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
        let path = '';
        if (snapshot.routeConfig) {
            path += snapshot.routeConfig.path;
        }

        if (snapshot.firstChild) {
            return path + this.getRouteTemplate(snapshot.firstChild);
        }

        return path;
    }
}
