<ng-container *ngIf="isAuthenticated === true">
    <app-header></app-header>

    <div class="v2-layout" [class.menu-expanded]="menuExpanded">

        <div class="container-fluid px-0 no-scroll">

            <div class="fade-block view-container no-scroll">

                <div class="side-bar fade-block">
                    <app-side-bar (expandedChanged)="menuExpanded = $event"></app-side-bar>
                </div>

                <div class="router scroll">
                    <router-outlet></router-outlet>
                </div>
            </div>

        </div>
    </div>

</ng-container>

<ng-container *ngIf="isAuthenticated === false">
    <router-outlet></router-outlet>
</ng-container>