import { Component, Input, EventEmitter, Output, ViewChild, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

export interface EditableTextModel {
  key: string, value: string
}

@Component({
  selector: 'app-editable-text-and-select',
  templateUrl: 'editable-text-and-select.html',
  styleUrls: ['./editable-text-and-select.scss']
})

export class EditableTextAndSelectComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) 
  public inputAutoComplete: MatAutocompleteTrigger;

  @Input('IsDisabled')
  public isDisabled: boolean = false;

  @Input('IsFinishOnEnter')
  public isFinishOnEnter: boolean;

  @Input('IsDeletable')
  public isDeletable: boolean;

  @Input('Options')
  public options: Array<EditableTextModel>

  @Input('Placeholder')
  public placeholder: string = '';

  @Input()
  public value: string = '';

  @Output()
  public onTextChanged: EventEmitter<string> = new EventEmitter<string>();

  public myControl = new FormControl();
  public filteredOptions: Observable<EditableTextModel[]>;
  public originalOptions: EditableTextModel[]; 

  public isEditing: boolean = false;

  public ngOnInit(): void {
    
    this.myControl.setValue(this.value);

    this.isDisabled === true ? this.myControl.disable() : this.myControl.enable();

    this.isEditing = false;

    this.originalOptions = this.options;

    this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        switchMap(value => {
            if (value === '') {
                return of(this.originalOptions);
            } else {
                return of(this.originalOptions.filter(option => option.value.toLowerCase().includes(value.toLowerCase())));
            }
        })
    );

  }

  public onFocus() {
    this.isEditing = true;
  }

  public valueChanged(event) {
    this.value = event.option.value;
  }

  public onBlur() {

    this.isEditing = false;

    if (this.inputAutoComplete.panelOpen === true) {

      this.inputAutoComplete.closePanel();

    }

    this.onTextChanged.emit(this.myControl.value);

  }

  public onDownArrowClick(event) {

    event.stopPropagation();

    if (this.isDisabled === true) {
      return;
    }

    if (this.inputAutoComplete.panelOpen === true) {

      this.inputAutoComplete.closePanel();

    } else {

      this.isEditing = true;

      this.inputAutoComplete.openPanel();

    }
  }

}