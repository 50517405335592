<app-dialog-shell [title]="title || 'Download Contract'" saveText="Download" (onClose)="close()"
    [saveDisabled]="hasSelection === false" (onSave)="save()" [showFooter]="isLoading === false">
    <div class="download-contract">
        <ng-container *ngIf="isLoading === true">
            <div class="loader">
                <app-loading-spinner visible="true"></app-loading-spinner>
            </div>
        </ng-container>
        <ng-container *ngIf="isLoading === false">

            <ng-container *ngIf="isInbox === false">

                <p>{{description || 'Select the contract version and any linked contract files you want to download.'}}
                </p>

                <div *ngIf="selectVersions?.length > 0" class="version-select">
                    <app-select-box (changed)="selectVersion($event)" [options]="selectVersions"
                        [value]="selectedDocumentId" placeholder="Please select the version:"></app-select-box>
                </div>

                <div *ngIf="model" class="files">
                    <div class="file">
                        <div class="selector">
                            <mat-checkbox [value]="model.checked" (change)="model.checked = $event.checked"
                                [checked]="model.checked">
                            </mat-checkbox>
                        </div>
                        <div class="info">
                            <div class="name">
                                {{model.documentName}}
                            </div>
                            <div class="type">
                                Master
                            </div>
                        </div>
                    </div>
                    <div *ngFor="let linked of model.linkedDocuments" class="file">
                        <div class="selector">
                            <mat-checkbox [value]="linked.checked" (change)="linked.checked = $event.checked"
                                [checked]="linked.checked">
                            </mat-checkbox>
                        </div>
                        <div class="info">
                            <div class="name">
                                {{linked.documentName}}
                            </div>
                            <div class="type">
                                {{linked.linkedDocumentType}}
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="isInbox === true">

                <div class="files">
                    <div *ngFor="let document of model" class="file">
                        <div class="selector">
                            <mat-checkbox [value]="document.checked" (change)="document.checked = $event.checked"
                                [checked]="document.checked">
                            </mat-checkbox>
                        </div>
                        <div class="info">
                            <div class="name">
                                {{document.documentName}}
                            </div>
                            <div class="type">
                                {{document.originalFile.documentType}}
                            </div>
                        </div>
                    </div>
                </div>

            </ng-container>

        </ng-container>
    </div>
</app-dialog-shell>