import { NgModule } from '@angular/core';

import { SmzDatePipe } from './date.pipe';
import { UserTypeFriendlyNamePipe } from './userTypeFriendlyName.pipe';

@NgModule({
  declarations: [
    SmzDatePipe,
    UserTypeFriendlyNamePipe
  ],
  exports: [
    SmzDatePipe,
    UserTypeFriendlyNamePipe
  ],
})
export class CorePipesModule { }

export * from './date.pipe';
