import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { CustomFieldDefinition, CustomFieldType } from '@summize/feature/custom-fields';
import { SelectBoxModel } from '../form-select-box/form-select-box.component';

@Component({
    selector: 'app-custom-field-value-inline-editor',
    templateUrl: 'custom-field-value-inline-editor.html',
    styleUrls: ['./custom-field-value-inline-editor.scss']
})
export class CustomFieldValueInlineEditorComponent implements OnInit, OnChanges {

    public CustomFieldType = CustomFieldType;

    @Output()
    public onChange: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    public value: any;

    @Input()
    public name: any;

    @Input()
    public fields: Array<CustomFieldDefinition>;

    @Input()
    public isEditable: boolean;

    @Input()
    public hasEditableClaim: boolean;

    public get hasValue() {

        if (this.backingField.fieldType === CustomFieldType.Dropdown ||
            this.backingField.fieldType === CustomFieldType.MultiSelect) {

            return this.boundValue !== undefined && this.boundValue.length > 0 && this.boundValue[0] !== '--';

        }

        return this.boundValue !== undefined && this.boundValue !== '' && this.boundValue !== '--';

    }

    public values: Array<SelectBoxModel>

    public isEditing: boolean = false;

    public boundValue: any;

    public backingField: CustomFieldDefinition;

    public ngOnInit(): void {

        this.isEditable = this.hasEditableClaim === true ? true : false;

        if (this.value === 'n/a' === true) {

            this.isEditable = false;

        }

        this.backingField = this.fields.find(f => `cf:${f.id}` === this.name);

        if (this.backingField !== undefined) {

            if (this.backingField.fieldType === CustomFieldType.Dropdown ||
                this.backingField.fieldType === CustomFieldType.MultiSelect) {

                this.values = JSON.parse(this.backingField.values).map(v => ({ key: v.id, value: v.display }));

                if (this.backingField.fieldType === CustomFieldType.Dropdown === true) {

                    this.boundValue = this.value;

                    const matchingValue = this.values.find(x => x.key === this.value);

                    if (matchingValue !== undefined && this.value !== '--' && this.value !== 'n/a') {

                        this.value = matchingValue.value;

                    }

                } else {

                    this.boundValue = this.value.split(',');

                    const displayParts = [];

                    for (const val of this.boundValue) {

                        const matchingValue = this.values.find(x => x.key === val);

                        if (matchingValue !== undefined) {

                            displayParts.push(matchingValue.value);

                        }

                    }

                    if (this.value !== '--' && this.value !== 'n/a') {

                        this.value = displayParts.join(',');

                    }

                }

            } else {

                this.boundValue = this.value;

            }

        }

        if (this.boundValue === '--') {

            this.boundValue = '';

        }

    }

    public ngOnChanges(changes: SimpleChanges): void {

        if (changes['value']) {

            if (changes['value'].firstChange === false) {

                this.ngOnInit();

            }
        }
    }


    public reset() {

        this.isEditing = false;

    }

    public setArrayValue(value) {

        this.boundValue = value;

    }

    public enableEditing() {

        if (this.isEditable === true) {

            this.isEditing = true

        }

    }

    public apply() {

        let displayValue = this.boundValue;

        if (this.backingField.fieldType === CustomFieldType.Dropdown ||
            this.backingField.fieldType === CustomFieldType.MultiSelect) {

            this.values = JSON.parse(this.backingField.values).map(v => ({ key: v.id, value: v.display }));

            if (this.backingField.fieldType === CustomFieldType.Dropdown === true) {

                const matchingValue = this.values.find(x => x.key === this.boundValue);

                if (matchingValue !== undefined) {

                    displayValue = matchingValue.value;

                } else {

                    this.reset();

                    return;
                    
                }

            } else {

                const displayParts = [];

                for (const val of this.boundValue) {

                    const matchingValue = this.values.find(x => x.key === val);

                    if (matchingValue !== undefined) {

                        displayParts.push(matchingValue.value);

                    }

                }

                displayValue = displayParts.join(',');

            }
        }


        if (this.backingField.fieldType === CustomFieldType.MultiSelect) {

            this.onChange.next({
                definition: this.backingField,
                value: this.boundValue.join(','),
                displayValue
            });

        } else {

            this.onChange.next({
                definition: this.backingField,
                value: this.boundValue,
                displayValue
            });

        }

        this.reset();
    }

    public setNormalValue(value) {

        this.boundValue = value;

    }

}