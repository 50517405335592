import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';

export interface CustomFieldDefinition {
    id?: string;
    name: string;
    fieldType: CustomFieldType;
    targetContractTypes: any;
    values?: any;
    description?: string;
    targetContractTypesDisplay?: string;
    fieldTypeDisplay?: string;
    valueDisplay?: any;
}

export enum CustomFieldType {
    Dropdown,
    MultiSelect,
    FreeText,
    Numeric,
    Datepicker
}

@Injectable({ providedIn: 'root' })
export class ChangeCustomFieldDialogService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getCustomFieldDefinitionsByDocumentIds(documentIds: Array<string>): Promise<Array<CustomFieldDefinition>> {

        const baseUrl = this.getUserBaseUrl(true);

        return await this.http.post<Array<CustomFieldDefinition>>(`${baseUrl}customfields/values/by-documentids`, documentIds).toPromise();
    }
}