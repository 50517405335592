<div class="tokenlimit">
  <app-icon class="tokenlimit-icon" icon="alert-circle-outline"></app-icon>

  <div class="tokenlimit-text">
    The token limit for this thread has been reached. A new thread has been started so you can continue. <span (click)="showMore = !showMore" class="clickable tokenlimit-text-showmore">See {{ showMore ? 'less' : 'more' }}</span>

    <ng-container *ngIf="showMore === true">
        <p>A ‘token’ is a unit of text that AI uses to process requests, for example “Hello, how are you today?” uses 6 tokens. Each thread has a token limit to ensure responses are accurate and prevent overloading the AI.</p>
        <p>If the token limit is reached we will automatically start a new thread and reset the token limit so you can continue. However, when a new thread is started, AI will have no context of the messages from previous threads, so you may need to provide new context for more accurate results.</p>
    </ng-container>

  </div>

</div>
