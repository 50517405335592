import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';

import { ContractStatusSelectComponent } from './contractstatus-select.component';
import { BlockButtonModule } from '../block-button/block-button.module';
import { AvatarModule } from '../avatar/avatar.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AvatarModule,
        ReactiveFormsModule,
        MatInputModule,
        MatMenuModule,
        MatButtonModule,
        MatTooltipModule,
        BlockButtonModule
    ],
    exports: [
        ContractStatusSelectComponent
    ],
    declarations: [
        ContractStatusSelectComponent
    ]
})
export class ContractStatusSelectModule { }

export * from './contractstatus-select.component'