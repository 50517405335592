import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatRadioModule } from '@angular/material/radio';

import { FilterEditDialogComponent } from './filter-edit-dialog.component';
import { DialogShellModule } from '../dialog-shell/dialog-shell.module';

@NgModule({
    imports: [
        CommonModule,
        DialogShellModule,
        FormsModule,
        MatRadioModule
    ],
    exports: [
        FilterEditDialogComponent
    ],
    declarations: [
        FilterEditDialogComponent
    ]
})
export class FilterEditDialogModule { }

export * from './filter-edit-dialog.component'