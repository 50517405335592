import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ClauseSelectorComponent } from './clause-selector.component';
import { ClauseSelectorService } from './clause-selector.service';
import { StyledButtonModule } from '../styled-button/styled-button.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

@NgModule({
    imports: [
        CommonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatTooltipModule,
        StyledButtonModule,
        LoadingSpinnerModule
    ],
    exports: [
        ClauseSelectorComponent
    ],
    declarations: [
        ClauseSelectorComponent
    ],
    providers: [
        ClauseSelectorService
    ]
})
export class ClauseSelectorModule { }

export * from './clause-selector.component'