<div *ngIf="manual === false">
    <mat-form-field [class.v2]="v2 === true" class="company-search" appearance="fill">
        <input matInput placeholder="Company search" aria-label="State" [matAutocomplete]="auto"
            [formControl]="searchCtrl">
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
            <ng-container *ngIf="!isLoading">
                <mat-option *ngFor="let company of companies" [value]="company.companyName"
                    (onSelectionChange)="onSelect(company)"
                    [matTooltip]="company.companyName + ' (' + company.companyId + ') ' + company.companyAddress">
                    <span>
                        {{company.companyName}} ({{company.companyId}}) {{company.companyAddress}}
                    </span>
                </mat-option>
            </ng-container>
            <mat-option *ngIf="errorMsg && !isLoading">{{errorMsg}}</mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <div *ngIf="question?.allowManualSearch !== false" (click)="setManual(true)" class="enter-manually clickable">
        Enter company manually
    </div>
</div>

<div *ngIf="manual === true">
    <div class="info-segment">
        <div class="header required">
            Name
        </div>
        <div>
            <input [class.v2]="v2 === true" class="smz-standard-input"
                (ngModelChange)="onChanged($event, 'companyName')" [ngModel]="company.companyName" required />
        </div>
    </div>
    <div class="info-segment">
        <div class="header" [class.required]="isCompanyNumberRequired">
            Number
        </div>
        <div>
            <input [class.v2]="v2 === true" class="smz-standard-input" (ngModelChange)="onChanged($event, 'companyId')"
                [ngModel]="company.companyId" [required]="isCompanyNumberRequired" />
        </div>
    </div>
    <div class="info-segment">
        <div class="header required">
            Address
        </div>
        <div>
            <input [class.v2]="v2 === true" class="smz-standard-input"
                (ngModelChange)="onChanged($event, 'companyAddress')" [ngModel]="company.companyAddress" required />
        </div>
    </div>
    <div (click)="setManual(false)" class="enter-manually clickable">Search for company</div>
</div>