import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserTasksComponent } from './user-tasks.component';
import { UserTasksHelper } from './user-tasks.helper';
import { UserTaskService } from './user-task.service';
import { TaskCardModule } from '../task-card/task-card.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { TaskLegendModule } from '../task-legend/task-legend.module';

@NgModule({
    imports: [
        CommonModule,
        TaskCardModule,
        LoadingSpinnerModule,
        TaskLegendModule
    ],
    exports: [
        UserTasksComponent,
    ],
    declarations: [
        UserTasksComponent
    ],
    providers: [
        UserTasksHelper,
        UserTaskService
    ]
})
export class UserTasksModule { }

export * from './user-tasks.component'
export * from './user-tasks.helper';