<div *ngIf="playbooks?.length > 0" class="playbook-content">

    <div class="playbook-picker">
        <app-select-box (changed)="selectPlaybook($event)" [options]="playbookOptions"
            placeholder="Select Playbook ...">
        </app-select-box>
    </div>

    <div *ngIf="isLoading === false && selectedPlaybook">

        <!-- Standard Playbook -->
        <div *ngIf="selectedPlaybook.isPremium === false">

            <div *ngIf="selectedPlaybook?.clauses?.length === 0" class="no-position">
                No Clauses have been configured for this playbook.
            </div>

            <div *ngFor="let clause of selectedPlaybook.clauses">

                <app-expandable-card [title]="clause.name" [border]="false">

                    <div *ngFor="let standardPosition of clause.standardPositions" class="position">

                        <div class="container">

                            <div class="title-bar">
                                <span class="position-title">Standard Position</span>

                                <div class="commands">
                                    <app-icon *ngIf="selectedClause" [icon]="'list-circle-outline'"
                                        (click)="copyToClauseBullet(standardPosition.text)"
                                        [tooltip]="'Add to clause bullets'" [disabled]="!standardPosition.insertText">
                                    </app-icon>

                                    <app-icon *ngIf="selectedClause" [icon]="'chatbubble-ellipses-outline'"
                                        (click)="copyToComments(standardPosition.text)" [tooltip]="'Add to comments'"
                                        [disabled]="!standardPosition.insertComment">
                                    </app-icon>

                                    <app-icon [icon]="'clipboard-outline'"
                                        (click)="copyToClipboard(standardPosition.text)"
                                        [disabled]="!standardPosition.clipboard" [tooltip]="'Copy to clipboard'">
                                    </app-icon>
                                </div>
                            </div>

                            <span class="position-text">
                                {{standardPosition.text}}
                            </span>

                        </div>

                    </div>

                    <div *ngFor="let position of clause.positions; let i = index" class="position">

                        <div class="container">

                            <div class="title-bar">

                                <span class="position-title">Position {{i+1}}</span>

                                <div class="commands">
                                    <app-icon *ngIf="selectedClause" [icon]="'list-circle-outline'"
                                        (click)="copyToClauseBullet(position.text)" [disabled]="!position.insertText"
                                        [tooltip]="'Add to clause bullets'">
                                    </app-icon>

                                    <app-icon *ngIf="selectedClause" [icon]="'chatbubble-ellipses-outline'"
                                        (click)="copyToComments(position.text)" [tooltip]="'Add to comments'"
                                        [disabled]="!position.insertComment">
                                    </app-icon>

                                    <app-icon [icon]="'clipboard-outline'" (click)="copyToClipboard(position.text)"
                                        [disable]="!position.clipboard" [tooltip]="'Copy to clipboard'">
                                    </app-icon>
                                </div>

                            </div>

                            <span class="position-text">{{position.text}}</span>

                        </div>

                    </div>
                </app-expandable-card>

            </div>
        </div>

        <!-- Premium Playbook -->
        <div *ngIf="selectedPlaybook.isPremium === true">

            <div *ngIf="selectedPlaybook?.clauses?.length === 0" class="no-position">
                No Clauses have been configured for this playbook.
            </div>

            <div *ngFor="let clause of selectedPlaybook.clauses">

                <app-expandable-card [title]="clause.name" [border]="false">

                    <div *ngFor="let standardPosition of clause.standardPositions" class="position premium-position">
                        <div class="container">

                            <div class="title-bar">
                                <span class="position-title">Standard Position</span>

                                <div class="commands">
                                    <app-icon *ngIf="selectedClause" [icon]="'list-circle-outline'"
                                        (click)="copyToClauseBullet(standardPosition.text)"
                                        [tooltip]="'Add to clause bullets'" [disabled]="!standardPosition.insertText">
                                    </app-icon>

                                    <app-icon *ngIf="selectedClause" [icon]="'chatbubble-ellipses-outline'"
                                        (click)="copyToComments(standardPosition.text)" [tooltip]="'Add to comments'"
                                        [disabled]="!standardPosition.insertComment">
                                    </app-icon>

                                    <app-icon [icon]="'clipboard-outline'"
                                        (click)="copyToClipboard(standardPosition.text)"
                                        [disabled]="!standardPosition.clipboard" [tooltip]="'Copy to clipboard'">
                                    </app-icon>
                                </div>
                            </div>

                            <span class="position-text">
                                {{standardPosition.text}}
                            </span>

                        </div>
                    </div>

                    <div *ngFor="let position of clause.positions; let i = index" class="position premium-position">

                        <div *ngIf="position?.columns">

                            <span class="position-title">Position {{i + 1}}</span>


                            <div class="position-group">
                                <div *ngFor="let column of position?.columns; let j = index"
                                    class="container premium-container">

                                    <div class="title-bar">
                                        <span class="position-title">{{column.name}}</span>

                                        <div class="commands">
                                            <app-icon *ngIf="selectedClause" [icon]="'list-circle-outline'"
                                                (click)="copyToClauseBullet(column.text)"
                                                [tooltip]="'Add to clause bullets'" [disabled]="!column.insertText">
                                            </app-icon>

                                            <app-icon *ngIf="selectedClause" [icon]="'chatbubble-ellipses-outline'"
                                                (click)="copyToComments(column.text)" [tooltip]="'Add to comments'"
                                                [disabled]="!column.insertComment">
                                            </app-icon>

                                            <app-icon [icon]="'clipboard-outline'"
                                                (click)="copyToClipboard(column.text)" [disabled]="!column.clipboard"
                                                [tooltip]="'Copy to clipboard'">
                                            </app-icon>
                                        </div>
                                    </div>


                                    <span class="position-text">
                                        {{column.text}}
                                    </span>
                                </div>

                            </div>

                        </div>

                    </div>

                </app-expandable-card>

                <div>
                    <hr class="separator" />
                </div>

            </div>
        </div>

    </div>
</div>

<div *ngIf="isLoading" class="loader">
    <app-loading-spinner visible="true" type="pale"></app-loading-spinner>
</div>