<div class="smz-flex-area">
    <div class="app-header container-fluid">
        <div class="row align-items-center mx-0 my-0">
            <div class="col-2 px-0">
                <div class="fade-block logo align-self-center">
                    <img src="assets/logos/summize-logo.svg" />
                </div>
            </div>
            <ng-container *ngIf="user !== undefined">
                <div class="col block align-self-end align-self-center">
                    <div class="block actions my-auto float-right">
                        <button *ngIf="!isAuthenticated" mat-raised-button color="primary" (click)="login()">Login
                        </button>
                        <button *ngIf="isAuthenticated" mat-button [matMenuTriggerFor]="menu">
                            {{ user }}
                            <mat-icon>arrow_drop_down</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="logout()">Logout
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>