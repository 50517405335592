import { Component, Input } from '@angular/core';

import { StatusPillStatus } from '@summize/shared/framework';

@Component({
    selector: 'app-status-pill',
    templateUrl: 'status-pill.html',
    styleUrls: ['./status-pill.scss']
})
export class StatusPillComponent {

    @Input()
    public status: StatusPillStatus;

}