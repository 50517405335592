import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OverlayModule as CdkOverlayModule } from '@angular/cdk/overlay';
import { BlockButtonModule } from '@summize/shared/components-v2';

import { OverlayComponent } from './overlay.component';

@NgModule({
    imports: [
        CommonModule,
        CdkOverlayModule,
        BlockButtonModule
    ],
    exports: [
        OverlayComponent
    ],
    declarations: [
        OverlayComponent
    ]
})
export class OverlayModule { }

export * from './overlay.component'