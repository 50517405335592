import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseTenantService } from '@summize/shared/core';

export interface QuestionSetQuestion {
    questionId: string;
    quickSummaryQuestionSetId: string;
    description: string;
    questionType: number,
    typeMeta: string;
    meta: any;
    index: number;
}

export interface QuestionSetAccess {
    id: string;
    groupId: string;
    group?: { groupName: string; };
}

export interface QuestionSetNotification {
    id: string;
    outboundIntegrationId: string;
    outboundIntegration?: { displayName: string; };
}

export interface QuestionSet {
    id: string;
    displayName: string;
    description: string;
    isDisabled: boolean;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date,
    updatedBy: string;
    isDeleted: boolean;
    deletedAt: Date,
    deletedBy: string;
    accesses: Array<QuestionSetAccess>;
    notifications: Array<QuestionSetNotification>;
    questions: Array<QuestionSetQuestion>;
    assignTo: string;

    isRestrictedAccess?: boolean;
}

@Injectable({ providedIn: 'root' })
export class QuestionSetSelectionService extends BaseTenantService {

    constructor(private httpClient: HttpClient) { super(); }

    public async getQuestionSets(): Promise<Array<QuestionSet>> {

        return this.httpClient
            .get<Array<any>>(`${this.getUserBaseUrl(true)}quicksummaries/questionsets/me?includeQuestionBuilderTemplates=true`)
            .toPromise()
            .then(x => x.map(y => {

                for (const q of y.questions) {
                    q.meta = q.typeMeta !== undefined && q.typeMeta !== '' ? JSON.parse(q.typeMeta) : undefined;
                }

                return {
                    ...y,
                    id: y.id,
                    displayName: y.displayName,
                    description: y.description,
                    questions: y.questions,
                };
            }));

    }

}