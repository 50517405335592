import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { SummizeStorage } from '@summize/shared/framework';

import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage.service';
import { SessionUser } from '../models/sessionuser.model';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthUserService implements OnInit, OnDestroy {
  public user = new BehaviorSubject<SessionUser>(null);
  private isAuthenticatedSub: Subscription;
  private isGettingUserDetails = false;

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService) {

    this.isAuthenticatedSub = this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.getUserDetails();
      } else {
        this.purgeUserDetails();
      }
    });
  }

  ngOnInit() {
    this.loadUser();
  }

  ngOnDestroy() {
    this.isAuthenticatedSub.unsubscribe();
  }

  public purgeUserDetails() {
    this.saveUser(null);
  }

  loadUser() {
    const cachedUser = JSON.parse(SummizeStorage.getLocalItem('user'));
    if (cachedUser) {
      this.user.next(cachedUser);
    }
  }

  saveUser(user: SessionUser) {
    if (user === null) {
      SummizeStorage.removeLocalItem('user');
    } else {
      SummizeStorage.setLocalItem('user', JSON.stringify(user));
    }

    this.user.next(user);
  }

  getIdentityUrl() {
    return environment.auth.oidc.authority;
  }

  async getUserDetails() {
    if (this.isGettingUserDetails) {
      return;
    }

    this.isGettingUserDetails = true;

    return Promise.all([
      this.httpClient.get<any>(`${environment.apiUrl}/admin/api/1.0/session`).toPromise()
    ])
      .then((results: any) => {
        const sessionResponse = results[0];
        const authResponse = results[1];
        const user: SessionUser = {
          email: sessionResponse.user.email,
          firstName: sessionResponse.user.firstName,
          lastName: sessionResponse.user.lastName,
          userId: sessionResponse.user.userId,
          featureFlags: [...sessionResponse.user.featureFlags, ...(environment.featureFlags || [])],
          tenantName: '',
          apiBaseUrl: '',
          isSummizeAuthentication: sessionResponse.user.isSummizeAuthentication
        };
        this.saveUser(user);
        this.localStorageService.setJson('isSummizeAuthentication', user.isSummizeAuthentication);
        this.isGettingUserDetails = false;
        return user;
      })
      .catch(err => {
        console.log(err);
        this.localStorageService.removeItem('isSummizeAuthentication');
        this.isGettingUserDetails = false;
        throw err;
      });
  }
}
