<div class="menu" [class.expanded]="expanded === true" [class.collapsed]="expanded === false">

    <div class="segment first">
        <div class="segment-header">
            Dashboards
        </div>
        <a routerLink="/dashboard/clm" routerLinkActive="active" class="segment-item">
            <app-icon icon="stats-chart-outline"></app-icon> CLM Usage
        </a>
    </div>

    <div class="segment first">
        <div class="segment-header">
            Entities
        </div>
        <a routerLink="/tenants" routerLinkActive="active" class="segment-item">
            <app-icon icon="person-outline"></app-icon> Tenants
        </a>
    </div>

    <div class="segment first">
        <div class="segment-header">
            Users
        </div>

        <a routerLink="/tenantusers" routerLinkActive="active" class="segment-item">
            <app-icon icon="people-outline"></app-icon> Tenant Users
        </a>

        <a routerLink="/oktausers" routerLinkActive="active" class="segment-item">
            <app-icon icon="people-outline"></app-icon> Okta Users
        </a>

        <a routerLink="/identityusers" routerLinkActive="active" class="segment-item">
            <app-icon icon="people-outline"></app-icon> Identity Users
        </a>
    </div>

    <div class="segment">
        <div class="segment-header">
            Management
        </div>

        <a routerLink="/tenantsvisibility" routerLinkActive="active" class="segment-item">
            <app-icon icon="lock-closed-outline"></app-icon>Admin Users
        </a>
    </div>

    <div class="segment">
        <div class="segment-header">
            Activity
        </div>

        <a routerLink="/auditevents" routerLinkActive="active" class="segment-item">
            <app-icon icon="code-working-outline"></app-icon>Audits
        </a>

        <a routerLink="/activityevents" routerLinkActive="active" class="segment-item">
            <app-icon icon="swap-horizontal-outline"></app-icon>Activity
        </a>

        <a routerLink="/usage" routerLinkActive="active" class="segment-item">
            <app-icon icon="analytics-outline"></app-icon>Usage
        </a>
    </div>

    <div class="segment">
        <div class="segment-header">
            Issues
        </div>

        <a routerLink="/exceptions" routerLinkActive="active" class="segment-item">
            <app-icon icon="bug-outline"></app-icon>Errors
        </a>

        <a routerLink="/logs" routerLinkActive="active" class="segment-item">
            <app-icon icon="code-slash-outline"></app-icon>Logs
        </a>

        <a routerLink="/documents" routerLinkActive="active" class="segment-item">
            <app-icon icon="document-text-outline"></app-icon>Documents
        </a>

    </div>

    <div *appFeatureFlag="'adminUIDeveloper'" class="segment">
        <div class="segment-header">
            Developer
        </div>

        <a routerLink="/developer" routerLinkActive="active" class="segment-item">
            <app-icon icon="shield-outline"></app-icon>Developer
        </a>

    </div>

    <div (click)="toggle()" class="control clickable">
        <app-icon *ngIf="expanded === false" class="collapse" icon="chevron-forward-circle-outline">
        </app-icon>
        <app-icon *ngIf="expanded === true" class="expand" icon="chevron-back-circle-outline">
        </app-icon>
    </div>

</div>