<div [class.compact]="compact === true" class="task-card flex-column type-{{cardStyle}}">

    <div *ngIf="showDocumentName === true" (click)="goToDocument()" class="document-name clickable">
        <span *ngIf="model.requestSubType !== RequestSubType.Manage">{{model.requestName}}</span>
        <span *ngIf="model.requestSubType === RequestSubType.Manage">{{model.documentName}}</span>
    </div>

    <div class="title flex-row flex-space-between">
        <div class="flex-row flex-align-center" data-cy="task-title">
            {{model.task.taskTitle}}
        </div>
        <div *ngIf="instanceCommands?.length > 0">
            <app-ellipses-menu [commands]="instanceCommands" />
        </div>
    </div>
    <div class="description">{{model.task.taskDescription}}</div>
    <div [class.flex-column]="compact === true" class="parts flex-row">
        <div class="part flex-row">
            <app-icon icon="checkmark-circle-outline"></app-icon>
            <div class="text">{{getTypeString()}} <span *ngIf="model.task.isMandatory === true">(required)</span></div>
        </div>

        <ng-container *ngIf="isComplete === false">
            <div class="part flex-row">
                <app-icon icon="person-add-outline"></app-icon>
                <div [matTooltip]="getAssignedToString(true)" class="text">{{getAssignedToString()}}</div>
            </div>
        </ng-container>

        <ng-container *ngIf="model.task.taskType !== TaskType.System && isComplete === true">
            <div class="part flex-row">
                <app-icon icon="person-add-outline"></app-icon>
                <div class="text">Completed on {{model.task.completedAt | smzdate:'mediumDate'}} by
                    {{model.completeByUserName}}</div>
            </div>
        </ng-container>

    </div>

    <ng-container *ngIf="mode === 'Definition' && model.conditions && model.conditions.length > 0 ">
        <div class="condition-info flex-row flex-align-vcenter">
            <app-icon cdkOverlayOrigin #trigger="cdkOverlayOrigin" (mouseenter)="overlayOpen = true"
                (mouseleave)="overlayOpen = false" icon="alert-circle-outline"></app-icon>
            <div class="text">
                This task has x{{model.conditions?.length}} condition(s)
            </div>
        </div>

        <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="overlayOpen">
            <div class="selected-conditional-items">
                <div *ngFor="let condition of model.conditions; let first = first; let last = last;"
                    [class.mb-3]="first === true || last === false" class="conditional">
                    {{first === true? 'IF' : 'AND'}} the answer to question "<span
                        class="weight-light">{{condition.questionText}}</span>" {{condition.operatorText}}
                    <span
                        *ngFor="let part of formatTaskCondition(condition.valueDisplay || condition.value); let last = last"
                        class="weight-light">{{part}} <strong *ngIf="last === false" class="weight-heavy">OR</strong>
                    </span>
                </div>
            </div>
        </ng-template>

    </ng-container>

    <div *ngIf="isComplete === false" class="mt-3 flex-row flex-wrap gap-4">
        <ng-container *ngIf="displayCommands === true || canCompleteTask === true">
            <ng-container *ngIf="stage !== RequestStage.Abandoned">
                <div *ngIf="mode === 'Instance' && canComplete === true && isComplete === false"
                    matTooltip="Required tasks cannot be completed, edited or deleted during an active edit session"
                    [matTooltipDisabled]="isCompleteDisabled !== true" matTooltipClass="smz-tooltip-light"
                    [ngStyle]="{'cursor': isCompleteDisabled === true ? 'not-allowed' : 'default'}" class="complete">
                    <app-block-button (click)="complete()" [disabled]="isCompleteDisabled === true" display="Complete"
                        width="140" icon="checkmark-circle-outline" type="v2-blue"></app-block-button>
                </div>
            </ng-container>
        </ng-container>

        <app-due-date [dueDate]="dueDate" [dueInDays]="dueInDays" [schedule]="schedule"
            [style]="dueDateStyle"></app-due-date>
    </div>

    <app-task-notes *ngIf="isNotesAvailable" [taskInstanceId]="model.task.taskInstanceId"
        [documentId]="model.documentId" [taskNotes]="model.notes" [taskComplete]="isComplete" 
        [completionNote]="model.task.completionNotes" (notesUpdated)="onNotesUpdated($event)"></app-task-notes>

</div>