<app-dialog-shell title="Change status" saveText="Change status" [saveDisabled]="selected === undefined"
    [saveButtonWidth]="160" [showHeader]="true" [showFooter]="true" (onClose)="close()" (onSave)="save()">
    <div class="section-area">
        <div class="description">
            Select the status you want to change the selected contract(s) to, then click 'Change status' to complete the
            action.
        </div>
        <div class="select">
            <div class="title">
                Status
            </div>
            <app-select-box (changed)="selected = $event" [options]="statuses" placeholder="Select">
            </app-select-box>
        </div>
    </div>
</app-dialog-shell>