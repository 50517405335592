import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SelectBoxModule } from '@summize/shared/components-v2';

import { CustomFieldValueEditorComponent } from './custom-field-value-editor.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SelectBoxModule
    ],
    exports: [
        CustomFieldValueEditorComponent
    ],
    declarations: [
        CustomFieldValueEditorComponent
    ]
})
export class CustomFieldValueEditorModule { }

export * from './custom-field-value-editor.component'