<app-context-bar [primaryText]="getPrimaryText()" [secondaryText]="getSecondaryText()" [hasBackButton]="true"
    (onBack)="goBack()">
</app-context-bar>

<div class="table-container scroll">
    <app-table *ngIf="datasource" [datasource]="datasource"></app-table>
</div>

<div [attr.type]="'avatar'">
    <ng-template #nameTemplate let-row>
        <app-avatar bgColor="#0066FF" size="32" class="avatar" [name]="row.userName">
        </app-avatar>
    </ng-template>
</div>
