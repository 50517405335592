<div>
    <div *ngIf="isLoading === false" class="results-count">
        <span
            *ngIf="widgetChartElement?.totalRecords !== undefined && widgetChartElement?.totalRecords !== null">{{widgetChartElement?.totalRecords}}
            {{widgetChartElement?.totalRecords === 1 ? 'Result' : 'Results'}}
        </span>
    </div>
    <div *ngIf="widgetChartElement?.totalRecords === 0 || widgetChartElement?.data?.datasets?.length === 0"
        class="flex-align-center no-data">No results, try
        adjusting the
        chart's filters</div>

    <div *ngIf="isError === true" class="flex-align-center no-data">There was a problem getting data for this chart, try
        refreshing</div>

    <div *ngIf="widgetChartElement?.kpis?.length > 0" class="kpi-container">
        <app-kpi *ngFor="let kpi of widgetChartElement?.kpis" [tileColor]="kpi.primaryAccentColor"
            [topLabel]="kpi.label" [bottomLabel]="kpi.displayValue"></app-kpi>
    </div>

    <div #chartContainer class="chart-container"
        [ngClass]="{'no-results': widgetChartElement?.totalRecords === 0 || isError === true }">
        <div *ngIf="isLoading === true" class="loading-container">
            <app-loading-spinner visible="true"></app-loading-spinner>
        </div>
    </div>
</div>