import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectModel } from '@summize/shared/components-v2';
import { RequestSubtype } from '@summize/shared/framework';

import { DownloadDocumentService, ReviewDocumentVersion } from './download-documents.service';
import { DownloadHelper } from '../download.helper';

@Component({
    selector: 'app-download-documents',
    templateUrl: 'download-documents.html',
    styleUrls: ['./download-documents.scss']
})
export class DownloadDocumentsComponent implements OnInit {

    public isInbox = false;

    public isLoading: boolean = true;

    public documentId: string;
    public selectedDocumentId: string;

    public versions: Array<ReviewDocumentVersion>;
    public selectVersions: Array<SelectModel>;

    public model: any;

    public title: any;

    public description: any;

    public linkedContractType = [
        { key: '1', value: 'Variation' },
        { key: '2', value: 'Addendum' },
        { key: '3', value: 'Novation' },
        { key: '4', value: 'SOW' },
        { key: '5', value: 'OrderForm' }
    ];

    public get hasSelection() {

        if (this.isInbox) {

            return this.model !== undefined && (this.model?.checked === true || this.model?.some(d => d.checked === true));

        } else {

            return this.model !== undefined && (this.model.checked === true || this.model?.linkedDocuments.some(d => d.checked === true));

        }

    }

    constructor(
        private dialogRef: MatDialogRef<DownloadDocumentsComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private downloadService: DownloadDocumentService,
        private downloadHelper: DownloadHelper) {

        this.documentId = data?.documentId;

        this.title = data?.title;

        this.description = data?.description;

        this.isInbox = data?.isInbox ?? false;

        if (this.isInbox === true) {
            this.model = data?.envelope?.receivedDocuments;
        }

    }

    public async ngOnInit(): Promise<void> {

        this.isLoading = true;

        if (!this.isInbox) {

            this.versions = await this.downloadService.getDocumentVersions(this.documentId);

            this.selectVersions = this.versions.map(x => ({
                key: x.document.documentId,
                value: `Version ${x.version}${x.version === this.versions.length ? ' (current)' : ''}`,
            }));

            if (this.selectVersions.length > 0) {

                this.selectedDocumentId = this.documentId;

                this.selectVersion(this.selectVersions.find(x => x.key === this.documentId)?.key);

            }

        }

        this.isLoading = false;

    }

    public selectVersion(documentId: string) {

        if (documentId === undefined) {
            return;
        }

        const version = this.versions.find(x => x.document.documentId === documentId);

        if (version === undefined) {
            return;
        }

        this.model = version.document;

        this.model.checked = true;

        if (this.model.linkedDocuments !== undefined) {

            for (const linked of this.model.linkedDocuments) {

                linked.checked = true;
                linked.linkedDocumentType = this.linkedContractType.find(x => x.key === `${linked.documentType}`)?.value || '';

            }
        }
    }

    public close() {

        this.dialogRef.close(undefined);

    }

    public async save() {

        if (this.isInbox) {

            this.model.forEach(document => {

                if (document.checked === true) {

                    this.downloadFile(document.documentName, document.originalFile.accessToken);

                }

            });

        } else {

            if (this.model.checked === true) {

                if (this.model.requestSubType === RequestSubtype.Create) {

                    const url = await this.downloadService.getDownloadLinkForCreated(this.model.documentId);

                    this.downloadFile(this.model.documentName, url);

                } else {

                    const downloadUrl: any = await this.downloadService.getDocumentFileUrl(this.model.documentId, '1');

                    this.downloadFile(this.model.documentName, downloadUrl.accessToken);
                }

            }

            if (this.model.requestSubType !== RequestSubtype.Create) {

                let documentIndex = 2;

                for (const linked of this.model.linkedDocuments || []) {

                    if (linked.checked === true) {

                        const downloadUrl: any = await this.downloadService.getDocumentFileUrl(this.model.documentId, `${documentIndex}`);

                        this.downloadFile(linked.documentName, downloadUrl.accessToken);

                    }

                    ++documentIndex;

                }
            }

        }

        this.close();

    }

    private downloadFile(name: string, location: string) {

        // RichS: Use the extension from the actual file url if it doesn't match the name
        let locationExtension = undefined;

        let locationQueryParamsIndex = location.indexOf('?');

        if (locationQueryParamsIndex >= 0) {

            const tmpUrl = new URL(location.substring(0, locationQueryParamsIndex));

            let lastDotIndex = tmpUrl.pathname.lastIndexOf('.');

            if (lastDotIndex >= 0) {

                locationExtension = tmpUrl.pathname.substring(lastDotIndex);

                if (locationExtension.length > 0 && !name.toLowerCase().endsWith(locationExtension.toLowerCase())) {

                    name += locationExtension;

                }

            }

        }

        fetch(location)
            .then(resp => resp.blob())
            .then(blob => this.downloadHelper.downloadBlob(location, name, blob));

    }
}
