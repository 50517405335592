import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService, IsValidString } from '@summize/shared/core';

import { PlatformAttachments, PlatformSuggestion } from './platform.types';
import { PlatformAttachmentType } from '@summize/shared/framework';

@Injectable({ providedIn: 'root' })
export class JiraService extends BaseTenantService {

    public get fileText() {

        return 'Files from Jira';

    }

    constructor(private http: HttpClient) {

        super();

    }

    public async getAttachmentState(): Promise<PlatformAttachments> {

        const params = new URLSearchParams(window.location.search);

        const connectorRef = params.get('connectorRef');

        if (IsValidString(connectorRef) === true) {

            const url = `${this.getUserBaseUrl(true)}platform/jira/${connectorRef}/attachments`;

            const results = await this.asPromise(this.http.get(url))

            if (results !== undefined && results !== null) {

                const items = results.map(d => ({ ...d, fileName: d.name.toLowerCase(), smzType: PlatformAttachmentType.Jira }));

                return {
                    emailAttachments: items.filter(x => x.fileName.endsWith('.pdf') || x.fileName.endsWith('.docx')),
                    allAttachments: items,
                    context: {}
                };

            }

        } else {

            return Promise.resolve({
                emailAttachments: undefined,
                allAttachments: undefined,
                context: undefined
            });

        }

    }

    public handleEvent(): void { }
    public showRequestModal(): void { }
    public isComposeMode(): boolean { return false }
    public addFileAttachment() { }
    public openInBrowser() { }
    public getPlatformSuggestions(): Promise<Array<PlatformSuggestion>> { return Promise.resolve(undefined) }
    public getEmailId(): string { return undefined; }
    public contextChanged(): void { }
    public async getEmailAsAttachment(): Promise<any> { return undefined; }
    public async ready() { return Promise.resolve() };

}