import { Component, OnInit, ViewContainerRef, ViewChild, ComponentFactoryResolver } from '@angular/core';

import { trigger, state, style, transition, animate } from '@angular/animations';


import { Router, NavigationStart } from '@angular/router';
import { AppComponent } from '@summize/shared/core';
import { SummizeStorage } from '@summize/shared/framework';

import { SlidePanelSettings, CommandDefinition, SlidePanelService, } from './slide-panel.service'

export interface SlidePanelContent {
    init(parent: SlidePanelComponent): void
}

export enum State {
    Open = 'open',
    Closed = 'closed'
}

@Component({
    selector: 'app-slide-panel',
    templateUrl: 'slide-panel.html',
    styleUrls: ['slide-panel.scss'],
    animations: [
        trigger('state', [
            state(State.Open, style({
                transform: 'translate3d(0, 0, 0)'
            })),
            state(State.Closed, style({
                transform: 'translate3d(100%, 0, 0)'
            })),
            transition('* => *', animate('0.3s ease'))
        ]),
        trigger('overlay', [
            state(State.Open, style({
                display: 'block'
            })),
            state(State.Closed, style({
                display: 'none'
            }))
        ]),
        trigger('backAnimation', [
            transition(':enter', [
                style({ opacity: 0, width: 0, 'margin-right': 0 }),
                animate('0.2s', style({ opacity: 1, width: 17.5, 'margin-right': '1rem' }))
            ]),
            transition(':leave', [
                animate('0.2s', style({ opacity: 0, width: 0, 'margin-right': 0 }))
            ])
        ])
    ]
})
export class SlidePanelComponent extends AppComponent implements OnInit {

    @ViewChild('host', { static: false, read: ViewContainerRef })
    public host: ViewContainerRef;

    public state: State = State.Closed;

    public instance: any;

    public isFrameMode: boolean;

    public set settings(val) {

        if (val !== undefined && val.component !== undefined) {

            this.__frames.push(val);

            this.state = State.Open;

            setTimeout(() => {

                this.renderContent()

            }, 0);

        }

    }

    public get settings() {

        return this.__frames[this.__currentFrameIndex];

    }

    public get isRoot() {

        return this.__currentFrameIndex === 0;

    }

    private __frames: Array<SlidePanelSettings>

    private __currentFrameIndex;

    constructor(public router: Router, public resolver: ComponentFactoryResolver, public service: SlidePanelService) {

        super();

        this.__currentFrameIndex = 0;

        this.__frames = [];

        this.isFrameMode = SummizeStorage.isIsolated() || SummizeStorage.IsOutlook();

        this.subscribe(this.router.events, ((event: any) => {

            if (event instanceof NavigationStart) {

                this.destroy();

                this.reset();

            }

        }));

    }

    public ngOnInit() {

        this.subscribe(this.service.$onChange, (args => {

            this.reset();

            if (SummizeStorage.IsOutlook() === true) {

                args.backdrop = false;

                args.size = 'scale';
            }

            this.settings = args

        }));

        this.subscribe(this.service.$onShowChild, (args => {

            this.__currentFrameIndex = this.__currentFrameIndex + 1;

            this.settings = args

            if (SummizeStorage.IsOutlook() === true) {

                args.backdrop = false;

                args.size = 'scale';
            }

        }));

        this.subscribe(this.service.$onClose, () => {

            this.destroy();

            this.reset();

        });

    }

    public onBackdrop(): void {

        if (this.settings.onBackdrop !== undefined) {

            this.settings.onBackdrop();

        } else {

            this.destroy();

        }

    }

    public destroy(): void {

        this.settings = undefined;

        this.instance = undefined;

        if (this.settings?.onDestroy !== undefined) {

            this.settings?.onDestroy();

        }

        this.state = State.Closed;

    }

    public despatchClickToComponent(command: CommandDefinition): void {

        if (this.checkForValidator(command)) {

            command.onClick(this.instance, command, this);

            if (command.closeAfterClick === true) {

                this.destroy();

            }

        }
    }

    public checkForValidator(command: CommandDefinition): boolean {

        if (this.instance !== undefined && this.instance.validate !== undefined) {

            return this.instance.validate(command);

        }

        return true;

    }

    public goBack(): void {

        this.__currentFrameIndex
            = this.__currentFrameIndex - 1;

        this.renderContent(true);

        this.clearLast();

    }

    private renderContent(isGoBack: boolean = false): void {

        if (this.host !== undefined) {

            this.host.clear();

        }

        this.setupCommands();

        const context
            = this.getContext();

        const factory =
            this.resolver.resolveComponentFactory(this.settings.component);

        this.instance = this.host.createComponent(factory).instance;

        if (this.settings.arguments !== undefined) {

            Object
                .keys(this.settings.arguments)
                .forEach(k => this.instance[k] = this.settings.arguments[k]);

        }

        if (context !== undefined) {

            Object
                .keys(context)
                .forEach(k => this.instance[k] = context[k]);

            this.instance.isFromBackCommand = true;

        }

        if (this.instance.init !== undefined) {

            this.instance.init(this);

        }

        if (this.settings.init !== undefined) {

            this.settings.init(this.instance, this);

        }

    }

    private getContext(): any {

        if (this.instance !== undefined && this.instance.getContext !== undefined) {

            return this.instance.getContext();

        }


    }

    private setupCommands(): void {

        if (this.settings.commands !== undefined) {

            const hasCancel: any
                = this.settings.commands.find(c => c.text === 'Cancel' || c.text === 'Close');

            if (hasCancel !== undefined) {

                hasCancel._onClick = hasCancel.onClick;

                hasCancel.onClick = () => {

                    if (hasCancel._onClick !== undefined) {

                        hasCancel._onClick(this.instance);

                    }

                    this.destroy();

                }
            }

            if (this.__currentFrameIndex > 0) {

                const hasSave
                    = this.settings.commands.find(c => c.isSaveCommand === true);

                if (hasSave !== undefined) {

                    const oFunc = hasSave.onClick;

                    hasSave.onClick = async (instance, command) => {

                        this.__currentFrameIndex--;

                        if (oFunc !== undefined) {

                            await oFunc(instance, command);

                        }

                        this.renderContent();

                        this.clearLast();

                    }

                }

            }

        }

    }

    private reset() {

        this.__currentFrameIndex = 0;

        this.__frames = [];

    }

    private clearLast() {

        this.__frames.pop();

    }

}