import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CustomFieldDefinition, CustomFieldType } from '@summize/feature/custom-fields/types';
import { SelectBoxModel } from '@summize/shared/components-v2';

export interface CustomFieldValueModel {
    definition: CustomFieldDefinition,
    value: any;
}

@Component({
    selector: 'app-custom-field-value-editor',
    templateUrl: 'custom-field-value-editor.html',
    styleUrls: ['./custom-field-value-editor.scss']
})
export class CustomFieldValueEditorComponent implements OnInit {

    public CustomFieldType = CustomFieldType;

    @Input()
    public model: CustomFieldValueModel;

    @Input()
    public canEdit: Boolean = false;

    @Output()
    public onChange: EventEmitter<any> = new EventEmitter<any>();

    public values: Array<SelectBoxModel>;

    public boundValue: any;

    public ngOnInit() {

        if (this.model.definition.fieldType === CustomFieldType.Dropdown ||
            this.model.definition.fieldType === CustomFieldType.MultiSelect) {

            this.values = JSON.parse(this.model.definition.values).map(v => ({ key: v.id, value: v.display }));

            if (this.model.definition.fieldType === CustomFieldType.Dropdown === true) {

                this.values.unshift({
                    key: null,
                    value: null
                });

                this.boundValue = this.model.value.value;

            } else {

                this.boundValue = this.model.value.value?.split(',');

            }

        } else {

            this.boundValue = this.model.value.value;

        }

    }

    public setArrayValue(value) {

        this.boundValue = value;

        if (this.model.definition.fieldType === CustomFieldType.MultiSelect) {

            this.onChange.next(this.boundValue.join(','));

        } else {

            this.onChange.next(this.boundValue);

        }

    }

    public setNormalValue(value) {

        this.boundValue = value;

        this.onChange.next(this.boundValue);

    }
}