import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';

import { SelectMenuComponent } from './select-menu.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
    imports: [
        CommonModule,
        MatMenuModule,
        IconModule
    ],
    exports: [
        SelectMenuComponent
    ],
    declarations: [
        SelectMenuComponent
    ]
})
export class SelectMenuModule { }

export * from './select-menu.component';