import { AppComponent } from "@summize/shared/core";

import { PlatformService } from "./platform/platform.service";
import { ExternalContextChangedEvent } from "./platform/platform.types";

export class EmailAttachmentComponent extends AppComponent {

    public emailAttachments: Array<any>;

    public attachmentContext: any;

    public allAttachments: Array<any>;

    public isLoading = true;

    public get hasEmailAttachments() {

        return this.emailAttachments !== undefined && this.emailAttachments.length > 0;

    }

    constructor(public platform: PlatformService) {

        super();

    }

    public async configureAttachments(afterContextChanged = undefined) {

        const worker = async (context: ExternalContextChangedEvent = undefined) => {

            const state = await this.platform.getAttachmentContext();

            if (state !== undefined) {

                this.emailAttachments = state.emailAttachments;

                this.allAttachments = state.allAttachments;

                this.attachmentContext = state.context;

                if (afterContextChanged !== undefined) {

                    afterContextChanged(context);

                }

            }

        }

        this.subscribe(this.platform.onContextChange, async (context) => {

            await worker(context);

        });

        worker(undefined);

    }

}