const AssignationClaims = {
    CanAssignedToSelfRead: 'CanAssignedToSelfRead',
    CanAssignedToUpdate: 'CanAssignedToUpdate'
};

const AttachmentClaims = {
    CanAttachmentsDelete: 'CanAttachmentsDelete'
};

const CalendarClaims = {
    CanCalendarEventsRead: 'CanCalendarEventsRead'
};

const ConversationsClaims = {
    CanConversationCreate: 'CanConversationCreate',
    CanConversationRead: 'CanConversationRead'
};

const CustomFieldClaims = {
    CanCustomFieldCreate: 'CanCustomFieldCreate',
    CanCustomFieldDelete: 'CanCustomFieldDelete',
    CanCustomFieldEdit: 'CanCustomFieldEdit',
    CanCustomFieldValueUpdate: 'CanCustomFieldValueUpdate'
}

const DocumentClaims = {
    CanDocumentsBulkUpdateContractTypes: 'CanDocumentsBulkUpdateContractTypes',
    CanDocumentsCompare: 'CanDocumentsCompare',
    CanDocumentsCreate: 'CanDocumentsCreate',
    CanDocumentsExport: 'CanDocumentsExport',
    CanDocumentsRename: 'CanDocumentsRename',
    CanDocumentsRead: 'CanDocumentsRead',
    CanDocumentsUpdate: 'CanDocumentsUpdate'
};

const DocumentTypeClaims = {
    CanDocumentTypesUpdate: 'CanDocumentTypesUpdate'
};

const ESignatureClaims = {
    CanESignatureCreate: 'CanESignatureCreate',
    CanESignatureRead: 'CanESignatureRead'
};

const PlaybookClaims = {
    CanPlaybooksCreateBasicPlaybooks: 'CanPlaybooksCreateBasicPlaybooks',
    CanPlaybooksViewBasicPlaybooks: 'CanPlaybooksViewBasicPlaybooks'
}

const RelationshipsClaims = {
    CanRelationshipsCreate: 'CanRelationshipsCreate',
    CanRelationshipsDelete: 'CanRelationshipsDelete'
};

const RequestsClaims = {
    CanQuickSummarySelfRead: 'CanQuickSummarySelfRead'
};

const RulebookClaims = {
    CanRulebooksCreate: 'CanRulebooksCreate',
    CanRulebooksDelete: 'CanRulebooksDelete',
    CanRulebooksRead: 'CanRulebooksRead'
}

const TaskClaims = {
    CanTasksCreate: 'CanTasksCreate',
    CanTasksDelete: 'CanTasksDelete',
    CanTasksRead: 'CanTasksRead',
    CanTasksUpdate: 'CanTasksUpdate',
}

export class SummizeClaims {

    static Assignation = AssignationClaims;

    static Attachments = AttachmentClaims;

    static Calendar = CalendarClaims;

    static CustomFields = CustomFieldClaims;

    static Conversations = ConversationsClaims;

    static Documents = DocumentClaims;

    static DocumentTypes = DocumentTypeClaims;

    static ESignature = ESignatureClaims;

    static Relationships = RelationshipsClaims;

    static Requests = RequestsClaims;

    static Rulebooks = RulebookClaims;

    static Tasks = TaskClaims;

    static Playbooks = PlaybookClaims;

}