<div [@fadeInAnimation] class="expandable-area" [ngClass]="{'expanded': expanded, 'inline':subtitle === undefined}">
    <div class="expandable-header fade-block">
        <span class="header">{{title}}
            <app-info-point *ngIf="tooltip !== undefined" [text]="tooltip"></app-info-point>
        </span>
        <small>
            {{subtitle}}
        </small>
        <span *ngIf="expanded" class="body__verysmall clickable" (click)="expanded = !expanded">
            Show less
        </span>
        <span *ngIf="!expanded" class="body__verysmall clickable" (click)="expanded = !expanded">
            Show more
        </span>
        <div [@fadeInAnimation]="headerTemplate" *ngIf="headerTemplate !== undefined">
            <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
        </div>
    </div>
    <div [@fadeInAnimation]="expanded" *ngIf="expanded" class="expandable-content">
        <ng-content class="fade-block"></ng-content>
    </div>
</div>