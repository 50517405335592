import { Component, Inject, OnInit, ViewChild } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChangeDetectionHelper } from '@summize/shared/core';
import { RequestSubtype, SummizeStorage } from '@summize/shared/framework';

import { ConversationInputComponent } from '../conversation-input/conversation-input.component';

@Component({
    selector: 'app-create-conversation',
    templateUrl: 'create-conversation.html',
    styleUrls: ['./create-conversation.scss']
})
export class CreateConversationComponent implements OnInit {

    public RequestSubType = RequestSubtype;

    @ViewChild(ConversationInputComponent, { static: true })
    public inputComponent: ConversationInputComponent;

    public subject: string;

    public users: any;

    public quotedText: string;

    public contractId: string;

    public requestSubType: RequestSubtype;

    public isWordAddIn: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<CreateConversationComponent>
    ) {
        
        this.users = data.users;

        this.subject = data.subject;

        this.quotedText = data.quotedText;

        this.contractId = data.contractId;

        this.requestSubType = data.requestSubType;
    }

    public ngOnInit(): void {

        this.isWordAddIn = SummizeStorage.IsWordAddIn();

        if (this.quotedText !== undefined) {

            ChangeDetectionHelper.doNextCycle(() => {

                const text = `${this.quotedText.replace(/(\r\n|\n|\r)/gm, " ")}`;

                this.inputComponent?.setContent(text, 'blockquote');

            }, 200);

        }
    }

    public close() {

        this.dialogRef.close(undefined);

    }

    public save() {

        const result = {
            subject: this.subject,
            message: this.inputComponent.getMessage()
        };

        this.dialogRef.close(result);

    }

    public isSaveDisabled() {

        const current = this.inputComponent?.getMessage();

        return this.subject == undefined
            || this.subject === ''
            || current === undefined
            || current.text == undefined
            || current.text === '';

    }
}