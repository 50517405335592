import { ConnectorIntegrationTypes, ESignatureAutoSendModel, TaskDefinitionModel } from "@summize/shared/framework";
import { QuestionDefinitionType } from '@summize/shared/core';

export interface QuestionSetQuestion {
    questionId: string;
    quickSummaryQuestionSetId: string;
    description: string;
    questionType: number,
    typeMeta: string;
    index: number;
    fieldMappings?: string;
    mappings?: Array<{ connector: ConnectorIntegrationTypes, value: string }>;
}

export interface QuestionSetAccess {
    id: string;
    groupId: string;
    group?: { groupName: string };
}

export interface QuestionSetNotification {
    id: string;
    notificationEntityType: number;
    notificationEntityId: string;
    displayName?: string;
}

export interface QuestionSet {
    contractType?: string;
    id: string;
    requestTemplateType?: number;
    displayName: string;
    details?: string;
    description: string;
    isDisabled: boolean;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date,
    updatedBy: string;
    isDeleted: boolean;
    deletedAt: Date,
    deletedBy: string;
    accesses: Array<QuestionSetAccess>;
    notifications: Array<QuestionSetNotification>;
    questions: Array<QuestionSetQuestion>;
    assignTo: string;
    tasks?: Array<TaskDefinitionModel>;
    isRestrictedAccess?: boolean;
    requestType?: number;
    eSignatureAutoSendDetail?: ESignatureAutoSendModel;
    templatePermissions?: Array<any>;
    isTemplateQuestionFieldMappingEnabled?: boolean;
}

export enum TemplateQuestionType {
    Text = 1,
    Date,
    Number,
    Company,
    Option,
    Guidance
}

export const ConditionalTaskListOperators = [
    { key: 10, value: 'Equals' },
    { key: 11, value: 'Does not equal' }
];

export const ConditionalTaskDateOperators = [
    { key: 30, value: 'Is less than X days away' },
    { key: 31, value: 'Is greater than X days away' }
];

export const ConditionalTaskStringOperators = [
    { key: 50, value: 'Equals' },
    { key: 51, value: 'Does not equal' },
    { key: 52, value: 'Starts with' },
    { key: 53, value: 'Ends with' },
    { key: 54, value: 'Contains' },
    { key: 55, value: 'Does not contain' }
];

export const ConditionalTaskNumericOperators = [
    { key: 70, value: 'Equals' },
    { key: 71, value: 'Does not equal' },
    { key: 72, value: 'Is greater than' },
    { key: 73, value: 'Is greater than or equal to' },
    { key: 74, value: 'Is less than' },
    { key: 75, value: 'Is less than or equal to' }
];

export interface QuestionDefinition {
    fieldType: QuestionDefinitionType;
    values?: any;
    description?: string;
    longDescription?: string;
}