import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EditableTextAndSelectComponent } from './editable-text-and-select.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { IconModule } from '../icon/icon.module';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  imports: [
    CommonModule,
    IconModule,
    ReactiveFormsModule,
    MatAutocompleteModule
  ],
  exports: [
    EditableTextAndSelectComponent
  ],
  declarations: [
    EditableTextAndSelectComponent
  ]
})
export class EditableTextAndSelectModule { }

export * from './editable-text-and-select.component';
