<div class="esignature-email-recipients">

    <div *ngFor="let recipient of recipients; let i = index" class="section">
        <div class="type">
            <div class="header">
                <div *ngIf="recipientType === recipientTypeEnum.Internal" class="field required">
                    Internal recipient email address ({{i+1}})
                </div>
                <div *ngIf="recipientType === recipientTypeEnum.Counterparty" class="field required">
                    Counterparty recipient email address ({{i+1}})
                </div>
                <div *ngIf="showCheckmark === true" class="completed-icon"
                    [class.complete]="recipient.email?.length > 1 && (recipient.emailInvalid === undefined || recipient.emailInvalid === false) && (recipient.emailExists === undefined || recipient.emailExists === false)">
                    <app-icon icon="checkmark-circle"></app-icon>
                </div>
            </div>
            <div class="control">
                <input [(ngModel)]="recipient.email" name="email{{i}}" type="email" class="smz-standard-input v2"
                    maxlength="200" (keyup)="onKeyUp(recipient)" (blur)="onKeyUp(recipient)" />
                <app-icon *ngIf="i > 0" (click)="removeRecipient(i)" icon="trash-outline"
                    class="remove-button clickable"></app-icon>
            </div>
            <div *ngIf="recipientType === 1" class="hint-container">
                <p class="text">This should be the email address of an internal signer who is required to sign.</p>
            </div>
            <div *ngIf="recipient?.emailInvalid === true" class="hint-container">
                <p class="text error">Enter a valid email.</p>
            </div>
            <div *ngIf="recipient?.emailExists === true" class="hint-container">
                <p class="text error">Email already entered.</p>
            </div>
        </div>
    </div>

    <div *ngIf="allowMultiple === true" (click)="addRecipient()"
        class="flex-row flex-align-vcenter inline-button clickable">
        <app-icon icon="add-circle-outline" class="button-text add-button"></app-icon>
        <span *ngIf="recipientType === recipientTypeEnum.Internal">Add another internal recipient</span>
        <span *ngIf="recipientType === recipientTypeEnum.Counterparty">Add another counterparty recipient</span>
    </div>

</div>