import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { AppComponent, EMPTY_GUID } from '@summize/shared/core';

import { AssignedToService } from './assignto-select.service';

@Component({
    selector: 'app-assignto-select',
    templateUrl: 'assignto-select.html',
    styleUrls: ['./assignto-select.scss']
})

export class AssigntoSelectComponent extends AppComponent implements OnInit {

    @Input()
    public selectedUserId: string;

    @Input()
    public docIds: Array<string> = [];

    @Input()
    public clientId: string = EMPTY_GUID;

    @Input()
    public matterId: string = EMPTY_GUID;

    @Input()
    public menuPositionX: 'before' | 'after' = 'before';

    @Input()
    public menuPositionY: 'above' | 'below' = 'below';

    @Input()
    public isDisabled = false;

    @Output()
    public onUpdated: EventEmitter<any> = new EventEmitter();

    public searchValueControl = new FormControl();

    public users: any = [];

    public searchValue: string = '';

    public loading: boolean = false;

    public get selectedString() {

        if (this.selectedUserId === undefined) {

            return 'Change owner';

        } else {

            if (this.users !== undefined) {

                const match = this.users.find(u => u.id === this.selectedUserId);

                if (match !== undefined) {

                    return `${match.firstName} ${match.lastName}`;

                }

            }

            if (this.selectedUserId === EMPTY_GUID) {

                return 'No owner';
            }

            return this.selectedUserId;
        }
    }


    constructor(private assignedToService: AssignedToService) { super(); }

    public async ngOnInit() {

        if (this.selectedUserId !== undefined) {

            await this.loadUsers();
        }

        this.searchValueControl.valueChanges.pipe(
            debounceTime(250),
            distinctUntilChanged()
        ).subscribe(async (search) => {

            this.searchValue = search;

            this.loadUsers()

        });

    }

    public async initSearch() {

        this.loading = true;

        if (this.searchValueControl.value === '') {

            await this.loadUsers();

        }

        this.searchValueControl.setValue('');

    }

    public async loadUsers() {

        this.loading = true;

        this.users = await this.assignedToService.getAssignableUsers(this.clientId, this.matterId, this.searchValue);

        this.loading = false;

    }

    public async assignDocuments(userId: string, docIds: Array<string>) {

        if (docIds.length > 0) {

            await this.assignedToService.assignDocuments(userId, docIds);

            this.onUpdated.emit();

        }

    }

    public async unassignDocuments(docIds: Array<string>) {

        if (docIds.length > 0) {

            await this.assignedToService.unassignDocuments(docIds);

            this.onUpdated.emit();

        }

    }

}