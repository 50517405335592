import { IconModule } from './../icon/icon.module';
import { MatChipsModule } from '@angular/material/chips';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AutocompleteChipsComponent } from './autocomplete-chips.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatChipsModule,
        IconModule,
    ],
    exports: [
        AutocompleteChipsComponent
    ],
    declarations: [
        AutocompleteChipsComponent
    ]
})
export class AutocompleteChipsModule { }

export * from './autocomplete-chips.component'