import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';

import { PDFOnClickArguments } from './pdf-viewer.component';

export class PDFViewerHelper {

    public static linkedDocPageIndexes = [];
    public static docList = [];
    public static totalPages = 0;
    private static readonly pointsDiff = 0.002;


    public static async resetHelper() {
        this.linkedDocPageIndexes = [];
        this.docList = [];
        this.totalPages = 0;
    }

    public static async fetchBlob(source: any): Promise<Blob> {

        if (source === undefined) {

            return;

        }

        let delay = (time, value = true) => new Promise(resolve => setTimeout(() => resolve(value), time));

        // Sometime the document isn't quite in the right place (funcs haven't moved it to the correct storage area).
        for (let i = 0; i < 15; ++i) {
            const fetchedDoc = await fetch(source.accessToken);

            if (fetchedDoc?.ok === true) {
                const fetchDocArrayBuffer = await fetchedDoc.arrayBuffer();
                return new Blob([fetchDocArrayBuffer]);
            }

            await delay(2000);
        }

        const blankPage = await PDFDocument.create();
        const helveticaFont = await blankPage.embedFont(StandardFonts.Helvetica);
        const page = blankPage.addPage();
        const { width, height,  } = page.getSize();

        const fontSize = 16;

        page.drawText(`We were unable to load the preview for "${source.documentName}".\nRefresh the page to try loading the preview again. If this does not work please contact support@summize.com.`, {
            x: 40,
            y: height - 4 * fontSize,
            size: fontSize,
            font: helveticaFont,
            maxWidth: width,
            color: rgb(0.266, 0.149, 0.776),
        });

        // Need a pdf filetype to load it.
        source.documentName = 'LoadIssue.pdf';

        return new Blob([(await blankPage.save()).buffer]);
    }

    public static async createSeparatorPage(source: any): Promise<Blob> {

        const fillerPage = await PDFDocument.create();

        const helveticaFont = await fillerPage.embedFont(StandardFonts.Helvetica);

        const page = fillerPage.addPage();

        const { height } = page.getSize();

        const fontSize = 22;

        page.drawText(`${source.documentType} (${source.documentName})`, {
            x: 40,
            y: height - 4 * fontSize,
            size: fontSize,
            font: helveticaFont,
            color: rgb(0.266, 0.149, 0.776),
        });

        this.totalPages++;

        return new Blob([(await fillerPage.save()).buffer]);
    }

    public static FindMatchingParagraphForBullet(term: PDFOnClickArguments): Element | undefined | any {

        let quads = term.quads[term.page];
        const pages = Object.keys(term.quads);

        // Might be a page numbering issue
        if(quads === undefined && term.quads !== undefined){

            if(pages !== undefined){
                quads = term.quads[pages[0]];
            }
        }

        if (quads === undefined) {
            throw new Error(`Invalid Quads for ${JSON.stringify(term)}`);
        }
        
        // Return the highest point of the selected text
        const quad = quads.reduce((prev, curr) => { return prev.y1 < curr.y1 ? prev : curr });

        Object.keys(quad).forEach((key) => { quad[key] = quad[key] / 72 });

        // Find the doc that contains this page
        const docIndex = this.linkedDocPageIndexes.findIndex(pageCount => term.page <= pageCount);

        if (docIndex === -1) {
            throw new Error(`Invalid Page ${term.page} for docIndexes ${JSON.stringify(this.linkedDocPageIndexes)}`);
        }

        const actualPageNumber = term.page - (docIndex === 0 ? 0 : this.calculateDocumentStart(docIndex));

        // The para selection works on finding the bounding box with the correct top left coordinate (point) within it.
        // Because the point goes to 5 dp a slight variation in the calculations can throw it out meaning the para above can be highlighted incorrectly.
        // To prevent this we're adding .002 to the point coords which shifts the top left corner down and right slightly but not enough to cause issues
        // if text at the end of a para is selected.


        return {
            points: [quad.x1 + this.pointsDiff, quad.y1 + this.pointsDiff],
            documentId: this.docList[docIndex].documentId,
            pageNumber: pages[0],
            documentPageNumber: actualPageNumber - (docIndex === 0 ? 0 : 1)
        }

    }

    public static calculateDocumentStart(docIndex: number) {
        const currentDocPageEnd = this.linkedDocPageIndexes[docIndex];
        const prevDocPageEnd = this.linkedDocPageIndexes[docIndex - 1];

        return currentDocPageEnd - (currentDocPageEnd - prevDocPageEnd);
    }

    public static FindActualPageNumberForDocument(documentId: string, pageNumber: number) {

        const docs = this.docList.filter(x => x.documentId === documentId);

        if (docs) {

            const index = this.docList.indexOf(docs[0]);

            return pageNumber + this.linkedDocPageIndexes[index - 1] + 1;
        }

        return pageNumber;
    }

}
