import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SelectModel } from '@summize/shared/components-v2';
import { AppComponent, QuestionDefinitionType } from '@summize/shared/core';

import { QuestionSetSelectionService } from './question-set-selection.service';

export interface QuestionSetQuestion {
    questionId: string;
    quickSummaryQuestionSetId: string;
    description: string;
    questionType: number,
    typeMeta: string;
    index: number;
}

export interface QuestionSetAccess {
    id: string;
    groupId: string;
    group?: { groupName: string; };
}

export interface QuestionSetNotification {
    id: string;
    outboundIntegrationId: string;
    outboundIntegration?: { displayName: string; };
}

export interface QuestionSet {
    id: string;
    displayName: string;
    description: string;
    isDisabled: boolean;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date,
    updatedBy: string;
    isDeleted: boolean;
    deletedAt: Date,
    deletedBy: string;
    accesses: Array<QuestionSetAccess>;
    notifications: Array<QuestionSetNotification>;
    questions: Array<QuestionSetQuestion>;
    assignTo: string;

    isRestrictedAccess?: boolean;
}

export enum QuestionType {
    Text = 1,
    Date,
    Number,
    Company,
    Option,
    Guidance
}

@Component({
    selector: 'question-set-selection',
    templateUrl: './question-set-selection.component.html',
    styleUrls: ['./question-set-selection.component.scss'],
})
export class QuestionSetSelectionComponent extends AppComponent implements OnInit {

    public questionSets: Array<QuestionSet>;

    public questionSet: QuestionSet;

    public questionSetOptions: Array<SelectModel>;

    public questionDefinitionType = QuestionDefinitionType;

    public selection: any;

    constructor(@Inject(MAT_DIALOG_DATA)
    public data: any,
        private qsService: QuestionSetSelectionService,
        private dialogRef: MatDialogRef<QuestionSetSelectionComponent>) {

        super();
    }

    public async ngOnInit(): Promise<void> {

        this.questionSets = await this.qsService.getQuestionSets();

        if (this.questionSets.length === 1) {

            this.questionSet = this.questionSets[0];

        }

        this.questionSetOptions = this.questionSets.map(x => ({
            key: x.id,
            value: x.displayName,
            ...x
        }));

    }

    public changeQuestionSet(questionSetId: string) {

        this.questionSet = this.questionSets.find(x => x.id === questionSetId);

    }

    public fire(): void {

        this.dialogRef.close({
            questionSet: this.questionSet,
            cancel: false
        });

    }

    public close(): void {

        this.dialogRef.close({
            cancel: true
        });

    }

    public isQuestionBuilderType(type: number): boolean {

        return type >= QuestionDefinitionType.Dropdown;

    }

}
