import { Injectable } from '@angular/core';

import { LoginValues, LogoutValues } from '../types';
import { AuthServiceBase } from './auth-service.base';
import { OidcAuthService } from './oidc-auth.service';

@Injectable({ providedIn: 'root' })
export class AuthService {

    private authService: AuthServiceBase;

    constructor(
        private oidcAuthService: OidcAuthService,
    ) { }

    public async init() {

        this.authService = this.oidcAuthService;

        await this.authService.initialise();

    }

    public async login(loginValues?: LoginValues) {

        this.authService.login(loginValues);

    }

    public async logout(logoutValues?: LogoutValues) {

        await this.authService.logout(logoutValues);

    }

    public async isAuthenticated() {

        return this.authService.isAuthenticated();

    }

}
