import { BaseTenantService } from "../services/base-tenant.service";

export class ClientMatterLocalizerUtil extends BaseTenantService {

  private isClientMatterEnabled: boolean = false;

  private showGenericClientMatterLabels: boolean = false;

  private labelLookup: { [key: string]: any } = {
    'client': { clientMatter: 'Main Folder', generic: 'Main Folder' },
    'clients': { clientMatter: 'Folders', generic: 'Folders' },
    'matter': { clientMatter: 'Sub-Folder', generic: 'Sub-Folder' },
    'matter id': { clientMatter: 'Sub-Folder', generic: 'Sub-Folder' },
    'matters': { clientMatter: 'Matters', generic: 'Sub-Folders' },
  };

  constructor() {

    super();

    const user = this.getUser();

    this.isClientMatterEnabled = (user?.isClientMatterEnabled || false);

    this.showGenericClientMatterLabels = (user?.showGenericClientMatterLabels || false);

  }

  transform(value: string, specificGenericLabel?: string): string {

    if (this.isClientMatterEnabled) {

      if (this.showGenericClientMatterLabels && specificGenericLabel !== undefined) {

        return specificGenericLabel;

      }

      const label = this.labelLookup[value.toLowerCase()];

      if (label) {

        return this.showGenericClientMatterLabels ? label.generic : label.clientMatter;

      }

      // Fallback for any others.
      if (this.showGenericClientMatterLabels) {

        value = value.replace('Client ID', 'Folder Alias');
        value = value.replace('Client', 'Folder');
        value = value.replace('client', 'folder');
        value = value.replace('CLIENT', 'FOLDER');

        value = value.replace('Matter ID', 'Sub-Folder');
        value = value.replace('Matter', 'Sub-Folder');
        value = value.replace('Matters', 'Sub-Folders');
        value = value.replace('matter id', 'Sub-Folder');
        value = value.replace('matter', 'Sub-Folder');
        value = value.replace('MATTER ID', 'Sub-Folder');
        value = value.replace('MATTER', 'Sub-Folder');

      }

    }

    return value;
  }
}
