import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { SPECIAL_KEYWORD_TYPES } from '@summize/feature/clause-manager/keyword-constants';
import { ElipsisCommand } from '@summize/shared/components-v2';
import { AppComponent } from '@summize/shared/core';
import { EventService } from '@summize/shared/framework';
import { MatDialog } from '@angular/material/dialog';

import { ConfigureMetricComponent } from '../configure-metric/configure-metric.component';
import { RemoveClauseMetric, UpdateClauseMetric } from '../../analytics.events';
import { ClauseMetric, ClauseMetricDefinition, TrendDirection } from '../../analytics.types';
import { ClauseMetricService } from './clause-metric.service';
import { MaskValue } from './clause-metric.helper';

@Component({
    selector: 'app-clause-metric',
    templateUrl: 'clause-metric.html',
    styleUrls: ['./clause-metric.scss']
})
export class ClauseMetricComponent extends AppComponent {

    public TrendDirection = TrendDirection;

    @Input()
    public definition!: ClauseMetricDefinition;

    @Input()
    public range!: string;

    @Input()
    public clientId!: string;

    @Input()
    public matterId!: string;

    @Input()
    public metric!: ClauseMetric;

    @Input()
    public mode: string;

    @Input()
    public documentId: string;


    public progressValue!: number;

    public deltaValue!: number;

    public commands!: Array<ElipsisCommand>

    public get isCompare() {

        return this.mode === 'compare';

    }

    constructor(private service: ClauseMetricService,
        private router: Router,
        private events: EventService,
        private dialog: MatDialog) {

        super();

    }

    public async ngOnInit(): Promise<void> {

        this.commands = [
            {
                text: 'Configure',
                icon: 'cog-outline',
                onClick: () => this.configureWidget()
            },
            {
                text: 'Remove',
                icon: 'trash-outline',
                onClick: () => this.events
                    .despatch(RemoveClauseMetric, this.definition)
            },

        ];

        this.metric = await this.service.getClauseMetric(
            this.clientId,
            this.matterId,
            this.definition.contractTypeId,
            this.definition.clauseId,
            this.definition.type,
            this.range,
            this.documentId
        );

        if (this.isCompare === true
            && this.metric.documentComparisonResult !== null
            && this.metric.documentComparisonResult.value !== null) {

            this.metric.previousValue
                = this.metric.documentComparisonResult.value.toString();

            this.metric.percentage
                = this.metric.documentComparisonResult.delta;

            this.metric.trendDirection
                = this.metric.documentComparisonResult.trendDirection;

            this.deltaValue
                = (this.metric.documentComparisonResult.value / this.metric.high) * 100;

        }

        if (this.metric.noData === false) {

            this.progressValue = (this.metric.currentValue / this.metric.high) * 100;

            this.metric.type = SPECIAL_KEYWORD_TYPES[this.metric.type].label;

        }

    }

    public getValueDisplay(metric): string {

        if (this.isCompare === false) {

            return this.maskValue(metric.currentValueDisplay);

        }

        return `Contract: ${this.maskValue(this.metric.previousValue)},
         Avg: ${this.maskValue(metric.currentValueDisplay)}`;
    }

    public maskValue(value: any): string {

        return MaskValue(this.definition, value);

    }

    public goToTable() {

        this.router.navigate(['analytics/clause/table',
            this.definition.id,
            this.definition.clauseId,
            this.definition.contractTypeId,
            this.definition.type,
            this.range
        ], {
            queryParams: {
                clientId: this.clientId,
                matterId: this.matterId
            }
        });

    }

    public getTrendDirection(trend: TrendDirection): string {

        return trend === TrendDirection.Up ? 'up' : 'down';

    }

    public getValueTooltip(metric: any): string {

        if (this.isCompare === true) {

            return `${this.maskValue(metric.currentValueDisplay)} is the current average, with a high of
            ${this.maskValue(metric.highDisplay)} and low of ${this.maskValue(metric.lowDisplay)}. The value of this contract is
           ${this.maskValue(this.metric.previousValue)}`;

        }

        return `Current range value is ${MaskValue(this.definition, metric.currentValue)}, previous was ${MaskValue(this.definition, metric.previousValue) || 0}`

    }

    private configureWidget(): void {

        const confirmDelete = this.dialog.open(ConfigureMetricComponent, {
            data: {
                definition: this.definition
            }
        });

        this.subscribe(confirmDelete.afterClosed(), async (def: any) => {

            if (def !== undefined) {

                this.definition.settings = def.settings;

                this.events
                    .despatch(UpdateClauseMetric, this.definition)

            }

        });

    }


}