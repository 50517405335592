import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FadeInAnimation } from '@summize/shared/framework';
import { groupBy, flatMap } from 'lodash-es';

import { ClauseSelectorService } from './clause-selector.service';

export interface SelectClauseResult {
    checked: boolean;
    clauseId: string;
    displayName: string;
    documentTypeId: string;
    documentTypeName: string;
    standard: string;
    standardPositionLink: string;
}

@Component({
    selector: 'app-clause-selector',
    templateUrl: 'clause-selector.html',
    styleUrls: ['./clause-selector.scss'],
    animations: [FadeInAnimation]
})

export class ClauseSelectorComponent implements OnInit {

    public clauses: any;

    public loading: boolean;

    public title: string;

    public subtitle: string;

    private excludeDocType: string;

    private selectedClauses: Array<string>;

    private mode: string = 'Summary';

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private service: ClauseSelectorService) {

        this.excludeDocType = data.exclude;

        this.selectedClauses = data.selected;

        this.title = data.title || 'Select clauses';

        this.subtitle = data.subtitle || 'Choose the clauses you want to add to this contract type:';

        this.mode = data.mode || 'Summary';

    }

    public async ngOnInit(): Promise<void> {

        this.loading = true;

        const results = await this.service.getClauses(this.mode);

        const clauses = results.filter(r => r.documentTypeId !== this.excludeDocType);

        if (this.selectedClauses !== undefined) {

            for (const s of this.selectedClauses) {

                const match: any = clauses.find(c => c.clauseId === s);

                if (match !== undefined) {

                    match.checked = true

                }

            }

        }

        this.clauses = groupBy(clauses, 'documentTypeName');

        this.loading = false;

    }

    public get hasSelectedItems(): boolean {

        return this.selected().length > 0;
    }

    public selected(): Array<any> {

        const map: any = flatMap(this.clauses);

        return map.filter((c: { checked: boolean }) => c.checked === true);

    }

}