
import { Injectable } from '@angular/core';

import { ApplicationInsightsService } from './application-insights.service';
import { BaseTenantService } from './base-tenant.service';

export const MakeTelemetryEvent = (str): any => `Summize-${str}`

@Injectable()
export class TelemetryService extends BaseTenantService {

    constructor(private appInsights: ApplicationInsightsService) {
        super();
    }

    public track(event: any, properties?): void {

        const user = this.getUser();

        const base = {
            tenantId: user.tenantId,
            tenantName: user.tenantName,
            userId: user.userId,
            user: `${user.firstName} ${user.lastName}`,
            email: user.email,
            TenantId: user.tenantId,
            TenantName: user.tenantName,
            UserId: user.userId,
            User: `${user.firstName} ${user.lastName}`,
            Email: user.email
        };

        this.appInsights?.trackEvent(event.toString(), { ...base, ...properties });

    }

    public trackAnonymous(event: any, properties?): void {

        this.appInsights?.trackEvent(event.toString(), { ...properties });

    }

}
