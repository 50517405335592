import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Environment } from '../types';

import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';
import { CorrelationIdService } from '../services/correlation.service';

export const InterceptorSkipAuthValidation = 'X-Skip-Auth-Validation';
export const InterceptorSkipAuthBearer = 'X-Skip-Auth-Bearer';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

    constructor(private correlationIdService: CorrelationIdService,
        private authService: AuthService,
        private userService: UserService,
        @Inject(Environment) private environment) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let headers = req.headers;

        let skipAuthValidation = false;

        let skipAuthBearer = false;

        if (req.headers.has(InterceptorSkipAuthValidation)) {

            headers = headers.delete(InterceptorSkipAuthValidation);

            skipAuthValidation = true;

        }

        if (req.headers.has(InterceptorSkipAuthBearer)) {

            headers = headers.delete(InterceptorSkipAuthBearer);

            skipAuthBearer = true;

        }

        const isExternalRequest =
            skipAuthBearer === true ||
            req.url.includes(this.environment.storageLocation) === true ||
            req.url.includes(this.environment.originalLocation) === true ||
            req.url.includes(this.environment.logoUploadLocation) === true;

        if (isExternalRequest === false) {

            req = req.clone({
                withCredentials: true
            });

            const bearerToken = this.userService.getToken();

            if (bearerToken !== undefined) {

                headers = headers.set('Authorization', `Bearer ${bearerToken}`);

                const cId = this.correlationIdService.getCorrelationId();

                if (cId !== undefined && cId !== null) {

                    headers = headers.set('x-correlation-id', cId);

                }

            }
        }

        const httpEvent = undefined;

        httpEvent.toPromise().catch(err => {

            if (err.status === 401) {

                if (skipAuthValidation) {
                    return;
                }

                this.userService.clearSession();

                this.authService.login({ autoLogin: false });

            }

            if (err.status === 402) {

                console.log("Payment needed");

                return;

            }

        });

        return httpEvent;
    }
}
