import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseTenantService } from '@summize/shared/core';

@Injectable({
    providedIn: 'root'
})
export class ClauseSummaryPanelService extends BaseTenantService {

    constructor(private http: HttpClient) {
        super();
    }

}