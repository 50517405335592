import { NgModule } from '@angular/core';

import { LineSeparatorComponent } from './line-separator.component';


@NgModule({
    declarations: [
        LineSeparatorComponent
    ],
    exports: [
        LineSeparatorComponent
    ]
})
export class LineSeparatorModule {

}

export * from './line-separator.component';