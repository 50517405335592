<app-expandable-card [title]="clause.ruleName" [subtitle]="clauseSubtitle" [template]="template" [border]="false" [expanded]="isExpanded"
    (onExpandChange)="isExpanded = $event" [subtitlePosition]="'below'" [subtitleHover]="clauseSubtitleHover">
    <!-- Clause Commands -->
    <ng-template #template>
        <div *ngIf="isChildView === false" class="clause-commands">

            <app-icon *ngIf="bulletFromCopyEnabled === true" icon="add-circle-outline" (click)="onBulletFromCopyContext(clause)" tooltip="Create from copied text"
                [disabled]="!isLatest">
            </app-icon>

            <ng-container *appClaimsShow="[Claims.Documents.CanDocumentsUpdate]">
                <div *ngIf="isOpenAiEnabled === true" class="openai-command" [matTooltip]="'Open AI Summary'" matTooltipClass="smz-tooltip">
                    <img src="assets/images/openai_logo.svg" height="24px" width="24px" (click)="showOpenAiClauseReplacer(clause)" alt="Open AI" />
                </div>

                <app-icon [icon]="clause.clauseComments.length > 0 ? 'chatbubble-ellipses' : 'chatbubble-ellipses-outline'"
                    [ngClass]="clause.clauseComments.length > 0 ? 'comment-active' : ''" (click)="addComment(clause)" [tooltip]="'Add comment'" [disabled]="!isLatest">
                </app-icon>

                <app-icon [icon]="clause.isFlagged ? 'flag' : 'flag-outline'" [ngClass]="clause.isFlagged ? 'flag-active' : ''" (click)="toggleRedflag()"
                    [tooltip]="'Red flag clause'" [disabled]="!isLatest"></app-icon>

                <app-icon *ngIf="clause.documentTypes?.length > 0" [icon]="'book-outline'" (click)="showPlaybook(clause)" [tooltip]="'View playbooks'">
                </app-icon>
            </ng-container>
        
            <app-icon *ngIf="canCreateConversations" icon="chatbox-ellipses-outline" (click)="beginConversation(clause)" tooltip="New conversation">
            </app-icon>
        </div>
    </ng-template>

    <!-- Clause Result Bullets -->
    <div *ngIf="clause.ruleResult?.length > 0">

        <app-alert-banner *ngIf="clause.state === DocumentClauseResultState.AiPolicyViolationFallback"
            [theme]="AlertBannerThemes.warn" icon="warning" class="alert-banner"
            message="AI was unable to process a summary for this clause due to policy restrictions. The original text has been used for the summary instead." />

        <ul class="bullets">
            <li *ngFor="let bullet of clause.ruleResult; let i = index" [class.read-only]="clauseSummaryReadOnly === true" 
                class="bullet bullet-{{bullet.paragraphNumber}}" [class.bullet-isEditing]="bullet.isEditing === true">
                <p *ngIf="bullet.isEditing === false" (click)="clauseBulletClicked(clause, bullet)" [class.read-only]="clauseSummaryReadOnly === true"
                    [class.bullet-selected]="selectedBullet === getClauseBulletId(clause, bullet)">{{bullet.resultText}}
                </p>

                <textarea *ngIf="bullet.isEditing === true" cdkTextareaAutosize class="bullet-edit smz-standard-input" [(ngModel)]="bullet.resultText"></textarea>

                <!-- View Mode -->
                <div *ngIf="bullet.isEditing === false && isLatest" class="edit-panel">
                    <app-icon [icon]="'pencil-outline'" [tooltip]="'Edit'" (click)="editBullet(bullet)">
                    </app-icon>

                    <app-icon [icon]="'trash-outline'" [tooltip]="'Delete'" (click)="deleteBullet(bullet, i)">
                    </app-icon>
                </div>

                <!-- Edit Mode -->
                <div *ngIf="bullet.isEditing === true" class="edit-panel">
                    <app-icon [icon]="'save-outline'" [tooltip]="'Save'" (click)="saveBullet(bullet, i)">
                    </app-icon>

                    <app-icon [icon]="'close-outline'" [tooltip]="'Cancel'" (click)="bullet.isEditing = false">
                    </app-icon>
                </div>
            </li>
        </ul>
    </div>

    <div *ngIf="clause.ruleResult?.length === 0">
        No clause has been found
    </div>

    <!-- Comments -->
    <div *ngIf="clause?.clauseComments?.length > 0">

        <div *ngFor="let comment of clause.clauseComments">

            <!-- Edit Mode -->
            <div *ngIf="comment.isEditing === false" class="view-comment">

                <div>

                    <div class="clause-comment">
                        <div>
                            <p>{{comment.comment}}</p>
                            <p class="date-added">Comment added {{comment.createdAt | smzdate:'medium'}}</p>
                        </div>

                        <div *ngIf="isLatest" class="commands">
                            <app-icon [icon]="'pencil-outline'" [tooltip]="'Edit'" (click)="editComment(comment)">
                            </app-icon>

                            <app-icon [icon]="'trash-outline'" [tooltip]="'Delete'" (click)="deleteComment(comment)">
                            </app-icon>
                        </div>
                    </div>

                </div>
            </div>

            <!-- Edit Mode -->
            <div *ngIf="comment.isEditing === true" class="edit-comment">
                <div [textContent]="comment.comment" class="edit-container" (input)="comment.comment = $event.target.textContent" contenteditable="true"></div>

                <div class="commands">
                    <app-icon [icon]="'close-outline'" [tooltip]="'Cancel'" (click)="closeComment(comment)">
                    </app-icon>
                    <app-icon [icon]="'create-outline'" [tooltip]="'Save'" (click)="saveComment(comment)">
                    </app-icon>
                </div>
            </div>

        </div>
    </div>

</app-expandable-card>