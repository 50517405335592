import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CompanySelectComponent } from './company-select.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatAutocompleteModule,
        MatTooltipModule
    ],
    exports: [
        CompanySelectComponent
    ],
    declarations: [
        CompanySelectComponent
    ]
})
export class CompanySelectModule { }

export * from './company-select.component'