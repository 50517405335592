import { Component, ChangeDetectionStrategy, Input, TemplateRef, Output, EventEmitter, OnDestroy } from '@angular/core';

import { BaseTenantService } from '@summize/shared/core';
import { FadeInAnimation, SlideUpAnimation } from '@summize/shared/framework';

@Component({
    selector: 'app-context-bar',
    templateUrl: 'context-bar.html',
    styleUrls: ['./context-bar.scss'],
    animations: [FadeInAnimation, SlideUpAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ContextBarComponent implements OnDestroy {

    @Output()
    public onBack: EventEmitter<void>;

    @Output()
    public onIntroClick: EventEmitter<void>;

    @Input()
    public set primaryText(value: string) {

        this._primaryText = value;

        if (value !== undefined && value !== null) {

            this.setWindowTitle(value);
        }

    }

    public get primaryText(): string {

        return this._primaryText;

    }

    @Input()
    public secondaryText: string;

    @Input()
    public template: TemplateRef<any>;

    @Input()
    public primaryTemplate: TemplateRef<any>;

    @Input()
    public secondaryTemplate: TemplateRef<any>;

    @Input()
    public centerTemplate: TemplateRef<any>;

    @Input()
    public hasBackButton: boolean = false;

    @Input()
    public backIcon: string = 'arrow-back-outline';

    @Input()
    public hasIntro: boolean = false;

    @Input()
    public type: string;

    private centerObserver: MutationObserver;

    private _primaryText: string;

    constructor() {

        this.onBack = new EventEmitter<void>();

        this.onIntroClick = new EventEmitter<void>();

    }

    public ngOnDestroy(): void {

        if (this.centerObserver !== undefined) {

            this.centerObserver.disconnect();

        }

    }

    private setWindowTitle(title: string): void {

        if (BaseTenantService.environment.apiUrl.includes('.local')) {

            document.title = `DevSummize: ${title}`;

            return;

        }

        document.title = `Summize: ${title}`;

    }

}