import { 
    Component, 
    Input, 
    ViewContainerRef, 
    ViewChild, 
    AfterViewInit, 
    Output, 
    EventEmitter, 
    OnDestroy 
} from '@angular/core';

import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

import { 
    AppComponent, 
    BaseTenantService, 
    ChangeDetectionHelper, 
    EMPTY_GUID, 
    LayoutHelper, 
    MakeTelemetryEvent, 
    TelemetryService 
} from '@summize/shared/core';
import { 
    EventService, 
    RequestSubtype, 
    ShowPanelEvent, 
    WebsocketEventTypes, 
    WebsocketPayload, 
    WebsocketService 
} from '@summize/shared/framework';
import { ToolPanelService } from './tool-panel.service';

export interface ToolPanelContent {
    id: string,
    icon: string,
    iconSVG?: string,
    iconSelectedSVG?: string,
    component: any;
    tooltip: string;
    arguments?: any;
    eventGroups?: Array<string>;
    events?: Array<WebsocketEventTypes>;
    requiresClaim?: string;
    badgeEndpoint?: string;
    badgeValue?: string;
    isVersionSpecific?: boolean;
    featureFlag?: string;
}

export enum ToolPanelDisplayMode {
    Standard, 
    Sidebar, 
    Standalone
}

export interface ToolPanel {
    init(parent: ToolPanelComponent, panel: ToolPanelContent): Promise<void>;
    handleEvent?(type: WebsocketEventTypes, payload: any): Promise<void>;
    destroy(): void;
}

@Component({
    selector: 'app-tool-panel',
    templateUrl: 'tool-panel.html',
    styleUrls: ['./tool-panel.scss']
})
export class ToolPanelComponent extends AppComponent implements AfterViewInit, OnDestroy {

    public ToolPanelDisplayMode = ToolPanelDisplayMode;

    @ViewChild('host', { static: false, read: ViewContainerRef })
    public host: ViewContainerRef;

    @Output()
    public onSelected: EventEmitter<ToolPanelContent>;

    @Output()
    public onExpand: EventEmitter<boolean>;

    @Output()
    public onClose: EventEmitter<boolean>;

    @Input()
    public panels: Array<ToolPanelContent>;

    @Input()
    public contractId: string;

    @Input()
    public linkedContractId: string;

    @Input()
    public set contract(value: any) {

        this._contract = value;

        if (this.activePanelInstance !== undefined) {

            (<any>this.activePanelInstance).contract = value;
        }

    }

    public get contract(): any {

        return this._contract;

    }

    @Input()
    public clientId: string = EMPTY_GUID;

    @Input()
    public matterId: string = EMPTY_GUID;

    @Input()
    public selectedId: string;

    @Input()
    public type: string;

    @Input()
    public displayMode: ToolPanelDisplayMode = ToolPanelDisplayMode.Standard;

    @Input()
    public hidePanelTabs: boolean = false;    

    public selected: ToolPanelContent;

    public isExpanded: boolean = true;

    public isLoading: boolean = true;

    public isOnSmallDisplay: any;

    public previousPanel: any;

    private isFirstLoad: boolean = true;

    private animationDelay: number = 200;

    private socket: WebsocketService;

    private socketIsReady: boolean = false;

    private activePanelInstance: ToolPanel;

    private _contract: any;

    constructor(private events: EventService,
        private service: ToolPanelService,
        private telemetry: TelemetryService) {

        super();

        this.socket = new WebsocketService();

        this.onSelected = new EventEmitter<ToolPanelContent>();

        this.onExpand = new EventEmitter<boolean>();

        this.onClose = new EventEmitter<boolean>();

        this.isOnSmallDisplay = LayoutHelper.isSmallDisplay();

        this.isExpanded = !this.isOnSmallDisplay;

    }

    public async ngAfterViewInit(): Promise<void> {

        if (this.socketIsReady === false) {

            await this.setupWebsocketListener();

        }

        if (this.isExpanded === true) {

            const selected = this.selectedId === undefined ? this.panels[0] :
                this.panels.find(p => p.id === this.selectedId);

            await this.setSelectedPanel(selected);

        }

        const showPanelObs = this.events.when(ShowPanelEvent).pipe(
            debounceTime(100),
            distinctUntilChanged()
        );

        this.subscribe(showPanelObs, async (context: any) => {

            const args = context.context;

            const panel = this.panels.find(p => p.id == args.panelId);

            if (panel !== undefined) {

                if (this.selected.id === panel.id) {

                    await this.activePanelInstance[args.panelFunction](args.arguments);

                    return;

                }

                this.previousPanel = this.selectedId;

                this.selected = panel;

                this.selectedId = panel.id;

                await this.renderPanel(panel);

                await this.activePanelInstance[args.panelFunction](args.arguments);

            }

        });

        this.setPanelBadges();

    }

    public ngOnDestroy(): void {

        this.socket.stopConnection();

        this.socket = undefined;

        this.clearCurrentPanel();

        this.activePanelInstance = undefined;

        if (this.activePanelInstance !== undefined) {

            this.activePanelInstance.destroy();

        }

        super.ngOnDestroy();

    }

    public async reload(contractId: string, linkedContractId: string, contract: any): Promise<void> {

        this.contractId = contractId;

        this.linkedContractId = linkedContractId;

        this.contract = contract;

        if (this.selected?.id !== this.selectedId) {

            this.selected = this.panels.find(x => x.id === this.selectedId);

        }

        return await this.renderPanel(this.selected);

    }

    public toggleExpanded(): void {

        this.isExpanded = !this.isExpanded;

        this.clearCurrentPanel();

        this.selected = undefined;

        if (this.isExpanded === true) {

            this.ngAfterViewInit();
        }

        this.onExpand.next(this.isExpanded);

    }

    public shouldShow(panel: ToolPanelContent) {

        return this.hasClaims([panel.requiresClaim])
            && (panel.featureFlag === undefined || this.hasFeatureFlag(panel.featureFlag));

    }

    public joinSocketGroup(name: string): void {

        this.socket.joinGroup(name, this.clientId, this.matterId);

    }

    public async setSelectedPanel(panel: ToolPanelContent): Promise<void> {

        // RichS: 2023.11.22 - Added the 'isFirstLoad !== true' as otherwise it fails to load on first load
        // as @Input (I think) has already set the panel id so it doesn't end up setting 'isLoading to false' later.
        // It might be better to set isLoading to false in this 'if', but then we don't get the other bits
        // (emit, telemetry call, etc...). Nneeds SteO to fix this properly :).
        if (this.selected !== undefined && this.selected.id === panel.id && this.isFirstLoad !== true) {

            return;

        }

        this.previousPanel = undefined;

        this.selected = panel;

        this.selectedId = panel.id;

        this.isExpanded = true;

        this.onSelected.emit(this.selected);

        ChangeDetectionHelper.doNextCycle(async () => {

            this.isLoading = true;

            await this.renderPanel(this.selected);

            this.telemetry.track(MakeTelemetryEvent('ToolPanelRender'), {
                type: this.selected.id,
                requestType: RequestSubtype[this.contract?.requestSubType]
            });

            this.isLoading = false;

            this.isFirstLoad = false;

        }, this.animationDelay);

    }

    public getContractLink(): string {

        const base = new BaseTenantService().getDocumentPage(this.contract.clientId, this.contract.matterId, this.contract.documentId);

        return base;
    }

    private async renderPanel(panel: ToolPanelContent): Promise<void> {

        if (this.isExpanded === false || panel === undefined || this.host === undefined) {

            return;

        }

        this.clearCurrentPanel();

        this.activePanelInstance = this.host?.createComponent(panel.component as any)?.instance as ToolPanel;

        const baseArguments = {
            contractId: this.contractId,
            linkedContractId: this.linkedContractId,
            contract: this.contract,
            type: this.type,
            isChild: this.previousPanel !== undefined,
            isSideBar: this.displayMode === ToolPanelDisplayMode.Sidebar
        };

        const args = Object.assign(baseArguments, panel.arguments || {});

        Object
            .keys(args)
            .forEach(k => this.activePanelInstance[k] = args[k]);

        if (this.activePanelInstance.init !== undefined) {

            await this.activePanelInstance.init(this, panel);

        }

        if (this.selected.eventGroups !== undefined && this.selected.eventGroups.length > 0) {

            for (const group of this.selected.eventGroups) {

                const g = group.replace('__contractId__', this.contractId);

                this.socket.joinGroup(g, this.clientId, this.matterId);

            }

        }
    }

    private clearCurrentPanel(): void {

        const currentComp = (<any>this.activePanelInstance);

        if (currentComp !== undefined && currentComp.ngOnDestroy !== undefined) {

            currentComp?.ngOnDestroy();
        }

        if (this.host !== undefined) {

            this.host?.clear();

        }

    }

    private async setupWebsocketListener(): Promise<void> {

        const $onMessage = await this.socket.initConnection(true);

        this.subscribe($onMessage, (message: WebsocketPayload<any>) => {

            this.socketIsReady = true;

            if (this.selected === undefined) {

                return;
            }

            if (this.selected.events === undefined || this.selected.events.length === 0) {

                return;

            }

            if (this.selected.events.includes(message.type) === true) {

                if (this.activePanelInstance.handleEvent !== undefined) {

                    this.activePanelInstance.handleEvent(message.type, message.payload);

                }

            }

        });

    }

    public async setPanelBadges(): Promise<void> {

        const loadBadges = async () => {

            const panels = this.panels.filter(p => p.badgeEndpoint !== undefined && this.shouldShow(p));

            const promises = [];

            for (const panel of panels) {

                promises.push(new Promise(async (resolve) => {

                    const replaced = panel.badgeEndpoint.replace('__contractId__', this.contractId);

                    const value = await this.service.getBadgeValue(replaced);

                    panel.badgeValue = value;

                    resolve(true);

                }));

            }

            await Promise.all(promises);

        };

        this.subscribe(this.events.when('ReloadContractMeta'), (async () => {

            await loadBadges();

        }));

        await loadBadges();

    }

}
