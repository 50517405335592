import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'app-menu-buttons',
    templateUrl: 'menu-buttons.html',
    styleUrls: ['./menu-buttons.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})


export class MenuButtonsComponent {
    @Input()
    public display: boolean;

    @Input()
    public text: boolean;

    @Input()
    public icon: string;

}


