import { EventEmitter, Injectable, Injector } from '@angular/core';

import { AppComponent } from '@summize/shared/core';
import { SummizeStorage } from '@summize/shared/framework';

import { GmailService } from './gmail.service';
import { OutlookService } from './outlook.service';
import { ExternalContextChangedEvent, PlatformType, PlatformAttachments, PlatformSuggestion } from './platform.types';
import { SalesforceService } from './salesforce.service';
import { JiraService } from './jira.service';
import { HubspotService } from './hubspot.service';

class NoopPlatform {

    public async getAttachmentState(): Promise<PlatformAttachments> {

        return Promise.resolve({
            emailAttachments: undefined,
            allAttachments: undefined,
            context: undefined
        });

    }

    public get fileText() {

        return 'From Web';

    }

    public handleEvent(): void { }
    public showRequestModal(): void { }
    public isComposeMode(): boolean { return false }
    public addFileAttachment() { }
    public openInBrowser() { }
    public getPlatformSuggestions(): Promise<Array<PlatformSuggestion>> { return Promise.resolve(undefined) }
    public getEmailId(): string { return undefined; }
    public contextChanged(): void { }
    public async getEmailAsAttachment(): Promise<any> { return undefined; }
    public async ready(args?: any) { return Promise.resolve() };
}

@Injectable({ providedIn: 'root' })
export class PlatformService extends AppComponent {

    public onContextChange: EventEmitter<ExternalContextChangedEvent>;

    private gmail: GmailService;

    private outlook: OutlookService;

    private salesforce: SalesforceService;

    private jira: JiraService;

    private hubspot: HubspotService;

    private noPlatform: NoopPlatform;

    public static get Platform() {

        if ((<any>window).chrome?.runtime !== undefined) {

            return PlatformType.Gmail;

        }

        if (SummizeStorage.IsGmail() === true) {

            return PlatformType.GmailBridge;

        }

        if (OutlookService.IsOfficeLoaded === true) {

            return PlatformType.Outlook;

        }

        if (SummizeStorage.isHost(SummizeStorage.SalesforceHost)) {

            return PlatformType.Salesforce;

        }

        if (SummizeStorage.isHost(SummizeStorage.JiraHost)) {

            return PlatformType.Jira;

        }

        if (SummizeStorage.isHost(SummizeStorage.HubspotHost)) {

            return PlatformType.Hubspot;

        }

        return PlatformType.WebApp;

    }

    public static get ExtensionId() {

        return (<any>window).chrome?.runtime?.id;

    }

    public get currentPlatform() {

        return this.gmail
            || this.outlook
            || this.salesforce
            || this.jira
            || this.hubspot
            || this.noPlatform;

    }

    public get fileText() {

        return this.currentPlatform.fileText;

    }

    constructor(private injector: Injector) {

        super();

        this.setupPlatform();

    }

    public setupPlatform() {

        this.reset();

        this.onContextChange = new EventEmitter<ExternalContextChangedEvent>();

        if (PlatformService.Platform === PlatformType.Gmail || PlatformService.Platform === PlatformType.GmailBridge) {

            this.gmail = this.injector.get<GmailService>(GmailService);

            if (PlatformService.Platform === PlatformType.Gmail) {

                this.gmail.init();

                this.subscribe(this.gmail.onContextChange, ctx => this.onContextChange.next(ctx));

            }

        }

        if (PlatformService.Platform === PlatformType.Outlook) {

            this.outlook = this.injector.get<OutlookService>(OutlookService);

            this.subscribe(this.outlook.onContextChange, ctx => this.onContextChange.next(ctx));

        }

        if (PlatformService.Platform === PlatformType.Salesforce) {

            this.salesforce = this.injector.get<SalesforceService>(SalesforceService);

        }

        if (PlatformService.Platform === PlatformType.Jira) {

            this.jira = this.injector.get<JiraService>(JiraService);

        }

        if (PlatformService.Platform === PlatformType.Hubspot) {

            this.hubspot = this.injector.get<HubspotService>(HubspotService);

        }

        this.noPlatform = new NoopPlatform();

    }

    public async ready(args?: any) {

        return await this.currentPlatform.ready(args);

    }

    public getPlatform<T>() {

        return this.currentPlatform as T;

    }

    public handleEvent(event): void {

        return this.currentPlatform.handleEvent(event);

    }

    public async showRequestModal(requestId): Promise<void> {

        return await this.currentPlatform.showRequestModal(requestId);

    }


    public async getAttachmentContext(): Promise<PlatformAttachments> {

        return await this.currentPlatform.getAttachmentState();

    }

    public isComposeMode(): boolean {

        return this.currentPlatform.isComposeMode();

    }

    public addFileAttachment(url: string, filename: string, context = { asyncContext: null }) {

        return this.currentPlatform.addFileAttachment(url, filename, context);

    }

    public openInBrowser(url: string) {

        return this.currentPlatform.openInBrowser(url);

    }

    public async getPlatformSuggestions(): Promise<Array<PlatformSuggestion>> {

        return await this.currentPlatform.getPlatformSuggestions();

    }

    public async getEmailId(): Promise<string> {

        return await this.currentPlatform.getEmailId();

    }

    public contextChanged(): void {

        this.currentPlatform.contextChanged();

    }

    public async getEmailAsAttachment(): Promise<any> {

        return await this.currentPlatform.getEmailAsAttachment();

    }

    private reset() {

        this.outlook = undefined;

        this.gmail = undefined;

        this.noPlatform = undefined;

    }

}