import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'smz-block-button',
    templateUrl: 'smz-block-button.component.html',
    styleUrls: ['./smz-block-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmzBlockButtonComponent {

    @Input()
    public display: string;

    @Input()
    public icon: string;

    @Input()
    public type: 'block' | 'ghost' = 'block';

    @Input()
    public width: number = 235;

    @Input()
    public disabled: boolean = false;

}