import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { IconModule } from '../icon/icon.module';
import { SelectBoxModule } from '../select-box/select-box.module';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

import { SendForSignatureAutoPanelComponent } from './send-for-signature-auto-panel.component';
import { SendForSignatureAutoPanelService } from './send-for-signature-auto-panel.service';
import { EsignatureEmailRecipientsModule } from '../esignature-email-recipients/esignature-email-recipients.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IconModule,
        SelectBoxModule,
        MatCheckboxModule,
        EsignatureEmailRecipientsModule,
        LoadingSpinnerModule
    ],
    exports: [
        SendForSignatureAutoPanelComponent
    ],
    declarations: [
        SendForSignatureAutoPanelComponent
    ],
    providers:[
        SendForSignatureAutoPanelService
    ]
})
export class SendForSignatureAutoPanelModule { }

export * from './send-for-signature-auto-panel.component'