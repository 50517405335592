import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: 'confirmation-modal.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ConfirmationModalComponent {

    public header: string;

    public text: string;

    public entity: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {

        const _this: any = this;

        Object.keys(data).forEach((k: any) => _this[k] = data[k]);

    }

}