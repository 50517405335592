import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';

import { Metric, TrendDirection } from '../../analytics.types';

@Component({
    selector: 'app-metric',
    templateUrl: 'metric.html',
    styleUrls: ['./metric.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MetricComponent {

    public TrendDirection = TrendDirection;

    @Output()
    public onDrillDown: EventEmitter<void>;

    @Input()
    public type: 'full' | 'compact' = 'full';

    @Input()
    public metric: Metric | undefined;

    @Input()
    public hasDrilldown: boolean;

    @Input()
    public isAllDisplay: boolean = false;

    public constructor() {

        this.onDrillDown = new EventEmitter<void>();

    }

    public getTrendDirection(trend: TrendDirection): string {

        return trend === TrendDirection.Up ? 'up' : 'down';

    }

    public getValueTooltip(metric: Metric): string {

        return `Current range value is ${metric.value}, previous was ${metric.previousValue || 0}`

    }

}
