import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import 'quill-mention';
import { QuillEditorComponent } from 'ngx-quill';
import { Subscription } from 'rxjs';

import { ChatService } from '../../chat.service';

export interface InputMessage {
  html: string;
  mentions: string;
  elements?: InputMessageElement[],
  text?: string;
}

export interface InputMetaItem {
  value: string;
  key: string;
  type?: string;
  prompt?: any;
}

export interface InputMeta {
  atMentions: InputMetaItem[],
  hashMentions: InputMetaItem[]
}

export enum InputMessageElementType {
  Text = 0,
  Mention
};

export interface InputMessageElement {
  text: string;
  elementType: InputMessageElementType;
  elementKey?: string;
}

@Component({
  selector: 'app-chat-input',
  templateUrl: 'chat-input.component.html',
  styleUrls: ['./chat-input.component.scss']
})
export class ChatInputComponent implements OnInit, OnDestroy {

  @ViewChild(QuillEditorComponent, { static: true })
  public editor: QuillEditorComponent;

  @Input()
  public set content(value: string) {

    this._content = value;

    if (this.initialRenderComplete === true) {

      this.renderInitialContent(value);

    }

  }

  public get content(): string {

    return this._content;

  }

  @Input()
  public meta: InputMeta = undefined;

  @Input()
  public readonly: boolean = false;

  @Input()
  public placeholder: string = 'Enter your text and prompts here';

  @Input()
  public display: 'full' | 'text-area' | 'inline' = 'inline';

  @Input()
  public fixMentions: boolean = true;

  @Input()
  public positionMode: string = 'absolute';

  @Input()
  public toolbar = false;

  @Input()
  public showEntities = false;

  @Output()
  public onContentChanged: EventEmitter<any>;

  @Output()
  public onContentSend: EventEmitter<any>;

  public configuration: any;

  public isSendDisabled: boolean = true;

  public renderedContents: string;

  private initialRenderComplete: boolean = false;

  private _content: string;

  private __renderedContent;

  private _clickHandle: any;

  private subs: Subscription = new Subscription();

  private mentionModule = undefined;

  private currentMentionItems: InputMetaItem[] = undefined;

  private currentMentionPath: string[] = [];

  private mentionList = undefined;

  constructor(private chatService: ChatService) {

    this.onContentChanged = new EventEmitter<any>();
    this.onContentSend = new EventEmitter<any>();

    this.subs.add(
      this.onContentChanged.subscribe((x) => {

        this.isSendDisabled = x?.text === undefined || x.text.length <= 0;
        
      })
    );

  }

  public ngOnInit(): void {

    if (this.showEntities === true) {

      this._clickHandle = this.handleMentionClick.bind(this);

      window.addEventListener('mention-clicked', this._clickHandle, false);

    }

    this.configuration = {
      style: this.getStyleForDisplay(),
      modules: {
        toolbar: (this.toolbar === true && this.readonly === false) ? this.getToolbar() : false,
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: this.getMentionCharacterList(),
          mentionListClass: 'variable-list',
          fixMentionsToQuill: this.fixMentions,
          positioningStrategy: this.positionMode,
          showDenotationChar: true,
          dataAttributes: [
            ['id', 'value', 'denotationChar', 'key', 'type', 'path']
          ],
          onOpen: () => {
          },
          onClose: () => {

            this.mentionList = undefined;
            this.currentMentionItems = undefined;
            this.currentMentionPath = [];

          },
          onSelect: async (item, insertItem) => {

            const mentionItems = await this.getMentionItems(item.denotationChar);

            const mentionItem = mentionItems.find(x => x.value === item.value);

            this.currentMentionPath.push(item.value);

            this.onMentionItemSelected(mentionItem, { ...item, value: this.currentMentionPath.join(' > ') }, insertItem);
            
          },
          renderItem: (item: any,) => this.renderMenuItem(item),
          source: async (searchTerm, renderList, mentionChar) => {

            // Capture for the future.
            this.mentionList = { renderList, mentionChar, searchTerm };

            const mentionItems = this.getMentionItems(mentionChar);

            this.buildMenu(searchTerm, renderList, mentionItems);

          }
        }
      }
    };

  }

  public openMentionMenu(denotationChar) {

    this.mentionModule?.openMenu(denotationChar);

  }

  private getMentionCharacterList() {

    if (this.meta === undefined) {

      return [];

    }

    let mentionCharacterList = [];

    if (this.meta.atMentions !== undefined && this.meta.atMentions.length > 0) {

      mentionCharacterList.push('@');

    }

    if (this.meta.hashMentions !== undefined && this.meta.hashMentions.length > 0) {

      mentionCharacterList.push('#');

    }

    return mentionCharacterList;

  }

  public getMentionItems(mentionChar) {

    if (this.currentMentionItems !== undefined) {

      return this.currentMentionItems;

    }

    if (mentionChar === '@') {

      if (this.meta?.atMentions !== undefined) {

        return this.meta.atMentions;

      }

    } else if (mentionChar === '#') {

      if (this.meta?.hashMentions !== undefined) {

        return this.meta.hashMentions;

      }

    };

  }

  public ngAfterContentInit() {

    this.quillInit();
    this.renderInitialContent(this.content);

  }

  public ngOnDestroy(): void {

    window.removeEventListener('mention-clicked', this._clickHandle, false);

    this.subs.unsubscribe();

  }

  public handleMentionClick(event: any): void {

    const val = event.value;

    // RichS-Note: Currently don't need to handle this.

  }

  public getContent($event): void {

    if ($event.html !== undefined) {

      this.__renderedContent = $event.html;

      this.onContentChanged.next(this.getMessage());

    }

  }

  public contentSend() {

    this.onContentSend.next(this.getMessage());

  }

  public setContent(html: string, contentType: 'blockquote' | 'text' = 'text'): void {

    const editor = this.editor.quillEditor;

    if (editor !== undefined) {

      if (contentType === 'blockquote') {

        editor.insertEmbed(0, 'blockquote', html);

      }

      editor.insertText(0, html, {
        magicid: 'test'
      });

    }

  }

  public clear(): void {

    this.editor.quillEditor.setContents([], 'silent');

  }

  public getMessage(): InputMessage {

    const parser = new DOMParser();

    const doc = parser.parseFromString(this.__renderedContent, "text/html");

    const mentionsElements = doc.querySelectorAll('.mention');

    const mentions = [];

    mentionsElements.forEach(el => {

      const denotationChar = el.getAttribute('data-denotation-char');
      const val = el.getAttribute('data-key');

      mentions.push(`${denotationChar}:${val}`);

    });

    const innerContent = doc.querySelectorAll('body p');

    const parseHtml = (content): InputMessageElement[] => {

      let elements: InputMessageElement[] = [];

      for (const block of content) {

        for (const childNode of block.childNodes) {

          if (childNode.className === 'mention') {

            const denotationChar = childNode.getAttribute('data-denotation-char');
            const key = childNode.getAttribute('data-key');
            const innerText = childNode.firstElementChild.innerText;

            if (!(denotationChar && key && innerText)) {

              elements.push({ text: childNode.textContent, elementType: InputMessageElementType.Text });

            } else {

              let mentionKey = `${denotationChar}:${key}`;

              elements.push({ text: innerText, elementKey: mentionKey, elementType: InputMessageElementType.Mention });

            }

          } else {

            elements.push({ text: childNode.textContent, elementType: InputMessageElementType.Text });

          }
        }

        elements.push({ text: '\n', elementType: InputMessageElementType.Text });

      }

      return elements;

    }

    const result: InputMessage = {
      html: this.__renderedContent,
      mentions: mentions.join(',')
    };

    if (innerContent !== undefined && innerContent !== null) {

      const elements = parseHtml(innerContent);
      result.elements = elements;
      result.text = elements.map(x => x.text).join('').trim();

    } else {

      result.text = '';

    }

    return result;

  }

  private getToolbar(): any {

    return [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': 1 }, { 'header': 2 }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ];

  }

  private onMentionItemSelected(mentionItem, item, insertFunction: Function) {

    if (mentionItem.key !== undefined) {

      const editor = this.editor.quillEditor;

      insertFunction({ ...item, key: mentionItem.promptKey ?? mentionItem.key });

      editor.insertText(editor.getLength() - 1, '', 'prompt');

    }

  }

  private getStyleForDisplay(): any {

    if (this.display === 'text-area' || this.display === 'full') {

      return {
        height: '100px'
      };

    }

    return {};

  }

  private renderMenuItem(item: InputMetaItem): string | Node {

    if (item.type === 'header') {

      const element = document.createElement('div');

      const icon = document.createElement('span');
      icon.innerHTML = `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.75 0.625V3.125H17.25C17.5625 3.125 17.875 3.4375 17.875 3.75C17.875 4.10156 17.5625 4.375 17.25 4.375H14.75V6.875C14.75 7.22656 14.4375 7.5 14.125 7.5C13.7734 7.5 13.5 7.22656 13.5 6.875V4.375H11C10.6484 4.375 10.375 4.10156 10.375 3.75C10.375 3.4375 10.6484 3.125 11 3.125H13.5V0.625C13.5 0.3125 13.7734 0 14.125 0C14.4375 0 14.75 0.3125 14.75 0.625ZM6.9375 10.3906C6.78125 10.7422 6.42969 11.0156 6 11.0547L2.60156 11.5625L5.0625 13.9844C5.375 14.2578 5.49219 14.6875 5.41406 15.0781L4.82812 18.5156L7.91406 16.9141C8.26562 16.7188 8.69531 16.7188 9.04688 16.9141L12.1328 18.5156L11.5469 15.0781C11.4688 14.6875 11.5859 14.2969 11.8984 13.9844L14.3594 11.5625L10.9609 11.0938C10.5312 11.0156 10.1797 10.7422 10.0234 10.3906L8.46094 7.30469L6.9375 10.3906ZM7.64062 6.17188C7.99219 5.46875 8.96875 5.46875 9.32031 6.17188L11.1172 9.84375L15.1797 10.4297C15.9609 10.5469 16.2344 11.4844 15.6875 12.0312L12.7578 14.8828L13.4609 18.9062C13.5781 19.6875 12.7969 20.2734 12.0938 19.9219L8.5 18.0078L4.86719 19.9219C4.16406 20.2734 3.38281 19.6875 3.5 18.9062L4.20312 14.8828L1.27344 12.0312C0.726562 11.4844 1 10.5469 1.78125 10.4297L5.84375 9.84375L7.64062 6.17188ZM18.5 6.25C18.8125 6.25 19.125 6.5625 19.125 6.875V8.125H20.375C20.6875 8.125 21 8.4375 21 8.75C21 9.10156 20.6875 9.375 20.375 9.375H19.125V10.625C19.125 10.9766 18.8125 11.25 18.5 11.25C18.1484 11.25 17.875 10.9766 17.875 10.625V9.375H16.625C16.2734 9.375 16 9.10156 16 8.75C16 8.4375 16.2734 8.125 16.625 8.125H17.875V6.875C17.875 6.5625 18.1484 6.25 18.5 6.25Z" fill="url(#paint0_linear_35_2818)"/>
        <defs>
        <linearGradient id="paint0_linear_35_2818" x1="1" y1="10" x2="21" y2="10" gradientUnits="userSpaceOnUse">
        <stop stop-color="#1BCDFF"/>
        <stop offset="1" stop-color="#2B158C"/>
        </linearGradient>
        </defs>
        </svg>`;

      element.appendChild(icon);

      const text = document.createTextNode(item.value);

      element.appendChild(text);

      element.className = 'entity-header';

      return element;

    } else if (item.type === 'prompt') {

      const element = document.createElement('div');

      element.className = 'entity-element';
      element.innerText = item.value;

      return element;

    } else {

      const element = `${item.value}`;

      return element;

    }
  }

  private buildMenu(search: string, renderListFunction: Function, metaItems: InputMetaItem[]): void {

    let headerItem = {
      key: '',
      value: 'Suggested prompts',
      type: 'header',
      disabled: true
    };

    if (search.length === 0) {

      renderListFunction([headerItem, ...metaItems], search);

    } else {

      const matches = [];

      metaItems.forEach((metaItem) => {

        if (metaItem.value?.toLowerCase().indexOf(search.toLowerCase()) !== -1) {

          matches.push(metaItem);

        }

      })

      renderListFunction([headerItem, ...matches], search);

    }

  }

  private quillInit() {

    setTimeout(() => {

      const editor = this.editor?.quillEditor;

      if (editor !== undefined) {

        // Strip styling of pasted text.
        editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {

          let ops = [];

          delta.ops.forEach(op => {

            if (op.insert && typeof op.insert === 'string') {

              ops.push({
                insert: op.insert
              });

            }

          });

          delta.ops = ops;

          return delta;

        });

      }

    }, 100);

  }

  private renderInitialContent(initialContent): void {

    setTimeout(() => {

      this.initialRenderComplete = true;

      const editor = this.editor.quillEditor;

      if (editor !== undefined) {

        const contents = editor.clipboard.convert(initialContent);

        editor.setContents(contents, 'silent');

        this.mentionModule = editor.getModule('mention');

        // RichS: Be careful with this as we are detouring the original logic for 'onSelect' as
        // we need to support nested items.
        const mentionModuleSelectItem = this.mentionModule.selectItem;

        this.mentionModule.selectItem = async () => {

          if (this.mentionModule.itemIndex === -1) {

            return;

          }

          const data = this.mentionModule.mentionList.childNodes[this.mentionModule.itemIndex].dataset;

          const mentionItems = await this.getMentionItems(data.denotationChar);

          const mentionItem = mentionItems.find(x => x.value === data.value);

          if (mentionItem?.prompt?.path !== undefined && mentionItem?.prompt?.path !== null) {

            this.currentMentionPath.push(data.value);

            if (this.mentionList?.searchTerm?.length > 0) {

              const length = this.mentionList.searchTerm.length;

              editor.deleteText(editor.getSelection().index - length, length);

            }

            const meta = await this.chatService.getPrompts(mentionItem.prompt.path);

            this.currentMentionItems = meta?.prompts?.map(x => ({ value: x.label, key: x.promptKey ?? x.promptId, prompt: x })) ?? [];

            this.buildMenu('', this.mentionList.renderList, this.currentMentionItems);

          } else {

            mentionModuleSelectItem.call(this.mentionModule);

          }

        }

      }
    }, 100);

  }

}