import { Component, Input } from '@angular/core';

import { FadeInAnimation } from '@summize/shared/framework';

import { ProgressListItem } from '../../analytics.types';

@Component({
    selector: 'app-progress-result-bar',
    templateUrl: 'progress-result-bar.html',
    styleUrls: ['./progress-result-bar.scss'],
    animations: [FadeInAnimation]
})
export class ProgressResultBarComponent {

    @Input()
    public model!: ProgressListItem;

    @Input()
    public avatar: boolean = true;

}