import { trigger, transition, query, stagger, style, animate } from "@angular/animations";

export let SlideUpAnimation = trigger('slideUpAnimation', [
    transition(':enter', [
        query('.block', style({ opacity: 0 }), { optional: true }),
        query('.block', stagger(0, [
            style({ transform: 'translateY(25px)' }),
            animate('0.3s cubic-bezier(.75,-0.48,.26,1.52)',
                style({ transform: 'translateY(0px)', opacity: 1 })),
        ]), { optional: true }),
    ])
]);