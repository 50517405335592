import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DialogShellModule } from '../dialog-shell/dialog-shell.module';
import { SelectBoxModule } from '../select-box/select-box.module';

import { ClauseAssignBulletComponent } from './clause-assign-bullet.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        DialogShellModule,
        SelectBoxModule
    ],
    exports: [
        ClauseAssignBulletComponent
    ],
    declarations: [
        ClauseAssignBulletComponent
    ]
})
export class ClauseAssignBulletModule { }

export * from './clause-assign-bullet.component'