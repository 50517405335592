import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-overlay',
    templateUrl: 'overlay.html',
    styleUrls: ['./overlay.scss'],
})
export class OverlayComponent {

    @Input()
    public disabled: boolean = false;

    @Input()
    public display: string;

    @Input()
    public width: number;

    public isOpen: boolean = false;
}