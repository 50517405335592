import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NoAuthGuard implements CanActivate {

  constructor(
    private authService: AuthService, 
    private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot): 
      | boolean
      | UrlTree
      | Promise<boolean | UrlTree>
      | Observable<boolean | UrlTree>  {
    return this.authService.isAuthenticated$.pipe(
      map(isAuthenticated => {

        if (isAuthenticated) {
          return this.router.createUrlTree(['/tenants']);          
        }
        return true;
      })
    );
  }
}
