import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { BaseTenantService, User } from "@summize/shared/core";
import { InterceptorSkipAuthBearer, RequestAttachment } from "@summize/shared/framework";

@Injectable({ providedIn: 'root' })
export class AttachmentDetailsService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async deleteAttachment(attachmentId: string): Promise<any> {

        const url = `${this.getUserBaseUrl(true)}attachments/${attachmentId}`;

        return await this.http.delete(url).toPromise();
    }

    public async downloadAttachmentBlob(attachment: RequestAttachment): Promise<any> {

        const token = await this.getAttachmentToken(attachment.attachmentId);

        const url = `${token.cloudLocation}/${token.fileName}${token.accessToken}`

        const options = {
            headers: new HttpHeaders().set(InterceptorSkipAuthBearer, 'true'),
            responseType: 'blob' as 'json'
        };

        return await this.http.get(url, options).toPromise();

    }

    public async getAttachmentUrl(attachment: RequestAttachment): Promise<string> {

        const token = await this.getAttachmentToken(attachment.attachmentId);

        return `${token.cloudLocation}/${token.fileName}${token.accessToken}`

    }

    private async getAttachmentToken(attachmentId: string): Promise<any> {

        const url = `${this.getUserBaseUrl(true)}attachments/${attachmentId}/download`;

        return await this.http.get(url).toPromise();
    }

}