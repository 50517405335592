import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

export interface CommandDefinition {
    onClick?: Function,
    text: string,
    width?: number,
    icon?: string,
    class?: string,
    type?: string,
    isSaveCommand?: boolean
    closeAfterClick?: boolean
}

export interface SlidePanelSettings {
    component: any;
    init?: (instance, panel) => void;
    arguments?: any;
    title: string;
    closeable: boolean;
    size: 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'max' | 'scale';
    backdrop: boolean,
    commands?: Array<CommandDefinition>
    managed?: boolean;
    amimate?: boolean;
    onBackdrop?: () => void;
    onDestroy?: () => void;
}

export interface SlidePanelContextProvider {

    getContext?(): any;

}

@Injectable({ providedIn: 'root' })
export class SlidePanelService {

    public $onChange: Subject<SlidePanelSettings>;

    public $onShowChild: Subject<SlidePanelSettings>;

    public $onClose: Subject<void>;

    constructor() {

        this.$onChange = new Subject<SlidePanelSettings>();

            this.$onShowChild = new Subject<SlidePanelSettings>();

        this.$onClose = new Subject<void>();

    }

    public show(args: SlidePanelSettings) {

        this.$onChange.next(args);

    }

    public showChild(args: SlidePanelSettings) {

        this.$onShowChild.next(args);

    }

    public close() {

        this.$onClose.next();

    }

}