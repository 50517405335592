import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { BaseTenantService } from "@summize/shared/core";
import { DocusignEnvironment, DocusignInitialInformationResponse } from "@summize/shared/framework";

@Injectable({ providedIn: 'root' })
export class DocusignService extends BaseTenantService {

    constructor(private http: HttpClient) { super(); }

    public async getDocusignEnvironment(): Promise<DocusignEnvironment> {

        const baseUrl = this.getUserBaseUrl(true);

        return await this.http.get<DocusignEnvironment>(`${baseUrl}docusign/environment`).toPromise();

    }

    public async getDocusignInitialInformation(authCode: string): Promise<DocusignInitialInformationResponse> {

        const baseUrl = this.getUserBaseUrl(true);

        const payload = {
            authCode
        };

        return await this.http.post<DocusignInitialInformationResponse>(`${baseUrl}docusign/accounts/new/meta`, { ...payload }).toPromise();

    }

    public async saveDocusignPersonalAccount(displayName: string, docusignUserAccounts: any, docusignAccessToken: any): Promise<any> {

        const url = this.getUserBaseUrl(true);

        const payload = {
            displayName,
            docusignUserAccounts,
            docusignAccessToken
        };

        return await this.http.post(`${url}docusign/accounts/link`, { ...payload }).toPromise() as any;

    }

    public async getDocusignAccountsForIntegration(integrationId: string) {

        const baseUrl = this.getUserBaseUrl(true);

        const payload = {
            integrationId: integrationId
        }

        return await this.http.post(`${baseUrl}docusign/accounts`, payload).toPromise();

    }

    public async deletePersonalDocusignIntegration(integrationId: string) {

        const baseUrl = this.getUserBaseUrl(true);

        const results = await this.http.delete<any>(`${baseUrl}docusign/accounts/${integrationId}`).toPromise();

    }

    public async refreshPersonalDocusignIntegration(integrationId: string) {

        const baseUrl = this.getUserBaseUrl(true);

        const results = await this.http.get<any>(`${baseUrl}docusign/accounts/${integrationId}/refresh`).toPromise();

    }

}