import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { UserModalNotificationType } from '@summize/shared/framework';
import { NotificationsService } from '@summize/shared/core';

import { RequestAnalyticsMovedComponent } from './request-analytics-moved-modal.component';

export function handlePendingNotifications(
    notificationsService: NotificationsService,
    dialog: MatDialog,
    router: Router,
    menuItemClick?: boolean,
    onComplete?: () => void
) {
    return async () => {
        const pendingNotificationsResponse = await notificationsService.getUserNotifications();

        if (pendingNotificationsResponse?.pendingNotifications?.showRequestAnalyticsMovedNotification) {
            dialog
                .open(RequestAnalyticsMovedComponent, {
                    disableClose: true,
                    data: {
                        menuItemClick,
                        UserModalNotificationType: UserModalNotificationType.RequestAnalyticsMoved,
                    },
                })
                .afterClosed()
                .subscribe((result) => {

                    if (result?.goToAnalytics) {

                        router.navigateByUrl('quick-summary/dashboards');
                    }

                    if (onComplete) {

                        onComplete();
                    }

                });
        } else {

            if (onComplete) {

                onComplete();
            }
            
        }
    };
}
