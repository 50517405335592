<div class="openai-panel">
    <div>
        <div class="info-text">
            OpenAI will generate a summary based on the related paragraphs found for this clause. You can edit the
            OpenAI generated summary below and use this to replace the current clause bullet(s) for this clause by
            clicking 'Replace summary'.
        </div>

        <div class="subtitle">
            Related paragraphs:
        </div>

        <div class="text-card">
            <textarea #clauseText class="clause-text" [value]="ruleResults" (keyup)="ruleResults = $event.target.value">
            </textarea>
        </div>

        <div class="hint-text">
            Related paragraphs are determined by the settings in Clause Manager and are what OpenAI uses to generate the
            summary below.
        </div>

        <div>
            <div class="row">
                <div class="subtitle">
                    OpenAI generated summary:
                </div>
            </div>

            <div *ngIf="isLoading === true" class="spinner">
                <app-loading-spinner visible="true" type="pale"></app-loading-spinner>
            </div>

            <div *ngIf="isLoading === false">
                <div class="text-card">
                    <textarea #clauseText class="clause-text" [value]="answer" (keyup)="answer = $event.target.value">
                    </textarea>
                </div>

                <div *ngIf="isLoading === false && error === true" class="error-message">
                    <i class="icon icon-error1 error"></i>
                    <div *ngIf="error === true" class="isLoading-text">
                        {{errorMessage}}
                    </div>
                </div>
            </div>
            <div class="row commands">

                <app-block-button (click)="replaceSummary()" [disabled]="isLoading === true || error === true 
                    || answer === undefined || answer.length === 0" display="Replace summary" width="175"
                    type="v2-primary"></app-block-button>

                <app-block-button (click)="getSummary()" [disabled]="isLoading === true || ruleResults.length === 0"
                    display="Regenerate" width="175" type="v2-primary"></app-block-button>
            </div>

        </div>
    </div>