import { Injectable } from '@angular/core';

import { SummizeStorage } from './summize-storage';

@Injectable({ providedIn: 'root' })
export class SessionStorageService {

    private window = (typeof window !== 'undefined');

    public getItems() {

        if (this.window) {

            return SummizeStorage.getSessionItems();

        }

        return null;
    }

    getItem(key: string): any {

        if (this.window) {

            return SummizeStorage.getSessionItem(key);
        }

        return null;
    }

    public getNumberFromStorage(key: string, fallback: number): number {

        const value = this.getItem(key);

        if (value !== null && value !== undefined) {

            return parseInt(value, 10);

        }

        return fallback;

    }


    setItem(key: string, val: string) {
        if (this.window) {
            SummizeStorage.setSessionItem(key, val);
        }
    }

    removeItem(key: string) {
        if (this.window) {
            SummizeStorage.removeSessionItem(key);
        }
    }

    removeItemByRegex(regex: RegExp) {
        if (this.window) {
            var items = this.getItems();
            items.forEach(i => {
                if (regex.test(i.key)) {
                    this.removeItem(i.key);
                }
            })
        }
    }

    clear() {
        if (this.window) {
            SummizeStorage.clearSession();
        }
    }

}
