import { Component, EventEmitter, Input, Output } from '@angular/core';

interface Status {
    id: number,
    statusName: string,
    statusStyle: string,
    background: string;
}

@Component({
    selector: 'app-status-info',
    templateUrl: 'status-info.html',
    styleUrls: ['./status-info.scss']
})
export class StatusInfoComponent {

    static REGEX = /color:\s*#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3});/;

    @Output()
    public onStatusChanged: EventEmitter<Status> = new EventEmitter<Status>();

    @Input()
    public readonly = false;

    @Input()
    public set status(value: number) {

        this._status = value;

        this.setSelected();

    }

    public get status(): number {

        return this._status;

    }

    @Input()
    public set available(value: Array<Status>) {

        this._available = value;

        for (const status of this.available) {

            status.background = `#${StatusInfoComponent.REGEX.exec(status.statusStyle)[1].toLowerCase()}`;

        }

        this.setSelected();

    }

    public get available(): Array<Status> {

        return this._available;

    }

    public selected: Status;

    private _status: number;

    private _available: Array<Status>;

    private setSelected(): void {

        if (this.status !== undefined && this.status !== null) {

            this.selected = this.available?.find(a => a.id === this.status);

        }

    }

}