import { Directive, Input, ElementRef } from '@angular/core';

import { BaseTenantService } from '@summize/shared/core';

@Directive({
    selector: '[appClaimsDisable]'
})
export class ClaimsDisableDirective {

    @Input()
    public set appClaimsDisable(claims: string[]) {

        this.claims = claims;

        this.updateView();

    }

    public claims: string[] = [];

    private __base: BaseTenantService;

    constructor(private el: ElementRef) {

        this.__base = new BaseTenantService();

    }

    public updateView(): void {

        const claims = this.__base.getClaims();

        if (claims) {

            if (this.__base.hasClaims(this.claims)) {

                this.el.nativeElement.disabled = true;

            } else {

                this.el.nativeElement.disabled = false;

            }
        }
    }


}