import { Component, ViewChild, ElementRef, Input, OnInit, EventEmitter, Output } from '@angular/core';

import { Chart } from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';

import { DateTimeFilterPresetEnum, EventService, WidgetChartConfigurationOptions, WidgetChartElement, WidgetFilterRequest, WidgetTimeFormat } from '@summize/shared/framework';
import { AppComponent, WidgetChartConfigurationOptionsImpl } from '@summize/shared/core';

import { ChartWidgetService } from './chart-widget.service';
import { ChartJsColorsHelper } from './chartjs-colors.helper';

@Component({
    selector: 'app-chart-widget',
    templateUrl: 'chart-widget.html',
    styleUrl: './chart-widget.scss'
})
export class ChartWidgetComponent extends AppComponent implements OnInit {

    @ViewChild('chartContainer')
    public chartContainer: ElementRef;

    @Input()
    public chartMeta: any;

    @Input()
    public widgetFilters: any;

    @Input()
    public dateFrom: Date;

    @Input()
    public dateTo: Date;

    @Input()
    public dateFilterPreset: DateTimeFilterPresetEnum;

    @Input()
    public timeFormat: WidgetTimeFormat;

    @Input()
    public showSampleData = false;

    @Input()
    public widgetChartConfigurationOptions: WidgetChartConfigurationOptions;

    @Output()
    public onWidgetFiltersReceived = new EventEmitter<WidgetFilterRequest[]>();

    public widgetChartElement: WidgetChartElement;

    public isLoading = true;

    public isError = false;

    private currentChart: any;

    private preventLegendInteraction = true;

    constructor(private service: ChartWidgetService, private events: EventService) {

        super();

        Chart.register(zoomPlugin);
    }

    public async ngOnInit() {

        if (this.chartMeta === undefined) {

            this.isLoading = false;

            return;

        }

        this.widgetChartConfigurationOptions ??= WidgetChartConfigurationOptionsImpl.create(true);

        if (this.chartMeta.datasource !== undefined && this.showSampleData === false) {

            await this.loadData();

        } else if (this.showSampleData === true && this.chartMeta.sampleData !== undefined) {

            this.widgetChartElement = this.chartMeta.sampleData;

            this.renderChart();

        } else {

            this.renderChart();
        }

    }

    public async reload(filters: any, dateFilter: any, timeFormat: WidgetTimeFormat) {

        this.widgetFilters = filters;

        if (dateFilter) {

            this.dateFrom = dateFilter.dateFrom;

            this.dateTo = dateFilter.dateTo;

            this.dateFilterPreset = dateFilter.type;
        }

        this.timeFormat = timeFormat;

        await this.loadData();
    }

    public async loadData() {

        try {

            this.widgetChartElement = await this.service.getData(this.chartMeta.datasource, this.dateFrom, this.dateTo, this.dateFilterPreset, this.widgetFilters, this.timeFormat);

            if (this.widgetChartElement.widgetFilters !== undefined && this.widgetChartElement.widgetFilters !== null && this.widgetChartElement.widgetFilters.length > 0) {

                this.onWidgetFiltersReceived.next(this.widgetChartElement.widgetFilters);
            }

            this.renderChart();

        } catch (err) {

            this.events.actionFailed('Failed to create chart, try refreshing the page');

            this.isLoading = false;

            this.isError = true;

        }

    }

    public renderChart(): void {

        this.isLoading = false;

        if (this.widgetChartElement?.totalRecords === 0) {

            return;
        }

        setTimeout(() => {

            if (this.currentChart) {

                this.currentChart.destroy();

                while (this.chartContainer.nativeElement.firstChild) {

                    this.chartContainer.nativeElement.removeChild(this.chartContainer.nativeElement.firstChild);

                }

            }

            if (this.widgetChartElement?.data?.datasets.length === 0) {

                return;
            }

            const ctx = this.buildCanvas();

            if (ctx === null) {

                this.events.actionFailed('Failed to create chart, try refreshing the page');

                return;

            }

            const opts = this.widgetChartElement?.options ?? {};

            opts.plugins ??= {};

            opts.plugins.legend ??= {};

            if (this.preventLegendInteraction === true) {

                opts.plugins.legend.onClick = opts.plugins.legend.onClick ?? (() => { return; });

            }

            if (this.widgetChartConfigurationOptions.displayTotals === true) {

                opts.plugins.tooltip ??= {};

                opts.plugins.legend.labels = {
                    usePointStyle: true,
                    generateLabels: (chart) => {
                        const datasets = chart.data.datasets;
                        const totalValues = datasets
                            .map(dataset => {
                                return dataset.data.reduce((a, b) => a + b, 0)
                            });
                        return datasets
                            .map((dataset, i) => ({
                                text: `${dataset.label} - ${this.formatDisplayValue(totalValues[i])}`,
                                fillStyle: dataset.backgroundColor,
                                hidden: !chart.isDatasetVisible(i),
                                lineCap: dataset.borderCapStyle,
                                lineDash: dataset.borderDash,
                                lineDashOffset: dataset.borderDashOffset,
                                lineJoin: dataset.borderJoinStyle,
                                lineWidth: dataset.borderWidth,
                                strokeStyle: dataset.borderColor,
                                pointStyle: 'rect',
                                rotation: dataset.rotation,
                                datasetIndex: i
                            }))
                    }

                }

                opts.plugins.tooltip = this.getTooltipOptions();

                opts.plugins.tooltip.itemSort = (a, b) => {
                    return b.raw - a.raw;
                };

                if (this.widgetChartElement.widgetTimeFormat === undefined || this.widgetChartElement.widgetTimeFormat === WidgetTimeFormat.None) {

                    opts.plugins.tooltip.filter = (tooltipItem) => {
                        // Check if the data value is not zero
                        return tooltipItem.raw !== null && tooltipItem.raw !== undefined && tooltipItem.raw !== 0;
                    }

                }

            }

            this.currentChart = new Chart(ctx, {
                type: this.chartMeta.type || 'bar',
                data: this.widgetChartElement?.data ?? this.chartMeta.data,
                options: opts,
                plugins: [{
                    id: '1',
                    beforeInit: (chart) => {

                        chart.data.datasets.forEach((dataset, idx) => {

                            if (dataset.backgroundColor !== undefined && dataset.backgroundColor !== null) {
                                return;
                            }

                            let c = ChartJsColorsHelper.getNextColor(idx);

                            dataset.backgroundColor = c;
                            dataset.borderColor = c;
                        });
                    }
                }]
            });

        }, 1000);

    }

    private getTooltipOptions() {
        return {
            mode: 'index',
            intersect: false,
            callbacks: {
                title: (context) => {
                    if (context.length === 0) {

                        return;

                    }
                    const total = context[0].chart.data.datasets.reduce((sum, dataset) => {
                        return sum + dataset.data[context[0].dataIndex];
                    }, 0);

                    return `${context[0].label}: ${this.formatDisplayValue(total)}`;
                },
                label: (context) => {

                    if (context.length === 0) {

                        return;

                    }

                    let label = context.dataset.label || '';

                    if (label) {
                        label += ': ';
                    }

                    label += this.formatDisplayValue(context.raw);

                    return label;
                }
            }
        };
    }

    private formatDisplayValue(avgValue: number): string {

        if (this.widgetChartElement.widgetTimeFormat == undefined) {

            return avgValue.toString();

        }

        switch (this.widgetChartElement.widgetTimeFormat) {

            case WidgetTimeFormat.Weeks:
                return `${avgValue.toFixed(1)}w`;
            case WidgetTimeFormat.Days:
                return `${avgValue.toFixed(1)}d`;
            case WidgetTimeFormat.Minutes:
                return `${avgValue.toFixed(1)}m`;
            case WidgetTimeFormat.Hours:
                return `${avgValue.toFixed(1)}h`;
            case WidgetTimeFormat.None:
            default:
                return avgValue.toString();
            }

    }


    private buildCanvas() {

        const targetElement = this.chartContainer.nativeElement;

        if (targetElement) {

            const canvas = document.createElement('canvas');

            canvas.width = targetElement.clientWidth;
            canvas.height = 600;

            targetElement.appendChild(canvas);

            return canvas;

        } else {

            return null;
        }

    }

}