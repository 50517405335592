import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MatCheckboxModule } from '@angular/material/checkbox';

import { IconModule } from '../icon/icon.module';
import { SelectBoxModule } from '../select-box/select-box.module';

import { ContractTypeSelectorComponent } from './contract-type-selector.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SelectBoxModule,
        IconModule,
        MatCheckboxModule
    ],
    exports: [
        ContractTypeSelectorComponent
    ],
    declarations: [
        ContractTypeSelectorComponent
    ]
})
export class ContractTypeSelectorModule { }

export * from './contract-type-selector.component';