import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './new-dashboard-modal.html',
    styleUrl: './new-dashboard-modal.scss'
})
export class NewDashboardModalComponent {

    public dashboardName: string = '';
    public isDefault: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<NewDashboardModalComponent>) { }

    public close() {

        this.dialogRef.close({
            name: this.dashboardName,
            isDefault: this.isDefault
        });

    }
}