import { Component, OnInit } from '@angular/core';

import { AppComponent } from '@summize/shared/core';
import { EventService } from '@summize/shared/framework';

import { HeaderCommandPressed } from '../../analytics.events';

@Component({
    selector: 'app-header-bar',
    templateUrl: 'header-bar.html',
    styleUrls: ['./header-bar.scss']
})
export class HeaderBarComponent extends AppComponent implements OnInit {

    constructor( private events: EventService ) {

        super();
    }

    public async ngOnInit(): Promise<void> { }

    public addGraph(): void {

        this.events.despatch(HeaderCommandPressed, { id: 'ADD_GRAPH' });
    }

}